import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';

class ActivityRecognitionTagClient {
  
  getTags() {
    return client.callApi(Config.API_BASE + '/activity_recognition_tags.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  createTag(tagData) {
    return client.callApi(Config.API_BASE + '/activity_recognition_tags.json', {}, 'POST', {recognition_tag: tagData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
    
  updateTag(tagId, tagData) {
    return client.callApi(Config.API_BASE + '/activity_recognition_tags/'+tagId+'.json', {}, 'PUT', {recognition_tag: tagData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
    
  deleteTag(tagId) {
    return client.callApi(Config.API_BASE + '/activity_recognition_tags/'+ tagId +'.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  sortTags(tagIds) {
    return client.callApi(Config.API_BASE + '/activity_recognition_tags/sort.json', {}, 'PUT', {recognition_tags: tagIds})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }

  getTagImageURL(tagName){
    if(!tagName) return;
    return Config.API_BASE+'/assets/images/tags/'+tagName+'.png'
  }
  
}

export const activityRecognitionTagClient = new ActivityRecognitionTagClient();
