import React from "react";
import ActionCable from 'actioncable';
import { companySettingsClient } from "../../clients/CompanySettingsClient";
import { adminLoggedIn } from "../../helpers/TopMenu";
import AdminMenuItems from "./AdminMenuItems";
import EmployeeMenuItems from "./EmployeeMenuItems";

import './DesktopLeftBar.css';
import { client } from "../Client";

class DesktopLeftBar extends React.Component {
    state = {
        userAccessSettings: {
            conversation: false,
            pickup_shift: false,
            invitation: false,
            acknowledgement: false,
            poll: false,
            need_to_change_password: false,
        }
    }

    subscription;
    channelName = "NotificationChannel";

    componentDidMount() {
        const _this = this;
        if (!adminLoggedIn()) {
            this.getCurrentUserAccessSettings();
        }

        const listener = {
            received(data) {
                if (!client.isLoggedIn()) {
                    window.location.hash = '/login';
                    return;
                };

                switch (data.action) {
                    case 'refresh_access_settings':
                        _this.getCurrentUserAccessSettings();
                        break;
                    default:
                        break;
                }
                console.log("received, channel:" + _this.channelName, data);
            }
        }

        ActionCable.debugging = true;
        window.cable = ActionCable.createConsumer(client.getConsumerUrl());
        this.subscription = window.cable.subscriptions.create({ channel: this.channelName }, listener);
    }

    componentWillUnmount() {
        if (this.subscription) {
            setTimeout(() => { console.log('unsubscribed'); this.subscription.unsubscribe() }, 100);
        }
    }

    getCurrentUserAccessSettings = () => {
        companySettingsClient.getCurrentUserAccessSettings()
            .then((response) => {
                if (response.access_settings) {
                    this.setState({ userAccessSettings: response.access_settings });
                    if (response.access_settings.need_to_change_password) {
                        this.refs.changePasswordForm.open();
                    }
                }
            });
    }

    renderList = () => {
        const pathname = this.props.location.pathname
        if (adminLoggedIn())
            return (<AdminMenuItems
                containerClass="nav"
                currentPath={pathname}
            />);
        else return (<EmployeeMenuItems
            containerClass="nav"
            currentPath={pathname}
            pickShiftIsAllowed={this.state.userAccessSettings.pickup_shift}
            acknowledgementIsAllowed={this.state.userAccessSettings.acknowledgement}
            pollIsAllowed={this.state.userAccessSettings.poll}
        />);
    }

    render() {
        return (
            <div className="left-bar ezzely-header top-navigation">
                {this.renderList()}
            </div>
        )
    }
}

export default DesktopLeftBar;