// @flow
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { rewardTypeClient } from '../../clients/RewardTypeClient';
import { client } from '../Client';
import { Button } from 'react-bootstrap';
import IBox from '../common/IBox';
import SweetAlert from 'sweetalert-react';
import { toastrNotification } from './../../helpers/Toastr';
import CreateRewardType from './CreateRewardType';
import RewardTypeRaw from './RewardTypeRaw';
import RouletteList from './RouletteList';
import ReactQuill from 'react-quill';

import 'sweetalert/dist/sweetalert.css';
import './RewardsList.css'

import 'react-quill/dist/quill.snow.css';

class RewardTypeList extends Component {

  state = {
    rewardTypes: [],
    editorText: ''
  }

  constructor(props){
    super(props)
  }
  
  componentDidMount() {
    this.getRewardTypes();
  }

  getRewardTypes() {
    rewardTypeClient.getRewardTypes().
    then((reward_types) => {
      this.setState({ rewardTypes: reward_types })
    });
  }
    
  handleUpdateActiveStatus = (rewardTypeId) => {
    let modifiedRewardTypes = this.state.rewardTypes;
    rewardTypeClient.updateActiveStatus(rewardTypeId).
    then((reward_type) => {
      if (!reward_type.id && reward_type.message) {
        toastrNotification({ success: false, message: reward_type.message });
        return;
      };
      for (let i in modifiedRewardTypes) {
        if (modifiedRewardTypes[i].id === reward_type.id) {
          modifiedRewardTypes[i].active = reward_type.active;
          break
        }
      }
      this.setState({ rewardTypes: modifiedRewardTypes });
    });
  }
  
  handleCreateNewRewardType = (newRewardType) => {
    let joinedRewardTypes = this.state.rewardTypes.concat(newRewardType);
    this.setState({ rewardTypes: joinedRewardTypes });
  }
  
  handleUpdateRewardType = (updatedRewardType) => {
    let updatedRewardTypes = this.state.rewardTypes;
    for (let i in updatedRewardTypes) {
      if (updatedRewardTypes[i].id === updatedRewardType.id) {
        updatedRewardTypes[i] = updatedRewardType;
        break
      }
    }
    this.setState({ rewardTypes: updatedRewardTypes });
  }
  
  openModal() {
    this.refs.CreateRewardModal.show();
  }
  
  handleEditorChange(value) {
    this.setState({ editorText: value })
  }
  
  deleteRewardType = (rewardTypeId) => {
    rewardTypeClient.deleteRewardType(rewardTypeId).then((response) => {
      if (response.success == true) {
        toastrNotification({ success: true, message: 'Reward type item was successfully deleted' });
        this.getRewardTypes();
      } else {
        toastrNotification({ success: false, message: 'Unable to delete reward type' });
      }
    });
  }
  
  render() {
    return (
      <div>
        <div className="ibox">
            <div className="ibox-title">
              <h5>Rewards</h5>
            </div>
            <div className="ibox-content">
              <div className="row">
                <div className="col-sm-12">
                  <button className="btn btn-primary btn-sm pull-right" onClick={() => this.openModal()}><FontAwesomeIcon icon={faPlus}/> New Reward</button>
                  <CreateRewardType 
                    ref="CreateRewardModal" 
                    onAddNewRewardType={this.handleCreateNewRewardType}
                    pointsRatio={this.props.pointsRatio}
                  />
                </div>
              </div>
              <div className="table-responsive project-list">
                { this.props.companyBalance && this.props.companyBalance == 0 &&
                  <p>
                    * A balance in your account is required to activate a gift card reward. 
                    <a target="_blank" href="https://prod.ezzely.com/backend"> Replenish incentive balance</a>.
                  </p>
                }
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Responsible Person</th>
                      <th>Type</th>
                      <th>Taxable</th>
                      <th>Redeemable</th>
                      <th>Unit</th>
                      <th>Points</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.rewardTypes.map((rewardType) => (
                        <RewardTypeRaw 
                          rewardType={rewardType} 
                          key={'rt'+rewardType.id}
                          onUpdateActiveStatus={this.handleUpdateActiveStatus}
                          onUpdateRewardType={this.handleUpdateRewardType}
                          pointsRatio={this.props.pointsRatio}
                          deleteRewardType={this.deleteRewardType}
                          companyBalance={this.props.companyBalance}
                        />
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
        </div>
        
        <RouletteList rewardTypes={this.state.rewardTypes} />        
            
      </div>    
    )
  }

}


export default RewardTypeList;
