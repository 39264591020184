import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DateFieldLink extends Component {

  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string
  }

  render () {
    return (
      <a onClick={this.props.onClick}>      
        <span>{this.props.value}</span>
      </a>
    )
  }
}

export default DateFieldLink;