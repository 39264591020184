import React, { Component } from 'react';
import SweetAlert from 'sweetalert-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';

import 'sweetalert/dist/sweetalert.css';

class RemoveCommentLink extends Component {

  state = {
    show: false
  }
  
  handleCommentRemove = () => {
    this.props.onRemoveComment();
    this.setState({ show: false });
  }
  
  render() {
      return (
        <div style={{display: 'inline-block'}}>
          <a onClick={() => this.setState({ show: true })}><FontAwesomeIcon icon={faTrashAlt}/></a>
          <SweetAlert
            show={this.state.show}
            type="warning"
            title="Are you sure?"
            showCancelButton
            text="This operation can not be undone."
            onConfirm={() => this.handleCommentRemove()}
            onCancel={() => this.setState({ show: false })}
          />
        </div>  
      )  
  }
      
}

export default RemoveCommentLink;
