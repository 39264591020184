import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Tabs, Tab, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { mediumDateFormat } from '../helpers/Formatter';
import Raffle from '../models/Raffle';
import { toastrNotification } from './../helpers/Toastr';
import SweetAlert from 'sweetalert-react';
import Participant from '../components/raffle/Participant';
import { raffleClient } from '../clients/RaffleClient';
import { currencyFormat } from '../helpers/Formatter';
import moment from 'moment';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

import '../components/raffle/RaffleView.css'
import {activeRoute} from "../helpers/TopMenu";
import {faEye} from "@fortawesome/free-regular-svg-icons/faEye";

class RaffleView extends Component {

    state = {
      raffle: new Raffle({participants: [], winner: {}}),
      spinnerClass: 'sk-loading',
      showGetWinnerNotification: false,
      showCancelRaffleNotification: false,
      showVisibleRaffleNotification: false,
      loading: false
    }

    componentDidMount() {
      this.setState(this.state);
      this.getRaffle();
    }

    getRaffle = () => {
      raffleClient.getRaffle(this.props.match.params.id).then((raffle) => {
        this.setState({ raffle: raffle, spinnerClass: '', loading: false})
      });
    }

    getRaffleWinner = () => {
      this.setState({showGetWinnerNotification: false, loading: true});
      raffleClient.getRaffleWinner(this.props.match.params.id).then((response) => {
        if (response.success == true) {
          this.getRaffle();
        } else {
          toastrNotification({success: false, message: 'Something went wrong. Please try later.'})
          setTimeout(() => {
            this.setState({ loading: false })
          }, 1500)
        }
      });
    }

    handleGetWinnerClick = () => {
      if (this.state.raffle.participants.filter(p => p.active).length === 0) {
        swal("Error!", "There are no active participants in this raffle yet.", "error");
      } else {
        this.setState({ showGetWinnerNotification: true })
      }
    }

    renderWinner() {
      const winner = this.state.raffle.winner

      return (
        <dd className="project-people">
            <Participant
              id={'tw'+winner.employee_id}
              participant={winner}
            />
        </dd>
      )
    }

    handleVisibleRaffleClick() {
        this.setState({ showVisibleRaffleNotification: true });
    }

    handelCancelRaffleClick() {
      this.setState({ showCancelRaffleNotification: true });
    }

    hideRaffle() {
        this.setState({ showVisibleRaffleNotification: false });
        raffleClient.hideRaffle(this.state.raffle.id).then((response) => {
            if (response.success) {
                toastrNotification({success: true, message: 'The raffle has been removed.'})
                this.props.history.push('/raffles')
            } else {
                toastrNotification({success: false, message: 'Something went wrong. Please try later.'})
            }
        });
    }

    cancelRaffle() {
      this.setState({ showCancelRaffleNotification: false });
      raffleClient.cancelRaffle(this.state.raffle.id).then((response) => {
        if (response.success) {
          toastrNotification({success: true, message: 'The raffle has been cancelled.'})
          this.getRaffle();
        } else {
          toastrNotification({success: false, message: 'Something went wrong. Please try later.'})
        }
      });
    }

    renderStatusLabel = (raffle) => {
      switch (raffle.status)
         {
           case 'not started': return (<span className="label label-warning">Not started</span>);
           case 'active': return (<span className="label label-primary">Active</span>);
           case 'canceled': return (<span className="label">Canceled</span>);
           case 'finished': return (<span className="label label-info">Finished</span>);
           case 'expired': return (<span className="label label-danger">Expired</span>);
           case 'completed': return (<span className="label label-info">Finished</span>);
         }
    }

    updateParticipant = (participant, action) => {
      raffleClient.updateParticipant(participant, {data: action}).then((response) => {
        if (response.success == true) {
          const participants = this.state.raffle.participants.map((p) => {
            if (participant.id === p.id) {
              if (action === 'exclude') {
                return Object.assign({}, participant, {excluded_at: new Date()});
              } else if (action === 'include') {
                return Object.assign({}, participant, {excluded_at: null});
              }
            } else {
              return p;
            }
          })
          this.setState({raffle: new Raffle(Object.assign({}, this.state.raffle, {participants: participants}))})
        } else {
          toastrNotification({success: false, message: 'Something went wrong. Please try later.'})
        }
      })
    }

    render() {
        const raffle = this.state.raffle

        return (
            <div className={'wrapper wrapper-content animated fadeInUp ' + this.state.spinnerClass}>
                <Link to="/raffles" className="btn btn-white btn-sm pull-right m-b-sm hidden-xs">Back to Raffles</Link>
                <div className="sk-spinner sk-spinner-double-bounce">
                  <div className="sk-double-bounce1"></div>
                  <div className="sk-double-bounce2"></div>
                </div>

                <div className="ibox">
                  <div className="ibox-content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="m-b-md">
                          <h2>{raffle.title}</h2>
                        </div>
                        <dl className="dl-horizontal">
                          <dt>Status:</dt>
                          <dd>{this.renderStatusLabel(raffle)}</dd>
                          <dt>Type:</dt>
                          <dd>{raffle.raffle_type}</dd>
                        </dl>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-5 col-md-6">
                        <dl className="dl-horizontal">
                          <dt>Visible For:</dt>
                          <dd>{raffle.department_title}</dd>
                          <dt>Raffle start date:</dt>
                          <dd>{moment(raffle.start_date).format('dddd MMM DD, hh:mm a')}</dd>
                          <dt>Raffle expiry date:</dt>
                          <dd>{moment(raffle.expiry_date).format('dddd MMM DD, hh:mm a')}</dd>
                          { raffle.description &&
                            <span>
                              <dt>Description:</dt>
                              <dd>{raffle.description}</dd>
                            </span>
                          }
                          { raffle.isEventRaffle() &&
                            <span>
                              <dt>Event:</dt>
                              <dd>{raffle.event_name}</dd>
                              <dt>Event date:</dt>
                              <dd>{moment(raffle.event_date).format('dddd MMM DD, hh:mm a')}</dd>
                              <dt>Venue:</dt>
                              <dd>{raffle.event_location}</dd>
                              <dt>Ticket count:</dt>
                              <dd>{raffle.ticket_count}</dd>
                              <dt>Estimated cost:</dt>
                              <dd>{currencyFormat(raffle.estimated_cost)}</dd>
                              { raffle.tickets_cost &&
                                <span>
                                  <dt>Tickets cost:</dt>
                                  <dd>{currencyFormat(raffle.tickets_cost)}</dd>
                                </span>
                              }
                            </span>
                          }
                          { raffle.isRewardRaffle() &&
                            <span>
                              <dt>Reward Type:</dt>
                              <dd>{raffle.reward_type}</dd>
                            </span>
                          }
                          { raffle.isGiftCardRaffle() &&
                            <span>
                              <dt>Gift Card Amount:</dt>
                              <dd>{currencyFormat(raffle.card_amount)}</dd>
                            </span>
                          }
                          { raffle.canceled_at &&
                            <span>
                              <dt>Cancel date:</dt>
                              <dd>{moment(raffle.canceled_at).format('dddd MMM DD, hh:mm a')}</dd>
                            </span>
                          }
                          { raffle.finished_at &&
                            <span>
                              <dt>Finish date:</dt>
                              <dd>{moment(raffle.finished_at).format('dddd MMM DD, hh:mm a')}</dd>
                            </span>
                          }
                          <dt>Participants:</dt>
                          <dd className="project-people">
                            {
                              raffle.participants.filter(p => p.active).map((participant) => (
                                <Participant
                                  key={'participant' + participant.employee_id}
                                  id={'tp'+participant.employee_id}
                                  participant={participant}
                                />
                              ))
                            }
                          </dd>
                        </dl>
                        { raffle.winner  &&
                          <dl className="dl-horizontal">
                            <dt>Winner:</dt>
                             { this.renderWinner() }
                          </dl>}
                      </div>
                      <div className="col-lg-6 col-md-6">
                        { raffle.isEventRaffle() &&
                          <img src={raffle.image_url} alt="image" className="img-responsive" />
                        }
                      </div>
                    </div>

                    <div className="row m-b-sm">
                      <div className="col-lg-4">
                        <br/>
                          { raffle.isActive() && raffle.can_be_finished &&
                            <LaddaButton data-style={EXPAND_LEFT} type="button" loading={this.state.loading} className={'ladda-button btn btn-primary m-r-sm'} onClick={() => this.handleGetWinnerClick()}>Finish</LaddaButton>
                          }
                          { raffle.isActive() && raffle.can_be_canceled &&
                            <button className="btn btn-white btn-smallscreen-right m-r-sm" onClick={() => this.handelCancelRaffleClick()}><FontAwesomeIcon icon={faBan}/> Cancel Raffle</button>
                          }
                          { (raffle.isFinished() || raffle.isCanceled() || raffle.isExpired()) && raffle.isVisible() &&
                            <button className="btn btn-danger btn-smallscreen-right " onClick={() => this.handleVisibleRaffleClick()}><FontAwesomeIcon icon={faTimes}/> Remove Raffle</button>
                          }

                          <SweetAlert
                            show={this.state.showGetWinnerNotification}
                            type="warning"
                            title="Are you sure you want to complete the raffle and get winner?"
                            confirmButtonColor="#DD6B55"
                            showCancelButton
                            text="This operation can not be undone."
                            onConfirm={() => this.getRaffleWinner()}
                            onCancel={() => this.setState({ showGetWinnerNotification: false })}
                          />
                          <SweetAlert
                            show={this.state.showCancelRaffleNotification}
                            type="warning"
                            title="Are you sure?"
                            confirmButtonColor="#DD6B55"
                            showCancelButton
                            text="This operation can not be undone."
                            onConfirm={() => this.cancelRaffle()}
                            onCancel={() => this.setState({ showCancelRaffleNotification: false })}
                          />
                          <SweetAlert
                              show={this.state.showVisibleRaffleNotification}
                              type="warning"
                              title="Are you sure?"
                              confirmButtonColor="#DD6B55"
                              showCancelButton
                              text="This operation can not be undone."
                              onConfirm={() => this.hideRaffle()}
                              onCancel={() => this.setState({ showVisibleRaffleNotification: false })}
                          />
                      </div>
                    </div>

                    { raffle.participants.length > 0 &&
                      <div className="row">
                        <div className="row m-t-sm">
                          <div className="col-lg-12">
                            <div className="panel blank-panel">
                              <Tabs defaultActiveKey={1} id="uncontrolled-tab-example" className="panel-heading">
                                <Tab eventKey={1} title="Active Participants">
                                  <div className="panel-body">
                                    <div className="tab-content">
                                      <div className="tab-pane active" id="participant-activity">
                                        <div className="feed-activity-list">
                                          {
                                            raffle.participants.filter(p => p.active && p.excluded_at === null).map((participant) => (
                                              <div key={'participant-activity' + participant.employee_id} className="feed-element">
                                                <Link to={`/employee/${participant.employee_id}`} className="pull-left">
                                                  <img className="img-circle" alt="image" src={participant.avatar_url ?  participant.avatar_url : 'img/avatar_missing.png'} />
                                                </Link>
                                                <div className="media-body">
                                                  <Button disabled={!raffle.isActive()} bsClass="btn btn-danger btn-sm float-right" onClick={(() => this.updateParticipant(participant, 'exclude'))}>Exclude</Button>
                                                  <strong>{participant.employee_full_name}</strong>
                                                  <br></br>
                                                  <small className="text-muted">{moment(participant.created_at).format('dddd MMM DD, hh:mm a')}</small>
                                                </div>
                                              </div>
                                            ))
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                                <Tab eventKey={2} title="Excluded">
                                  <div className="panel-body">
                                    <div className="tab-content">
                                      <div className="tab-pane active" id="participant-activity">
                                        <div className="feed-activity-list">
                                        {
                                          raffle.participants.filter(p => p.excluded_at !== null).map((participant) => (
                                            <div key={'participant-activity' + participant.employee_id} className="feed-element">
                                              <Link to={`/employee/${participant.employee_id}`} className="pull-left">
                                                <img className="img-circle" alt="image" src={participant.avatar_url ?  participant.avatar_url : 'img/avatar_missing.png'} />
                                              </Link>
                                              <div className="media-body">
                                                <Button disabled={!raffle.isActive()} bsClass="btn btn-success btn-sm float-right" onClick={(() => this.updateParticipant(participant, 'include'))}>Include</Button>
                                                <strong>{participant.employee_full_name}</strong>
                                                <br></br>
                                                <small className="text-muted">{moment(participant.created_at).format('dddd MMM DD, hh:mm a')}</small>
                                              </div>
                                            </div>
                                          ))
                                        }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                                <Tab eventKey={3} title="Not Interested">
                                  <div className="panel-body">
                                    <div className="tab-content">
                                      <div className="tab-pane active" id="participant-activity">
                                        <div className="feed-activity-list">
                                          {
                                            raffle.participants.filter(p => !p.active && p.excluded_at === null).map((participant) => (
                                              <div key={'participant-activity' + participant.employee_id} className="feed-element">
                                                <Link to={`/employee/${participant.employee_id}`} className="pull-left">
                                                  <img className="img-circle" alt="image" src={participant.avatar_url ?  participant.avatar_url : 'img/avatar_missing.png'} />
                                                </Link>
                                                <div className="media-body">
                                                  <strong>{participant.employee_full_name}</strong>
                                                  <br></br>
                                                  <small className="text-muted">{moment(participant.created_at).format('dddd MMM DD, hh:mm a')}</small>
                                                </div>
                                              </div>
                                            ))
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                              </Tabs>

                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
            </div>
        )
    }
}

export default RaffleView
