import React, { Component } from 'react';
import { Wheel } from 'react-custom-roulette';
import { Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert-react';
import { toastrNotification } from '../helpers/Toastr';
import { rouletteClient } from '../clients/RouletteClient';
import { trimText } from '../helpers/Humanizer';
import ReelPrompt from '../components/reels/congratulations/ReelPrompt';

//const backgroundColors = ['#82e4aa', '#eeeeee', '#ffc47a', '#39b9ea', '#ff7c7c', '#ecbeef', '#f7efb5', '#98b0dc' ]
//const textColors = ['#333', '#333', '#333', '#333', '#333', '#333', '#333', '#333']

//const backgroundColors = ['#104c77', '#ff9151', '#f7efb5', '#39b9ea', '#104c77', '#ff9151', '#f7efb5', '#39b9ea' ]
//const textColors = ['#73c7ff', '#444', '#ff9151', '#444', '#f7efb5', '#444', '#104c77', '#444']

const backgroundColors = ['#EF4F24', '#8BC53F', '#EF4F24', '#8BC53F', '#EF4F24', '#8BC53F', '#EF4F24', '#8BC53F']
const textColors = ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']

class RouletteView extends Component {

  state = {
    mustSpin: false,
    showPrize: false,
    prizeNumber: 0,
    record: {
      roulette: {},
      prizes: []
    }
  }

  constructor(props) {
    super(props)
    this.promptRef = React.createRef();
  }

  componentDidMount() {
    this.getRouletteRecord()
  }

  getRouletteRecord() {
    rouletteClient.getRouletteRecord(this.props.match.params.id).then((record) => {
      if (record.id) {
        this.setState({ record: this.composePrizes(record) })
      } else {
        toastrNotification({ success: false, message: "Unable to find roulette." });
      }
    });
  }

  stopSpinning = () => {
    rouletteClient.updateRouletteRecord(this.state.record.code, this.state.record.prizes[this.state.prizeNumber].reward_type_id).then((record) => {
      if (record.id) {
        this.setState({ mustSpin: false, showPrize: true }, () => {
          this.promptRef.current?.houseReward();
        })
      } else {
        toastrNotification({ success: false, message: "Unable to save prize." });
        this.setState({ mustSpin: false })
      }
    })
  }

  composePrizes = (record) => {
    let prizes = Array.from(record.prizes)
    for (var i = prizes.length; i < 8; i++) {
      prizes.push(prizes[i % record.prizes.length])
    }
    return Object.assign({}, record, { prizes: prizes })
  }

  closeCongratulation = () => {
    this.setState({ showPrize: false })
  }

  rouletteOptions = () => {
    return (this.state.record.prizes || []).map((prize, i) => (
      {
        option: trimText(prize.title, 16),
        style: {
          backgroundColor: backgroundColors[i % backgroundColors.length],
          textColor: textColors[i % textColors.length]
        }
      }
    )
    )
  }

  handleSpinClick = () => {
    const data = this.rouletteOptions()
    const prizeNumber = Math.floor(Math.random() * data.length)
    this.setState({ prizeNumber: prizeNumber, mustSpin: true })
  }

  winningText = () => {
    if (this.state.record.prizes[this.state.prizeNumber]) {
      return "You won " + this.state.record.prizes[this.state.prizeNumber].title + ". We'll notify your manager."
    } else {
      return ""
    }
  }

  render() {

    return (
      <>
        {this.state.record.id &&
          <div className="roulette-box">
            <h1 className="text-center">{this.state.record.roulette.title}</h1>
            <Wheel
              mustStartSpinning={this.state.mustSpin}
              prizeNumber={this.state.prizeNumber}
              data={this.rouletteOptions()}
              outerBorderWidth={10}
              outerBorderColor={'#ddd'}
              radiusLineWidth={10}
              radiusLineColor={'#ddd'}
              fontSize={18}
              onStopSpinning={this.stopSpinning}
            />
            <div className='text-center' >
              <Button bsStyle="success" bsSize="large" disabled={!!this.state.record.reward_type_id} onClick={this.handleSpinClick}>Spin to get your Reward</Button>
            </div>
          </div>
        }

        {this.state.showPrize &&
          <ReelPrompt ref={this.promptRef} text={this.winningText()} />
        }
        {/*<SweetAlert
            show={this.state.showPrize}
            type="success"
            title="Congratulations!"
            confirmButtonColor="#DD6B55"
            text={this.winningText()}
            onConfirm={this.closeCongratulation}
      />*/}
      </>
    )
  }
}

export default RouletteView