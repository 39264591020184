import React, { Component } from 'react';
import CompanyInfo from '../components/account/CompanyInfo';

import { client } from '../components/Client';
import { toastrNotification } from './../helpers/Toastr';

class SettingsView extends Component {

    state = {
      university: {}
    };

    componentDidMount() {
      this.getUniversity();
    }

    getUniversity = () => {
      client.getUniversity().then((university) => {
        this.setState({ university: university })
      });
    }
    
    reloadUniversity = (updatedUniversity) => {
      this.setState({ university: updatedUniversity });
    }

    updateUniversity = (data) => {
      client.updateUniversity(data).then((university) => {
        if (university.id) {
          toastrNotification({success: true, message:'Updated successfully'});
        } else {
          toastrNotification({success: false, message: ('Unable to update') })
        }
        this.setState({ university: university })
      });
    }

    render() {
        return (
            <div className="wrapper wrapper-content animated fadeIn">
                <h1>Company Settings</h1>
                <CompanyInfo university={this.state.university} onUpdateUniversity={this.updateUniversity} onReloadUniversity={this.reloadUniversity} />
            </div>
        )
    }

}

export default SettingsView;
