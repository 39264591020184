import React, { Component } from "react";
import "./Welcome.css";
import { Modal, Button, ModalBody, ModalDialog } from "react-bootstrap";
import { Redirect } from "react-router-dom";

class Welcome extends Component {
  state = {
    showModal: false,
    shouldRedirect: false,
  };
  componentDidMount() {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
    this.setState({ shouldRedirect: true });
  };

  render() {
    const redirect = () => {
      <Redirect to="/activity" />;
    };
    return (
      <div className="welcome-view">
        {this.state.shouldRedirect && <Redirect to="/activity" />}
        <Modal
          show={this.state.showModal}
          onHide={this.closeModal}
          dialogClassName='welcome-view'
          style={{
            borderbackdropFilter: "blur(5px)",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        >
          <Modal.Body>
            <div className="welcome-main">
              <img src="img/Agree.png" className="welcome-logo" />
              <p className="welcome-para">Welcome to Ezzely!</p>
              <button className="welcome-button" onClick={this.closeModal}>
                continue to activity feed
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default Welcome;
