import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class Participant extends Component {

  imgStyle = (p) => {
    return p.excluded_at !== null ? {'border': '1px solid red', 'margin': '1px'} : {'margin': '1px'}
  }

  render() {
    const {id, participant} = this.props 
    const tooltip = <Tooltip id={id}><span className="p-xxs">{participant.employee_full_name}</span></Tooltip>;
    return (
      <OverlayTrigger overlay={tooltip} trigger={['hover', 'focus']} placement="top" delayShow={300} delayHide={150}>
        <Link to={`/employee/${participant.employee_id}`}>
          <img className="img-circle" style={this.imgStyle(participant)} alt="image" src={participant.avatar_url ?  participant.avatar_url : 'img/avatar_missing.png'} />
        </Link>
      </OverlayTrigger>
    )      
  }
}

export default Participant