import React, { Component } from "react";

import ReelThumbnail from "../components/reels/ReelThumbnail";

import "./Reels.css";

class UserReels extends Component {

  render() {
    return (
      <>
        <div className="logo">
        </div>
        <div className="main-all">
          {[1,2,3,4,5].map(i=> <ReelThumbnail key={i} src={i%2==0? 'img/dummy-thumbnail.jpg': 'img/alternative.jpg'}/>)}
        </div>
      </>
    );
  }
}
export default UserReels;
