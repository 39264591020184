// @flow
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { Button } from 'react-bootstrap';
import { Checkbox } from 'react-ui-icheck';
import { Link } from 'react-router-dom';
import EditRewardType from './EditRewardType';
import SweetAlert from 'sweetalert-react';

import './RewardsList.css'

class RewardTypeRaw extends Component {

  state = {
    showDeleteRewardTypeDialog: false
  }

  handleStatusChange = (evt) => {
    this.props.onUpdateActiveStatus(this.props.rewardType.id);
  }

  openEditModal() {
    this.refs.EditRewardModal.show();
  }

  renderRewardTypeLabel() {
    if (this.props.rewardType.type() === 'MONETARY') {
      return (
        <span className="label label-primary">{this.props.rewardType.type()}</span>
      )
    } else {
      return (
        <span className="label label-success">{this.props.rewardType.type()}</span>
      )
    }
  }

  renderResponsiblePerson() {
    const responsiblePerson = this.props.rewardType.responsible_person
    if (responsiblePerson !== undefined) {
      return (
        <Link to={`/employee/${responsiblePerson.id}`}>
          <img alt="image" className="img-circle" src={this.props.rewardType.responsiblePersonAvatar()} width="32px" height="32px" />
        </Link>
      )
    }
  }

  handleDeleteRewardTypeBtnClick = () => {
    this.setState({ showDeleteRewardTypeDialog: true });
  }

  handleDeleteRewardType = (rewardType) => {
    if (!rewardType) { return };
    this.setState({ showDeleteRewardTypeDialog: false });
    this.props.deleteRewardType(rewardType.id);
  }

  render() {
    const rewardType = this.props.rewardType;

    return (
      <tr>
        <td>
          <Checkbox
            checkboxClass="icheckbox_square-green"
            checked={rewardType.active}
            onChange={(evt) => this.handleStatusChange(evt)}
            label="&nbsp;"
          />
        </td>
        <td>
          {rewardType.title}
        </td>
        <td>
          {rewardType.description}
        </td>
        <td>
          {this.renderResponsiblePerson()}
        </td>
        <td>
          {this.renderRewardTypeLabel()}
        </td>
        <td>
          {rewardType.taxable ? "Yes" : "No"}
        </td>
        <td>
          {rewardType.redeemable ? "Yes" : "No"}
        </td>
        <td>
          {rewardType.unit}
        </td>
        <td>
          {rewardType.points}
        </td>
        <td>
          {rewardType.type() === 'IN-HOUSE' &&
            <div>
              <button
                className="btn btn-white btn-sm"
                style={{ marginRight: "5px" }}
                onClick={() => this.openEditModal()}>
                <FontAwesomeIcon icon={faPencilAlt} /> Edit
              </button>
              <button
                className="btn btn-white btn-sm"
                onClick={() => this.handleDeleteRewardTypeBtnClick()}>
                <FontAwesomeIcon icon={faTimes} /> Delete
              </button>
              <EditRewardType
                ref="EditRewardModal"
                rewardType={rewardType}
                onUpdateRewardType={this.props.onUpdateRewardType}
                pointsRatio={this.props.pointsRatio}
              />
              <SweetAlert
                show={this.state.showDeleteRewardTypeDialog}
                type="warning"
                title="Are you sure?"
                confirmButtonColor="#DD6B55"
                showCancelButton
                text="This operation can not be undone."
                onConfirm={() => this.handleDeleteRewardType(rewardType)}
                onCancel={() => this.setState({ showDeleteRewardTypeDialog: false })}
              />
            </div>
          }
        </td>
      </tr>
    )
  }

}


export default RewardTypeRaw;
