import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';

class IboxCollapse extends React.Component {

    collapsePanel(e) {
        e.preventDefault();
        var element = $(e.target);
        var ibox = element.closest('div.ibox');
        var button = element.closest("i");
        var content = ibox.find('div.ibox-content');
        content.slideToggle(200);
        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        ibox.toggleClass('').toggleClass('ibox-closed');
        setTimeout(function () {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
        }, 50);
    }

    render() {
        return (
            <div className={"ibox-tools " + this.props.className} >
              <a className="collapse-link" onClick={this.collapsePanel}>
                <FontAwesomeIcon icon={faChevronUp}/>
              </a>
            </div>
        )
    }
}

export default IboxCollapse
