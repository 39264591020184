import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage} from '@fortawesome/free-solid-svg-icons/faImage';
import { client } from '../../Client';
import { toastrNotification } from '../../../helpers/Toastr';
import ActivityPostFormModal from './ActivityPostFormModal';
import { withRouter } from 'react-router-dom';


class ActivityPostFormMobile extends Component {

  componentDidMount() {
    this.props.onRef(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.hash !== '#new_post' && this.refs.form.isOpened()) {
      this.refs.form.close();
    };
    if (this.props.location.hash == '#new_post' && !this.refs.form.isOpened()) {
      this.refs.form.open();
    };
  }
    
  componentWillUnmount() {
    this.props.onRef(undefined);
  } 
  
  openForm = (openDropZone) => {
    this.refs.form.open(openDropZone);
    this.changeLocation(this.props.location.pathname + '#new_post')
  }
  
  changeLocation = (location) => {
    this.props.history.push(location)
  }
  
  loadPost = (post) => {
    this.refs.form.loadPost(post);
    this.changeLocation(this.props.location.pathname + '#new_post');
  }
  
  
  render() {        
    return (
      <div className="ibox">
        <div className="ibox-content mobile-post-form" > 
          <div className="row">    
            <div className="col-md-12 df" style={{alignItems: "center"}}>
              <div className="user-avatar" onClick={() => this.openForm(false)}>
                <img alt="image" src={client.currentUser().avatar_image_url()}/>
              </div>
              <div className="preview-text" onClick={() => this.openForm(false)} style={{flex: "1 1 auto"}}>
                How’s Your Day Going?
              </div>
              <button className="btn-primary image-icon icon-button" type="button" onClick={() => this.openForm(true)}>
                <FontAwesomeIcon icon={faImage}/>
              </button> 
            </div>  
          </div> 
        </div>
        <ActivityPostFormModal 
          ref="form"
          sendPost={this.props.sendPost}
          sendPostDraft={this.props.sendPostDraft}
          sendPostUpdate={this.props.sendPostUpdate}
          selectedDepartment={this.props.selectedDepartment}
          changeLocation={this.changeLocation}
          currentPathname={this.props.location.pathname}
          rewardTags={this.props.rewardTags}
        />
      </div>
    );
  }
}

export default withRouter(ActivityPostFormMobile);
