import React, { Component } from 'react';
import { client } from '../components/Client';
import { companySettingsClient } from '../clients/CompanySettingsClient';
import AdminShiftRequestDetails from '../components/pick_up_shifts/AdminShiftRequestDetails';
import EmployeeShiftRequestDetails from '../components/pick_up_shifts/EmployeeShiftRequestDetails';

class ShiftRequestView extends Component {
  
    state = {
      isAccessible: false
    }
    
    componentDidMount() {
      this.checkUserAccess()
    }  
    
    checkUserAccess = () => {
      if ( client.adminLoggedIn() ) {
        this.setState({ isAccessible: true });
      } else {
        companySettingsClient.checkUserAccess("pickup_shift")
        .then((response) => {
          this.setState({ isAccessible: response.success });
          if ( !response.success ) {
            swal("Error!", "You dont have access to this page.", "error");
          }                             
        });
      }  
    }

    render() {
        return (
          <div>
            { this.state.isAccessible &&
                ( client.adminLoggedIn() ? 
                 <AdminShiftRequestDetails requestId={this.props.match.params.id} /> : 
                 <EmployeeShiftRequestDetails requestId={this.props.match.params.id} /> ) 
            }
          </div>  
        )
    }
}

export default ShiftRequestView
