import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faCalendarAlt} from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import UsStateSelector from '../form_components/UsStateSelector';
import FormField from '../../components/common/FormField';
import Input from '../form_components/Input';
import DateField from '../form_components/DateField';
import Select from '../form_components/Select';
import { client } from '../Client';
import moment from 'moment';
import { mediumDateFormat } from './../../helpers/Formatter';
import { toastrNotification } from '../../helpers/Toastr';
import Employee from '../../models/Employee';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

class AddressFields extends Component {

  state = {
    editMode: false,
    canSubmit: false,
    errorMessage: "",
    loading: false,
    fields: {},
    countries: []
  }

  openEditForm = () => {
    if (this.props.onChangeMode) this.props.onChangeMode(true);
    client.getCountries().then((countries) => {
      this.setState({editMode: true,
        countries: countries,
        fields: {
          nickname:   this.props.employee.nickname || '',
          date_of_birth: this.props.employee.date_of_birth,
          nationality:    this.props.employee.nationality || '',
          email: this.props.employee.email_value(),
          cell_phone: this.props.employee.cell_phone || '',
          home_phone: this.props.employee.home_phone || '',
          work_phone: this.props.employee.work_phone || '',
          address1:   this.props.employee.address1 || '',
          address2: this.props.employee.address2 || '',
          city: this.props.employee.city || '',
          state: this.props.employee.state || '',
          zip:     this.props.employee.zip || '',
          country:     this.props.employee.country || ''
        }
      });
    });
  }

  closeEditForm = () => {
    if (this.props.onChangeMode) this.props.onChangeMode(false);
    this.setState({editMode: false, errorMessage: ""});
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }
  
  onDateChange = (field, date) => {
    const fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields: fields });
  }
  
  formSubmit = (data) => {
    this.disableButton();
    this.setState({ loading: true });
    client.updateEmployee(this.props.employee.id, data).then((empl) => {
      if (empl.id) {
        let employee = new Employee(empl); 
        this.props.onReloadEmployee(employee);
        this.setState({ errorMessage: "" });
        toastrNotification({success: true, message: 'Updated successfully'});
        this.closeEditForm();
      } else {
        let formErrors = empl;
        if (formErrors.base) {
          this.setState({ errorMessage: formErrors.base });
        } else {
          this.setState({ errorMessage: "" });
        };
        try {
          this.refs.form.updateInputsWithError(formErrors);
          toastrNotification({ success: false, message: 'Unable to update' })
        } catch (error) {
          toastrNotification({ success: false, message: 'Unable to update: ' + formErrors.error})
        }
      }
      this.setState({ loading: false });
      this.enableButton();
    });
  }
        
  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };
  
  render() {    
      return (
        <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal">
          {this.state.errorMessage.length > 0 && 
          <div className="alert alert-danger">
            {this.state.errorMessage}
          </div>
          }
          <ul className="list-unstyled">
          <br/>
            <FormField label="Nickname">
              { this.state.editMode ?
              <Input value={this.state.fields.nickname} onChange={this.onInputChange} className='form-control' name='nickname'/>
              :
              <div className="details-item-text">{this.props.employee.nickname || '-'}</div>
              }
            </FormField>
            <FormField label="Birth Date">
              { this.state.editMode ?
              <DateField name="date_of_birth" className="form-control" minDate={moment().subtract(75, 'years')} maxDate={moment().subtract(14, 'years')} value={this.state.fields.date_of_birth} onChange={(date) => this.onDateChange('date_of_birth', date)} />
              :
                <div className="details-item-text">
                { this.props.employee.date_of_birth ? <div>
                  <FontAwesomeIcon icon={faCalendarAlt}/>&nbsp;
                  {mediumDateFormat(this.props.employee.date_of_birth)}&nbsp;
                  / &nbsp;<FontAwesomeIcon icon={faUser} />&nbsp;{this.props.employee.age()}
                </div>
                : '-' }
                </div>
              }
            </FormField>
            <FormField label="Nationality">
              { this.state.editMode ?
              <Input value={this.state.fields.nationality} onChange={this.onInputChange} className='form-control' name='nationality'/>
              :
              <div className="details-item-text">{this.props.employee.nationality || '-'}</div>
              }
            </FormField>
            <FormField label="Work Email">
              { this.state.editMode ?         
                <Input value={this.state.fields.email || ''} className='form-control' name="email" onChange={this.onInputChange} />
                :
                <div className="details-item-text">{this.props.employee.email_value()}</div>
              }  
            </FormField>
            <FormField label="Mobile Phone">
              { this.state.editMode ?
              <Input name="cell_phone" className="form-control" value={this.state.fields.cell_phone} onChange={this.onInputChange}/>
              :
              <div className="details-item-text">{ this.props.employee.cell_phone ? <span><FontAwesomeIcon icon={faPhone}/>&nbsp;{this.props.employee.cell_phone} </span> : '-'}</div>
              }
            </FormField>
            <FormField label="Home Phone">
              { this.state.editMode ?
              <Input value={this.state.fields.home_phone} onChange={this.onInputChange} className='form-control' name='home_phone'/>
              :
              <div className="details-item-text">{ this.props.employee.home_phone ? <span><FontAwesomeIcon icon={faPhone}/>&nbsp;{this.props.employee.home_phone} </span> : '-'}</div>
              }
            </FormField>
            <FormField label="Work Phone">
              { this.state.editMode ?
              <Input value={this.state.fields.work_phone} onChange={this.onInputChange} className='form-control' name='work_phone'/>
              :
              <div className="details-item-text">{ this.props.employee.work_phone ? <span><FontAwesomeIcon icon={faPhone}/>&nbsp;{this.props.employee.work_phone} </span> : '-'}</div>
              }
            </FormField>
            <hr />
            { this.state.editMode ?
              <div>
                <FormField label="Address 1">
                  <Input value={this.state.fields.address1} onChange={this.onInputChange} className='form-control' name='address1'/>
                </FormField>
                <FormField label="Address 2">
                  <Input type='text' value={this.state.fields.address2} onChange={this.onInputChange} className='form-control' name='address2'/>
                </FormField>
                <FormField label="City">
                  <Input value={this.state.fields.city} onChange={this.onInputChange} className='form-control' name='city'/>
                </FormField>
                <FormField label="State">
                  { this.state.fields.country === 'US' &&
                    <UsStateSelector onChange={this.onInputChange} selected={this.state.fields.state} className='form-control' name='state'/>
                  }                  
                  { this.state.fields.country !== 'US' &&
                    <Input value={this.state.fields.state} onChange={this.onInputChange} className='form-control' name='state'/>
                  }                  
                </FormField>
                <FormField label="Zip">
                  <Input value={this.state.fields.zip} onChange={this.onInputChange} className='form-control' name='zip'/>
                </FormField>
                <FormField label="Country">
                  <Select
                    value={this.state.fields.country}
                    onChange={this.onInputChange}
                    options={this.state.countries}
                    clearable={false}
                    className="form-control"
                    name='country'
                  />                  
                </FormField>
                <FormField label="Time Zone">
                  <div className="details-item-text">{this.props.employee.time_zone}</div>
                </FormField>
              </div>
              :
              <div>
                <FormField label="Address">
                  <div className="details-item-text">
                    { (this.props.employee.address1 || this.props.employee.city || this.props.employee.state) ?
                      <div>
                        <div>{this.props.employee.address1}</div>
                        {this.props.employee.address2 &&
                          <div>{this.props.employee.address2}</div>
                        }
                        <div>{[this.props.employee.city, this.props.employee.state, this.props.employee.zip, this.props.employee.country].filter(function(el){return el}).join(', ')}</div>
                      </div>
                      :
                      '-'
                    }
                  </div>
                </FormField> 
                <FormField label="Time Zone">
                  <div className="details-item-text">{this.props.employee.time_zone}</div>
                </FormField>                
              </div>
            }
          </ul>
          { this.state.editMode &&
          <div className="modal-footer">
            <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Save</LaddaButton>
            <button onClick={this.closeEditForm} className="btn btn-sm btn-default">Cancel</button>
          </div>}
        </Formsy>
      )

  }
}

export default AddressFields;
