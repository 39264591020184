import React, { Component } from "react";

import './ReelThumbnail.css';

class ReelThumbnail extends Component {
    render() {
        return (
            <div className="reel-thumbnail">
                <img src={this.props.src}></img>
                <div className="reel-info">
                    <div className="reel-title">{this.props.title || 'Video Title'}</div>
                    <div className="reel-stats">{this.props.likes || '0'} likes</div>
                </div>
            </div>
        );
    }
}
export default ReelThumbnail;
