import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';

class ConversationClient {
  getConversations(page, search = "") {
    return client.callApi(Config.API_BASE + '/conversations.json?page=' + page + '&search=' + search)
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }

  getConversation(conversationId) {
    return client.callApi(this.conversationUrl(conversationId))
      .catch((error) => {
        client.handleApiError(error);
        return [];
    });
  }

  createConversation(recipientId) {
    return client.callApi(Config.API_BASE + '/conversations', {}, 'POST', {recipient_id: recipientId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  conversationUrl(conversationId) {
    return Config.API_BASE + '/conversations/'+ conversationId +'.json';
  }

}

export const conversationClient = new ConversationClient();
