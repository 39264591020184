import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift';
import './RedeemDialog.css';

class RewardTypeItem extends Component {
  
  itemClassName = () => {
    let classname = 'redeem-type-item';
    if (this.isSelected()) classname += ' selected';
    if (this.props.employeePoints < this.props.rewardType.points) classname += ' inactive';
    return classname;
  }
  
  selectRewardsType(evt) {
    evt.preventDefault();
    if (this.props.employeePoints >= this.props.rewardType.points) {
      this.props.onRewardsTypeSelect(this.props.rewardType)
    } 
  }
  
  isSelected() {
    return (this.props.selectedRewardsType !== null && this.props.selectedRewardsType.id === this.props.rewardType.id)
  }

  render() {
    const reward_popover = (
      <Popover id='reward-popover' style={{zIndex:10000}}>
        <span>{this.props.rewardType.description}</span>
      </Popover>
    );
        
    return (
      <div className="row">
        <div className='col-sm-12'>
          <div className={this.itemClassName()} onClick={(evt) => this.selectRewardsType(evt)}>
            <div className="pull-left">
              <FontAwesomeIcon icon={faGift} size='2x' className='m-sm'/>
            </div>
            <div className="pull-left">
              <h4>{this.props.rewardType.title} &nbsp;                         
                <OverlayTrigger trigger={['focus']} placement="top" overlay={reward_popover}>
                  <a href="#" onClick={function(evt){evt.preventDefault();}}><FontAwesomeIcon icon={faQuestionCircle} /></a>
                </OverlayTrigger>                        
              </h4>
              <span><small>Each {this.props.rewardType.points} points = {this.props.rewardType.unit}.</small></span>
            </div>
            { this.isSelected() &&
              <div className="pull-right">
                <FontAwesomeIcon icon={faAngleRight} className="fa redeem-type-icon" size="3x"/>
              </div>
            }  
            <div className="clearfix"/>
          </div>
        </div>
      </div>
    )
  }

}

export default RewardTypeItem;
