// @flow
import React, { Component } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import ActivityPostCommentsContainer from './ActivityPostCommentsContainer';
import ActivityPostCommentNew from './ActivityPostCommentNew';
import UserPopover from './UserPopover';
import { Link } from 'react-router-dom';
import RemovePostLink from './RemovePostLink';
import ActivityMessageBox from './ActivityMessageBox';
import { client } from '../Client';
import moment from 'moment';
import Coin from '../common/Coin';
import { Element, animateScroll as scroll, scroller } from 'react-scroll';
import ActivityPostAttachments from './ActivityPostAttachments';
import ActivityPostAttachment from './ActivityPostAttachment';
import Employee from '../../models/Employee';

import './ActivityPost.css'
import { activityRecognitionTagClient } from '../../clients/ActivityRecognitionTagClient';
import BadgePopover from '../badges/BadgePopover';
import { badgesClient } from '../../clients/BadgesClient';

class ActivityPost extends Component {

  state = {
    showCommentBox: false,
    comments: this.props.post.comments,
    showAllComments: false,
    editableComment: null,
    postPointsUpdated: false,
    badgesEnabled:false,
    adminBadgeEnabled:false,
  }

  componentWillReceiveProps = (newProps) => {
    this.setState({ comments: newProps.post.comments });
    if (newProps.post.points !== this.props.post.points) {
      this.setState({ postPointsUpdated: true })
    }
  }

  componentDidMount = () => { 
    badgesClient.badgesConfig()
    .then(resp=>{
      let badgesEnabled=false, adminBadgeEnabled=false;
      if(resp?.badges_enabled) badgesEnabled=true;
      if(resp?.admin_badge) adminBadgeEnabled=true;
      this.setState({badgesEnabled,adminBadgeEnabled});
    });
  }

  componentWillUnmount = () => {
    if (this.state.timeout) clearTimeout(this.state.timeout);
  }

  showCommentBox = () => {
    this.setState({ showCommentBox: true });
  }

  hideCommentBox = () => {
    this.setState({ showCommentBox: false });
  }

  toggleCommentBox = () => {
    if (this.state.showCommentBox) {
      this.hideCommentBox();
    } else {
      const parentComments = this.props.post.comments.filter(c => c.parent_id != null)
      if (parentComments.length > 3 & !this.state.showAllComments)
        this.showAllComments();
      this.showCommentBox();
      if (this.props.post.comments.length > 1)
        scroller.scrollTo(('comment-box' + this.props.post.id), { duration: 1000, delay: 50, offset: -150, smooth: 'easeInOutQuart' });
    }
  }

  sendPostLike = () => {
    const postId = this.props.post.id;
    this.props.channel.perform('activity_post_like', { post_id: postId });
  }

  sendRemovePost = () => {
    const postId = this.props.post.id;
    this.props.channel.perform('remove_activity_post', { post_id: postId });
  }

  sendReward = (rewardTag) => {
    const postId = this.props.post.id;
    this.props.channel.perform('activity_post_reward', { post_id: postId, tag: rewardTag });
  }

  editPost = () => {
    this.props.onEditPost(this.props.post)
  }

  showAllComments = () => {
    this.setState({ showAllComments: true });
    this.showCommentBox();
  }

  showLessComments = () => {
    this.setState({ showAllComments: false })
  }

  postAuthorIsLeader = () => {
    const leaders = this.props.leaders;
    return (leaders && leaders.length > 0 && leaders[0].id === this.props.post.author.id)
  }

  renderComments() {
    let comments;
    if (this.state.showAllComments) {
      comments = this.state.comments;
    } else {
      comments = this.state.comments.filter(comment => comment.parent_id == null).slice(0, 3);
    }
    if (comments.length > 0) {
      return (
        <div className="social-footer">
          {
            comments.filter(comment => comment.parent_id == null).map((comment) => (
              <ActivityPostCommentsContainer
                key={this.props.containerId + 'comments_container' + comment.id}
                comment={comment}
                post={this.props.post}
                channel={this.props.channel}
                containerId={this.props.containerId}
              />
            ))
          }
          {this.renderShowAllCommentsLink()}
        </div>
      )
    }
  }

  renderNewCommentBox() {
    if (this.state.showCommentBox) {
      return (
        <div className="social-footer">
          <ActivityPostCommentNew
            post={this.props.post}
            ref='comment_editor'
            channel={this.props.channel}
            onHideCommentBox={this.hideCommentBox}
          />
        </div>
      )
    }
  }

  getImageURL = (tagName) => {
    return activityRecognitionTagClient.getTagImageURL(tagName)
  }

  renderTagForRecognition = (recognition) => {
    if (recognition.tag_name === 'Special') return (
      <div className="tag-image" style={{ display: 'flex', justifyContent: 'center' }}>
        <svg style={{ fill: 'currentcolor', height: '100%' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M288 358.3c13.98-8.088 17.53-30.04 28.88-41.39c11.35-11.35 33.3-14.88 41.39-28.87c7.98-13.79 .1658-34.54 4.373-50.29C366.7 222.5 383.1 208.5 383.1 192c0-16.5-17.27-30.52-21.34-45.73c-4.207-15.75 3.612-36.5-4.365-50.29c-8.086-13.98-30.03-17.52-41.38-28.87c-11.35-11.35-14.89-33.3-28.87-41.39c-13.79-7.979-34.54-.1637-50.29-4.375C222.5 17.27 208.5 0 192 0C175.5 0 161.5 17.27 146.3 21.34C130.5 25.54 109.8 17.73 95.98 25.7C82 33.79 78.46 55.74 67.11 67.08C55.77 78.43 33.81 81.97 25.72 95.95C17.74 109.7 25.56 130.5 21.35 146.2C17.27 161.5 .0008 175.5 .0008 192c0 16.5 17.27 30.52 21.34 45.73c4.207 15.75-3.615 36.5 4.361 50.29C33.8 302 55.74 305.5 67.08 316.9c11.35 11.35 14.89 33.3 28.88 41.4c13.79 7.979 34.53 .1582 50.28 4.369C161.5 366.7 175.5 384 192 384c16.5 0 30.52-17.27 45.74-21.34C253.5 358.5 274.2 366.3 288 358.3zM112 192c0-44.27 35.81-80 80-80s80 35.73 80 80c0 44.17-35.81 80-80 80S112 236.2 112 192zM1.719 433.2c-3.25 8.188-1.781 17.48 3.875 24.25c5.656 6.75 14.53 9.898 23.12 8.148l45.19-9.035l21.43 42.27C99.46 507 107.6 512 116.7 512c.3438 0 .6641-.0117 1.008-.0273c9.5-.375 17.65-6.082 21.24-14.88l33.58-82.08c-53.71-4.639-102-28.12-138.2-63.95L1.719 433.2zM349.6 351.1c-36.15 35.83-84.45 59.31-138.2 63.95l33.58 82.08c3.594 8.797 11.74 14.5 21.24 14.88C266.6 511.1 266.1 512 267.3 512c9.094 0 17.23-4.973 21.35-13.14l21.43-42.28l45.19 9.035c8.594 1.75 17.47-1.398 23.12-8.148c5.656-6.766 7.125-16.06 3.875-24.25L349.6 351.1z" /></svg>
      </div>
    );
    else return (
      <img src={recognition.tag_icon_url || this.getImageURL(recognition.tag_name)} alt={'#' + recognition.tag_name} className="tag-image" />
    );
  }

  renderPostRecognitionsTag() {
    const post = this.props.post;
    if (post.recognitions.length > 0) {
      return (
        <span>
          <span key={this.props.containerId + post.recognitions[0].tag_id} to='' className="hashtag">
            {/*#{post.recognitions[0].tag_name}*/
              this.renderTagForRecognition(post.recognitions[0])}
          </span>
        </span>
      )
    } else if (post.department_recognitions.length > 0) {
      return (
        <span>
          <span key={this.props.containerId + post.department_recognitions[0].tag_id} to='' className="hashtag">
            {/*#{post.department_recognitions[0].tag_name}*/
              this.renderTagForRecognition(post.department_recognitions[0])}
          </span>
        </span>
      )
    } else {
      return (
        <span></span>
      )
    }
  }

  renderPostRewardsPoints() {
    if (this.props.post.points !== 0) {
      return (
        <div className={this.state.postPointsUpdated ? "text-right animated rotateIn" : "text-right"}>
          <Coin width="24px" /> <b>{this.props.post.points}</b>
        </div>
      )
    }
  }

  renderPostRecognitionsPoints() {
    const post = this.props.post;
    if (post.recognitions.length > 0 && post.recognitions[0].points > 0) {
      return (
        <span>
          <Coin width="16px" /><b>{post.recognitions[0].points}</b>
        </span>
      )
    }
  }

  renderPostRecognitions() {
    const post = this.props.post;
    if (post.recognitions.length > 0) {
      if (post.recognitions.length <= 2) {
        const recognition = post.recognitions[0];
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            &nbsp;{this.renderPostRecognitionsTag()}&nbsp;
            {this.renderPostRecognitionsPoints()}&nbsp;
            <FontAwesomeIcon icon={faCaretRight} />&nbsp;
            {post.recognitions.map((recognition) => (
              <span key={this.props.containerId + 'recognition' + recognition.id}>
                <UserPopover id={'rec-' + recognition.id} employee={recognition.employee} />
              </span>
            )
            )}
          </span>
        )
      } else {
        const moreRecognitions = post.recognitions.length - 1;
        const popover = (
          <Popover id={'recognitions-popover-' + this.props.post.id} className="popover-recognitions">
            {
              post.recognitions.map((recognition) => (
                <div key={this.props.containerId + 'recognition' + recognition.id} style={{ margin: '2px 2px' }}>
                  <img alt={recognition.employee.full_name()} src={recognition.employee.avatar_image_url()} height='32' className="img-circle" />
                  &nbsp;
                  <small>{recognition.employee.full_name()}</small>
                </div>
              ))
            }
          </Popover>
        );
        return (
          <span>
            &nbsp;{this.renderPostRecognitionsTag()}&nbsp;
            {this.renderPostRecognitionsPoints()}&nbsp;
            <FontAwesomeIcon icon={faCaretRight} />&nbsp;
            <OverlayTrigger trigger={['hover', 'focus', 'click']} placement="bottom" overlay={popover} rootClose={true}>
              <a className="small recognition">{post.recognitions[0].employee.full_name()} and {moreRecognitions} others...</a>
            </OverlayTrigger>
          </span>
        )
      }
    } else if (post.department_recognitions.length > 0) {
      return (
        <span>
          &nbsp;{this.renderPostRecognitionsTag()}&nbsp;
          {this.renderPostRecognitionsPoints()}&nbsp;
          <FontAwesomeIcon icon={faCaretRight} />&nbsp;
          {
            post.department_recognitions.map((recognition) => (
              <span key={this.props.containerId + 'recognition' + recognition.id}>
                <a className="recognition">{recognition.department.title}</a>
              </span>
            ))
          }
        </span>
      )
    } else {
      return (
        <span></span>
      )
    }
  }

  renderLikesCount() {
    if (this.props.post.likes.length > 0) {
      const morelikes = this.props.post.likesNumber - 5;
      const txt = this.props.post.likesNumber;
      const popover = (
        <Popover id={'like-popover-' + this.props.post.id} className="popover-like">
          {
            this.props.post.likes.map((like) => (
              <div key={this.props.containerId + 'like' + like.id} style={{ margin: '2px 2px' }}>
                <img alt={like.employee.full_name()} src={like.employee.avatar_image_url()} height='32' className="img-circle" />
                &nbsp;
                <small>{like.employee.full_name()}</small>
              </div>
            ))
          }
          {morelikes > 0 &&
            <div className="text-center"><small>and {morelikes} more ...</small></div>
          }
        </Popover>
      );
      return (
        <div>
          <OverlayTrigger trigger={['click']} placement="right" overlay={popover} rootClose={true}>
            <a className="large-text"><FontAwesomeIcon icon={faThumbsUp} size="lg" /> {txt}</a>
          </OverlayTrigger>
        </div>
      )
    }
  }

  renderLikeButton() {
    return (
      <button className={`btn btn-white btn-xs ${(this.props.post.liked ? 'ez-marked' : '')}`} disabled={!this.props.post.confirmed} onClick={() => this.sendPostLike()} >
        <FontAwesomeIcon icon={faThumbsUp} /> Like this!
      </button>
    );
  }

  isAuthor() {
    return this.props.post.author.id === client.currentUser().id
  }

  renderRemovePostLink() {
    return (
      <RemovePostLink onRemovePost={this.sendRemovePost} post={this.props.post} />
    )
  }

  renderRewardButton() {
    const { post, rewardTags } = this.props;
    let disabled = (post.rewarded || post.is_copy || post.author.id == client.currentUser().id || (rewardTags && rewardTags.length == 0));

    return (
      <div className="dropdown">
        <button
          data-toggle="dropdown"
          className={`dropdown-toggle btn btn-white btn-xs ${(post.rewarded ? 'ez-marked' : '')}`}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faStar} /> Reward
        </button>
        <ul className="dropdown-menu m-t-xs" id={'reward' + this.props.post.id}>
          {rewardTags && rewardTags.map((tag) => {
            return (
              <li
                key={tag.id}
                onClick={() => this.sendReward(tag.name)}
              >
                <a style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={tag.icon_url || this.getImageURL(tag.name)} alt={tag.name} className="tag-image" style={{ width: '30px', marginRight: '5px' }} />
                  <strong>{tag.name} {tag.points > 0 && <Coin width="16px" />} {tag.points > 0 ? tag.points : ''} </strong>
                </a>
              </li>
            )
          }
          )}
        </ul>
      </div>
    )
  }

  renderRewardsCount() {
    const post = this.props.post;
    if (post.rewards.length > 0) {
      const moreRewards = post.rewards_number - 5;
      const txt = post.rewards_number;
      const popover = (
        <Popover id={'rewards-popover-' + post.id} className="popover-reward">
          {
            post.rewards.map((reward) => (
              <div key={this.props.containerId + 'reward' + reward.id} className="popover-reward-item">
                <div className="reward-author-avatar">
                  <img alt={reward.author.full_name} src={reward.author.avatar_url} height='32' className="img-circle" />
                </div>
                <div>
                  <div className="reward-author-name">{reward.author.full_name}</div>
                  <div className="reward-tag"><a>#{reward.tag}</a></div>
                </div>
              </div>
            ))
          }
          {moreRewards > 0 &&
            <div className="text-center"><small>and {moreRewards} more ...</small></div>
          }
        </Popover>
      );
      return (
        <div>
          <OverlayTrigger trigger={['click']} placement="right" overlay={popover} rootClose={true}>
            <a className='large-text'><FontAwesomeIcon icon={faStar} size="lg" /> {txt}</a>
          </OverlayTrigger>
        </div>
      )
    }
  }

  renderShowAllCommentsLink = () => {
    const parentComments = this.props.post.comments.filter(c => c.parent_id == null)
    if (parentComments.length > 3 && !this.state.showAllComments) {
      return (
        <div className="p-xs b-r-xs text-right" onClick={() => this.showAllComments()} style={{ cursor: 'pointer', color: "#337ab7", paddingBottom: '0' }}>
          Show all <span className="badge" style={{ backgroundColor: "#337ab7", color: "#fff" }}>{parentComments.length}</span> comments
        </div>
      )
    }
  }

  renderLessCommentsLink = () => {
    const parentComments = this.props.post.comments.filter(c => c.parent_id == null)
    if (parentComments.length > 3 && this.state.showAllComments) {
      return (
        <div className="p-xs b-r-xs text-center" onClick={() => this.showLessComments()} style={{ cursor: 'pointer', color: "#337ab7" }}>
          Show less comments
        </div>
      )
    }
  }

  render() {
    const containerClassName = this.props.post.is_copy ? "social-feed-box sponsored-post-box" : "social-feed-box";

    return (
      <div className={containerClassName}>
        <div className="social-avatar">
          <div className={this.postAuthorIsLeader() ? 'pull-left leader' : 'pull-left'} style={{position: 'relative'}}>
            <img alt="image" src={this.props.post.avatar_image_url()} className="img-circle" />
            {this.state.badgesEnabled && this.props.post.author?.badge && <BadgePopover badge={this.props.post.author.badge} adminBadgeEnabled={this.state.adminBadgeEnabled}/>}
          </div>
          <div className="media-body">
            <div className="pull-right dropdown">
              {this.renderPostRewardsPoints()}
              {this.props.post.is_copy &&
                <span className="sponsored-label">From your friends at Ezzely</span>
              }
            </div>
            <div style={{ marginTop: 0, display: 'flex', alignItems: 'center' }}>
              <span className="post-title">{this.props.post.full_name()}</span>
              {this.renderPostRecognitions()}
            </div>
            <small className="text-muted">{this.props.post.created_date()}</small>
          </div>
        </div>
        <div className="social-body">
          <div>
            <div style={{ marginTop: '5px', marginBottom: '20px' }}>
              <ActivityMessageBox content={this.props.post.message} />
            </div>
          </div>
          {this.props.post.attachments.length == 1 &&
            <ActivityPostAttachment attachment={this.props.post.attachments[0]} />
          }
          {this.props.post.attachments.length > 1 &&
            <ActivityPostAttachments attachments={this.props.post.attachments} post={this.props.post} />
          }
          <div className="post-buttons post-counters">
            {this.props.post.rewards?.length > 0 &&
              <div className="post-reward-button">
                <div className="rewards-counter">
                  {this.renderRewardsCount()}
                </div>
              </div>
            }
            {this.props.post.likes?.length > 0 &&
              <div className="post-like-button">
                <div className="likes-counter">
                  {this.renderLikesCount()}
                </div>
              </div>
            }
          </div>

          <div className="post-buttons">
            <div className="post-reward-button">
              <div>
                {this.renderRewardButton()}
              </div>
            </div>
            <div className="post-like-button">
              <div>
                {this.renderLikeButton()}
              </div>
            </div>
            {(!client.noComments() || client.adminLoggedIn()) &&
              <div className="post-comment-button">
                <button className={`btn btn-white btn-xs ${(this.state.showCommentBox ? 'ez-marked' : '')}`} disabled={!this.props.post.confirmed} onClick={() => this.toggleCommentBox()}><FontAwesomeIcon icon={faComments} /> Comment</button>
              </div>
            }
            {(this.isAuthor() || client.adminLoggedIn()) &&
              <div className="post-delete-button">
                {this.props.post.confirmed &&
                  <div className="dropdown">
                    <button data-toggle="dropdown" className="dropdown-toggle btn-white dropdown-button">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                    <ul className="dropdown-menu m-t-xs activity-dropdown-menu" id={'edit' + this.props.post.id}>
                      {this.isAuthor() &&
                        moment().diff(moment(this.props.post.created_at), 'minutes') <= 10 &&
                        this.props.post.recognitions.length == 0 &&
                        <li>
                          <a onClick={() => this.editPost()}>Edit</a>
                        </li>
                      }
                      {this.renderRemovePostLink()}
                    </ul>
                  </div>
                }
              </div>
            }
          </div>
        </div>
        {this.renderComments()}
        <Element name={'comment-box' + this.props.post.id} className="element">
          {this.renderNewCommentBox()}
        </Element>
      </div>
    )
  }

}

export default ActivityPost;
