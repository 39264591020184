import React, { Component } from 'react';
import SweetAlert from 'sweetalert-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

import 'sweetalert/dist/sweetalert.css';

class RemoveCourseLink extends Component {

  state = {
    show: false
  }

  handleClick() {
    this.setState({ show: true })
  }

  handleCourseRemove = () => {
    this.props.onRemoveCourse(this.props.course.id);
    this.setState({ show: false });
  }

  render() {
      return (
        <div className="m-t text-right">
          {!this.props.course.is_license &&
            <a className="btn btn-xs btn-primary" onClick={() => this.handleClick()}><FontAwesomeIcon icon={faTimes}/> Delete</a>
          }
          <SweetAlert
            show={this.state.show}
            type="warning"
            title="Are you sure to remove this course?"
            confirmButtonColor="#DD6B55"
            showCancelButton
            text="This operation can not be undone."
            onConfirm={() => this.handleCourseRemove()}
            onCancel={() => this.setState({ show: false })}
          />
        </div>
      )
  }

}

export default RemoveCourseLink;
