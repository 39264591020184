import { faPhotoVideo } from "@fortawesome/free-solid-svg-icons/faPhotoVideo";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faGift } from "@fortawesome/free-solid-svg-icons/faGift";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal, Panel } from "react-bootstrap";
import ActivityTextBox from "./ActivityTextBox";

import './ActivityPostFormModal.css';
import ActivityPostAttachments from "./ActivityPostAttachments";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import ActivityRecognitionsBox from "./ActivityRecognitionsBox";
import Coin from "../../common/Coin";
import { activityRecognitionTagClient } from "../../../clients/ActivityRecognitionTagClient";

class ActivityPostFormModal extends React.Component {
    state = {
        showRecognitionsView: false,
        selectedTag: '',
        recognitions: [],
        recognitionsSaved: false,
    }

    constructor(props) {
        super(props);
        //this.postAttachmentsRef = React.createRef();
        this.state.selectedTag = props.getSelectedTag();
    }

    changeSelectedTag(value) {
        this.setState({ selectedTag: value })
        this.props.handleTagChange(value)
    }

    getImageURL = (tagName) => {
        return activityRecognitionTagClient.getTagImageURL(tagName)
    }

    renderRecognitionsView = () => {
        const constainerClass = 'content-container';
        return (
            <div>
                <div className={constainerClass}>
                    <Panel className="recognize-fields" expanded collapsible="true" onToggle={() => { }} >
                        <Panel.Collapse>
                            {this.state.selectedTag && this.state.selectedTag !== '' && this.state.recognitions?.length < 1 &&
                                <div style={{ color: '#e43c29', paddingBottom: '5px' }}>
                                    Please select a Colleague.
                                </div>
                            }
                            <div className="form-group">
                                <div>
                                    <ActivityRecognitionsBox
                                        ref={this.props.recognitionsEditorRef}
                                        placeholder="Start typing colleague name"
                                        validateRecognitionsBox={this.props.validateRecognitionsBox}
                                        selectedTag={this.props.selectedTag}
                                        autoFocus={this.props.selectedTag != 'Special'}
                                        selectedEmployees={this.state.recognitions}
                                        employeesChange={this.selectedEmployeesChange}
                                    />
                                    {this.props.recognitionsBoxIsValid ?
                                        ""
                                        :
                                        <span className="form-error">Select the person you want to reward</span>
                                    }
                                </div>
                            </div>
                            <div className="tags-container">
                                {this.props.rewardTags.map(tag => {
                                    return (
                                        <span className={`hashtag-badge-new${this.state.selectedTag === tag.name ? ' selected' : ''}`} key={"tag" + tag.id} onClick={() => this.changeSelectedTag(tag.name)}>
                                            <img src={tag.icon_url || this.getImageURL(tag.name)} alt="IMAGE" className="tag-image" />
                                            <label>
                                                {tag.name}
                                            </label>
                                            <div className="points">
                                                {tag.points > 0 &&
                                                    <span> <Coin width="16px" /> {tag.points}</span>
                                                }
                                            </div>
                                        </span>
                                    )
                                })
                                }
                                <span className={`hashtag-badge-new${this.state.selectedTag === 'Special' ? ' selected' : ''}`} onClick={() => this.changeSelectedTag('Special')}>
                                    <div className="tag-image" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <svg style={{ fill: 'currentcolor', height: '100%' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M288 358.3c13.98-8.088 17.53-30.04 28.88-41.39c11.35-11.35 33.3-14.88 41.39-28.87c7.98-13.79 .1658-34.54 4.373-50.29C366.7 222.5 383.1 208.5 383.1 192c0-16.5-17.27-30.52-21.34-45.73c-4.207-15.75 3.612-36.5-4.365-50.29c-8.086-13.98-30.03-17.52-41.38-28.87c-11.35-11.35-14.89-33.3-28.87-41.39c-13.79-7.979-34.54-.1637-50.29-4.375C222.5 17.27 208.5 0 192 0C175.5 0 161.5 17.27 146.3 21.34C130.5 25.54 109.8 17.73 95.98 25.7C82 33.79 78.46 55.74 67.11 67.08C55.77 78.43 33.81 81.97 25.72 95.95C17.74 109.7 25.56 130.5 21.35 146.2C17.27 161.5 .0008 175.5 .0008 192c0 16.5 17.27 30.52 21.34 45.73c4.207 15.75-3.615 36.5 4.361 50.29C33.8 302 55.74 305.5 67.08 316.9c11.35 11.35 14.89 33.3 28.88 41.4c13.79 7.979 34.53 .1582 50.28 4.369C161.5 366.7 175.5 384 192 384c16.5 0 30.52-17.27 45.74-21.34C253.5 358.5 274.2 366.3 288 358.3zM112 192c0-44.27 35.81-80 80-80s80 35.73 80 80c0 44.17-35.81 80-80 80S112 236.2 112 192zM1.719 433.2c-3.25 8.188-1.781 17.48 3.875 24.25c5.656 6.75 14.53 9.898 23.12 8.148l45.19-9.035l21.43 42.27C99.46 507 107.6 512 116.7 512c.3438 0 .6641-.0117 1.008-.0273c9.5-.375 17.65-6.082 21.24-14.88l33.58-82.08c-53.71-4.639-102-28.12-138.2-63.95L1.719 433.2zM349.6 351.1c-36.15 35.83-84.45 59.31-138.2 63.95l33.58 82.08c3.594 8.797 11.74 14.5 21.24 14.88C266.6 511.1 266.1 512 267.3 512c9.094 0 17.23-4.973 21.35-13.14l21.43-42.28l45.19 9.035c8.594 1.75 17.47-1.398 23.12-8.148c5.656-6.766 7.125-16.06 3.875-24.25L349.6 351.1z" /></svg>
                                    </div>
                                    <label>
                                        {/*<Radio value='Special' style={{ 'marginTop': '5px' }} />*/}
                                        Special &nbsp;
                                    </label>
                                    <div className="points">
                                        <span style={{ marginRight: '5px' }}><Coin width="16px" /></span>
                                        <input type='number' min='1' disabled={this.props.selectedTag != 'Special'} value={this.props.specialPoints} onChange={this.props.updateSpecialPoints} className="custom-points-input" />
                                    </div>
                                </span>

                            </div>
                        </Panel.Collapse>
                    </Panel>
                </div>
                {this.state.selectedTag && this.state.selectedTag !== '' && this.state.recognitions?.length > 0 &&
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <button className="btn btn-outline" onClick={() => this.toggleRecognitionsView(true)}><FontAwesomeIcon icon={faCheck} /> Done</button>
                    </div>
                }
            </div>
        );
    }

    clear() {
        this.setState({
            showRecognitionsView: false,
            selectedTag: '',
            recognitions: [],
            recognitionsSaved: false,
        });
    }

    getSelectedTagImageURL = (tagName) => {
        const tag = this.props.rewardTags?.find(tag => tag.name === tagName)
        if (tag) return tag.icon_url || this.getImageURL(tag.name)
    }

    renderTagForRecognitionsRow = () => {
        if (this.state.selectedTag === 'Special') return (
            <div className="tag-image" style={{ display: 'flex', justifyContent: 'center' }}>
                <svg style={{ fill: 'currentcolor', height: '100%' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M288 358.3c13.98-8.088 17.53-30.04 28.88-41.39c11.35-11.35 33.3-14.88 41.39-28.87c7.98-13.79 .1658-34.54 4.373-50.29C366.7 222.5 383.1 208.5 383.1 192c0-16.5-17.27-30.52-21.34-45.73c-4.207-15.75 3.612-36.5-4.365-50.29c-8.086-13.98-30.03-17.52-41.38-28.87c-11.35-11.35-14.89-33.3-28.87-41.39c-13.79-7.979-34.54-.1637-50.29-4.375C222.5 17.27 208.5 0 192 0C175.5 0 161.5 17.27 146.3 21.34C130.5 25.54 109.8 17.73 95.98 25.7C82 33.79 78.46 55.74 67.11 67.08C55.77 78.43 33.81 81.97 25.72 95.95C17.74 109.7 25.56 130.5 21.35 146.2C17.27 161.5 .0008 175.5 .0008 192c0 16.5 17.27 30.52 21.34 45.73c4.207 15.75-3.615 36.5 4.361 50.29C33.8 302 55.74 305.5 67.08 316.9c11.35 11.35 14.89 33.3 28.88 41.4c13.79 7.979 34.53 .1582 50.28 4.369C161.5 366.7 175.5 384 192 384c16.5 0 30.52-17.27 45.74-21.34C253.5 358.5 274.2 366.3 288 358.3zM112 192c0-44.27 35.81-80 80-80s80 35.73 80 80c0 44.17-35.81 80-80 80S112 236.2 112 192zM1.719 433.2c-3.25 8.188-1.781 17.48 3.875 24.25c5.656 6.75 14.53 9.898 23.12 8.148l45.19-9.035l21.43 42.27C99.46 507 107.6 512 116.7 512c.3438 0 .6641-.0117 1.008-.0273c9.5-.375 17.65-6.082 21.24-14.88l33.58-82.08c-53.71-4.639-102-28.12-138.2-63.95L1.719 433.2zM349.6 351.1c-36.15 35.83-84.45 59.31-138.2 63.95l33.58 82.08c3.594 8.797 11.74 14.5 21.24 14.88C266.6 511.1 266.1 512 267.3 512c9.094 0 17.23-4.973 21.35-13.14l21.43-42.28l45.19 9.035c8.594 1.75 17.47-1.398 23.12-8.148c5.656-6.766 7.125-16.06 3.875-24.25L349.6 351.1z" /></svg>
            </div>
        );
        else return (
            <img src={this.getSelectedTagImageURL(this.state.selectedTag)} alt="IMAGE" className="tag-image" />
        );
    }

    removeRecognition = (index) => {
        const recognitions = this.state.recognitions;
        recognitions.splice(index, 1);
        this.setState({ recognitions });
    }

    renderModal = () => {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                {this.state.showRecognitionsView && this.renderRecognitionsView()}
                <div className="content-container" style={{ display: this.state.showRecognitionsView ? 'none' : 'flex' }}>
                    {this.state.recognitionsSaved && this.state.recognitions?.length > 0 &&
                        <div className="post-recognitions-view">
                            {this.state.selectedTag && this.state.selectedTag !== '' &&
                                <div className="tag-container">
                                    {this.renderTagForRecognitionsRow()}
                                </div>
                            }
                            <div className="recognitions-container">
                                {this.state.recognitions.map((recog, index) => (
                                    <div key={"recognise" + recog.id} className="user-container">
                                        {recog.full_name}
                                        <span className="close-button" onClick={() => { this.removeRecognition(index) }}><FontAwesomeIcon icon={faTimes} /></span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    <div className="post-editor">
                        <ActivityTextBox
                            ref={this.props.editorRef}
                            placeholder={""}
                            validateMessageBox={this.props.validateMessageBox}
                        />
                    </div>
                    <div>
                        <ActivityPostAttachments
                            ref={this.props.postAttachmentsRef}
                            disablePostForm={this.props.disableForm}
                            enablePostForm={this.props.enableForm}
                            editPostMode={!!this.props.editedPostId}
                            validateAttachmentsBox={this.props.validateAttachmentsBox}
                        />
                    </div>
                </div>
            </div>
        );
    }

    getRecognitions = () => {
        if(!this.state.recognitionsSaved) return [];
        const ref = this.props.recognitionsEditorRef.current;
        if (ref) return ref.getRecognitions() || [];
        else return this.state.recognitions
    }

    selectedEmployeesChange = (selectedEmployees) => {
        this.setState({ recognitions: selectedEmployees || [] });
    }

    toggleRecognitionsView(recognitionsSaved=false) {
        /*
        if (this.state.showRecognitionsView)
            this.setState({
                showRecognitionsView: !this.state.showRecognitionsView,
                recognitions: this.props.recognitionsEditorRef.current?.getRecognitions() || []
            })
        else
        */
        this.setState({ showRecognitionsView: !this.state.showRecognitionsView, recognitionsSaved });
    }

    renderModalTitle = () => {
        if (this.state.showRecognitionsView) return (
            <Modal.Header>
                <Modal.Title>
                    <div className="custom-header">
                        <div className="back-button" onClick={() => this.toggleRecognitionsView()}><FontAwesomeIcon icon={faArrowLeft} /></div>
                        <div className="modal-title">Recognize Colleagues</div>
                    </div>
                </Modal.Title>
            </Modal.Header>
        );
        else return (
            <Modal.Header closeButton>
                <Modal.Title>{this.props.editMode ?
                    `Edit Post` :
                    `Create Post in ${this.props.selectedDepartment.title} channel`
                }</Modal.Title>
            </Modal.Header>
        );
    }

    render() {
        //console.log(this.props)
        return (
            <Modal dialogClassName="activity-post-modal-new" show={this.props.show} onHide={this.props.handleClose}>
                {this.renderModalTitle()}
                <Modal.Body>
                    {this.renderModal()}
                    {!this.state.showRecognitionsView && <div className="modal-actions-row">
                        {!this.props.editedPostId &&
                            <button className="btn btn-primary btn-split left-button" onClick={() => this.toggleRecognitionsView()}>
                                <FontAwesomeIcon icon={faGift} /> Recognize Colleague
                            </button>
                        }
                        <button className="btn btn-primary btn-split" onClick={() => this.props.postAttachmentsRef.current?.openFileInputDialog()}>
                            <FontAwesomeIcon icon={faPhotoVideo} /> Image/Video
                        </button>
                    </div>}
                    {!this.state.showRecognitionsView && <div className="modal-actions-row">
                        <button className="btn btn-outline" style={{ textAlign: 'center' }} onClick={() => this.props.sendPost()}><div style={{ width: '100%' }}>{!this.props.editedPostId ? 'Post' : 'Update'}</div></button>
                    </div>}
                </Modal.Body>
            </Modal>
        );
    }
}

export default ActivityPostFormModal;