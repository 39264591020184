import React, { Component } from 'react';
import {Checkbox} from 'react-ui-icheck';

class ChoicesQuestion extends Component {
  
  state = {
    choices: []
  }

  componentDidMount = () => {
    const choices = (this.props.answer || '').split(',');
    this.setState({choices: choices});    
  }


  hasChecked = (choice) => {
    return (this.state.choices).indexOf(choice) > -1; 
  }

  handleChange = (event, choice) => {
    // TODO join choices
    let choices = []
    if (this.state.choices.indexOf(choice) === -1) {
      choices = this.state.choices.concat(choice);
    } else {
      choices = this.state.choices.filter(t => t !== choice);
    }
    this.setState({choices: choices});
    this.props.onUpdateAnswer(this.props.question, choices.sort().join());
  } 
  
  render() {
       
    return (
      <div className="row">
        <div className="col-md-4"><h4>{this.props.question.question}</h4></div>
        <div className="col-md-8">
          <div className="row">
          {
            this.props.question.values.map((choice) => (
              <div key={'q' + this.props.question.id + choice } className="col-sm-3">
                <label style={{fontWeight:400}}>
                  <Checkbox checkboxClass="icheckbox_square-green" checked={this.hasChecked(choice)} onChange={(evt) => this.handleChange(evt, choice)}/> &nbsp;
                  {choice}
                </label>
              </div>
            ))
          }
          </div>
        </div>    
      </div>
    );
  }
}

export default ChoicesQuestion;
