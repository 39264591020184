import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';
import Acknowledgement from '../models/Acknowledgement';

class AcknowledgementClient {
  getAcknowledgements() {
    return client.callApi(Config.API_BASE + '/acknowledgements.json')
      .then((acknowledgements) => acknowledgements.map((acknowledgementData) => (new Acknowledgement(acknowledgementData))))
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  acknowledgementsUrl() {
    return Config.API_BASE + '/acknowledgements.json';
  }
  
  getActivityAcknowledgements() {
    return client.callApi(Config.API_BASE + '/acknowledgement_instances.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  acknowledgeInstance(instanceId) {
    return client.callApi(Config.API_BASE + '/acknowledgement_instances/'+instanceId+'/acknowledge.json', {}, 'PUT')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });       
  }
  
  getAcknowledgement(acknowledgementId) {
    return client.callApi(Config.API_BASE + '/acknowledgements/' + acknowledgementId + '.json')
      .then((acknowledgementData) => (new Acknowledgement(acknowledgementData)))
      .catch((error) => {
        client.handleApiError(error);
        return [] ;
      });        
  }
  
  deleteAcknowledgement(acknowledgementId) {
    return client.callApi(Config.API_BASE + '/acknowledgements/'+ acknowledgementId +'.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  } 
  
  updateAcknowledgement(acknowledgementId, acknowledgementData) {
    return client.callApi(Config.API_BASE + '/acknowledgements/'+acknowledgementId+'.json', {}, 'PUT', {acknowledgement: acknowledgementData})
      .then((acknowledgementData) => new Acknowledgement(acknowledgementData))
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  downloadAcknowledgementAttachment(acknowledgement) {
    return client.callApi(acknowledgement.attachment_url)
      .then((resp) => {window.ezzely_download(resp.s3_url); return resp})
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });        
  }
  
}

export const acknowledgementClient = new AcknowledgementClient();
