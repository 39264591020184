import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';

class KpiReportClient {
  
  getReportData(report_type) {
    return client.callApi(Config.API_BASE + '/kpi_report.json', {}, 'POST', {report: {type: report_type}})
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
      
  getTrainingTransactions() {
    const fromDate = client.currentUser().year_started_at; 
    return client.callApi(Config.PORTAL_API_BASE + '/ezzely/api/finance_operations.json?from='+fromDate, {authorization: client.getPortalAuthorizationHeader()})
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  getTopCategories() {
    const fromDate = client.currentUser().year_started_at; 
    return client.callApi(Config.PORTAL_API_BASE + '/ezzely/api/top_categories.json?from='+fromDate, {authorization: client.getPortalAuthorizationHeader()})
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
    
}

export const kpiReportClient = new KpiReportClient();