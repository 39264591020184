import React from 'react';
import Footer from '../common/Footer';
import TopHeader from '../common/TopHeader';
import { client } from '../Client.js';
import { correctHeight, detectBody } from './Helpers';
import { routeIsEqual } from '../../helpers/TopMenu';
import DesktopLeftBar from '../common/DesktopLeftBar';


class Desktop extends React.Component {

  hideFooter = () => {
    return (routeIsEqual("/conversation/", this.props.location.pathname));
  }


  render() {
    let wrapperClass = "gray-bg"
    let contextClass = (this.props.location.pathname || '').split('/')[1]
    if (contextClass) {
      wrapperClass += (" wrapper-" + contextClass)
    }
    return (
      <div id="page-wrapper" className={wrapperClass} style={{backgroundColor:"#eee"}}>
        <TopHeader location={this.props.location} />
        <DesktopLeftBar location={this.props.location} />
        <div className="content-area ez-opacity-filter" style={{paddingLeft: '72px', paddingTop: '40px'}}>
          {this.props.children}
        </div>
        {!this.hideFooter() && <Footer />}
      </div>
    )
  }

  componentDidMount() {
    // Load css template
    const css_template_url = client.getLayoutUrl();
    if (css_template_url) {
      $("<link/>", { rel: "stylesheet", type: "text/css", href: css_template_url }).appendTo("head");
    };

    // Run correctHeight function on load and resize window event
    $(window).bind("load resize", function () {
      correctHeight();
      detectBody();
    });

    // Correct height of wrapper after metisMenu animation.
    $('.metismenu a').click(() => {
      setTimeout(() => {
        correctHeight();
      }, 300)
    });
  }
}

export default Desktop
