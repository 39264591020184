import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons/faUser';

import { kpiReportClient } from '../../clients/KpiReportClient';

class HRReport extends Component {
    
  state = {
    reportData: {employees: {}, attrition_rate: {}},
    employeesChartData:   {},
    engagementChartData:  {data: {}, options: {}},
    enpsChartData:        {data: {}, options: {}},
    reportDataPresent: true
  };
  
  componentDidMount() {
    this.setState(this.state);
    this.getReportData();
  }
    
  getReportData = () => {
    kpiReportClient.getReportData('hr').then((data) => {
      const employeesChartData = {        
        labels: Object.keys(data.employees.chart_data),           
        datasets: [{ 
          data: Object.values(data.employees.chart_data),
          backgroundColor:  ["#3366CC", "#DC3912", "#FF9900", "#109618", "#990099", "#3B3EAC", "#0099C6", "#DD4477", "#66AA00", "#B82E2E", "#316395", "#994499", "#22AA99", "#AAAA11", "#6633CC", "#E67300", "#8B0707", "#329262", "#5574A6", "#651067"],
          borderColor:      ["#fff"],
        }]                
      }
      
      const engagementChartData = {
        avgValue: data.engagement_data['Avg value'],
        data: {
          labels: Object.keys(data.engagement_data),           
          datasets: [{ 
            label: 'Engagement Score', 
            data: Object.values(data.engagement_data),
            borderColor: '#E95227',
            backgroundColor: '#E95227'
          }]            
        },
        options: {
          aspectRatio: 1,
          scales: {
            y: {
              ticks: {
                beginAtZero: true,
                callback: function(value) {if (value % 1 === 0) {return value;}}
              }
            }
          },             
          plugins: { 
            legend: { display: true, position: 'bottom'},       
          }          
        }
      }
      
      const enpsChartData = {
        avgValue: data.enps_data['Avg value'],
        data: {
          labels: Object.keys(data.enps_data),           
          datasets: [{ 
            label: 'eNPS', 
            data: Object.values(data.enps_data),
            borderColor: '#E95227',
            backgroundColor: '#E95227'
          }]            
        },
        options: {
          aspectRatio: 1,
          scales: {
            y: {
              ticks: {
                beginAtZero: true,
                callback: function(value) {if (value % 1 === 0) {return value;}}
              }
            }
          },             
          plugins: { 
            legend: { display: true, position: 'bottom'},       
          }          
        }
      }
            
      this.setState({ reportData: data, 
                      employeesChartData: employeesChartData,
                      engagementChartData: engagementChartData,
                      enpsChartData: enpsChartData,
                      reportDataPresent: data !== undefined })
    });
  }

  renderEnpsChart() {
    if (this.state.enpsChartData.avgValue) {
      return (  
        <div className="ibox">
          <div className="ibox-content">
            <h2>eNPS&nbsp;
              <span className="label label-default" style={{fontSize: '24px'}}>{ this.state.enpsChartData.avgValue }</span>
            </h2>
            <Line data={this.state.enpsChartData.data} options={this.state.enpsChartData.options}/>
          </div>
        </div>
      )
    } else {
      return (
        <div className="ibox">
          <div className="ibox-content">
            <h2>eNPS</h2>
            <div>There is no data for eNPS calculation yet.</div>
          </div>
        </div>
      )
    }    
  }
  
  renderEngagementScoreChart() {
    if (this.state.engagementChartData.avgValue) {
      return (
        <div className="ibox" >
          <div className="ibox-content" >
            <h2>Engagement Score&nbsp;
              <span className="label label-default" style={{fontSize: '24px'}}>{ this.state.engagementChartData.avgValue}</span>
            </h2>
            <Line data={this.state.engagementChartData.data} options={this.state.engagementChartData.options} />
          </div>
        </div>
      ) 
    } else {
       return (
         <div className="ibox">
           <div className="ibox-content">
             <h2>Engagement Score</h2>
             <div>There is no data for Engagement Score calculation yet.</div>
           </div>
         </div>
       )           
    }
  }  
  
 renderReport = () => {
   if (this.state.reportDataPresent) {
     return (
       <div>
          <div className="row">
            <div className="col-lg-6 report-block">
               <div className="ibox">
                 <div className="ibox-content">
                   <h2>Total Employees&nbsp; 
                     <span className="label label-default" style={{fontSize: '24px'}}>{this.state.reportData.employees.total}</span>
                   </h2>
                   <Pie data={this.state.employeesChartData } />
                 </div>
               </div>
            </div>
            <div className="col-lg-6 report-block">
               <div className="ibox">
                 <div className="ibox-content">
                   <h2>Attrition Rate</h2>
                   <div className="widget p-lg text-center" style={{padding: '40px'}}>
                      <div className="m-b-md" style={{fontSize: '24px'}}>
                          <FontAwesomeIcon icon={faUser} size="3x"/>
                          <h1 className="m-xs">{this.state.reportData.attrition_rate.rate_value}%</h1>
                          <h3 className="font-bold no-margins">
                              {this.state.reportData.attrition_rate.terminated_employees} employees
                          </h3>
                          <small>left the company</small>
                      </div>
                   </div>
                 </div>  
               </div>  
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 report-block">
               { this.renderEnpsChart() }
            </div>
            <div className="col-lg-6 report-block engagement-score">
               { this.renderEngagementScoreChart() } 
            </div>  
          </div>
       </div>
     )
   } else {
     return (
       <div className="text-center">
         <h1>There is no report data</h1>
       </div>
     )
   }
 }  
  
 render() {

    return (
      <div>
        {this.renderReport()}
      </div>  
    );
  }
}

export default HRReport;
