import React, { Component } from 'react';
import { mediumDateFormat } from './../../helpers/Formatter';
import { iconUrl } from './../../helpers/Courses';

class LicensesTable extends Component {

  render() {
    return (
      <div>
        { this.props.licenses.length === 0 && 
          <h2 className="text-center">No Courses</h2>
        }
        { this.props.licenses.length > 0 && 
        <table className="table table-striped table-condensed">
          <thead>
          <tr>
            <th>Views</th>
            <th>Time Spent</th>
            <th>Started At</th>
            <th>Score</th>
            <th>Status</th>
          </tr>
          </thead>
          {
            this.props.licenses.map((license) => (
              <tbody key={'lic' + license.document_id}>
                <tr>
                  <td colSpan="5">
                    <div className="table-course-icon">
                      <img style={{width:'60px', marginRight: '20px'}} src={iconUrl(license)} />
                    </div>
                    <span>{license.title}</span>
                  </td>
                </tr>
                <tr>
                  <td>{license.views}</td>
                  <td>{license.total_time}</td>
                  <td>{mediumDateFormat(license.start_date)}</td>
                  <td>{license.complete}</td>
                  <td>{license.boolean_complete}</td>
                </tr>
              </tbody>
            ))
          }

        </table>
        }
      </div>
    )
  }
}

export default LicensesTable;