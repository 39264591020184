import React, { Component } from 'react';
import { client } from '../../Client';
import { activityRecognitionTagClient } from '../../../clients/ActivityRecognitionTagClient';
import Config from 'config';
import debounce from 'lodash/debounce';

import '../../../../public/styles/atwho.css';
import Caret from '../../../../public/vendor/caret/jquery.caret.min';
import At from '../../../../public/vendor/at/jquery.atwho';

import emojiOne from 'emojione';
import 'emojione/extras/css/emojione.css';
import emojiStrategy from './emoji_strategy.js';

import mediumEditor from 'medium-editor/dist/js/medium-editor';
import 'medium-editor/dist/css/medium-editor.min.css';
import 'medium-editor/dist/css/themes/default.min.css';
import './ActivityTextBox.css';

import * as linkify from 'linkifyjs';
import hashtag from 'linkifyjs/plugins/hashtag';
import linkifyHtml from 'linkifyjs/html';
hashtag(linkify);

class ActivityTextBox extends Component {

  state = {
    minTextLength: 0,
    editor: Object,
    editableContent: null,
    hashtagMentions: []
  }
  
  constructor(props) {
    super(props);
    this.getMentions = debounce(this.getMentions, 200);
  }

  componentDidMount = () => {
    let _this = this;
    
    if (this.props.onRef) this.props.onRef(this);
        
    let emojiList = Object.keys(emojiStrategy).map(function(key, index) {
      return {shortname: emojiStrategy[key].shortname, unicode: key}
    });
    
    this.getHashtags();

    let editor = new mediumEditor(".activity-text-editor", {
        placeholder: {
          text: _this.props.placeholder || 'Write your message',
          hideOnClick: true
        },
        delay: 1000,
        targetBlank: true,
        toolbar: false,
        autoLink: true
    });
    
    editor.subscribe('editableInput', function (event, editorElement) {
      _this.saveSelectionRange()
    });
    
    editor.subscribe('editableClick', function (event, editorElement) {
      _this.saveSelectionRange()
    });
      
    this.setState({ editor: editor });
    
    if (this.props.content) {
      this.setState({ editableContent: this.props.content });
    }
    
    if (this.props.focusAfterLoad) this.focus();

    $(".activity-text-editor").atwho({
      at: ":",
      data: emojiList,
      searchKey: "shortname",
      callbacks: {
        filter: function(query, data, searchKey) {
          if(query == '') {
            return [
              {shortname: ":ok_hand:", unicode: "1f44c"},
              {shortname: ":thumsub:", unicode: "1f44d"},
              {shortname: ":smiley:", unicode: "1f603"},
              {shortname: ":slight_smile:", unicode: "1f642"},
              {shortname: ":heart:", unicode: "2764"}
            ];
          } else {
            var _results, i, item, len;
            _results = [];
            for (i = 0, len = data.length; i < len; i++) {
              item = data[i];
              if (~new String(item[searchKey]).toLowerCase().indexOf(query.toLowerCase())) {
                _results.push(item);
              }
            };
            return _results;
          }
        },
        beforeInsert: function(value, li) {
          _this.setCursorPosition();  
          return value;
        }
      },
      displayTpl:"<li data-value=':${shortname}:'><img src='https://cdn.jsdelivr.net/emojione/assets/3.1/png/32/${unicode}.png'  height='20' width='20' /> ${shortname}</li>",
      insertTpl: "<img class='emojione' alt='${shortname}' title='${shortname}' src='https://cdn.jsdelivr.net/emojione/assets/3.1/png/32/${unicode}.png'>",
    }).
    atwho({
      at: "#",
      data: _this.state.hashtagMentions,
      searchKey: "title",
      displayTpl:"<li data-value=':${title}:'>${displayTpl}</li>",
      insertTpl: "<span class='hashtag-selected' data-hashtag='${title}'><span>#${title}</span></span>",
      callbacks: {
        beforeInsert: function(value, li) {
          _this.setCursorPosition();  
          return value;
        }
      }
    }).
    atwho({
      at: "@",
      searchKey: "name",
      displayTpl:"<li data-value=':${id}:' ><img src='${avatar}' height='20' width='20' class='img-circle'/> ${name}</li>",
      insertTpl: "<span class='mention-selected' data-mention-id='${id}'><span>${name}</span></span>",
      callbacks: {
        beforeInsert: function(value, li) {
          _this.setCursorPosition();  
          return value;
        },
        remoteFilter: function(query, callback) {
          _this.getMentions(query, callback)
        },
        beforeInsert: function(value, li) {
          //return null if it is a loader element
          if (li.attr("data-value") !== ":-1:") {
            return value;
          } else {
            return null;
          }  
        }
      }
    });
  }
      
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.editableContent !== prevState.editableContent) {
      this.state.editor.setContent(this.state.editableContent, 0);
      this.setCursorPosition();
    };
    let _this = this;
    if (this.state.hashtagMentions !== prevState.hashtagMentions) {
      $(".activity-text-editor").atwho({
        at: "#",
        data: _this.state.hashtagMentions,
        searchKey: "title",
        limit: _this.state.hashtagMentions.length,
        displayTpl:"<li data-value=':${title}:'>${displayTpl}</li>",
        insertTpl: "<span class='hashtag-selected' data-hashtag='${title}'><span>#${title}</span></span>",
        callbacks: {
          beforeInsert: function(value, li) {
            _this.setCursorPosition();  
            return value;
          }
        }
      })
    }
  }
  
  componentWillUnmount() {
    if (this.props.onRef) this.props.onRef(undefined);
  }
  
  getMentions(query, callback) {
    callback([{id: '-1', name: "loading...", avatar: "img/loading_icon.gif"}]);
    
    client.getPaginatedEmployees(1, query)
    .then((resp) => {
       let mentions = resp.employees.map(employee => {
         let em = {};
         em.name = employee.full_name();
         em.avatar = employee.avatar_url;
         em.id = employee.id;
         return em;
       });
       callback(mentions);
    });
  }
  
  getHashtags = () => {
    activityRecognitionTagClient.getTags().
    then((tags) => {
      const hashtagMentions = tags.map(tag => {
        return ({ title: tag.name, displayTpl: `#${tag.name} <img src='img/coin.png' width='16px'> ${tag.points}`})
      });
      this.setState({ hashtagMentions });
    });
  }

  getContent() {
    let content = this.state.editor.getContent(0)
    return emojiOne.toShort(content);
  }

  getSerializedContent() {
    return this.state.editor.serialize();
  }

  isValid() {
    let content = this.state.editor.getContent(0);
    if (content === "<p><br></p>") return false;
    return content.length > this.state.minTextLength;
  }

  clear() {
    this.setState({ editableContent: null });
    return this.state.editor.setContent('', 0);
  }
  
  loadContent = (content) => {
    this.setState({ editableContent: content })
  }

  addEmoji = (emoji) => {
    if (!emoji) return false;
    const imageHtml = `<img class='emojione' alt='${emoji.colons}' title='${emoji.colons}' src='https://cdn.jsdelivr.net/emojione/assets/4.0/png/32/${emoji.unified.split('-')[0]}.png'>`
    if (this.state.selectionRange) {
      mediumEditor.selection.selectRange(document, this.state.selectionRange)
    } else {
      this.state.editor.selectElement(document.getElementById("activity-text-editor"));
      //mediumEditor.selection.clearSelection(document, false)
    }
    this.state.editor.pasteHTML(imageHtml, { cleanAttrs: [] });
  }

  saveSelectionRange = () => {
    const selectionRange = mediumEditor.selection.getSelectionRange(document);
    this.setState({ selectionRange: selectionRange })
  }
  
  setCursorPosition = () => {
    if (this.state.selectionRange) {
      mediumEditor.selection.selectRange(document, this.state.selectionRange)
    } else {
      this.state.editor.selectElement(document.getElementById("activity-text-editor"));
      //mediumEditor.selection.clearSelection(document, false)
    }
  }
  
  pasteContent = (html) => {
    //let newContent = this.state.editor.getContent(0).replace(/\<p\><br>\<\/p\>/ig, '') + html;
    //this.state.editor.setContent(newContent, 0);
    this.setCursorPosition();  
    this.state.editor.pasteHTML(html, { cleanAttrs: [] });
  }
  
  focus() {
    const element = document.getElementById('activity-text-editor');
    element.focus();
  }
      
  render() {
    return (
      <div className="medium-text-box">
        <div
          id="activity-text-editor"
          className="activity-text-editor"
          contentEditable
        >
        </div>
      </div>
    )
  }
}

export default ActivityTextBox;
