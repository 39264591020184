// @flow
import React from "react";
import Main from "../components/layouts/Main";
import Blank from "../components/layouts/Blank";

import ActivityView from "../views/Activity";
import CoursesView from "../views/Courses";
import Welcome from "../components/signup/Welcome";
import CourseView from "../views/Course";
import WellnessView from "../views/Wellness";
import SettingsView from "../views/Settings";
import EmployeesView from "../views/Employees";
import ColleaguesView from "../views/Colleagues";
import EmployeeView from "../views/Employee";
import AccountBalanceView from "../views/Balance";
import LogoutView from "../views/Logout";
import LoginTokenView from "../views/LoginToken";
import LoginView from "../views/Login";
import ClientCredentialsView from "../views/ClientCredentials";
import ResetPasswordView from "../views/ResetPassword";
import ProfileView from "../views/Profile";
import SurveyView from "../views/Survey";
import TerminationSurveyView from "../views/TerminationSurvey";
import SurveyBuilderView from "../views/SurveyBuilder";
import NewSurveyView from "../views/NewSurvey";
import RaffleView from "../views/Raffle";
import KPIReportView from "../views/KPIReport";
import RafflesView from "../views/Raffles";
import RewardTypesView from "../views/RewardTypes";
import MyRewardsView from "../views/MyRewards";
import ActivityPostView from "../views/ActivityPost";
import ActivityNewBirthdayPostView from "../views/ActivityNewBirthdayPost";
import VerifiedCredentialsView from "../views/VerifiedCredentials";
import SignupView from "../views/Signup";
import PickUpShiftRequestsView from "../views/PickUpShiftRequests";
import ShiftRequestView from "../views/ShiftRequest";
import AcknowledgementsView from "../views/Acknowledgements";
import AcknowledgementView from "../views/Acknowledgement";
import PollsView from "../views/Polls";
import PollView from "../views/Poll";
import MobileMenuView from "../views/MobileMenu";
import NotificationsView from "../views/Notifications";
import ConversationView from "../views/Conversation";
import ConversationsView from "../views/Conversations";
import CoggnoMarketplaceView from "../views/CoggnoMarketplace";
import GroupsView from "../views/Groups";
import GroupView from "../views/Group";
import GroupMembersView from "../views/GroupMembers";
import GroupCandidatesView from "../views/GroupCandidates";
import CalendarView from "../views/Calendar";
import EventView from "../views/Event";
import RouletteView from "../views/Roulette";
import RouletteRecordingView from "../views/RouletteRecording";
import GiftBrandsView from "../views/GiftBrands";
import { client } from "../components/Client";
import { extractParameter } from "../helpers/PathParameter";
import Config from "config";
import Reel from "../views/Reel";
import ReelDraft from "../components/reels/ReelDraft";

import {
  HashRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import PointsHistoryView from "../views/PointsHistory";
import Reels from "../views/Reels";
import UserReels from "../views/UserReels";

const PrivateRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const token = extractParameter("token", props.location.search);
      if (token) {
        return (
          <Redirect
            to={{
              pathname: "/login_by_token",
              state: { from: props.location, token: token },
            }}
          />
        );
      } else {
        if (client.isLoggedIn()) {
          return React.createElement(component, {
            ...props,
            key: props.location.pathname,
          });
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }
    }}
  />
);

const TerminationRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        client.isRegistered() ? (
          <Blank {...props} component={Component} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location, allow_terminated: true },
            }}
          />
        )
      }
    />
  );
};

export default (
  <Router>
    <Switch>
      <Route exact path="/login" component={LoginView} />
      <Route exact path="/reset_password/:code" component={ResetPasswordView} />
      <Route exact path="/login_by_token" component={LoginTokenView} />
      <Route exact path="/logout" component={LogoutView} />
      <Route exact path="/signup" component={SignupView} />
      <Route
        exact
        path="/client_credentials"
        component={ClientCredentialsView}
      />
      <TerminationRoute
        path="/termination_survey/:id"
        component={TerminationSurveyView}
      />
      <Main>
        <PrivateRoute path="/activity" component={ActivityView} />
        <PrivateRoute path="/courses" component={CoursesView} />
        <PrivateRoute path="/course/:id" component={CourseView} />
        <PrivateRoute path="/roulette/:id" component={RouletteView} />
        <PrivateRoute
          path="/roulette_recording/:id"
          component={RouletteRecordingView}
        />
        <PrivateRoute
          path="/coggno_training"
          component={CoggnoMarketplaceView}
        />
        <PrivateRoute path="/employees" component={EmployeesView} />
        <PrivateRoute path="/welcome" component={Welcome} />

        <PrivateRoute path="/colleagues" component={ColleaguesView} />
        <PrivateRoute path="/employee/:id" component={EmployeeView} />
        <PrivateRoute path="/account" component={AccountBalanceView} />
        <PrivateRoute path="/wellness" component={WellnessView} />
        <PrivateRoute path="/profile" component={ProfileView} />
        <PrivateRoute path="/settings" component={SettingsView} />
        <PrivateRoute path="/survey_builder" component={SurveyBuilderView} />
        <PrivateRoute path="/new_survey" component={NewSurveyView} />
        <PrivateRoute path="/surveys/:id" component={SurveyView} />
        <PrivateRoute path="/raffle/:id" component={RaffleView} />
        <PrivateRoute path="/kpi_report" component={KPIReportView} />
        <PrivateRoute path="/raffles" component={RafflesView} />
        <PrivateRoute path="/reward_types" component={RewardTypesView} />
        <PrivateRoute path="/rewards" component={MyRewardsView} />
        <PrivateRoute path="/transactions" component={PointsHistoryView} />
        <PrivateRoute path="/activity/posts/:id" component={ActivityPostView} />
        <PrivateRoute exact path="/reels" component={Reels} />
        <PrivateRoute path="/reel/:id" component={Reel} />
        <PrivateRoute exact path="/reels/employee/:id" component={UserReels} />
        <PrivateRoute exact path="/reels/draft" component={ReelDraft} />

        <PrivateRoute
          path="/verified_credentials"
          component={VerifiedCredentialsView}
        />
        <PrivateRoute
          path="/pick_up_shift_requests"
          component={PickUpShiftRequestsView}
        />
        <PrivateRoute path="/shift_requests/:id" component={ShiftRequestView} />
        <PrivateRoute
          path="/acknowledgements"
          component={AcknowledgementsView}
        />
        <PrivateRoute
          path="/acknowledgement/:id"
          component={AcknowledgementView}
        />
        <PrivateRoute path="/polls" component={PollsView} />
        <PrivateRoute path="/poll/:id" component={PollView} />
        <PrivateRoute path="/mobile_menu" component={MobileMenuView} />
        <PrivateRoute path="/notifications" component={NotificationsView} />
        <PrivateRoute path="/conversations" component={ConversationsView} />
        <PrivateRoute path="/conversation/:id" component={ConversationView} />
        <PrivateRoute path="/groups" component={GroupsView} />
        <PrivateRoute path="/calendar" component={CalendarView} />
        <PrivateRoute path="/gift_brands" component={GiftBrandsView} />
        <PrivateRoute path="/calendar/events/:id" component={EventView} />
        <PrivateRoute exact path="/group/:id" component={GroupView} />
        <PrivateRoute
          exact
          path="/group/:id/members"
          component={GroupMembersView}
        />
        <PrivateRoute
          exact
          path="/group/:id/candidates"
          component={GroupCandidatesView}
        />
        <PrivateRoute
          exact
          path="/activity/birthdays/:employee_id/new_post"
          component={ActivityNewBirthdayPostView}
        />
        <Route exact path="/" render={() => <Redirect to="/activity" />} />
      </Main>
    </Switch>
  </Router>
);
