import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons/faThumbsUp';
import { groupClient } from '../clients/GroupClient';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import { toastrNotification } from './../helpers/Toastr';

class GroupCandidatesView extends Component {
  
  state = {
    group: {admin: {}},
    candidates: [],
    shouldRedirect: false
  }

  componentDidMount() {
    this.getGroup();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.candidates !== this.state.candidates && this.state.candidates.length === 0) {
      this.setState({ shouldRedirect: true })
    }
  }

  getGroup = () => {
    groupClient.getGroup(this.props.match.params.id).then((group) => {
      let candidates = group.candidates;
      this.setState({ group: group, spinnerClass: '', loading: false, candidates: candidates })
    });
  }
  
  approveCandidate = (candidate) => {
    groupClient.approveGroupCandidate(this.props.match.params.id, candidate.id).then((response) => {
      if (response.success) {
        toastrNotification({ success: true, title: `${response.employee_name} was successfully added in the group.` });
        const mCandidates = this.state.candidates.filter(c => c.id !== candidate.id);
        this.setState({ candidates: mCandidates });
      } else {
        toastrNotification({ success: false, title: `Unable to approve ${response.employee_name}.` });
      }
    });
  }
  
  refuseCandidate = (candidate) => {
    groupClient.refuseGroupCandidate(this.props.match.params.id, candidate.id).then((response) => {
      if (response.success) {
        toastrNotification({ success: true, title: `You have forbidden ${response.employee_name} to join group.` });
        const mCandidates = this.state.candidates.filter(c => c.id !== candidate.id);
        this.setState({ candidates: mCandidates });
      } else {
        toastrNotification({ success: false, title: `Unable to refuse.` });
      }
    });
  } 
  
  render() {
      const {group, candidates} = this.state 
      
      if (this.state.shouldRedirect) {
        return (
          <Redirect to={`/group/${group.id}`} />
        )
      } else { 
        return (
          <div className="wrapper wrapper-content animated fadeIn">
            <div className="row">
              <div className="col-sm-12 col-lg-8 col-lg-offset-2">
                  <Link to={`/group/${group.id}`} className="btn btn-white btn-sm pull-right m-b-sm hidden-xs">{`Back to ${group.title}`}</Link>
                  <div className="ibox">
                      <div className="ibox-content">
                          <div className="row">
                            <div className="col-md-12">{}
                              <h2 className="pull-left">{group.title} Join Requests</h2>
                            </div>
                          </div>
                          <div className="clients-list">
                              <div className="full-height-scroll">
                                  <div>
                                      <table className="table table-striped table-hover mobile-table">
                                          <tbody>
                                          {
                                             candidates.map((candidate) => ( 
                                               <tr key={'c'+candidate.id} >
                                                 <td data-label="" className="client-avatar">
                                                   <div>
                                                     <img alt="image" src={candidate.avatar_url} />
                                                   </div>
                                                 </td>
                                                 <td data-label="Name:" >
                                                   <div className="client-link">{candidate.full_name}</div>
                                                 </td>
                                                 <td data-label="Email:">
                                                   <div><FontAwesomeIcon icon={faEnvelope}/> {candidate.email}</div>
                                                 </td>
                                                 <td data-label="Request Date:">
                                                   { moment(candidate.created_at).format('D MMM YYYY') }
                                                 </td>
                                                 <td className="actions">
                                                   <div>
                                                     <button type="button" className='btn btn-white pull-right' onClick={() => this.refuseCandidate(candidate)}><FontAwesomeIcon icon={faBan} /> Refuse</button>
                                                     <button type="button" className='btn btn-primary pull-right m-r-sm' onClick={() => this.approveCandidate(candidate)}><FontAwesomeIcon icon={faThumbsUp} /> Approve</button>
                                                   </div>
                                                 </td>
                                               </tr>
                                             ))
                                          }                                  
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        )  
      }
  }    
  
}

export default GroupCandidatesView
