import React from "react";
import {
  Modal,
  Panel,
  Button,
  OverlayTrigger,
  Tooltip,
  Collapse,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import "./ReelForm.css";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { DropzoneComponent } from "react-dropzone-component";
import { reelsClient } from "../../clients/ReelsClient";
import ReelDraft, { DraftTumbnail } from "./ReelDraft";

import RSVP from "../../../public/vendor/frame-grab/lib/rsvp";
import frameGrab from "../../../public/vendor/frame-grab/frame-grab";

import Config from "config";
import { toastrNotification } from "../../helpers/Toastr";

class ReelForm extends React.Component {
  state = {
    showModal: false,
    attachmentBoxIsValid: true,
    canSubmit: false,
    videos: [],
    title: "",
    description: "",
    hideUploadButton: false,
    draftField: false,
    formField: true,
    draft: null,
  };

  constructor(props) {
    super(props);
    this.dropzoneRef = React.createRef();
  }

  contentUploaded() {
    return this.state.videos.length > 0;
  }

  reset = () => {
    this.setState({
      title: "",
      description: "",
      videos: [],
      hideUploadButton: false,
      canSubmit: false,
      draft: null,
    });
  };

  processAsDraft = () => {
    const video = this.state.videos[0];
    if (video) {
      reelsClient.processDraftVideo(video);
    }
  };

  close = (saveDraft = true) => {
    this.setState({ showModal: false }, () => {
      if (saveDraft) this.processAsDraft();
      this.reset();
      this.props.history.push("/activity");
    });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  openDraft = () => {
    this.setState({ formField: false, draftField: true });
  };

  openForm = (draft = null) => {
    if (draft) this.setState({ draft, draftField: false, formField: true });
    else this.setState({ draftField: false, formField: true });
  };

  disableSubmit = () => {
    this.setState({ canSubmit: false });
  };

  setDraft = (draft) => {
    this.setState({ draft });
  };

  validateForm = () => {
    const { videos, title } = this.state;

    if (!title || title === "") return false;
    else if (!this.state.draft && !this.contentUploaded()) return false;

    this.setState({ canSubmit: true });
  };

  formInputChange(name) {
    return (event) => {
      if (name === "title")
        this.setState({ title: event.target.value }, () => this.validateForm());
      else if (name === "description")
        this.setState({ description: event.target.value }, () =>
          this.validateForm()
        );
    };
  }

  postReel = (event) => {
    event.preventDefault();
    const { videos, title, description } = this.state;
    let reelId;
    if (this.state.draft) reelId = this.state.draft.id;
    else reelId = videos[0];
    if (reelId)
      reelsClient.postReel(reelId, title, description).then(() => {
        toastrNotification({ success: true, message: "Xtras Posted" });
        this.close(false);
      });
  };

  renderDropzoneOrDraft = () => {
    const draft = this.state.draft;
    if (draft)
      return (
        <div className="draft-container">
          <DraftTumbnail draft={draft} hideOptions />
        </div>
      );
    else {
      const _this = this;

      const componentConfig = {
        showFiletypeIcon: true,
        postUrl: Config.API_BASE + "/reels/post_reel",
      };

      const djsConfig = {
        addRemoveLinks: true,
        acceptedFiles:
          "application/vnd.apple.mpegurl,application/x-mpegurl, video/3gpp, video/mp4, video/mpeg, video/ogg, video/quicktime, video/webm, video/x-m4v, video/ms-asf, video/x-ms-wmv, video/x-msvideo",
        clickable: [".add-reels-manually-btn"],
        dictDefaultMessage: "",
        maxFiles: 1,
        maxFilesize: 128, //MB
        timeout: 3600000,
        method: "put",
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        accept: (file, done) => {
          if (/video/i.test(file.type)) {
            _this.setState({ hideUploadButton: true });
            reelsClient
              .getVideosPresignedURL(file.upload.filename)
              .then((data) => {
                file.uploadUrl = data.signedUrl;
                file.method = "put";
                file.headers = {
                  "Content-Type": "multipart/form-data",
                  "Access-Control-Allow-Origin": "*",
                };
                file.reel_id = data.reel_id;
                done();
              });
          } else {
            done("Unable to upload. Use image or video only.");
          }
        },
      };

      const eventHandlers = {
        success: (file, response) => {
          if (file.previewElement) {
            if (file.reel_id) {
              let videos = this.state.videos;
              this.setState({ videos: videos.concat(file.reel_id) }, () =>
                this.validateForm()
              );
              file.media_type = "video";
            }
            //this.props.validateAttachmentsBox(this.contentUploaded());
            return file.previewElement.classList.add("dz-success");
          }
        },

        error: (file, errorMessage) => {
          if (
            file.reel_id &&
            file.media_type == "video" &&
            this.contentUploaded()
          ) {
            reelsClient.removeReel(file.reel_id);
            let videos = this.state.videos;
            let video_index = videos.indexOf(file.reel_id);
            if (video_index > -1) {
              videos.splice(video_index, 1);
            }
            this.setState({ videos: videos, hideUploadButton: false }, () =>
              this.validateForm()
            );
            //this.props.validateAttachmentsBox(this.contentUploaded());
          }
        },

        addedfile: (file) => {
          if (/video\//i.test(file.type)) {
            FrameGrab.blob_to_video(file).then(
              function videoRendered(videoEl) {
                let frameGrab = new FrameGrab({ video: videoEl });
                let imgEl = file.previewElement.querySelector("img");
                frameGrab.grab(imgEl, 1, 220).then(
                  function frameGrabbed(img) {},
                  function frameFailedToGrab(reason) {
                    console.log(
                      "Can't grab the video frame from file: " +
                        file.name +
                        ". Reason: " +
                        reason
                    );
                  }
                );
              },
              function videoFailedToRender(reason) {
                console.log(
                  "Can't convert the file to a video element: " +
                    file.name +
                    ". Reason: " +
                    reason
                );
              }
            );
          }
        },

        removedfile: (file) => {
          if (file.reel_id && file.media_type && this.contentUploaded()) {
            if (!this.props.editPostMode) {
              reelsClient.removeReel(file.reel_id);
            }
            let videos = this.state.videos;
            let video_index = videos.indexOf(file.reel_id);
            if (video_index > -1) {
              videos.splice(video_index, 1);
            }
            this.setState({ videos: videos, hideUploadButton: false }, () =>
              this.validateForm()
            );
            //this.props.validateAttachmentsBox(this.contentUploaded());
          }
        },

        queuecomplete: () => {
          //this.props.enablePostForm();
          this.validateForm();
        },

        canceled: (file) => {
          //this.props.enablePostForm();

          _this.setState({ hideUploadButton: false });
          this.validateForm();
        },

        processing: (file) => {
          this.disableSubmit();
          const element = this.dropzoneRef.current;
          if (element) {
            element.dropzone.options.url = file.uploadUrl;
            element.dropzone.options.method = file.method;
            element.dropzone.options.headers = file.headers;
          }
        },

        sending: (file, xhr) => {
          //override send method for video files
          if (/video\//i.test(file.type)) {
            let _send = xhr.send;
            xhr.send = () => {
              _send.call(xhr, file);
            };
          }
        },
      };
      return (
        <DropzoneComponent
          ref={this.dropzoneRef}
          config={componentConfig}
          eventHandlers={eventHandlers}
          djsConfig={djsConfig}
        />
      );
    }
  };

  isPlatformCordova = () => {
    return !!window.cordova;
  };

  captureVideo = () => {
    if (!this.isPlatformCordova()) return;

    const captureVideo = window.captureVideo;
    if (captureVideo) {
      captureVideo().then((file) => {
        this.dropzoneRef.current?.dropzone.handleFiles([file]);
      })
        .catch((err) => {
          toastrNotification({ success: false, message: err });
        })
    }
  }

  render() {
    const draftPanel = (
      <Panel
        className="draft-fields"
        collapsible="true"
        expanded={this.state.draftField}
        onToggle={() => {}}
        style={{ border: "none" }}
      >
        <Panel.Collapse>
          <ReelDraft onDraftSelected={this.openForm} />
        </Panel.Collapse>
      </Panel>
    );
    const formData = (
      <div>
        {this.renderDropzoneOrDraft()}
        <Panel
          className="draft-fields"
          collapsible="true"
          expanded={this.state.formField}
          onToggle={() => {}}
          style={{ border: "none" }}
        >
          <Panel.Collapse>
            <div
              className="add-reels-manually-btn"
              id="label-reel"
              style={
                this.state.hideUploadButton || this.state.draft
                  ? { display: "none" }
                  : {}
              }
            >
              <svg
                viewBox="0 0 256 256"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M256 240C256 248.837 248.837 256 240 256H16C7.1635 256 0 248.837 0 240C0 231.163 7.1635 224 16 224H240C248.837 224 256 231.163 256 240ZM71.3115 88.6889C75.406 88.6889 79.501 87.1269 82.625 84.0024L112 54.6274V178C112 186.837 119.163 194 128 194C136.837 194 144 186.837 144 178V54.6274L173.375 84.0024C179.624 90.2509 189.754 90.2509 196.003 84.0024C202.251 77.7539 202.251 67.6234 196.003 61.3749L139.314 4.68638C133.065 -1.56213 122.934 -1.56213 116.686 4.68638L59.9975 61.3749C53.749 67.6234 53.749 77.7539 59.9975 84.0024C63.122 87.1269 67.2165 88.6889 71.3115 88.6889Z"
                  fill="#D3D3D3"
                />
              </svg>

              <h3>Upload a Xtras</h3>
              <p id="para">*Please note: Xtras are limited to 30 seconds</p>
            </div>
            <input
              className="text-inputs"
              type="text"
              placeholder="Xtra Title"
              id="input-title"
              onChange={this.formInputChange("title")}
            />
            <textarea
              className="text-inputs"
              type="text"
              placeholder="About the Xtras.."
              id="input-description"
              onChange={this.formInputChange("description")}
            />
            <LaddaButton
              disabled={!this.state.canSubmit}
              onClick={this.postReel}
              data-style={EXPAND_LEFT}
              type="submit"
              loading={this.state.loading}
              className={" btn btn-outline"}
              style={{
                width: "100%",
                height: "45px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h3>Post Xtras</h3>
            </LaddaButton>

            {/* <div className="post-form-right-buttons">
                <button
                  className="btn btn-primary"
                  disabled={!this.state.canSubmit}
                  onClick={() => this.sendPost()}
                >
                  {this.editPostMode() ? "Update" : "Share"}
                </button>
              </div> */}
            <div
              style={{
                display:
                  this.isPlatformCordova() && !this.state.hideUploadButton
                    ? "block"
                    : "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0",
                }}
              >
                <div
                  style={{
                    flex: "1 1 auto",
                    height: "2px",
                    backgroundColor: "gray",
                  }}
                ></div>
                <span style={{ padding: "0 40px" }}>OR</span>
                <div
                  style={{
                    flex: "1 1 auto",
                    height: "2px",
                    backgroundColor: "gray",
                  }}
                ></div>
              </div>
              <LaddaButton
                data-style={EXPAND_LEFT}
                type="button"
                loading={this.state.loading}
                onClick={() => this.captureVideo()}
                className={"ladda-button btn btn-sm btn-primary no-margins"}
                style={{
                  width: "100%",
                  height: "50px",
                }}
              >
                <svg
                  className="upload-img2"
                  viewBox="0 0 258 256"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M257.1 78.3913C257.1 68.8895 246.973 62.8123 238.588 67.2849L194.88 90.5956C190.777 92.7834 188.216 97.0528 188.216 101.702V154.151C188.216 158.8 190.778 163.07 194.88 165.257L238.588 188.568C246.973 193.039 257.1 186.965 257.1 177.46V78.3913ZM200.905 154.092V101.763L244.412 78.5593V177.295L200.905 154.092Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.2"
                  />
                  <path
                    d="M25.9752 65.5876H144.61C151.452 65.5876 156.997 71.1343 156.997 77.9752V177.878C156.997 184.721 151.452 190.266 144.61 190.266H25.9752C19.1343 190.266 13.5876 184.721 13.5876 177.878V77.9752C13.5876 71.1343 19.133 65.5876 25.9752 65.5876ZM25.9752 52.9C12.1264 52.9 0.9 64.1264 0.9 77.9752V177.878C0.9 191.727 12.1264 202.954 25.9752 202.954H144.61C158.459 202.954 169.685 191.727 169.685 177.878V77.9752C169.685 64.1264 158.459 52.9 144.61 52.9H25.9752Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.2"
                  />
                </svg>
                <span style={{ marginLeft: "30px" }}>Record an Xtra</span>
              </LaddaButton>
            </div>
          </Panel.Collapse>
        </Panel>
      </div>
    );
    return (
      <Modal show={this.state.showModal} onHide={this.close}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="headers-reel">
              <span
                onClick={() => this.openForm()}
                className={`reel-header-form
                  ${
                    this.state.formField ? "active-header" : "inactive-header"
                  }`}
              >
                Create New Xtras
              </span>
              <span
                style={
                  this.state.hideUploadButton
                    ? { display: "none" }
                    : { marginLeft: "10px" }
                }
              >
                |
              </span>
              <span
                onClick={() => this.openDraft()}
                id="button2"
                className={`real-header-draft
                  ${
                    this.state.draftField ? "active-header" : "inactive-header"
                  }`}
                style={this.state.hideUploadButton ? { display: "none" } : {}}
              >
                Drafts
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <form id="reel-form">
          <Modal.Body>
            {draftPanel}
            {formData}
          </Modal.Body>
        </form>
      </Modal>
    );
  }
}

export default ReelForm;
