// @flow
import React, { Component } from 'react';

import { ButtonGroup, Button, Modal } from 'react-bootstrap';
import RangeSlider from '../common/RangeSlider';
import moment from 'moment';
import NumericInput from 'react-numeric-input';

import Event from "./StubHubEvent";

import './RaffleForm.css'

type Props = {
  event: Event
}

class RaffleEventForm extends Component {

  props: Props;
  
  state: {
    tickets: number,
    price: number,
    eventDate: date
  }

  constructor(props: Props) {
    super(props)

    let evt = this.props.event
    let max = Math.ceil(evt.ticketInfo.maxPriceWithCurrencyCode.amount)
    let min = Math.ceil(evt.ticketInfo.minPriceWithCurrencyCode.amount)

    let initialPrice = min + Math.ceil((max - min) / 10); // min + 10%
    let eventDate  = moment(evt.eventDateLocal)
    this.state = {  tickets: 1, 
                    price: initialPrice, 
                    eventDate: eventDate
                  };
  }
  
  formatDate(str: string): string {
    return moment(str).format('dddd MMM DD, hh:mma');
  }

  calculateEstimatedCost() {
    return this.state.tickets * this.state.price;
  }

  setTickets(num: number) {
    this.setState({ tickets: num || 1 });
  }

  priceChange(val: RangeSlider.Data) {
    this.setState({ price: val.from });
  }
    
  getFormData() {
    let evt = this.props.event;
    return { 
      event_name: evt.name,
      event_date: evt.eventDateLocal,
      event_location: evt.venue.name,
      ticket_count: this.state.tickets,
      estimated_cost: this.calculateEstimatedCost(),
      image_url: evt.imageUrl
    }
  }
      
  render() {
    let evt = this.props.event;
    let max = Math.ceil(evt.ticketInfo.maxPriceWithCurrencyCode.amount);
    let min = Math.ceil(evt.ticketInfo.minPriceWithCurrencyCode.amount);
    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>Select Tickets Number</Modal.Title>
        </Modal.Header>
        <Modal.Body className="gray-bg">
           <h3>{evt.name}</h3>
           <div className="row">
             <div className="col-lg-4">
               <div className="ibox">
               <div className="ibox-content">
                 <img src={evt.imageUrl} alt="image" style={{width: "100%"}} />
                 <small className="text-muted">
                   {evt.venue.name} <br />
                   {this.formatDate(evt.eventDateLocal)}
                 </small>
               </div>
               </div>
             </div>
             <div className="col-lg-8" style={{ borderLeft: '1px solid #ccc'}}>
               <h4>Number of Tickets</h4>
               <NumericInput 
                 min={1} 
                 max={10} 
                 value={this.state.tickets} 
                 onChange={(ticketsNumber) => this.setTickets(ticketsNumber)} 
                 className="form-control"
               />
               <h4 className="m-t-lg">Ticket Price</h4>
               <p>
                 Ticket prices for this event range between ${min} and ${max} per ticket.
                 Please select desired ticket price below.
               </p>
               <RangeSlider value={this.state.price} min={min} max={max} labels={{'min': min, 'max': max }} onChange={(v)=> this.priceChange(v)}/>
               <hr />
               <p className="pull-right">
                 <strong className="text-uppercase">Estimated Cost:</strong> <span className="font-bold">${this.calculateEstimatedCost()}</span>
               </p>
             </div>
           </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.props.onCloseBtnClick()}>Cancel</Button>
          <Button bsStyle="primary" onClick={() => this.props.showDetailsForm()}>Next</Button>
        </Modal.Footer>
      </div>
    )
  }

}

export default RaffleEventForm;
