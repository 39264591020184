import React, { Component } from 'react';
import { client } from '../Client';

import debounce from 'lodash/debounce';

var styles = {
  spotlightWrapper: {
    position: 'absolute',
    top: '80px',
    left: 0,
    right: 0,
    width: '100%',
    zIndex: '1000',
    // moved
    boxShadow: '0 25px 60px 10px rgba(0, 0, 0, 0.3)',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    width: '428px',
    fontFamily: 'HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif',
    backgroundColor: '#f8f5f4',
    colors: '#000',
    margin: 'auto',
  },
  spotlight: {
    display: 'block',
    width: '100%',
    height: '56px',
    // -moz-border-radius: '5px',
    // -webkit-border-radius: '5px',
    // -moz-appearance: 'none',
    // -webkit-appearance: 'none',
    // -moz-box-shadow: '0 25px 60px 10px rgba(0, 0, 0, 0.3)',
    // -webkit-box-shadow: '0 25px 60px 10px rgba(0, 0, 0, 0.3)',
    border: 'none',
    outline: 'none',
    fontSize: '1.6rem',
    backgroundColor: '#f8f5f4',
    backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDIyLjcgMjIuNSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjIuNyAyMi41IiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIHNsaWNlIiB3aWR0aD0iMjIuNyIgaGVpZ2h0PSIyMi41Ij4+DQogICAgIDxjaXJjbGUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2NzY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgY3g9IjguMSIgY3k9IjguNCIgcj0iNi44Ii8+DQogICAgIDxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzY2Njc2NiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHgxPSIxMy4yIiB5MT0iMTIuOSIgeDI9IjIxLjUiIHkyPSIyMS40Ii8+DQo8L3N2Zz4NCg==)',
    backgroundPosition: 'left 17px center',
    backgroundRepeat: 'no-repeat',
    //backgroundSize: '3.5%',
    padding: '0 52px'
  }
}

class SpotlightSearch extends Component {

  firstChar = false

  state = {
    visible: false,
    pissed: false,
    text: '',
    results: []
  }

  constructor(props) {
    super(props);
    this.handleDocumentKeyup.bind(this);
    this.handleDocumentKeydown.bind(this);
    this.handleDocumentClick.bind(this);
    this.performSearch = debounce(this.performSearch, 300);
  }

  hideSpotlight() {
    this.setState({visible: false, text: ''});
  }

  showSpotlight(){
    this.setState({visible: true});
  }

  handleTextChange(e) {
    let newText = e.target.value;
    this.setState({ text: newText });
    this.performSearch(newText);
  }

  performSearch(search) {
    client.getPaginatedEmployees(1, search)
    .then((resp) => {
       this.setState({ results: resp.employees.slice(0, 5) });
    });
  }

  /* add listener for keydown to detect shortcut */
  handleDocumentKeydown = (event) => {
    if(!event.target.classList.contains('spotlight-search-field')) return;

    //17 = CTRL, 32 = SPACE
    //save char code in var if it is ctrl
    if (event.which === 17) {
      this.firstChar = event.which;
    }
    //if firstchar is ctrl and the current keydown event char is space, continue
    if (this.firstChar === 17 && event.which === 32) {
      if(!this.state.visible || this.state.visible == undefined){
        this.showSpotlight();
      } else {
        this.hideSpotlight();
      }
      this.firstChar = false;
    }

    if (event.which === 13 && !this.state.pissed) {
      alert('No users found');
      this.setState({ pissed: true });
    }
  }


  componentWillMount() {
    document.addEventListener('keyup', this.handleDocumentKeyup, false);
    document.addEventListener('keydown', this.handleDocumentKeydown, false);
    document.addEventListener('click', this.handleDocumentClick, false);
  }

  componentDidUpdate() {
    if (this.state.visible) {
      this.refs.textField.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleDocumentKeyup, false);
    document.removeEventListener('keydown', this.handleDocumentKeydown, false);
    document.removeEventListener('click', this.handleDocumentClick, false);
  }

  // delete firstchar var on keyup to ensure "shortcut" behaviour and that ther spotlight doesn't show up if not wanted
  handleDocumentKeyup = () => {
    this.firstChar = false;
  }

  // hide spotlight when clicked anywhere
  handleDocumentClick = (event) => {
    
    if(event.target.classList.contains('spotlight-search-field')) return;

    this.hideSpotlight();
  }

  render() {
    let child = this.props.children;// supports one child - template for the result
    let results = this.state.results.map(result => {
      return React.cloneElement(child, { item: result });
    });
    return (
      <div className="b-r-md" style={{...styles.spotlightWrapper,display: this.state.visible ? 'block' : 'none' }}>
        <input ref="textField" type="text" className="b-r-md spotlight-search-field" style={styles.spotlight}
            placeholder={this.props.placeholder ? this.props.placeholder : 'Spotlight-Search'}
            onClick={(e) => e.stopPropagation() }
            onChange={(e) => this.handleTextChange(e)}/>
          { results.length > 0 &&
              <ul className="list-group" style={{marginBottom: 0}}>
              {results}
              </ul>
          }
      </div>
    );
  }
}

export default SpotlightSearch;
