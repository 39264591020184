import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { pollClient } from '../../clients/PollClient';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { client } from '../Client';
import Select from '../form_components/Select';
import Textarea from '../form_components/Textarea';
import Input from '../form_components/Input';
import DateField from '../form_components/DateField';
import DateFieldLink from '../form_components/DateFieldLink';
import moment from 'moment';
import { getRandomInt } from '../../helpers/Calculation';
import { toastrNotification } from '../../helpers/Toastr';

class PollForm extends Component {

  state = {
    showModal: false,
    canSubmit: false,
    errorMessage: '',
    loading: false,
    minChoicesNumber: 2,
    maxChoicesNumber: 5, 
    choices: [],
    fields: {}
  }
  
  componentDidMount = () => {
    let pollFields = this.props.poll;
    this.setState({ fields: pollFields })
  }

  close = () => {
    this.setState({ showModal: false, errorMessage: '' });
  }

  open = () => {
    pollClient.getPoll(this.props.poll.id).
    then((poll) => {
      if (poll.can_be_modified) {
        let pollFields = poll;
        this.setState({ fields: pollFields, choices: poll.choices, showModal: true, loading: false });
      } else {
        this.props.showUnableToEditNotification();
      }
    });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
    
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  } 
  
  onDateChange = (field, date) => {
    const fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields: fields });
  }
  
  onChoiceChange = (choiceId, evt) => {
    const mChoices = this.state.choices.map((ch) => {
      if (ch.id === choiceId) {
        let choice = Object.assign({}, ch);
        choice.title = evt.target.value;
        return choice;
      } else {
        return ch;
      }
    })
    this.setState({ choices: mChoices }); 
  }
  
  addChoice = () => {
    const newChoices = this.state.choices.concat([ { id: getRandomInt(0, 1000), title: "" } ]);
    this.setState({ choices: newChoices })
  }
  
  removeChoice = (choiceId) => {
    const newChoices = this.state.choices.filter(ch => ch.id !== choiceId);
    this.setState({ choices: newChoices });
  }   
    
  formSubmit = (data) => {
    const choicesList = this.state.choices;
    const choicesListInvalid = choicesList.some(function (item) { return item.title.length == 0 });
    if (choicesListInvalid) {
      toastrNotification({success: false, message: "Choice can't be empty."})
      return 
    };
    data.choices = choicesList;
    this.setState({ loading: true });
    pollClient.updatePoll(this.props.poll.id, data)
    .then((poll) => {
        if (poll.id) {
          this.props.onUpdatePoll();
          toastrNotification({success: true, title: 'A poll was successfully updated.'});
          this.close();
        } else {
          let formErrors = poll;
          setTimeout(() => {
            this.setState({ loading: false });
            this.refs.form.updateInputsWithError(formErrors);
            toastrNotification({success: false, title: 'Unable to update poll.'});
          }, 1500)
        }
    });
  }

 render() {

    return (
      <Modal show={this.state.showModal} onHide={this.close} >
        <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal employee-form">
          <Modal.Header closeButton>
            <Modal.Title>Edit Poll</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.errorMessage.length > 0 && 
            <div className="alert alert-danger">
              {this.state.errorMessage}
            </div>
            }
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="question" className="sr-only"></label>
                <Textarea 
                  name="question"
                  placeholder="Question *"
                  className="form-control"
                  tabIndex="1"
                  value={this.state.fields.question}
                  onChange={this.onInputChange}
                  required  
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-12">
                <p>You can add from 2 to 5 choices.</p>
                { 
                  this.state.choices.map((choice) => (
                    <div className="row" key={'ch'+choice.id}>
                      <div className="form-group col-sm-10">
                        <input 
                          name={'choice'+choice.id}
                          className="form-control"
                          value={choice.title}
                          onChange={(evt) => this.onChoiceChange(choice.id, evt)}  
                        />
                      </div>
                      <div className="form-group col-sm-2">
                        { this.state.choices.length > this.state.minChoicesNumber && 
                          <button className="btn btn-default pull-right" onClick={() => this.removeChoice(choice.id) }><FontAwesomeIcon icon={faTimes} aria-hidden="true"/></button>
                        }  
                      </div> 
                    </div> 
                  ))
                }
                { this.state.choices.length < this.state.maxChoicesNumber && 
                  <a onClick={() => this.addChoice()}>+ Add a choice</a> 
                }  
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-12">
                <div className="pull-left">&nbsp; expires on &nbsp;</div>
                <div className="pull-left">
                  <DateField name="expires_at" value={this.state.fields.expires_at}                   
                    customInput={<DateFieldLink />}
                    minDate={moment().add(1, "days").toDate()}
                    onChange={(date) => this.onDateChange('expires_at', date)}/>
                </div>
              </div>
            </div>                
          </Modal.Body>
          <Modal.Footer>
            <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Submit</LaddaButton>
            <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
        </Formsy>
      </Modal>
    );
  }
}

export default PollForm;
