import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { companySettingsClient } from '../clients/CompanySettingsClient';
import { client } from '../components/Client';

const mobileSwipableRoutes = [
    '/activity',
    '/conversations',
    '/notifications',
    '/mobile_menu',
]

export const NAVIGATE_LEFT = -1;
export const NAVIGATE_RIGHT = 1;

let swipeEnabled=true;
export function toggleSwipe(){
    swipeEnabled=!swipeEnabled
}

export function disableSwipe(){
    swipeEnabled=false;
}

async function nextPath(activeRoute,navigateTo) {
    if (activeRoute < 0) throw new Error('Invalid Path');
    const nextRoute = activeRoute + navigateTo;
    if (nextRoute < 0 || nextRoute >= mobileSwipableRoutes.length) throw new Error('Out of bounds');
    return nextRoute;
}

export async function getNextRoute(currentRoute, navigateTo) {
    if(!swipeEnabled) {
        toggleSwipe();
        return Promise.reject('Disabled');
    }
    const activeRouteIndex = mobileSwipableRoutes.indexOf(currentRoute);
    const nextRoute = await nextPath(activeRouteIndex,navigateTo);
    if (!client.adminLoggedIn() && nextRoute === 1)
        return companySettingsClient.checkUserAccess("conversation")
            .then(response => {
                if (response.success) return mobileSwipableRoutes[nextRoute];
                else return nextPath(nextRoute,navigateTo>0 ? NAVIGATE_RIGHT:NAVIGATE_LEFT)
                .then(nextRoute=> mobileSwipableRoutes[nextRoute]);
            });
    return Promise.resolve(mobileSwipableRoutes[nextRoute]);
}

export function SwipeDetector(props) {
    const handlers = useSwipeable({
        onSwipedLeft: props?.onSwipedLeft,
        onSwipedRight: props?.onSwipedRight,
        onSwipedUp: props?.onSwipedUp,
        onSwipedDown: props?.onSwipedDown,
        delta: props?.delta || 50,
        preventScrollOnSwipe: props?.preventScrollOnSwipe|| false,
    });

    return (
        <div {...handlers}>
            {props.children}
        </div>
    );
}