// @flow
import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ActivityMessageBox from '../activity/ActivityMessageBox';
import { client } from '../Client';

class ConversationMessage extends Component {

  state = {} // do not remove, otherwise componentWillUnmount will cause exception

  render() {
      const message = this.props.message;
      const currentUserIsAuthor = message.author.id === client.currentUser().id;
      const containerClass = currentUserIsAuthor ? "chat-message right" : "chat-message left"
     
      return (
        <div className={containerClass}>
            { !currentUserIsAuthor &&
              <img alt="image" src={message.avatar_image_url()} className="message-avatar"/>
            }
            <div className="message" style={{border: 'none'}}>         
              <span className="message-content">
                <ActivityMessageBox  content={message.body} />
              </span>
              <span className="message-date">{moment(message.created_at).format("h:mm A")}</span>
            </div>
        </div>
      )
    }

}

export default ConversationMessage;
