import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';
import Raffle from '../models/Raffle';

class RaffleClient {

  getRaffles() {
    return client.callApi(Config.API_BASE + '/universal_raffles.json')
      .then((raffles) => raffles.map((raffle) => (new Raffle(raffle))))
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }

  getRaffle(raffleId) {
    return client.callApi(Config.API_BASE + '/universal_raffles/' + raffleId + '.json')
      .then((raffleData) => (new Raffle(raffleData)))
      .catch((error) => {
        client.handleApiError(error);
        return [] ;
      });
  }

  getRaffleWinner(raffleId) {
    return client.callApi(Config.API_BASE + '/universal_raffles/' + raffleId + '/get_winner.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }

  createRaffle(raffleData) {
    return client.callApi(Config.API_BASE + '/universal_raffles.json', {}, 'POST', {raffle: raffleData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  cancelRaffle(raffleId) {
    return client.callApi(Config.API_BASE + '/universal_raffles/'+raffleId+'/cancel.json', {}, 'POST')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  hideRaffle(raffleId) {
  return client.callApi(Config.API_BASE + '/universal_raffles/'+raffleId+'/hide.json', {}, 'POST')
      .catch((error) => {
          client.handleApiError(error);
          return {};
      });
  }

  createRaffleParticipant(participantData) {
    return client.callApi(Config.API_BASE + '/raffle_participants.json', {}, 'POST', {raffle_participant: participantData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  hideRafflewidget(participantId) {
    return client.callApi(Config.API_BASE + '/raffle_participants/'+ participantId +'/hide.json', {}, 'PUT')
      .catch((error) => {
        client.handleApiError(error);
        return {};
    });
  }

  updateParticipant(participant, data) {
    console.log(data)
    return client.callApi(Config.API_BASE + '/raffle_participants/'+ participant.id +'.json', {}, 'PUT', data)
      .catch((error) => {
        client.handleApiError(error);
        return {};
    });
  }

}

export const raffleClient = new RaffleClient();
