import React, { Component } from 'react';
import { client } from '../Client';
import { iconUrl, priceTag } from '../../helpers/Courses.js'

class StartCourse extends Component {

  startDocument = (doc, e) => {
    e.preventDefault();
    window.open(client.startDocumentUrl(doc), doc.title, 'height=600,width=800,resizable=yes,scrollbars=no,toolbar=no,status=no');
  }
  
  descriptionMarkup = (document) => {
    return {__html: document.rich_description};
  }    

 render() {
    return (
      <div>
      {/* package */}
      {this.props.document.package && this.props.document.items.map((doc) => (
        <div className="row" key={doc.coggno_document_id}>
          <div className="col-lg-11">
          <div className="feed-element">
            <a href="#" className="pull-left">
              <img alt="image" style={{height: '48px'}} src={iconUrl(doc)}/>
            </a>
            <div className="media-body ">
              <strong>{doc.title}</strong><br/>
              { doc.rich_description &&
              <div className="well">
                <div dangerouslySetInnerHTML={this.descriptionMarkup(doc)} />
              </div>
              }              
            </div>
          </div>
          </div>
          <div className="col-lg-1">
            <a className={'btn btn-primary btn-sm btn-block ' + (this.props.document.license ? '' : 'disabled')} 
            href='#'
            onClick={(e) => this.startDocument(doc, e)}
            >Start</a>
          </div>
        </div>
      ))}
      {/* document */}
      { !this.props.document.package &&
        <div className="row" key={this.props.document.coggno_document_id}>
          <div className="col-lg-11">
          <div className="feed-element">
            <div className="media-body ">
              <div className="well">
                {this.props.document.description}
              </div>
            </div>
          </div>
          </div>
          <div className="col-lg-1">
            <a className={'btn btn-primary btn-sm btn-block ' + (this.props.document.license ? '' : 'disabled')}
            href="#"
            onClick={(e) => this.startDocument(this.props.document, e)}
            >Start</a>
          </div>
        </div>
      }
      </div>
    )
  }
}

export default StartCourse;
