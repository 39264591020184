import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { acknowledgementClient } from '../../clients/AcknowledgementClient';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { client } from '../Client';
import Select from '../form_components/Select';
import Textarea from '../form_components/Textarea';
import Input from '../form_components/Input';
import FileInput from '../form_components/FileInput';
import DateField from '../form_components/DateField';
import DateFieldLink from '../form_components/DateFieldLink';
import AcknowledgementTextBox from './AcknowledgementTextBox';
import FileUploadProgress  from 'react-fileupload-progress';
import { addValidationRule } from 'formsy-react';

import moment from 'moment';
import { toastrNotification } from '../../helpers/Toastr';

addValidationRule('notEmptyText', function (values, value) {
  return value.replace(/(&nbsp;|\s|<([^>]+)>)/ig, "").length > 0;
});

let upload_message = '';

class AcknowledgementForm extends Component {

  state = {
    showModal: false,
    canSubmit: false,
    errorMessage: '',
    loading: false,
    fields: {
      message: '',
      expires_at: moment().add(7, 'days').toDate(),
      university_department_id: ""
    }
  };

  close = () => {
    this.setState({ showModal: false, errorMessage: '' });
  }

  open = () => {
    this.setState({ showModal: true, loading: false, canSubmit: false, errorMessage: '', fields: {message: '', expires_at: moment().add(7, 'days').toDate(), university_department_id: ""} });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
    
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  } 
  
  onDateChange = (field, date) => {
    const fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields: fields });
  }   

  formGetter = () => {
    let data = new FormData(document.getElementById('ack-form'))
    const mdl = this.refs.ackUpload.refs.ackForm.getModel();
    // Add formsy specific values, that are not regular input elements
    data.append('acknowledgement[message]', mdl.acknowledgement.message);
    data.append('acknowledgement[expires_at]', mdl.acknowledgement.expires_at);
    return data;
  }

  customFormRenderer = (onSubmit) => {
     return (
       <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref="ackForm" id="ack-form" className="form-horizontal employee-form">
         {this.state.errorMessage.length > 0 && 
         <div className="alert alert-danger">
           {this.state.errorMessage}
         </div>
         }          
         <div className="row">
           <div className="form-group col-sm-12">
             <label htmlFor="title" className="sr-only"></label>
             <Input 
               name="acknowledgement[title]"
               placeholder="Title *"
               className="form-control"
               value={this.state.fields.title}
               onChange={this.onInputChange} 
               required 
             />
           </div>
         </div>
         <div className="row">
           <div className="form-group col-sm-12">
             <label htmlFor="department_id" className="sr-only"></label>
             <Select 
               name="acknowledgement[university_department_id]"
               options={this.props.departments}
               placeholder="Select Group *"
               className="form-control"
               value={this.state.fields.university_department_id}
               onChange={this.onInputChange}
               required
             />
           </div>
         </div>
         <div className="row">
           <div className="form-group col-sm-12">
             <AcknowledgementTextBox name="acknowledgement[message]" value={this.state.fields.message} required validations='notEmptyText'/>                
           </div>
         </div>
         <div className="row">
           <div className="form-group col-sm-12">
               <FileInput 
                 name="acknowledgement[attachment]"
                 accept="application/pdf"
                 className="form-control"
                 onChange={this.onInputChange}
               />
               <small><i>Optional PDF attachment</i></small>               
           </div>            
         </div>           
         <div className="row">
           <div className="form-group col-sm-6">
             <div className="pull-left">Expires on &nbsp;</div>
             <div className="pull-left">
               <DateField name="acknowledgement[expires_at]" value={this.state.fields.expires_at}                   
                 customInput={<DateFieldLink />}
                 minDate={moment().toDate()}
                 onChange={(date) => this.onDateChange('expires_at', date)}/>
             </div>
           </div>
           <div className="col-sm-6 text-right">
             <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} onClick={onSubmit} loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Create</LaddaButton>
           </div>
         </div>                
       </Formsy>
     );
   }

   customProgressRenderer = (progress, hasError, cancelHandler) => {

      if (hasError || progress > -1 ) {
        let barStyle = {};
        barStyle.width = progress + '%';
        upload_message = (<span>{barStyle.width}</span>);
        if (hasError) {
          upload_message = (<span style={{'color': '#a94442'}}>Failed to upload ...</span>);
        }
        if (progress === 100){
          upload_message = '';
        }

        return (
          <span>
          { this.refs.ackUpload.refs.ackForm.getModel().acknowledgement.attachment !== undefined && 
            <div>
              <div className="file-upload-progress-wrapper">
                <div style={barStyle} className="progress-bar progress-bar-success file-upload-progress-bar"></div>
              </div>
              <button className="cancel-upload file-upload-cancel-button" onClick={cancelHandler}>
                <span>&times;</span>
              </button>
              <div style={{'clear':'left'}}>{upload_message}</div>
            </div>
          }
          </span>
        );
      } else {
        return;
      }
    }

   render() {
    const tooltip = (
      <Tooltip id="modal-tooltip">
      </Tooltip>
    )

    return (
      <Modal show={this.state.showModal} onHide={this.close} >
      <Modal.Header closeButton>
        <Modal.Title>New Acknowledgement</Modal.Title>
      </Modal.Header>
      <Modal.Body>      
        <FileUploadProgress url={acknowledgementClient.acknowledgementsUrl()} ref='ackUpload'
          method='post'
          formRenderer={this.customFormRenderer.bind(this)}
          progressRenderer={this.customProgressRenderer.bind(this)}
          formGetter={this.formGetter.bind(this)}
          beforeSend={(request) => {
            this.disableButton(); 
            request.setRequestHeader('authorization', client.getAuthorizationHeader());
            request.setRequestHeader('X-Client-Version', client.getClientVersion());
            request.setRequestHeader('X-App-Version', client.getAppVersion());
            return request; }}
          onError={(e, request) => {
              toastrNotification({success: false, message:'Unable to create acknowledgement.'});
              setTimeout(() => { this.close() }, 2000)
            }
          }
          onLoad={(e, request) => {
            let json = JSON.parse(request.response);
            this.props.onAddNewAcknowledgement();
            toastrNotification({success: json.success, message: json.id ? 'Acknowledgement has been created.' : 'Unable to create.'});
            this.close();
          }}
        />   
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.close}>Close</Button>
        </Modal.Footer>               
      </Modal>
    );
  }
}

export default AcknowledgementForm;
