import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Line } from 'react-chartjs-2'

import { kpiReportClient } from '../../clients/KpiReportClient';

class ActivityReport extends Component {
    
  state = {
    reportData: {},
    options: {},
    reportDataPresent: true
  };
  
  componentDidMount() {
    this.setState(this.state);
    this.getReportData();
  }
  
  getReportData = () => {
    kpiReportClient.getReportData('activity').then((data) => {      
      this.setState({ 
        reportData: { 
          labels: Object.keys(data),           
          datasets: [{ 
            label: 'Post Count', 
            data: Object.values(data), 
            borderColor: '#E95227',
            backgroundColor: '#E95227',
            tension: 0.44           
          }] 
        }, 
        options: { 
          scales: {
            y: {
              ticks: {
                beginAtZero: true,
                callback: function(value) {if (value % 1 === 0) {return value;}}
              }
            }
          },             
          plugins: { 
            legend: { display: true, position: 'bottom'},       
          }
        },
        reportDataPresent: true
      })
    });
  }
            
  
 renderReport = () => {
   if (this.state.reportDataPresent) {
     return (
       <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className="col-md-12">
              <Line data={this.state.reportData} options={this.state.options}/>
            </div>
          </div>
       </div>
     )
   } else {
     return (
       <div className="text-center">
         <h1>There is no data</h1>
       </div>
     )
   }
 }  
  
 render() {

    return (
      <div>
        <h2>Activity</h2>
        {this.renderReport()}
      </div>
    );
  }
}

export default ActivityReport;
