import Config from 'config';
import moment from 'moment';
import Employee from './Employee';
import { client } from '../components/Client';

class ConversationMessage {
  constructor(obj) {
    for (var prop in obj) {
      this[prop] = obj[prop];
    }
  }
  
  full_name() {
    return this.author.first_name + ' ' + this.author.last_name;
  }
  
  avatar_image_url() {
    const url = (this.author.avatar_url) ? this.author.avatar_url : 'img/avatar_missing.png'
    return url
  }
  
  created_date() {
    let date = moment.parseZone(this.created_at).format('MMMM DD')
    return date
  }
  
}

export default ConversationMessage;
