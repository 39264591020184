import React, { Component } from 'react';
import RawHtml from "react-raw-html";
import emojiOne from 'emojione';
import * as linkify from 'linkifyjs';
import hashtag from 'linkifyjs/plugins/hashtag';
import linkifyHtml from 'linkifyjs/html';
hashtag(linkify);

import "./ActivityMessageBox.css";

class ActivityMessageBox extends Component {
    
  render() {      
    let content = emojiOne.shortnameToImage(this.props.content);
    content = linkifyHtml(content, {
      formatHref: function (href, type) {
        if (type === 'hashtag') {
          href = '#'; //href.substring(1)
        }
        return href;
      },
      format: function (value, type) {
        if (type === 'url' && value.length > 50) {
          value = value.slice(0, 50) + '…';
        }
        return value;
      },
      className: function (href, type) {
         return 'new-link-' + type
      }
    });
    
    return (
      <div className="message-box" >
        <RawHtml.div>{ content }</RawHtml.div>
      </div>
    )
  }
}

export default ActivityMessageBox;
