import moment from 'moment';

export function currencyFormat(value) {
  if (isNaN(value)) return '-';
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(value);
}

export function mediumDateFormat(date) {
  // 2017/06/13 06:45:16 -0500
  return moment(date, 'YYYY/MM/D HH:mm:ss ZZ').format('MMM D, YYYY');
}

export function monthDateFormat(date) {
  // 2017/06/13 06:45:16 -0500
  return moment(date, 'YYYY/MM/D HH:mm:ss ZZ').format('M/YYYY');
}

export function diffDays(date1, date2) {
  var a = moment(date1);
  var b = moment(date2);
  return b.diff(a, 'days');
}