import React, { Component } from 'react';
import {Radio, RadioGroup} from 'react-ui-icheck';

import './ChoiceQuestion.css';

class ChoiceQuestion extends Component {
  
  /*
  state = {
    answer: ''
  }; 

  getAnswer = () => {
    return this.state.answer
  }

  buildAnswerHash = () => {
    return { survey_question_id: this.props.question.id, data: this.state.answer }
  }
*/

  handleChange = (event, choice) => {
    this.props.onUpdateAnswer(this.props.question, choice);
  } 
  
 render() {       
    return (
      <div className="row">
        <div className="col-md-4"><h4>{this.props.question.question}</h4></div>
        <div className="col-md-8">
          <div className="row choice_question">        
            <RadioGroup name={'q' + this.props.question.id} onChange={this.handleChange} value={this.props.answer}>
            {
              this.props.question.values.map((choice) => (
                <Radio key={'q' + this.props.question.id + choice} 
                  value={choice} 
                  radioClass="icheckbox_square-green" 
                  label={choice}
                  labelClassName="col-sm-3"
                />
              ))
            }
            </RadioGroup>
          </div>
        </div>    
      </div>  
    );
  }
}

export default ChoiceQuestion;