import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import './BadgePopover.css';

class BadgePopover extends Component {

  componentDidMount() {
  }

  getBadgeURL = () => {
    return `img/badges/Badges/${this.props.badge}.png`
  }

  render() {
    const popover = (
      <Popover id={'popover-' + this.props.id}>
        <div className='text-center'>
          {this.props.badge}
        </div>
      </Popover>
    );
    if (this.props.badge === 'Admin' && !this.props.adminBadgeEnabled)
      return (null);

    return (
      <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popover}>
        <img alt="badge" src={this.getBadgeURL()} className="badge-icon" />
      </OverlayTrigger>
    )
  }
}

export default BadgePopover;
