import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import ShiftRequest from '../../models/ShiftRequest';
import { toastrNotification } from '../../helpers/Toastr';
import SweetAlert from 'sweetalert-react';
import { shiftRequestClient } from '../../clients/ShiftRequestClient';
import moment from 'moment';
import { statusLabel } from '../../helpers/ShiftRequests';
import ApproveApplicantForm from './ApproveApplicantForm';

import '../raffle/RaffleView.css'

class AdminShiftRequestDetails extends Component {

    state = {
      shiftRequest: new ShiftRequest({applicants: []}),
      spinnerClass: 'sk-loading',
      showDeleteNotification: false,
      shouldRedirect: false
    }

    componentDidMount() {
      this.setState(this.state);
      this.getShiftRequest();
    }

    getShiftRequest = () => {
      shiftRequestClient.getShiftRequest(this.props.requestId).then((request) => {
        this.setState({ shiftRequest: request, spinnerClass: ''})
      });
    }
    
    handleDeleteShiftRequestClick() {
      this.setState({ showDeleteNotification: true });
    }
    
    handleApproveApplicantClick() {
      this.refs.approveForm.open();
    }

    deleteShiftRequest() {
      this.setState({ showDeleteNotification: false });
      shiftRequestClient.deleteShiftRequest(this.state.shiftRequest.id).then((response) => {
        if (response.success == true) {
          toastrNotification({ success: true, message: 'Shift request was successfully deleted.' })
          this.setState({ shouldRedirect: true });
        } else {
          toastrNotification({ success: false, message: 'Unable to delete request.' });
          this.getShiftRequest();
        }
      });
    }

    render() {
        if (this.state.shouldRedirect) {
          return (
            <Redirect to="/pick_up_shift_requests" />
          );
        } else {
        return (
            <div className={'wrapper wrapper-content animated fadeInUp ' + this.state.spinnerClass}>
                <div className="sk-spinner sk-spinner-double-bounce">
                  <div className="sk-double-bounce1"></div>
                  <div className="sk-double-bounce2"></div>
                </div>
                
                <div className="row">
                  <div className="col-sm-8 col-sm-offset-2">
                    <Link to="/pick_up_shift_requests" className="btn btn-white btn-sm pull-right m-b-sm hidden-xs">Back to Shift Requests</Link>
                    <div className="ibox">
                      <div className="ibox-title">
                        <h5>Request Details</h5>
                      </div>
                      <div className="ibox-content">
                        <div className="row">
                          <div className="col-sm-12">
                            <dl className="dl-horizontal">
                              <dt>Status:</dt>
                              <dd>{statusLabel(this.state.shiftRequest)}</dd>
                            </dl>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <dl className="dl-horizontal">
                              <dt>Employee:</dt>
                              <dd>
                                <Link to={`/employee/${this.state.shiftRequest.employee_id}`}>
                                  {this.state.shiftRequest.employee_full_name}  
                                </Link>
                              </dd>
                              <dt>Group:</dt>
                              <dd>
                                {this.state.shiftRequest.department}
                              </dd>
                              <dt>Start date:</dt>
                              <dd>{moment(this.state.shiftRequest.start_date).format('D MMM YYYY')}</dd> 
                              <dt>End date:</dt>
                              <dd>{moment(this.state.shiftRequest.end_date).format('D MMM YYYY')}</dd>
                              <dt>Start time:</dt>
                              <dd>{this.state.shiftRequest.start_time}</dd> 
                              <dt>End time:</dt>
                              <dd>{this.state.shiftRequest.end_time}</dd>
                              <dt>Created at:</dt>
                              <dd>{moment(this.state.shiftRequest.created_at).format('D MMM YYYY')}</dd>
                              { this.state.shiftRequest.comment && this.state.shiftRequest.comment.length > 0 &&
                                <span>  
                                  <dt>Comment:</dt>
                                  <dd>{this.state.shiftRequest.comment}</dd>
                                </span>
                              }  
                              <dt>Applicants:</dt>
                              <dd>
                                {this.state.shiftRequest.applicants.map((applicant, index) => 
                                  <Link key={'ap'+applicant.employee_id} to={`/employee/${applicant.employee_id}`}>
                                    {`${applicant.employee_full_name}` + ((this.state.shiftRequest.applicants.length-1 == index) ? "" : ", ")} 
                                  </Link>  
                                )}
                              </dd>
                            </dl>
                            { this.state.shiftRequest.approved() &&
                              <dl className="dl-horizontal">
                                <dt>Approved Employee:</dt>
                                <dd>
                                  <Link to={`/employee/${this.state.shiftRequest.performer_id}`}>
                                    {this.state.shiftRequest.performer_full_name} 
                                  </Link>
                                </dd>
                              </dl>}
                          </div>
                        </div>                         
                        <div className="row m-b-sm">
                          <div className="col-sm-12">
                            <br/>
                              { this.state.shiftRequest.canBeApproved() &&
                                <button className="btn btn-primary m-r-sm" onClick={() => this.handleApproveApplicantClick()}>Approve Applicant</button>
                              }
                              {this.state.shiftRequest.canBeDeleted() &&
                                <button className="btn btn-white" onClick={() => this.handleDeleteShiftRequestClick()}><FontAwesomeIcon icon={faTimes}/> Delete</button>
                              }
                              <SweetAlert
                                show={this.state.showDeleteNotification}
                                type="warning"
                                title="Are you sure?"
                                confirmButtonColor="#DD6B55"
                                showCancelButton
                                text="This operation can not be undone."
                                onConfirm={() => this.deleteShiftRequest()}
                                onCancel={() => this.setState({ showDeleteNotification: false })}
                              />
                              <ApproveApplicantForm 
                                shiftRequest={this.state.shiftRequest} 
                                ref="approveForm"
                                getShiftRequest={this.getShiftRequest}
                              />
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>  
                </div> 
            </div>
        )
      }
    }
}

export default AdminShiftRequestDetails
