// @flow
import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { client } from '../Client';
import { surveyQuestionClient } from '../../clients/SurveyQuestionClient';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { toastrNotification } from '../../helpers/Toastr';
import Textarea from '../form_components/Textarea';
import Select from '../form_components/Select';
import TypeaheadSelect from '../form_components/TypeaheadSelect';

class EditQuestionModal extends Component {
      
  state = {
    showModal: false,
    canSubmit: false,
    fields: {
      category_name: '',
      question_type: '',
      question: ''
    }
  };
  
  componentDidMount() {
    let fields = this.state.fields;
    fields.question = this.props.question.question;
    fields.question_type = this.props.question.qtype_number;
    this.setState({ fields: fields });
  };
              
  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true });
  }
  
  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  resetForm = () => {
     formsy.reset();
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }

  formSubmit = (data) => {
    surveyQuestionClient.updateSurveyQuestion(this.props.question.id, data)
    .then((response) => {
        if (response.success) {
          this.props.reloadSurvey();
          toastrNotification({success: true, title: 'Survey Question was successfully updated'});
          this.close();
        } else {
          this.refs.form.updateInputsWithError(response.errors);
        }
    });
  }
  
  
 render() {
    
    const questionTypes = [
      {title: 'Select question type *', value: ''},
      {title: 'Yes/No', value: 2},
      {title: 'Single choice - 5 choices', value: 3},
      {title: 'Scale of 10', value: 1},
      {title: 'Rating through star', value: 4},
      {title: 'Feedback', value: 5}
    ]
    
    const categoryOptions = this.props.categories.map((category) => {
           let item = {};
           item.id = category.id;
           item.title = category.name;

           return item;
         });
         

    return (
      <div>
        <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Question</Modal.Title>
          </Modal.Header>
          <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal employee-form">
            <Modal.Body>
              <div className="row">
                <div className="col-sm-12">   
                  <div className="form-group">
                    <label htmlFor="category" className="sr-only"></label>
                    <TypeaheadSelect
                      name="category_name"
                      options={categoryOptions}
                      defaultSelectedId={this.props.question.survey_category_id}
                      placeholder="Select category *"
                      tabIndex="1"
                      labelKey="title"
                      allowNew={true}
                      newSelectionPrefix="Add new category: "
                      onChange={this.onInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-12">   
                  <div className="form-group">
                    <label htmlFor="question_type" className="sr-only"></label>
                    <Select
                      name="question_type"
                      options={questionTypes}
                      placeholder="Select question type*"
                      className="form-control"
                      tabIndex="2"
                      value={this.state.fields.question_type}
                      onChange={this.onInputChange}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12">   
                  <div className="form-group">
                    <label htmlFor="question" className="sr-only"></label>
                    <Textarea
                      name="question"
                      placeholder="Type question *"
                      className="form-control"
                      tabIndex="3"
                      value={this.state.fields.question}
                      onChange={this.onInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>  
            <Modal.Footer>  
              <Button
                bsClass="btn btn-primary"
                type="submit"
                disabled={!this.state.canSubmit}
              >Update</Button>
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
          </Formsy>  
        </Modal>
      </div>  
    );
  }
}

export default EditQuestionModal;
