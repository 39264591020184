import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { client } from '../components/Client';
import SignupForm from '../components/signup/SignupForm';

class SignupView extends Component {

  render() {
        return (
          <div className="container">
            <div className="row signup-form">
              <div className="ibox float-e-margins">
                <SignupForm />
              </div>
            </div>
          </div>
        )
  }
}

export default SignupView;
