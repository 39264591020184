import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { client } from "../components/Client";
import LoginForm from "../components/login/LoginForm";
import ForgotPasswordForm from "../components/login/ForgotPasswordForm";
import "./Login.css";

class LoginView extends Component {
  state = {
    shouldRedirect: false,
    forgotPassword: false,
    resetLinkSent: false,
  };

  enterAccount = () => {
    this.setState({ shouldRedirect: true });
  };

  showForgotPasswordForm = () => {
    this.setState({ forgotPassword: true });
  };

  hideForgotPasswordForm = () => {
    this.setState({ forgotPassword: false });
  };

  resetLinkSent = () => {
    this.setState({ resetLinkSent: true });
  };

  redirectPath = () => {
    const locationState = this.props.location.state;
    const pathname =
      locationState && locationState.from && locationState.from.pathname;
    return pathname || "/activity";
  };

  render() {
    if (this.state.shouldRedirect) {
      return (
        <>
          <Redirect to={this.redirectPath()} />
        </>
      );
    } else {
      return (
        <div className="container">
          <div className="row signin-form">
            <div className="ibox float-e-margins" style={{ boxShadow: "none" }}>
              {!this.state.forgotPassword && (
                <LoginForm
                  onForgotPassword={this.showForgotPasswordForm}
                  onEnterAccount={this.enterAccount}
                />
              )}
              {this.state.forgotPassword && !this.state.resetLinkSent && (
                <ForgotPasswordForm
                  onCancel={this.hideForgotPasswordForm}
                  onSubmitEmail={this.resetLinkSent}
                />
              )}
              {this.state.resetLinkSent && (
                <div style={{ borderRadius: "inherit" }}>
                  <div className="ibox-title">
                    <h5>
                      <img src="img/logo2.png" height="23" />
                    </h5>
                  </div>
                  <div className="ibox-content">
                    <div className="form-horizontal">
                      <div className="form-group">
                        <div className="col-lg-10 col-lg-offset-1">
                          <h3>
                            <p>We've sent you instructions!</p>
                          </h3>
                          <p>
                            <small>
                              Please check your inbox and follow the
                              instructions to reset the password.
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default LoginView;
