import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import 'react-quill/dist/quill.snow.css'
import '../../../public/styles/quill-css-fixes.css';
import { toastrNotification } from './../../helpers/Toastr';
import { client } from '../Client';

let message = '';

class AddNoteForm extends Component {

  state = {
    showModal: false,
    categories: [],
    warning: '',
    fields: {
      title: '',
      note: null,
      category_id: null,
      employee_id: null      
    }
  };

  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true, fields: {employee_id: this.props.employee.id, text: ''}});
    client.getAttachmentCategories().then((categories) => {
      this.setState({ categories: categories })
    });
  }

  disableButton = (warn = '') => {
    this.setState({fields: {category: null, title: null, file: null}});
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }

  onEditorChange = (value) => {
    const fields = this.state.fields;
    fields['text'] = value;
    this.setState({ fields: fields });    
  };
  
  canSubmit = () => {
    return (!!this.state.fields.category_id && !!this.state.fields.title);
  }

  formSubmit = () => {
    const _this = this;
    
    //let fields = this.state.fields;
    const fields = Object.assign({}, this.state.fields, {text: this.state.fields.text.toString('html')});
    //fields.text = fields.toString('html');
    
    
    client.createNote(fields).then((resp) => {
        if (resp.success) {
          //_this.props.onAddNewNote(employee)
          toastrNotification({success: true, message:'Note added successfully'});
          _this.props.onAddNote();
          _this.close()
        } else {
          toastrNotification({success: false, message: ('Unable to add the note: ' + resp.message) })
          //_this.refs.form.updateInputsWithError(employee);
        }
    });
  }

 render() {
  return (
      <div>
        <button className="btn btn-white btn-sm  btn-block btn-outline" onClick={this.open}><FontAwesomeIcon icon={faPlus}/> Add Note</button>
        <Modal show={this.state.showModal} onHide={this.close}>
          <Formsy onValidSubmit={this.formSubmit} ref="form" className="form-horizontal">
            <Modal.Body>
              <h3 className="m-b">Add Note</h3>
                <div className="row">
                  <div className="col-sm-12">
                    <input type="text" className="form-control" name="title" placeholder="Enter Title *" onChange={this.onInputChange}/>
                  </div>
                </div>
                <br/>
                <div className="row">
                  <div className="col-sm-12">
                    <select className="form-control" tabIndex="-1" name="category_id" onChange={this.onInputChange} defaultValue="">
                      <option key="-1" value="" disabled>Choose category *</option>
                      {
                         this.state.categories.map((category) => (
                           <option key={category.id} value={category.id}>{category.title}</option>
                         ))
                       }
                    </select>
                  </div>
                </div>
                <br/>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="add-note-editor">
                      <ReactQuill value={this.state.fields.text} onChange={this.onEditorChange}/>
                    </div>
                  </div>
                </div>
            </Modal.Body>
            <div className="clearfix"></div>
            <Modal.Footer>
              <Button bsClass="btn btn-primary" type="submit" disabled={!this.canSubmit()}>Save</Button>
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
          </Formsy>
        </Modal>
      </div>
    );
  }
}

export default AddNoteForm;
