// @flow
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import NotificationRow from './NotificationRow';
import InfiniteScroll from 'react-infinite-scroll-component';
import { notificationClient } from '../../clients/NotificationClient';

class NotificationsList extends Component {

  state = {
    page: 1,
    notifications: [],
    hasMoreNotifications: true,
    spinnerClass: 'sk-loading'
  }

  initNotifications = (markRead = true) => {
    const page = 1;
    this.setState({ spinnerClass: 'sk-loading', notifications: [] })
    notificationClient.getNotifications(page).
      then((notifications) => {
        this.setState({
          notifications: notifications,
          page: page + 1,
          hasMoreNotifications: notifications.length > 0,
          spinnerClass: ""
        });
        if (markRead)
          this.markNotificationsRead(notifications.map(notif => notif.id))
      });
  }

  getNotifications = () => {
    notificationClient.getNotifications(this.state.page).
      then((notifications) => {
        const newNotifications = this.state.notifications.concat(notifications);
        const hasMore = notifications.length > 0 ? true : false;
        this.setState({
          notifications: newNotifications,
          page: this.state.page + 1,
          hasMoreNotifications: hasMore
        });
        this.markNotificationsRead(notifications.map(notif => notif.id))
      });
  }

  markNotificationsRead = (notificationIds) => {
    notificationClient.markNotificationsRead(notificationIds);
  }

  render() {
    let sp = {
      dataLength: this.state.notifications.length,
      next: this.getNotifications,
      hasMore: this.state.hasMoreNotifications,
      useWindow: false,
      height: this.state.notifications.length > 4 ? 250 : ((this.state.notifications.length + 1) * 38)
    }

    return (
      <ul className="dropdown-menu dropdown-messages notification-messages" id="notificationMessagesBox">
        <div className={'wrapper wrapper-content' + this.state.spinnerClass}>
          <div className="sk-spinner sk-spinner-double-bounce">
            <div className="sk-double-bounce1"></div>
            <div className="sk-double-bounce2"></div>
          </div>
          {this.state.notifications.length == 0 && !this.state.hasMoreNotifications &&
            <div className="text-center">
              <b>There are no notifications.</b>
            </div>}
          <InfiniteScroll {...sp} >
            {
              this.state.notifications.map((notification, index) => (
                <NotificationRow onMarkRead={() => this.markNotificationsRead([notification.id])} notification={notification} key={'notif' + notification.id} />
              ))
            }
          </InfiniteScroll>
        </div>
      </ul>
    )
  }

}

export default NotificationsList;
