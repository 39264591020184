import React, { Component } from "react";
import "./ReelReport.css";
import BottomSheet from "./BottomSheet";
class ReelReport extends Component {
  state = {
    openSheet: false,
  };

  openSheet = () => {
    this.setState({ openSheet: true });
  };

  closeSheet = () => {
    this.setState({ openSheet: false });
  };

  render() {
    return (
      <BottomSheet
        openSheet={this.state.openSheet}
        onClose={this.closeSheet}
        height={30}
        hideClose
      >
        <div className="report-main">
          <form>
            <textarea
              type="text"
              placeholder="About the reel..."
              id="report-input"
            />
          </form>
          <div className="report-btns">
            <div className="cancel-btn" onClick={() => this.closeSheet()}>
              <span>
                <svg
                  viewBox="0 0 256 256"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M151.453 128.002L251.135 28.3155C257.622 21.8314 257.622 11.3473 251.135 4.86314C244.651 -1.62105 234.167 -1.62105 227.683 4.86314L127.998 104.549L28.3172 4.86314C21.8301 -1.62105 11.3494 -1.62105 4.86531 4.86314C-1.62177 11.3473 -1.62177 21.8314 4.86531 28.3155L104.547 128.002L4.86531 227.688C-1.62177 234.172 -1.62177 244.656 4.86531 251.141C8.09671 254.375 12.3455 256 16.5913 256C20.837 256 25.0828 254.375 28.3172 251.141L127.998 151.454L227.683 251.141C230.917 254.375 235.163 256 239.409 256C243.654 256 247.9 254.375 251.135 251.141C257.622 244.656 257.622 234.172 251.135 227.688L151.453 128.002Z"
                    fill="#676A6C"
                  />
                </svg>
              </span>
              <span className="text">Cancel</span>
            </div>
            <div className="submit-btn">
              <span>
                <svg
                  viewBox="0 0 256 256"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M212.025 10.345C200.423 3.384 188.055 0 174.213 0C157.652 0 141.396 4.8765 125.675 9.5925C110.317 14.1995 95.8105 18.551 81.4525 18.5525C81.4505 18.5525 81.4485 18.5525 81.4465 18.5525C71.8475 18.5525 63.155 16.5205 55 12.361V7.5C55 3.358 51.642 0 47.5 0C43.358 0 40 3.358 40 7.5V16.776V118.724V248.5C40 252.642 43.358 256 47.5 256C51.642 256 55 252.642 55 248.5V130.727C63.2835 133.926 72.026 135.5 81.4525 135.5C98.013 135.5 114.268 130.624 129.988 125.908C145.347 121.3 159.854 116.948 174.213 116.948C185.259 116.948 195.103 119.632 204.307 125.155C206.624 126.545 209.509 126.582 211.861 125.251C214.212 123.92 215.666 121.426 215.666 118.724V16.776C215.666 14.1415 214.283 11.7 212.025 10.345Z"
                    fill="#676A6C"
                  />
                </svg>
              </span>
              <span className="text">Submit</span>
            </div>
          </div>
        </div>
      </BottomSheet>
    );
  }
}
export default ReelReport;
