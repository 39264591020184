import React, { Component } from 'react';
import SweetAlert from 'sweetalert-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import 'sweetalert/dist/sweetalert.css';

class RemoveQuickDocumentLink extends Component {

  state = {
    show: false
  }

  handleClick() {
    this.setState({ show: true })
  }

  handleQuickDocumentRemove = () => {
    this.props.onRemoveQuickDocument(this.props.course.id);
    this.setState({ show: false });
  }

  render() {
      return (
        <div className="m-t text-right">
          <a className="btn btn-xs btn-primary" onClick={() => this.handleClick()}><FontAwesomeIcon icon={faTimes}/> Delete</a>
          <SweetAlert
            show={this.state.show}
            type="warning"
            title="Are you sure to remove this document?"
            confirmButtonColor="#DD6B55"
            showCancelButton
            text="This operation can not be undone."
            onConfirm={() => this.handleQuickDocumentRemove()}
            onCancel={() => this.setState({ show: false })}
          />
        </div>
      )
  }

}

export default RemoveQuickDocumentLink;
