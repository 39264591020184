import React, { Component } from 'react';

class CourseProgress extends Component {
  
 render() {
    return ( 
<div>
  {this.props.document.license && this.props.document.license.score > 0 &&
  <div className="row">
      <div className="col-lg-12">
          <dl className="dl-horizontal">
              <dt>Score:</dt>
              <dd>
                  <div className="progress progress-striped active m-b-sm">
                      <div style={{width: this.props.document.license.score + '%'}} className="progress-bar"></div>
                  </div>
                  <small>score <strong>{this.props.document.license.score}%</strong>.</small>
              </dd>
          </dl>
      </div>
  </div>
  }
</div>
    )
  }
}

export default CourseProgress;



