import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { groupClient } from '../../clients/GroupClient';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { client } from '../Client';
import Textarea from '../form_components/Textarea';
import Input from '../form_components/Input';
import { Checkbox } from 'react-ui-icheck';
import { toastrNotification } from '../../helpers/Toastr';

class NewGroupForm extends Component {

  state = {
    showModal: false,
    canSubmit: false,
    errorMessage: '',
    loading: false,
    fields: {}
  };

  close = () => {
    this.setState({ showModal: false, errorMessage: '' });
    
  }

  open = () => {
    this.setState({ 
      showModal: true, 
      loading: false,
      fields: {
        title: '',
        description: '',
        is_private: false
      }
    });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
    
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }
  
  onPrivateChange = (evt) => {
    const fields = this.state.fields;
    fields["is_private"] = !this.state.fields.is_private;
    this.setState({ fields: fields });
  }  
        
  formSubmit = (data) => {
    let formData = data;
    formData.is_private = this.state.fields.is_private;
    this.setState({ loading: true });
    groupClient.createGroup(data)
    .then((group) => {
        if (group.id) {
          this.props.addNewGroup();
          toastrNotification({ success: true, title: 'New group was successfully created' });
          this.close();
        } else {
          let formErrors = group;
          this.refs.form.updateInputsWithError(formErrors);
          toastrNotification({ success: false, title: 'Unable to create group.' });
          setTimeout(() => {
            this.setState({ loading: false })
          }, 1500)
        }
    });
  }

 render() {

    return (
      <Modal show={this.state.showModal} onHide={this.close} >
        <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal employee-form group-form">
          <Modal.Header closeButton>
            <Modal.Title>New Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.errorMessage.length > 0 && 
            <div className="alert alert-danger">
              {this.state.errorMessage}
            </div>
            }
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="title" className="sr-only"></label>
                <Input 
                  name="title"
                  placeholder="Group Name *"
                  className="form-control"
                  tabIndex="1"
                  value={this.state.fields.title}
                  onChange={this.onInputChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="Group Description" className="sr-only"></label>
                <Textarea 
                  name="description"
                  placeholder="Description"
                  className="form-control"
                  tabIndex="2"
                  value={this.state.fields.description}
                  onChange={this.onInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 taxable-field">
                <Checkbox
                  key="is_private"
                  checkboxClass="icheckbox_square-green"
                  increaseArea="20%"
                  label="Is Private"
                  onChange={this.onPrivateChange}
                  checked={this.state.fields.is_private}
                />
              </div>
            </div>               
          </Modal.Body>
          <Modal.Footer>
            <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Submit</LaddaButton>
            <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
        </Formsy>
      </Modal>
    );
  }
}

export default NewGroupForm;
