// @flow
import React, { Component } from "react";
import ActionCable from "actioncable";
import PageVisibility from "react-page-visibility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { isIOS } from "react-device-detect";
import { client } from "../components/Client";
import { shiftRequestClient } from "../clients/ShiftRequestClient";
import { acknowledgementClient } from "../clients/AcknowledgementClient";
import { pollClient } from "../clients/PollClient";
import { activityRecognitionTagClient } from "../clients/ActivityRecognitionTagClient";
import Config from "config";
import ActivityList from "../components/activity/ActivityList";
import Raffle from "../components/activity/Raffle";
import RaffleModel from "../models/Raffle";
import ShiftRequest from "../models/ShiftRequest";
import EmployeeSurveys from "../components/activity/EmployeeSurveys";
import ProfileUpdateWidget from "../components/common/ProfileUpdateWidget";
import { extractParameter } from "../helpers/PathParameter";
import ActivityShiftRequest from "../components/pick_up_shifts/ActivityShiftRequest";
import ActivityAcknowledgementWidget from "../components/acknowledgements/ActivityAcknowledgementWidget";
import ActivityPollWidget from "../components/polls/ActivityPollWidget";
import { toastrNotification } from "../helpers/Toastr";
import LeaderBoard from "../components/activity/LeaderBoard";
import Media from "react-media";
import { renderProfileIcon } from "../helpers/TopMenu";
import { Link } from "react-router-dom";
import Sidebar from "react-sidebar";
import LinesEllipsis from "react-lines-ellipsis";
import { Modal, Button, ModalBody, ModalDialog } from "react-bootstrap";

import IboxCollapse from "../components/common/IboxCollapse";

import "./Activity.css";

class ActivityView extends Component {
  state = {
    employeeSurveys: [],
    employeeRaffles: [],
    shiftRequests: [],
    employeePolls: [],
    profileCompleteness: {},
    employeeAcknowledgements: [],
    leaders: [],
    hideProfileWidget: true,
    departmentsList: [],
    selectedDepartment: { id: "", title: "" },
    loadPosts: false,
    sidebarOpen: false,
    rewardTags: [],
  };

  subscription: ActionCable;
  notificationsSubscription: ActionCable;
  channelName = "ActivityChannel";
  notificationsChannelName = "ActivityNotificationsChannel";

  componentDidMount() {
    ActionCable.debugging = true;
    window.cable = ActionCable.createConsumer(client.getConsumerUrl());
    const listener = this.actionCablePostsListener();
    const notificationsChannelListener = this.actionCableWidgetsListener();
    this.subscription = window.cable.subscriptions.create(
      { channel: this.channelName, department_id: "" },
      listener
    );
    this.notificationsSubscription = window.cable.subscriptions.create(
      { channel: this.notificationsChannelName },
      notificationsChannelListener
    );

    this.getDepartmentsList();
    this.getRewardTags();
    this.getEmployeePoints();
    this.getShiftRequests();
    this.getEmployeeAcknowledgements();
    this.getEmployeePolls();
    this.getCompanyLeaders();
  }

  componentWillUnmount() {
    if (this.subscription) {
      setTimeout(() => {
        console.log("unsubscribed");
        this.subscription.unsubscribe();
        this.notificationsSubscription.unsubscribe();
      }, 100);
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.selectedDepartment.id !== this.state.selectedDepartment.id) {
      const listener = this.actionCablePostsListener();
      const prevSubscription = this.subscription;
      prevSubscription.unsubscribe();
      this.subscription = window.cable.subscriptions.create(
        {
          channel: this.channelName,
          department_id: this.state.selectedDepartment.id,
        },
        listener
      );
      this.setState({ sidebarOpen: false });
    }
  };

  actionCablePostsListener = () => {
    let _this = this;
    const activityList = this.activityList;
    let listener = {
      received(data) {
        if (!client.isLoggedIn()) {
          window.location.hash = "/login";
          return;
        }

        switch (data.action) {
          case "create_post":
            activityList.addNewPost(data);
            break;
          case "update_post":
            activityList.updatePost(data);
            break;
          case "create_comment":
            activityList.addNewComment(data);
            break;
          case "update_comment":
            activityList.updateComment(data);
            break;
          case "remove_post":
            activityList.removePost(data);
            break;
          case "remove_comment":
            activityList.removePostComment(data);
            break;
          case "post_like":
            activityList.updatePostLikes(data);
            break;
          case "post_high_five":
            activityList.updatePostHighFive(data);
            break;
          case "comment_like":
            activityList.updateCommentLikes(data);
            break;
          case "create_post_reward":
            activityList.updatePostRewards(data);
            break;
          case "update_post_points":
            activityList.updatePostPoints(data);
            break;
        }
        console.log("received, channel:" + _this.channelName, data);
      },
    };
    return listener;
  };

  actionCableWidgetsListener = () => {
    let _this = this;
    const activityList = this.activityList;
    let listener = {
      received(data) {
        if (!client.isLoggedIn()) {
          window.location.hash = "/login";
          return;
        }

        if (
          data.action !== "refresh_leader_board" &&
          !data.recipients.includes(client.currentUser().id)
        ) {
          return;
        }

        switch (data.action) {
          case "new_survey":
            _this.addEmployeeSurvey(data.survey);
            break;
          case "complete_survey":
            _this.removeEmployeeSurvey(data.survey_instance_id);
            break;
          case "new_universal_raffle":
            _this.addNewRaffle(data);
            break;
          case "raffle_finished":
            _this.removeRaffle(data.raffle_id);
            break;
          case "new_shift_request":
            _this.addShiftRequest(data.shift_request);
            break;
          case "delete_shift_request":
            _this.deleteShiftRequest(data.shift_request_id);
            break;
          case "new_acknowledgement":
            _this.addAcknowledgement(data.acknowledgement);
            break;
          case "delete_acknowledgement":
            _this.deleteAcknowledgement(data.acknowledgement_instance_id);
            break;
          case "update_acknowledgement":
            _this.updateAcknowledgement(data.acknowledgement);
            break;
          case "new_poll":
            _this.addPoll(data.poll);
            break;
          case "delete_poll":
            _this.deletePoll(data.poll_instance_id);
            break;
          case "update_poll":
            _this.updatePoll(data.poll_instance);
            break;
          case "refresh_leader_board":
            _this.getCompanyLeaders();
            break;
          case "update_unread_posts_size":
            _this.updateUnreadPostsIndicator(data);
            break;
          case "added_to_department":
            _this.addDepartmentToChannelsList(data);
            break;
          case "removed_from_department":
            _this.removeDLoggeepartmentFromChannelsList(data);
            break;
          case "update_reward_tags":
            _this.getRewardTags();
            break;
        }
        console.log("received, channel:" + _this.employeeChannelName, data);
      },
    };
    return listener;
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  sideBarToggle = () => {
    const sidebarOpen = !this.state.sidebarOpen;
    this.setState({ sidebarOpen });
  };

  handleVisibilityChange(visibilityState, documentHidden) {
    if (isIOS) {
      console.log("************** visibility hidden: **********");
      console.log(documentHidden);
      if (!documentHidden) {
        this.activityList.reload();
        this.getEmployeePoints();
      }
    }
  }

  getDepartmentsList = () => {
    client.getActivityDepartmentsList().then((departmentsList) => {
      const lastDepartment = client.currentUser().department_channel_id;
      const lastSelectedDepartment = departmentsList.find(
        (d) => d.id == lastDepartment
      );
      const defaulDepartment = departmentsList.find((d) => d.default);
      const loadPosts = true;
      let selectedDepartment =
        lastSelectedDepartment || defaulDepartment || departmentsList[0];
      this.setState({ departmentsList, selectedDepartment, loadPosts });
    });
  };

  getRewardTags = () => {
    activityRecognitionTagClient.getTags().then((tags) => {
      this.setState({ rewardTags: tags || [] });
    });
  };

  getEmployeePoints() {
    client.getEmployee(client.currentUser().id).then((employee) => {
      let raffles = [];
      this.updateEmployeeSurveys(employee.surveys);
      (employee.universal_raffles || []).forEach((raffleData) => {
        if (!raffleData.widget_hidden)
          raffles.push(new RaffleModel(raffleData));
      });
      this.updateEmployeeRaffles(raffles);
      this.updateEmployeeProfileCompleteness(
        JSON.parse(employee.profile_completeness)
      );
      this.setState({ hideProfileWidget: employee.hide_profile_widget });
    });
  }

  getShiftRequests = () => {
    shiftRequestClient.getActivityShiftRequests().then((requests) => {
      this.setState({ shiftRequests: requests });
    });
  };

  getEmployeeAcknowledgements = () => {
    acknowledgementClient
      .getActivityAcknowledgements()
      .then((acknowledgements) => {
        this.setState({ employeeAcknowledgements: acknowledgements });
      });
  };

  getEmployeePolls = () => {
    pollClient.getActivityPolls().then((polls) => {
      this.setState({ employeePolls: polls });
    });
  };

  getCompanyLeaders = () => {
    client.getCompanyLeaders().then((leaders) => {
      console.log(leaders);
      this.setState({ leaders: leaders });
    });
  };

  handleHideProfileWidget = () => {
    client.hideEmployeeProfileWidget().then((result) => {
      if (result.success) {
        this.setState({ hideProfileWidget: true });
      } else {
        toastrNotification({
          success: false,
          message: "Unable to hide profile widget.",
        });
      }
    });
  };

  addShiftRequest = (shiftRequestData) => {
    const newShiftRequest = new ShiftRequest(JSON.parse(shiftRequestData));
    let modifiedShiftRequests = this.state.shiftRequests;
    if (!modifiedShiftRequests.includes(newShiftRequest)) {
      this.setState({
        shiftRequests: modifiedShiftRequests.concat(newShiftRequest),
      });
    }
  };

  deleteShiftRequest = (shiftRequestId) => {
    let mShiftRequests = this.state.shiftRequests;
    this.setState({
      shiftRequests: mShiftRequests.filter((sr) => sr.id !== shiftRequestId),
    });
  };

  addAcknowledgement = (acknowledgementData) => {
    const newAcknowledgement = JSON.parse(acknowledgementData);
    let modifiedAcknowledgements = this.state.employeeAcknowledgements;
    if (!modifiedAcknowledgements.includes()) {
      this.setState({
        employeeAcknowledgements: [newAcknowledgement].concat(
          modifiedAcknowledgements
        ),
      });
    }
  };

  deleteAcknowledgement = (AcknowledgementInstanceId) => {
    let modifiedAcknowledgements = this.state.employeeAcknowledgements;
    this.setState({
      employeeAcknowledgements: modifiedAcknowledgements.filter(
        (ack) => ack.instance_id !== AcknowledgementInstanceId
      ),
    });
  };

  updateAcknowledgement = (acknowledgementData) => {
    const modified = JSON.parse(acknowledgementData);
    this.setState({
      employeeAcknowledgements: this.state.employeeAcknowledgements.map(
        (ack) => {
          if (ack.id === modified.id) {
            return Object.assign({}, ack, modified);
          } else {
            return ack;
          }
        }
      ),
    });
  };

  addPoll = (pollData) => {
    const newPoll = JSON.parse(pollData);
    let mPolls = this.state.employeePolls;
    this.setState({ employeePolls: [newPoll].concat(mPolls) });
  };

  deletePoll = (pollInstanceId) => {
    let mPolls = this.state.employeePolls;
    this.setState({
      employeePolls: mPolls.filter((p) => p.instance_id !== pollInstanceId),
    });
  };

  updatePoll = (pollInstanceData) => {
    const mPoll = JSON.parse(pollInstanceData);
    this.setState({
      employeePolls: this.state.employeePolls.map((p) => {
        if (p.instance_id === mPoll.instance_id) {
          return Object.assign({}, p, mPoll);
        } else {
          return p;
        }
      }),
    });
  };

  updateEmployeeSurveys = (surveys) => {
    this.setState({ employeeSurveys: surveys || [] });
  };

  addEmployeeSurvey = (newSurveyData) => {
    const newSurvey = JSON.parse(newSurveyData);
    this.setState({
      employeeSurveys: this.state.employeeSurveys.concat(newSurvey),
    });
  };

  removeEmployeeSurvey = (surveyInstanceId) => {
    let mSurveys = this.state.employeeSurveys;
    this.setState({
      employeeSurveys: mSurveys.filter(
        (s) => s.survey_instance_id !== surveyInstanceId
      ),
    });
  };

  addNewRaffle = (data) => {
    const newRaffle = new RaffleModel(JSON.parse(data.raffle));
    let modifiedRaffles = [newRaffle].concat(this.state.employeeRaffles);
    this.setState({ employeeRaffles: modifiedRaffles });
  };

  removeRaffle = (raffleId) => {
    let mRaffles = this.state.employeeRaffles;
    this.setState({
      employeeRaffles: mRaffles.filter((r) => r.id !== raffleId),
    });
  };

  updateEmployeeRaffles = (raffles) => {
    this.setState({ employeeRaffles: raffles || [] });
  };

  updateEmployeeProfileCompleteness = (profileCompleteness) => {
    this.setState({ profileCompleteness: profileCompleteness });
  };

  addDepartmentToChannelsList = (data) => {
    const department = JSON.parse(data.department);
    const mDepartmentsList = this.state.departmentsList.concat(department);
    this.setState({
      departmentsList: mDepartmentsList.sort(this.compareByTitle),
    });
    toastrNotification({
      success: true,
      message: `You have been added to the ${department.title} group.`,
    });
  };

  removeDepartmentFromChannelsList = (data) => {
    const department = JSON.parse(data.department);
    const mDepartmentsList = this.state.departmentsList.filter(
      (dep) => dep.id !== department.id
    );
    this.setState({ departmentsList: mDepartmentsList });
    this.selectDepartment(mDepartmentsList[0].id);
    toastrNotification({
      success: false,
      message: `You have been removed from ${department.title} group.`,
    });
  };

  compareByTitle = (a, b) => {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  };

  selectDepartment = (depId) => {
    const selectedDepartment = this.state.departmentsList.find(
      (dep) => dep.id === depId
    );
    this.setState({ selectedDepartment });
    client.updateCurrentUserDepartmentChannelId(selectedDepartment.id);
  };

  updateUnreadPostsIndicator = (data) => {
    if (
      this.state.selectedDepartment.id === data.department_id &&
      data.unread_posts_size > 0
    ) {
      return;
    }
    this.setState({
      departmentsList: this.state.departmentsList.map((d) => {
        if (d.id === data.department_id) {
          return Object.assign({}, d, {
            unread_posts_size: data.unread_posts_size,
          });
        } else {
          return d;
        }
      }),
    });
  };

  selectNextDepartmentChannel = () => {
    const departmentsList = this.state.departmentsList;
    let currentIndex = departmentsList
      .map((d) => d.id)
      .indexOf(this.state.selectedDepartment.id);
    if (currentIndex === -1) return false;
    const nextIndex = ++currentIndex;
    const nextDepartment = !!departmentsList[nextIndex]
      ? departmentsList[nextIndex]
      : departmentsList[0];
    this.selectDepartment(nextDepartment.id);
  };

  selectPrevDepartmentChannel = () => {
    const departmentsList = this.state.departmentsList;
    let currentIndex = departmentsList
      .map((d) => d.id)
      .indexOf(this.state.selectedDepartment.id);
    if (currentIndex === -1) return false;
    const nextIndex = --currentIndex;
    const nextDepartment = !!departmentsList[nextIndex]
      ? departmentsList[nextIndex]
      : departmentsList[departmentsList.length - 1];
    this.selectDepartment(nextDepartment.id);
  };

  renderSurveys = () => {
    if (this.state.employeeSurveys.length > 0) {
      return (
        <EmployeeSurveys
          employeeSurveys={this.state.employeeSurveys}
          initSurveyId={extractParameter("survey", this.props.location.search)}
        />
      );
    }
  };

  renderRaffles = () => {
    if (this.state.employeeRaffles.length > 0) {
      return this.state.employeeRaffles.map((raffle) => (
        <Raffle key={"raffle" + raffle.id} raffle={raffle} />
      ));
    }
  };

  renderShiftRequests = () => {
    if (this.state.shiftRequests.length > 0) {
      return this.state.shiftRequests.map((request) => (
        <ActivityShiftRequest
          key={"request" + request.id}
          request={request}
          getShiftRequests={this.getShiftRequests}
        />
      ));
    }
  };

  renderAcknowledgements = () => {
    if (this.state.employeeAcknowledgements.length > 0) {
      return this.state.employeeAcknowledgements.map((acknowledgement) => (
        <ActivityAcknowledgementWidget
          key={"ack" + acknowledgement.id}
          acknowledgement={acknowledgement}
          refreshEmployeeAcknowledgements={this.getEmployeeAcknowledgements}
        />
      ));
    }
  };

  renderPolls = () => {
    if (this.state.employeePolls.length > 0) {
      return this.state.employeePolls.map((poll) => (
        <ActivityPollWidget
          key={"poll" + poll.id}
          poll={poll}
          refreshEmployeePolls={this.getEmployeePolls}
          widgetMode={true}
        />
      ));
    }
  };

  renderLoader = () => {
    if (!this.state.loadPosts) {
      return (
        <div className="sk-spinner sk-spinner-double-bounce">
          <div className="sk-double-bounce1"></div>
          <div className="sk-double-bounce2"></div>
        </div>
      );
    }
  };

  renderChannelsList = () => {
    const { departmentsList, selectedDepartment } = this.state;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>{selectedDepartment.title} channel</h5>
              <IboxCollapse className="hidden-xs" />
            </div>
            <div className="ibox-content">
              <ul className="folder-list" style={{ padding: 0 }}>
                {departmentsList.map((dep) => (
                  <li
                    key={"ch" + dep.id}
                    style={{
                      display: "flex",
                      padding: "4px 4px",
                      cursor: "pointer",
                    }}
                    onClick={(evt) => this.selectDepartment(dep.id)}
                    className={
                      selectedDepartment.id === dep.id
                        ? "gray-bg active-channel"
                        : ""
                    }
                  >
                    <span>{dep.title}</span>
                    {dep.unread_posts_size > 0 && (
                      <span
                        className="badge badge-primary"
                        style={{ marginLeft: "auto" }}
                      >
                        {dep.unread_posts_size}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const sidebarStyles = {
      root: {
        position: "inherit",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "hidden",
      },
      sidebar: {
        zIndex: 2,
        position: "fixed",
        top: 0,
        bottom: 0,
        transition: "transform .3s ease-out",
        WebkitTransition: "-webkit-transform .3s ease-out",
        willChange: "transform",
        overflowY: "auto",
        backgroundColor: "rgb(255, 255, 255)",
        width: "240px",
      },
      content: {
        position: "inherit",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: "auto",
        WebkitOverflowScrolling: "touch",
        transition: "left .3s ease-out, right .3s ease-out",
      },
      overlay: {
        zIndex: 1,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        visibility: "hidden",
        transition: "opacity .3s ease-out, visibility .3s ease-out",
        backgroundColor: "rgba(0,0,0,.3)",
      },
      dragHandle: { zIndex: 1, position: "fixed", top: 0, bottom: 0 },
    };
    return (
      <>
        <PageVisibility
          onChange={(visibilityState, documentHidden) =>
            this.handleVisibilityChange(visibilityState, documentHidden)
          }
        >
          <Media query="(max-width: 767px)">
            {(matches) =>
              matches ? (
                <Sidebar
                  sidebar={this.renderChannelsList()}
                  open={this.state.sidebarOpen}
                  onSetOpen={this.onSetSidebarOpen}
                  styles={sidebarStyles}
                >
                  <div className="wrapper wrapper-content animated fadeIn">
                    <div className="row border-bottom sticky">
                      <nav
                        className="navbar navbar-static-top white-bg"
                        role="navigation"
                        style={{ marginBottom: 0 }}
                      >
                        <div className="navbar-header mobile-activity-header">
                          <button
                            type="button"
                            className="btn btn-white"
                            style={{ borderRadius: "5px" }}
                            onClick={() => this.sideBarToggle()}
                          >
                            <FontAwesomeIcon icon={faBars} />
                          </button>
                          <div className="text-center activity-channel-name">
                            {this.state.selectedDepartment.title && (
                              <span style={{ display: "flex" }}>
                                <a
                                  onClick={() =>
                                    this.selectPrevDepartmentChannel()
                                  }
                                >
                                  <FontAwesomeIcon icon={faChevronLeft} />
                                </a>
                                <div
                                  className="channel-name-ellipsis"
                                  style={{ width: "auto", flex: "1 1 auto" }}
                                >
                                  {this.state.selectedDepartment.title}
                                </div>
                                <a
                                  onClick={() =>
                                    this.selectNextDepartmentChannel()
                                  }
                                >
                                  <FontAwesomeIcon icon={faChevronRight} />
                                </a>
                              </span>
                            )}
                          </div>

                          <div>
                            <Link to="/profile">{renderProfileIcon()}</Link>
                          </div>
                        </div>
                      </nav>
                    </div>
                    <div className="row" style={{ marginTop: "45px" }}>
                      <div className="col-md-12">
                        {this.state.leaders.length > 0 && (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="activity-leader-board">
                                <LeaderBoard leaders={this.state.leaders} />
                              </div>
                            </div>
                          </div>
                        )}
                        {this.renderAcknowledgements()}
                        {this.renderShiftRequests()}
                        {this.renderRaffles()}
                        {this.renderPolls()}
                        {this.renderSurveys()}
                        {!this.state.hideProfileWidget &&
                          this.state.profileCompleteness.completeness < 100 && (
                            <ProfileUpdateWidget
                              profileCompleteness={
                                this.state.profileCompleteness
                              }
                              showUpdateSteps={true}
                              showHideButton={true}
                              onHideWidget={this.handleHideProfileWidget}
                            />
                          )}
                        {this.renderLoader()}
                        {this.state.loadPosts && (
                          <ActivityList
                            onRef={(ref) => (this.activityList = ref)}
                            subscription={this.subscription}
                            leaders={this.state.leaders}
                            rewardTags={this.state.rewardTags}
                            selectedDepartment={this.state.selectedDepartment}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Sidebar>
              ) : (
                <div className="wrapper wrapper-content animated fadeIn">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12 col-lg-push-8 col-md-push-7">
                      {this.renderChannelsList()}
                      {this.renderAcknowledgements()}
                      {this.renderShiftRequests()}
                      {this.renderRaffles()}
                      {this.renderPolls()}
                      {this.renderSurveys()}
                      {this.state.leaders.length > 0 && (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="activity-leader-board">
                              <LeaderBoard leaders={this.state.leaders} />
                            </div>
                          </div>
                        </div>
                      )}
                      {!this.state.hideProfileWidget &&
                        this.state.profileCompleteness.completeness < 100 && (
                          <ProfileUpdateWidget
                            profileCompleteness={this.state.profileCompleteness}
                            showUpdateSteps={true}
                            showHideButton={true}
                            onHideWidget={this.handleHideProfileWidget}
                          />
                        )}
                    </div>
                    <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 col-lg-pull-4 col-md-pull-5">
                      {this.state.loadPosts && (
                        <ActivityList
                          onRef={(ref) => (this.activityList = ref)}
                          subscription={this.subscription}
                          leaders={this.state.leaders}
                          selectedDepartment={this.state.selectedDepartment}
                          rewardTags={this.state.rewardTags}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )
            }
          </Media>
        </PageVisibility>
      </>
    );
  }
}

export default ActivityView;
