// @flow
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import IBox from '../common/IBox';
import SweetAlert from 'sweetalert-react';
import { toastrNotification } from './../../helpers/Toastr';
import AcknowledgementForm from './AcknowledgementForm';
import { acknowledgementClient } from '../../clients/AcknowledgementClient';
import { client } from '../Client';
import AcknowledgementRow from './AcknowledgementRow';

import 'sweetalert/dist/sweetalert.css';

class AcknowledgementsList extends Component {

  state = {
    acknowledgements: [],
    departments: []
  }
  
  componentDidMount() {
    this.getAcknowledgements();
    this.getDepartments();
  }
  
  getAcknowledgements() {
    acknowledgementClient.getAcknowledgements().
    then((acknowledgements) => {
      this.setState({ acknowledgements: acknowledgements })
    });
  }
  
  getDepartments() {
    client.getEmployeeDepartmentsList().then((data) => {
      const depts = data.map((department, i) => (
        { title: department.title, value: department.id }
      ));
      this.setState({ departments: depts });
    });
  }
  
  refreshAcknowledgements = () => {
    this.getAcknowledgements();
  }
  
  openNewAcknowledgementForm() {
    this.refs.acknowledgementForm.open();
  }
    
  render() {
    return (
      <div className="ibox">
          <div className="ibox-title">
            <h5>Acknowledgements</h5>
          </div>
          <div className="ibox-content">
            <div className="row">
              <div className="col-sm-12">
                <button className="btn btn-primary btn-sm pull-right" onClick={() => this.openNewAcknowledgementForm()}><FontAwesomeIcon icon={faPlus}/> New Acknowledgement</button>
                <AcknowledgementForm 
                  ref="acknowledgementForm"
                  onAddNewAcknowledgement={this.refreshAcknowledgements}
                  departments={this.state.departments}
                />
              </div>
            </div>
            <div className="table-responsive project-list">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Group</th>
                    <th>Created At</th>
                    <th>Expires At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.acknowledgements.map((acknowledgement) => (
                      <AcknowledgementRow 
                        acknowledgement={acknowledgement} 
                        key={'ack'+acknowledgement.id}
                        onUpdateAcknowledgement={this.refreshAcknowledgements}
                      />
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
      </div>    
    )
  }

}


export default AcknowledgementsList;
