import { ActivitySolid } from './Activity';
import { GroupsSolid } from './Groups';
import { ColleaguesSolid } from './Colleagues';
import { Raffles } from './Raffles';
import {
    InviteSolid,
    ChatSolid,
    BellSolid,
    KeySolid,
    ListSolid,
    CalendarSolid,
    GiftSolid,
    DocReportSolid,
    Power,
    Add,
    Menu,
    HomeSolid,
    DollarSolid,
    GearSolid,
    RewardSolid,
    TrainingSolid,
    PickShiftNew,
    AcknowledgementNew,
    Award,
    History,
    reels as Reels,
} from './HeroIcons';
import { CheckList } from './CheckList';

export const solidIcons = {
    ActivitySolid,
    GroupsSolid,
    ColleaguesSolid,
    InviteSolid,
    ChatSolid,
    BellSolid,
    KeySolid,
    ListSolid,
    CheckList,
    CalendarSolid,
    GiftSolid,
    DocReportSolid,
    Power,
    Add,
    Menu,
    HomeSolid,
    DollarSolid,
    GearSolid,
    RewardSolid,
    TrainingSolid,
    PickShiftNew,
    AcknowledgementNew,
    Raffles,
    Award,
    History,
    Reels
}