// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons/faFolder';
import { raffleClient } from '../../clients/RaffleClient';
import { client } from '../Client';
import Raffle from '../../models/Raffle';
import { mediumDateFormat } from './../../helpers/Formatter';
import CreateRaffle from './CreateRaffle';
import SweetAlert from 'sweetalert-react';
import { toastrNotification } from './../../helpers/Toastr';

import 'sweetalert/dist/sweetalert.css';

class RaffleList extends Component {

  state = {
    raffles: [],
    spinnerClass: 'sk-loading',
    companyBalance: 0,
  }

  componentDidMount() {
    this.getRaffles();
    this.getCompanyBalance();
  }

  getRaffles() {
    raffleClient.getRaffles().
    then((raffles) => {
      this.setState({ raffles: raffles, spinnerClass: "" })
    });
  }
  
  getCompanyBalance() {
    client.getCompanyBalance().
    then((response) => {
      this.setState({ companyBalance: response.balance })
    });
  }

  addRaffle = (raffleData) => {
    let newRaffle  = new Raffle(raffleData);
    let joinedRaffles = [newRaffle].concat(this.state.raffles);
    this.setState({ raffles: joinedRaffles });
  }

  openModal() {
    this.refs.createRaffleModal.show();
  }
  
  renderStatusLabel = (raffle) => {
    switch (raffle.status) 
       {
           case 'not started': return (<span className="label label-warning">Not started</span>);
           case 'active': return (<span className="label label-primary">Active</span>);
           case 'canceled': return (<span className="label">Canceled</span>);
           case 'finished': return (<span className="label label-info">Finished</span>);
           case 'expired': return (<span className="label label-danger">Expired</span>);
           case 'completed': return (<span className="label label-info">Finished</span>);
       }        
  }
  
  rafflePrize = (raffle) => {
    switch (raffle.prize_type) 
       {
           case 'RaffleEvent': return (raffle.event_name);
           case 'RaffleReward': return (raffle.reward_type);
           case 'RaffleGiftCard': return (`Gift Card on  $${raffle.card_amount}`);
       }        
  }

  render() {  
    return (
      <div className={'wrapper wrapper-content ' + this.state.spinnerClass}>
        <div className="sk-spinner sk-spinner-double-bounce">
          <div className="sk-double-bounce1"></div>
          <div className="sk-double-bounce2"></div>
        </div>  
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Raffles</h5>
                <div className="ibox-tools">
                  <button className="btn btn-primary btn-sm" style={{marginLeft: '10px'}} onClick={() => this.openModal()}>Create Raffle</button>
                  <CreateRaffle 
                    ref="createRaffleModal" 
                    onAddRaffle={this.addRaffle}
                    companyBalance={this.state.companyBalance}
                  />
                </div>
              </div>
              <div className="ibox-content">
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th>Title</th>
                            <th>Prize</th>
                            <th>Visible For</th>
                            <th>Period</th>
                            <th>Participation</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                           this.state.raffles.map((raffle) => (
                              <tr key={'raffle' + raffle.id}>
                                  <td className="project-status">
                                      {this.renderStatusLabel(raffle)}
                                  </td>
                                  <td className="project-title">
                                    <Link to={`/raffle/${raffle.id}`}>
                                      {raffle.title}
                                    </Link>
                                    <br/>
                                    <small>Created {mediumDateFormat(raffle.created_at)}</small>
                                  </td>
                                  <td className="project-title">
                                    {raffle.raffle_type}
                                    <br/>
                                    <small>{this.rafflePrize(raffle)}</small>
                                  </td>
                                  <td className="project-title">
                                    {raffle.department_title}
                                  </td>
                                  <td className="project-title">
                                    {mediumDateFormat(raffle.start_date)} - {mediumDateFormat(raffle.expiry_date)}
                                  </td>
                                  <td className="project-title">
                                    <p>{raffle.participant_active_count} participating, {raffle.participant_passed_count} passed</p>
                                  </td>
                                  <td>
                                    <Link to={`/raffle/${raffle.id}`} className="btn btn-white btn-sm">
                                      <FontAwesomeIcon icon={faFolder} /> View
                                    </Link>
                                  </td>
                              </tr>
                           ))
                        }
                        </tbody>
                    </table>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}


export default RaffleList;
