// @flow
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { pollClient } from '../../clients/PollClient';
import ActivityPollWidget from '../polls/ActivityPollWidget';

class PollNotification extends Component {
  
 state = {
   pollInstance: {choices: []},
   hasLoaded: false, 
   selectedChoice: null,
   spinnerClass: 'sk-loading'
 }
 
 componentDidMount() {
   this.setState(this.state);
   this.getPollInstance();
 }

 getPollInstance = () => {
   pollClient.getPollInstance(this.props.notification.source_id).then((pollInstance) => {
     this.setState({ pollInstance: pollInstance, spinnerClass: '', hasLoaded: true })
   });
 }
  
updateSelectedChoice = (newChoiceId) => {
  this.setState({ selectedChoice: newChoiceId })
}
  
 vote() {
   this.refs.pollWidgetForm.vote();
 }
    
 render() {
       
    return (
      <div>
        <Modal.Body className="poll-notification">
          <div className={'wrapper wrapper-content' + this.state.spinnerClass}>
            <div className="sk-spinner sk-spinner-double-bounce">
              <div className="sk-double-bounce1"></div>
              <div className="sk-double-bounce2"></div>
            </div>
            {this.state.hasLoaded && 
              <ActivityPollWidget 
                poll={this.state.pollInstance} 
                widgetMode={false} 
                updateChoiceOnParent={this.updateSelectedChoice}
                refreshEmployeePolls={this.props.close}
                ref="pollWidgetForm" 
              />
            }  
          </div> 
        </Modal.Body>  
        <Modal.Footer>
          {!this.state.pollInstance.voted && !this.state.pollInstance.is_expired &&
            <button type="button" disabled={!this.state.selectedChoice} className="btn btn-primary" onClick={() => this.vote()}>Vote</button>
          }     
          <Button onClick={this.props.close}>Close</Button>
        </Modal.Footer>
      </div>    
    );
  }
}

export default PollNotification;
