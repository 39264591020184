// @flow
import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { activityRecognitionTagClient } from '../../clients/ActivityRecognitionTagClient';
import Config from 'config';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Input from '../form_components/Input';
import NumericInput from 'react-numeric-input';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { toastrNotification } from '../../helpers/Toastr';
import { Cropper } from 'react-cropper';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faCrop } from '@fortawesome/free-solid-svg-icons/faCrop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';

import './RecognitionTagModalForm.css';

class RecognitionTagModalForm extends Component {

  state = {
    showModal: false,
    loading: false,
    canSubmit: false,
    tag: null,
    openCropper: false,
    editMode: false,
    cropped: false,
    uploadedSrc: null,
    fields: {
      name: '',
      points: '',
      icon_url: '',
    }
  }

  constructor(props) {
    super(props);

    this.cropperRef = React.createRef();
    this.fileUploadButtonRef = React.createRef();
  }

  componentDidMount() { }


  open = (tag = null) => {
    this.setState({ showModal: true, loading: false, tag: tag });
    if (tag) {
      this.setState({ fields: tag, editMode: true });
    } else {
      this.setState({ fields: { name: '', points: '' } });
    }
  }

  onFileChange = (e) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const reader = new FileReader()
    reader.onload = () => {
      this.setState({ uploadedSrc: reader.result, openCropper: true })
    }
    reader.readAsDataURL(files[0]);
  }

  close = () => {
    this.setState({ showModal: false });
  }

  formSubmit = (data) => {
    const icon_url = this.state.fields?.icon_url;
    if (!this.state.editMode && (!icon_url || icon_url === '')) {
      toastrNotification({ success: false, title: 'Icon missing' });
      return;
    }
    this.setState({ loading: true });
    if (this.state.editMode) {
      if (this.state.cropped) this.updateTag({ ...data, icon_url });
      else this.updateTag(data);
    } else {
      this.createTag({ ...data, icon_url });
    }
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  onCrop = () => {
    //console.log(this.cropperRef.current?.cropper.getCroppedCanvas)
    const cropper = this.cropperRef.current?.cropper;
    if (cropper === null) {
      this.setState({
        canSubmit: this.state.editMode,
      })
    }
    if (cropper && typeof (cropper.getCroppedCanvas() !== 'undefined')) {
      this.setState({
        fields: { ...this.state.fields, icon_url: cropper.getCroppedCanvas({ width: 240, height: 200 }).toDataURL() },
        canSubmit: true,
        cropped: true
      })
    }
  }

  createTag = (data) => {
    activityRecognitionTagClient.createTag(data)
      .then((tag) => {
        if (tag.id) {
          toastrNotification({ success: true, title: 'New hashtag was successfully created' });
          this.props.reloadTags();
          this.close();
        } else {
          this.refs.form.updateInputsWithError(tag);
          toastrNotification({ success: false, title: 'Unable to create hashtag' });
          setTimeout(() => {
            this.setState({ loading: false })
          }, 1500)
        }
      });
  }

  updateTag = (data) => {
    if (!this.state.tag) { return };
    activityRecognitionTagClient.updateTag(this.state.tag.id, data)
      .then((tag) => {
        if (tag.id) {
          toastrNotification({ success: true, message: 'Hashtag was successfully updated' });
          this.props.reloadTags();
          this.close();
        } else {
          this.refs.form.updateInputsWithError(tag);
          toastrNotification({ success: false, message: 'Unable to update hashtag' });
          setTimeout(() => {
            this.setState({ loading: false })
          }, 1500)
        }
      });
  }

  renderPreview = () => {
    if (this.state.fields?.icon_url && this.state.fields.icon_url !== '') return (
      <div className="form-group col-sm-12">
        <div className="d-flex center">
          <img src={this.state.fields.icon_url} className="img-preview" style={{ height: 300, width: 'auto' }} />
        </div>
        <div className="d-flex center" style={{ marginTop: '10px' }}>
          <button type="button" className="btn btn-white btn-outline" onClick={() => this.fileUploadButtonRef.current?.click()}>
            <FontAwesomeIcon icon={faEdit} /> Change Icon
          </button>
          {this.state.uploadedSrc &&
            <button type="button" className="btn btn-white btn-outline" style={{ marginLeft: '10px' }} onClick={() => this.setState({ openCropper: true })}>
              <FontAwesomeIcon icon={faCrop} /> Crop
            </button>
          }
        </div>
      </div>
    );
    else return (
      <div className="form-group col-sm-12">
        <div className="d-flex center">
          <button type="button" className="btn btn-white btn-outline" onClick={() => this.fileUploadButtonRef.current?.click()}>
            <FontAwesomeIcon icon={faPlus} /> Add Icon
          </button>
        </div>
      </div>
    );
  }

  renderCropper = () => {
    if (this.state.openCropper) return (
      <div className="form-group col-sm-12">
        <Cropper
          style={{ height: 300, width: '100%' }}
          aspectRatio={6 / 5}
          guides={false}
          crop={this.onCrop}
          src={this.state.uploadedSrc || this.state.fields.icon_url}
          ref={this.cropperRef}
        />
        <div className="d-flex center" style={{ marginTop: '10px' }}>
          <button type="button" className="btn btn-white btn-outline" onClick={() => this.setState({ openCropper: false })}>
            <FontAwesomeIcon icon={faSave} /> Save Changes
          </button>
        </div>
      </div>
    );
    else return this.renderPreview();
  }

  render() {
    return (
      <Modal show={this.state.showModal} onHide={this.close} >
        <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal employee-form">
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.tag
                ?
                "Update hashtag"
                :
                "Create new hashtag"
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="name" className="sr-only"></label>
                <Input
                  name="name"
                  placeholder="Name *"
                  className="form-control"
                  tabIndex="1"
                  value={this.state.fields.name}
                  onChange={this.onInputChange}
                  required
                />
                <small>Write hashtag name without leading # symbol</small>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="points" className="sr-only"></label>
                <Input
                  name="points"
                  placeholder="Points *"
                  className="form-control"
                  tabIndex="2"
                  min="0"
                  type="number"
                  value={this.state.fields.points}
                  onChange={this.onInputChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              <input ref={this.fileUploadButtonRef} type="file" accept="image/*" onChange={this.onFileChange} style={{ display: 'none' }} />
              {this.renderCropper()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Submit</LaddaButton>
            <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
        </Formsy>
      </Modal>
    );
  }

}

export default RecognitionTagModalForm
