// @flow
import React, { Component } from "react";
import { client } from "../Client";
import Config from "config";
import { Waypoint } from "react-waypoint";
import Post from "../../models/ActivityPost";
import Employee from "../../models/Employee";
import ActivityPost from "./ActivityPost";
import ActivityPostForm from "./ActivityPostForm/ActivityPostFormV2";
import { Button } from "react-bootstrap";
import { toastrNotification } from "./../../helpers/Toastr";
import Coin from "../common/Coin";
import { Link } from "react-router-dom";
import Media from "react-media";
import ActivityPostFormMobile from "./ActivityPostForm/ActivityPostFormMobile";
import { Element, animateScroll as scroll, scroller } from "react-scroll";

import "./ActivityList.css";
import BadgePercentage from "../badges/BadgePercentage";
import FrontPage from "../reels/FrontPage";

class ActivityList extends Component {
  state = {
    posts: [],
    newPostsCnt: 0,
    page: 1,
    isLoading: false,
    postsEnded: false,
    shouldReload: false,
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.selectedDepartment.id !== this.props.selectedDepartment.id) {
      this.reload();
      if (this.postForm !== undefined) {
        this.postForm.reset();
      }
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.shouldReload) {
      this.setState({ shouldReload: false });
    }
  };

  updatePostPoints(data) {
    const mPosts = this.state.posts.map((p) => {
      if (p.id === data.post_id) {
        let pp = new Post(p);
        pp.points = data.points;
        return pp;
      } else {
        return p;
      }
    });
    this.setState({ posts: mPosts });
  }

  addNewPost(data) {
    if (data.success == true) {
      toastrNotification({ success: true, message: "Post created" });
      const postData = JSON.parse(data.activity_post);
      const newPost = new Post(postData);
      if (data.has_not_processed_video == true) {
        if (newPost.author.id === client.currentUser().id) {
          swal(
            "Processing Videos",
            "The video in your post is being processed. We'll send you a notification when it's done and your post is ready to view."
          );
          if (this.postForm !== undefined) {
            this.postForm.reset();
          }
        }
        return;
      }
      if (newPost.author.id === client.currentUser().id) {
        this.showNewPosts();
        this.setState({ posts: [newPost].concat(this.state.posts) });
        if (data.rewards_limit_is_over == true) {
          toastrNotification({
            success: false,
            message: data.rewards_limit_notification,
          });
        }
      } else {
        if (data.confirmed) {
          newPost.hidden = true;
          this.setState({
            posts: [newPost].concat(this.state.posts),
            newPostsCnt: this.state.newPostsCnt + 1,
          });
        }
      }
      if (this.postForm !== undefined) {
        this.postForm.reset();
      }
    } else {
      if (data.recipients[0] === client.currentUser().id) {
        toastrNotification({ success: false, message: data.errors });
      }
    }
  }

  updatePost(data) {
    if (data.success == true) {
      const postData = JSON.parse(data.activity_post);
      const post = new Post(postData);
      if (data.has_not_processed_video == true) {
        if (post.author.id === client.currentUser().id) {
          swal(
            "Processing Videos",
            "The video in your post is being processed. We'll send you a notification when it's done and your post is ready to view."
          );
          if (this.postForm !== undefined) {
            this.postForm.reset();
          }
        }
        return;
      }
      const mPosts = this.state.posts.map((p) => {
        if (p.id === post.id) {
          return post;
        } else {
          return p;
        }
      });
      this.setState({ posts: mPosts });
      if (post.author.id === client.currentUser().id) {
        if (data.rewards_limit_is_over == true) {
          toastrNotification({
            success: false,
            message: data.rewards_limit_notification,
          });
        }
        if (this.postForm !== undefined) {
          this.postForm.reset();
        }
        scroller.scrollTo("post" + post.id, { offset: -100 });
        toastrNotification({
          success: true,
          message: "Post was successfully updated.",
        });
      }
    } else {
      if (data.recipients[0] === client.currentUser().id) {
        toastrNotification({ success: false, message: data.errors });
      }
    }
  }

  removePost(data) {
    const post = JSON.parse(data.activity_post);
    const mPosts = this.state.posts.filter((p) => p.id !== post.id);
    this.setState({
      posts: mPosts,
      newPostsCnt: mPosts.filter((p) => p.hidden).length,
    });
  }

  showNewPosts() {
    const mPosts = this.state.posts.map((p) => {
      if (p.hidden) {
        let pp = new Post(p);
        pp.hidden = false;
        return pp;
      } else {
        return p;
      }
    });
    this.setState({ posts: mPosts, newPostsCnt: 0 });
  }

  addNewComment(data) {
    if (data.success === true) {
      const comment = JSON.parse(data.comment);
      if (
        comment.author_id === client.currentUser().id ||
        (comment.confirmed && comment.author_id !== client.currentUser().id)
      ) {
        const modifiedPosts = this.state.posts.map((p) => {
          if (p.id === comment.post_id) {
            p.comments = p.comments.concat([comment]);
            p.newCommentErrors = [];
          }
          return p;
        });
        this.setState({ posts: modifiedPosts });
      }
    } else {
      if (data.recipients[0] === client.currentUser().id) {
        toastrNotification({ success: false, message: data.errors });
      }
    }
  }

  updateComment(data) {
    if (data.success === true) {
      const comment = JSON.parse(data.comment);
      const modifiedPosts = this.state.posts.map((p) => {
        if (p.id === comment.post_id) {
          let pp = new Post(p);
          pp.comments = p.comments.map((c) => {
            if (c.id === comment.id) {
              return comment;
            } else {
              return c;
            }
          });
          return pp;
        } else {
          return p;
        }
      });
      this.setState({ posts: modifiedPosts });
      if (comment.author_id === client.currentUser().id) {
        toastrNotification({
          success: true,
          message: "Comment was successfully updated",
        });
      }
    } else {
      if (data.recipients[0] === client.currentUser().id) {
        toastrNotification({ success: false, message: data.errors });
      }
    }
  }

  removePostComment(data) {
    const comment = JSON.parse(data.comment);
    const mPosts = this.state.posts.map((p) => {
      if (p.id === comment.post_id) {
        let pp = new Post(p);
        pp.comments = p.comments.filter((c) => c.id !== comment.id);
        return pp;
      } else {
        return p;
      }
    });
    this.setState({ posts: mPosts });
  }

  updatePostLikes(data) {
    const mPosts = this.state.posts.map((p) => {
      if (p.id === data.postId) {
        let pp = new Post(p);
        pp.liked = data.liked;
        pp.likes = data.likes.map((like) => {
          like.employee = new Employee(like.employee);
          return like;
        });
        pp.likesNumber = data.likesNumber;
        return pp;
      } else {
        return p;
      }
    });
    this.setState({ posts: mPosts });
  }

  updatePostRewards(data) {
    if (data.success == true) {
      const mPosts = this.state.posts.map((p) => {
        if (p.id === data.post_id) {
          let pp = new Post(p);
          pp.rewards_number = data.rewards_number;
          pp.points = data.post_points;
          pp.rewarded = data.rewarded;
          pp.rewards = data.rewards;
          return pp;
        } else {
          return p;
        }
      });
      this.setState({ posts: mPosts });
    } else {
      if (data.author_id === client.currentUser().id) {
        toastrNotification({ success: false, message: data.errors });
      }
    }
  }

  updatePostHighFive(data) {
    if (data.success == true) {
      const mPosts = this.state.posts.map((p) => {
        if (p.id === data.postId) {
          let pp = new Post(p);
          pp.high_fived = data.high_fived;
          pp.high_fives = data.high_fives.map((high_five) => {
            high_five.employee = new Employee(high_five.employee);
            return high_five;
          });
          return pp;
        } else {
          return p;
        }
      });
      this.setState({ posts: mPosts });
    } else {
      if (data.author_id === client.currentUser().id) {
        toastrNotification({ success: false, message: data.errors });
      }
    }
  }

  updateCommentLikes(data) {
    const mPosts = this.state.posts.map((p) => {
      if (p.id === data.post_id) {
        let pp = new Post(p);
        pp.comments = p.comments.map((c) => {
          if (c.id === data.commentId) {
            return Object.assign({}, c, { likesNumber: data.likesNumber });
          } else {
            return c;
          }
        });
        return pp;
      } else {
        return p;
      }
    });
    this.setState({ posts: mPosts });
  }

  sendPost = (formData) => {
    const response = this.props.subscription.perform(
      "create_activity_post",
      formData
    );
    /*
    if (response)
      toastrNotification({ success: true, message: "Post created" });
    else toastrNotification({ success: false, message: "Failed to post" });
    */
    return response;
  };

  sendPostUpdate = (formData) => {
    const response = this.props.subscription.perform(
      "update_activity_post",
      formData
    );
    if (response)
      toastrNotification({ success: true, message: "Post Updated" });
    else toastrNotification({ success: false, message: "Failed to Update" });
    return response;
  };

  sendPostDraft = (formData) => {
    return this.props.subscription.perform(
      "create_activity_post_draft",
      formData
    );
  };

  handleEditPost = (post) => {
    if (this.postForm !== undefined) {
      this.postForm.loadPost(post);
      scroll.scrollToTop({ duration: 500 });
    } else {
      this.mobilePostForm.loadPost(post);
    }
  };

  loadMorePosts() {
    if (this.state.postsEnded) return;
    this.setState({ isLoading: true });
    const employeeId =
      this.props.employee !== undefined ? this.props.employee.id : "";
    const departmentId = this.props.selectedDepartment.id || "";
    client
      .getActivityPosts(this.state.page, employeeId, departmentId)
      .then((posts) => {
        const postsEnded = posts.length > 0 ? false : true;
        this.setState({
          posts: this.state.posts.concat(posts),
          page: (this.state.page += 1),
          isLoading: postsEnded,
          postsEnded: postsEnded,
        });
      });
  }

  renderWaypoint = () => {
    if (!this.state.postsEnded && !this.state.shouldReload) {
      return (
        <Waypoint
          onEnter={this.loadMorePosts.bind(this)}
          scrollableAncestor={window}
        />
      );
    }
  };

  reload = () => {
    this.setState({
      posts: [],
      page: 1,
      newPostsCnt: 0,
      postsEnded: false,
      shouldReload: true,
    });
  };

  renderLoader = () => {
    if (this.state.isLoading && !this.state.postsEnded) {
      return (
        <div className="sk-spinner sk-spinner-double-bounce">
          <div className="sk-double-bounce1"></div>
          <div className="sk-double-bounce2"></div>
        </div>
      );
    }
  };

  renderNewPostsLink = () => {
    if (this.state.newPostsCnt > 0) {
      return (
        <div
          className="bg-primary p-xs b-r-xs text-center"
          onClick={() => this.showNewPosts()}
          style={{ cursor: "pointer" }}
        >
          You have {this.state.newPostsCnt} unread posts
        </div>
      );
    }
  };

  render() {
    const { selectedDepartment } = this.props;

    return (
      <div>
        <BadgePercentage />
        {(!client.noPosts() || client.adminLoggedIn()) && (
          <div className="row editor-section">
            <div className="col-md-12">
              {/*<Media query="(max-width: 767px)" render={() => 
                  <ActivityPostFormMobile 
                    onRef={ref => (this.mobilePostForm = ref)}
                    sendPost={this.sendPost}
                    sendPostDraft={this.sendPostDraft}
                    sendPostUpdate={this.sendPostUpdate}
                    selectedDepartment={this.props.selectedDepartment}
                    rewardTags={this.props.rewardTags}
                  />
                <Media query="(min-width: 768px)" render={() => 
                }/>*/}
              <ActivityPostForm
                onRef={(ref) => (this.postForm = ref)}
                sendPost={this.sendPost}
                sendPostDraft={this.sendPostDraft}
                sendPostUpdate={this.sendPostUpdate}
                selectedDepartment={this.props.selectedDepartment}
                rewardTags={this.props.rewardTags}
              />
            </div>
          </div>
        )}
        <FrontPage />

        <div className="activity-list" id="activityList">
          {this.renderNewPostsLink()}
          {this.state.posts.map((post) => {
            if (!post.hidden)
              return (
                <Element
                  name={"post" + post.id}
                  key={"activityPost" + post.id}
                  className="element"
                >
                  <ActivityPost
                    post={post}
                    channel={this.props.subscription}
                    onEditPost={this.handleEditPost}
                    containerId="activityList"
                    leaders={this.props.leaders}
                    rewardTags={this.props.rewardTags}
                  />
                </Element>
              );
          })}
          {this.renderWaypoint()}
          {this.renderLoader()}
          {this.state.posts.length === 0 && this.state.postsEnded && (
            <div className="row">
              <div className="col-md-12 text-center">
                <h4>
                  There are no posts in the {selectedDepartment.title} channel
                  yet.
                </h4>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ActivityList;
