import React, { Component } from 'react';
import { client } from '../Client';
import debounce from 'lodash/debounce';
import { AsyncTypeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Loader.css';
import 'react-bootstrap-typeahead/css/Token.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Config from 'config';

class MultipleEmployeeSelector extends Component {

  state = {
    isLoading: false,
    options: [],
    query: '',
  }
  
  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 300);
  }
  
  cache = {}

  handleChange = (selectedEmployees) => {
    //this.setState({ selectedEmployee: selectedEmployees });
    this.props.selectAllowedEmployees(selectedEmployees);
  }
  
  handleInputChange = (query) => {
    this.setState({query});
  }

  handlePagination = (e) => {  
    const {query} = this.state;
    const cachedQuery = this.cache[query];

    // Don't make another request if:
    // - we've already fetched all possible results
    if (
      cachedQuery.employees.length === cachedQuery.totalCount
    ) {
      return;
    }

    this.setState({ isLoading: true });

    const page = cachedQuery.page + 1;
    
    client.getPaginatedEmployees(page, query)
    .then((resp) => {
       let newEmployees = resp.employees.map(employee => {
         let em = {};
         em.full_name = employee.full_name();
         em.avatar_url = employee.avatar_url;
         em.id = employee.id;
         return em;
       });
       const employees = cachedQuery.employees.concat(newEmployees);
       this.cache[query] = {...cachedQuery, employees, page};
       this.setState({
         isLoading: false,
         options: employees
       });
    });
  }

  handleSearch = (query) => {
    if (this.cache[query]) {
      this.setState({options: this.cache[query].employees});
      return;
    }

    this.setState({ isLoading: true });
    
    client.getPaginatedEmployees(1, query)
    .then((resp) => {
       let employees = resp.employees.map(employee => {
         let em = {};
         em.full_name = employee.full_name();
         em.avatar_url = employee.avatar_url;
         em.id = employee.id;
         return em;
       });
       this.cache[query] = {totalCount: resp.totalCount, employees, page: 1};
       this.setState({
         isLoading: false,
         options: employees
       });
    });
  }    
  
  renderMenuItemChildren(option, props, index) {
    return (
      <div key={option.id}>
        <img
          src={option.avatar_url}
          style={{
            height: '24px',
            marginRight: '10px',
            width: '24px',
          }}
        />
        <span>{option.full_name}</span>
      </div>
    );
  }

  render() {  
        
    return (
      <div>
        <AsyncTypeahead
          {...this.state}
          defaultSelected={this.props.selectedEmployees}
          multiple={true}
          allowNew={false}
          labelKey="full_name"
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          onPaginate={this.handlePagination}
          onInputChange={this.handleInputChange}
          placeholder={this.props.placeholder}
          renderMenuItemChildren={this.renderMenuItemChildren}
          minLength={2}
          maxResults={5}
          paginate
          useCache={false}
          emptyLabel={false}
        />
      </div>
    )
  }
}

export default MultipleEmployeeSelector;
