import { client } from "../components/Client";
import Config from 'config';

class BadgesClient {
    listBadges() {
        return client.callApi(Config.API_BASE + '/university_badges.json')
            .catch(error => {
                client.handleApiError(error);
                return [];
            });
    }

    getBadge(badgeId) {
        return client.callApi(Config.API_BASE + '/university_badges/' + badgeId + '.json')
            .catch(error => {
                client.handleApiError(error);
                return {};
            });
    }

    updateBadge(badgeId, enabled) {
        return client.callApi(Config.API_BASE + '/university_badges/' + badgeId + '.json', {}, 'PUT', { enabled })
            .catch(error => {
                client.handleApiError(error);
                return {};
            });
    }

    enableBadges(enabled) {
        return client.callApi(Config.API_BASE + '/university_badges/enable_badges.json', {}, 'PUT', { enabled })
            .catch(error => {
                client.handleApiError(error);
                return {};
            });
    }

    badgesConfig() {
        return client.callApi(Config.API_BASE + '/university_badges/badges_config.json')
            .catch(error => {
                client.handleApiError(error);
                return {};
            });
    }

    badgesEnabled() {
        return this.badgesConfig().then(resp => (resp?.badges_enabled ? true : false))
    }

    adminBadgeEnabled(){
        return this.badgesConfig().then(resp => (resp?.admin_badge ? true : false))
    }

}

export const badgesClient = new BadgesClient();