import React, { Component } from 'react';
import RewardTypeList from '../components/reward_types/RewardTypeList';
import PointsAllocationSettings from '../components/points_allocation_settings/PointsAllocationSettings';
import PointsAllocationReportBox from '../components/points_allocation_settings/PointsAllocationReportBox';
import { client } from '../components/Client';

class RewardTypesView extends Component {
  
   state = {
     pointsRatio: 10,
     companyBalance: 0,
   }
   
   componentDidMount() {
     this.getUniversityPointsRatio();
     this.getCompanyBalance();
   }
   
   getUniversityPointsRatio() {
     client.getUniversityPointsRatio().
     then((response) => {
       this.setState({ pointsRatio: response.points_ratio })
     });
   }
   
   getCompanyBalance() {
     client.getCompanyBalance().
     then((response) => {
       this.setState({ companyBalance: response.balance })
     });
   }
   
   updatePointsRatio = () => {
     this.getUniversityPointsRatio();
   }
    
   render() {
        return (
            <div className="wrapper wrapper-content animated fadeIn">
               <div className="row">  
                <div className="col-md-6">
                  <PointsAllocationSettings updatePointsRatio={this.updatePointsRatio}/>
                </div>
                <div className="col-md-6">
                  <PointsAllocationReportBox />
                </div>  
               </div>
               <RewardTypeList 
                pointsRatio={this.state.pointsRatio}
                companyBalance={this.state.companyBalance}
               />
               
            </div>
        )
    }

}

export default RewardTypesView;
