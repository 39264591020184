// @flow
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory';
import { faTrophy } from '@fortawesome/free-solid-svg-icons/faTrophy';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { Checkbox } from 'react-ui-icheck';
import { Link } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import EditRoulette from './EditRoulette';
import HistoryRouletteModal from './HistoryRouletteModal';
import ChooseRouletteWinnerModal from './ChooseRouletteWinnerModal';

class RouletteRow extends Component {
  
  state = {
    showDeleteDialog: false
  }
      
  handleStatusChange = (evt) => {
    this.props.onUpdateActiveStatus(this.props.rewardType.id);
  } 
  
  openEditModal() {
    this.refs.EditModal.open();
  }

  openHistoryModal() {
    this.refs.HistoryModal.open();
  }
        
  openChooseWinnerModal() {
    this.refs.ChooseWinnerModal.open();
  }
        
  handleDeleteRouletteClick = () => {
    this.setState({ showDeleteDialog: true });
  }
  
  handleDeleteRoulette = (roulette) => {
    if (!roulette) { return };
    this.setState({ showDeleteDialog: false });
    this.props.onDeleteRoulette(roulette.id);
  }
  
  handleDeleteRouletteClick = () => {    
    this.setState({ showDeleteDialog: true });    
  }  
  
  handleUpdateRoulette = (roulette) => {
    this.props.onUpdateRoulette(roulette)
  }
    
  render() {
    const roulette = this.props.roulette;
    return (
      <tr>
        <td>
          {roulette.title}
        </td>
        <td>
          {roulette.frequency}
        </td>
        <td>
          {moment.parseZone(roulette.next_date).format('D MMM YYYY')}
        </td>
        <td>
          <div className="pull-right">
            <button 
              className="btn btn-white btn-sm"
              style={{marginRight: "5px"}}
              onClick={() => this.openChooseWinnerModal()}>
                <FontAwesomeIcon icon={faTrophy}/> Choose Manually
            </button>
            <ChooseRouletteWinnerModal 
              ref="ChooseWinnerModal"
              roulette={roulette}
            />                        
            <button 
              className="btn btn-white btn-sm"
              style={{marginRight: "5px"}}
              onClick={() => this.openHistoryModal()}>
                <FontAwesomeIcon icon={faHistory}/> History
            </button>
            <HistoryRouletteModal 
              ref="HistoryModal"
              roulette={roulette}
            />                      
            <button 
              className="btn btn-white btn-sm"
              style={{marginRight: "5px"}}
              onClick={() => this.openEditModal()}>
                <FontAwesomeIcon icon={faPencilAlt}/> Edit
            </button>        
            <button 
              className="btn btn-white btn-sm" 
              onClick={() => this.handleDeleteRouletteClick()}>
                <FontAwesomeIcon icon={faTimes}/> Delete
            </button>
            <EditRoulette 
              ref="EditModal"
              roulette={roulette} 
              rewardTypes={this.props.rewardTypes}
              onUpdateRoulette={this.handleUpdateRoulette}
            />          
            <SweetAlert
              show={this.state.showDeleteDialog}
              type="warning"
              title="Are you sure?"
              confirmButtonColor="#DD6B55"
              showCancelButton
              text="This operation can not be undone."
              onConfirm={() => this.handleDeleteRoulette(roulette)}
              onCancel={() => this.setState({ showDeleteDialog: false })}
            />
          </div>      
        </td>
      </tr>
    )
  }
}

export default RouletteRow;
