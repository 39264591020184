import React from "react";
import { timeFromNow } from "../../helpers/Humanizer";
import { client } from "../Client";
import BottomSheet from "./BottomSheet";
import "./CommentBottomSheet.css";

class CommentBottomSheet extends React.Component {
  state = {
    openSheet: false,
    disabledSubmit: true,
    commnetMessage: '',
    avatar_url: 'img/avatar_missing.png'
  };

  componentDidMount() {
    const avatar_url = client.currentUser()?.avatar_url;
    if (avatar_url) this.setState({ avatar_url });
  }

  toggleSheet = () => {
    this.setState({ openSheet: !this.state.openSheet });
  };

  onInputChange = (evt) => {
    this.setState({
      commnetMessage: evt.target.value
    }, () => {
      if (this.validateMessage()) this.setState({ disabledSubmit: false });
    });
  }

  validateMessage = () => {
    const message = this.state.commnetMessage;
    if (!message || message === '') return false;
    return true;
  }

  submitComment = (event) => {
    event.preventDefault();
    if (!this.validateMessage()) return;
    const message = this.state.commnetMessage;
    if (this.props.addComment(message))
      this.setState({ commnetMessage: null });
  }


  render() {
    return (
      <BottomSheet
        openSheet={this.state.openSheet}
        onClose={this.toggleSheet}
        height={60}
      >
        <div className="Comment-main">
          <div className="comment-row">
            <div className="profile-pic">
              <img className="img-circle" src={this.state.avatar_url} />
            </div>
            <form>
              <input
                type="text"
                placeholder="Add your comment"
                id="comment-input"
                value={this.state.commnetMessage}
                onChange={(event) => this.onInputChange(event)}
              />
              <button type="submit" disabled={this.state.disabledSubmit} className="btn btn-primary" onClick={(evt) => this.submitComment(evt)}>
                Comment
              </button>
            </form>
          </div>
          <div className="comments-list">
            {this.props.comments?.map((comment, index, array) => {
              const style = {};
              if (index === 0) style['paddingTop'] = '0';
              else if (array.length === index + 1) style['borderBottom'] = 'none';
              return (
                <div key={index} className="reel-comment" style={style}>
                  <div className="profile-pic">
                    <img className="img-circle" src={comment.commenter?.avatar_url} />
                  </div>
                  <div className="comment-details">
                    <div className="user-info">
                      <div className="user-name">{comment.commenter?.full_name}</div>
                      |
                      <div className="comment-duration">{timeFromNow(comment.created_at)}</div>
                    </div>
                    <div className="comment-message">
                      {comment.comment}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </BottomSheet>
    );
  }
}

export default CommentBottomSheet;
