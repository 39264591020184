import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt} from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import { faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import moment from 'moment';
import Input from '../form_components/Input';
import Select from '../form_components/Select';
import DateField from '../form_components/DateField';
import FormField from '../../components/common/FormField';
import { mediumDateFormat } from './../../helpers/Formatter';
import { client } from '../Client';
import { toastrNotification } from '../../helpers/Toastr';
import Employee from '../../models/Employee';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

class WorkFields extends Component {

  state = {
    employees: [],
    editMode: false,
    loading: false,
    fields: {},
    managersList: []
  }

  componentDidMount = () => {
    this.getEmployees();
    this.updateEmployeeFields(this.props.employee);
  }
    
  componentWillReceiveProps = (nextProps) => {
    this.updateEmployeeFields(nextProps.employee);
  }
  
  getEmployees = () => {
    client.getEmployees(false).then((employees) => {
      let managersList = [{title: 'Select', value: ''}];
      employees.forEach(function(empl, i, arr) {
        managersList.push({title: empl.full_name(), value: empl.id})
      });
      this.setState({ employees: employees, managersList: managersList });
    });
  }
  
  updateEmployeeFields = (employee) => {
    this.setState({
      fields: {
        enumber: employee.enumber || '',
        employment: employee.employment || '',
        date_of_birth: employee.date_of_birth,
        employment_date: employee.employment_date,
        first_name: employee.first_name,
        last_name: employee.last_name,
        position: employee.position,
        manager_id: employee.manager_id,
        departments: employee.departments
      }
    });
  }
    
  openEditForm = () => {
    this.setState({ editMode: true });
  }

  managerName = () => {
    if (!this.props.employee.manager_id) return '';
    let empl = this.state.employees.find(x => x.id === this.props.employee.manager_id);
    return (empl ? empl.full_name() : '');
  }

  closeEditForm = () => {
    this.setState({editMode: false});
  }

  employmentType = () => {
    switch (this.props.employee.employment) {
      case 'r': return "Full-Time";
      case 'p': return "Part-Time";
      case 'c': return "Contract";
      case 'i': return "Intern";
      default: return "-";
    }
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }

  onDateChange = (field, date) => {
    const fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields: fields });
  }
  
  formSubmit = (data) => {
    this.disableButton();
    this.setState({ loading: true });
    client.updateEmployee(this.props.employee.id, data).then((empl) => {
      if (empl.id) {
        let employee = new Employee(empl); 
        this.props.onReloadEmployee(employee);
        toastrNotification({success: true, message: 'Updated successfully'});
        this.closeEditForm();
      } else {
        let formErrors = empl;
        try {
          this.refs.form.updateInputsWithError(formErrors);
          toastrNotification({ success: false, message: 'Unable to update' })
        } catch (error) {
          toastrNotification({ success: false, message: 'Unable to update: ' + formErrors.error})
        }
      }
      this.setState({ loading: false });
      this.enableButton();
    });
  }
        
  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  render() {
      const employmentTypes = [
        {title: 'Select', value: ''},
        {title: 'Full-Time', value: 'r'},
        {title: 'Part-Time', value: 'p'},
        {title: 'Contract', value: 'c'},
        {title: 'Intern', value: 'i'}
      ]
                            
      return (
        <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal">
          <ul className="list-unstyled">
          <br/>
            <FormField label="First Name">
              { this.state.editMode ?
              <Input value={this.state.fields.first_name || ''} onChange={this.onInputChange} className='form-control' name='first_name' required/>
              :
              <div className="details-item-text">{this.state.fields.first_name || '-'}</div>
              }
            </FormField>
            <FormField label="Last Name">
              { this.state.editMode ?
              <Input value={this.state.fields.last_name || ''} onChange={this.onInputChange} className='form-control' name='last_name' required/>
              :
              <div className="details-item-text">{this.state.fields.last_name || '-'}</div>
              }
            </FormField>
            <FormField label="Position">
              { this.state.editMode ?
              <Input value={this.state.fields.position || ''} onChange={this.onInputChange} className='form-control' name='position'/>
              :
              <div className="details-item-text">{this.state.fields.position || '-'}</div>
              }
            </FormField>
            { !this.state.editMode &&
              <FormField label="Groups">
                <div className="details-item-text">
                  {(this.state.fields.departments || []).map(dep => dep.title).join(', ')}
                </div>
              </FormField>  
            }          
            <FormField label="Employee Number">
              { this.state.editMode ?
              <Input value={this.state.fields.enumber} onChange={this.onInputChange} className='form-control' name='enumber'/>
              :
              <div className="details-item-text">{this.state.fields.enumber || '-'}</div>
              }
            </FormField>
            <FormField label="Employment Type">
              { this.state.editMode ?
              <Select
                name="employment"
                options={employmentTypes}
                className="form-control"
                value={this.state.fields.employment || ''}
                onChange={this.onInputChange}
                className="form-control"
              />
              :
              <div className="details-item-text">{this.employmentType()}</div>
              }
            </FormField>
            <FormField label="Manager">
              { this.state.editMode ?
              <Select
                name="manager_id"
                options={this.state.managersList}
                className="form-control"
                value={this.state.fields.manager_id || ''}
                onChange={this.onInputChange}
                className="form-control"
              />
              :
              <div className="details-item-text">{this.managerName()}</div>
              }
            </FormField>            
            <FormField label="Hire Date">
              { this.state.editMode ?
              <DateField name="employment_date" className="form-control" value={this.state.fields.employment_date} onChange={(date) => this.onDateChange('employment_date', date)} />  
              :
                <div className="details-item-text"><FontAwesomeIcon icon={faCalendarAlt}/>&nbsp;
                  {mediumDateFormat(this.state.fields.employment_date)}&nbsp;
                  / &nbsp;<FontAwesomeIcon icon={faUser} />&nbsp;{this.props.employee.work_experience()}
                </div>
              }
            </FormField>
          </ul>
          { this.state.editMode &&
          <div className="modal-footer">
            <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Save</LaddaButton>
            <button onClick={this.closeEditForm} className="btn btn-sm btn-default">Cancel</button>
          </div>
          }
        </Formsy>
      )
  }
}

export default WorkFields;
