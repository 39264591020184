import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { groupClient } from '../clients/GroupClient';
import { Link } from 'react-router-dom';
import AddGroupMembersModal from '../components/groups/AddGroupMembersModal';
import DeleteGroupMembersModal from '../components/groups/DeleteGroupMembersModal';
import { client } from '../components/Client.js';

class GroupMembersView extends Component {
  
  state = {
    group: {admin: {}},
    members: [],
    cnt: 0
  }

  componentDidMount() {
    this.getGroup();
  }

  getGroup = () => {
    groupClient.getGroup(this.props.match.params.id).then((group) => {
      let members = group.members;
      for (let m of members) { m.visible = true }
      this.setState({ group: group, spinnerClass: '', loading: false, members: members, cnt: members.length })
    });
  }
  
  updateMemberVisibility = (member, searchExpr) => {
    member.visible = member.full_name.match(searchExpr) !== null;
    return member
  }
  
  handleSearch = (evt) => {
    evt.preventDefault();
    const searchExpr = new RegExp(this.refs.search.value, "i");
    const nextMembers = [...this.state.members].map((member) => this.updateMemberVisibility(member, searchExpr));
    const cnt = nextMembers.filter((member) => (member.visible)).length
    this.setState({ members: nextMembers, cnt: cnt });
  }
  
  openAddMembersDialog = () => {
    this.refs.addGroupMembersModal.open()
  }
  
  openDeleteMembersDialog = () => {
    const members = this.state.members.filter(m => (!m.is_admin && m.id !== client.currentUser().id))
    this.refs.deleteGroupMembersModal.open(members)
  }
  
  render() {
      const {group, members} = this.state 
      
      return (
        <div className="wrapper wrapper-content animated fadeIn">
          <div className="row">
            <div className="col-sm-12 col-lg-8 col-lg-offset-2">
                <Link to={`/group/${group.id}`} className="btn btn-white btn-sm pull-right m-b-sm hidden-xs">{`Back to ${group.title}`}</Link>
                <div className="ibox">
                    <div className="ibox-content">
                        <div className="row">
                          <div className="col-md-12">{}
                            <h2 className="pull-left">{group.title} <small>({this.state.cnt})</small></h2>
                          </div>
                        </div>
                        <div className="table-actions">
                          {!group.default &&
                            <div className="html5buttons">
                              <div className="dt-buttons btn-group">
                                <button className="btn btn-primary m-r-sm" onClick={() => this.openAddMembersDialog()}><FontAwesomeIcon icon={faPlus}/> Add Members</button>
                                <button className="btn btn-white" onClick={() => this.openDeleteMembersDialog()}><FontAwesomeIcon icon={faTimes}/> Remove Members</button>
                                <AddGroupMembersModal ref="addGroupMembersModal" groupId={group.id}  refreshGroupMembers={this.getGroup} />
                                <DeleteGroupMembersModal ref="deleteGroupMembersModal" groupId={group.id}  refreshGroupMembers={this.getGroup} />
                              </div>
                            </div>
                          }
                          <form>
                            <div className="input-group">
                              <input type="text" placeholder="Search " ref="search" className="input form-control"  onChange={this.handleSearch} />
                              <span className="input-group-btn">
                                 <button type="submit" className="btn btn-primary"> <FontAwesomeIcon icon={faSearch} /></button>
                              </span>
                            </div>
                          </form>
                        </div>
                        <div className="clients-list">
                            <div className="full-height-scroll">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                        {
                                           members.map((member) => ( 
                                             <tr key={'m'+member.id} className={member.visible ? '' : 'hidden'} >
                                               <td className="client-avatar">
                                                 <div>
                                                   <img alt="image" src={member.avatar_url ?  member.avatar_url : 'img/avatar_missing.png'} />
                                                 </div>
                                               </td>
                                               <td>
                                                 <div className="client-link">{member.full_name}</div>
                                               </td>
                                               <td>
                                                 <div><FontAwesomeIcon icon={faEnvelope}/> {member.email}</div>
                                               </td>
                                               <td className="client-status">
                                                 { member.is_admin
                                                   ? <span className="label label-success">Admin</span>
                                                   : ""
                                                 }   
                                               </td>
                                             </tr>
                                           ))
                                        }                                  
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      )
  }    
  
}

export default GroupMembersView
