import moment from 'moment';
class Raffle {
  constructor(obj) {
    for (var prop in obj) {
      if (prop === 'event_date' || prop === 'expiry_date' || prop === 'created_at' || prop === 'start_date') {
        this[prop] = moment.parseZone(obj[prop]).toDate();
      } else {
        this[prop] = obj[prop];
      }
    }
  }

  isActive() {
    return (this.status === 'active');
  }

  isExpired() {
    return (this.status === 'expired');
  }

  isVisible() {
    return (this.visible === true);
  }

  isCanceled() {
    return (this.canceled_at !== null);
  }

  isFinished() {
    return (this.finished_at !== null);
  }

  isEventRaffle() {
    return this.prize_type === "RaffleEvent"
  }

  isRewardRaffle() {
    return this.prize_type === "RaffleReward"
  }

  isGiftCardRaffle() {
    return this.prize_type === "RaffleGiftCard"
  }
}

export default Raffle;
