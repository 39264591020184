import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';

class GroupClient {
  
  getGroups() {
    return client.callApi(Config.API_BASE + '/university_departments.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  getGroupsList() {
    return client.callApi(Config.API_BASE + '/university_departments/list.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  getPaginatedGroups() {
    return client.callApi(Config.API_BASE + '/university_departments/paginated_list.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  createGroup(groupData) {
    return client.callApi(Config.API_BASE + '/university_departments.json', {}, 'POST', {department: groupData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  getGroup(groupId) {
    return client.callApi(Config.API_BASE + '/university_departments/' + groupId + '.json')
      .catch((error) => {
        client.handleApiError(error);
        return [] ;
      });        
  }
  
  updateGroup(groupId, groupData) {
    return client.callApi(Config.API_BASE + '/university_departments/'+groupId+'.json', {}, 'PUT', {department: groupData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  getGroupCandidates(groupId) {
    return client.callApi(Config.API_BASE + '/university_departments/' + groupId + '/get_member_candidates.json')
      .catch((error) => {
        client.handleApiError(error);
        return [] ;
      });        
  }
  
  addEmployeesInGroup(groupId, employeeIds) {
    return client.callApi(Config.API_BASE + '/university_departments/' + groupId + '/add_employees.json', {}, 'POST', {employee_ids: employeeIds})
      .catch((error) => {
        client.handleApiError(error);
        return {};
    });
  }
  
  deleteEmployeesFromGroup(groupId, employeeIds) {
    return client.callApi(Config.API_BASE + '/university_departments/' + groupId + '/remove_employees.json', {}, 'POST', {employee_ids: employeeIds})
      .catch((error) => {
        client.handleApiError(error);
        return {};
    });
  }
  
  joinGroup(groupId) {
    const employeeId = client.currentUser().id;
    return client.callApi(Config.API_BASE + '/university_departments/' + groupId + '/join_department.json', {}, 'POST', {employee_id: employeeId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
    });
  }
  
  exitGroup(groupId) {
    const employeeId = client.currentUser().id;
    return client.callApi(Config.API_BASE + '/university_departments/' + groupId + '/exit_department.json', {}, 'POST', {employee_id: employeeId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
    });
  }
  
  removeEmployeeFromGroup(groupId) {
    const employeeId = client.currentUser().id;
    return client.callApi(Config.API_BASE + '/university_departments/' + groupId + '/remove_employee.json', {}, 'POST', {employee_id: employeeId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
    });
  }
  
  deleteGroup(groupId) {
    return client.callApi(Config.API_BASE + '/university_departments/'+ groupId +'.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  approveGroupCandidate(groupId, employeeId) {
    return client.callApi(Config.API_BASE + '/university_departments/' + groupId + '/approve_candidate.json', {}, 'POST', {employee_id: employeeId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
    });
  }
  
  refuseGroupCandidate(groupId, employeeId) {
    return client.callApi(Config.API_BASE + '/university_departments/' + groupId + '/refuse_candidate.json', {}, 'POST', {employee_id: employeeId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
    });
  }
      
}

export const groupClient = new GroupClient();
