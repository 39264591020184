// @flow
import React from 'react';
import { smoothlyMenu } from '../layouts/Helpers';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { client } from '../Client';
import { activeRoute, renderProfileIcon } from '../../helpers/TopMenu';
import { withRouter } from 'react-router-dom';

class TopMobileHeader extends React.Component {
      
    state = {
      prevPath: ""
    }

    componentDidUpdate = () => {
      //hide navbar after redirect for small areas 
      if ($('body').is('.body-small') && $('#navbar').is('.in')) {
        $(".navbar-toggle").click();        
      };
      window.scrollTo(0,0);;
    }

    toggleNavigation(e: Event) {
      e.preventDefault();
      $("body").toggleClass("mini-navbar");
      smoothlyMenu();
    }
      
    showBackButton = () => {
      const pathname = this.props.location.pathname;
      return (pathname.match(/\/[a-z_]{4,15}\/[0-9]{1,10}/gi) || pathname === '/new_survey' || pathname === '/coggno_training')
    }
    
    render() {  
        const pathname = this.props.location.pathname;
  
        return (
            <div className="row border-bottom sticky">
                <nav className="navbar navbar-static-top white-bg" role="navigation" style={{marginBottom: 0}}>
                    <div className="navbar-header ezzely-logo-box" style={{paddingLeft: this.showBackButton() ? "0px" : "10px"}}>
                      {this.showBackButton() &&
                        <button className="btn btn-circle back-button" type="button" onClick={() => this.props.history.goBack()}>
                          <FontAwesomeIcon icon={faChevronLeft}/>
                        </button>
                      }
                      <a href="#" className="navbar-brand company-logo">&nbsp;</a>                  
                      <Link to="/profile" className="header-profile-image">                  
                        { renderProfileIcon() }
                      </Link>
                    </div>
                </nav>
            </div>
        )
    }
}

export default withRouter(TopMobileHeader);
