import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import Employee from "../models/Employee";
import ColleagueSummary from "../components/employees/ColleagueSummary";
import InviteEmployeeForm from "../components/employees/InviteEmployeeForm";
import { client } from "../components/Client";
import { companySettingsClient } from "../clients/CompanySettingsClient";
import GroupsPopover from "../components/groups/GroupsPopover";
import ReactPaginate from "react-paginate";

class ColleaguesView extends Component {
  state = {
    employees: [],
    cnt: 0,
    selectedEmployee: null,
    page: 1,
    loading: false,
    query: "",
    canInvite: false,
  };

  componentDidMount() {
    this.getEmployees();
    this.checkUserAccessToInvite();
  }

  getEmployees = () => {
    const { page, query } = this.state;
    this.setState({ loading: true });
    client.getPaginatedEmployees(page, query).then((data) => {
      this.setState({
        employees: data.employees,
        cnt: data.totalCount,
        pageCount: data.totalPages,
        loading: false,
      });
    });
  };

  checkUserAccessToInvite = () => {
    if (client.adminLoggedIn()) {
      this.setState({ canInvite: true });
    } else {
      companySettingsClient.checkUserAccess("invitation").then((response) => {
        this.setState({ canInvite: response.success });
      });
    }
  };

  selectEmployee = (employee) => {
    this.openSummaryForm(employee);
  };

  openSummaryForm = (employee) => {
    this.refs.summaryModal.open(employee);
  };

  handleSearch = (evt) => {
    evt.preventDefault();
    const query = this.refs.search.value;
    this.setState({ page: 1, query }, () => {
      this.getEmployees();
    });
  };

  renderEmployeeGroups = (employee) => {
    const groups = employee.departments;
    switch (groups.length) {
      case 0:
        return "";
      case 1:
        return <span>{groups[0].title}</span>;
      case 2:
        return <span>{[groups[0].title, groups[1].title].join(", ")}</span>;
      default:
        return <GroupsPopover id={"groups" + employee.id} groups={groups} />;
    }
  };

  renderOnlineStatus = (employee) => {
    return (
      <span>
        <small>
          <i>{employee.online_status()}</i>
        </small>
      </span>
    );
  };

  handlePageClick = (data) => {
    let page = data.selected + 1;
    this.setState({ page: page }, () => {
      this.getEmployees();
    });
  };

  renderLoader = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="sk-spinner-loader">
            <div className="sk-spinner sk-spinner-double-bounce">
              <div className="sk-double-bounce1"></div>
              <div className="sk-double-bounce2"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let employeesList = (
      <div className="clients-list">
        <div className="full-height-scroll">
          {this.state.employees.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <tbody>
                  {this.state.employees.map((employee) => (
                    <tr
                      key={"colleague-" + employee.id}
                      className={employee.visible ? "" : "hidden"}
                    >
                      <td
                        className="client-avatar"
                        onClick={() => this.selectEmployee(employee)}
                      >
                        <div
                          className={employee.is_online ? "online" : "offline"}
                        >
                          <img alt="image" src={employee.avatar_image_url()} />
                        </div>
                      </td>
                      <td onClick={() => this.selectEmployee(employee)}>
                        <div
                          className="client-link"
                          style={{ cursor: "pointer" }}
                        >
                          {employee.full_name()}
                        </div>
                        {this.renderOnlineStatus(employee)}
                      </td>
                      <td>{this.renderEmployeeGroups(employee)}</td>
                      <td>
                        <div>{employee.full_position()}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center">
              <h4>No employees have been found</h4>
            </div>
          )}
        </div>
      </div>
    );

    return (
      <div className="wrapper wrapper-content animated fadeIn">
        <div className="row">
          <div className="col-sm-12 col-lg-8 col-lg-offset-2">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="pull-left">
                      Colleagues <small>({this.state.cnt})</small>
                    </h2>
                    {this.state.canInvite && (
                      <div className="pull-right">
                        <InviteEmployeeForm>
                          <button
                            className="btn btn-white btn-sm btn-outline"
                            onClick={this.open}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Invite
                          </button>
                        </InviteEmployeeForm>
                      </div>
                    )}
                  </div>
                </div>
                <form className="clearfix">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Search "
                      ref="search"
                      className="input form-control"
                      onChange={this.handleSearch}
                    />
                    <span className="input-group-btn">
                      <button type="submit" className="btn btn btn-primary">
                        {" "}
                        <FontAwesomeIcon icon={faSearch} /> Search
                      </button>
                    </span>
                  </div>
                </form>
                {this.state.loading ? this.renderLoader() : employeesList}
                {this.state.employees.length > 0 && this.state.pageCount > 1 && (
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        pageClassName={"paginate_button"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ColleagueSummary ref="summaryModal" />
        </div>
      </div>
    );
  }
}

export default ColleaguesView;
