// Doesn't know about jQuery plugins  @ flow
import React, { Component } from 'react';

import SliderJS from 'ion-rangeslider';
import 'ion-rangeslider/css/ion.rangeSlider.css';
// import 'ion-rangeslider/css/ion.rangeSlider.skinHTML5.css';


class Data {
  from: number;
  to: number;
  min: number;
  max: number;
}

class RangeSlider extends Component {

  static Data: any;

  renderSlider() {
    $(this.refs.slider).ionRangeSlider({
      min: this.props.min,
      max: this.props.max,
      from: this.props.value,
      type: 'single',
      step: 1,
      prefix: "$",
      prettify: true,
      hasGrid: true,
      onChange: this.props.onChange,
      onFinish: this.props.onFinish
    });
  }

  getSingleValue(): number {
    return this.getData().from;
  }

  getData() : Data {
    let untyped: any = $(this.refs.slider).data("ionRangeSlider");
    let data = new Data({
      from: untyped.from,
      to: untyped.to,
      min: untyped.min,
      max: untyped.max
    });
    return data;
  }

  componentDidMount() {
    this.renderSlider();
  }

  componentDidUpdate() {
    this.renderSlider();
  }

  render() {
    return (<input ref="slider"></input>);
  }
}

RangeSlider.Data = Data;

export default RangeSlider;
