import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { client } from '../Client';
import moment from 'moment';
import { toastrNotification } from '../../helpers/Toastr';
import SurveyQuestionModal from './SurveyQuestionModal';
import SurveyView from './SurveyView';

class SurveyPreviewStep extends Component {

  state = {
    selectedQuestions: [],
    selectedDepartments: [],
    saveBtnDisabled: false,
    formFields: {
      name: '',
      start_time: moment(),
      end_time: moment().add(3, 'days'),
      points: 0
    },
    shouldRedirect: false
  }
  
  componentDidMount() {
    this.setState(this.props.getSurveyData());
  } 
  
  createSurvey = () => {
    this.setState({ saveBtnDisabled: true });
    const data = Object.assign({}, 
      this.state.formFields, 
      { questions: this.state.selectedQuestions.map((question) => (question.id)) },
      { departments: this.state.selectedDepartments }
    )
    client.createSurvey(data).then((survey) => {
      if (survey.id) {
        toastrNotification({success: true, title: 'Survey has been created'})
        this.setState({shouldRedirect: true});
      } else {
        this.setState({ saveBtnDisabled: false })
        toastrNotification({success: false, title: 'Unable to create survey'})        
      }
    }); 
  }
  
      
  render() { 
        let survey = Object.assign({},
          this.state.formFields,
          { questions: this.state.selectedQuestions} 
        );
    
        if (this.state.shouldRedirect) {
          return (
            <Redirect to='/survey_builder' />
          );
        } else {       
          return (
            <div>
              <div className="ibox row">
                <div className="ibox-content col-md-8 col-md-offset-2">
                  <div>
                    <h4>Survey Details</h4>
                    Survey name: <SurveyView survey={survey} employeeMode={false} previewMode={true}/>
                    <br/>
                    Start Date: {moment(this.state.formFields.start_time).format('MMMM Do YYYY')}
                    <br/>
                    End Date: {moment(this.state.formFields.end_time).format('MMMM Do YYYY')}
                    <br/>
                    Points: {this.state.formFields.points}
                  </div>
                  <div>
                    <h4>Survey questions</h4>
                    <ul>
                    {
                       this.state.selectedQuestions.map((question) => ( 
                        <li  key={'sqp'+question.id}><SurveyQuestionModal question={question} /></li> 
                      ))
                    }
                    </ul>     
                  </div>
                  <div>
                    <h4>Groups</h4>
                    <ul>
                    {
                      this.state.selectedDepartments.map((department, index) => ( 
                        <li key={index}>{department}</li> 
                      ))
                    }
                    </ul>
                  </div>  
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-next btn-primary btn-lg" disabled={this.state.saveBtnDisabled} onClick={this.createSurvey}>Save</button>
              </div>  
          </div>          
        )
      }  
    }
}

export default SurveyPreviewStep
