import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import {Radio, RadioGroup} from 'react-ui-icheck';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { client } from '../Client';
import { rouletteClient } from '../../clients/RouletteClient';
import { toastrNotification } from '../../helpers/Toastr';

class ChooseRouletteWinnerModal extends Component {

  state = {
    winner_id: null,
    showModal: false,
    employees: [],
    selectedCnt: 0,
    loading: false
  }
    
  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true, winner_id: null, loading: false, selectedCnt: 0 })
    client.getEmployees().then((employees) => {
      this.setState({ employees })
    })
  }
  
  chooseWinner = () => {
     this.setState({ loading: true });
     rouletteClient.setWinner(this.props.roulette.id, this.state.winner_id)
     .then((response) => {
         if (response.success) {
           toastrNotification({success: true, title: `You successfully chose a winner`});
           this.close();
         } else {
           toastrNotification({success: false, title: 'Unable to submit.'});
           setTimeout(() => {
             this.setState({ loading: false })
           }, 1500)
         }
     });
  }
  
  updateEmployeeVisibility = (employee, searchExpr) => {
    employee.visible = employee.full_name().match(searchExpr) !== null;
    return employee
  }
  
  handleSearch = (evt) => {
    evt.preventDefault();
    const searchExpr = new RegExp(this.refs.search.value, "i");
    const nextEmployees = [...this.state.employees].map((employee) => this.updateEmployeeVisibility(employee, searchExpr));
    const cnt = nextEmployees.filter((employee) => (employee.visible)).length
    this.setState({ employees: nextEmployees });
  }
  
  handleEmployeeSelect = (evt, employee) => {
    this.setState({winner_id: employee})
  }

 render() {

    const {employees} = this.state

    return (
      <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Choose a winner to send him his Wheel of Fortune link.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <form className="clearfix">
                  <div className="input-group">
                    <input type="text" placeholder="Search " ref="search" className="input form-control"  onChange={this.handleSearch} />
                    <span className="input-group-btn">
                       <button type="button" className="btn btn btn-primary" onClick={(evt) => this.handleSearch(evt)}> <FontAwesomeIcon icon={faSearch}/></button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
            <br/>
            <div className="row">
              <div className="col-md-12 roulette-candidates-list">
                <RadioGroup name="employee" radioWrapTag="div" value={this.state.winner_id} onChange={this.handleEmployeeSelect.bind(this)}>
                  {employees.filter(e => e.visible || e.id == this.state.winner_id).map((employee) => 
                      <Radio
                        key={'rew-'+employee.id}                           
                        value={`${employee.id}`}
                        radioClass="iradio_square-green"
                        increaseArea="20%"
                        label={`<span><img className='img-circle' alt='image' src=${employee.avatar_url} />${employee.first_name} ${employee.last_name}</span>`}
                      />
                  )}
                </RadioGroup>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LaddaButton disabled={!this.state.winner_id} data-style={EXPAND_LEFT} type="button" onClick={() => this.chooseWinner()} loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Submit</LaddaButton>
            <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
      </Modal>
    );
  }
}

export default ChooseRouletteWinnerModal;
