// @flow
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import moment from 'moment';
import { rouletteClient } from '../../clients/RouletteClient';
import { client } from '../Client';
import { Button } from 'react-bootstrap';
import { Checkbox } from 'react-ui-icheck';
import IBox from '../common/IBox';
import SweetAlert from 'sweetalert-react';
import CreateRoulette from './CreateRoulette';
import RouletteRow from './RouletteRow';
import { toastrNotification } from './../../helpers/Toastr';

import 'sweetalert/dist/sweetalert.css';

class RouletteList extends Component {

  state = {
    roulettes: []
  }
  
  componentDidMount() {
    this.getRoulettes();
  }

  getRoulettes() {
    rouletteClient.getRoulettes().then((roulettes) => {
      this.setState({ roulettes: roulettes })
    });
  }
  
  openModal() {
    this.refs.CreateRouletteModal.show();
  }
  
  handleEditorChange = (value) => {
  }
  
  handleCreateRoulette = (roulette) => {
    const allRoulettes = this.state.roulettes.concat(roulette)
    this.setState({ roulettes: allRoulettes })        
  }

  handleDeleteRoulette = (rouletteId) => {
    rouletteClient.deleteRoulette(rouletteId).then((response) => {
      if (response.success == true) {
        toastrNotification({ success: true, message: 'Roulette was successfully deleted' });
        this.getRoulettes();
      } else {
        toastrNotification({ success: false, message: 'Unable to delete roulette' });
      }
    });
  }
  
  handleUpdateRoulette = (roulette) => {
    this.getRoulettes();
  }
    
  render() {
    return (
      <div className="ibox">
          <div className="ibox-title">
            <h5>Roulettes</h5>
          </div>
          <div className="ibox-content">
            <div className="row">
              <div className="col-sm-12">
                <button className="btn btn-primary btn-sm pull-right" onClick={() => this.openModal()}><FontAwesomeIcon icon={faPlus}/> New Roulette</button>
                <CreateRoulette 
                  ref="CreateRouletteModal" 
                  rewardTypes={this.props.rewardTypes.filter(rt => rt.kind == 1)}
                  onAddRoulette={this.handleCreateRoulette}
                  pointsRatio={this.props.pointsRatio}
                />
              </div>
            </div>
            <div className="table-responsive project-list">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Frequency</th>
                    <th>Start Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.roulettes.map((roulette) => (
                      <RouletteRow 
                        roulette={roulette} 
                        rewardTypes={this.props.rewardTypes}
                        key={'roulette'+roulette.id}
                        onDeleteRoulette={this.handleDeleteRoulette}
                        onUpdateRoulette={this.handleUpdateRoulette}
                      />
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
      </div>    
    )
  }
}

export default RouletteList;