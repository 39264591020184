import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import EmployeesTab from './EmployeesTab';
class EmployeesList extends Component {

  render() {
    return (
      <div className="clients-list">
        <Tabs defaultActiveKey={this.props.activeTab} animation={false} id="groups-tabs" onSelect={(key) => this.props.onTabChange(key)}>
          <Tab eventKey={0} title="Active" >
            <EmployeesTab employees={this.props.employees.filter(employee => !employee.terminated())} onSelectEmployee={this.props.onSelectEmployee} showStatus={false} />
          </Tab>
          <Tab eventKey={1} title="Terminated" >
            <EmployeesTab employees={this.props.employees?.filter(employee => employee.terminated())} onSelectEmployee={this.props.onSelectEmployee} showStatus={false} />
          </Tab>
          <Tab eventKey={2} title="Deleted" >
            <EmployeesTab employees={this.props.employees} onSelectEmployee={this.props.onSelectEmployee} showStatus={false} />
          </Tab>
        </Tabs>
      </div>
    )
  }

}

export default EmployeesList;
