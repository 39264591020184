// @flow
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import IBox from '../common/IBox';
import SweetAlert from 'sweetalert-react';
import { toastrNotification } from './../../helpers/Toastr';
import PollForm from './PollForm';
import { pollClient } from '../../clients/PollClient';
import { client } from '../Client';
import PollRow from './PollRow';

import 'sweetalert/dist/sweetalert.css';

class PollsList extends Component {

  state = {
    polls: [],
    departments: []
  }
  
  componentDidMount() {
    this.getPolls();
    this.getDepartments();
  }
  
  getPolls() {
    pollClient.getPolls().
    then((polls) => {
      this.setState({ polls: polls })
    });
  }
  
  getDepartments() {
    client.getEmployeeDepartmentsList().then((data) => {
      const depts = data.map((department, i) => (
        { title: department.title, value: department.id }
      ));
      this.setState({ departments: depts });
    });
  }
  
  refreshPolls = () => {
    this.getPolls();
  }
  
  openNewPollForm() {
    this.refs.pollForm.open();
  }
    
  render() {
    return (
      <div className="ibox">
          <div className="ibox-title">
            <h5>Polls</h5>
          </div>
          <div className="ibox-content">
            <div className="row">
              <div className="col-sm-12">
                <button className="btn btn-primary btn-sm pull-right" onClick={() => this.openNewPollForm()}><FontAwesomeIcon icon={faPlus}/> New Poll</button>
                <PollForm 
                  ref="pollForm"
                  onAddNewPoll={this.refreshPolls}
                  departments={this.state.departments} 
                />
              </div>
            </div>
            <div className="table-responsive project-list">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Poll question</th>
                    <th>Group</th>
                    <th>Created At</th>
                    <th>Expires At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.polls.map((poll) => (
                      <PollRow 
                        poll={poll} 
                        key={'poll'+poll.id}
                      />
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
      </div>    
    )
  }

}


export default PollsList;
