const toastr = require("./../../public/vendor/toastr/toastr.min.js");
toastr.options.timeOut = 8000;
toastr.options.positionCLass="toast-bottom-center";
export function toastrNotification(opts) {
  if (opts.success) {
    toastr.success(opts.message, opts.title);      
  } else {
    toastr.error(opts.message, opts.title);            
  }
}
