// @flow
import React, { Component } from "react";
import { client } from "../../Client";
import { activityActionClient } from "../../../clients/ActivityActionClient";

import "./ActivityPostForm.css";
import ActivityPostFormModal from "./ActivityPostFormModalV2";
import Media from "react-media";
import { Link, withRouter } from "react-router-dom";
import ReelForm from "../../reels/ReelForm";
import AppIcon from "../../icons/AppIcon";
import { solidIcons } from "../../icons/lib";

class ActivityPostForm extends Component {
  state = {
    canSubmit: true,
    selectedTag: "",
    messageBoxIsValid: true,
    attachmentsBoxIsValid: true,
    recognitionsBoxIsValid: true,
    recognizeFieldsIsOpen: false,
    formIsActive: false,
    activityActions: [],
    editedPostId: null,
    specialPoints: 100,
    showModal: false,
  };

  constructor(props) {
    super(props);

    this.postModalRef = React.createRef();
    this.postAttachmentsRef = React.createRef();
    this.editorRef = React.createRef();
    this.recognitionsEditorRef = React.createRef();

    this.reelFormRef = React.createRef();
  }

  componentDidMount() {
    this.props.onRef(this);
    this.getActivityActions();
    this.getDraft();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.rewardTags !== this.props.rewardTags &&
      this.props.rewardTags.length === 0
    ) {
      this.recognitionsEditorRef.current?.clear();
      this.setState({ recognizeFieldsIsOpen: false });
    }

    if (this.props.location.hash == "#new_post" && !this.state.showModal) {
      setTimeout(() => this.makeFormActive(), 300);
    }

    if (this.props.location.hash === "#new_reel" && !this.state.showReelForm) {
      console.log("working", this.reelFormRef);
      setTimeout(() => this.reelFormRef.current?.open(), 300);
    } else if (
      this.props.location.hash !== "#new_reel" &&
      this.state.showReelForm
    ) {
      this.reelFormRef.current?.close();
    }
  };

  componentWillUnmount() {
    this.props.onRef(undefined);
    this.sendPostDraft();
  }

  getDraft() {
    client.getActivityPostDraft().then((post_draft) => {
      if (post_draft.id) {
        this.editorRef.current?.loadContent(post_draft.message);
        this.postAttachmentsRef.current?.loadContent(post_draft.attachments);
      }
    });
  }

  getActivityActions() {
    activityActionClient.getActivityActions().then((actions) => {
      this.setState({ activityActions: actions });
    });
  }

  validateMessageBox = (result) => {
    this.setState({ messageBoxIsValid: result });
  };

  validateAttachmentsBox = (result) => {
    this.setState({ attachmentsBoxIsValid: result });
  };

  validateRecognitionsBox = (result) => {
    this.setState({ recognitionsBoxIsValid: result });
  };

  messageBoxIsValid = () => {
    const editor = this.editorRef.current;
    if (!editor) return false;
    if (editor.isValid()) {
      this.setState({ messageBoxIsValid: true });
      return true;
    } else {
      this.setState({ messageBoxIsValid: false });
      //toastrNotification({success: false, message: "Please write your message."});
      editor.focus();
      return false;
    }
  };

  attachmentsBoxIsValid = () => {
    if (
      this.postAttachmentsRef.current &&
      (this.postAttachmentsRef.current.getImages().length > 0 ||
        this.postAttachmentsRef.current.getVideos().length > 0)
    ) {
      this.setState({ attachmentsBoxIsValid: true });
      return true;
    } else {
      this.setState({ attachmentsBoxIsValid: false });
      return false;
    }
  };

  recognitionsBoxIsValid = () => {
    if (!this.state.recognizeFieldsIsOpen) {
      return true;
    }

    if (this.recognitionsEditorRef.current?.isValid()) {
      this.setState({ recognitionsBoxIsValid: true });
      return true;
    } else {
      this.setState({ recognitionsBoxIsValid: false });
      return false;
    }
  };

  isValid = () => {
    return (
      this.recognitionsBoxIsValid() &&
      (this.messageBoxIsValid() || this.attachmentsBoxIsValid())
    );
  };

  loadPost = (post) => {
    this.setState({ showModal: true }, () => {
      this.editorRef.current?.loadContent(post.message);
      this.postAttachmentsRef.current?.loadContent(post.attachments);
    });
    if (post.id) {
      this.setState({ editedPostId: post.id });
    }
  };

  reset = (clearAttachments = false) => {
    this.editorRef.current?.clear();
    if (clearAttachments) this.postAttachmentsRef.current?.clear();
    this.recognitionsEditorRef.current?.clear();
    this.postModalRef.current?.clear();
    this.setState({
      canSubmit: true,
      selectedTag: "",
      recognizeFieldsIsOpen: false,
      recognitionsBoxIsValid: true,
      messageBoxIsValid: true,
      attachmentsBoxIsValid: true,
      editedPostId: null,
    });
  };

  handleRecognizeFields() {
    if (this.state.recognizeFieldsIsOpen) {
      this.setState({ recognizeFieldsIsOpen: false, selectedTag: "" });
      this.recognitionsEditorRef.current?.clear();
    } else {
      const rewardTags = this.props.rewardTags || [];
      const selectedTag = rewardTags.length > 0 ? rewardTags[0].name : "";
      this.setState({ recognizeFieldsIsOpen: true, selectedTag: selectedTag });
    }
  }

  handleTagChange = (value) => {
    if (value == "Special") {
      const el = document.getElementsByClassName("custom-points-input")[0];
      if (el) {
        setTimeout(function () {
          el.focus();
        }, 100);
      }
    }
    this.setState({ selectedTag: value });
  };

  getFormData() {
    const department_id = this.props.selectedDepartment
      ? this.props.selectedDepartment.id
      : "";
    //console.log(this.recognitionsEditorRef)
    return {
      message: this.editorRef.current?.getContent(),
      recognitions: this.postModalRef.current?.getRecognitions(),
      tag: this.state.selectedTag,
      images: this.postAttachmentsRef.current?.getImages(),
      videos: this.postAttachmentsRef.current?.getVideos(),
      department_id: department_id,
      special_points: this.state.specialPoints,
      post_id: this.state.editedPostId,
    };
  }

  disableForm = () => {
    this.setState({ canSubmit: false });
  };

  enableForm = () => {
    this.setState({ canSubmit: true });
  };

  makeFormActive = () => {
    //this.setState({ formIsActive: true });
    this.setState({ showModal: true });
  };

  makeFormActiveAndOpenFileUpload = () => {
    this.setState({ showModal: true }, () => {
      this.postAttachmentsRef.current?.openFileInputDialog();
    });
  };

  makeFormActiveAndOpenRecognitions = () => {
    this.setState({ showModal: true }, () => {
      this.postModalRef.current?.toggleRecognitionsView();
    });
  };

  selectActivityAction = (key, evt) => {
    const actionMessage = this.state.activityActions.find(
      (a) => a.id === key
    ).message;
    this.editorRef.current?.pasteContent(`<p>${actionMessage}&nbsp;</p>`);
  };

  sendPost = () => {
    if (this.isValid()) {
      //console.log(this.postAttachmentsRef.current?.getImages());
      const formData = this.getFormData();
      console.log(formData);
      //debugger
      if (this.editPostMode()) {
        if (this.props.sendPostUpdate(formData)) {
          this.handleModalClose(false);
          //this.reset();
        }
      } else {
        if (this.props.sendPost(formData)) {
          this.handleModalClose(false);
          //this.reset();
        }
      }
    } else {
      return this.editorRef.current?.focus();
    }
  };

  sendPostDraft = () => {
    if (this.isValid()) {
      const formData = this.getFormData();
      this.props.sendPostDraft(formData);
    } else {
      return;
    }
  };

  editPostMode = () => {
    return !!this.state.editedPostId;
  };

  getSelectedTag = () => {
    return this.state.selectedTag;
  };

  updateSpecialPoints = (e) => {
    this.setState({ specialPoints: e.target.value });
  };

  handleModalClose = (clearAttachments = true) => {
    if (this.props.location.hash == "#new_post") {
      this.props.history.push(this.props.location.pathname);
      setTimeout(() => {
        this.reset(clearAttachments);
        this.setState({ showModal: false });
      }, 500);
    } else {
      this.reset(clearAttachments);
      this.setState({ showModal: false });
    }
  };

  render() {
    const rewardTags = this.props.rewardTags || [];
    return (
      <div className="ibox">
        <div
          className="ibox-content desktop-post-form"
          onClick={() => this.makeFormActive()}
          style={{
            display: this.state.formIsActive ? "none" : "block",
            paddingBottom: "15px",
          }}
        >
          <div className="row">
            <div
              className="col-md-12 df post-form-link-new"
              style={{ alignItems: "center" }}
            >
              <Media
                query="(min-width: 768px)"
                render={() => (
                  <div className="user-avatar">
                    <img
                      alt="image"
                      src={client.currentUser().avatar_image_url()}
                    />
                  </div>
                )}
              />
              <div className="preview-text-v2" style={{ flex: "1 1 auto" }}>
                What's on your mind?
              </div>

              <div
                className="icon-button"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Link to="/activity#new_reel" style={{ color: "unset" }}>
                  <svg
                    height="25"
                    viewBox="0 0 256 257"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 93.0923V197.819C0.00555146 213.248 6.13718 228.044 17.0472 238.954C27.9572 249.864 42.7527 255.996 58.1817 256.001H197.818C213.247 255.996 228.042 249.864 238.952 238.954C249.862 228.044 255.994 213.248 256 197.819V93.0923H0ZM168.611 173.034L98.7926 212.365C97.0647 213.378 95.0935 213.901 93.0908 213.878C90.0182 213.834 87.0837 212.594 84.9109 210.421C82.738 208.248 81.498 205.314 81.4544 202.241V123.58C81.4712 121.555 82.0139 119.57 83.0291 117.819C84.0443 116.067 85.4972 114.609 87.2455 113.589C88.9937 112.568 90.9772 112.019 93.0015 111.996C95.0257 111.972 97.0213 112.476 98.7926 113.456L168.611 152.787C170.41 153.794 171.909 155.264 172.952 157.043C173.995 158.823 174.545 160.848 174.545 162.91C174.545 164.973 173.995 166.998 172.952 168.778C171.909 170.557 170.41 172.026 168.611 173.034Z"
                      fill="currentColor"
                    />
                    <path
                      d="M71.0981 69.8199H4.20613e-05V58.1836C-0.0116126 48.4987 2.39895 38.9647 7.01235 30.4492C11.6257 21.9337 18.2955 14.7072 26.4145 9.42728L40.2618 28.2782L71.0981 69.8199Z"
                      fill="currentColor"
                    />
                    <path
                      d="M175.941 69.8194H100.072L65.6283 23.274L48.8719 0.81587C51.9423 0.251203 55.0593 -0.0215282 58.181 0.00132543H124.741L141.73 23.274L175.941 69.8194Z"
                      fill="currentColor"
                    />
                    <path
                      d="M256 58.1838V69.8201H204.8L170.473 23.2747L153.367 0.00204468H197.818C213.247 0.00759614 228.043 6.13922 238.953 17.0492C249.863 27.9592 255.994 42.7547 256 58.1838Z"
                      fill="currentColor"
                    />
                  </svg>
                </Link>
              </div>

              <div
                className="icon-button"
                onClick={(event) => {
                  event.stopPropagation();
                  this.makeFormActiveAndOpenRecognitions();
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    data-name="Path 1"
                    d="M467 120h-61.041A74.959 74.959 0 0 0 346 0c-24.911 0-43.28 8.925-57.809 28.087C276.036 44.119 267.148 66.5 256 94.785c-11.148-28.283-20.036-50.666-32.191-66.7C209.28 8.925 190.911 0 166 0a74.959 74.959 0 0 0-59.959 120H45a45.051 45.051 0 0 0-45 45v30a45.071 45.071 0 0 0 30 42.42V467a45.051 45.051 0 0 0 45 45h362a45.051 45.051 0 0 0 45-45V237.42A45.071 45.071 0 0 0 512 195v-30a45.051 45.051 0 0 0-45-45Zm-183.466-13.26C306.513 48.442 315.249 30 346 30a45 45 0 0 1 0 90h-67.713c1.838-4.615 3.591-9.058 5.247-13.26ZM166 30c30.751 0 39.487 18.442 62.466 76.74 1.656 4.2 3.409 8.645 5.247 13.26H166a45 45 0 1 1 0-90Zm30 452H75a15.017 15.017 0 0 1-15-15V240h136Zm0-272H45a15.017 15.017 0 0 1-15-15v-30a15.017 15.017 0 0 1 15-15h151Zm90 272h-60V150h60Zm166-15a15.017 15.017 0 0 1-15 15H316V240h136Zm30-272a15.017 15.017 0 0 1-15 15H316v-60h151a15.017 15.017 0 0 1 15 15Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div
                className="icon-button"
                onClick={(event) => {
                  event.stopPropagation();
                  this.makeFormActiveAndOpenFileUpload();
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 442">
                  <path
                    d="M492 244a20 20 0 0 0 20-20v-74a70.079 70.079 0 0 0-70-70h-10V70a70.079 70.079 0 0 0-70-70H69A69.078 69.078 0 0 0 0 69v223a70.079 70.079 0 0 0 70 70h10v10a70.079 70.079 0 0 0 70 70h292.169A69.91 69.91 0 0 0 512 372.164l-.014-45.7a20 20 0 0 0-4.755-12.94l-94.664-111.47a20 20 0 0 0-30.561.085L291.19 310.287l-42.675-51.979a20 20 0 0 0-30.658-.308L120 372.442V149a29.033 29.033 0 0 1 29-29h293a30.034 30.034 0 0 1 30 30v74a20 20 0 0 0 20 20Zm-259.254 58.14 42.813 52.146a20 20 0 0 0 30.774.17L397.408 246l74.58 87.82.012 38.35A29.864 29.864 0 0 1 442.169 402H150q-1.287 0-2.549-.107ZM80 149v173H70a30.034 30.034 0 0 1-30-30V69a29.033 29.033 0 0 1 29-29h293a30.034 30.034 0 0 1 30 30v10H149a69.078 69.078 0 0 0-69 69Zm124 14a25 25 0 1 1-25 25 25 25 0 0 1 25-25Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <ActivityPostFormModal
          ref={this.postModalRef}
          show={this.state.showModal}
          handleClose={this.handleModalClose}
          selectedDepartment={this.props.selectedDepartment}
          editMode={this.editPostMode()}
          validateMessageBox={this.validateMessageBox}
          disableForm={this.disableForm}
          enableForm={this.enableForm}
          editedPostId={this.state.editedPostId}
          validateAttachmentsBox={this.validateAttachmentsBox}
          validateRecognitionsBox={this.validateRecognitionsBox}
          selectedTag={this.state.selectedTag}
          recognitionsBoxIsValid={this.state.recognitionsBoxIsValid}
          postAttachmentsRef={this.postAttachmentsRef}
          getSelectedTag={this.getSelectedTag}
          handleTagChange={this.handleTagChange}
          rewardTags={rewardTags}
          specialPoints={this.state.specialPoints}
          updateSpecialPoints={this.updateSpecialPoints}
          editorRef={this.editorRef}
          recognitionsEditorRef={this.recognitionsEditorRef}
          sendPost={this.sendPost}
        />

        <ReelForm ref={this.reelFormRef} history={this.props.history} />
      </div>
    );
  }
}

export default withRouter(ActivityPostForm);
