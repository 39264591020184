import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { toastrNotification } from './../../helpers/Toastr';
import { client } from '../Client';
import Input from '../form_components/Input';
import Select from '../form_components/Select';

// import 'react-select/dist/react-select.css';

class UpdateAttachment extends Component {

  state = {
    categories: [],
    showDialog: false,
    canSubmit: false,
    attachment: {},
    fields: {
      title: '',
      attachment_category_id: ''
    }
  };

  show(attachment) {
    this.setState({ showDialog: true });
    client.getAttachmentCategories().then((categories) => {
      const attachmentCategories = categories.map((category) => {
             let item = {};
             item.value = category.id;
             item.title = category.title;

             return item;
      });
      let attachmentFields = {}
      attachmentFields.title = attachment.title;
      attachmentFields.category_id = attachment.category.id;
      this.setState({ categories: attachmentCategories, 
                      fields: attachmentFields,
                      attachment: attachment })
    });
  }

  close() {
    this.setState({ showDialog: false });
    //this.resetForm();
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
    
  formSubmit = (data) => {
    const _this = this;
    client.updateAttachment(this.state.attachment, data)
    .then((attachment) => {
        if (attachment.id) {
          _this.props.onUpdateAttachment();
          toastrNotification({success: true, title: 'Attachment was successfully updated'});
          _this.close();
        } else {
          _this.refs.rewardUpdateForm.updateInputsWithError(attachment);
        }
    });
  }
    
  resetForm = () => {
     formsy.reset();
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }

  onUnitChange = (newUnit) => {
    this.setState({ unit: newUnit });
  }
    

 render() {

    return (
      <Modal show={this.state.showDialog } onHide={() => this.close()} >
        <Modal.Header closeButton>
          <Modal.Title>Edit Attachment</Modal.Title>
        </Modal.Header>
        <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="attachmentUpdateForm" >
          <Modal.Body >
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="title" className="sr-only"></label>
                  <Input
                    name="title"
                    placeholder="Enter Title *"
                    className="form-control"
                    tabIndex="1"
                    value={this.state.fields.title}
                    onChange={this.onInputChange}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="attachment_category_id" className="sr-only"></label>
                  <Select
                    name="attachment_category_id"
                    options={this.state.categories}
                    placeholder="Choose Category*"
                    className="form-control"
                    tabIndex="2"
                    value={this.state.fields.category_id}
                    onChange={this.onInputChange}
                    required
                  />
                </div>
              </div> 
            </div>   
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.close()}>Cancel</Button>
            <Button 
              bsStyle="primary" 
              type="submit"
              disabled={!this.state.canSubmit}
            >Update</Button>
          </Modal.Footer>
        </Formsy>  
      </Modal>
    );
  }
}

export default UpdateAttachment;
