import React, { Component } from 'react';

class ImportCredentialsResults extends Component {

  componentDidUpdate = (nextProps, nextState) => {
    $('[data-toggle="popover"]').popover({
      html:true,
      content:function(){
        return $($(this).data('contentwrapper')).html();
      }      
    });
  }

 render() {

    return (
      <div>
      { this.props.json &&
        <div>
          <p>
            <span>added {this.props.json.added.length}</span>, {' '}
            <span>failed {' '}
              <a data-toggle="popover" data-placement="auto bottom" data-contentwrapper='.failed-content-wrapper' >
                {this.props.json.failed.length}
              </a>          
            </span>
          </p>
          <div className='failed-content-wrapper' style={{display:'none'}} >
            <ul>
            { this.props.json.failed.map((record) => ( 
              <li key={record.item}> {record.item} {record.message}</li>
            ))}
            </ul>
          </div>
        </div>
      }
      </div>  
    );
  }
}

export default ImportCredentialsResults;
