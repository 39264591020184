// @flow
import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import AcknowledgementNotification from './AcknowledgementNotification';  
import PollNotification from './PollNotification';  
import ActivityPostView from '../../views/ActivityPost';

class NotificationModal extends Component {
    
  state = {
    showModal: false
  };
            
  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true });
  }
    
  renderNotification = () => {
    if ( this.props.notification.type === 'acknowledgement' ) {
      return (
        <AcknowledgementNotification notification={this.props.notification} close={this.close}/>
      ) 
    } else if ( this.props.notification.type === 'poll' ) {
      return (
        <PollNotification notification={this.props.notification} close={this.close}/>
      ) 
    }
    else if ( this.props.notification.type === 'activity_post_reward' || this.props.notification.type === 'activity_recognition') {
      return (
        <ActivityPostView notification={this.props.notification} close={this.close}/>
      ) 
    }
  }  
  
 render() {

    return (
      <div>
        <Modal show={this.state.showModal} onHide={this.close}>
            {this.renderNotification()}
        </Modal>
      </div>  
    );
  }
}

export default NotificationModal;
