import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';

class IBox extends Component {

  hasTools() {
    return this.props.onEdit;
  }

  render() {
      return (
        <div className="ibox">
              <div className="ibox-title">
                  <h5>{this.props.title}</h5>
                  { this.hasTools() &&
                    <div className="ibox-tools">
                      { this.props.onEdit &&
                        <a onClick={this.props.onEdit}> &nbsp; <FontAwesomeIcon icon={faPencilAlt}/> Edit</a>
                      }
                    </div>
                  }
              </div>
              <div className="ibox-content">
                {this.props.children}
              </div>
        </div>
      )
    }
}
export default IBox;
