// @flow
import React, { Component } from 'react';
import { Modal, Panel, Button, OverlayTrigger, Tooltip,  Collapse, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage} from '@fortawesome/free-regular-svg-icons/faImage';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import ActivityTextBox from './ActivityTextBox';
import { client } from '../../Client';
import { activityActionClient } from '../../../clients/ActivityActionClient';
import { activityRecognitionTagClient } from '../../../clients/ActivityRecognitionTagClient';
import Config from 'config';
import Post from '../../../models/ActivityPost';
import { toastrNotification } from '../../../helpers/Toastr';
import ActivityPostAttachments from './ActivityPostAttachments';
import RawHtml from 'react-raw-html';
import Coin from '../../common/Coin';
import {RadioGroup, Radio} from 'react-radio-group';
import ActivityRecognitionsBox from './ActivityRecognitionsBox';

class ActivityPostFormModal extends Component {

  state = {
    showModal: false,
    canSubmit: true,
    messageBoxIsValid: true,
    attachmentsBoxIsValid: true,
    openActivityActions: true,
    recognitionsBoxIsValid: true,
    recognizeFieldsIsOpen: false,
    activityActions: [],
    editablePost: null,
    openDropZone: false,
    selectedTag: "",
    specialPoints: 100    
  }
  
  componentDidMount() {  
    this.getActivityActions();
  }
  
  componentWillUnmount() {
    //this.sendPostDraft();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.editablePost !== prevState.editablePost && this.state.editablePost) {
      const editablePost = this.state.editablePost;
      this.editor.loadContent(editablePost.message);
      this.postAttachmentsRef.loadContent(editablePost.attachments);
    };
    if (prevState.openDropZone == false && this.state.openDropZone == true) {
      this.refs.addImageVideoBtn.click();
      this.setState({ openDropZone: false });
    };
    if (prevProps.rewardTags !== this.props.rewardTags && this.props.rewardTags.length === 0) {
      this.refs.recognitionsEditor?.clear();
      this.setState({ recognizeFieldsIsOpen: false });
    } 
  }
  
  getDraft() {
    client.getActivityPostDraft().
    then((postDraft) => {
      if (postDraft.id && !this.state.editablePost) {
        this.editor.loadContent(postDraft.message);
        this.postAttachmentsRef.loadContent(postDraft.attachments);
      }
    });
  } 
  
  getActivityActions() {
    activityActionClient.getActivityActions().
    then((actions) => {
      this.setState({ activityActions: actions })
    });
  }
  
  close = () => {
    this.setState({ showModal: false });
  }

  isOpened = () => {
    return this.state.showModal;
  }

  open = (openDropZone) => {
    if (openDropZone) this.setState({ openDropZone: true });
    this.getDraft();
    this.setState({ showModal: true, recognizeFieldsIsOpen: false, recognitionsBoxIsValid: true });
  }
  
  closeForm = () => {
    if (!this.state.editablePost) this.sendPostDraft();
    this.setState({ editablePost: null })
    this.close();
    this.props.changeLocation(this.props.currentPathname);
  }
  
  validateMessageBox = (result) => {
    this.setState({ messageBoxIsValid: result })
  }
  
  validateAttachmentsBox = (result) => {
    this.setState({ attachmentsBoxIsValid: result })
  }
  
  validateRecognitionsBox = (result) => {
    this.setState({ recognitionsBoxIsValid: result })
  }
      
  messageBoxIsValid = () => {
    if (this.editor.isValid()) {
      this.setState({ messageBoxIsValid: true });
      return true;
    } else {
      this.setState({ messageBoxIsValid: false });
      //toastrNotification({success: false, message: "Please write your message."});
      this.editor.focus();
      return false;
    }
  }
  
  attachmentsBoxIsValid = () => {
    if (this.postAttachmentsRef.getImages().length > 0 || this.postAttachmentsRef.getVideos().length > 0) {
      this.setState({ attachmentsBoxIsValid: true });
      return true;
    } else {
      this.setState({ attachmentsBoxIsValid: false });
      return false;
    }
  }
  
  recognitionsBoxIsValid = () => {
    if (!this.state.recognizeFieldsIsOpen) { return true };
    
    if (this.refs.recognitionsEditor?.isValid()) {
      this.setState({ recognitionsBoxIsValid: true });
      return true;
    } else {
      this.setState({ recognitionsBoxIsValid: false });
      return false;
    }
  }
  
  isValid = () => {
    return this.recognitionsBoxIsValid() && (this.messageBoxIsValid() || this.attachmentsBoxIsValid());
  }
  
  loadPost = (post) => {
    this.setState({ showModal: true, editablePost: post });
  }

  handleRecognizeFields() {
    if (this.state.recognizeFieldsIsOpen) {
      this.setState({ recognizeFieldsIsOpen: false, selectedTag: "" })
      this.refs.recognitionsEditor?.clear();
    } else {
      const rewardTags = this.props.rewardTags || [];
      const selectedTag = rewardTags.length > 0 ? rewardTags[0].name : "";
      this.setState({ recognizeFieldsIsOpen: true, selectedTag: selectedTag });
    }
  }
  
  handleTagChange = (value) => {
    this.setState({ selectedTag: value });
  }
        
  getFormData() {
    const department_id = this.props.selectedDepartment ? this.props.selectedDepartment.id : '';
    return { message: this.editor.getContent(),
             recognitions: this.refs.recognitionsEditor?.getRecognitions(),
             tag: this.state.selectedTag,
             images: this.postAttachmentsRef.getImages(),
             videos: this.postAttachmentsRef.getVideos(),
             special_points: this.state.specialPoints,             
             department_id: department_id
           }
  }
  
  disableForm = () => {
    this.setState({ canSubmit: false });
  }
  
  enableForm = () => {
    this.setState({ canSubmit: true });
  }
  
  editPostMode = () => {
    return !!this.state.editablePost
  }
  
  renderMessageBoxErrors = () => {
    if (this.state.attachmentsBoxIsValid) {
      return (<span></span>)
    } else {
      if (this.state.messageBoxIsValid) {
        return (<span></span>)
      } else {
        return (  
          <span className="form-error">The message can not be empty</span> 
        )
      }    
    }
  }
  
  sendPost = () => {
    if (this.isValid()) {
      const formData = this.getFormData();
      if (this.editPostMode()) {
        const postId = this.state.editablePost.id;
        formData.post_id = postId;
        this.props.sendPostUpdate(formData)
      } else {
        this.props.sendPost(formData)   
      };
      this.setState({ editablePost: null });
      this.props.changeLocation(this.props.currentPathname); 
      this.close();
    } else {
      return this.editor.focus();
    }
  }
  
  sendPostDraft = () => {
    if (this.isValid()) {
      const formData = this.getFormData();
	    this.props.sendPostDraft(formData)      
    } else {
      return
    }
  }
  
  toggleActivityActionsList = () => {
    this.setState({ openActivityActions: !this.state.openActivityActions })
  }
  
  selectActivityAction(actionMessage) {
    this.editor.pasteContent(`<p>${actionMessage}&nbsp;</p>`)  
  }
  
  getSelectedTag = () => {
    return this.state.selectedTag;
  }

  updateSpecialPoints = (e) => {
    this.setState({specialPoints: e.target.value})
  }

 render() {
       
    const tooltip = (
      <Tooltip id="modal-tooltip">
      </Tooltip>
    )
    
    const rewardTags = this.props.rewardTags || [];
      
    const recognitionPanel = (
      <Panel className="recognize-fields" collapsible="true" expanded={this.state.recognizeFieldsIsOpen} onToggle={()=>{}}>
        <Panel.Collapse>
        <div className="form-group">
          <div>
            <ActivityRecognitionsBox
              ref="recognitionsEditor"
              placeholder="Start typing colleague name"
              validateRecognitionsBox={this.validateRecognitionsBox}
              selectedTag={this.state.selectedTag}
              autoFocus={this.state.selectedTag != 'Special' && this.state.recognizeFieldsIsOpen}
            />
            { this.state.recognitionsBoxIsValid ?
               ""
               :
               <span className="form-error">Select the person you want to reward</span>
            }
          </div>
        </div>
        <div className="form-group">
          <RadioGroup name="tag" selectedValue={this.getSelectedTag()} onChange={this.handleTagChange}>
            {rewardTags.map(tag => {
                return (
                  <span className="badge badge-plain hashtag-badge" key={"tag"+tag.id}>  
                    <label className="radio-inline" >
                      <Radio value={tag.name} />
                      #{tag.name}
                      {tag.points > 0 &&
                        <span><Coin width="16px" /> {tag.points}</span>
                      }  
                    </label>
                  </span>
                )
              }) 
            }  
            
            <span> 
              <span className="badge badge-plain hashtag-badge" style={{'paddingTop': '2px', 'paddingBottom': '2px'}}>  
                <label className="radio-inline" >
                  <Radio value='Special' style={{'marginTop': '5px'}}/>
                  #Special &nbsp;
                  <input type='number' min='1' disabled={this.state.selectedTag != 'Special'} value={this.state.specialPoints} onChange={this.updateSpecialPoints} className="custom-points-input"/> 
                  <span> <Coin width="16px" /> </span>
                </label>
              </span>                
            </span>            
            
          </RadioGroup>
        </div>
        </Panel.Collapse>
      </Panel>
    )
    
    const attachmentsBox = (
      <div className="row">
        <div className="col-md-12" style={{marginBottom: '5px'}}>
          <ActivityPostAttachments 
            onRef={ref => (this.postAttachmentsRef = ref)} 
            disablePostForm={this.disableForm}
            enablePostForm={this.enableForm}
            validateAttachmentsBox={this.validateAttachmentsBox}
            editPostMode={!!this.state.editablePost}
          />
        </div>
      </div>
    )
    
    const buttons = (
      <div className="row">
        <div className="col-md-12 post-buttons">
          {rewardTags.length > 0 &&
            <button className="btn btn-primary btn-block" style={{marginBottom: "10px"}} onClick={()=> this.handleRecognizeFields()}>
              <FontAwesomeIcon icon={faStar}/> Recognize Colleague <FontAwesomeIcon icon={this.state.recognizeFieldsIsOpen ? faChevronUp : faChevronDown}/>
            </button>
          }  
        </div>
        <div className="col-md-12 post-buttons">
          <button className="add-post-photo-btn btn btn-primary btn-block" style={{marginLeft: "0px"}} ref="addImageVideoBtn">
            <FontAwesomeIcon icon={faImage}/> Image/Video
          </button>
        </div>
        {this.state.activityActions.length > 0 &&
          <div className="col-md-12 post-buttons" style={{marginTop: "10px"}}>
            <button className="btn btn-primary btn-block" onClick={this.toggleActivityActionsList}>
              <FontAwesomeIcon icon={faList}/> Hustle Feed <FontAwesomeIcon icon={this.state.openActivityActions ? faChevronUp : faChevronDown}/>
            </button>
            <Collapse in={this.state.openActivityActions}> 
              <ListGroup>
                { this.state.activityActions.map((action) => 
                  {
                    return (<ListGroupItem key={action.id} onClick={() => this.selectActivityAction(action.message)}><RawHtml.div>{ action.message }</RawHtml.div></ListGroupItem>)  
                  }
                )}
              </ListGroup>
            </Collapse>      
          </div>
        }
      </div>
    ) 

    return (
      <Modal show={this.state.showModal} onHide={this.close} className="full-screen-modal new-post-modal">
          <Modal.Header  style={{display: "flex"}}>
            <div style={{alignSelf: "center"}} disabled={!this.state.canSubmit} onClick={() => this.closeForm()}><FontAwesomeIcon icon={faArrowLeft}/></div>
            <div style={{alignSelf: "center", marginLeft: "20px"}}> 
              { this.editPostMode() ? 'Edit Post' : 'Create New Post' }
            </div>
            <button className="btn btn-primary" style={{marginLeft: "auto"}} disabled={!this.state.canSubmit} onClick={() => this.sendPost()}>
              { this.editPostMode() ? 'Update' : 'Share' }
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                { recognitionPanel }
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-12 mobile-post-form">
                <div className="post-editor">
                  <ActivityTextBox
                    onRef={ref => (this.editor = ref)}
                    placeholder="Write your message"
                    validateMessageBox={this.validateMessageBox}
                  />  
                </div>
              </div>
            </div>
            
            { attachmentsBox }
                    
            { buttons }
          </Modal.Body>
      </Modal>
    );
  }
}

export default ActivityPostFormModal;
