// @flow
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye';
import moment from 'moment';
import { statusLabel } from '../../helpers/Poll';

class PollRow extends Component {
          
  render() {
    let poll = this.props.poll;
    
    return (
      <tr>
        <td>
          { statusLabel(poll) }
        </td>
        <td>
          <Link to={`/poll/${poll.id}`} >
            { poll.short_question }
          </Link>
        </td>
        <td>
          { poll.department ? poll.department : "All Groups" }
        </td>
        <td>
          { moment(poll.created_at).format('D MMM YYYY') }
        </td>
        <td>
          { moment(poll.expires_at).format('D MMM YYYY') }
        </td>
        <td>
          <Link to={`/poll/${poll.id}`} className="btn btn-white btn-sm m-l-xs">
            <FontAwesomeIcon icon={faEye}/> View
          </Link>
        </td>
      </tr>
    )
  }

}


export default PollRow;
