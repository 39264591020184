import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { toastrNotification } from './../helpers/Toastr';
import SweetAlert from 'sweetalert-react';
import AvatarWithTooltip from '../components/common/AvatarWithTooltip';
import { groupClient } from '../clients/GroupClient';
import moment from 'moment';
import { client } from '../components/Client.js';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import EditGroupForm from '../components/groups/EditGroupForm';
import { mediumDateFormat } from '../helpers/Formatter';

import '../components/raffle/RaffleView.css'

class GroupView extends Component {

    state = {
      group: { admin: {}, members: [], candidates: [] },
      spinnerClass: 'sk-loading',
      loading: false,
      showDeleteGroupNotification: false,
      showExitGroupNotification: false,
      shouldRedirect: false
    }

    componentDidMount() {
      this.getGroup();
    }

    getGroup = () => {
      groupClient.getGroup(this.props.match.params.id).then((group) => {
        this.setState({ group: group, spinnerClass: '', loading: false})
      });
    }
        
    deleteGroup = () => {
      this.setState({ showDeleteGroupNotification: false })
      groupClient.deleteGroup(this.props.match.params.id).then((response) => {
        if (response.success) {
          toastrNotification({ success: true, title: 'The group was successfully deleted.' });
        } else {
          toastrNotification({ success: false, title: "Unable to delete group." });
        }
        this.setState({ loading: false, shouldRedirect: response.success })
      });
    }
    
    openEditGroupForm = () => {
      this.refs.editGroupForm.open()
    }
    
    handleGroupUpdate = (mGroupData) => {
      const mGroup = Object.assign({}, this.state.group,
                                       {title: mGroupData.title,
                                        description: mGroupData.description,
                                        is_private: mGroupData.is_private }
                                  )
      this.setState({ group: mGroup })
    }
    
    exitGroup = () => {
      this.setState({ showExitGroupNotification: false, loading: true })
      groupClient.exitGroup(this.props.match.params.id).then((response) => {
        if (response.success) {
          toastrNotification({ success: true, title: 'You successfully left the group.' });
        } else {
          toastrNotification({ success: false, title: "Unable to exit group." });
        }
        this.setState({ loading: false, shouldRedirect: true })
      });
    }

    statusLabel = () => {
      const group = this.state.group
      switch (group.is_private) 
         {
             case false: return (<span className="label label-success">Public</span>);
             case true: return (<span className="label label-danger">Private</span>);
         }        
    }
    
    render() {
        const group = this.state.group

        if (this.state.shouldRedirect) {
          return (
            <Redirect to="/groups" />
          )
        } else {
          return (
              <div className={'wrapper wrapper-content animated ' + this.state.spinnerClass}>
                <div className="sk-spinner sk-spinner-double-bounce">
                  <div className="sk-double-bounce1"></div>
                  <div className="sk-double-bounce2"></div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-lg-8 col-lg-offset-2">
                    <Link to="/groups" className="btn btn-white btn-sm pull-right m-b-sm hidden-xs">Back to Groups</Link>                  
                    <div className="ibox">
                      <div className="ibox-content">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="m-b-md">
                              <h2>{group.title} {group.default && <small>(default group)</small>}</h2>
                            </div>
                            <dl className="dl-horizontal">
                              <dt>Type:</dt>
                              <dd>{this.statusLabel()}</dd>
                              <dt>Description:</dt>
                              <dd>{group.description}</dd>
                              <dt>Group Created On:</dt>
                              <dd>{mediumDateFormat(group.created_at)}</dd>
                              <dt>Admin:</dt>
                              {group.creator && <dd>{group.creator.full_name}</dd> }
                              {(group.current_user_is_member || group.current_user_is_admin) &&
                                <span>
                                  <dt>Members:</dt>
                                  <dd><Link to={`/group/${group.id}/members`}>{group.members.length}</Link></dd>
                                </span>  
                              }
                              {group.current_user_is_admin && group.candidates.length > 0 &&
                                <span>
                                  <dt>Join Requests:</dt>
                                  <dd><Link to={`/group/${group.id}/candidates`}>{group.candidates.length}</Link></dd>
                                </span>
                              }
                            </dl>
                          </div>
                        </div>
                        <div className="row m-b-sm">
                          <div className="col-md-12">
                            {!group.default && 
                              <div className="details-page-btn-group">
                                {(group.current_user_is_member || group.current_user_is_admin) &&
                                  <button type="button" className={'btn btn-white m-r-sm'} onClick={() => this.openEditGroupForm()}><FontAwesomeIcon icon={faPencilAlt}/> Edit</button>
                                }
                                {(group.current_user_is_member || group.current_user_is_admin) &&
                                  <Link to={`/group/${group.id}/members`} className={'btn btn-white m-r-sm'}><FontAwesomeIcon icon={faUsers} /> Manage Members</Link>
                                }
                                { group.current_user_is_admin &&
                                  <button type="button" className='btn btn-white m-r-sm' onClick={() => this.setState({ showDeleteGroupNotification: true })}><FontAwesomeIcon icon={faTimes}/> Delete</button>
                                }
                                { group.current_user_is_member && !group.current_user_is_admin &&
                                  <button type="button" className='btn btn-white' onClick={() => this.setState({ showExitGroupNotification: true })}><FontAwesomeIcon icon={faSignOutAlt} /> Exit</button>
                                }
                                <SweetAlert
                                  show={this.state.showDeleteGroupNotification}
                                  type="warning"
                                  title="Are you sure you want to delete the group?"
                                  confirmButtonColor="#DD6B55"
                                  showCancelButton
                                  text="This operation can not be undone."
                                  onConfirm={() => this.deleteGroup()}
                                  onCancel={() => this.setState({ showDeleteGroupNotification: false })}
                                />
                                <SweetAlert
                                  show={this.state.showExitGroupNotification}
                                  type="warning"
                                  title="Are you sure you want to leave the group?"
                                  confirmButtonColor="#DD6B55"
                                  showCancelButton
                                  onConfirm={() => this.exitGroup()}
                                  onCancel={() => this.setState({ showExitGroupNotification: false })}
                                />
                                <EditGroupForm ref="editGroupForm" group={group} onUpdateGroup={this.handleGroupUpdate} />
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>  
              </div>
          )
        }
    }
}

export default GroupView
