import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { client } from '../components/Client';
import { surveyQuestionClient } from '../clients/SurveyQuestionClient';
import { toastrNotification } from './../helpers/Toastr';
import moment from 'moment';
import SurveyQuestion from '../components/survey/SurveyQuestion';
import StepZilla from 'react-stepzilla';
import QuestionsSelectionStep from '../components/survey/QuestionsSelectionStep';
import SurveyDetailsStep from '../components/survey/SurveyDetailsStep';
import DepartmentsSelectionStep from '../components/survey/DepartmentsSelectionStep';
import SurveyPreviewStep from '../components/survey/SurveyPreviewStep';
import NewQuestionModal from '../components/survey/NewQuestionModal';
import NewCategoryModal from '../components/survey/NewCategoryModal';

import '../components/survey/SurveyWizard.css';

class NewSurveyView extends Component {
  
  state = {
    selectedCategory: -1,
    categories: [],
    selectedQuestions:[],
    checkboxes: {},
    departments: [],
    selectedDepartments: [],
    employees_checkboxes: {},
    isInitialStep: true, 
    formFields: {
      name: '',
      start_time: moment(),
      end_time: moment().add(3, 'days'),
      points: 0
    }
  }
    
  getSurveyData() {
    return this.state;
  }

  updateSurveyData(update) {
    let newState = {};
    Object.keys(update).map(function(key, index) {
      newState[key] = update[key]
    });
    this.setState(newState);
  }
  
  componentDidMount() {
    this.getSurveyCategories();
    this.getEmployeeDepartments();
  }  
  
  getSurveyCategories = () => {
    let nextSelectedQuestions = [];
    let _this = this;
    client.getSurveyCategories().then((categories) => {      
      this.setState({ categories: categories });
      categories.forEach(function(cat, i, arr) {
        cat.questions.forEach(function(question, i, arr) {          
          if (question.required) {
            nextSelectedQuestions = _this.state.selectedQuestions.concat(question);
            const chks = Object.assign({}, _this.state.checkboxes, {['q'+question.id]: true });
            _this.setState({checkboxes: chks, selectedQuestions: nextSelectedQuestions});
          }  
        });  
      });
    });
  }
  
  onDeleteSurveyQuestion = (question) => {
    const _this = this;
    surveyQuestionClient.removeSurveyQuestion(question)
    .then((response) => {
        if (response.success) {
          let selectedQuestionsUpdated = this.state.selectedQuestions.filter(function(q) {
            return q.id !== response.survey_question_id;
          });
          _this.setState({ selectedQuestions: selectedQuestionsUpdated });
          _this.reloadSurvey();
          toastrNotification({success: true, title: 'Survey Question was successfully removed'});
        } else {
          toastrNotification({success: false, title: 'Unable to remove survey question'});
        }
    });
  }
  
  onDeleteSurveyCategory = (category) => {
    const _this = this;
    client.removeSurveyCategory(category)
    .then((response) => {
        if (response.success) {
          let selectedQuestionsUpdated = this.state.selectedQuestions.filter(function(q) {
            return q.survey_category_id !== response.survey_category_id;
          });
          _this.setState({ selectedQuestions: selectedQuestionsUpdated });
          _this.reloadSurvey();
          toastrNotification({success: true, title: 'Category was successfully removed'});
        } else {
          toastrNotification({success: false, title: 'Unable to remove category'});
        }
    });
  }
  
  reloadSurvey = () => {
    client.getSurveyCategories().then((categories) => {      
      this.setState({ categories: categories });
    });
    this.setState({ selectedCategory: -1 });
  }
  
  
  getEmployeeDepartments = () => {
    client.getEmployeeDepartmentsList()
    .then((departments) => {
      this.setState({ departments: departments })
     });    
  }
  
  openNewQuestionModal = () => {
    this.refs.newSurveyQuestionModal.open();
  }
  
  openNewCategoryModal = () => {
    this.refs.newSurveyCategoryModal.open();
  } 
      
  render() {
      const steps =
      [ 
        {name: 'Select questions', component: <QuestionsSelectionStep 
                                                categories={this.state.categories} 
                                                selectedQuestions={this.state.selectedQuestions}
                                                checkboxes={this.state.checkboxes}
                                                getSurveyData={() => (this.getSurveyData())}
                                                onDeleteSurveyQuestion={this.onDeleteSurveyQuestion}
                                                onDeleteSurveyCategory ={this.onDeleteSurveyCategory} 
                                                updateSurveyData={(u) => {this.updateSurveyData(u)}}
                                                selectedCategory={this.state.selectedCategory}
                                                reloadSurvey={this.reloadSurvey} 
                                                /> },
        {name: 'Survey details', component: <SurveyDetailsStep 
                                                  getSurveyData={() => (this.getSurveyData())}
                                                  updateSurveyData={(u) => {this.updateSurveyData(u)}} 
                                               /> },
        {name: 'Select groups', component: <DepartmentsSelectionStep
                                                  departments={this.state.departments}
                                                  getSurveyData={() => (this.getSurveyData())}
                                                  updateSurveyData={(u) => {this.updateSurveyData(u)}} 
                                               /> },
        {name: 'Summary', component: <SurveyPreviewStep
                                                  getSurveyData={() => (this.getSurveyData())}
                                                  updateSurveyData={(u) => {this.updateSurveyData(u)}} 
                                               /> }                                                                                 
      ]      
        return (
          <div className="wrapper wrapper-content">
            <div className="row">
              <div className="col-md-12">
                <Link to="/survey_builder" className="btn btn-white btn-sm pull-right hidden-xs">Back to Surveys</Link>
                { this.state.isInitialStep &&
                  <span>
                    <button className="btn btn-primary btn-sm pull-right" onClick={() => this.openNewQuestionModal()} style={{marginRight: '5px'}}>
                      <FontAwesomeIcon icon={faPlus}/> New Question
                    </button>
                    <button className="btn btn-primary btn-sm pull-right" onClick={() => this.openNewCategoryModal()} style={{marginRight: '5px'}}>
                      <FontAwesomeIcon icon={faPlus}/> New Category
                    </button>
                  </span>  
                }
              </div>
              <NewQuestionModal 
                ref="newSurveyQuestionModal" 
                categories={this.state.categories}
                reloadSurvey={this.reloadSurvey}
              />
              <NewCategoryModal
                ref="newSurveyCategoryModal" 
                reloadSurvey={this.reloadSurvey}
              />
            </div>
            <div className='row'>
              <StepZilla
                steps={steps}
                preventEnterSubmission={true}
                prevBtnOnLastStep={false}
              />
            </div>
          </div>        
        )
   }      
}

export default NewSurveyView
