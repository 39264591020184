import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import SliderQuestion from './SliderQuestion';
import ChoiceQuestion from './ChoiceQuestion';
import RatingQuestion from './RatingQuestion';
import FeedbackQuestion from './FeedbackQuestion';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faFolder } from '@fortawesome/free-solid-svg-icons/faFolder'

import { toastrNotification } from './../../helpers/Toastr';
import { client } from '../Client';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SurveyView.css";

class SurveyView extends Component {

  state = {
    showModal: false,
    lastQuestionSelected: false,
    firstQuestionSelected: true,
    currentAnswer: '',
    selectedQuestionIndex: 0
  };

  componentDidMount = () => {
    if (this.props.initSurvey) this.open();
  }

  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true, lastQuestionSelected: this.props.survey.questions.length == 1 });
    if (this.props.survey.survey_instance_id !== undefined) {
      client.updateSurveyInstanceStartDate(this.props.survey.survey_instance_id);
    }
  }

  updateCurrentAnswer = (answer) => {
    this.setState({ currentAnswer: answer })
  }

  saveAnswers = () => {
    let answers = [];
    if (this.state.currentAnswer == "" || this.state.currentAnswer === undefined) {
      toastrNotification({success: false, title: 'Type the answer please'});
      return false;
    };
    $.each(this.refs, function(k, question) {
      answers.push(question.buildAnswerHash());
    });
    client.createSurveyAnswers(this.props.survey.survey_instance_id, answers).then((result) => {
      if (result.success) {
        toastrNotification({success: true, title: 'Survey was successfully completed'});
        this.close();
      } else {
        toastrNotification({success: false, title: 'Unable to complete survey'})
      }
    });
  }

  renderQuestion = (question) => {
    switch(question.qtype) {
      case 'slider':
        return (
          <SliderQuestion question={question} ref={'question'+question.id} updateCurrentAnswer={this.updateCurrentAnswer}/>
        );
        break;
      case 'choice':
        return (
          <ChoiceQuestion question={question} ref={'question'+question.id} updateCurrentAnswer={this.updateCurrentAnswer}/>
        );
        break;
      case 'rating':
        return (
          <RatingQuestion question={question} ref={'question'+question.id} updateCurrentAnswer={this.updateCurrentAnswer}/>
        );
        break;
      case 'feedback':
        return (
          <FeedbackQuestion question={question} ref={'question'+question.id} updateCurrentAnswer={this.updateCurrentAnswer}/>
        );
        break;
      default:
        break;
    }
  }

  nextQuestion = () => {
    this.slider.slickNext();
  }

  previousQuestion = () => {
    this.slider.slickPrev()
  }

  handleNextButtonClick = () => {
    if ((this.state.currentAnswer == "" || this.state.currentAnswer === undefined) && this.props.employeeMode) {
      toastrNotification({success: false, title: 'Type the answer please'});
      return false;
    } else {
      this.slider.slickNext();
    }
  }

  renderSurveyLink = () => {
    const departments = this.props.survey.departments;
    const surveyDepartmentTitles =  departments && departments.length > 0
                                      ?  `(${departments.map(d => (d.title)).join(', ')})`
                                      :  ""
                                 
    if (this.props.employeeMode || this.props.previewMode) {
      return (
        <a onClick={this.open}>{this.props.survey.name} {surveyDepartmentTitles}</a>
      )
    } else {
      return (
        <a className="btn btn-white btn-sm" onClick={this.open}><FontAwesomeIcon icon={faFolder} /> Preview </a>
      )
    }
  }

 render() {
    let _this = this;

    const settings = {
      draggable: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      afterChange: function (currentIndex) {
        _this.setState({ selectedQuestionIndex: currentIndex });
        const currentRef = 'question' + _this.props.survey.questions[currentIndex].id;
        if (_this.refs[currentRef] !== undefined) {
          _this.setState({ currentAnswer: _this.refs[currentRef].getAnswer() })
        }
        if (currentIndex == _this.props.survey.questions.length - 1) {
          _this.setState({ firstQuestionSelected: false, lastQuestionSelected: true })
        } else if (currentIndex == 0) {
          _this.setState({ firstQuestionSelected: true, lastQuestionSelected: false })
        } else {
          _this.setState({ firstQuestionSelected: false, lastQuestionSelected: false })
        }
      }
    };

    return (
      <div style={{display: 'inline'}}>
        {this.renderSurveyLink()}
        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Body>
              <Slider ref={c => this.slider = c } {...settings}>
                {
                  this.props.survey.questions.map((question) => (
                    <div key={'q'+question.id}>{this.renderQuestion(question)}</div>
                  ))
                }
              </Slider>
              <div className="text-center">
                <button className='btn btn-primary btn-small' onClick={this.previousQuestion} disabled={this.state.firstQuestionSelected}>
                  <FontAwesomeIcon icon={faArrowLeft} /> 
                </button>
                <button className='btn btn-primary btn-small' onClick={this.handleNextButtonClick} disabled={this.state.lastQuestionSelected} style={{marginLeft: '5px'}}>
                  <FontAwesomeIcon icon={faArrowRight} /> 
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="text-center">
                  <button className='btn btn-w-m btn-primary' onClick={this.saveAnswers} style={(this.state.lastQuestionSelected && this.props.employeeMode) ? {} : {display: 'none'}}>Save</button>
                </div>
            </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default SurveyView;
