import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <div className="footer text-center">
                Copyright Ezzely/Coggno Inc &copy; {(new Date()).getFullYear()}
            </div>
        )
    }
}

export default Footer
