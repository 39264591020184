import React, { Component } from 'react';
import mediumEditor from 'medium-editor/dist/js/medium-editor';
import { withFormsy } from 'formsy-react';
class AcknowledgementTextBox extends Component {

  state = {
    editor: Object
  };

  componentDidMount = () => {
    let editor = new mediumEditor(".acknowledgement-text-editor", {
        placeholder: {
          text: 'Write your acknowledgement here *',
          hideOnClick: true
        },
        delay: 1000,
        targetBlank: true,
        toolbar: false,
        autoLink: true
    });
    editor.setContent(this.props.value || '', 0)    
    this.setState({ editor: editor });
    let _this = this;
    editor.subscribe('editableInput', function (event, editable) {
      _this.props.setValue(editable.innerHTML);
    });
  }
    
 render() {
    const errorMessage = this.props.errorMessage;
    
    return (
      <div>
        <div className="acknowledgement-text-editor form-control" contentEditable>
        </div>
        <span className='validation-error'>{errorMessage}</span>   
      </div>                
    );
  }
}

export default withFormsy(AcknowledgementTextBox);