import React, { Component } from 'react';
import FormField from '../../components/common/FormField';

class AboutMeFields extends Component {

  state = {
    editMode:false,
    fields: {}
  }

  openEditForm = () => {
    if (this.props.onChangeMode) this.props.onChangeMode(true);
    this.setState({editMode: true,
      fields: {
        about_me: this.props.employee.about_me || '',
        linkedin_profile: this.props.employee.linkedin_profile || ''
      }
    });
  }

  closeEditForm = () => {
    if (this.props.onChangeMode) this.props.onChangeMode(false);
    this.setState({editMode: false});
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }

  onStateChange = (field, state) => {
    const fields = this.state.fields;
    fields[field] = state;
    this.setState({ fields: fields });
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    this.props.onFormSubmit(this.state.fields);
    this.closeEditForm();
  }

  render() {
      return (
        <div className="form-horizontal">
          <ul className="list-unstyled">
            <br/>
            <FormField label="About Me">
              { this.state.editMode ?
              <textarea value={this.state.fields.about_me} onChange={this.onInputChange} className='form-control' name='about_me'/>
              :
              <div className="details-item-text">{this.props.employee.about_me || '-'}</div>
              }
            </FormField>
            <FormField label="Linkedin profile link">
              { this.state.editMode ?
              <input type="text" value={this.state.fields.linkedin_profile} onChange={this.onInputChange} className='form-control' name='linkedin_profile'/>
              :
              <div className="details-item-text">{this.props.employee.linkedin_profile || '-'}</div>
              }
            </FormField>
          </ul>
          { this.state.editMode &&
          <div className="modal-footer">
            <button onClick={this.handleSubmit} className="btn btn-sm btn-primary no-margins">Save</button>
            <button onClick={this.closeEditForm} className="btn btn-sm btn-default">Cancel</button>
          </div>}
        </div>
      )

  }
}

export default AboutMeFields;
