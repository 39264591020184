import React, { Component } from 'react';
import RawHtml from "react-raw-html";
import { toastrNotification } from '../../helpers/Toastr';
import { acknowledgementClient } from '../../clients/AcknowledgementClient';
import moment from 'moment';

class ActivityAcknowledgementWidget extends Component {
  
  state = {
    loading: false,
    approvement_allowed: false
  }
  
  componentDidMount = () => {
    this.setState({approvement_allowed: this.props.acknowledgement.approvement_allowed});
  }
  
  handleAcknowledgementAttachmentDownload = (acknowledgement) => {
    acknowledgementClient.downloadAcknowledgementAttachment(acknowledgement).then((a) => {
      this.setState({approvement_allowed: true})
    });
  }

  acknowledge() {
    this.setState({ loading: true })
    if (this.state.loading) return 
    acknowledgementClient.acknowledgeInstance(this.props.acknowledgement.instance_id).then((response) => {
      if (response.success) {
        this.props.refreshEmployeeAcknowledgements();
        toastrNotification({ success: true, title: 'Thank you.' }) 
      } else {
        toastrNotification({ success: false, title: 'Unable to acknowledge.' })        
        setTimeout(() => {
          this.setState({ loading: false })
        }, 1500)
      }
    });   
  }
  
  render() {
      const { acknowledgement } = this.props;
    
      return (
            <div className="ibox float-e-margins">
                <div className="ibox-content acknowledgement-content">
                  <div className="row">
                    <div className="col-sm-12">
                      <h4>{ acknowledgement.title } ({ acknowledgement.department } group)</h4>
                      <RawHtml.div>{ acknowledgement.message }</RawHtml.div> 
                      { acknowledgement.original_filename &&
                        <div>
                          <p>
                            <a onClick={() => this.handleAcknowledgementAttachmentDownload(acknowledgement)} title={acknowledgement.original_filename}>
                            {acknowledgement.original_filename}
                            </a>
                          </p>
                        </div>
                      }                      
                    </div>
                  </div>
                  { !this.state.approvement_allowed &&
                    <small><i>Download attachment to unlock the button</i></small>                    
                  }
                  <div className="user-button">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                           <button type="button" disabled={this.state.loading || !this.state.approvement_allowed} className="btn btn-primary btn-sm btn-block" onClick={() => this.acknowledge()}>Acknowledge</button>  
                        </div>
                    </div>
                  </div>
                </div>
            </div>
      )
    }
}

export default ActivityAcknowledgementWidget;
