import React, { Component } from 'react';

import { Typeahead } from 'react-bootstrap-typeahead';
import {withFormsy} from 'formsy-react';
import 'react-bootstrap-typeahead/css/Loader.css';
import 'react-bootstrap-typeahead/css/Token.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Config from 'config';

class TypeaheadSelect extends Component {
  
  state = {
    defaultSelected: []
  }
  
  componentDidMount() {
    this.getDefaultSelected();
  }

  handleChange = (selectedItems) => {
    if (selectedItems[0] !== undefined) {
      //use 'title' attribute as value
      this.props.setValue(selectedItems[0].title)
    } else {
      this.props.setValue('')
    }
  }
  
  handleInputChange = (newItemText) => {
    this.props.setValue(newItemText)
  }
  
  getDefaultSelected = () => {
    let defaultSelected = [];
    if (this.props.defaultSelectedId !== undefined) {
      let options = this.props.options;
      for (let i in options) {
        if (options[i].id === this.props.defaultSelectedId) {
          defaultSelected.push(options[i])
        }
      }
    };
    this.setState({ defaultSelected: defaultSelected });
    if (defaultSelected[0] !== undefined) {
      this.props.setValue(defaultSelected[0].title)
    } else {
      this.props.setValue('')
    }
  }
    
  render() {
    const errorMessage = this.props.errorMessage;
    
    return (
      <div>
        <Typeahead
          ref="typeahead"
          name={this.props.name}
          selected={this.state.defaultSelected}
          allowNew={this.props.allowNew}
          options={this.props.options}
          labelKey={this.props.labelKey}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          newSelectionPrefix={this.props.newSelectionPrefix}
          placeholder={this.props.placeholder}
          tabIndex={this.props.tabIndex}
        />
        <span className='validation-error'>{errorMessage}</span>
      </div>
    )
  }
}

export default withFormsy(TypeaheadSelect)
