import React from "react";

import "./Experience.css";
import LaddaButton from "react-ladda";
import { withRouter } from "react-router-dom";

class Experience extends React.Component {
  render() {
    return (
      <div className="experience-main">
        <div className="close-button text-navy">{this.props.children}</div>
        <div className="icon-button text-navy">
          <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 186.184V395.638C0.0111029 426.496 12.2743 456.087 34.0942 477.907C55.9142 499.727 85.5052 511.99 116.363 512.001H395.635C426.493 511.99 456.084 499.727 477.904 477.907C499.724 456.087 511.987 426.496 511.998 395.638V186.184H0ZM337.22 346.067L197.585 424.728C194.129 426.754 190.187 427.8 186.181 427.754C180.036 427.667 174.167 425.187 169.821 420.841C165.476 416.495 162.996 410.626 162.908 404.481V247.158C162.942 243.11 164.027 239.139 166.058 235.636C168.088 232.133 170.994 229.218 174.49 227.177C177.987 225.135 181.954 224.037 186.002 223.991C190.051 223.944 194.042 224.95 197.585 226.911L337.22 305.573C340.82 307.588 343.817 310.526 345.903 314.085C347.989 317.644 349.089 321.695 349.089 325.82C349.089 329.945 347.989 333.995 345.903 337.554C343.817 341.113 340.82 344.052 337.22 346.067V346.067Z"
              fill="currentColor"
            />
            <path
              d="M142.196 139.638H8.41223e-05V116.366C-0.0232252 96.9959 4.79789 77.928 14.0247 60.8971C23.2514 43.8662 36.5909 29.4131 52.829 18.8533L80.5234 56.555L142.196 139.638Z"
              fill="currentColor"
            />
            <path
              d="M351.881 139.638H200.143L131.256 46.5479L97.7437 1.63174C103.884 0.502405 110.118 -0.0430562 116.362 0.00265085H249.481L283.459 46.5479L351.881 139.638Z"
              fill="currentColor"
            />
            <path
              d="M512 116.368V139.641H409.601L340.946 46.5506L306.735 0.00531006H395.637C426.495 0.016413 456.086 12.2796 477.906 34.0996C499.726 55.9195 511.989 85.5105 512 116.368V116.368Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <h1 className="text-navy">Tell your friends about this!</h1>
        <p className="text-navy">
          You’ve been rewarded!
          Now it’s your turn to create an engaging <strong>Ezzely Xtra!</strong>
        </p>

        <LaddaButton
          className={"rbc-event"}
          style={{
            width: "95%",
            height: "42px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px",
          }}
          onClick={() => this.props.history.push('/activity#new_reel')}
        >
          <h3 style={{ paddingTop: "6px" }}>Share the experience</h3>
        </LaddaButton>
      </div>
    );
  }
}
export default withRouter(Experience);
