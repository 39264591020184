import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import FileUploadProgress  from 'react-fileupload-progress';
import {Checkbox} from 'react-ui-icheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import ImportResults from './ImportResults'
import styles from '../../../public/styles/upload-progress.css'
import { client } from '../Client';
import { toastrNotification } from '../../helpers/Toastr';

import "./ImportEmployees.css"

let message = '';

class ImportEmployees extends Component {

  state = {
    showModal: false,
    canSubmit: false,
    show: false,
    json: null,
    send_notification: true,
    need_to_change_password: false, 
    warning: ''
  };

  onFileSelect = (evt) => {
    if (evt.target.value.split('.').pop() == 'csv') {
      this.enableButton();
    }
    else {
      this.disableButton(<span className="text-danger">Please choose a *.csv file</span>);
    }
  }

  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true, json: null });
    this.disableButton();
  }

  enableButton = (warn = '') => {
    this.setState({ canSubmit: true, warning: warn });
  }

  disableButton = (warn = '') => {
    this.setState({ canSubmit: false, warning: warn });
  }

  toggleSendNotifCheckbox = () => {
    this.setState({send_notification: !this.state.send_notification});
  }
  
  toggleChangePasswordCheckbox = () => {
    this.setState({need_to_change_password: !this.state.need_to_change_password});
  }

  formGetter = () => {
    return new FormData(document.getElementById('upload-employees-form'));
  }

  renderResults = (json) => {
    $('.cancel-upload').hide();
    this.setState({json: json});
  }
  
  handleImportError = () => {
    toastrNotification({success: false, title: 'Unable to import employees.'});
    this.disableButton();
  }

  customFormRenderer = (onSubmit) => {
     return (
       <form style={{marginBottom: '15px'}} id="upload-employees-form">
         <div className="row">
         <br/>
          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-8">
             <p>
               <input type="file" name='file' className="form-control" style={{height: 'auto'}}  onChange={this.onFileSelect}/>
             </p>
             <div> &nbsp;
               <label>
                 <Checkbox name="send_notification" 
                 checkboxClass="icheckbox_square-green" 
                 checked={this.state.send_notification}
                 increaseArea="20%"
                 onChange={this.toggleSendNotifCheckbox}
                 />
                 &nbsp; Send notifications
               </label>
             </div>
             <div> &nbsp;
               <label>
                 <Checkbox name="need_to_change_password" 
                 checkboxClass="icheckbox_square-green" 
                 checked={this.state.need_to_change_password}
                 increaseArea="20%"
                 onChange={this.toggleChangePasswordCheckbox}
                 />
                 &nbsp; Ask employees to change password
               </label>
             </div>
           </div>
           <div className="col-lg-2 col-md-2 col-sm-2 col-xs-4">
             <button type="button" className="btn btn-primary" disabled={!this.state.canSubmit} onClick={onSubmit}><FontAwesomeIcon icon={faUpload} /> Import</button>
           </div>
         </div>
       </form>
     );
   }

   customProgressRenderer = (progress, hasError, cancelHandler) => {
      if (hasError || progress > -1 ) {
        let barStyle = {};
        barStyle.width = progress + '%';

        message = (<span>{barStyle.width}</span>);
        if (hasError) {
          message = (<span style={{'color': '#a94442'}}>Failed to upload ...</span>);
        }
        if (progress === 100){
          message = '';
        }

        return (
          <div>
            <div className="file-upload-progress-wrapper">
              <div style={barStyle} className="progress-bar progress-bar-success file-upload-progress-bar"></div>
            </div>
            <button className="cancel-upload file-upload-cancel-button" onClick={cancelHandler}>
              <span>&times;</span>
            </button>
            <div style={{'clear':'left'}}>{message}</div>
          </div>
        );
      } else {
        return;
      }
    }

 render() {

    return (
      <div>
        <button className="btn btn-white btn-outline" onClick={this.open}><FontAwesomeIcon icon={faUpload} /> Import</button>
        <Modal show={this.state.showModal} onHide={this.close} onShow={this.onModalShow}>
            <Modal.Body>
              <h3 className="m-b">Import Employees</h3>
              <p>Import employees allows to add mulitple employees into the system at once.</p>
              <p>In order to do that you will need to download a sample spreadsheet and fill in the employee information, one employee per row.
              Over a dozen fields are available in this format, such as first and last name, position, department, hire date etc <br />
              <b>The required fields are marked with (*) in the headings. Can use email or mobile phone number.</b>
              </p>
              <p><b className="label">STEP 1</b> Download sample <a download="employees-sample.csv" href='employees-sample.csv'>employees.csv</a> file</p>
              <p><b className="label">STEP 2</b> Open the downloaded file in Excel or another editor of your choice</p>
              <p><b className="label">STEP 3</b> Enter the required information and save the spreadsheet.</p>
              <p><b className="label">STEP 4</b> Choose the saved file using the button below and click IMPORT</p>
              <FileUploadProgress url={client.importEmployeesUrl(this.state.send_notification, this.state.need_to_change_password)}
                method='post'
                formRenderer={this.customFormRenderer.bind(this)}
                progressRenderer={this.customProgressRenderer.bind(this)}
                formGetter={this.formGetter.bind(this)}
                beforeSend={(request) => {
                  this.disableButton();
                  request.setRequestHeader('authorization', client.getAuthorizationHeader()); 
                  request.setRequestHeader('X-Client-Version', client.getClientVersion());  
                  return request;
                }}
                onError={(e, request) => (this.handleImportError())}
                onLoad={(e, request) => {
                  let json = JSON.parse(request.response);
                  this.renderResults(json);
                  this.props.onFinishImport();
                }}
              />
              <ImportResults json={this.state.json}/>
              <p><b className="label">STEP 5</b> Review the results in the system</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ImportEmployees;
