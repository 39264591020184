import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';
import Event from '../models/Event';

class EventClient {
  
  getEvents(date) {
    return client.callApi(Config.API_BASE + '/events.json?date=' + date)
      .then((event_data) => {
        return {
          timezone: event_data.timezone,
          events: event_data.events.map((event) => (new Event(event)))          
        }
      })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  getEvent(event_id) {
    return client.callApi(Config.API_BASE + '/events/' + event_id + '.json')
      .then((event) => (new Event(event)))
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });            
  }
    
  createEvent(eventData) {
    return client.callApi(Config.API_BASE + '/events.json', {}, 'POST', {event: eventData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
    
  deleteEvent(eventId) {
    return client.callApi(Config.API_BASE + '/events/'+ eventId +'.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }    

}

export const eventClient = new EventClient();
