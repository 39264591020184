import moment from 'moment';
class ShiftRequest {
  constructor(obj) {
    for (var prop in obj) {
      if (prop === 'start_date' || prop === 'end_date' || prop === 'created_at') {
        this[prop] = moment.parseZone(obj[prop]);        
      } else {
        this[prop] = obj[prop]; 
      }
    }
  }
  
  canBeDeleted() {
    return !this.performer_id
  }

  
  approved() {
    return this.performer_id;
  }
  
  canBeApproved() {
    return (this.applicants.length > 0 && !this.performer_id && this.start_date.diff(moment(), 'days') >= 0)
  }
}

export default ShiftRequest;
