import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class ShowRespondedEmployeesModal extends Component {

  state = {
    showModal: false,
    employees: [],
    title: ''
  }
  
  close = () => {
    this.setState({ showModal: false });
  }

  open = (employees, title) => {
    this.setState({ showModal: true, employees: employees, title: title });
  }

 render() {
    const tooltip = (
      <Tooltip id="modal-tooltip">
      </Tooltip>
    )
    const employees = this.state.employees
    const title = this.state.title

    return (
      <Modal show={this.state.showModal} onHide={this.close} bsSize="small">
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 acknowledgement-responded-employees-list">
                <ul>
                  {employees.map((employee) => 
                    <li key={'re'+employee.id}>
                      <img className="img-circle" alt="image" src={employee.avatar_url ?  employee.avatar_url : 'img/avatar_missing.png'} />
                      <span>{employee.full_name}</span>
                    </li>    
                  )}
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
             <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
      </Modal>
    );
  }
}

export default ShowRespondedEmployeesModal;
