import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import { eventClient } from '../../clients/EventClient';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { client } from '../Client';
import { toastrNotification } from '../../helpers/Toastr';

class EventModal extends Component {

  state = {
    showModal: false,
    loading: false,
    event: {}
  }
  
  close = () => {
    this.setState({ showModal: false, loading: false, event: {} });
  }

  open = (event) => {
    this.setState({ showModal: true, event: event });
  }

  deleteEvent = () => {
    this.setState({ loading: true })
    eventClient.deleteEvent(this.state.event.id)
    .then((response) => {
        if (response.success) {
          this.props.onDeleteEvent();
          toastrNotification({success: true, title: 'Event was successfully removed'});
          this.close();
        } else {
          toastrNotification({success: false, title: 'Unable to remove event'});
          setTimeout(() => {
            this.setState({ loading: false })
          }, 1500)
        }
    })
  }
    
 render() {
    
    event = this.state.event;

    return (
      <Modal show={this.state.showModal} onHide={this.close} >
        <Modal.Header closeButton>
          <Modal.Title>{event.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <dl className="dl-horizontal">
                <dt>Time:</dt>
                <dd>{moment(event.start).format('D MMM YYYY, h:mm a')} - {moment(event.end).format('D MMM YYYY, h:mm a')}</dd>
                <dt>Groups:</dt>
                <dd>{event.departments}</dd>
                {event.location && 
                  <span>
                    <dt>Location:</dt>
                    <dd>{event.location}</dd>
                  </span>  
                }
                {event.description && 
                  <span>
                    <dt>Description:</dt>
                    <dd>{event.description}</dd>
                  </span>  
                }
              </dl>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          { client.currentUser().id == event.author_id &&
            <LaddaButton data-style={EXPAND_LEFT} type="button" onClick={() => this.deleteEvent()} loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Delete Event</LaddaButton>
          }
          <Button onClick={this.close}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EventModal;
