import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';
import Message from '../models/ConversationMessage';

class ConversationMessageClient {
  getConversationMessages(page, conversationId) {
    return client.callApi(this.conversationMessagesUrl(page, conversationId))
      .then((messages) => messages.map((message) => (new Message(message))))
      .catch((error) => {
        this.handleApiError(error);
        return [];
    });
  }

  createMessage(conversationId, messageData) {
    return client.callApi(this.createMessageUrl(conversationId), {}, 'POST', {conversation_message: messageData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  createMessageUrl(conversationId) {
    return Config.API_BASE + '/conversations/' + conversationId + '/conversation_messages'
  }

  conversationMessagesUrl(page, conversationId) {
    return Config.API_BASE + '/conversations/' + conversationId + '/conversation_messages.json?page=' + page;
  }

}

export const conversationMessageClient = new ConversationMessageClient();
