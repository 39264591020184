import React, { Component } from 'react';
import {Checkbox} from 'react-ui-icheck';
import SweetAlert from 'sweetalert-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import SurveyQuestionModal from './SurveyQuestionModal';
import EditQuestionModal from './EditQuestionModal';

import "./SurveyQuestion.css";

class SurveyQuestion extends Component {
  
 state = {
   showDeleteQuestionDialog: false
 }  
    
onQuestionChange = (question, evt) => {
  this.props.handleQuestionChange(question, evt)
}        
 
handleRemoveQuestionLinkClick = () => {
  this.setState({ showDeleteQuestionDialog: true });
}

deleteSurveyQuestion = () => {
  this.props.deleteSurveyQuestion(this.props.question);
  this.setState({ showDeleteQuestionDialog: false });  
}

openEditQuestionModal = () => {
  this.refs.editQuestionModal.open();
}    
  
 render() {
    return (
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
          <div className="file survey-question">
              <div className="icon">
                  <div className="pull-left">
                    <Checkbox 
                        checkboxClass="icheckbox_square-green"
                        checked={!!this.props.checkboxes['q' + this.props.question.id]}                        
                        onChange={(evt) => this.onQuestionChange(this.props.question, evt)}
                        label="&nbsp;"
                    />
                  </div>
                  { this.props.question.can_be_deleted && 
                    <div className="pull-right">
                      <div className="dropdown">
                        <button data-toggle="dropdown" className="dropdown-toggle btn-white">
                           <FontAwesomeIcon icon={faAngleDown}/>
                        </button>
                        <ul className="dropdown-menu m-t-xs activity-dropdown-menu" id={'edit' + this.props.question.id}>
                          <li>
                            <a onClick={() => this.openEditQuestionModal()}>Edit</a>
                            <EditQuestionModal 
                              question={this.props.question} 
                              categories={this.props.categories}
                              reloadSurvey={this.props.reloadSurvey}
                              ref="editQuestionModal" 
                            />
                          </li>
                          <li>
                            <a onClick={() => this.handleRemoveQuestionLinkClick()}>Delete</a>
                            <SweetAlert
                              show={this.state.showDeleteQuestionDialog}
                              type="warning"
                              title="Are you sure to remove this question?"
                              confirmButtonColor="#DD6B55"
                              showCancelButton
                              text="This operation can not be undone."
                              onConfirm={() => this.deleteSurveyQuestion()}
                              onCancel={() => this.setState({ showDeleteQuestionDialog: false })}
                            />
                          </li>
                        </ul>
                      </div>    
                    </div>
                  }  
                  <FontAwesomeIcon icon={faFileAlt} size='3x'/>
              </div>
              <div className="file-name">
                  <SurveyQuestionModal question={this.props.question} showTruncatedQuestion={true} />
              </div>
          </div>
      </div> 
    );
  }
}

export default SurveyQuestion;
