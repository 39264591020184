import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import DateField from '../form_components/DateField';
import TimeField from '../form_components/TimeField';
import Select from '../form_components/Select';
import Textarea from '../form_components/Textarea';
import { shiftRequestClient } from '../../clients/ShiftRequestClient';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

import 'react-datepicker/dist/react-datepicker.css';

class EmployeeShiftRequestForm extends Component {

  state = {
    showModal: false,
    canSubmit: false,
    errorMessage: '',
    loading: false,
    fields: {
      start_date: '',
      end_date: '',
      start_time: null,
      end_time: null,
      comment: '',
      university_department_id: ''
    }
  };
  
  close = () => {
    this.setState({ showModal: false, baseErrors: [], loading: false });
  }

  open = () => {
    this.setState({ showModal: true, errorMessage: "" });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  resetForm = () => {
     formsy.reset();
  }

  onDateChange = (field, date) => {
    const fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields: fields });
  } 
  
  onTimeChange = (field, time) => {
    const fields = this.state.fields;
    fields[field] = time;
    this.setState({ fields: fields });
  } 
  
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }
  
  formSubmit = (data) => {
    const start_date = moment(data.start_date)
    const end_date = moment(data.end_date)
    
    if (start_date.isAfter(end_date)) {
      this.setState({errorMessage: "End date should be later than start date"})
      return;
    };
    if(end_date.diff(start_date, 'years', true) > 1) {
      this.setState({errorMessage: "Please don't choose an interval longer than 1 year"})
      return;      
    };    
    if (start_date.isSame(end_date) && data.start_time.isAfter(data.end_time)) {
      this.setState({errorMessage: "End time should be later than start time"})
      return;
    };
    data.start_time = data.start_time.format("h:mm a");
    data.end_time = data.end_time.format("h:mm a");
    data.start_date = start_date.format('LL');
    data.end_date = end_date.format('LL');
    this.setState({ loading: true });
    shiftRequestClient.createShiftRequest(data)
    .then((shift_request) => {
        if (shift_request.id) {
          this.props.onAddNewRequest();
          this.close();
        } else {
          let formErrors = shift_request;
          this.refs.form.updateInputsWithError(formErrors)
          setTimeout(() => {
            this.setState({ loading: false })
          }, 1500)
        }
    })
  }

 render() {

    return (
      <Modal show={this.state.showModal} onHide={this.close}>
        <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal employee-form">
          <Modal.Header closeButton>
            <Modal.Title>Pick Up Shift Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.errorMessage.length > 0 && 
              <div className="alert alert-danger">
                {this.state.errorMessage}
              </div>
            } 
            <div className="row">
              <div className="form-group col-sm-12">
                <Select 
                  name="university_department_id"
                  options={this.props.employeeDepartments}
                  placeholder="Select Group *"
                  className="form-control"
                  value={this.state.fields.university_department_id}
                  onChange={this.onInputChange}
                  required
                />
              </div>
              <div className="form-group col-sm-12">
                <label htmlFor="start_date" className="sr-only"></label>
                <DateField name="start_date" className="form-control" placeholder="Start Date *" minDate={moment().startOf('month')} maxDate={moment().add(1, 'year')} value={this.state.fields.start_date} onChange={(date) => this.onDateChange('start_date', date)} required/>
              </div>          
              <div className="form-group col-sm-12">
                <label htmlFor="end_date" className="sr-only"></label>
                <DateField name="end_date" className="form-control" placeholder="End Date *" minDate={moment().startOf('month')} maxDate={moment().add(1, 'year')} value={this.state.fields.end_date} onChange={(date) => this.onDateChange('end_date', date)} required/>
              </div>
              <div className="form-group col-sm-12">
                <label htmlFor="start_time" className="sr-only"></label>
                <TimeField name="start_time" className="form-control" minuteStep={15} placeholder="Start Time *" value={this.state.fields.start_time} onChange={(time) => this.onTimeChange('start_time', time)} required/>
              </div>
              <div className="form-group col-sm-12">
                <label htmlFor="end_time" className="sr-only"></label>
                <TimeField name="end_time" className="form-control" minuteStep={15} placeholder="End time *" value={this.state.fields.end_time} onChange={(time) => this.onTimeChange('end_time', time)} required/>
              </div>
              <div className="form-group col-sm-12">
                <label htmlFor="comment" className="sr-only"></label>
                <Textarea
                  name="comment"
                  placeholder="Comment"
                  className="form-control"
                  value={this.state.fields.comment}
                  onChange={this.onInputChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Submit</LaddaButton>
            <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
        </Formsy>
      </Modal>
    );
  }
}

export default EmployeeShiftRequestForm;
