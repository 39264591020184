import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faCalendarAlt} from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import Survey from '../models/Survey';
import { client } from '../components/Client';
import SurveyQuestionModal from '../components/survey/SurveyQuestionModal';
import moment from 'moment';
import { toastrNotification } from '../helpers/Toastr';
import ENPSWidget from '../components/survey_reports/ENPSWidget';
import KeyMetricsReportWidget from '../components/survey_reports/KeyMetricsReportWidget';
import ParticipationReportWidget from '../components/survey_reports/ParticipationReportWidget';
import Coin from '../components/common/Coin';
import SweetAlert from 'sweetalert-react';

class SurveyView extends Component {

    state = {
      survey: new Survey({questions: [], departments: []}),
      spinnerClass: 'sk-loading',
      showDeleteNotification: false
    }

    componentDidMount() {
      this.setState(this.state);
      this.getSurvey();
    }

    getSurvey = () => {
      client.getSurvey(this.props.match.params.id).then((survey) => {
        this.setState({ survey: survey, spinnerClass: '' })
      });
    }
    
    deleteSurvey() {
      this.setState({ showDeleteNotification: false });
      client.removeSurvey(this.props.match.params.id).then((response) => {
        if (response.success == true) {
          toastrNotification({ success: true, message: 'Survey was successfully deleted.' })
          this.setState({ shouldRedirect: true });
        } else {
          toastrNotification({ success: false, message: 'Unable to delete survey.' });
        }
      });
    }
    
    handleDeleteSurveyClick() {
      this.setState({ showDeleteNotification: true });
    }

    render() {
      if (this.state.shouldRedirect) {
        return (
          <Redirect to="/survey_builder" />
        );
      } else {
        return (
          <div className={'wrapper wrapper-content ' + this.state.spinnerClass}>
              <Link to="/survey_builder" className="btn btn-white btn-sm pull-right m-b-sm hidden-xs">Back to Surveys</Link>
              <div className="sk-spinner sk-spinner-double-bounce">
                  <div className="sk-double-bounce1"></div>
                  <div className="sk-double-bounce2"></div>
              </div>

              <div className="ibox">
                <div className="ibox-content">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="m-b-md">
                        <h2>{this.state.survey.name}</h2>
                        <FontAwesomeIcon icon={faCalendarAlt}/> {moment(this.state.survey.start_time).format('MMMM Do')} - {moment(this.state.survey.end_time).format('MMMM Do YYYY')}
                        {this.state.survey.points > 0 && 
                          <h3>
                            <Coin width="16px" /> {this.state.survey.points} <small>EZ-points</small>
                          </h3>
                        }  
                      </div>
                    </div>
                    { this.state.survey.id && !this.state.survey.isActive() &&
                    <div className="col-md-3">
                      <ENPSWidget survey={this.state.survey}/>
                    </div>}
                  </div>
                  <hr />


                  { this.state.survey.id && !this.state.survey.isActive() &&
                    <div className="row">
                      <div className="col-lg-12">
                        <KeyMetricsReportWidget survey={this.state.survey}/>
                      </div>
                    </div>
                  }


                  <div className="row">
                    <div className="col-lg-6">
                      <h3>Groups</h3>
                        <ul>
                        {
                          this.state.survey.departments.map((dep) => (
                            <li key={'d'+dep.id}>{dep.title}</li>
                          ))
                        }
                        </ul>
                      { this.state.survey.id && !this.state.survey.isActive() &&
                        <div className="m-t-xl">
                          <h3>Participation Report</h3>
                          <ParticipationReportWidget survey={this.state.survey}/>
                        </div>
                      }

                    </div>
                    <div className="col-lg-6">
                      <h3>Questions</h3>
                      <ul style={{listStyleType: 'none', WebkitPaddingStart: '0px'}}>
                      {
                         this.state.survey.questions.map((question) => (
                          <li  key={'sqp'+question.id}><SurveyQuestionModal question={question} /></li>
                        ))
                      }
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button className="btn btn-white" onClick={() => this.handleDeleteSurveyClick()}>
                        <FontAwesomeIcon icon={faTimes}/> Delete
                      </button>
                      <SweetAlert
                        show={this.state.showDeleteNotification}
                        type="warning"
                        title="Are you sure?"
                        confirmButtonColor="#DD6B55"
                        showCancelButton
                        text="This operation can not be undone."
                        onConfirm={() => this.deleteSurvey()}
                        onCancel={() => this.setState({ showDeleteNotification: false })}
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>

        )
      }
    }

}

export default SurveyView
