// @flow
import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit} from '@fortawesome/free-solid-svg-icons/faUserEdit';
import { faCalendarAlt} from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import { rouletteClient } from '../../clients/RouletteClient';
import { mediumDateFormat } from './../../helpers/Formatter';
import { Modal, Button } from 'react-bootstrap';


class HistoryRouletteModal extends Component {

  state = { 
    showDialog: false,
    canSubmit: false,
    records: []
  }
  
  componentDidMount() {
    // console.log(this.props.roulette)
  }  

  open() {
    rouletteClient.getRouletteRecords(this.props.roulette.id).then((records) => {
      this.setState({ records: records, showDialog: true });
    })
  }

  close() {
    this.setState({ showDialog: false });
  }
  
  renderRecordTypeIcon = (record) => {
    if(record.manual) {
      return (
        <FontAwesomeIcon icon={faUserEdit}/>
      )
    } else {
      return (
        <FontAwesomeIcon icon={faCalendarAlt}/>
      )        
    }
  }    
  
  
  render() {
          
      return (
        <Modal show={this.state.showDialog } onHide={() => this.close()} bsSize="large">
          <Modal.Header closeButton>
            <Modal.Title>History</Modal.Title>
          </Modal.Header>
            <Modal.Body>
              <div className="full-height-scroll">
                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <td></td>
                        <td>Winner</td>
                        <td>Code</td>
                        <td>Prize</td>
                        <td>Won At</td>
                        <td>Created At</td>
                      </tr>
                    </thead>
                    <tbody>
                    {
                       this.state.records.map((record) => (
                         <tr key={'record-' + record.id}>
                           <td className="client-avatar">
                             <img className="img-circle" alt="image" src={record.winner.avatar_url} />
                           </td>
                           <td>
                            {record.winner.full_name}                            
                           </td>
                           <td>{this.renderRecordTypeIcon(record)} <small>{record.code}</small></td>
                           <td>
                             {record.prize &&
                               <span>{record.prize}</span>
                             }
                           </td>
                           <td>                           
                             { record.completed_at &&
                               mediumDateFormat(record.completed_at)
                             }                           
                           </td>
                           <td>{mediumDateFormat(record.created_at)}</td>
                         </tr>
                       ))
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.close()}>Close</Button>
            </Modal.Footer>
        </Modal>        
      )
  }
}

export default HistoryRouletteModal;