import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import Select from '../form_components/Select';
import { shiftRequestClient } from '../../clients/ShiftRequestClient';
import { toastrNotification } from './../../helpers/Toastr';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

class ApproveApplicantForm extends Component {

  state = {
    showModal: false,
    canSubmit: false,
    loading: false,
    fields: {
      performer_id: '',  
      shift_request_id: ''
    }
  };
  
  close = () => {
    this.setState({ showModal: false, baseErrors: [] });
  }

  open = () => {
    this.setState({ showModal: true });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  resetForm = () => {
     formsy.reset();
  }
  
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }
  
  formSubmit = (data) => {
    this.setState({ loading: true });
    shiftRequestClient.approveApplicant(data, this.props.shiftRequest.id)
    .then((response) => {
        if (response.success) {
          toastrNotification({success: true, message: "Applicant was successfully approved."})
          this.props.getShiftRequest()
          this.close()
        } else {
          toastrNotification({success: false, message: "Unable to approve applicant."})
          this.close()
        }
    })
  }

 render() {
    
    let applicantsList = [];
    
    this.props.shiftRequest.applicants.forEach(function(applicant) {
      let item = {};
      item.value = applicant.employee_id;
      item.title = applicant.employee_full_name;
      applicantsList.push(item);
    });     

    return (
      <Modal show={this.state.showModal} onHide={this.close} bsSize="small">
        <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal employee-form">
          <Modal.Header closeButton>
            <Modal.Title>Approve Applicant</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="employee_id" className="sr-only"></label>
                <Select
                  name="performer_id"
                  options={applicantsList}
                  placeholder="Select applicant"
                  className="form-control"
                  value={this.state.fields.employee_id || ''}
                  onChange={this.onInputChange}
                  className="form-control"
                  required
                />
              </div> 
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Submit</LaddaButton>
            <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
        </Formsy>
      </Modal>
    );
  }
}

export default ApproveApplicantForm;
