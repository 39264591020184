// @flow
import React, { Component } from 'react';
import ActionCable  from 'actioncable';
import Config from 'config';
import { Modal, Button, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import Post from '../models/ActivityPost';
import Employee from '../models/Employee';
import { Redirect } from 'react-router-dom';
import { client } from '../components/Client';
import ActivityPost from '../components/activity/ActivityPost';
import { toastrNotification } from '../helpers/Toastr';

import '../components/activity/ActivityPostModal.css';

class ActivityPostView extends Component {

  state = {
    showModal: true,
    shouldRedirect: false,
    spinnerClass: 'sk-loading',
    postError: false,
  }
  
  subscription: ActionCable;
  channelName = "ActivityPersonalChannel";

  componentDidMount() {
    let _this = this
    this.getActivityPost(this.props.match.params.id);
    let listener = {
      received(data) {
                
        if (!client.isLoggedIn()) {
          window.location.hash = '/login';
          return;
        };
                        
        switch(data.action) {
        case 'create_comment':
          _this.addNewComment(data);
          break;
        case 'confirm_comment':
          _this.confirmComment(data);
          break;
        case 'remove_post':
          _this.removePost(data);
          break;
        case 'remove_comment':
          _this.removePostComment(data);
          break;
        case 'post_like':
          _this.updatePostLikes(data);
          break;
        case 'comment_like':
          _this.updateCommentLikes(data);
          break;
        case 'create_post_reward':
          _this.updatePostRewards(data);
          break;
        case 'update_post_points':
          _this.updatePostPoints(data);
          break;
        }
        console.log("received", data);
      }
    };
    ActionCable.debugging = true;
    window.cable = ActionCable.createConsumer(client.getConsumerUrl());
    this.subscription = window.cable.subscriptions.create({channel: this.channelName}, listener);
  }
  
  componentWillUnmount() {
    if (this.subscription) {
      this.confirmPendingComments();
      setTimeout(() => {console.log('unsubscribed');this.subscription.unsubscribe()}, 100);
    }      
  }
  
  getActivityPost = (postId) => {
    client.getActivityPost(postId).then((data) => {
      if (data.success === false) {
        this.setState({ postError: true, spinnerClass: "" })
      } else {
        let post = new Post(data);
        this.setState({ post: post, spinnerClass: "", postError: false });
      }     
    });
  }
        
  updatePostPoints(data) {
   let mPost = new Post(this.state.post);
   if (mPost.id === data.post_id) { 
       mPost.points = data.points;
       this.setState({ post: mPost });
   }
 }
      
  confirmPendingComments() {
    if (this.state.post) {
      this.state.post.comments.map((c) => {
        if (c.author_id === client.currentUser().id && !c.confirmed) 
          this.subscription.perform('confirm_activity_post_comment', {comment_id: c.id});
      });
    }   
  }
      
  removePost(data) {
    const post = JSON.parse(data.activity_post);
    let modifiedPost = this.state.post;
    if (modifiedPost.id === post.id) {
      this.close();
    };
  }
  
  addNewComment(data) {
    if (data.success === true) {
      const comment = JSON.parse(data.comment);
      if (comment.author_id === client.currentUser().id || (comment.confirmed && (comment.author_id !== client.currentUser().id))) {          
        let mPost = new Post(this.state.post);
        if (mPost.id === comment.post_id) {
          mPost.comments = mPost.comments.concat([comment]);
          mPost.newCommentErrors = [];
          this.setState({ post: mPost });
        };
      } 
    } else {
      if (data.recipients[0] === client.currentUser().id) {
        toastrNotification({success: false, message: data.errors});
      }
    }
  }
  
  confirmComment(data) {
    const comment = JSON.parse(data.comment);
    console.log('confirm callback', comment);
    if (comment.author_id === client.currentUser().id) {   
      let mPost = new Post(this.state.post);
      if (mPost.id === comment.post_id) {
          mPost.comments = this.state.post.comments.map((c) => {
            if (c.id === comment.id) {
              return comment;
            } else {
              return c;
            }
          })
      }        
      this.setState({ post: mPost });
    } else {
      this.addNewComment(data);
    }
  }
    
  removePostComment(data) {
    const comment = JSON.parse(data.comment);
    let mPost = new Post(this.state.post);
    mPost.comments = this.state.post.comments.filter(c => c.id !== comment.id);
    this.setState({ post: mPost });
  }
  
  updatePostLikes(data)  {
    let mPost = new Post(this.state.post);
    if (mPost.id === data.postId) {
      mPost.likesNumber = data.likesNumber;
      mPost.liked = data.liked;
      mPost.likes = data.likes.map((like) => {
        like.employee = new Employee(like.employee); 
        return like
      });
      this.setState({ post: mPost });  
    }
  }
  
  updateCommentLikes(data) {
    let mPost = new Post(this.state.post);
    mPost.comments = this.state.post.comments.map((c) => {
      if (c.id === data.commentId) {
        return Object.assign({}, c, {likesNumber: data.likesNumber});
      } else {
        return c;
      }
    })
    this.setState({ post: mPost });    
  }
  
  updatePostRewards(data)  {
    if (data.success == true) {
      let mPost = new Post(this.state.post);
      if (mPost.id === data.post_id) {
        mPost.rewards_number = data.rewards_number;
        mPost.points = data.post_points;
        mPost.rewarded = data.rewarded;
        mPost.rewards = data.rewards;
        this.setState({ post: mPost });  
      }
    } else {
      if (data.author_id === client.currentUser().id) { 
        toastrNotification({success: false, message: data.errors})
      } 
    }    
  }
              
  close = () => {
    this.setState({ showModal: false, shouldRedirect: true });
  }
      
  render() {
    const popover = (
      <Popover id="modal-popover" title="popover">
      </Popover>
    )
    const tooltip = (
      <Tooltip id="modal-tooltip">
      </Tooltip>
    )
    
    if (this.state.shouldRedirect) {
      return (
        <Redirect to='/activity' />
      );
    } else {
      return (
        <div>
          <Modal show={this.state.showModal} 
            onHide={this.close} 
            dialogClassName="activity-post-modal" 
          >
              <Modal.Header className={'wrapper wrapper-content ' + this.state.spinnerClass} closeButton> 
                <div className="sk-spinner sk-spinner-double-bounce">
                  <div className="sk-double-bounce1"></div>
                  <div className="sk-double-bounce2"></div>
                </div>
              </Modal.Header>  
              <Modal.Body  id="activityPostModal">
                { (this.state.post && !this.state.postError) && 
                  <ActivityPost 
                    post={this.state.post} 
                    channel={this.subscription}
                    containerId="modalPost"
                  />
                }
                { this.state.postError && 
                  <div className="text-center" style={{fontSize: "16px", color: "#DE4646", marginBottom: "30px" }}>
                    You have not access rights or this post was removed.
                  </div>  
                }
              </Modal.Body>  
          </Modal>
        </div>  
      );
    }
  }
    
}

export default ActivityPostView
