import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { raffleClient } from '../../clients/RaffleClient';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import DateRangePickerWrapper from '../common/DateRangePickerWrapper';
import isSameDay from 'react-dates/lib/utils/isSameDay';
import isInclusivelyAfterDay from 'react-dates/lib/utils/isInclusivelyAfterDay';
import moment from 'moment';
import Input from '../form_components/Input';
import Textarea from '../form_components/Textarea';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { toastrNotification } from '../../helpers/Toastr';
import DepartmentsBox from './DepartmentsBox';

class CreateRaffleGiftCard extends Component {
  
  state = {  startDate: moment(),
             endDate: moment(),
             fields: {
               card_amount: 10,
               title: 'Gift Card Raffle',
               description: ''
             },
             loading: false
           }

  componentDidMount = () => {}
    
  saveRaffle = (data) => {
    let formData = data 
    formData['start_date'] = this.state.startDate
    formData['expiry_date'] = this.state.endDate
    formData['duration'] = this.state.endDate.diff(this.state.startDate, 'days')
    formData['raffle_type'] = 'Gift Card'
    Object.assign(formData, this.refs.departmentsBox.getSelectedData());
    if (formData['visibility'] == 'employees' && formData['employees_id'].length == 0) {
        toastrNotification({success: false, title: 'Please select raffle participants.'})
        return false;          
    }
        
    this.setState({ loading: true })
    raffleClient.createRaffle(formData).then((raffle) => {
      if (raffle.id) {
        toastrNotification({success: true, title: 'Raffle has been created'})
        this.props.onAddRaffle(raffle)
        this.props.onCloseBtnClick()
      } else {
        toastrNotification({success: false, title: 'Unable to create raffle'})        
        setTimeout(() => {
          this.setState({ loading: false })
        }, 1500)
      }
    })
  }
              
  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }
    
  onDatesChange = ({ startDate, endDate }) => {
    const dates = { startDate, endDate }
    this.setState({ startDate, endDate })
    if (dates.startDate == null || dates.endDate == null) { 
      this.disableButton() 
    } else {
      this.enableButton()
    }
  }
  
 render() {
    const companyBalance = this.props.companyBalance;
    const maxCardValue = companyBalance <= 100 ? companyBalance : 100;
    const minCardValue = 5;
   
    return (
      <Formsy onValidSubmit={this.saveRaffle} onValid={this.enableButton} onInvalid={this.disableButton} ref="form">
        <Modal.Header closeButton>
          <Modal.Title>Create Raffle</Modal.Title>
        </Modal.Header>
        <Modal.Body className="gray-bg">
            <div className='wrapper wrapper-content'>
              {companyBalance >= minCardValue 
                ?
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Title</label>
                      <Input value={this.state.fields.title} onChange={this.onInputChange} className='form-control' name='title' required/>
                    </div>
                    <div className="form-group">
                      <label>Description</label>
                      <Textarea value={this.state.fields.description} onChange={this.onInputChange} className='form-control' name='description' />
                    </div>
                    <div className="form-group">
                      <DepartmentsBox ref="departmentsBox" />
                    </div>
                    <div className="form-group">
                      <label>Card Cost</label>
                      <div className="input-group m-b">
                        <span className="input-group-addon">$</span> 
                        <Input type="number" name="card_amount" placeholder="10" value={this.state.fields.card_amount} className="form-control" min={minCardValue} max={maxCardValue} required validations="isInt"/> 
                        <span className="input-group-addon">.00</span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Raffle Period</label>
                      <DateRangePickerWrapper
                        ref='rafflePeriodPicker'
                        initialStartDate={moment()}
                        initialEndDate={moment()}
                        isOutsideRange={day =>
                          !isInclusivelyAfterDay(day, moment())
                        }
                        minimumNights={0}
                        readOnly
                        onDatesChange={this.onDatesChange}
                      />
                    </div>
                  </div>
                </div>
                :
                <div className="row">
                  <div className="col-md-12">
                    A balance in your account is required to start a gift card raffle. 
                    Replenish the company <a target="_blank" href="https://prod.ezzely.com/backend">incentive balance</a> to start gift card raffle.
                  </div>
                </div> 
              }  
            </div>              
        </Modal.Body>
        <Modal.Footer>
            <LaddaButton disabled={!this.state.canSubmit || this.state.loading} data-style={EXPAND_LEFT} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Start Raffle</LaddaButton> 
            <button type="button" className="btn btn-default btn-sm" onClick={() => this.props.onCloseBtnClick()}>Close</button>
        </Modal.Footer>
      </Formsy>
    );
  }
}

export default CreateRaffleGiftCard;
