import React, { Component } from 'react';
import PointsTransactionList from '../components/rewards/PointsTransactionList';
import AvailablePointsTransactionList from '../components/rewards/AvailablePointsTransactionList';

class PointsHistoryView extends Component {

    render() {
        return (
            <div className="wrapper wrapper-content">

                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>Points Transactions</h5>
                            </div>
                            <div className="ibox-content" style={{ paddingTop: '0' }}>
                                <PointsTransactionList ref="pointsTransactionList" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5>Points Rewarded To Others</h5>
                            </div>
                            <div className="ibox-content" style={{ paddingTop: '0' }}>
                                <AvailablePointsTransactionList />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PointsHistoryView;
