import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

class ProfileUpdateWidget extends Component {
  
  hideWidget = () => {
    this.props.onHideWidget();
  }

  render() {
      let profileCompleteness = this.props.profileCompleteness.completeness || 0;
      
      return (
        <div className="ibox profile-completeness-widget">
          <div className="ibox-content">
            <div className="row">
              <div className="col-md-12">
                <h3>Profile: <span className='main-color'>{profileCompleteness}% Complete</span></h3>
                {this.props.showHideButton &&
                  <a className="close-link" onClick={() => this.hideWidget()}>
                    <FontAwesomeIcon icon={faTimes}/>
                  </a>
                }  
                <div className="progress progress-mini" style={{marginBottom: '8px'}}>
                    <div style={{width: `${profileCompleteness}%`}} className="progress-bar"></div>
                </div>
                { this.props.profileCompleteness.next_update_step &&
                  <div>
                    { this.props.showUpdateSteps &&
                    <Link to={'/profile'}>
                      {this.props.profileCompleteness.next_update_step.text}
                    </Link> 
                    }
                    { !this.props.showUpdateSteps &&
                      <span>{this.props.profileCompleteness.next_update_step.text}</span>
                    }
                    <strong>&nbsp; +{this.props.profileCompleteness.next_update_step.percentages}% 
                    {this.props.profileCompleteness.next_update_step.points > 0 &&
                      <span>, +{this.props.profileCompleteness.next_update_step.points} EZ-points</span>
                    }
                    </strong> 
                  </div>
                }  
              </div>
            </div>    
          </div>  
        </div>  
      )
    }
}

export default ProfileUpdateWidget;
