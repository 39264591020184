import React, { Component } from 'react';
import {withFormsy} from 'formsy-react';

class Input extends Component { 
  
  handleChange = (evt) => {
    this.props.setValue(evt.target.value);
    if (this.props.onChange) this.props.onChange(evt);
  }
  
  render() {

    const errorMessage = this.props.errorMessage;
    //this.props.showRequired()

    return (
      <div>
        <input
          type={this.props.type || 'text'}
          name={this.props.name}
          onChange={this.handleChange}
          value={this.props.value || ''}
          checked={this.props.type === 'checkbox' && this.props.value ? 'checked' : null}
          className={this.props.className}
          placeholder={this.props.placeholder}
          tabIndex={this.props.tabIndex}
          disabled={this.props.disabled}
          required={this.props.required}
          min={this.props.min}
          max={this.props.max}       
          autoComplete="off"
        />
        <span className='validation-error'>{errorMessage}</span>
      </div>  
    );
  }
}

export default withFormsy(Input)
