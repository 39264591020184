// @flow
import React  from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import SpotlightSearch from './SpotlightSearch';
import { client } from '../Client';


class SpotlightEmployeeRow extends React.Component {

  openEmployee() {
    alert('opened ' + this.props.item.id);
  }

  render() {
      return (
        <Link className="list-group-item" to={`/employee/${this.props.item.id}`}>
          <img className="img-rounded" src={this.props.item.avatar_image_url()} height="40"  style={{ marginRight: '10px'}}/>
            {this.props.item.first_name} {this.props.item.last_name}
            <FontAwesomeIcon icon={faChevronRight} className="pull-right" style={{ marginTop: '1em'}} />
        </Link>
      )
  }
}

export default SpotlightEmployeeRow;
