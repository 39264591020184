import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';

class CompanySettingsClient {
  
  getSettings(option) {
    return client.callApi(Config.API_BASE + '/university_settings.json?option='+option)
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }

  updateSettings(data) {
    return client.callApi(Config.API_BASE + '/university_settings/update_settings.json', {}, 'PUT', {settings: data})
      .catch((error) => {
        client.handleApiError(error);
        return {} ;
      });    
  }

  getAllowedEmployees(option) {
    return client.callApi(Config.API_BASE + '/university_settings/get_allowed_employees.json?option='+option)
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  getCurrentUserAccessSettings() {
    return client.callApi(Config.API_BASE + '/university_settings/get_user_access_settings.json')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  checkUserAccess(option) {
    return client.callApi(Config.API_BASE + '/university_settings/check_user_access.json?option='+option)
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }

}

export const companySettingsClient = new CompanySettingsClient();
