import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons/faTicketAlt';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { toastrNotification } from '../../helpers/Toastr';
import { mediumDateFormat } from './../../helpers/Formatter';
import moment from 'moment';
import { raffleClient } from '../../clients/RaffleClient';
import { currencyFormat } from '../../helpers/Formatter';
import { client } from '../Client';


class Raffle extends Component {
  
  state = {
    participant_active_count: 0,
    participant_passed_count: 0,
    user_is_participant: false, 
    user_clicked_on_btns: false,
    loading: false
  }
  
  componentDidMount() {
    this.setState({ participant_active_count: this.props.raffle.participant_active_count,
                    participant_passed_count: this.props.raffle.participant_passed_count,
                    user_is_participant: this.props.raffle.user_is_participant, 
                    user_clicked_on_btns: this.props.raffle.user_clicked_on_btns,
                    participantId: this.props.raffle.participant_id,
                    widgetHidden: false
                   })
  }
  

  createRaffleParticipant(is_active) {
    let participantData = { raffle_id: this.props.raffle.id, active: is_active }
    this.setState({ loading: true });
    raffleClient.createRaffleParticipant(participantData).then((response) => {
      if (response.success) {
        this.setState({ user_is_participant: response.user_is_participant, user_clicked_on_btns: true, participantId: response.participant_id });
        if (response.user_is_participant === true) {
          this.setState({ participant_active_count: this.state.participant_active_count + 1})
        } else {
          this.setState({ participant_passed_count: this.state.participant_passed_count + 1})
        }
      } else {
        toastrNotification({success: false, title: 'Something went wrong'})        
      }
      setTimeout(() => {
        this.setState({ loading: false })
      }, 1500)
    }); 
  }
  
  hideWidget = () => {
    raffleClient.hideRafflewidget(this.state.participantId).then((response) => {
      if (response.success) {
        this.setState({ widgetHidden: true })
      } else {
        toastrNotification({success: false, title: 'Unable to hide widget.'})        
      }
    });
  }
  
  need_to_show_raffle() {
    if (this.state.widgetHidden) return false;
    return !this.state.user_clicked_on_btns || this.state.user_is_participant
  }

  renderBecomeParticipantBtn() {
      return (
        <button type="button" disabled={this.state.loading} className="btn btn-white btn-sm btn-block" onClick={() => this.createRaffleParticipant(true)}>I&#39;m in</button>
      )
  }
  
  renderPassBtn() {
      return (
        <button type="button" disabled={this.state.loading} className="btn btn-white btn-sm btn-block" onClick={() => this.createRaffleParticipant(false)}>Pass</button>
      )
  }
  
  renderTimeLeft() {
    let now = moment();
    let deadline = moment(this.props.raffle.expiry_date);
    if (now.isAfter(deadline)) {
      return (
        ''
      )
    } else {
      return (
        <span>{ deadline.from(now) }</span>
      )  
    }
  }

  
  renderParticipantCountInfo() {
    if (this.state.user_is_participant) {
      return (
        <span>You are participating {this.state.participant_active_count === 1 ? 'in the raffle' : `with ${this.state.participant_active_count - 1} other ${this.state.participant_active_count === 2 ? 'person' : 'people'}`}. Raffle expires {this.renderTimeLeft()}.</span>
      )
    }
  }
  
  renderEventRaffleContent = () => {
    const raffle = this.props.raffle
    return (
      <div>
      { !this.state.user_is_participant &&
        <div className="row">
          <div className="col-md-4">
            <img src={raffle.image_url} className="img-responsive" style={{marginBottom:'8px'}}/>
          </div>
          <div className="col-md-8">
            <h3>{raffle.event_name}</h3>
          </div>
        </div>
      }
      {this.state.user_is_participant &&  
        <h4>{raffle.event_name}</h4>
      }                    
        <div className="pull-left">
          <small>
            <FontAwesomeIcon icon={faTicketAlt}/> {raffle.ticket_count} {raffle.ticket_count === 1 ? 'ticket' : 'tickets'} 
            &nbsp; | &nbsp;
          </small>
        </div>   
        <div className="pull-left">
          <small>
            {moment(raffle.event_date).format('MMMM Do YYYY, h:mm a')} 
            &nbsp; | &nbsp;
          </small>
        </div> 
        <div className="pull-left">
          <small>
            {raffle.event_location}
          </small>
        </div>
        { !this.state.user_is_participant && raffle.description &&
          <div className="row">
            <div className="col-md-12">
              <small>{raffle.description}</small>
            </div>  
          </div> 
        }
        <div className="clearfix"/> 
      </div>  
    )
  }
  
  renderRewardRaffleContent = () => {
    const raffle = this.props.raffle
    return (
      <div>
      { !this.state.user_is_participant &&
        <div className="row">
          <div className="col-md-12">
            <h3>{raffle.reward_type}</h3>
          </div>
        </div>
      }
      {this.state.user_is_participant &&  
        <h4>{raffle.reward_type}</h4>
      }                    
      { !this.state.user_is_participant && raffle.description &&
        <div className="row">
          <div className="col-md-12">
            <small>{raffle.description}</small>
          </div>  
        </div> 
      }
      <div className="clearfix"/> 
      </div>  
    )
  }
  
  renderGiftCardRaffleContent = () => {
    const raffle = this.props.raffle
    return (
      <div>
      { !this.state.user_is_participant &&
        <div className="row">
          <div className="col-md-12">
            <h3>Gift card worth {currencyFormat(raffle.card_amount)} to be won!</h3>
          </div>
        </div>
      }
      {this.state.user_is_participant &&  
        <h4>Gift Card on {currencyFormat(raffle.card_amount)}</h4>
      }                    
      { !this.state.user_is_participant && raffle.description &&
        <div className="row">
          <div className="col-md-12">
            <small>{raffle.description}</small>
          </div>  
        </div> 
      }
      <div className="clearfix"/> 
      </div>  
    )
  }

  render() {
      let raffle = this.props.raffle;
    
      return (
          <div>
          { this.need_to_show_raffle() && 
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                    <h5><FontAwesomeIcon icon={faBolt} className="text-warning" /> {raffle.title}</h5>
                    <div className="ibox-tools">
                      {!this.state.user_clicked_on_btns && <span><FontAwesomeIcon icon={faClock}/> {this.renderTimeLeft()}</span>}
                      {this.state.user_clicked_on_btns &&
                        <a className="close-link" onClick={() => this.hideWidget()}>
                          <FontAwesomeIcon icon={faTimes}/>
                        </a>
                      }
                    </div>
                </div>
                <div className="ibox-content ibox-heading">
                  { raffle.isEventRaffle() &&
                    this.renderEventRaffleContent()
                  }
                  { raffle.isRewardRaffle() &&
                    this.renderRewardRaffleContent()
                  }
                  { raffle.isGiftCardRaffle() &&
                    this.renderGiftCardRaffleContent()
                  }
                </div>
                <div className="ibox-content">
                  {this.renderParticipantCountInfo()}
                    {!this.state.user_clicked_on_btns &&
                      <div className="user-button">
                        <div className="row">
                            <div className="col-md-6">
                                {this.renderBecomeParticipantBtn()}                                
                            </div>
                            <div className="col-md-6">
                                {this.renderPassBtn()}
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <p><small>
                            By clicking <b>I'm in</b> you agree to the <a onClick={() => client.showRaffleRules()}>Ezzely Raffle Rules and Conditions</a>.
                            </small></p>
                          </div>
                        </div>
                      </div>
                    }  
                </div>
            </div>
          }
        </div>    
      )
    }
}

export default Raffle;
