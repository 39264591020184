// @flow
import React, { Component } from 'react';
import Coin from '../common/Coin';
import ReactPaginate from 'react-paginate';
import { rewardClient } from '../../clients/RewardClient';
import { faCcAmazonPay } from '@fortawesome/free-brands-svg-icons/faCcAmazonPay';
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class RewardsList extends Component {

    state = {
        rewards: [],
        page: 1,
        loading: false
    }

    componentDidMount() {
        this.getRewardsHistory();
    }

    getRewardsHistory = () => {
        let page = this.state.page;
        this.setState({ loading: true });
        rewardClient.getRewardsHistory(page, this.props?.employeeId).then((data) => {
            this.setState({
                rewards: data.rewards || [],
                pageCount: data.total_pages,
                loading: false
            });
        });
    }

    handlePageClick = (data) => {
        let page = data.selected + 1;
        this.setState({ page: page }, () => {
            this.getRewardsHistory();
        });
    }

    refresh = () => {
        this.setState({ page: 1 }, () => {
            this.getRewardsHistory();
        });
    }

    renderLoader = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="sk-spinner-loader">
                        <div className="sk-spinner sk-spinner-double-bounce">
                            <div className="sk-double-bounce1"></div>
                            <div className="sk-double-bounce2"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderLinkForGift= (reward)=>{
        console.log(reward)
        if(reward.is_gift && !this.props.employeeId) return(
            <a href={reward.link} target="_blank">{reward.title}</a>
        );
        else return(reward.title);
    }

    renderRewardImage = (reward)=>{
        if(reward.is_gift) return(
          <FontAwesomeIcon icon={faCcAmazonPay} size='2x'/>
        );
        else return(
          <FontAwesomeIcon icon={faGift} size='2x' />
        );
    }

    renderRedeemDate=(reward, dateFormat)=>{
        if(!reward.is_gift && reward.completed_at) return dateFormat(reward.completed_at, "mediumDate") 
    }

    render() {
        let dateFormat = require('dateformat');
        const { rewards } = this.state;
        return (
            <div className="full-height-scroll">
                {this.state.loading && this.renderLoader()}
                {!this.state.loading &&
                    <div className="table-responsive">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th width='64'></th>
                                    <th>Title</th>
                                    <th>Points</th>
                                    <th>Price</th>
                                    <th>Date</th>
                                    <th>Redeemed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rewards.map((reward, index) => (
                                    <tr key={"reward-" + index}>
                                        <td style={{padding: '4px'}} className='text-center'>{this.renderRewardImage(reward)}</td>
                                        <td>{this.renderLinkForGift(reward)}</td>
                                        <td className={reward.points < 0 ? 'balance-negative' : 'balance-positive'}><Coin width="16px" /> {reward.points}</td>
                                        <td>{reward.price}</td>
                                        <td>{dateFormat(reward.date, "mediumDate")}</td>
                                        <td>{this.renderRedeemDate(reward,dateFormat)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
                {rewards.length > 0 && this.state.pageCount > 1 &&
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                                pageClassName={'paginate_button'}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default RewardsList;