import React, { Component } from 'react';
import SurveyView from '../survey/SurveyView';

class EmployeeSurveys extends Component {

  componentDidMount = () => {}  
  
  render() {    
      return (
        <div className="ibox float-e-margins">
              <div className="ibox-title">
                  <h5>My Surveys</h5>
              </div>
              <div className="ibox-content ibox-heading">
                {
                  this.props.employeeSurveys.map((survey) => ( 
                    <div key={'s'+survey.id}><SurveyView survey={survey} employeeMode={true} initSurvey={survey.id == this.props.initSurveyId}/></div>
                  ))
                }
              </div>
        </div>
      )
    }
}

export default EmployeeSurveys;
