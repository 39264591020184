// @flow
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons/faReply';
import { client } from '../Client';
import { timeFromNow } from '../../helpers/Humanizer';
import ActivityMessageBox from './ActivityMessageBox';
import ActivityPostComment from './ActivityPostComment';
import ActivityPostCommentNew from './ActivityPostCommentNew';

class ActivityPostCommentsContainer extends Component {

  state = {
    showCommentBox: false,
    showAllReplies: false
  }
  
  showCommentBox = () => {
    this.setState({ showCommentBox: true, showAllReplies: true });
  }
  
  hideCommentBox = () => {
    this.setState({ showCommentBox: false });
  }
  
  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.editableComment !== null && this.refs.comment_editor !== undefined) {
      //console.log(prevState.editableComment)
      //this.refs.comment_editor.loadComment(prevState.editableComment);
      this.setState({ editableComment: null });
    }  
  }
  
  toggleCommentBox = () => {
    if(this.state.showCommentBox) {
      this.hideCommentBox();
    } else {
      this.showCommentBox();
    }
  }
  
  showAllReplies = () => {
    this.setState({ showAllReplies: true });
    this.showCommentBox();
  }

  render() {
      const comment = this.props.comment;
      const replies = this.props.post.comments.filter(c => c.parent_id == comment.id);
      const lastReply = replies.length > 0 ? replies[replies.length -1] : null
      
      return (
        <div>
          <ActivityPostComment
            comment={comment}
            post={this.props.post}
            channel={this.props.channel}
            containerId={this.props.containerId}
            onClickReply={this.toggleCommentBox}
          />
          <div className="social-comment">          
            { this.state.showAllReplies && replies.map((child_comment) => (
                <ActivityPostComment
                  key={this.props.containerId + 'comment' + child_comment.id}
                  comment={child_comment}
                  post={this.props.post}
                  channel={this.props.channel}
                  onClickReply={this.toggleCommentBox}
                  containerId={this.props.containerId}
                />
              ))
            }
            { !this.state.showAllReplies && replies.length > 0 &&
              <div className="social-comment show-replies-link">
                <div className="media-body">
                   <a className="small" onClick={() => this.showAllReplies()}>
                     <FontAwesomeIcon icon={faReply} className="replies-icon" />
                     <img alt="image" src={lastReply.author_avatar_url ? lastReply.author_avatar_url : 'img/avatar_missing.png'} className="avatar-image img-circle" style={{marginLeft: '6px', marginRight:'3px'}}/>            
                     {lastReply.author_name} replied · {replies.length} Replies
                   </a> 
                   <span> · </span><small className="text-muted">{timeFromNow(lastReply.createdDate)}</small>                            
                </div>
              </div>  
            }
            {this.state.showCommentBox && 
              <ActivityPostCommentNew 
                post={this.props.post}
                comment={comment} 
                ref='comment_editor'               
                channel={this.props.channel}
                onHideCommentBox={this.hideCommentBox}
              />
            }
          </div>    
        </div> 
      )
    }

}

export default ActivityPostCommentsContainer;
