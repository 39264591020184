import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { toastrNotification } from './../../helpers/Toastr';
import { client } from '../Client';

class ChangePasswordForm extends Component {

  state = {
    error: null,
    showModal: false,
    fields: {}
  }

  close = () => {
    this.setState({ showModal: false, error: null });
  }

  open = () => {
    this.setState({ showModal: true });
  }

  canSubmit = () => {
    return this.state.fields.password && 
    this.state.fields.password_confirmation && 
    this.state.fields.password.length >= 6 && 
    this.state.fields.password_confirmation.length >= 6;
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }

  submit = () => {
    if(this.state.fields.password !== this.state.fields.password_confirmation) {
      this.setState({error: "Password doesn't match confirmation"});
    } else {
      this.close();
      client.changePassword(this.state.fields).then((o) => {
        if (o.success){
          toastrNotification({success: true, title: 'Password has been changed'});
          if (this.props.onSuccessfulPasswordUpdate) {
            this.props.onSuccessfulPasswordUpdate()
          }
        } else {
          toastrNotification({success: false, title: 'Unable to change the password'})          
        }
      });
    }    
  }
  
  render() {
    return (
      <div>
        {!this.props.forceMode &&
          <Button bsStyle="primary" bsSize="small" onClick={this.open}><FontAwesomeIcon icon={faKey} />&nbsp;Change Password</Button>
        }
        <Modal show={this.state.showModal} onHide={this.close}  backdrop={ this.props.forceMode ? 'static' : true }>
            <Modal.Body>
              <h3 className="m-b">{this.props.title || 'Change Password'}</h3>
              <p>Minimum length is 6 characters</p>
              { this.state.error &&
                <p className="text-danger">{this.state.error}</p>
              }
              <div className='row'>
                <div className='col-sm-12'>
                  <input type="password" className="form-control" name="password" placeholder="Enter New Password *" onChange={this.onInputChange} />
                </div>
              </div>              
              <br/>  
              <div className='row'>
                <div className='col-sm-12'>
                  <input type="password" className="form-control" name="password_confirmation" placeholder="Enter Password Confirmation *" onChange={this.onInputChange}/>
                </div>
              </div>              
            </Modal.Body>
            <Modal.Footer>
              <Button bsClass="btn btn-primary" disabled={!this.canSubmit()} onClick={this.submit}>Submit</Button>
              { !this.props.forceMode &&
                <Button onClick={this.close}>Close</Button>
              }  
            </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ChangePasswordForm;
