// @flow
import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { verifiedCredentialsClient } from '../../clients/VerifiedCredentialsClient';
import { toastrNotification } from '../../helpers/Toastr';
import { Modal, Button } from 'react-bootstrap';
import Input from '../form_components/Input';

// import 'react-select/dist/react-select.css';
//import './CreateReward.css';

class CreateCredentialsItem extends Component {

  state = { 
    showDialog: false,
    canSubmit: false,
    email_or_phone: ""
  }

  show() {
    this.setState({ showDialog: true });
  }

  close() {
    this.setState({ showDialog: false });
  }
  
  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
    
  formSubmit = (data) => {
    const _this = this;
    verifiedCredentialsClient.CreateCredentialsItem(data)
    .then((credentialsItem) => {
        if (credentialsItem.id) {
          _this.props.onAddNewCredentialsItem(credentialsItem);
          toastrNotification({success: true, title: 'New verified credentials item was successfully created'});
          _this.close();
        } else {
          _this.refs.newCredentialsItemForm.updateInputsWithError(credentialsItem);
        }
    });
  }
    
  resetForm = () => {
     formsy.reset();
  }

  onInputChange = (evt) => {
    this.setState({ email_or_phone: evt.target.value });
  }
    
  render() {
      
      return (
        <Modal show={this.state.showDialog } onHide={() => this.close()} >
          <Modal.Header closeButton>
            <Modal.Title>New Credentials Item</Modal.Title>
          </Modal.Header>
          <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="newCredentialsItemForm" className="form-horizontal">
            <Modal.Body >
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="title" className="sr-only"></label>
                    <Input
                      name="email_or_phone"
                      placeholder="Email or Phone *"
                      className="form-control"
                      tabIndex="1"
                      value={this.state.email_or_phone}
                      onChange={this.onInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.close()}>Cancel</Button>
              <Button 
                bsStyle="primary" 
                type="submit"
                disabled={!this.state.canSubmit}
              >Save</Button>
            </Modal.Footer>
          </Formsy>  
        </Modal>
      )
  }
}
export default CreateCredentialsItem;
