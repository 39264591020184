import React, { Component } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

class UserPopover extends Component {

 componentDidMount() {
 }

  render() {
    const popover = (
      <Popover id={'popover-'+this.props.id}>
        <div className='text-center'>
          <img alt={this.props.employee.full_name()} src={this.props.employee.avatar_image_url()} height='64' className="img-circle" />
        </div>
        {/*<div>
          {this.props.employee.full_position()}
        </div>*/}
      </Popover>
    );
    
    return (
      <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popover}>
        <a className="recognition">{this.props.employee.full_name()}</a>
      </OverlayTrigger>
    )
  }
}

export default UserPopover;
