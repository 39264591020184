// @flow
import React, { Component } from 'react';
import { activityActionClient } from '../../clients/ActivityActionClient';
import ActivityTextBox from '../activity/ActivityPostForm/ActivityTextBox';
import Config from 'config';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { toastrNotification } from '../../helpers/Toastr';


class HustleFeedItemModalForm extends Component {

  state = {
    showModal: false,
    loading: false,
    canSubmit: true,
    feedItem: null,
  }
  
  componentDidMount() {  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.feedItem !== this.state.feedItem && this.editor && this.state.feedItem) {
      this.editor.loadContent(this.state.feedItem.message);
      this.editor.focus();
    }
  }
  
  open = (feedItem=null) => {
    this.setState({ showModal: true, feedItem: feedItem, loading: false });
  }
                              
  close = () => {
    this.setState({ showModal: false });
  }
  
  handleSubmit = () => {
    if (!this.editor.isValid()) {
      toastrNotification({success: false, message: "Please write your message"})
    } else {
      this.setState({ loading: true });
      const message = this.editor.getContent();
      if (this.state.feedItem) {
        this.updateFeedItem(message);
      } else {
        this.createFeedItem(message);
      }
    }
  }
  
  createFeedItem = (message) => {
    activityActionClient.createAction({message: message})
    .then((item) => {
        if (item.id) {
          toastrNotification({success: true, title: 'New hustle feed item was successfully created'});
          this.props.reloadFeedItems();
          this.close();
        } else {
          toastrNotification({success: false, title: 'Unable to create hustle feed item'});
          setTimeout(() => {
            this.setState({ loading: false })
          }, 1500)
        }
    });
  }
  
  updateFeedItem = (message) => {
    if (!this.state.feedItem) { return };
    activityActionClient.updateAction(this.state.feedItem.id, {message: message})
    .then((item) => {
      if (item.id) {
        toastrNotification({ success: true, message: 'Hustle feed item was successfully updated' });
        this.props.reloadFeedItems();
        this.close();
      } else {
        toastrNotification({ success: false, message: 'Unable to update hustle feed item' });
        setTimeout(() => {
          this.setState({ loading: false })
        }, 1500)
      }
    });
  }
    
  validateMessageBox = (result) => { return }
        
  render() {
      return (
        <div>
          <Modal show={this.state.showModal} 
            onHide={this.close} 
            dialogClassName="" 
          >
              <Modal.Header closeButton> 
                {this.state.feedItem 
                  ?  
                  <h3>Edit Hustle Feed Item</h3>
                  : 
                  <h3>New Hustle Feed Item</h3>
                }
              </Modal.Header>
              <Modal.Body id="hustleFeedItemForm">
                <div className="row">
                  <div className="col-md-12">
                    <ActivityTextBox
                      onRef={ref => (this.editor = ref)}
                      placeholder="Write your text here"
                      validateMessageBox={this.validateMessageBox}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} onClick={() => this.handleSubmit()} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Submit</LaddaButton>
                <Button onClick={this.close}>Close</Button>
              </Modal.Footer>
          </Modal>
        </div>  
      );
  }
    
}

export default HustleFeedItemModalForm
