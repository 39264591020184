import React, { Component } from 'react';
import {withFormsy} from 'formsy-react';
import 'rc-time-picker/assets/index.css';

import TimePicker from 'rc-time-picker';

class TimeField extends Component { 
  
  handleChange = (time) => {
    this.props.setValue(time);
    if(this.props.onChange) this.props.onChange(time);
  }
  
  render() {

    const errorMessage = this.props.errorMessage;

    return (
      <div>
        <TimePicker
          autoComplete="off"
          onChange={this.handleChange}
          use12Hours={true}
          showSecond={false}
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          name={this.props.name || ""}
          placeholder={this.props.placeholder || ""}
          inputReadOnly={true}
          minuteStep={this.props.minuteStep || 1}
          popupClassName={this.props.popupClassName || ""}
        />
        <span className='validation-error'>{errorMessage}</span>
      </div>  
    );
  }
}

export default withFormsy(TimeField)
