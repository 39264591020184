import React from 'react';
import MobileFooter from '../common/MobileFooter';
import TopMobileHeader from '../common/TopMobileHeader';
import { client } from '../Client.js';
import { correctHeight, detectBody } from './Helpers';
import { routeIsEqual } from '../../helpers/TopMenu';
import { withRouter } from 'react-router-dom';
import { getNextRoute, NAVIGATE_LEFT, NAVIGATE_RIGHT, SwipeDetector } from '../../helpers/Gestures';

class Mobile extends React.Component {

  fullScreenMode = () => {
    return (routeIsEqual("/conversation/", this.props.location.pathname) ||
      routeIsEqual("/new_activity_post", this.props.location.pathname));
  }

  isActivityPage = () => {
    return routeIsEqual("/activity", this.props.location.pathname);
  }

  showHeader = () => {
    return !(this.isActivityPage() || this.fullScreenMode())
  }

  componentDidMount() {
    console.log(this.props.history)
    // Load css template
    const css_template_url = client.getLayoutUrl();
    if (css_template_url) {
      $("<link/>", { rel: "stylesheet", type: "text/css", href: css_template_url }).appendTo("head");
    };

    // Run correctHeight function on load and resize window event
    $(window).bind("load resize", function () {
      correctHeight();
      detectBody();
    });

    // Correct height of wrapper after metisMenu animation.
    $('.metismenu a').click(() => {
      setTimeout(() => {
        correctHeight();
      }, 300)
    });
  }

  gestureNavigation = (direction) => {
    getNextRoute(this.props.location.pathname, direction)
      .then(path => {
        console.log(path);
        if (path) this.props.history.push(path);
      })
      .catch(err => console.error(err));
  }

  render() {
    let wrapperClass = "gray-bg mobile-wrapper " +
      (this.fullScreenMode() ? "full-screen no-padding " : " ") +
      this.props.location.pathname.replace(/\//gi, '_');
    let contextClass = (this.props.location.pathname || '').split('/')[1]
    if (contextClass) {
      wrapperClass += (" wrapper-" + contextClass)
    }
    return (
      <SwipeDetector onSwipedLeft={(e) => this.gestureNavigation(NAVIGATE_RIGHT)} onSwipedRight={(e) => this.gestureNavigation(NAVIGATE_LEFT)}>
        <div id="page-wrapper" className={wrapperClass} style={{ backgroundColor: "#eee" }}>
          {this.showHeader() && <TopMobileHeader location={this.props.location} />}
          <div className="content-area ez-opacity-filter">
            {this.props.children}
          </div>
          {!this.fullScreenMode() && <MobileFooter location={this.props.location} />}
        </div>
      </SwipeDetector>
    )
  }
}

export default withRouter(Mobile)
