import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons/faUserCircle';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons/faAddressCard';
import { faUserTimes } from '@fortawesome/free-solid-svg-icons/faUserTimes';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faAmbulance } from '@fortawesome/free-solid-svg-icons/faAmbulance';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons/faGraduationCap';
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift';
import { faGifts } from '@fortawesome/free-solid-svg-icons/faGifts';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons/faRedoAlt';
import Employee from '../models/Employee';
import { client } from '../components/Client';
import { toastrNotification } from './../helpers/Toastr';
import { statusLabel, adminLabel } from './../helpers/Employees';
import { iconUrl } from '../helpers/Courses.js'
import LicensesTable from '../components/employees/LicensesTable';
import CertificatesTable from '../components/employees/CertificatesTable';
import UploadAttachment from '../components/employees/UploadAttachment';
import AttachmentsList from '../components/employees/AttachmentsList';
import NotesTable from '../components/employees/NotesTable';
import AddNoteForm from '../components/employees/AddNoteForm';

import WorkFields from '../components/employees/WorkFields';
import AddressFields from '../components/employees/AddressFields';
import AboutMeFields from '../components/employees/AboutMeFields';
import EmergencyFields from '../components/employees/EmergencyFields';
import EmployeePoints from '../components/employees/EmployeePoints';

import IBox from '../components/common/IBox';

import './Employee.css';
import EmployeeRewards from '../components/employees/EmployeeRewards';

class EmployeeView extends Component {

  state = {
    activeTab: 'employee',
    employee: new Employee({}),
    licenses: [],
    certificates: [],
    isLoading: false
  };

  componentDidMount() {
    this.setState(this.state);
    this.getEmployee(true).then(() => {
      const queryParams = new URLSearchParams(this.props.location.search)
      if (queryParams.get('tab') === 'myrewards') this.show('myrewards');
    });
  }

  componentWillReceiveProps = (newProps) => {
    this.setState({ isLoading: true });
    client.getEmployee(newProps.match.params.id, true).then((employee) => {
      window.scrollTo(0, 0);
      this.getUserRecords(employee.coggno_email);
      this.setState({ employee: employee, isLoading: false })
    });
  }

  show(tab) {
    this.setState({ activeTab: tab });
  }

  getEmployee = () => {
    this.setState({ isLoading: true });
    return client.getEmployee(this.props.match.params.id, true).then((employee) => {
      window.scrollTo(0, 0);
      this.getUserRecords(employee.coggno_email);
      this.setState({ employee: employee, isLoading: false })
    });
  }

  reloadEmployee = (employee) => {
    this.setState({ employee: employee, isLoading: false })
  }

  terminateEmployee = () => {
    if (confirm('Are you sure to terminate employee?')) {
      this.setState({ isLoading: true });
      client.terminateEmployee(this.props.match.params.id).then((employee) => {
        this.reloadEmployee(employee);
        this.setState({ employee: employee, isLoading: false });
      })
    }
  }

  reactivateEmployee = () => {
    if (confirm('Are you sure to re-activate employee?')) {
      this.setState({ isLoading: true });
      client.reActivateEmployee(this.props.match.params.id).then((employee) => {
        this.reloadEmployee(employee);
        this.setState({ employee: employee, isLoading: false });
      })
    }
  }

  changeAdminRole = () => {
    if (confirm('Are you sure to update employee role?')) {
      this.setState({ isLoading: true });
      client.updateEmployeeRole(this.state.employee.id, { admin: !this.state.employee.admin }).then((empl) => {
        if (empl.id) {
          this.reloadEmployee(empl);
          toastrNotification({ success: true, message: 'Updated successfully' });
        } else {
          toastrNotification({ success: false, message: ('Unable to update') })
        }
        this.setState({ employee: employee, isLoading: false });
      });
    }
  }

  removeEmployee = () => {
    if (confirm('Are you sure to remove employee?')) {
      this.setState({ isLoading: true });
      client.removeEmployee(this.props.match.params.id).then((employee) => {
        this.reloadEmployee(employee);
        this.setState({ employee: employee, isLoading: false });
      })
    }
  }

  restoreEmployee = () => {
    if (confirm('Are you sure to restore employee?')) {
      this.setState({ isLoading: true });
      client.restoreEmployee(this.props.match.params.id).then((employee) => {
        this.reloadEmployee(employee);
        this.setState({ employee: employee, isLoading: false });
      })
    }
  }

  getUserRecords = (email) => {
    client.getUserRecords(email).then((data) => {
      this.setState({ licenses: data.licenses, certificates: data.certificates });
    });
  }

  handleCertificateDownload = (certificate) => {
    client.downloadCertificateUrl(certificate);
  }

  removeAttachment = (attachment) => {
    if (confirm('Are you sure to remove document?')) {
      client.removeAttachment(attachment).then((stat) => {
        if (stat.success) {
          let empl = Object.assign({}, this.state.employee);
          empl.attachments = empl.attachments.filter(a => a.id !== attachment.id);
          this.setState({ employee: new Employee(empl) });
          toastrNotification({ success: true, title: 'Document has been removed' })
        } else {
          toastrNotification({ success: false, title: 'Unable to remove document' })
        }
      });
    }
  }

  updateEmployeeAttachments = () => {
    this.getEmployee();
  }

  removeNote = (note) => {
    if (confirm('Are you sure to remove the note?')) {
      client.removeNote(note).then((stat) => {
        if (stat.success) {
          let empl = Object.assign({}, this.state.employee);
          empl.notes = empl.notes.filter(a => a.id !== note.id);
          this.setState({ employee: new Employee(empl) });
          toastrNotification({ success: true, title: 'Note has been removed' })
        } else {
          toastrNotification({ success: false, title: 'Unable to remove the note' })
        }
      });
    }
  }

  canBeTerminated = () => {
    return !(this.state.employee.terminated() || this.state.employee.id === client.currentUser().id || this.state.employee.admin || this.state.employee.deleted());
  }

  submitData = (data) => {
    client.updateEmployee(this.state.employee.id, data).then((empl) => {
      if (empl.id) {
        let employee = new Employee(empl);
        this.reloadEmployee(employee);
        toastrNotification({ success: true, message: 'Updated successfully' });
      } else {
        try {
          this.refs.form.updateInputsWithError(empl);
          toastrNotification({ success: false, message: 'Unable to update' })
        } catch (error) {
          toastrNotification({ success: false, message: 'Unable to update: ' + empl.error})
        }
      }
    });
  }

  render() {
    return (
      <div className="wrapper wrapper-content animated fadeIn employee-page">
        <div className="row" style={{ marginTop: '0px' }}>
          <div className="col-sm-12 m-t-sm">
            <Link to="/employees" className="btn btn-white btn-sm pull-right m-b-sm hidden-xs">Back to Employees</Link>
            <div className="btn-group pull-right" style={{ marginRight: '12px' }}>
              <button data-toggle="dropdown" className="btn btn-sm btn-white dropdown-toggle" aria-expanded="false">Actions <span className="caret"></span></button>
              <ul className="dropdown-menu">
                {this.canBeTerminated() &&
                  <li><a onClick={this.terminateEmployee}><FontAwesomeIcon icon={faPowerOff} /> &nbsp; Deactivate Employee</a></li>
                }
                {this.state.employee.terminated() &&
                  <li><a onClick={this.reactivateEmployee}><FontAwesomeIcon icon={faRedoAlt} /> &nbsp; Reactivate Employee</a></li>
                }
                {client.ownerLoggedIn() && this.state.employee.admin && !this.state.employee.terminated() && !this.state.employee.deleted() &&
                  <li><a onClick={this.changeAdminRole}><FontAwesomeIcon icon={faUserCircle} /> &nbsp; Disable Admin Role</a></li>
                }
                {client.ownerLoggedIn() && !this.state.employee.admin && !this.state.employee.terminated() && !this.state.employee.deleted() &&
                  <li><a onClick={this.changeAdminRole}><FontAwesomeIcon icon={faUserCircle} /> &nbsp; Enable Admin Role</a></li>
                }
                {this.state.employee.terminated() &&
                  <li>
                    <Link to={`/termination_survey/${this.state.employee.termination_survey_instance_id}`} target="_blank">
                      <FontAwesomeIcon icon={faEye} /> &nbsp; View Termination Survey
                    </Link>
                  </li>
                }
                {client.ownerLoggedIn() && this.state.employee.deleted() &&
                  <li><a onClick={this.restoreEmployee}><FontAwesomeIcon icon={faUserPlus} /> &nbsp; Restore Employee</a></li>
                }
                {client.ownerLoggedIn() && !this.state.employee.deleted() &&
                  <li><a onClick={this.removeEmployee}><FontAwesomeIcon icon={faUserTimes} /> &nbsp; Remove Employee</a></li>
                }
                {/*
        <li className="divider"></li>
        <li><a>More actions</a></li>
        */}
              </ul>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="row">
          <div className='col-sm-2 no-gutters'>
            <nav className="navbar-default" role="navigation">
              <div className="sidebar-collapse">
                <ul className="nav metismenu" id="side-menu">
                  <li className="nav-header2">
                    <div className="custom-row">
                      <div className="profile-image  profile-centered">
                        <img alt="image" src={this.state.employee.avatar_image_url()} className="img-circle circle-border m-b-md" alt="profile" />
                      </div>
                      <div className="profile-info profile-centered" style={{ paddingTop: '0px' }}>
                        <h2 className="no-margins">
                          {this.state.employee.full_name()}
                        </h2>
                        {this.state.employee.position && <h4>{this.state.employee.position}</h4>}
                        <h4>{statusLabel(this.state.employee)} {adminLabel(this.state.employee)}</h4>
                      </div>
                    </div>
                  </li>
                  <li className={this.state.activeTab == 'employee' ? 'active' : ''}>
                    <a onClick={() => this.show('employee')} className='left-scroll'><FontAwesomeIcon icon={faBriefcase} /> <span className="nav-label">Employee</span>  </a>
                  </li>
                  <li className={this.state.activeTab == 'personal' ? 'active' : ''}>
                    <a onClick={() => this.show('personal')} className='left-scroll'><FontAwesomeIcon icon={faAddressCard} /> <span className="nav-label">Personal</span></a>
                  </li>
                  {client.adminLoggedIn() && <li className={this.state.activeTab == 'rewards' ? 'active' : ''}>
                    <a onClick={() => this.show('rewards')} className='left-scroll'><FontAwesomeIcon icon={faGift} /> <span className="nav-label">Points</span></a>
                  </li>}
                  {client.adminLoggedIn() && <li className={this.state.activeTab == 'myrewards' ? 'active' : ''}>
                    <a onClick={() => this.show('myrewards')} className='left-scroll'><FontAwesomeIcon icon={faGifts} /> <span className="nav-label">Rewards</span></a>
                  </li>}
                  <li className={this.state.activeTab == 'emergency' ? 'active' : ''}>
                    <a onClick={() => this.show('emergency')} className='left-scroll'><FontAwesomeIcon icon={faAmbulance} /> <span className="nav-label">Emergency Contact</span></a>
                  </li>
                  <li className={this.state.activeTab == 'notes' ? 'active' : ''}>
                    <a onClick={() => this.show('notes')} className='left-scroll'><FontAwesomeIcon icon={faBook} /> <span className="nav-label">Notes </span>
                      {this.state.employee.notes.length > 0 &&
                        <span className="label label-info pull-right">{this.state.employee.notes.length}</span>}
                    </a>
                  </li>
                  <li className={this.state.activeTab == 'files' ? 'active' : ''}>
                    <a onClick={() => this.show('files')} className='left-scroll'><FontAwesomeIcon icon={faPaperclip} /> <span className="nav-label">Files</span>
                      {this.state.employee.attachments.length > 0 &&
                        <span className="label label-info pull-right">{this.state.employee.attachments.length}</span>}
                    </a>
                  </li>
                  <li className={this.state.activeTab == 'training' ? 'active' : ''}>
                    <a onClick={() => this.show('training')} className='left-scroll'><FontAwesomeIcon icon={faGraduationCap} /> <span className="nav-label">Training</span></a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className='col-sm-10'>
            <div>
              {this.state.isLoading &&
                <div className="sk-spinner sk-spinner-double-bounce">
                  <div className="sk-double-bounce1"></div>
                  <div className="sk-double-bounce2"></div>
                </div>
              }
              <div className="row" style={{ minHeight: '500px' }}>
                <div className="col-lg-12">

                  {this.state.activeTab === 'employee' &&
                    <IBox title="Employee Details" onEdit={() => this.refs.employeeForm.openEditForm()}>
                      <WorkFields ref="employeeForm" employee={this.state.employee} onReloadEmployee={this.reloadEmployee} />
                    </IBox>
                  }

                  {this.state.activeTab === 'personal' &&
                    <div>
                      <IBox title="Personal Information" onEdit={() => this.refs.personalForm.openEditForm()}>
                        <AddressFields ref="personalForm" employee={this.state.employee} onReloadEmployee={this.reloadEmployee} />
                      </IBox>
                      <IBox title="About" onEdit={() => this.refs.aboutMeForm.openEditForm()}>
                        <AboutMeFields ref="aboutMeForm" employee={this.state.employee} onFormSubmit={this.submitData} />
                      </IBox>
                    </div>
                  }

                  {this.state.activeTab === 'emergency' &&
                    <IBox title="Emergency Contact Information" onEdit={() => this.refs.emergencyForm.openEditForm()}>
                      <EmergencyFields ref="emergencyForm" employee={this.state.employee} onFormSubmit={this.submitData} />
                    </IBox>
                  }
                  {client.adminLoggedIn() && this.state.activeTab === 'rewards' && this.state.employee.id &&
                    <EmployeePoints employeeId={this.state.employee.id} />
                  }

                  {client.adminLoggedIn() && this.state.activeTab === 'myrewards' && this.state.employee.id &&
                    <EmployeeRewards employeeId={this.state.employee.id} />
                  }

                  {this.state.activeTab === 'notes' &&
                    <IBox title="Notes">
                      <div className="clearfix">
                        {this.state.employee.notes.length === 0 &&
                          <div className="text-center">
                            <h2>Upload the first Note for {this.state.employee.full_name()}</h2>
                            <div style={{ width: '120px', margin: '0px auto' }}>
                              <AddNoteForm employee={this.state.employee} onAddNote={this.getEmployee} />
                            </div>
                          </div>
                        }
                        {this.state.employee.notes.length > 0 &&
                          <div className="pull-right">
                            <AddNoteForm employee={this.state.employee} onAddNote={this.getEmployee} /><br />
                          </div>
                        }
                      </div>
                      <div className="table-responsive">
                        <NotesTable employee={this.state.employee} onRemoveNote={this.removeNote} />
                      </div>
                    </IBox>
                  }

                  {this.state.activeTab === 'files' &&
                    <IBox title="Files">
                      <div className="clearfix">
                        {this.state.employee.attachments.length === 0 &&
                          <div className="text-center">
                            <h2>Upload the first File for {this.state.employee.full_name()}</h2>
                            <div style={{ width: '120px', margin: '0px auto' }}>
                              <UploadAttachment employee={this.state.employee} onFinishUpload={this.getEmployee} />
                            </div>
                          </div>
                        }
                        {this.state.employee.attachments.length > 0 &&
                          <div className="pull-right">
                            <UploadAttachment employee={this.state.employee} onFinishUpload={this.getEmployee} />
                          </div>
                        }
                      </div>
                      <AttachmentsList
                        employee={this.state.employee}
                        onRemoveAttachment={this.removeAttachment}
                        onUpdateAttachment={this.updateEmployeeAttachments}
                      />
                    </IBox>
                  }

                  {this.state.activeTab === 'training' &&
                    <div className="m-b-xl">
                      <IBox title="Attempted Courses">
                        <div className="table-responsive">
                          <LicensesTable licenses={this.state.licenses || []} />
                        </div>
                      </IBox>

                      <IBox title="Certificates">
                        <div className="table-responsive">
                          <CertificatesTable certificates={this.state.certificates || []} />
                        </div>
                      </IBox>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default EmployeeView;
