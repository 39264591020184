import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faStar as faStarRegular} from '@fortawesome/free-regular-svg-icons/faStar'

import './SurveyQuestions.css'
import './RatingQuestion.css'

class RatingQuestion extends Component {
  
state = {
  answer: 3
} 

getAnswer = () => {
  return this.state.answer;
}

buildAnswerHash = () => {
  return { survey_question_id: this.props.question.id, data: this.state.answer }
}

handleChange = (val) => {
  this.setState({ answer: val });
  this.props.updateCurrentAnswer(val);
} 

renderStarIcon = (checked) => {
  if (checked) {
    return (
      <FontAwesomeIcon icon={faStar} />
    )    
  } else {
    return (
      <FontAwesomeIcon icon={faStarRegular} />
    )        
  }
}
    
render() {
       
    return (
      <div className="row">
        <div className="col-md-12">
          <h3 className="text-center">{this.props.question.question}</h3>
          <div style={{margin: '30px 0'}}>
            <div className='radio-options text-center'>            
              <div className="star-rating">
                <div className="star-rating__wrap">
                  <input className="star-rating__input" id={'star-rating-5-' + this.props.question.id} type="radio" onChange={(e) => this.handleChange(5)}  checked={this.state.answer === '5'}/>
                  <label className="star-rating__ico" htmlFor={'star-rating-5-' + this.props.question.id} title="5 out of 5 stars">
                    {this.renderStarIcon(this.state.answer === 5)}
                  </label>
                  <input className="star-rating__input" id={'star-rating-4-' + this.props.question.id} type="radio" onChange={(e) => this.handleChange(4)}  checked={this.state.answer === '4'}/>
                  <label className="star-rating__ico" htmlFor={'star-rating-4-' + this.props.question.id} title="4 out of 5 stars">
                    {this.renderStarIcon(this.state.answer >= 4)}
                  </label>
                  <input className="star-rating__input" id={'star-rating-3-' + this.props.question.id} type="radio" onChange={(e) => this.handleChange(3)}  checked={this.state.answer === '3'}/>
                  <label className="star-rating__ico" htmlFor={'star-rating-3-' + this.props.question.id} title="3 out of 5 stars">
                    {this.renderStarIcon(this.state.answer >= 3)}
                  </label>
                  <input className="star-rating__input" id={'star-rating-2-' + this.props.question.id} type="radio" onChange={(e) => this.handleChange(2)}  checked={this.state.answer === '2'}/>
                  <label className="star-rating__ico" htmlFor={'star-rating-2-' + this.props.question.id} title="2 out of 5 stars">
                    {this.renderStarIcon(this.state.answer >= 2)}
                  </label>
                  <input className="star-rating__input" id={'star-rating-1-' + this.props.question.id} type="radio" onChange={(e) => this.handleChange(1)} checked={this.state.answer === '1'}/>
                  <label className="star-rating__ico" htmlFor={'star-rating-1-' + this.props.question.id} title="1 out of 5 stars">
                    {this.renderStarIcon(this.state.answer >= 1)}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>    
      </div>  
    );
  }
}

export default RatingQuestion;
