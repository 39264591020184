import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { mediumDateFormat } from './../../helpers/Formatter';
import UpdateAttachment from './UpdateAttachment';
import { client } from '../Client';

class AttachmentsList extends Component {

  handleAttachmentDownload = (attachment) => {
    client.downloadAttachment(attachment);
  }

  handleRemoveAttachment = (attachment, event) => {
    this.props.onRemoveAttachment(attachment);
    event.stopPropagation();
  }
  
  openEditAttachmentForm = (attachment, event) => {
    this.refs.updateAttachmentDialog.show(attachment);
    event.stopPropagation();
  }
  
  editableByCurrentUser = (attachment) => {
    return attachment.author_id === client.currentUser().id
  }

  render() {
    return (
      <div>
      {
        this.props.employee.categories.map((category) => (
          <div key={'cat' + category.id} className="clearfix user-attachments">
            <h3>{category.title}</h3>
            {
              this.props.employee.attachments.map((attachment) => (
                category.id === attachment.category.id &&
                <div className="file-box" key={attachment.id}>
                  <div className="file">
                      <a onClick={() => this.handleAttachmentDownload(attachment)} title={attachment.original_filename}>
                          <span className="corner"></span>
                          <div className="icon">
                              <i className={'img-responsive fa ' + attachment.fa_classname}></i>
                          </div>
                          <div className="file-name">
                              {attachment.title}
                              <br />
                              <small>Added: {mediumDateFormat(attachment.created_at)}</small>
                              {this.editableByCurrentUser(attachment) && 
                                <span className="sdsd">
                                  <div className="pull-right"><a className="pull-right" onClick={(e) => this.handleRemoveAttachment(attachment, e)} title="Remove attachment"><FontAwesomeIcon icon={faTrashAlt} className="text-muted"/></a></div>
                                  <div className="pull-right" style={{margin: '1px 5px 0 0'}}><a className="pull-right" onClick={(e) => this.openEditAttachmentForm(attachment, e)} title="Update attachment"><FontAwesomeIcon icon={faEdit} className="text-muted" /></a></div>
                                </span>  
                              }
                              <br />
                              <small>by: {attachment.author_name}</small>
                          </div>
                      </a>
                  </div>
              </div>
                ))
            }
          </div>
        ))
      }
      <UpdateAttachment
        ref="updateAttachmentDialog"
        onUpdateAttachment={this.props.onUpdateAttachment} />
      </div>
    )
  }
}

export default AttachmentsList;
