import moment from 'moment';

class Notification {
  constructor(obj) {
    for (var prop in obj) {
      this[prop] = obj[prop];
    }
  }
    
  icon_url() {
    const url = (this.related_employee.avatar_url) ? this.related_employee.avatar_url : 'img/avatar_missing.png'
    return url
  }
  
  created_date() {
    let date = moment.parseZone(this.created_at).format('MMMM Do YYYY, h:mm:ss a');
    return date
  }
  
}

export default Notification;
