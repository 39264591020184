import React, { Component } from 'react';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import Formsy from 'formsy-react';
import { rewardTypeClient } from '../../clients/RewardTypeClient';
import { raffleClient } from '../../clients/RaffleClient';
import DateRangePickerWrapper from '../common/DateRangePickerWrapper';
import isSameDay from 'react-dates/lib/utils/isSameDay';
import isInclusivelyAfterDay from 'react-dates/lib/utils/isInclusivelyAfterDay';
import moment from 'moment';
import Input from '../form_components/Input';
import Textarea from '../form_components/Textarea';
import Select from '../form_components/Select';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { toastrNotification } from '../../helpers/Toastr';
import { Link } from 'react-router-dom';
import DepartmentsBox from './DepartmentsBox';

class CreateRaffleReward extends Component {
  
  state = {  rewardTypes: [],
             startDate: moment(),
             endDate: moment(),
             fields: {
               reward_type_id : null,
               title: 'In-House Reward Raffle',
               description: ''
             },
             loading: false,
             spinnerClass: '' }

  componentDidMount = () => {
    this.getRewardTypes();
  }
  
  getRewardTypes() {
    this.setState({ spinnerClass: 'sk-loading' })
    rewardTypeClient.getRewardTypes().
    then((reward_types) => {
      let activeRewardTypes = reward_types.filter((type) => (type.active && type.title != 'Gift Card'))
      let rewardTypes = [];
      activeRewardTypes.forEach(function(rt, i, arr) {
        rewardTypes.push({title: rt.title, value: rt.id})
      });
      this.setState({ rewardTypes: rewardTypes, spinnerClass: '' })
    });
  }
      
  saveRaffle = (data) => {
    let formData = data 
    formData['start_date'] = this.state.startDate
    formData['expiry_date'] = this.state.endDate
    formData['duration'] = this.state.endDate.diff(this.state.startDate, 'days')
    formData['raffle_type'] = 'Reward'
    Object.assign(formData, this.refs.departmentsBox.getSelectedData());
    if (formData['visibility'] == 'employees' && formData['employees_id'].length == 0) {
        toastrNotification({success: false, title: 'Please select raffle participants.'})
        return false;          
    }    
    this.setState({ loading: true })
    raffleClient.createRaffle(formData).then((raffle) => {
      if (raffle.id) {
        toastrNotification({success: true, title: 'Raffle has been created'})
        this.props.onAddRaffle(raffle)
        this.props.onCloseBtnClick()
      } else {
        toastrNotification({success: false, title: 'Unable to create raffle'})        
        setTimeout(() => {
          this.setState({ loading: false })
        }, 1500)
      }
    })
  }
              
  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }
    
  onDatesChange = ({ startDate, endDate }) => {
    const dates = { startDate, endDate }
    this.setState({ startDate, endDate })
    if (dates.startDate == null || dates.endDate == null) { 
      this.disableButton() 
    } else {
      this.enableButton()
    }
  }
  
 render() {     
    return (
      <Formsy onValidSubmit={this.saveRaffle} onValid={this.enableButton} onInvalid={this.disableButton} ref="form">
        <Modal.Header closeButton>
          <Modal.Title>Create Raffle</Modal.Title>
        </Modal.Header>
        <Modal.Body className="gray-bg">
            <div className={'wrapper wrapper-content ' + this.state.spinnerClass}>
              <div className="sk-spinner sk-spinner-double-bounce">
                <div className="sk-double-bounce1"></div>
                <div className="sk-double-bounce2"></div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Title</label>
                    <Input value={this.state.fields.title} onChange={this.onInputChange} className='form-control' name='title' required/>
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <Textarea value={this.state.fields.description} onChange={this.onInputChange} className='form-control' name='description' />
                  </div>
                  <div className="form-group">
                    <DepartmentsBox ref="departmentsBox" />
                  </div>
                  <div className="form-group">
                    <label>Reward Type</label>
                    <Select
                      name="reward_type_id"
                      options={this.state.rewardTypes}
                      placeholder="Select Reward Type"
                      className="form-control"
                      value={this.state.fields.reward_type_id || ''}
                      onChange={this.onInputChange}
                      required
                    />
                    { (this.state.rewardTypes && this.state.rewardTypes.length == 0) && 
                      <Link to="/reward_types">
                        You do not have active reward types. Please configure them.
                      </Link> }  
                  </div>
                  <div className="form-group">
                    <label>Raffle Period</label>
                    <DateRangePickerWrapper
                      ref='rafflePeriodPicker'
                      initialStartDate={moment()}
                      initialEndDate={moment()}
                      isOutsideRange={day =>
                        !isInclusivelyAfterDay(day, moment())
                      }
                      minimumNights={0}
                      readOnly
                      onDatesChange={this.onDatesChange}
                    />
                  </div>
                </div>
              </div>                
            </div>              
        </Modal.Body>
        <Modal.Footer>
            <LaddaButton disabled={!this.state.canSubmit || this.state.loading} data-style={EXPAND_LEFT} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Start Raffle</LaddaButton> 
            <button type="button" className="btn btn-default btn-sm" onClick={() => this.props.onCloseBtnClick()}>Close</button>
        </Modal.Footer>
      </Formsy>
    );
  }
}

export default CreateRaffleReward;
