import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { client } from '../Client';
import { groupClient } from '../../clients/GroupClient';
import debounce from 'lodash/debounce';
import { AsyncTypeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Loader.css';
import 'react-bootstrap-typeahead/css/Token.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import NumericInput from 'react-numeric-input';

import Config from 'config';

class MultipleGroupLimitsSelector extends Component {

  state = {
    isLoading: false,
    options: [],
    groups: [],
    query: '',
  }
  
  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 300);
  }
  
  cache = {}

  componentDidMount = () => {
    this.setState({groups: this.props.selectedGroups});
  }

  handleChange = (selectedGroups) => {
    if (selectedGroups.length == 1) {        
      let groups = this.state.groups;
      groups.push(selectedGroups[0]);
      groups = groups.filter((x, i, a) => a.indexOf(x) == i); // unique groups
      this.setState({groups: groups});
      if (Array.isArray(groups) && groups.length) {
        setTimeout(() => {this.clearTypeahead()}, 100);        
        this.props.selectGroups(groups);  
        this.props.addLimitedGroup(selectedGroups[0], this.getLimitedGroupPoints(selectedGroups[0].id))            
      }
    }
  }
  
  handleInputChange = (query) => {
    this.setState({query});
  }

  handlePointsLimitChange = (group, limit) => {
    this.props.updateLimitedGroups(group, limit);
  }

  handleDeletePointsLimit = (group) => {
    let groups = this.state.groups;
    groups = groups.filter(x => x.id !== group.id); // unique groups
    this.setState({groups: groups});
    this.props.selectGroups(groups);      
  }

  handlePagination = (e) => {  
    const {query} = this.state;
    const cachedQuery = this.cache[query];

    // Don't make another request if:
    // - we've already fetched all possible results
    if (
      cachedQuery.groups.length === cachedQuery.totalCount
    ) {
      return;
    }

    this.setState({ isLoading: true });

    const page = cachedQuery.page + 1;
    
    groupClient.getPaginatedGroups(page, query)
    .then((resp) => {
       let newGroups = resp.departments.map(department => {
         let dep = {};
         dep.title = department.title;
         dep.id = department.id;
         return dep;
       });
       const groups = cachedQuery.groups.concat(newGroups);
       this.cache[query] = {...cachedQuery, groups, page};
       this.setState({
         isLoading: false,
         options: groups
       });
    });
  }

  handleSearch = (query) => {
    if (this.cache[query]) {
      this.setState({options: this.removeSelectedGroupsFromSearch(this.cache[query].groups)});
      return;
    }
    this.setState({ isLoading: true });    
    groupClient.getPaginatedGroups(1, query).then((resp) => {
       let groups = resp.departments.map(department => {
         let dep = {};
         dep.title = department.title;
         dep.id = department.id;
         return dep;
       });
       this.cache[query] = {totalCount: resp.totalCount, groups, page: 1};
       this.setState({
         isLoading: false,
         options: this.removeSelectedGroupsFromSearch(groups)
       });
    });
  }    
  
  removeSelectedGroupsFromSearch = (groups) => {
    return groups.filter((x) => !this.props.selectedGroups.find((y) => (y.id == x.id)))
  }
  
  
  
  renderMenuItemChildren(option, props, index) {
    return (
      <div key={option.id}>
        <span>{option.title}</span>
      </div>
    );
  }

 clearTypeahead() {
   const typeahead = this.refs.typeahead.getInstance();
   typeahead.clear();
   typeahead.focus();
 }
 
 getLimitedGroupPoints(group_id) {
   const lg = this.props.limitedGroups.find(x => x.id === group_id);
   if (lg) {
     return lg.points_limit;
   } else {
     return this.props.pointsLimit || 100;
   }
 }

  render() {  
        
    return (
      <div>
        <table className="table">
          <tbody>
            {this.props.selectedGroups.map((group) => 
              <tr key={'sel-gr-' + group.id}>
                <td>
                  <AsyncTypeahead key={'at-' + group.id}
                    {...this.state}
                    defaultSelected={[group]}
                    multiple={false}
                    allowNew={false}
                    labelKey="title"
                    onSearch={this.handleSearch}
                    onChange={this.handleChange}
                    onPaginate={this.handlePagination}
                    onInputChange={this.handleInputChange}
                    placeholder={this.props.placeholder}
                    renderMenuItemChildren={this.renderMenuItemChildren}
                    minLength={2}
                    maxResults={5}
                    paginate
                    useCache={false}
                    emptyLabel={false}
                  />              
                </td>
                <td>
                  <NumericInput min={0} value={this.getLimitedGroupPoints(group.id)} onChange={(val) => this.handlePointsLimitChange(group, val)} className="form-control"/>
                  <button className="btn btn-white btn-sm pull-right" onClick={() => this.handleDeletePointsLimit(group)}>
                      <FontAwesomeIcon icon={faTimes}/> Delete
                  </button>
                </td>
              </tr>
            )}
            
            <tr>
              <td>
                <AsyncTypeahead
                  ref='typeahead'
                  {...this.state}
                  defaultSelected={[]}
                  multiple={false}
                  allowNew={false}
                  labelKey="title"
                  onSearch={this.handleSearch}
                  onChange={this.handleChange}
                  onPaginate={this.handlePagination}
                  onInputChange={this.handleInputChange}
                  placeholder={this.props.placeholder}
                  renderMenuItemChildren={this.renderMenuItemChildren}
                  minLength={2}
                  maxResults={5}
                  paginate
                  useCache={false}
                  emptyLabel={false}
                  clearButton={true}
                />              
              </td>
              <td>                
              </td>
            </tr>            
          </tbody>       
        </table>
      </div>
    )
  }
}

export default MultipleGroupLimitsSelector;
