import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import RawHtml from "react-raw-html";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import Acknowledgement from '../models/Acknowledgement';
import { toastrNotification } from '../helpers/Toastr';
import SweetAlert from 'sweetalert-react';
import { acknowledgementClient } from '../clients/AcknowledgementClient';
import moment from 'moment';
import ShowRespondedEmployeesModal from '../components/acknowledgements/ShowRespondedEmployeesModal';
import { client } from '../components/Client';
import EditAcknowledgementForm from '../components/acknowledgements/EditAcknowledgementForm';

class AcknowledgementView extends Component {

    state = {
      acknowledgement: new Acknowledgement({ responded_employees: [], not_responded_employees: [] }),
      spinnerClass: 'sk-loading',
      showDeleteNotification: false,
      shouldRedirect: false,
      reportButtonDisabled: false,
      showUnableToEditNotification: false
    }

    componentDidMount() {
      this.setState(this.state);
      this.getAcknowledgement();
    }

    getAcknowledgement = () => {
      acknowledgementClient.getAcknowledgement(this.props.match.params.id).then((acknowledgement) => {
        this.setState({ acknowledgement: acknowledgement, spinnerClass: '' })
      });
    }
    
    handleDeleteAcknowledgementClick = () => {
      this.setState({ showDeleteNotification: true });
    }
    
    handleAcknowledgementAttachmentDownload = (acknowledgement) => {
      acknowledgementClient.downloadAcknowledgementAttachment(acknowledgement);      
    }
    
    openEditModal = () => {
      this.refs.editAcknowledgementForm.open()
    }
    
    showUnableToEditNotification = () => {
      this.setState({ showUnableToEditNotification: true })
    }
    
    deleteAcknowledgement() {
      this.setState({ showDeleteNotification: false });
      acknowledgementClient.deleteAcknowledgement(this.state.acknowledgement.id).then((response) => {
        if (response.success == true) {
          toastrNotification({ success: true, message: 'Acknowledgement was successfully deleted.' })
          this.setState({ shouldRedirect: true });
        } else {
          toastrNotification({ success: false, message: 'Unable to delete acknowledgement.' });
          this.getAcknowledgement();
        }
      });
    }
    
    showRespondedEmployeesModal = (ev, employees, title) => {
      ev.preventDefault();
      this.refs.respondedEmployeesModal.open(employees, title)
    }
    
    submitReport = () => {
      const reportData = { start_date: this.state.acknowledgement.created_at,
                           end_date: this.state.acknowledgement.expires_at,
                           acknowledgment_id: this.state.acknowledgement.id }
      this.setState({ reportButtonDisabled: true });
      client.createStoredReport(reportData, 'AcknowledgementStoredReport').then((resp) => {
        if (resp.success) {
          window.ezzely_download(resp.s3_url);
        } else {
          toastrNotification({success: false, message: ('Unable to generate report: ' + resp.message) })
        };
        this.setState({ reportButtonDisabled: false })
      })
    }  

    render() {
        let acknowledgement = this.state.acknowledgement;
      
        if (this.state.shouldRedirect) {
          return (
            <Redirect to="/acknowledgements" />
          );
        } else {
        return (
            <div className={'wrapper wrapper-content' + this.state.spinnerClass}>
                <div className="sk-spinner sk-spinner-double-bounce">
                  <div className="sk-double-bounce1"></div>
                  <div className="sk-double-bounce2"></div>
                </div>
                
                <div className="row">
                  <div className="col-sm-6 col-sm-offset-3">
                    <Link to="/acknowledgements" className="btn btn-white btn-sm pull-right m-b-sm hidden-xs">Back to Acknowledgements</Link>
                    <div className="ibox">
                      <div className="ibox-title">
                        <h5>Acknowledgement Details</h5>
                        <div className="ibox-tools">
                          <button className="btn btn-primary btn-sm pull-right" disabled={this.state.reportButtonDisabled} onClick={() => this.submitReport()}><FontAwesomeIcon icon={faDownload}/> Download Report</button>
                        </div>  
                      </div>
                      <div className="ibox-content">
                        <div className="row">
                          <div className="col-sm-12">
                            <dl className="dl-horizontal">
                              <dt>Title:</dt>
                              <dd>{ acknowledgement.title }</dd>
                              <dt>Group:</dt>
                              <dd>{ acknowledgement.department ? acknowledgement.department : "All Groups" }</dd>
                              <dt>Message:</dt>
                              <dd><RawHtml.div>{ acknowledgement.message }</RawHtml.div></dd>
                              { acknowledgement.original_filename &&
                                <div>
                                  <dt>Attachment:</dt>
                                  <dd>
                                    <a onClick={() => this.handleAcknowledgementAttachmentDownload(acknowledgement)} title={acknowledgement.original_filename}>
                                      {acknowledgement.original_filename}
                                    </a>
                                  </dd>
                                </div>
                              }
                              <dt>Created at:</dt>
                              <dd>{ moment(acknowledgement.created_at).format('D MMM YYYY') }</dd>
                              <dt>Expires at:</dt>
                              <dd>{ moment(acknowledgement.expires_at).format('D MMM YYYY') }</dd>
                              <dt>Sent to:</dt>
                              <dd>{ acknowledgement.sent_to_size } employees</dd>
                              <dt>Response:</dt>
                              <dd>
                                 Received (<a href="#" onClick={(ev) => this.showRespondedEmployeesModal(ev, acknowledgement.responded_employees, "Responded Employees")}>{acknowledgement.responded_employees.length}</a>), 
                                 Not Received (<a href="#" onClick={(ev) => this.showRespondedEmployeesModal(ev, acknowledgement.not_responded_employees, "Not Responded Employees")}>{acknowledgement.not_responded_employees.length}</a>)
                              </dd>
                              <ShowRespondedEmployeesModal ref="respondedEmployeesModal"/>
                            </dl>
                          </div>
                        </div>                         
                        <div className="row m-b-sm">
                          <div className="col-sm-12">
                            <br/>
                              {acknowledgement.can_be_updated &&
                                <button className="btn btn-white m-r-sm" onClick={() => this.openEditModal()} >
                                  <FontAwesomeIcon icon={faPencilAlt}/> Edit
                                </button>
                              }
                              {acknowledgement.can_be_deleted &&
                                <button className="btn btn-white btn-smallscreen-right" onClick={() => this.handleDeleteAcknowledgementClick()}><FontAwesomeIcon icon={faTimes}/> Delete</button>
                              }
                              <SweetAlert
                                show={this.state.showDeleteNotification}
                                type="warning"
                                title="Are you sure?"
                                confirmButtonColor="#DD6B55"
                                showCancelButton
                                text="This operation can not be undone."
                                onConfirm={() => this.deleteAcknowledgement()}
                                onCancel={() => this.setState({ showDeleteNotification: false })}
                              />
                              <EditAcknowledgementForm 
                                ref="editAcknowledgementForm"
                                onUpdateAcknowledgement={this.getAcknowledgement}
                                acknowledgement={acknowledgement}
                                showUnableToEditNotification={this.showUnableToEditNotification}
                              />
                              <SweetAlert
                                show={this.state.showUnableToEditNotification}
                                type="warning"
                                title="Unable to edit acknowledgement."
                                confirmButtonColor="#DD6B55"
                                text="There are employees have responded to this acknowledgement."
                                onConfirm={() => this.setState({ showUnableToEditNotification: false })}
                              />
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>  
                </div> 
            </div>
        )
      }
    }
}

export default AcknowledgementView
