import React, { Component } from 'react';
import RadioImg from '../common/RadioImg';

import './SurveyQuestions.css'

class ChoiceQuestion extends Component {
  
state = {
  answer: ''
}; 

getAnswer = () => {
  return this.state.answer
}

buildAnswerHash = () => {
  return { survey_question_id: this.props.question.id, data: this.state.answer }
}

 buildOptions = () => {
   let options = [];
   let pnCls = 'radio-img-choice-options', pnClsSel = pnCls + ' sel';
   this.props.question.values.map((choice) => {
     let choice_image_name = choice.replace(/\s/g, '');
     let variant = {
       btnCls: pnCls,
       btnSelCls: pnClsSel,
       val: choice,
       label: choice,
       img: `img/${choice_image_name}.png`
     };
     options.push(variant);
   });
   return options;
 } 
 
 handleChange = (event) => {
   this.setState({ answer: event.target.value });
   this.props.updateCurrentAnswer(event.target.value);
 } 
    
 render() {
    //let offsetClass = (this.props.question.values.length == 5) ? ' col-md-1 col-sm-1 col-xs-1' : 'col-md-4 col-sm-4 col-xs-4'
       
    return (
      <div className="row">
        <div className="col-md-12">
          <h3 className="text-center">{this.props.question.question}</h3>
          <div style={{margin: '30px 0'}}>
            <div className='radio-options text-center'>
              <RadioImg 
                options={this.buildOptions()} 
                onChange={(e) => {this.handleChange(e)}}
                showOulineLabel={true}
              />
            </div>
          </div>    
        </div>  
      </div>
    );
  }
}

export default ChoiceQuestion;
