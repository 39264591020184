import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import FormField from '../../components/common/FormField';

class EmergencyFields extends Component {

  state = {
    editMode:false,
    fields: {}
  }

  openEditForm = () => {
    if (this.props.onChangeMode) this.props.onChangeMode(true);
    this.setState({editMode: true,
      fields: {
        emergency_contact:   this.props.employee.emergency_contact || '',
        relationship: this.props.employee.relationship || '',
        emergency_phone: this.props.employee.emergency_phone || ''
      }
    });
  }

  closeEditForm = () => {
    if (this.props.onChangeMode) this.props.onChangeMode(false);
    this.setState({editMode: false});
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    this.props.onFormSubmit(this.state.fields);
    this.closeEditForm();
  }

  render() {
      return (
        <div className="form-horizontal">
          <ul className="list-unstyled">
            <br/>
            <FormField label="Contact Name">
              { this.state.editMode ?
                <input type='text' value={this.state.fields.emergency_contact} onChange={this.onInputChange} className='form-control' name='emergency_contact'/>
              :
                <div className="details-item-text">{this.props.employee.emergency_contact || '-'}</div>
              }
            </FormField>
            <FormField label="Relationship to Employee">
              { this.state.editMode ?
                <input type='text' value={this.state.fields.relationship} onChange={this.onInputChange} className='form-control' name='relationship'/>
              :
                <div className="details-item-text">{this.props.employee.relationship || '-'}</div>
              }
            </FormField>
            <FormField label="Phone">
              { this.state.editMode ?
              <input type='text' value={this.state.fields.emergency_phone} onChange={this.onInputChange} className='form-control' name='emergency_phone'/>
              :
              <div className="details-item-text">{ this.props.employee.emergency_phone ? <span><FontAwesomeIcon icon={faPhone}/>&nbsp;{this.props.employee.emergency_phone} </span> : '-'}</div>
              }
            </FormField>
          </ul>
          { this.state.editMode &&
          <div className="modal-footer">
            <button onClick={this.handleSubmit} className="btn btn-sm btn-primary no-margins">Save</button>
            <button onClick={this.closeEditForm} className="btn btn-sm btn-default">Cancel</button>
          </div>
          }
        </div>
      )
    }
}

export default EmergencyFields;
