import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { toastrNotification } from '../../helpers/Toastr';
import DateField from '../form_components/DateField';
import Select from '../form_components/Select';
import { Modal, Button } from 'react-bootstrap';
import { currencyFormat, diffDays } from '../../helpers/Formatter';
import moment from 'moment';
import { groupClient } from '../../clients/GroupClient';
import { client } from '../Client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';

class EmployeeBenefitReportForm extends Component {

  state = {
    showModal: false,
    departments: [],
    errorMessage: "",
    fields: {
      start_date: null,
      end_date: null,
      university_department_id: ''
    }
  };

  componentDidMount = () => {
    groupClient.getGroupsList().then((departments) => {
      const depts = departments.map(dep => ({value: dep.id, title: dep.title}));
      this.setState({ departments: depts });
    });
  }

  close = () => {
    this.setState({ showModal: false});
  }

  open = () => {
    this.setState({ showModal: true, fields: {start_date: null, end_date: null, university_department_id: ''}});
  }
  
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }  

  onDateChange = (field, date) => {
    const fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields: fields });
  }   

  canSubmit = () => {
    return this.state.fields['start_date'] && this.state.fields['end_date']
  }
  
  formSubmit = () => {
    const a = this.state.fields['start_date'];
    const b = this.state.fields['end_date'];
    if(a > b) {
      this.setState({errorMessage: "End date should be later than start date"})
      return;
    }
    if(diffDays(a, b) > 366) {
      this.setState({errorMessage: "Please don't choose an interval longer than 1 year"})
      return;      
    }
    client.createStoredReport(this.state.fields, 'BenefitStoredReport').then((resp) => {
      if (resp.success) {
        window.ezzely_download(resp.s3_url);
        this.close();
      } else {
        toastrNotification({success: false, message: ('Unable to generate report: ' + resp.message) })
      }
    })
  }  
  
  render() {
    let dateFormat = require('dateformat');
    return (
      <div>
        <a onClick={this.open}><FontAwesomeIcon icon={faDownload} /> Employee Incentives</a>
        <Modal show={this.state.showModal} onHide={this.close}>
          <Formsy onValidSubmit={this.formSubmit} ref="form" className="form-horizontal">
            <Modal.Body>
              {this.state.errorMessage.length > 0 && 
              <div className="alert alert-danger">
                {this.state.errorMessage}
              </div>
              }            
              <h3 className="m-b">Employee Monetary Benefit Report</h3>
                <div className="row">
                  <div className="col-sm-12">
                    <DateField name="start_date" className="form-control" placeholder="Start Date"
                     onChange={(date) => this.onDateChange('start_date', date)} />
                  </div>
                </div>
                <br/>
                <div className="row">
                  <div className="col-sm-12">
                    <DateField name="end_date" className="form-control" placeholder="End Date" 
                    onChange={(date) => this.onDateChange('end_date', date)} />
                  </div>
                </div>
                <br/>
                <div className="row">
                  <div className="col-sm-12">
                    <Select
                      value={this.state.fields.university_department_id}
                      onChange={this.onInputChange}
                      options={this.state.departments}
                      className="form-control"
                      name='university_department_id'
                      placeholder="Select group *"
                    />
                  </div>
                </div>
                <br/>
            </Modal.Body>
            <Modal.Footer>
              <Button bsClass="btn btn-primary" type="submit" disabled={!this.canSubmit()}>Download Report</Button>
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
          </Formsy>
        </Modal>
      </div>
    )
  }
}
export default EmployeeBenefitReportForm;
