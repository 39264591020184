import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Modal, Button, OverlayTrigger} from 'react-bootstrap';
import Input from '../form_components/Input'
import { toastrNotification } from './../../helpers/Toastr';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { client } from '../Client';

class RequestCourse extends Component {

  state = {
    showModal: false,
    code: '',
    loading: false
  };

  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  onCodeChange = (evt) => {
    this.setState({code: evt.target.value});
  }

  formSubmit = (data) => {
    this.executeRequestCourse(data)
  }

  requestCourseOrEnterCode = () => {
    if (this.props.course.code_required) {
      this.open();
    } else {
      this.executeRequestCourse()
    }
  }

  executeRequestCourse = (opts = {}) => {
    this.disableButton();
    this.setState({loading: true});
    client.requestCourse(this.props.course.id, opts).then((data) => {
      this.props.onFinishRequest();
      this.close();
      if (data.success) {
        toastrNotification({success: true, title: data.message});
      } else {
        toastrNotification({success: false, title: data.message});
      }
      this.setState({loading: false});
    })
  }

  canSubmit = () => {
    return (this.state.category !== null && this.state.title !== null && this.state.file !== null);
  }

 render() {

    return (
      <div>
      { !this.props.course.license &&
        <LaddaButton data-style={EXPAND_LEFT} loading={this.state.loading} onClick={this.requestCourseOrEnterCode} className={'ladda-button btn btn-sm btn-primary btn-block'}>Begin Course</LaddaButton>
      }
        <Modal show={this.state.showModal} onHide={this.close}>
          <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal">
            <Modal.Body>
              <h3 className="m-b">Enter Validation Code</h3>
              <div className="form-group">
                <label htmlFor="code" className="sr-only"></label>
                <Input name="code" className="form-control" placeholder="Validation Code *" value={this.state.code} onChange={this.onCodeChange} required />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button bsClass="btn btn-primary" type="submit" disabled={!this.state.canSubmit}>Submit</Button>
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
          </Formsy>
        </Modal>
      </div>
    );
  }
}

export default RequestCourse;
