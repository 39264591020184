import React, { Component } from 'react';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import ActivityTextBox from './ActivityPostForm/ActivityTextBox';
import { Button } from 'react-bootstrap';
import { client } from '../Client';

class EditCommentModal extends Component {

  state = {
    showModal: false,
    comment: null
  }
  
  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.comment !== this.state.comment && this.state.comment && this.refs.editor) {
      this.refs.editor.loadContent(this.state.comment.message);
    }  
  }

  close = () => {
    this.setState({ showModal: false });
  }
  
  open = (comment) => {
    this.setState({ comment: comment, showModal: true })
  } 
  
  canSubmit = () => {
    return !!this.state.comment
  }
      
  updateComment() {
    const commentId = this.state.comment.id;
    const content = this.refs.editor.getContent();
    this.props.channel.perform('update_activity_post_comment', { comment_id: commentId, message: content });
    this.refs.editor.clear();
    this.close();
  }
    
  
  render() {
    const { commment } = this.state;
    
    return (
      <Modal show={this.state.showModal} onHide={this.close}  dialogClassName="edit-comment-modal">
          <Modal.Header closeButton> 
            <Modal.Title>Edit Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="social-comment">
                    <div className="media-body editor comment-editor comment-modal-editor">
                      <ActivityTextBox               
                        ref="editor"
                      />
                    </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button disabled={!this.state.comment} onClick={() => this.updateComment()} className={'btn btn-primary'}>Update</button>
            <button onClick={this.close} className="btn btn-default">Close</button>
          </Modal.Footer>
      </Modal>
    );
  }
}

export default EditCommentModal;
