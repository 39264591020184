import React, { Component } from 'react';

class FormField extends Component {

  render() {
    return (
      <li className="form-group">
        <div className="col-sm-3 col-xs-4 control-label">{this.props.label}</div>
        <div className="col-sm-9 col-xs-8">
          {this.props.children}
        </div>
      </li>
    )
  }
}

export default FormField;
