import React, { Component } from 'react';

class FeedbackQuestion extends Component {
  
state = {
  answer: ''
}; 

getAnswer = () => {
  return this.state.answer
}

buildAnswerHash = () => {
  return { survey_question_id: this.props.question.id, data: this.state.answer }
}

handleChange = (event) => {
  this.setState({ answer: event.target.value });
  this.props.updateCurrentAnswer(event.target.value);
} 
  
 render() {
       
    return (
      <div className="row">
        <div className="col-md-12">
          <h3 className="text-center">{this.props.question.question}</h3>
          <div style={{margin: '30px 0'}}>
            <div>
              <textarea
                style={{width: '100%'}}
                rows={5}
                placeholder="Type your answer here..."
                onChange={(e) => {this.handleChange(e)}}
              />
            </div>
          </div>    
        </div>
      </div>  
    );
  }
}

export default FeedbackQuestion;
