import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { client } from '../../components/Client';


class ResetPasswordForm extends Component {
  state = {
    invalid: false,    
    message: '',
    success: false
  };

  componentDidMount() {
    client.checkResetPasswordCode(this.props.code).then((response) => {
      if(!response.success) {
        this.setState({expired: true})
      };
    });    
  }  
  
  onFormSubmit = (evt) => {
    evt.preventDefault();
    if (this.refs.password.value.length < 6) {
      this.setState({invalid: true, message: 'Password is too short (minimum is 6 characters).'});
      return;
    } 
    if (this.refs.password.value !== this.refs.confirmation.value) {
      this.setState({invalid: true, message: "The password and confirmation don't match."});
      return;
    }
    client.resetPassword(this.refs.password.value, this.props.code).then((json) => {
      if(json.success) {        
        this.setState({success: true, invalid: false});
      } else {
        this.setState({invalid: true, message: json.message});
      };
    });
  };
  
  showForgotPasswordForm = () => {
    this.props.onForgotPassword();
  }
  
  renderResetForm = () => {
    if (this.state.expired) {
      return (  
        <div className="form-horizontal">
          <h3>
            <p className="text-center">Your confirmation code is expired or invalid.</p>
          </h3>
          <p className="text-center">Go back to <Link to={'/login'}>Sign-in</Link> page.</p>
        </div>      
      )
    } else {
      if (this.state.success) {
        return (          
          <div className="form-horizontal">
            <h3><p className="text-center">You've successfully changed the password!</p></h3><br/>
            <div className="text-center"><Link className="btn btn-sm btn-primary" to={'/activity'}>Sign in</Link></div>
          </div>
        )
      } else {        
        return (
          <form className="form-horizontal" onSubmit={this.onFormSubmit}>
            <h3 className="text-center"><p>Enter your new password twice</p></h3>
            <div className="form-group">
              <div className="corenderResetForml-lg-8 col-lg-offset-2">
                <input type="password" placeholder="Password" className="form-control" ref="password" required />
                <span className="help-block m-b-none"></span>
              </div>
            </div>
            <div className="form-group">
              <div className="col-lg-8 col-lg-offset-2">
                <input type="password" placeholder="Password Confirmation" className="form-control" ref="confirmation" required />
              </div>
            </div>
            <div className="form-group">
              <div className="col-lg-8 col-lg-offset-2">
                <button className="btn btn-sm btn-primary" type="submit">Submit</button> &nbsp; <Link to={'/login'}>Cancel</Link>
              </div>
            </div>
          </form>              
        )
      }      
    }        
  }
  
  render() {
    return (
      <div>              
        { this.state.invalid &&
          <div className="alert alert-danger" style={{borderTopLeftRadius:'inherit',borderTopRightRadius:'inherit', marginTop:'0'}}>
            {this.state.message}
          </div>
        }
        <div className="ibox-title">
          <img src="img/logo2.png" height="42"/>
        </div>
        <div className="ibox-content">
          { this.renderResetForm() }
        </div>                
      </div>
    )
  }
}

export default ResetPasswordForm;