// @flow
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { currencyFormat } from '../../helpers/Formatter';
class TransactionList extends Component {

  render() {
    let dateFormat = require('dateformat');
    return (
      <div className="full-height-scroll">
        <div className="table-responsive">
          <table className="table table-striped table-hover">
            <tbody>
              {
                this.props.transactions.map((transaction) => (
                  <tr key={transaction.id}>
                    <td>{transaction.name} <div><i>{transaction.description}</i></div></td>
                    <td>{transaction.operation_type}</td>
                    <td>{dateFormat(transaction.date, "mediumDate")}</td>
                    <td className={transaction.total < 0 ? 'balance-negative' : 'balance-positive'}>{currencyFormat(transaction.total)}</td>
                    <td className={transaction.balance < 0 ? 'balance-negative' : 'balance-positive'}>{currencyFormat(transaction.balance)}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        {this.props.transactions.length > 0 && this.props.pageCount && this.props.pageCount > 1 && this.props.handlePageChange &&
          <div className="row">
            <div className="col-md-12 text-center">
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.props.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.props.handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                pageClassName={'paginate_button'}
              />
            </div>
          </div>
        }
      </div>
    )
  }
}
export default TransactionList;
