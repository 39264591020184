import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';
import RewardType from '../models/RewardType';

class RouletteClient {
  
  getRoulettes() {
    return client.callApi(Config.API_BASE + '/roulettes.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }     
    
  createRoulette(rouletteData) {
    return client.callApi(Config.API_BASE + '/roulettes.json', {}, 'POST', {roulette: rouletteData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }

  updateRoulette(rouletteId, rouletteData) {
    return client.callApi(Config.API_BASE + '/roulettes/' + rouletteId + '.json', {}, 'PUT', {roulette: rouletteData})
    .catch((error) => {
      client.handleApiError(error);
      return {};
    });        
  }
  
  setWinner(rouletteId, winnerId) {
    return client.callApi(Config.API_BASE + '/roulettes/' + rouletteId + '/set_winner.json', {}, 'POST', {winner_id: winnerId})
    .catch((error) => {
      client.handleApiError(error);
      return {};
    });            
  }      
  
  deleteRoulette(rouletteId) {
    return client.callApi(Config.API_BASE + '/roulettes/' + rouletteId + '.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }    

  getRouletteRecords(rouletteId) {
    return client.callApi(Config.API_BASE + '/roulettes/' + rouletteId + '/records.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }         
  
  getRouletteRecord(code) {
    return client.callApi(Config.API_BASE + '/roulette_records/' + code + '.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }         
  
  getRouletteRecording(code) {
    return client.callApi(Config.API_BASE + '/roulette_records/' + code + '/recording.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }         
  
  updateRouletteRecord(code, rewardTypeId) {
    return client.callApi(Config.API_BASE + '/roulette_records/' + code + '.json', {}, 'PUT', {reward_type_id: rewardTypeId})
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }     
          
}

export const rouletteClient = new RouletteClient();
