import React from "react";
import { withRouter } from "react-router-dom";
import { reelsClient } from "../clients/ReelsClient";
import { client } from "../components/Client";
import CommentBottomSheet from "../components/reels/CommentBottomSheet";
import Inforamtion from "../components/reels/Information";
import Menu from "../components/reels/Menu";
import ReelReport from "../components/reels/ReelReport";
import { SwipeDetector } from "../helpers/Gestures";
import { toastrNotification } from "../helpers/Toastr";
import SweetAlert from 'sweetalert-react';
import "./Reel.css";
class Reel extends React.Component {
  state = {
    reel: null,
    comments: [],
    videoEnded: false,
    showDeleteWarningModal: false,
    canDelete: false,
  };

  constructor(props) {
    super(props);
    this.subscription = null;
    this.channelName = "ReelChannel";
    this.commentSheetRef = React.createRef();
    this.menuSheetRef = React.createRef();
    this.informationSheetRef = React.createRef();
    this.reportSheetRef = React.createRef();
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      reelsClient.getReel(id).then((reel) => {
        this.setState({ reel, comments: reel.comments }, () => {
          const canDelete = (reel?.employee.id === client.currentUser()?.id) || client.adminLoggedIn();
          this.setState({ canDelete });
        });

        const listener = this.actionCableReelsListener();
        const prevSubscription = this.subscription;
        prevSubscription?.unsubscribe();
        this.subscription = window.cable.subscriptions.create(
          { channel: this.channelName },
          listener
        );
      });
    }
  }

  actionCableReelsListener = () => {
    let _this = this;
    let listener = {
      received(data) {
        if (!client.isLoggedIn()) {
          window.location.hash = "/login";
          return;
        }

        if (data.status === "success" && data.reel_id === _this.state.reel.id) {
          let reel;
          switch (data.action) {
            case "reel_like":
            case "reel_unlike":
              reel = _this.state.reel;
              reel.likesNumber = data.total_likes;
              if (data.by.id == client.currentUser()?.id)
                reel.liked_by_me = data.is_liked;
              _this.setState({ reel });
              break;

            case "reel_new_comment":
              reel = _this.state.reel;
              reel.total_comments = data.total;
              _this.setState({ reel });
              _this.getComments();

            default:
              break;
          }
        }
        console.log("received, channel:" + _this.channelName, data);
      },
    };
    return listener;
  };

  getComments = () => {
    reelsClient
      .getComments(this.state.reel?.id)
      .then((comments) => this.setState({ comments: comments.comments }));
  };

  likeReel = () => {
    this.subscription?.perform("like_reel", { reel_id: this.state.reel?.id });
  };

  unLikeReel = () => {
    this.subscription?.perform("unlike_reel", { reel_id: this.state.reel?.id });
  };

  likeClicked = () => {
    const reel = this.state.reel;
    if (reel) {
      if (reel.liked_by_me) this.unLikeReel();
      else this.likeReel();
    }
  };

  createReelComment = (comment) => {
    this.subscription?.perform("create_reel_comment", {
      reel_id: this.state.reel?.id,
      comment,
    });
  };

  deleteReelComment = (comment_id) => {
    this.subscription?.perform("delete_reel_comment", {
      reel_id: this.state.reel?.id,
      comment_id,
    });
  };

  createReel = () => {
    this.props.history.push("/activity#new_reel");
  };

  openInformationSheet = () => {
    this.informationSheetRef.current?.openSheet();
  };

  openReportSheet = () => {
    this.reportSheetRef.current?.openSheet();
  };

  toggleVideoEnded = () => {
    this.setState({
      videoEnded: !this.state.videoEnded,
    });
  };

  playVideo = () => {
    this.toggleVideoEnded();
    this.videoRef.current?.play();
  };

  deleteReel = () => {
    this.setState({ showDeleteWarningModal: false }, () => {
      const reponse = this.subscription?.perform("remove_reel", {
        reel_id: this.state.reel?.id
      });
      if (reponse) {
        toastrNotification({ success: true, message: 'Xtra deleted.' });
        this.props.history.push('/activity');
      }
      else toastrNotification({ success: false, message: 'Failed to delete Xtra.' });
    })
  }

  promptDeleteWarningModal = () => {
    this.setState({ showDeleteWarningModal: true });
  }

  render() {
    return (
      <div className="wrapper wrapper-content animated fadeIn">
        <SwipeDetector preventScrollOnSwipe={true} delta={150}
          onSwipedUp={e => (this.state.reel?.next && this.props.history.push(`/reel/${this.state.reel?.next}`))}
          onSwipedDown={e => (this.state.reel?.previous && this.props.history.push(`/reel/${this.state.reel?.previous}`))}>

          <div className="main-single-reel">
            {this.state.reel?.url && (
              <video
                ref={this.videoRef}
                autoPlay
                playsInline
                preload="metadata"
                poster={this.state.reel.thumb_url}
                onEnded={() => this.toggleVideoEnded()}
              >
                <source src={this.state.reel.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            {this.state.videoEnded && (
              <div className="video-controls">
                <div className="play-button" onClick={() => this.playVideo()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                  </svg>
                </div>
              </div>
            )}
            <div className="reel-details">
              <p>
                {this.state.reel?.title && <span>{this.state.reel.title}</span>}
                {this.state.reel?.description}
              </p>
              {this.state.reel?.employee && (
                <div className="reel-author">
                  <div className="avatar">
                    <img
                      className="img-circle"
                      src={this.state.reel?.employee.avatar_url}
                    />
                  </div>
                  <div className="name">
                    {this.state.reel?.employee.full_name}
                  </div>
                </div>
              )}
              <button className="create-button" onClick={() => this.createReel()}>
                Create your own Xtra
              </button>
            </div>
            <div className="logos">
              <div
                className="svg-img"
              >
                <svg
                  onClick={() => this.menuSheetRef.current?.openSheet()}
                  viewBox="0 0 256 256"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M199.111 128.555C199.111 112.846 211.846 100.111 227.555 100.111C243.265 100.111 256 112.846 256 128.555C256 144.265 243.265 157 227.555 157C211.846 157 199.111 144.265 199.111 128.555Z"
                    fill="white"
                  />
                  <path
                    d="M99.556 128.555C99.556 112.846 112.291 100.111 128.001 100.111C143.71 100.111 156.445 112.846 156.445 128.555C156.445 144.265 143.71 157 128.001 157C112.291 157 99.556 144.265 99.556 128.555Z"
                    fill="white"
                  />
                  <path
                    d="M-4.54372e-05 128.555C-4.52498e-05 112.846 12.7351 100.111 28.4446 100.111C44.1542 100.111 56.8893 112.846 56.8893 128.555C56.8893 144.265 44.1542 157 28.4446 157C12.7351 157 -4.56245e-05 144.265 -4.54372e-05 128.555Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div
                className={
                  "svg-img" + (this.state.reel?.liked_by_me ? " selected" : "")
                }
              >
                <svg
                  onClick={() => this.likeClicked()}
                  viewBox="0 0 256 256"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M26.6665 111.667C11.968 111.667 0 123.635 0 138.334V223.667C0 238.366 11.968 250.334 26.6665 250.334H58.6665C64.672 250.334 70.197 248.307 74.6665 244.936V111.667H26.6665Z" />
                  <path d="M256 151.667C256 145.256 253.461 139.283 249.056 134.877C254.037 129.427 256.576 122.12 255.882 114.515C254.634 100.957 242.421 90.333 228.064 90.333H162.176C165.44 80.4235 170.666 62.2585 170.666 47.6665C170.666 24.5305 151.008 5 138.666 5C127.584 5 119.669 11.24 119.328 11.496C118.069 12.5095 117.333 14.0455 117.333 15.6665V51.837L86.6134 118.387L85.3334 119.037V233.363C94.0159 237.459 105.002 239.667 112 239.667H209.909C221.525 239.667 231.691 231.837 234.08 221.032C235.306 215.475 234.592 209.875 232.149 205.011C240.032 201.043 245.333 192.925 245.333 183.667C245.333 179.891 244.469 176.275 242.827 173C250.709 169.032 256 160.915 256 151.667Z" />
                </svg>
              </div>
              <p>{this.state.reel?.likesNumber || "0"}</p>
              <div className="svg-img">
                <svg
                  onClick={() => this.commentSheetRef.current?.toggleSheet()}
                  viewBox="0 0 256 256"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M230.271 9H25.5857C11.4795 9 0 20.4795 0 34.5857V171.043C0 185.149 11.4795 196.629 25.5857 196.629H51.1714V239.271C51.1714 242.555 53.0562 245.531 55.9986 246.956C57.1841 247.519 58.4463 247.8 59.7 247.8C61.6019 247.8 63.4867 247.169 65.0304 245.932L126.658 196.629H230.271C244.378 196.629 255.857 185.149 255.857 171.043V34.5857C255.857 20.4795 244.378 9 230.271 9ZM127.929 128.4H59.7C54.9837 128.4 51.1714 124.579 51.1714 119.871C51.1714 115.164 54.9837 111.343 59.7 111.343H127.929C132.645 111.343 136.457 115.164 136.457 119.871C136.457 124.579 132.645 128.4 127.929 128.4ZM196.157 94.2857H59.7C54.9837 94.2857 51.1714 90.4649 51.1714 85.7571C51.1714 81.0494 54.9837 77.2286 59.7 77.2286H196.157C200.873 77.2286 204.686 81.0494 204.686 85.7571C204.686 90.4649 200.873 94.2857 196.157 94.2857Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p>{this.state.reel?.total_comments || "0"}</p>
              <div className="svg-img share-reel" style={this.state.canDelete ? { display: 'block' } : {}}>
                <svg onClick={() => this.promptDeleteWarningModal()} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
                  <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
                </svg>
              </div>
            </div>

            <CommentBottomSheet
              ref={this.commentSheetRef}
              comments={this.state.comments}
              addComment={this.createReelComment}
            />
            <Menu
              ref={this.menuSheetRef}
              onOpenInfo={this.openInformationSheet}
              onReport={this.openReportSheet}
            />
            <Inforamtion ref={this.informationSheetRef} reel={this.state.reel} />
            <ReelReport ref={this.reportSheetRef} />
          </div>
        </SwipeDetector>
        <SweetAlert
          show={this.state.showDeleteWarningModal}
          type="warning"
          title="Are you sure?"
          confirmButtonColor="#DD6B55"
          showCancelButton
          text="This will remove your Xtra."
          onConfirm={() => this.deleteReel()}
          onCancel={() => this.setState({ showDeleteWarningModal: false })}
        />
      </div>
    );
  }
}

export default withRouter(Reel);
