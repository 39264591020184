import React, { Component } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

class GroupsPopover extends Component {

 render() {
    const {id, groups} = this.props;
    const title = `${groups.length} groups`
   
    const popover = (
      <Popover id={'popover-'+id}>
        <div>
          {groups.map((group) => (
            <p key={'group'+group.id}>
              {group.title}
            </p>
          ))}
        </div>
      </Popover>
    );
    
    switch (groups.length) {
      case 0: return (null);
      case 1: return (<span>{groups[0].title}</span>);
      case 2: return (<span>{ [groups[0].title, groups[1].title].join(', ') }</span>);
      default: return (<OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popover}>
                          <a>{title}</a>
                       </OverlayTrigger>);
    }
  }
}

export default GroupsPopover;
