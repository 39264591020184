import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import DateField from '../form_components/DateField';
import TimeField from '../form_components/TimeField';
import Select from 'react-select';
import Textarea from '../form_components/Textarea';
import Input from '../form_components/Input';
import {Checkbox} from 'react-ui-icheck';
import { eventClient } from '../../clients/EventClient';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { toastrNotification } from '../../helpers/Toastr';

// import 'react-select/dist/react-select.css';
import 'react-datepicker/dist/react-datepicker.css';

class NewEventForm extends Component {

  state = {
    showModal: false,
    canSubmit: false,
    errorMessage: '',
    loading: false,
    departmentIds: null,
    fields: { 
      title: '',
      description: '',
      location: '',
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      use_acknowledgement: true
    }
  };
  
  close = () => {
    this.setState({ showModal: false, loading: false });
  }

  open = () => {
    const dt = moment().add(1, 'day');
    this.setState({ showModal: true, 
                    errorMessage: "", 
                    departmentIds: null, 
                    fields: {start_date: dt.toDate(), 
                             end_date: dt.toDate(), 
                             start_time: this.defaultEventTime(), 
                             end_time: this.defaultEventTime().add(1, 'hour'), 
                             use_acknowledgement: true} 
                  });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  onDateChange = (field, date) => {
    const fields = this.state.fields;
    fields[field] = date;
    if (field == 'start_date' && fields['end_date'].length == 0) {
      fields['end_date'] = date
    };
    this.setState({ fields: fields });
  } 
  
  onTimeChange = (field, time) => {
    const fields = this.state.fields;
    fields[field] = time;
    this.setState({ fields: fields });
  } 
  
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }
  
  onUseAcknowledgementChange = (evt) => {    
    const checkboxState = !this.state.fields.use_acknowledgement;
    const fields = this.state.fields;
    fields[evt.target.name] = checkboxState;
    this.setState({ fields: fields });    
  }
  
  handleDepartmentSelect = departmentIds => {
    this.setState({ departmentIds });
  }
  
  defaultEventTime = () => {
    return moment().startOf('hour');    
  }
  
  formSubmit = (data) => {
    const start_date = moment(data.start_date)
    const end_date = moment(data.end_date)    
    if (start_date.isAfter(end_date)) {
      this.setState({errorMessage: "End date can't be less than start date"})
      return;
    };  
    if (start_date.isSame(end_date) && data.start_time.isAfter(data.end_time)) {
      this.setState({errorMessage: "End time should be later than start time"})
      return;
    };
    if (this.state.departmentIds === null) {
      this.setState({errorMessage: "Please select groups"})
      return;
    }; 
    data.start_time = data.start_time.format("h:mm a");
    data.end_time = data.end_time.format("h:mm a");
    data.start_date = start_date.format('LL');
    data.end_date = end_date.format('LL');
    data.use_acknowledgement = this.state.fields.use_acknowledgement;
    data.university_department_ids = [this.state.departmentIds.value]
    this.setState({ loading: true });
    eventClient.createEvent(data)
    .then((event) => {
        if (event.id) {
          this.props.onCreateEvent();
          toastrNotification({success: true, title: 'Event was successfully created'});
          //this.refs.form.reset();
          this.close();
        } else {
          let formErrors = event;
          this.refs.form.updateInputsWithError(formErrors)
          setTimeout(() => {
            this.setState({ loading: false })
          }, 1500)
        }
    })
  }

 render() {
    const { departmentIds } = this.state;
    const tooltip = (
      <Tooltip id="modal-tooltip">
      </Tooltip>
    )

    return (
      <Modal show={this.state.showModal} onHide={this.close} >
        <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal employee-form">
          <Modal.Header closeButton>
            <Modal.Title>New Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.errorMessage.length > 0 && 
              <div className="alert alert-danger">
                {this.state.errorMessage}
              </div>
            } 
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="title" className="sr-only"></label>
                <Input
                  name="title"
                  placeholder="Event title *"
                  className="form-control"
                  value={this.state.fields.title}
                  onChange={this.onInputChange}
                  required
                />
              </div>
              <div className="form-group col-sm-12">
                <Select
                  multi
                  searchable
                  name="university_department_ids"
                  value={departmentIds}
                  onChange={this.handleDepartmentSelect}
                  options={this.props.departmentList}
                  isSearchable={true}
                  placeholder="Select Groups *"
                />
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="start_date" className="sr-only"></label>
                <DateField name="start_date" className="form-control" placeholder="Start Date *" minDate={moment().startOf('month').toDate()} maxDate={moment().add(1, 'year').toDate()} value={this.state.fields.start_date ? this.state.fields.start_date : new Date()} onChange={(date) => this.onDateChange('start_date', date)} required/>
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="start_time" className="sr-only"></label>
                <TimeField name="start_time" className="form-control" minuteStep={5} placeholder="Start Time *" value={this.state.fields.start_time} onChange={(time) => this.onTimeChange('start_time', time)} required/>
              </div>  
              <div className="form-group col-sm-6">
                <label htmlFor="end_date" className="sr-only"></label>
                <DateField name="end_date" className="form-control" placeholder="End Date *" minDate={moment().startOf('month').toDate()} maxDate={moment().add(1, 'year').toDate()} value={this.state.fields.end_date ? this.state.fields.end_date : new Date()} onChange={(date) => this.onDateChange('end_date', date)} required/>
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="end_time" className="sr-only"></label>
                <TimeField name="end_time" className="form-control" minuteStep={5} placeholder="End time *" value={this.state.fields.end_time} onChange={(time) => this.onTimeChange('end_time', time)} required/>
              </div>
              <div className="form-group col-sm-12">
                <label htmlFor="location" className="sr-only"></label>
                <Input
                  name="location"
                  placeholder="Event location"
                  className="form-control"
                  value={this.state.fields.location}
                  onChange={this.onInputChange}
                />
              </div>
              <div className="form-group col-sm-12">
                <label htmlFor="comment" className="sr-only"></label>
                <Textarea
                  name="description"
                  placeholder="Description"
                  className="form-control"
                  value={this.state.fields.description}
                  onChange={this.onInputChange}
                />
              </div>
              <div className="form-group col-sm-12">
                <Checkbox
                  key="use_acknowledgement"
                  checkboxClass="icheckbox_square-green"
                  increaseArea="20%"
                  label="Acknowledge Event"
                  onChange={this.onUseAcknowledgementChange}
                  checked={this.state.fields.use_acknowledgement}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Submit</LaddaButton>
            <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
        </Formsy>
      </Modal>
    );
  }
}

export default NewEventForm;
