// @flow
import React, { Component } from 'react';
import { toastrNotification } from '../../helpers/Toastr';
import IBox from "../common/IBox";
import { Modal, Carousel, Button } from 'react-bootstrap';
import UpcomingEvents from "./UpcomingEvents";
import { raffleClient } from '../../clients/RaffleClient';
import { client } from '../Client';
import EventsLocationDialog from './EventsLocationDialog';
import RaffleEventForm from './RaffleEventForm';
import RaffleDetailsForm from './RaffleDetailsForm';

import "./CreateRaffle.css"

class CreateRaffleEvent extends Component {

  state = { 
    carousel: 0, 
    event: null,
    stateAndSitySelected: false, 
    companyIsFetched: false,
    company: {},
    city: '',
    coordinates: {},
    loading: false
  }
  
  componentDidMount() {
    this.getCompanyDetails();
  }
  
  getCompanyDetails = () => {
    client.getUniversity().then((company) => {
      this.setState({ company: company, city: company.city, companyIsFetched: true })
    });
  }
  
  showUpcomingEventsDialog = () => {
    this.setState({ stateAndSitySelected: true, carousel: 0 })
  }
    
  showDetailsForm = () => {
    this.setState({ carousel: 2 })
  }

  eventSelected(event: any) {
    this.setState({ event: event, carousel: 1 });
  }
  
  updateCitySelected = (newCity) => {
    this.setState({ city: newCity })
  }
  
  saveRaffle = () => {
    const eventData = this.refs.eventForm.getFormData()
    const detailsData = this.refs.detailsForm.getFormData()
    const data = Object.assign({}, eventData, detailsData)
    if (data['visibility'] == 'employees' && data['employees_id'].length == 0) {
        toastrNotification({success: false, title: 'Please select raffle participants.'})
        return false;          
    }        
    this.setState({ loading: true })
    raffleClient.createRaffle(data).then((raffle) => {
      if (raffle.id) {
        toastrNotification({success: true, title: 'Raffle has been created'})
        this.props.onAddRaffle(raffle)
        this.props.onCloseBtnClick()
      } else {
        toastrNotification({success: false, title: 'Unable to create raffle'})        
        setTimeout(() => {
          this.setState({ loading: false })
        }, 1500)
      }
    })
  }
  

  render() {
         if (this.state.stateAndSitySelected) {
           return (
             <Carousel controls={false} indicators={false} activeIndex={this.state.carousel} >
               <Carousel.Item>
                 <UpcomingEvents 
                   city={this.state.city} 
                   coordinates={this.state.coordinates} 
                   state={this.state.company.state} 
                   onSelected={(event) => this.eventSelected(event)}
                   onCloseBtnClick={this.props.onCloseBtnClick}
                 />
               </Carousel.Item>
               <Carousel.Item>
                 {this.state.event && 
                   <RaffleEventForm 
                    ref="eventForm" 
                    event={this.state.event} 
                    onCloseBtnClick={this.props.onCloseBtnClick} 
                    showDetailsForm={this.showDetailsForm}    
                   /> }
               </Carousel.Item>
               <Carousel.Item>
                {this.state.event && 
                  <RaffleDetailsForm 
                    ref="detailsForm" 
                    onCloseBtnClick={this.props.onCloseBtnClick} 
                    saveRaffle={this.saveRaffle}
                    event={this.state.event}
                    loading={this.state.loading}
                    departmentsList={this.props.departmentsList}
                  /> }
               </Carousel.Item>
             </Carousel>
           )  
         } else if (this.state.companyIsFetched) {
           return (
             <EventsLocationDialog 
               ref="citySelector" 
               city={this.state.company.city} 
               stateCode={this.state.company.state} 
               updateCitySelected={this.updateCitySelected}
               onCloseBtnClick={this.props.onCloseBtnClick}
               showUpcomingEventsDialog={this.showUpcomingEventsDialog}
             />
           )
         } else {
           return (
             <div></div>
           )
         }
  }
}
export default CreateRaffleEvent;
