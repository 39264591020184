import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';

import { parametersExcept } from '../helpers/PathParameter';
import { client } from '../components/Client';

class LoginTokenView extends Component {
  state = {
    loginSuccess: false,
    loginError: false,
    terminated: false
  }

  componentDidMount = () => {
    if (this.props.location.state && this.props.location.state.token) {      
      client.loginByToken(this.props.location.state.token).then(() => {
        if(client.isLoggedIn() || (client.isTerminatedLoggedIn() && this.props.location.state.allow_terminated)) {
          this.setState({ loginSuccess: true });
        } else {
          if(client.isTerminatedLoggedIn()) {
            this.setState({ terminated: true });          
          } else {          
            this.setState({ loginError: true });
          }
        };
      });
    } else {
      this.setState({ loginError: true });    
    }    
  }

  cleanedParams() {
    if(this.props.location.state && this.props.location.state.from) {
      return parametersExcept('token', this.props.location.state.from.search)
    } else {
      return '';
    }    
  }

  successPath = () => {
    const locationState = this.props.location.state;
    const pathname = (
      locationState && locationState.from && locationState.from.pathname
    );
    return ((pathname || '/activity') + this.cleanedParams());
  }

  errorPath = () => {
    return ('login' + this.cleanedParams());
  }

  render() {
      if (this.state.loginSuccess) {
        console.log("redirect to success");
        return (
          <Redirect to={this.successPath()} />
        );
      } else {
        if (this.state.loginError) {
          return (
            <Redirect to={this.errorPath()} />            
          )
        } else {
          return (
            <div className="container">
            <style>{`#root, .container, .signin-form { height: 100%;};`}</style>
            <style>{`.alert { margin-bottom: 0px; margin-top:0px; border-radius: inherit};`}</style>
            <div className="row signin-form">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
            <div className="ibox float-e-margins">
            {this.state.terminated &&
              <div className="alert alert-danger">
              Your account is terminated.
              </div>
            }
            <div className="ibox-title">
            <h5><img src="img/logo.png" height="23" /></h5>                            
            </div>
            <div className="ibox-content">
            {!(this.state.loginError || this.state.terminated) &&
              <span>Signing to ezzely ...</span>              
            }
            {this.state.loginError || this.state.terminated &&
              <span>Unable to sign-in</span>              
            }
            </div>
            </div>
            </div>
            <div className="col-lg-3"></div>
            </div>
            </div>
          )          
        }
    }
  }
}

export default LoginTokenView;
