// @flow
import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import VideoPlayerModal from './VideoPlayerModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay'

class ActivityPostAttachment extends Component {
  
  state = {
    lightboxIsOpen: false,
    attachmentLoaded: false
  }
  
  openLightbox = (event, obj) => {
    if (this.props.attachment.media_type == "video") {
      this.refs.videoPlayer.open(this.props.attachment.url)
    } else {
      this.setState({ lightboxIsOpen: true })
    } 
  }
  
  closeLightbox = () => {
    this.setState({
      lightboxIsOpen: false,
    },()=>{
      this.refs.videoPlayer.close()
    })
  }
  
  attachmentBoxStyle = () => {    
    let css = {}
    if(!this.state.attachmentLoaded) {
      css = {backgroundColor: 'rgb(238, 238, 238)'};
      if (this.props.attachment.thumb_height) css.height = this.props.attachment.thumb_height;
      //if (this.props.attachment.thumb_width) css.width = this.props.attachment.thumb_width;
    } 
    return css;
  }
  
  render() {
    const originalImgLinks = this.props.attachment.media_type == 'image' ? [this.props.attachment.url] : []
    const attachmentClass = this.props.attachment.media_type == 'image' ? "" : "video-attachment-thumbnail"  
           
    return (
      <div className="row">
        <div className="col-md-12">
          <div className={attachmentClass} onClick={this.openLightbox} style={this.attachmentBoxStyle()}>
             { this.props.attachment.media_type == 'video' &&
                 <span><FontAwesomeIcon icon={faPlay} /></span>
             }
              <img 
                src={this.props.attachment.thumb_url} 
                className="img-responsive post-image" 
                onClick={this.openLightbox}
                onLoad={() => this.setState({ attachmentLoaded: true })}
              />
              { this.state.lightboxIsOpen && 
                <Lightbox mainSrc={originalImgLinks[0]}
                  onClose={this.closeLightbox}
                  onCloseRequest={() => this.setState({ lightboxIsOpen: false })}
                />
              }              
              <VideoPlayerModal ref="videoPlayer" closeFnc={this.closeLightbox}/>
           </div>
        </div>
      </div>
    );
 }
}

export default ActivityPostAttachment
