import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';
import RewardType from '../models/RewardType';

class RewardTypeClient {
  
  getRewardTypes() {
    return client.callApi(Config.API_BASE + '/reward_types.json')
      .then((reward_types) => reward_types.map((reward_type) => (new RewardType(reward_type))))
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }    
    
  createRewardType(rewardTypeData) {
    return client.callApi(Config.API_BASE + '/reward_types.json', {}, 'POST', {reward_type: rewardTypeData})
      .then((reward_type) => new RewardType(reward_type))
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  updateRewardType(rewardTypeId, rewardTypeData) {
    return client.callApi(Config.API_BASE + '/reward_types/'+rewardTypeId+'.json', {}, 'PUT', {reward_type: rewardTypeData})
      .then((reward_type) => new RewardType(reward_type))
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  updateActiveStatus(rewardTypeId) {
    return client.callApi(Config.API_BASE + '/reward_types/'+rewardTypeId+'/update_active_status.json', {}, 'PUT')
      .then((reward_type) => new RewardType(reward_type))
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
    
  deleteRewardType(rewardTypeId) {
    return client.callApi(Config.API_BASE + '/reward_types/'+ rewardTypeId +'.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
          
}

export const rewardTypeClient = new RewardTypeClient();
