import React, { Component } from 'react';
import RedeemDialog from '../components/rewards/RedeemDialog/RedeemDialog';
import Coin from '../components/common/Coin';
import { rewardClient } from '../clients/RewardClient';
import RewardsList from '../components/rewards/RewardsList';

class MyRewardsView extends Component {

  state = {
    cheapestRewardType: {},
    userPoints: 0,
    recognitionsText: '',
  }

  constructor(props) {
    super(props);
    this.RewardsListRef = React.createRef()
  }

  componentDidMount() {
    this.getRewardsData()
  }

  getRewardsData = () => {
    rewardClient.getRewardsData().then((data) => {
      console.log(data)
      this.setState({
        cheapestRewardType: data.cheapest_reward_type,
        userPoints: data.rewarded_points,
        recognitionsText: data.recognitions_text,
        rewardTypes: data.reward_types,
        gifts: data.gifts,
        gifts_total: data.gifts_total
      })
    })
  }

  refreshPointsAndGifts = () => {
    this.getRewardsData();
    this.RewardsListRef.current?.refresh();
  }

  render() {
    return (
      <div className="wrapper wrapper-content">

        <div className="row">
          <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 pull-right">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Redeem Rewards</h5>
              </div>
              <div className="ibox-content">
                <h1 className="pull-left"><Coin width="55px" /></h1>
                <h3 className="text-left" style={{ marginLeft: '80px' }}>Your Balance <br /><strong> {this.state.userPoints}</strong> <small className="text-muted">EZ-points</small></h3>
                <small><span dangerouslySetInnerHTML={{ __html: this.state.recognitionsText }} /></small>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-md-12">
                    <RedeemDialog
                      refreshEmployeePoints={this.refreshPointsAndGifts}
                      cheapestRewardType={this.state.cheapestRewardType}
                      employeePoints={this.state.userPoints}
                      rewardTypes={this.state.rewardTypes}
                    />
                    {this.state.cheapestRewardType !== null &&
                      <small className="text-muted">Minimum of {this.state.cheapestRewardType.points} Ezzely points is required for redemption.</small>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Rewards</h5>
              </div>
              <div className="ibox-content" style={{ paddingTop: '0' }}>
                <RewardsList ref={this.RewardsListRef}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MyRewardsView;
