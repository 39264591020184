import React, { Component } from 'react';
import {withFormsy} from 'formsy-react';

class Select extends Component { 
  
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.value && this.props.options[0] && this.props.options[0].value) {
      const option = this.props.options[0]
      this.props.setValue(option.value)
    }  
  }
  
  handleChange = (evt) => {
    this.props.setValue(evt.target.value);
    if (this.props.onChange) this.props.onChange(evt);
  }
  
  render() {
    const options = this.props.options.map((option, i) => (
      <option key={option.title+option.value} value={option.value} disabled={option.value == '' ? true : false}>
        {option.title}
      </option>
    ));
    
    const errorMessage = this.props.errorMessage;
        
    return (
      <div>
        <select 
          name={this.props.name} 
          onChange={this.handleChange}
          value={this.props.value}
          className={this.props.className}
          tabIndex={this.props.tabIndex}
          disabled={this.props.disabled}
        > 
          {this.props.placeholder &&
            <option disabled value>{this.props.placeholder}</option>
          }
          {options}
        </select>
        <span className='validation-error'>{errorMessage}</span>
      </div>
    );
  }

}

export default withFormsy(Select)
