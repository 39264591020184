import React, { Component } from 'react';
import AccountReport from '../components/kpi_report/AccountReport';
import ActivityReport from '../components/kpi_report/ActivityReport';
import HRReport from '../components/kpi_report/HRReport';
import TrainingReport from '../components/kpi_report/TrainingReport';

import '../components/kpi_report/KpiReport.css';

class KPIReportView extends Component {
    
    render() {
      return (
          <div>        
            <div className="wrapper wrapper-content kpi-report">                
                <div className="row" style={{marginBottom: '20px'}}>
                  <div className="col-lg-6 report-block">
                    <div className="ibox">
                      <div className="ibox-content">
                        <AccountReport />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 report-block">
                    <div className="ibox">
                      <div className="ibox-content">
                        <ActivityReport />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 report-block">
                    <HRReport /> 
                  </div>
                  <div className="col-lg-6 report-block">
                    <div className="ibox">
                      <div className="ibox-content">
                        <TrainingReport />
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
      
      )

    }

}

export default KPIReportView
