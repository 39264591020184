import moment from 'moment';
class Event {
  constructor(obj) {
    for (var prop in obj) {
      if (prop === 'start' || prop === 'end') {
        this[prop]= moment.parseZone(obj[prop]);    
      } else {
        this[prop] = obj[prop]; 
      }
    }
  }
}

export default Event;
