import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';

class ReelsClient {

    getReels(page) {
        let url = Config.API_BASE + '/reels'
        if (page) url = url + '?page=' + page
        return client.callApi(url).catch(err => client.handleApiError(err));
    }
    getMyDrafts(page){
        const url = Config.API_BASE + '/reels/draft'
        if (page) url = url + '?page=' + page
        return client.callApi(url).catch(err => client.handleApiError(err))
    }
    getReel(id) {
        const url = Config.API_BASE + '/reels/' + id
        return client.callApi(url).catch(err => client.handleApiError(err));
    }

    removeReel(id) {
        const url = Config.API_BASE + '/reels/' + id
        return client.callApi(url, {}, 'DELETE').catch(err => client.handleApiError(err));
    }

    getMyReels() {
        const url = Config.API_BASE + '/reels/my_reeels'
        return client.callApi(url).catch(err => client.handleApiError(err));
    }

    getVideosPresignedURL(fileName) {
        let url = Config.API_BASE + '/reels/get_video_presigned_url'
        if (fileName) url = url + '?file_name=' + fileName
        return client.callApi(url).catch(err => client.handleApiError(err));
    }

    postReel(id, title, description) {
        const url = Config.API_BASE + '/reels/' + id + '/post_reel'
        return client.callApi(url, {}, 'PUT', { title, description }).catch(err => client.handleApiError(err));
    }
    
    getComments(reel_id,page){

        let url = Config.API_BASE + '/reels/' + reel_id + '/comments'
        if (page) url = url + '?page=' + page
        return client.callApi(url).catch(err => client.handleApiError(err));
    }

    processDraftVideo(id){
        const url = Config.API_BASE + '/reels/' + id + '/process_draft_video'
        return client.callApi(url).catch(err => client.handleApiError(err));
    }

}

export const reelsClient = new ReelsClient();
