import React, { Component } from 'react';
import { badgesClient } from '../../clients/BadgesClient';

import './BadgeSystem.css';

class BadgingSystem extends Component {

    state = {
        enabled:false,
        badges: []
    }

    constructor(props) {
        super(props);
        badgesClient.listBadges()
        .then(badges => this.setState({ badges }));
    }
    
    componentDidMount(){
        badgesClient.badgesEnabled().then(enabled=> this.setState({enabled}));
    }

    toggleBadgeSettings = () => {
        this.setState({ enabled: !this.state.enabled },()=>{
            console.log(this.state)
            badgesClient.enableBadges(this.state.enabled);
        })
    }

    toggleBadge = (badge) => {
        badgesClient.updateBadge(badge.id, !badge.enabled)
        .then(()=>{
            const badges = this.state.badges;
            const badgeIndex =badges.findIndex(b => b.id === badge.id)
            badge.enabled=!badge.enabled;
            badges[badgeIndex]=badge;
            this.setState({badges});
        })
    }

    render() {
        return (
            <div className="row badge-settings">
                <div className="col-lg-4" style={{ width: "100%" }}>

                    <div className='settings-row'>
                        <div style={{ fontSize: '1.75rem' }}>Badges Enabled</div>
                        <div className='spacer'></div>
                        <div className="checkbox">
                            <label>
                                <input type="checkbox" checked={this.state.enabled}  onChange={() => this.toggleBadgeSettings()} />
                            </label>
                        </div>
                    </div>
                    <hr />

                    {this.state.badges?.length > 1 && this.state.badges?.map((badge, index) =>
                        <div key={index.toString()}>
                            <div className='settings-row'>
                                <img src={`img/badges/Badges/${badge.label}.png`} className='badge-icon' style={badge.label === 'Admin' ? { width: '30px', marginLeft: '3px' } : {}} />
                                {badge.label === 'Admin' ? 'Display Admin Badge' : badge.label}
                                <div className='spacer'></div>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" checked={badge.enabled} disabled={!this.state.enabled} onChange={() => this.toggleBadge(badge)} />
                                    </label>
                                </div>
                            </div>
                            {badge.label === 'Admin' && <hr />}
                        </div>)
                    }
                </div>

            </div>
        )
    }
}
export default BadgingSystem;
