import React from 'react';
import moment from 'moment';

export function statusLabel(shiftRequest) {
  if (shiftRequest.performer_id) {
    return (<span className="label label-info">Picked Up</span>);
  } else {
    if (shiftRequest.start_date && shiftRequest.start_date.diff(moment(), 'days') >= 0) {
      return (<span className="label label-primary">Active</span>);
    } else {
      return (<span className="label">Inactive</span>);  
    }
  }   
}
