import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons/faSortAmountDown";
import { Link } from "react-router-dom";

import { reelsClient } from "../clients/ReelsClient";
import ReelThumbnail from "../components/reels/ReelThumbnail";

import "./Reels.css";

class Reels extends Component {
  state = {
    reels: [],
    loading: false,
  };

  componentDidMount() {
    this.getReelData();
  }

  getReelData() {
    this.setState({ loading: true });
    reelsClient.getReels(1).then((response) => {
      this.setState({ loading: false });
      if (response?.length > 0) {
        this.setState({ reels: response });
      }
    });
  }
  renderLoader = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="sk-spinner-loader">
            <div className="sk-spinner sk-spinner-double-bounce">
              <div className="sk-double-bounce1"></div>
              <div className="sk-double-bounce2"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="wrapper wrapper-content animated fadeIn">
        <div className="logo">
          <svg
            viewBox="0 0 256 257"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 93.0923V197.819C0.00555146 213.248 6.13718 228.044 17.0472 238.954C27.9572 249.864 42.7527 255.996 58.1817 256.001H197.818C213.247 255.996 228.042 249.864 238.952 238.954C249.862 228.044 255.994 213.248 256 197.819V93.0923H0ZM168.611 173.034L98.7926 212.365C97.0647 213.378 95.0935 213.901 93.0908 213.878C90.0182 213.834 87.0837 212.594 84.9109 210.421C82.738 208.248 81.498 205.314 81.4544 202.241V123.58C81.4712 121.555 82.0139 119.57 83.0291 117.819C84.0443 116.067 85.4972 114.609 87.2455 113.589C88.9937 112.568 90.9772 112.019 93.0015 111.996C95.0257 111.972 97.0213 112.476 98.7926 113.456L168.611 152.787C170.41 153.794 171.909 155.264 172.952 157.043C173.995 158.823 174.545 160.848 174.545 162.91C174.545 164.973 173.995 166.998 172.952 168.778C171.909 170.557 170.41 172.026 168.611 173.034Z"
              fill="#676A6C"
            />
            <path
              d="M71.0981 69.8199H4.20613e-05V58.1836C-0.0116126 48.4987 2.39895 38.9647 7.01235 30.4492C11.6257 21.9337 18.2955 14.7072 26.4145 9.42728L40.2618 28.2782L71.0981 69.8199Z"
              fill="#676A6C"
            />
            <path
              d="M175.941 69.8194H100.072L65.6283 23.274L48.8719 0.81587C51.9423 0.251203 55.0593 -0.0215282 58.181 0.00132543H124.741L141.73 23.274L175.941 69.8194Z"
              fill="#676A6C"
            />
            <path
              d="M256 58.1838V69.8201H204.8L170.473 23.2747L153.367 0.00204468H197.818C213.247 0.00759614 228.043 6.13922 238.953 17.0492C249.863 27.9592 255.994 42.7547 256 58.1838Z"
              fill="#676A6C"
            />
          </svg>
          &nbsp;
          <h1>Xtras</h1>
          <p>
            <FontAwesomeIcon icon={faSortAmountDown} />
            <span>Sort by</span>
          </p>
        </div>
        {this.state.loading && this.renderLoader()}
        {!this.state.loading && (
          <div className="main-all">
            {this.state.reels.map((reel) => (
              <Link key={reel.id} to={`/reel/${reel.id}`}>
                <ReelThumbnail
                  title={reel.title}
                  likes={reel.likesNumber}
                  src={reel.thumb_url}
                />
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  }
}
export default Reels;
