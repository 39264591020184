import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { pluralize } from '../../helpers/Humanizer';
import { client } from '../Client';

class ParticipationReportWidget extends Component {

  state = {
    reportData: {},
    spinnerClass: 'sk-loading',
    reportDataPresent: true
  };

  componentDidMount() {
    this.setState(this.state);
    this.getSurveyReportData();
  }

  getSurveyReportData = () => {
    client.getSurveyReportData(this.props.survey.id, 'participation').then((data) => {
      this.setState({ reportData: data, spinnerClass: '', reportDataPresent: data !== undefined })
    });
  }


 renderReport = () => {
   if (this.state.reportDataPresent) {
     return (
       <div>
          <div className='row'>
             <div className="col-md-12">
                <div>{this.state.reportData.survey_employees_total_number} employees targeted by the survey</div>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>{this.state.reportData.total_employees_saw_the_questions}</td>
                      <td>{pluralize('employee', this.state.reportData.total_employees_saw_the_questions)} saw the questions</td>
                    </tr>
                    <tr>
                      <td>{this.state.reportData.total_employees_answered}</td>
                      <td>{pluralize('employee', this.state.reportData.total_employees_answered)} answered</td>
                    </tr>
                    <tr>
                      <td>{this.state.reportData.total_employees_choose_not_to_answer}</td>
                      <td>{pluralize('employee', this.state.reportData.total_employees_choose_not_to_answer)} choose not to answer</td>
                    </tr>
                    <tr>
                      <td>{this.state.reportData.total_employees_has_yet_to_see_the_questions}</td>
                      <td>{pluralize('employee', this.state.reportData.total_employees_has_yet_to_see_the_questions)} have yet to take the survey</td>
                    </tr>
                  </tbody>
                </table>
             </div>
          </div>
       </div>
     )
   } else {
     return (
       <div className="text-center">
         <h1>There is no report data</h1>
       </div>
     )
   }
 }

 render() {

    return (
      <div>
        {this.renderReport()}
      </div>
    );
  }
}

export default ParticipationReportWidget;
