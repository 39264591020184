import React, { Component } from 'react';
import Select from 'react-select';
// import 'react-select/dist/react-select.css';
import {Checkbox} from 'react-ui-icheck';
import {Radio, RadioGroup} from 'react-ui-icheck';
import { client } from '../Client';

class DepartmentsBox extends Component {
  
  state = {
    visibility: 'departments',
    
    employeesList: [],
    selectedEmployees: [],
    
    departmentsList: [],
    selectedDepartments: []
  }
  
  componentDidMount = () => {
    this.getDepartments();
  }
  
  getDepartments = () => {
    client.getEmployeeDepartmentsList().then((departments) => {
      this.setState({ departmentsList: departments });
    })
  }
  
  isDepartmentSelected = (dep) => {
    return !!this.state.selectedDepartments.find(a => a.id === dep.id);
  }
          
  isEmployeeSelected = (empl) => {
    return !!this.state.selectedEmployees.find(a => a.id === empl.id);
  }
  
  isAllSelected = () => {
    if (this.state.visibility == 'departments') {
      return this.state.departmentsList.length == this.state.selectedDepartments.length
    } else {
      return this.state.employeesList.length == this.state.selectedEmployees.length      
    }
  }
          
  getSelectedData() {
    return {
      university_department_id: this.state.selectedDepartments.map(dep => dep.id).join(','),
      employees_id:             this.state.selectedEmployees.map(empl => empl.id).join(','),
      visibility:               this.state.visibility
    }
  }
  
  reorderDepartments = () => { 
    const array = this.state.departmentsList;
    const modulus = array.length % 2;
    const rows = (array.length + modulus) / 2
    let res = [];
    for (let i = 0; i < rows; i++) {
      res.push(array[i]);
      if (array[i+rows]) res.push(array[i+rows]);
    }
    return res;
  }  
  
  handleSelectAll = () => {
    if (this.state.visibility == 'departments') {
      if (this.isAllSelected()) {
        this.setState({selectedDepartments: []})
      } else {
        this.setState({selectedDepartments: this.state.departmentsList})
      }
    } else {
      if (this.isAllSelected()) {
        this.setState({selectedEmployees: []})
      } else {
        this.setState({selectedEmployees: this.state.employeesList})
      }      
    }
  }
    
  handleDepartmentChange = (evt, department) => {
    let deps = [];
    if (this.isDepartmentSelected(department)) {
      if (department.default)
        deps = []
      else
        deps = this.state.selectedDepartments.filter(a => a.id !== department.id)
    } else {
      if (department.default)
        deps = [department]
      else 
        deps = this.state.selectedDepartments.filter(a => !a.default).concat(department)
    }
    if (deps.length == 0) deps = this.state.departmentsList.filter(d => d.default);
    this.setState({selectedDepartments: deps});
  }
  
  handleEmployeeChange = (evt, employee) => {
    let empls = [];
    if (this.isEmployeeSelected(employee)) {
      empls = this.state.selectedEmployees.filter(a => a.id !== employee.id)
    } else  {
      empls = this.state.selectedEmployees.concat(employee);
    }
    this.setState({selectedEmployees: empls});    
  }
  
  showEmployees = () => {
    if(this.state.employeesList.length == 0) {      
      client.getEmployees().then((employees) => {
        this.setState({visibility: 'employees', employeesList: employees});        
      })
    } else {
      this.setState({visibility: 'employees'});      
    }
  }
    
  showDepartments = () => {
    this.setState({visibility: 'departments'});
  }
      
  render() {    
    return (
      <div className="post-form-departments-box">
        <div style={{'margin': '5px 10px'}}>
          <div className="pull-left">
            <RadioGroup name="radio" value={this.state.visibility}>
              <Radio
              value="departments"
              radioClass="iradio_square-green"
              increaseArea="20%"
              label="&nbsp; Groups &nbsp;"
              onClick={this.showDepartments}
              />
              <Radio
              value="employees"
              radioClass="iradio_square-green"
              increaseArea="20%"
              label="&nbsp; Employees &nbsp;"
              onClick={this.showEmployees}
              />
            </RadioGroup>
          </div>
          
          <div className="pull-right">
            <label>
              <Checkbox
              name='select-all'
              checkboxClass="icheckbox_square-green"
              checked={this.isAllSelected()}
              increaseArea="20%"
              onChange={this.handleSelectAll}
              /> Select All
            </label>
          </div>
          
          <div className="clearfix"></div>
        </div>
        <div className="scrollable-area">
        { this.state.visibility == 'departments' && this.reorderDepartments().map((dep, index) => (
           <div key={'dep'+index} className="col-xs-6">
           <label>
             <Checkbox
               name='departments'
               checkboxClass="icheckbox_square-green"
               checked={this.isDepartmentSelected(dep)}
               increaseArea="20%"
               onChange={(evt) => this.handleDepartmentChange(evt, dep)}
             /> &nbsp;
             {dep.title}</label>
           </div>
         ))          
        }
        { this.state.visibility == 'employees' && this.state.employeesList.map((employee) => (
          <div key={'employee-' + employee.id}>
          <label>
            <Checkbox
              name='departments'
              checkboxClass="icheckbox_square-green"
              checked={this.isEmployeeSelected(employee)}
              increaseArea="20%"
              onChange={(evt) => this.handleEmployeeChange(evt, employee)}
            /> &nbsp; &nbsp;
            <img alt="image" width='30px' src={employee.avatar_image_url()} /> &nbsp;
            {employee.full_name()}</label>
          </div>          
          ))          
        }
        </div>
      </div>
    )
  }
  
}  
  
export default DepartmentsBox;
