import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Modal, OverlayTrigger, Button } from 'react-bootstrap';
import DateRangePickerWrapper from '../common/DateRangePickerWrapper';
import isSameDay from 'react-dates/lib/utils/isSameDay';
import isInclusivelyAfterDay from 'react-dates/lib/utils/isInclusivelyAfterDay';
import moment from 'moment';
import Input from '../form_components/Input';
import Textarea from '../form_components/Textarea';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import DepartmentsBox from './DepartmentsBox';

class RaffleDetailsForm extends Component {
  
  constructor(props: Props) {
    super(props)

    let evt = this.props.event;

    let initialStartDate = moment();
    let eventDate  = moment(evt.eventDateLocal);
    let maxDurationDays = eventDate.diff(initialStartDate.startOf('day'), 'days');
    this.state = {  duration: maxDurationDays, 
                    startDate: initialStartDate,
                    endDate: initialStartDate, 
                    maxDurationDays: maxDurationDays,
                    daysInterval: maxDurationDays,
                    eventDate: eventDate,
                    fields: {
                      title: 'Event Raffle',
                      description: ''
                    } 
                  }
  }        
  
  getFormData() {
    const formData = this.refs.detailsForm.getModel()
    let data = {
      start_date: this.state.startDate,
      expiry_date:  this.state.endDate,
      duration: this.state.endDate.diff(this.state.startDate, 'days'),
      title: formData.title,
      description: formData.description,
      raffle_type: 'Event'
    }  
    Object.assign(data, this.refs.departmentsBox.getSelectedData());
    return data;
  }
              
  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }
    
  onDatesChange = ({ startDate, endDate }) => {
    const dates = { startDate, endDate }
    this.setState({ startDate, endDate })
    if (dates.startDate == null || dates.endDate == null) { 
      this.disableButton() 
    } else {
      this.enableButton()
    }
  }
    
 render() {     
    return (
      <Formsy onValidSubmit={this.saveRaffle} onValid={this.enableButton} onInvalid={this.disableButton} ref="detailsForm">
        <Modal.Header closeButton>
          <Modal.Title>Raffle Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="gray-bg">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Title</label>
                <Input value={this.state.fields.title} onChange={this.onInputChange} className='form-control' name='title' required/>
              </div>
              <div className="form-group">
                <label>Description</label>
                <Textarea value={this.state.fields.description} onChange={this.onInputChange} className='form-control' name='description' />
              </div>
              <div className="form-group">
                <DepartmentsBox ref="departmentsBox" />
              </div>
              <div className="form-group">
                <label>Raffle Period</label>
                <DateRangePickerWrapper
                  ref='rafflePeriodPicker'
                  initialStartDate={moment()}
                  initialEndDate={moment()}
                  isOutsideRange={day =>
                    !isInclusivelyAfterDay(day, moment()) ||
                    isInclusivelyAfterDay(day, moment().add(this.state.daysInterval, "days"))
                  }
                  minimumNights={0}
                  readOnly
                  onDatesChange={this.onDatesChange}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <LaddaButton 
            disabled={!this.state.canSubmit || this.props.loading} 
            data-style={EXPAND_LEFT} 
            type="submit" 
            loading={this.props.loading} 
            onClick={() => this.props.saveRaffle()}
            className={'ladda-button btn btn-sm btn-primary no-margins'}
          > Start Raffle </LaddaButton>
          <button type="button" className="btn btn-default btn-sm" onClick={() => this.props.onCloseBtnClick()}>Close</button>  
        </Modal.Footer>
      </Formsy>                             
    );
  }
}

export default RaffleDetailsForm;
