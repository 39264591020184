import React from "react";
import RewardsList from "../rewards/RewardsList";

class EmployeeRewards extends React.Component{
    render(){
        
        return <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                 <div className="ibox float-e-margins">
                   <div className="ibox-title">
                     <h5>Rewards</h5>
                   </div>
                   <div className="ibox-content">                
                     <RewardsList employeeId={this.props.employeeId}/>                
                   </div>
                 </div>
               </div>
            
        </div>;
    }
}

export default EmployeeRewards;