import React, { Component } from 'react';
import ActionCable  from 'actioncable';
import { client } from '../components/Client';
import { companySettingsClient } from '../clients/CompanySettingsClient';
import Config from 'config';
import { Link } from 'react-router-dom';
import ConversationsList from '../components/conversation/ConversationsList';
import { animateScroll as scroll } from 'react-scroll';

class ConversationsView extends Component {
  
  state = {
    isAccessible: false
  }
  
   subscription: ActionCable;
   channelName = "ConversationsChannel";

    componentDidMount() {
      let _this = this;
      const conversationsList = this.refs.conversationsList;
      
      scroll.scrollToTop();
    
      this.checkUserAccess();
    
      let listener = {
        received(data) {
          if (!client.isLoggedIn()) {
            window.location.hash = '/login';
            return;
          };

          switch(data.action) {
          case 'update_conversation':
            conversationsList.updateConversation(data);
            break;
          }
          console.log("received, channel:" + _this.channelName, data);
        }
      };

      ActionCable.debugging = true;
      window.cable = ActionCable.createConsumer(client.getConsumerUrl());
      this.subscription = window.cable.subscriptions.create({ channel: this.channelName }, listener);
    }
    
    componentWillUnmount() {
      if (this.subscription) {
        setTimeout(() => {console.log('unsubscribed'); this.subscription.unsubscribe()}, 100);
      }
    }
    
    checkUserAccess = () => {
      if ( client.adminLoggedIn() ) {
        this.setState({ isAccessible: true });
      } else {
        companySettingsClient.checkUserAccess("conversation")
        .then((response) => {
          this.setState({ isAccessible: response.success });
          if ( !response.success ) {
            swal("Error!", "You dont have access to this page.", "error");
          }                             
        });
      }  
    }

    render() {
        const { isAccessible } = this.state;
        return (
          <div className="wrapper wrapper-content animated fadeIn">
            <div className="row">
              <div className="col-sm-12 col-lg-8 col-lg-offset-2">
                { isAccessible && 
                  <ConversationsList ref="conversationsList" />
                }
              </div>
            </div>
          </div>
        )
    }

}

export default ConversationsView;
