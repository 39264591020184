import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons/faPaperPlane";
import { conversationClient } from "../../clients/ConversationClient";
import { companySettingsClient } from "../../clients/CompanySettingsClient";
import { client } from "../Client";
import { Modal, Button, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";

class ColleagueSummary extends Component {
  state = {
    conversationId: null,
    canCreateConversation: false,
  };

  close = () => {
    this.setState({ showModal: false });
  };

  open = (employee) => {
    this.setState({ showModal: true, employee: employee });

    companySettingsClient
      .getAllowedEmployees("conversation")
      .then((response) => {
        if (
          response.employee_ids.includes(client.currentUser().id) &&
          response.employee_ids.includes(employee.id)
        ) {
          this.setState({ canCreateConversation: true });
          this.createConversation(employee);
        }
      });
  };

  createConversation = (employee) => {
    conversationClient.createConversation(employee.id).then((response) => {
      if (response.conversation_id) {
        this.setState({ conversationId: response.conversation_id });
      }
    });
  };

  openMessageForm = () => {
    this.props.openMessageForm(this.state.employee);
  };

  render() {
    const employee = this.state.employee;

    return (
      <Modal show={this.state.showModal} onHide={this.close}>
        <Modal.Body>
          {this.state.employee && (
            <div className="row">
              <div className="col-lg-4 text-center">
                <div className="m-b-sm">
                  <img
                    alt="image"
                    className="img-circle"
                    src={employee.avatar_image_url()}
                    style={{ width: "62px" }}
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <h3>{employee.full_name()}</h3>
                <p>
                  <strong>{employee.position}</strong>
                  <br />
                  {employee.department}
                </p>
              </div>
              <div className="full-height-scroll">
                {this.state.canCreateConversation && this.state.conversationId && (
                  <div className="row" style={{ marginBottom: "5px" }}>
                    <div className="col-md-12">
                      <Link to={`/conversation/${this.state.conversationId}`}>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm btn-block"
                        >
                          <FontAwesomeIcon icon={faPaperPlane} /> Start
                          conversation
                        </button>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.close}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ColleagueSummary;
