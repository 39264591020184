// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router
} from 'react-router-dom'
import router from './config/routes';

import jquery from 'jquery';
import metismenu from 'metismenu';
import bootstrap from 'bootstrap';
import Config from 'config';
import {client} from './components/Client'
import './../public/vendor/offline/offline.min.js';
Offline.options = {checks: {xhr: {url: Config.API_BASE}}};

import './../node_modules/bootstrap/dist/css/bootstrap.min.css';

import './../node_modules/animate.css/animate.min.css';
import './../node_modules/react-image-lightbox/style.css';
//import './../node_modules/icheck/skins/square/green.css';
import './../public/styles/style.css';
import './../public/styles/ladda.min.css';
import './../public/styles/custom.css';
import './../public/styles/col-xsm.css';
import './../public/styles/dropzone.css';
import './../public/styles/offline-language-english.css';
import './../public/styles/offline-theme-default.css';
import './../public/styles/toastr.min.css'


window.ezzely_download = function(url) {
  window.open(url)
}

ReactDOM.render(router, document.getElementById('root'));
