import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { eventClient } from '../clients/EventClient';
import { client } from '../components/Client';
import { toastrNotification } from '../helpers/Toastr';

class EventView extends Component {

  state = {
    showModal: true,
    error: false,
    event: {}
  }
  
  componentDidMount() {
    eventClient.getEvent(this.props.match.params.id).then((event) => {
      if (event.success) {
        this.setState({ event: event });        
      } else {
        this.setState({ error: true });
      }
    });
  }  
  
  close = () => {
    this.setState({ showModal: false, event: {} });
  }

  renderModalContent = () => {
    if (this.state.error) {
      return (
        <p className="text-center">Unable to find event.</p>
      )
    } else {      
      return (
          <div className="row">
            <div className="col-md-12">
              <dl className="dl-horizontal">
                <dt>Time:</dt>
                <dd>{moment(event.start).format('D MMM YYYY, h:mm a')} - {moment(event.end).format('D MMM YYYY, h:mm a')}</dd>
                <dt>Group:</dt>
                <dd>{event.department}</dd>
                {event.location && 
                  <span>
                    <dt>Location:</dt>
                    <dd>{event.location}</dd>
                  </span>  
                }
                {event.description && 
                  <span>
                    <dt>Description:</dt>
                    <dd>{event.description}</dd>
                  </span>  
                }
              </dl>
            </div>
          </div>
      )    
    }
  }

 render() {
    const popover = (
      <Popover id="modal-popover" title="popover">
      </Popover>
    )
    const tooltip = (
      <Tooltip id="modal-tooltip">
      </Tooltip>
    )
    
    event = this.state.event;

    return (
      <Modal show={this.state.showModal} onHide={this.close} >
        <Modal.Header closeButton>
          <Modal.Title>{event.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderModalContent()}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.close}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default EventView;
