import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import SweetAlert from 'sweetalert-react';
import { rewardClient } from '../../clients/RewardClient';
import Coin from '../common/Coin';

class RewardsTransactionsList extends Component {

    state = {
        rewards: [],
        page: 1,
        loading: false
    }

    constructor(props) {
        super(props);
        this.reportFormRef = React.createRef();
    }

    componentDidMount() {
        this.getRewardsHistory();
    }

    getRewardsHistory = () => {
        let page = this.state.page;
        this.setState({ loading: true });
        (this.props.nonRedeemed ? rewardClient.getInhouseNonRedeemedRewardsHistoryUniversity(page) : rewardClient.getRewardsHistoryUniversity(page)).then((data) => {
            this.setState({
                rewards: data.rewards || [],
                pageCount: data.total_pages,
                loading: false
            });
        });
    }

    handlePageClick = (data) => {
        let page = data.selected + 1;
        this.setState({ page: page }, () => {
            this.getRewardsHistory();
        });
    }

    refresh = () => {
        this.setState({ page: 1 }, () => {
            this.getRewardsHistory();
        });
    }

    renderLoader = () => {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="sk-spinner-loader">
                        <div className="sk-spinner sk-spinner-double-bounce">
                            <div className="sk-double-bounce1"></div>
                            <div className="sk-double-bounce2"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    warnUsed = (rewardId) => {
        this.setState({ warnUsed: rewardId })
    }

    markUsed = (rewardId) => {
        rewardClient.markAsUsed(rewardId)
            .then(reward => {
                if (reward.completed_at) {
                    const rewards = this.state.rewards;
                    const index = rewards.findIndex(r => r.id === reward.id);
                    if (index > -1) rewards[index].completed_at = reward.completed_at;
                    this.setState({ rewards })
                }
            })
        this.warnUsed(null);
    }

    renderCompletedAt = (reward, dateFormat) => {
        if (!reward.is_gift) {
            if (reward.completed_at) return dateFormat(reward.completed_at, "mediumDate")
            else return (
                <div>
                    <button className="btn btn-primary btn-sm" onClick={() => this.warnUsed(reward.id)}>Redeem</button>
                    <SweetAlert
                        show={this.state.warnUsed === reward.id}
                        type="warning"
                        title="Are you sure?"
                        showCancelButton
                        text="This operation can not be undone."
                        onConfirm={() => this.markUsed(reward.id)}
                        onCancel={() => this.warnUsed(null)}
                        onOutsideClick={() => this.warnUsed(null)}
                    />
                </div>
            );
        }
    }

    render() {
        let dateFormat = require('dateformat');
        const { rewards } = this.state;
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="full-height-scroll">
                        {this.state.loading && this.renderLoader()}
                        {!this.state.loading &&
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <tbody>
                                        {rewards.map((reward, index) => (
                                            <tr key={"reward-" + index}>
                                                <td style={{ padding: '5px' }}>{reward.employee &&
                                                    <div>
                                                        <span className="client-avatar">
                                                            <img className="img-circle" src={reward.employee.avatar_url} />
                                                        </span>
                                                        <span style={{ marginLeft: '10px' }}>{reward.employee.full_name}</span>
                                                    </div>
                                                }</td>
                                                <td>{reward.title}</td>
                                                <td className={reward.points < 0 ? 'balance-negative' : 'balance-positive'}><Coin width="16px" /> {reward.points}</td>
                                                <td>{reward.price}</td>
                                                <td>{dateFormat(reward.date, "mediumDate")}</td>
                                                <td width="110" className='text-center'>{this.renderCompletedAt(reward, dateFormat)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                        {rewards.length > 0 && this.state.pageCount > 1 &&
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <ReactPaginate
                                        previousLabel={'previous'}
                                        nextLabel={'next'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'paginate_button'}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default RewardsTransactionsList;
