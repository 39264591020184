import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { activityActionClient } from '../../clients/ActivityActionClient';
import HustleFeedItemModalForm from './HustleFeedItemModalForm';
import RawHtml from "react-raw-html";
import SweetAlert from 'sweetalert-react';
import { toastrNotification } from '../../helpers/Toastr';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const DragHandle = sortableHandle(() => <FontAwesomeIcon icon={faTrashAlt}/>);

const SortableFeedItem = SortableElement(({item, openEditForm, showDeleteWarningModal}) => {
  return (
    <li className="list-group-item" key={item.id} >
      <DragHandle />
      <div>
        <RawHtml.div>{ item.message }</RawHtml.div>
        <button type="button" className="btn btn-xs btn-default" onClick={() => openEditForm(item)}><FontAwesomeIcon icon={faPencilAlt}/> Edit</button>
        <button type="button" className="btn btn-xs btn-default" onClick={() => showDeleteWarningModal(item)}><FontAwesomeIcon icon={faTrashAlt}/> Delete</button>
      </div>
    </li>
  )
});

const SortableFeedItemsList = SortableContainer(({feedItems, openEditForm, showDeleteWarningModal}) => {
  return (
    <ul className="list-unstyled list-group clear-list hustle-feed-items">
      {feedItems.map((item, index) => (
        <SortableFeedItem key={`item-${item.id}`} index={index} item={item} showDeleteWarningModal={showDeleteWarningModal} openEditForm={openEditForm} />
      ))}
    </ul>
  );
});

class HustleFeedSettings extends Component {
  
  state = {
    feedItems: []
  }
  
  componentDidMount() {
    this.getHustleFeedItems()
  }
  
  getHustleFeedItems = () => {
    activityActionClient.getActivityActions().
    then((feedItems) => {
      this.setState({ feedItems })
    });
  }
  
  openEditForm = (item) => {
    this.refs.hustleFeedItemForm.open(item);
  }
  
  openForm = () => {
    this.refs.hustleFeedItemForm.open();
  }
  
  closeForm = () => {
    this.refs.hustleFeedItemForm.close();
  }
  
  showDeleteWarningModal = (item) => {
    this.setState({ showDeleteWarning: true, selectedItemId: item.id });
  }
  
  onSortEnd = ({oldIndex, newIndex}) => {
    const sortedFeedItems = arrayMove(this.state.feedItems, oldIndex, newIndex);
    this.setState({ feedItems: sortedFeedItems });
    const itemIds = sortedFeedItems.map(item => item.id); 
    activityActionClient.sortActions(itemIds);
  }
  
  deleteFeedItem = () => {
    this.setState({ showDeleteWarning: false });
    if (!this.state.selectedItemId) { return };
    activityActionClient.deleteAction(this.state.selectedItemId).then((response) => {
      if (response.success == true) {
        toastrNotification({ success: true, message: 'Hustle feed item was successfully deleted' });
        this.getHustleFeedItems();
      } else {
        toastrNotification({ success: false, message: 'Unable to delete hustle feed item' });
      }
      this.setState({ selectedItemId: null });
    });
  }
      
  render() {
    return (
        <div>          
          
          <SortableFeedItemsList 
            feedItems={this.state.feedItems} 
            onSortEnd={this.onSortEnd}
            useDragHandle 
            openEditForm={this.openEditForm}
            showDeleteWarningModal={this.showDeleteWarningModal}
          />
          
          <div className="modal-footer">
            <button type="button" className="btn btn-sm btn-default" onClick={() => this.openForm()}><FontAwesomeIcon icon={faPlus}/> Add New</button>
          </div>
          
          <HustleFeedItemModalForm  
            reloadFeedItems={this.getHustleFeedItems}
            ref="hustleFeedItemForm" 
          />
          
          <SweetAlert
            show={this.state.showDeleteWarning}
            type="warning"
            title="Are you sure?"
            confirmButtonColor="#DD6B55"
            showCancelButton
            text="This operation can not be undone."
            onConfirm={() => this.deleteFeedItem()}
            onCancel={() => this.setState({ showDeleteWarning: false, selectedItemId: null })}
          />
        </div>
      )
   }
}

export default HustleFeedSettings;
