import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import Input from '../form_components/Input'
import DateField from '../form_components/DateField'
import Select from '../form_components/Select';
import moment from 'moment';
import { client } from '../Client';

import 'react-datepicker/dist/react-datepicker.css';

class NewEmployeeForm extends Component {

  state = {
    showModal: false,
    canSubmit: false,
    baseErrors: [],
    departmentsList: [],
    fields: {
      first_name: '',
      last_name: '',
      department_id: '',
      position: '',
      date_of_birth: '',
      employment_date: '',
      email: '',
      work_phone: '',
      cell_phone: '',
      home_phone: '',
      password: '',
      password_confirmation: ''
    }
  };
  
  close = () => {
    this.setState({ showModal: false, baseErrors: [], fields: {} });
  }

  open = () => {
    this.getDepartmentsList();
    this.setState({ showModal: true });
  }

  getDepartmentsList = () => {
    client.getEmployeeDepartmentsList().then((departments) => {
      this.setState({ departmentsList: departments.map((dep) => ({value: dep.id, title: dep.title}))  })
    });
  }
  
  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  resetForm = () => {
     formsy.reset();
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }

  onDateChange = (field, date) => {
    const fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields: fields });
  }  

  formSubmit = (data) => {
    client.createEmployee(data)
    .then((employee) => {
        if (employee.id) {
          this.props.onAddNewEmployee(employee);
          this.setState({ baseErrors: [] });
          this.close();
        } else {
          let formErrors = employee;
          if (formErrors.base) {
            this.setState({ baseErrors: formErrors.base });
          } else {
            this.setState({ baseErrors: [] });
          };
          delete formErrors.base;
          this.refs.form.updateInputsWithError(formErrors);
        }
    });
  }

 render() {

    return (
<div>
  <button className="btn btn-white btn-outline" onClick={this.open}>
    <FontAwesomeIcon icon={faPlus} /> Add
  </button>

  <Modal show={this.state.showModal} onHide={this.close}>
    <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal employee-form">
      <Modal.Body>
      <div className="row">
        {this.state.baseErrors.length > 0 && 
        <div className="alert alert-danger">
          <ul>
            {
              this.state.baseErrors.map((message, index) => (
                <li key={"baseFormError"+index}>{message}</li>
              ))
            }
          </ul>
        </div>
        }
        <h3 className="m-b col-md-12">Basic Information</h3>
        <div className="form-group col-sm-6">
          <label htmlFor="first_name" className="sr-only"></label>
          <Input name="first_name" className="form-control" placeholder="First Name *" value={this.state.fields.first_name} onChange={this.onInputChange} required />
        </div>            
        <div className="form-group col-sm-6">
          <label htmlFor="last_name" className="sr-only"></label>
          <Input name="last_name" className="form-control" placeholder="Last Name *" value={this.state.fields.last_name} onChange={this.onInputChange} required />
        </div>            
        <div className="form-group col-sm-6">
          <label htmlFor="department" className="sr-only"></label>
          <Select
            value={this.state.fields.department_id}
            onChange={this.onInputChange}
            options={this.state.departmentsList}
            className="form-control"
            name='department_id'
          />
        </div>            
        <div className="form-group col-sm-6">
          <label htmlFor="position" className="sr-only"></label>
          <Input name="position" className="form-control" placeholder="Position" title="Position" value={this.state.fields.position} onChange={this.onInputChange} />
        </div>
        <div className="form-group col-sm-6">
          <label htmlFor="employment_date" className="sr-only"></label>
          <DateField name="employment_date" className="form-control" placeholder="Hire Date" minDate={moment().subtract(75, 'years')} maxDate={moment().endOf('month')} value={this.state.fields.employment_date} onChange={(date) => this.onDateChange('employment_date', date)}/>
        </div>          
        <div className="form-group col-sm-6">
          <label htmlFor="date_of_birth" className="sr-only"></label>
          <DateField name="date_of_birth" className="form-control" placeholder="Date of Birth" maxDate={moment().subtract(14, 'years')} value={this.state.fields.date_of_birth} onChange={(date) => this.onDateChange('date_of_birth', date)}/>
        </div>
          
        <h3 className="m-b col-md-12">Contact Information</h3>
        <div className="form-group col-sm-6">
          <label htmlFor="email" className="sr-only"></label>
          <Input name="email" className="form-control" validations="isEmail" validationError="This is not a valid email" placeholder="Email" value={this.state.fields.email} onChange={this.onInputChange} />
        </div>        
        <div className="form-group col-sm-6">
          <label htmlFor="work_phone" className="sr-only"></label>
          <Input name="work_phone" className="form-control" placeholder="Work Phone" value={this.state.fields.work_phone} onChange={this.onInputChange} />
        </div>
        <div className="form-group col-sm-6" style={{marginBottom: "20px"}}>
          <label htmlFor="cell_phone" className="sr-only"></label>
          <Input name="cell_phone" className="form-control" placeholder="Mobile Phone" value={this.state.fields.cell_phone} onChange={this.onInputChange} />
        </div>
        <div className="form-group col-sm-6" style={{marginBottom: "20px"}}>
          <label htmlFor="home_phone" className="sr-only"></label>
          <Input name="home_phone" className="form-control" placeholder="Home Phone" value={this.state.fields.home_phone} onChange={this.onInputChange} />
        </div>

        <h3 className="m-b col-md-12">Password</h3>
        <div className="form-group col-sm-6">
          <label htmlFor="password" className="sr-only"></label>
          <Input type="password" name="password" className="form-control" placeholder="Password *" value={this.state.fields.password} onChange={this.onInputChange} required />
        </div>
        <div className="form-group col-sm-6">
          <label htmlFor="password_confirmation" className="sr-only"></label>
          <Input type="password" name="password_confirmation" className="form-control" placeholder="Password Confirmation *" validations="equalsField:password" 
            validationError="Password confirmation doesn't match Password" value={this.state.fields.password_confirmation} onChange={this.onInputChange} required />
        </div>
      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button bsClass="btn btn-primary" type="submit" disabled={!this.state.canSubmit} >Save</Button>
        <Button onClick={this.close}>Close</Button>
      </Modal.Footer>
    </Formsy>

  </Modal>
</div>
    );
  }
}

export default NewEmployeeForm;
