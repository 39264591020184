//@flow
import React, { Component } from 'react';
import { client } from '../Client';
import IBox from '../common/IBox';
import Coin from '../common/Coin';

class PointsAllocationReportBox extends Component {

  state = { 
    totalPointsAllocated: null,
    totalPointsRewarded: null
  }
  
  componentDidMount() {
    this.getUniversityPointsInfo()
  }
  
  getUniversityPointsInfo() {
    client.getUniversityPointsInfo().
    then((response) => {
      this.setState({ totalPointsAllocated: response.points_allocated, totalPointsRewarded: response.points_rewarded })
    });
  }
    
  render() {
      return (
        <div className="ibox" id="pointsAllocationSettings">
            <div className="ibox-title">
              <h5>Points Allocation</h5>
            </div>
            <div className="ibox-content">
              <ul className="list-unstyled list-group clear-list">
                <li>
                  <div>
                    <label>Total points allocated:  </label> <Coin width="16px" /> <b>{this.state.totalPointsAllocated}</b> <small>EZ-points</small> 
                  </div>
                </li>
                <li>
                  <div>
                    <label>Total points rewarded:  </label> <Coin width="16px" /> <b>{this.state.totalPointsRewarded}</b> <small>EZ-points</small>
                  </div>
                </li>
              </ul>
            </div>
        </div>    
      )
  }

}


export default PointsAllocationReportBox;
