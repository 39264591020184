import React, { Component } from "react";
import "./Information.css";
import BottomSheet from "./BottomSheet";
import moment from "moment";

class Inforamtion extends Component {
  state = {
    openSheet: false,
  };
  openSheet = () => {
    this.setState({ openSheet: true });
  };

  closeSheet = () => {
    this.setState({ openSheet: false });
  };
  render() {
    const timeStamp = moment(this.props.reel?.timestamp)
    const date = timeStamp.format('DD MMM');
    const year = timeStamp.format('YYYY');
    return (
      <BottomSheet
        openSheet={this.state.openSheet}
        onClose={this.closeSheet}
        height={25}
      >
        <div className="information-main">
          <div className="info-header">
            <h3>Information</h3>
          </div>
          <div className="info-message">
            <h4>
              {this.props.reel?.description}
            </h4>
          </div>
          <div className="info-user">
            <div className="user-img avatar">
              <img className="img-circle" style={{width: '24px', height: '24px'}} src={this.props.reel?.employee.avatar_url} />
            </div>
            <div className="user-name">
              <p>{this.props.reel?.employee.full_name}</p>
            </div>
          </div>
          <div className="extra-info">
            <div className="likes">
              <h3>{this.props.reel?.likesNumber || '0'}</h3>
              <p>Likes</p>
            </div>
            <div className="likes">
              <h3>--</h3>
              <p>views</p>
            </div>
            <div className="likes">
              <h3>{date}</h3>
              <p>{year}</p>
            </div>
          </div>
        </div>
      </BottomSheet>
    );
  }
}

export default Inforamtion;
