import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';

class RewardClient {
  
  createReward(rewardTypeId) {
    return client.callApi(Config.API_BASE + '/rewards.json', {}, 'POST', {reward_type_id: rewardTypeId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  getRewardsData() {
    return client.callApi(Config.API_BASE + '/rewards/rewards_data.json')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

  getRewardsHistory(page, employeeId) {
    let url = Config.API_BASE + '/rewards/rewards_history.json?page=' + page;
    if(employeeId) url += ('&id=' + employeeId);
    return client.callApi(url)
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }

  getRewardsHistoryUniversity(page) {
    let url = Config.API_BASE + '/rewards/rewards_history_university.json?page=' + page;
    return client.callApi(url)
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }

  getInhouseNonRedeemedRewardsHistoryUniversity(page) {
    let url = Config.API_BASE + '/rewards/inhouse_non_redeemed_rewards_history_university.json?page=' + page;
    return client.callApi(url)
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }

  markAsUsed(rewardId) {
    return client.callApi(Config.API_BASE + '/rewards/'+rewardId+'/mark_reward_used.json', {}, 'PUT')
      .then((reward) => reward)
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
}

/*
getCheapestRewardType() {
  return client.callApi(Config.API_BASE + '/reward_types/get_cheapest_reward_type.json')
    .catch((error) => {
      client.handleApiError(error);
      return [] ;
    });
} 
*/


export const rewardClient = new RewardClient();
