import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { conversationClient } from '../../clients/ConversationClient';
import { Redirect } from 'react-router-dom';
import Employee from '../../models/Employee';
import { Link } from 'react-router-dom';
import { client } from '../Client';
import { toastrNotification } from '../../helpers/Toastr';

class NewConversationModal extends Component {

  state = {
    employees: [],
    showModal: false,
    shouldRedirect: false,
    conversationId: null
  };

  componentDidMount() { }

  getEmployees = () => {
    client.getConversationColleagues()
    .then((employees) => {
      this.setState({ employees: employees })
     });
  }

  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.getEmployees(true);
    this.setState({ showModal: true });
  }

  selectEmployee = (employee) => {
    conversationClient.createConversation(employee.id)
    .then((response) => {
      if (response.conversation_id) {
        this.setState({ shouldRedirect: true, conversationId: response.conversation_id })
      } else {
        toastrNotification({success: false, title: 'Unable to create conversation.'});
      }
    })
  }

  handleSearch = (evt) => {
    evt.preventDefault();
    const searchExpr = new RegExp(this.refs.search.value, "i");
    const nextEmployees = [...this.state.employees].map((employee) => (employee.hideByName(searchExpr)));
    this.setState({ employees: nextEmployees });
  }

    render() {
        if (this.state.shouldRedirect && this.state.conversationId) {
          return (
            <Redirect to={`/conversation/${this.state.conversationId}`} />
          );
        } else {
        return (
          <Modal show={this.state.showModal} onHide={this.close} >
            <Modal.Header closeButton>
              <Modal.Title>
                New Conversation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-sm-12">
                  <form className="clearfix">
                    <div className="input-group">
                      <input type="text" placeholder="Search " ref="search" className="input form-control"  onChange={this.handleSearch} />
                      <span className="input-group-btn">
                        <button type="submit" className="btn btn-primary"> <FontAwesomeIcon icon={faSearch}/> Search</button>
                      </span>
                    </div>
                  </form>
                  <div className="clients-list new-conversation-recipients-list">
                      <div className="full-height-scroll">
                          <div className="table-responsive">
                              <table className="table table-striped table-hover">
                                  <tbody>
                                  {
                                     this.state.employees.map((employee) => (
                                       <tr key={'colleague-' + employee.id} onClick={() => this.selectEmployee(employee)} className={employee.visible ? '' : 'hidden'}>
                                         <td className="client-avatar">
                                           <div className={employee.is_online ? "online" : "offline"}>
                                              <img alt="image" src={employee.avatar_image_url()} />
                                           </div>
                                         </td>
                                         <td>
                                           <a data-toggle="tab" href="#contact-1" className="client-link">{employee.full_name()}</a>
                                         </td>
                                         <td>{employee.department}</td>
                                       </tr>
                                     ))
                                  }
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
          </Modal>
        ) }
    }

}

export default NewConversationModal;
