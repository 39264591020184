import React, { Component } from 'react';
import { client } from '../Client';
import { Link } from 'react-router-dom';
import { adminLoggedIn, ownerLoggedIn, quicktrainEnabled, pickupshiftEnabled, renderProfileIcon } from '../../helpers/TopMenu';
import AppIcon from '../icons/AppIcon';
import { solidIcons } from '../icons/lib';

class EmployeeMenuItems extends Component {

  render() {
    const path = this.props.currentPath

    return (
      <ul className={this.props.containerClass} >
        {this.props.isMobileMenu &&
          <li className="nav-mobile-profile-item">
            <Link to="/profile" className="profile-link">
              {renderProfileIcon()}
              <div className="menu-item-label">
                <div className="current-user-name">{client.currentUser().first_name}</div>
                <small>View your profile</small>
              </div>
            </Link>
          </li>
        }
        {quicktrainEnabled() &&
          <li className="app-nav-link">
            <Link to="/courses">
              <AppIcon icon={solidIcons.TrainingSolid} /> Training
            </Link>
          </li>
        }
        <li className="app-nav-link">
          <Link to="/colleagues">
            <AppIcon icon={solidIcons.ColleaguesSolid} /> My Colleagues
          </Link>
        </li>
        <li className="app-nav-link">
          <Link to="/groups">
            <AppIcon icon={solidIcons.GroupsSolid} /> Groups
          </Link>
        </li>
        <li className="app-nav-link">
          <Link to="/rewards">
            <AppIcon icon={solidIcons.RewardSolid} /> My Rewards
          </Link>
        </li>
        <li className="app-nav-link ">
          <Link to="/transactions">
            <AppIcon icon={solidIcons.History} /> Points History
          </Link>
        </li>
        {this.props.acknowledgementIsAllowed &&
          <li className="app-nav-link">
            <Link to="/acknowledgements">
              <AppIcon icon={solidIcons.AcknowledgementNew} /> Acknowledgements
            </Link>
          </li>
        }
        <li className="app-nav-link">
          <Link to="/calendar">
            <AppIcon icon={solidIcons.CalendarSolid} /> Calendar
          </Link>
        </li>
        {pickupshiftEnabled() && this.props.pickShiftIsAllowed &&
          <li className="app-nav-link">
            <Link to="/pick_up_shift_requests">
              <AppIcon icon={solidIcons.PickShiftNew} /> Pick Up Shift
            </Link>
          </li>
        }
        {this.props.pollIsAllowed &&
          <li className="app-nav-link">
            <Link to="/polls">
              <AppIcon icon={solidIcons.CheckList} /> Quick Polls
            </Link>
          </li>
        }
        {this.props.isMobileMenu &&
          <li className="nav-mobile-logout-item">
            <Link to='/logout'>
              <AppIcon icon={solidIcons.Power} /> Log Out
            </Link>
          </li>
        }
      </ul>
    )
  }
}
export default EmployeeMenuItems;
