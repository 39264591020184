import Config from 'config';
import { mediumDateFormat } from '../helpers/Formatter';
import moment from 'moment';

class University {
  constructor(obj) {
    for (var prop in obj) {
      if ((prop === 'year_started_at' || prop === 'created_at') && obj[prop]) {
        this[prop] = moment.parseZone(obj[prop]);        
      } else {
        this[prop] = obj[prop]; 
      }      
    }
  }
  
  year_started_at_value() {
    if(this.year_started_at) {
        return mediumDateFormat(this.year_started_at) + " - " + mediumDateFormat(this.year_started_at.clone().add(1, 'years'));
    };
  }
  
  member_since_value() {
    return mediumDateFormat(this.created_at);
  }
  
}

export default University;
