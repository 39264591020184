import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import Input from '../form_components/Input'
import Select from '../form_components/Select';
import { toastrNotification } from '../../helpers/Toastr';
import { client } from '../Client';
import Formsy, { addValidationRule } from 'formsy-react';
import { isEmail, isCellPhone } from '../../helpers/ValidationRules';

addValidationRule('EmailOrPhone', function (values, value) {
  return isEmail(value) || isCellPhone(value);
});

class InviteEmployeeForm extends Component {

  state = {
    showModal: false,
    canSubmit: false,
    baseErrors: [],
    departmentsList: [],
    loading: false,
    fields: {
      first_name: '',
      last_name: '',
      university_department_id: '',
      email_or_phone: ''
    }
  };
  
  close = () => {
    this.setState({ showModal: false, baseErrors: [] });
  }

  open = () => {
    this.getDepartmentsList();
    this.setState({ showModal: true, fields: {first_name: '', last_name: '', university_department_id: '', email_or_phone: ''} });
  }

  getDepartmentsList = () => {
    client.getEmployeeDepartmentsList().then((departments) => {
      this.setState({ departmentsList: departments.map((dep) => ({value: dep.id, title: dep.title}))})
    });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  resetForm = () => {
     formsy.reset();
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }

  formSubmit = (data) => {
    this.setState({loading: true});
    let formData = data;
    if (isCellPhone(formData.email_or_phone)) {
      formData.phone = formData.email_or_phone
    } else {
      formData.email = formData.email_or_phone
    };
    delete formData.email_or_phone;
    client.inviteEmployee(data).then((invitation) => {
      this.setState({loading: false});
        if (invitation.id) {
          //this.props.onInvite(invitation);
          this.setState({ baseErrors: [] });
          toastrNotification({success: true, title: 'Invitation has been sent.'});
          this.close();
        } else {
          let formErrors = invitation;
          if (formErrors.base) {
            this.setState({ baseErrors: formErrors.base });
          } else {
            this.setState({ baseErrors: [] });
          };
          if (formErrors.phone || formErrors.email) {
            formErrors.email_or_phone = formErrors.phone || formErrors.email || ""
          };
          if (formErrors.university_department) {
            formErrors.university_department_id = formErrors.university_department
          };
          delete formErrors.base;
          delete formErrors.phone;
          delete formErrors.email;
          delete formErrors.university_department;
          this.refs.form.updateInputsWithError(formErrors);
        }
    });
  }

 render() {
    return (
      <div>
        <div onClick={this.open}>
          {this.props.children}
        </div>
        <Modal show={this.state.showModal} onHide={this.close}>
          <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="employee-form">
            <Modal.Body>
            <div className="row">
              <div className="col-sm-12">
                {this.state.baseErrors.length > 0 && 
                  <div className="alert alert-danger">
                    <ul>
                    {
                      this.state.baseErrors.map((message, index) => (
                        <li key={"baseFormError"+index}>{message}</li>
                      ))
                    }
                    </ul>
                  </div>
                }
                <h3 className="m-b">Invite Employee</h3>                     
                <div className="form-group">
                  <label htmlFor="email_or_phone">Email Or Phone *</label>
                  <Input name="email_or_phone" className="form-control" validations="EmailOrPhone" placeholder="Email Or Phone Number" validationErrors={{EmailOrPhone: 'This is not valid email or mobile phone'}}  value={this.state.fields.email_or_phone} onChange={this.onInputChange} required />
                </div>        
                <div className="form-group">
                  <label htmlFor="university_department_id">Group *</label>
                  <Select
                    name='university_department_id'
                    value={this.state.fields.university_department_id}
                    placeholder="Select Group *"
                    onChange={this.onInputChange}
                    options={this.state.departmentsList}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="first_name" >First Name</label>
                  <Input name="first_name" className="form-control" placeholder="First Name" value={this.state.fields.first_name} onChange={this.onInputChange} />
                </div>            
                <div className="form-group">
                  <label htmlFor="last_name" >Last Name</label>
                  <Input name="last_name" className="form-control" placeholder="Last Name" value={this.state.fields.last_name} onChange={this.onInputChange} />
                </div>
              </div>  
            </div>
            </Modal.Body>
            <Modal.Footer>
              <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Invite</LaddaButton>        
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
          </Formsy>

        </Modal>
      </div>
    );
  }
}

export default InviteEmployeeForm;
