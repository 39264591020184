import React, { Component } from 'react';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt} from '@fortawesome/free-regular-svg-icons/faCalendarAlt'
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import { faAngleRight} from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { client } from '../Client';

class SelectRafflePrizeDialog extends Component {

  proceed = () => {
    this.props.proceed() 
  } 
      
  isProceedButtonDisabled = () => {
    return !this.props.selectedPrizeType
  }
  
  itemClassName = (type) => {
    let classname = 'raffle-prize-item';
    if (this.props.selectPrizeType === type) classname += ' selected';
    return classname;
  }
  
  handleSelectPrizeType(evt, prizeType) {
    evt.preventDefault();
    this.props.onSelectPrizeType(prizeType)
  }
  
 render() {
   
    return (
        <div>
          <Modal.Header closeButton>
            <Modal.Title>Create Raffle</Modal.Title> 
          </Modal.Header>
          <Modal.Body>             
            <div className='wrapper wrapper-content'>
               <div className="row">
                 <div className='col-sm-12'>
                   <div className={this.itemClassName('Event')} onClick={(evt) => this.handleSelectPrizeType(evt, 'Event')}>
                     <div className="pull-left">
                       <FontAwesomeIcon icon={faCalendarAlt} className="fa raffle-prize-type-icon" size="3x"/>
                     </div>
                     <div className="pull-left prize-title">
                       <h3>Event</h3>
                     </div>
                     { this.props.selectedPrizeType === 'Event' &&
                       <div className="pull-right">
                         <FontAwesomeIcon icon={faAngleRight} className="fa raffle-prize-type-icon" size="3x"/>
                       </div>
                     }  
                     <div className="clearfix"/>
                   </div>
                 </div>
               </div>
               <div className="row">
                 <div className='col-sm-12'>
                   <div className={this.itemClassName('Reward')} onClick={(evt) => this.handleSelectPrizeType(evt, 'Reward')}>
                     <div className="pull-left">
                      <FontAwesomeIcon icon={faGift} className="fa raffle-prize-type-icon" size="3x"/>
                     </div>
                     <div className="pull-left prize-title">
                       <h3>In-House Reward</h3>
                     </div>
                     { this.props.selectedPrizeType === 'Reward' &&
                       <div className="pull-right">
                         <FontAwesomeIcon icon={faAngleRight} className="fa raffle-prize-type-icon" size="3x"/>
                       </div>
                     }  
                     <div className="clearfix"/>
                   </div>
                 </div>
               </div>
               <div className="row">
                 <div className='col-sm-12'>
                   <div className={this.itemClassName('Gift Card')} onClick={(evt) => this.handleSelectPrizeType(evt, 'Gift Card')}>
                     <div className="pull-left">
                       <FontAwesomeIcon icon={faShoppingCart} className="fa raffle-prize-type-icon" size="3x"/>
                     </div>
                     <div className="pull-left prize-title">
                       <h3>Gift Card</h3>
                     </div>
                     { this.props.selectedPrizeType === 'Gift Card' &&
                       <div className="pull-right">
                         <FontAwesomeIcon icon={faAngleRight} className="fa raffle-prize-type-icon" size="3x"/>
                       </div>
                     }  
                     <div className="clearfix"/>
                   </div>
                 </div>
               </div>
               <p><small>By clicking <b>Next</b>, you agree to the <a onClick={() => client.showRaffleRules()}>Ezzely Raffle Rules and Conditions</a>.</small></p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-primary btn-sm" disabled={this.isProceedButtonDisabled()} onClick={this.proceed}>Next</button> 
            <button type="button" className="btn btn-default btn-sm" onClick={() => this.props.onCloseBtnClick()}>Close</button>
          </Modal.Footer>
        </div>    
    );
  }
}

export default SelectRafflePrizeDialog;
