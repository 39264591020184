import React from 'react';
import { client } from '../components/Client';

export function activeRoute(routeName: string, path) {
  return path.indexOf(routeName) > -1 ? "active" : "";
}

export function routeIsEqual(routeName: string, path) {
  return path.indexOf(routeName) > -1 ? true : false;
}

export function adminLoggedIn() {
  return client.adminLoggedIn();
}

export function ownerLoggedIn() {
  return client.ownerLoggedIn();
}

export function quicktrainEnabled() {
  return !client.noQuicktrain();
}

export function pickupshiftEnabled() {
  return !client.noPickupshift();
}

export function renderProfileIcon() {
  let src = client.currentUser().avatar_image_url();
  let classname = "current-user-avatar";
  if(src != 'img/avatar_missing.png') {
    classname += ' img-circle transparent-bg';
  } else {
    src = 'img/header/icon_profile.png';                
  }
  return (<img className={classname} src={src}/>);
}
