import React, { Component } from 'react';
import { groupClient } from '../clients/GroupClient';
import GroupsList from '../components/groups/GroupsList';
import NewGroupForm from '../components/groups/NewGroupForm';
import { toastrNotification } from './../helpers/Toastr';
import { client } from '../components/Client.js';
import { Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'


class GroupsView extends Component {
  
  state = {
    groups: [],
    cnt: 0
  }

  componentDidMount() {
    this.getGroups();
  }

  getGroups = () => {
    groupClient.getGroups()
    .then((groups) => {
      for (let g of groups) { g.visible = true }
      this.setState({ groups: groups, cnt: groups.length })
     });
  }
  
  joinGroup = (group) => {
    this.setState({ loading: true })
    groupClient.joinGroup(group.id).then((department) => {
      if (department.id) {
        const mGroups = this.state.groups.map((g) => {
            if (g.id === group.id) {
              let mGroup = Object.assign({}, g, department);
              return mGroup
            } else {
              return g
            }
        });
        this.setState({ groups: mGroups });
        const message = department.current_user_is_candidate 
                        ? "Your request has been sent to group admin."
                        : "You successfully joined the group." 
        toastrNotification({ success: true, title: message });
      } else {
        toastrNotification({ success: false, title: response.error_message });
      }
    });
  }
      
  updateGroupVisibility = (group, searchExpr) => {
    group.visible = group.title.match(searchExpr) !== null;
    return group
  }
  
  handleSearch = (evt) => {
    evt.preventDefault();
    const searchExpr = new RegExp(this.refs.search.value, "i");
    const nextGroups = [...this.state.groups].map((group) => this.updateGroupVisibility(group, searchExpr));
    const cnt = nextGroups.filter((group) => (group.visible)).length
    this.setState({ groups: nextGroups, cnt: cnt });
  }
  
  openNewGroupForm = () => {
    this.refs.newGroupForm.open()
  }
  
  handleAddNewGroup = () => {
    this.getGroups()
  }
    
  render() {
      const otherGroups = this.state.groups.filter(g => !g.current_user_is_member);
      const joinedGroups = this.state.groups.filter(g => g.current_user_is_member);
    
      return (
        <div className="wrapper wrapper-content animated fadeIn">
          <div className="row">
            <div className="col-sm-12 col-lg-8 col-lg-offset-2">
                <div className="ibox">
                    <div className="ibox-content">
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="pull-left">Groups <small>({this.state.cnt})</small></h2>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="table-actions">
                              <div className="html5buttons">
                                <div className="dt-buttons btn-group">
                                  <button className="btn btn-primary" onClick={() => this.openNewGroupForm()}><FontAwesomeIcon icon={faPlus} /> New Group</button>
                                  <NewGroupForm 
                                    ref="newGroupForm"
                                    addNewGroup={this.handleAddNewGroup}
                                  />
                                </div>
                              </div>
                              <form>
                                <div className="input-group">
                                  <input type="text" placeholder="Search " ref="search" className="input form-control" onChange={this.handleSearch} />
                                  <span className="input-group-btn">
                                     <button type="submit" className="btn btn-primary"><FontAwesomeIcon icon={faSearch} /></button>
                                  </span>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="row" style={{marginTop: "20px"}}>
                          <div className="col-md-12">
                            <Tabs defaultActiveKey={1} animation={false} id="groups-tabs">
                              <Tab eventKey={1} title="Already a member">
                                <GroupsList groups={joinedGroups} onJoinGroup={this.joinGroup} />
                              </Tab>
                              <Tab eventKey={2} title="Other groups" >
                                <GroupsList groups={otherGroups} onJoinGroup={this.joinGroup} />
                              </Tab>
                            </Tabs>
                          </div>
                        </div>  
                    </div>
                </div>
            </div>
          </div>
        </div>
      )
  }    
  
}

export default GroupsView
