import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { statusLabel } from './../../helpers/Employees';
import GroupsPopover from '../groups/GroupsPopover';

class EmployeeRow extends Component {
  /*
    statusLabel = (employee) => {
      switch (employee.status) 
         {
             case 0: return (<span className="label label-success">Active</span>);
             case 1: return (<span className="label label-danger">Terminated</span>);
         }        
    }
  */
  handleEmployeeClick = (employee) => {
    this.props.onSelectEmployee(employee);
  };

  renderOnlineStatus = (employee) => {
    return <span><small><i>{employee.online_status()}</i></small></span>;
  }

  render() {
    const { employee } = this.props;

    return (
      <tr className={employee.visible ? '' : 'hidden'}>
        <td className="client-avatar" onClick={() => this.handleEmployeeClick(employee)}>
          <div className={employee.is_online ? "online" : "offline"}>
            <img alt="image" src={employee.avatar_image_url()} />
          </div>
        </td>
        <td onClick={() => this.handleEmployeeClick(employee)} style={{ cursor: 'pointer' }}>
          <div className="client-link">{employee.full_name()}</div>
          {this.renderOnlineStatus(employee)}
        </td>
        <td><GroupsPopover id={'groups' + employee.id} groups={employee.departments} /></td>
        <td>
          {employee.email &&
            <div><FontAwesomeIcon icon={faEnvelope} /> {employee.email_value()}</div>
          }
        </td>
        {this.props.showStatus &&
          <td className="client-status">
            {statusLabel(employee)}
          </td>
        }
      </tr>
    )
  }
}

export default EmployeeRow;
