import React, { Component } from 'react';
import SweetAlert from 'sweetalert-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import 'sweetalert/dist/sweetalert.css';

class RemoveCredentialsItemLink extends Component {

  state = {
    show: false
  }
  
  handleCredentialsItemRemove = () => {
    this.props.onRemoveCredentialsItem(this.props.credentialsItem);
    this.setState({ show: false });
  }
  
  render() {
      return (
        <div>
          <button className="btn btn-white btn-sm" onClick={() => this.setState({ show: true })}> 
            <FontAwesomeIcon icon={faTrashAlt} /> Delete
          </button>
          <SweetAlert
            show={this.state.show}
            type="warning"
            title="Are you sure?"
            showCancelButton
            text="This operation can not be undone."
            onConfirm={() => this.handleCredentialsItemRemove()}
            onCancel={() => this.setState({ show: false })}
          />
        </div>  
      )  
  }
      
}

export default RemoveCredentialsItemLink ;
