// @flow
import React, { Component } from 'react'
import Gallery from 'react-photo-gallery'
import Measure from 'react-measure'
import Lightbox from 'react-image-lightbox'
import VideoPlayerModal from './VideoPlayerModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay'

const imgStyle = {
  transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
};

const cont = {
  backgroundColor: "#eee",
  cursor: "pointer",
  overflow: "hidden",
  position: "relative"
};

const Image = ({
  index,
  onClick,
  photo,
  margin,
  direction,
  top,
  left
}) => {
  if (direction === "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  };
  return (
    <div
      style={{ margin, height: photo.height, width: photo.width, display: "inline-block", ...cont }}
      className={ photo.media_type == "video" ? "video-attachment-thumbnail" : "" }
      onClick={e => onClick(e, { index, photo })} 
    >
      { photo.media_type == "video" &&
          <span><FontAwesomeIcon icon={faPlay} /></span>
      }
      <img 
        style={{ ...imgStyle }}
        //{...photo}
        src={photo.src}
        width={photo.width}
        height={photo.height}
        onClick={e => onClick(e, { index, photo })} 
      />
    </div>
  );
};

class ActivityPostAttachments extends Component {
  
  state = {
    width: -1,
    currentImage: 0
  }
  
  openLightbox = (event, obj) => {
    let attachment = this.props.attachments[obj.index];
    if (attachment.media_type == "video") {
      this.refs.videoPlayer.open(attachment.url)
    } else {
      const imageIndex = this.props.attachments.filter(at => at.media_type == "image").indexOf(attachment);
      this.setState({ currentImage: imageIndex, lightboxIsOpen: true })
    }  
  }
  
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }
  
  render() {
    const width = this.state.width;
                    
    const photos = this.props.attachments.map((attachment, i) => (
      { src: attachment.thumb_url,
        width: attachment.thumb_width || 400,
        height: attachment.thumb_height || 400,
        media_type: attachment.media_type
       }
    ));
        
    const originalPhotos = this.props.attachments.filter(at => at.media_type == "image").map((attachment, i) => (
      attachment.url 
    ));
                                  
    return (
      <div>
        <Measure bounds onResize={(contentRect) => this.setState({ width: contentRect.bounds.width })}>
          {
            ({measureRef}) => {
              if (width < 1 ){
                return <div ref={measureRef}></div>;
              }
              let columns = 1;
              if (width >= 480){
                columns = 2;
              }
              if (width >= 1024){
                columns = 3;
              }
              if (width >= 1824){
                columns = 4;
              }
              return <div ref={measureRef}><Gallery photos={photos} columns={columns} onClick={this.openLightbox} ImageComponent={Image}/></div>
            }
          }
        </Measure>
        { this.state.lightboxIsOpen &&
          <Lightbox mainSrc={originalPhotos[this.state.currentImage]}
            nextSrc={originalPhotos[(this.state.currentImage + 1) % originalPhotos.length]}
            prevSrc={originalPhotos[(this.state.currentImage + originalPhotos.length - 1) % originalPhotos.length]}
            onCloseRequest={this.closeLightbox}
            onMovePrevRequest={() => (
              this.setState({
                currentImage: (this.state.currentImage + originalPhotos.length - 1) % originalPhotos.length
              })
            )
            }
            onMoveNextRequest={() => (
                this.setState({
                  currentImage: (this.state.currentImage + 1) % originalPhotos.length
                })
              )
            }
          />
        }
        <VideoPlayerModal ref="videoPlayer"/>
      </div>
    );
 }
}

export default ActivityPostAttachments
