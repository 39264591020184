import React from 'react';
import Progress from '../common/Progress';
import Media from 'react-media';
import Desktop from './Desktop';
import Mobile from './Mobile';

class Main extends React.Component {
  
    state = {}

    render() {    
        return (
            <div id="wrapper">            
                <Progress />
                <Media query="(max-width: 767px)">
                 {matches =>
                   matches ? (
                     <Mobile {...this.props} />
                   ) : (
                     <Desktop {...this.props} />
                   )
                 }
                </Media>
            </div>
       )    
    }        

}

export default Main
