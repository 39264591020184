import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';

class ContactFields extends Component {
  
  state = {
    editMode:false,
    fields: {}
  }
  
  openEditForm = () => {
    this.setState({editMode: true,
      fields: {
        phone:   this.props.university.phone || '',
        contact_name:   this.props.university.contact_name || '',
        contact_email:   this.props.university.contact_email || '',
        contact_position:   this.props.university.contact_position || ''
      }
    });
  }  

  closeEditForm = () => {
    this.setState({editMode: false});
  }
  
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }  
    
  handleSubmit = (evt) => {
    evt.preventDefault();
    this.props.onFormSubmit(this.state.fields);
    this.closeEditForm();
  }
  
  render() {
    if (this.state.editMode) {
      return (
        <div className="form-horizontal">
          <ul className="list-unstyled">             
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>Name</label></div>
              <div className="col-sm-9">
                <input type='text' value={this.state.fields.contact_name} onChange={this.onInputChange} className='form-control' name='contact_name'/>
              </div>
            </li>
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>Position</label></div>
              <div className="col-sm-9">
                <input type='text' value={this.state.fields.contact_position} onChange={this.onInputChange} className='form-control' name='contact_position'/>
              </div>
            </li>
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>Phone</label></div>
              <div className="col-sm-9">
                <input type='text' value={this.state.fields.phone} onChange={this.onInputChange} className='form-control' name='phone'/>
              </div>
            </li>
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>Email</label></div>
              <div className="col-sm-9">
                <input type='text' value={this.state.fields.contact_email} onChange={this.onInputChange} className='form-control' name='contact_email'/>
              </div>
            </li>
          </ul>
          
          <div className="modal-footer">
            <button onClick={this.handleSubmit} className="btn btn-sm btn-primary">Save</button>  &nbsp;
            <button onClick={this.closeEditForm} className="btn btn-sm btn-default">Cancel</button>
          </div>
        </div>
      )      
    } else {
      return (
        <div>          
          <ul className="list-unstyled list-group clear-list">
            { this.props.university.contact_name && 
            <li>
              <div>
                <label>Name : </label> {this.props.university.contact_name}
              </div>
            </li>
            }            
            { this.props.university.contact_position && 
            <li>
              <div>
                <label>Position : </label> {this.props.university.contact_position }
              </div>
            </li>
            }            
            { this.props.university.phone && 
            <li>
              <div>
                <label>Phone : </label> <FontAwesomeIcon icon={faPhone}/> {this.props.university.phone}
              </div>
            </li>
            }            
            { this.props.university.contact_email && 
            <li>
              <div>
                <label>Email : </label> <FontAwesomeIcon icon={faEnvelope}/> {this.props.university.contact_email}
              </div>
            </li>
            }            
          </ul>
          
          <div className="modal-footer">
            <button type="button" className="btn btn-sm btn-default" onClick={this.openEditForm}><FontAwesomeIcon icon={faPencilAlt}/> Edit</button>
          </div>
        </div>
      )
    }
  }
}

export default ContactFields;
