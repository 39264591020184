// @flow
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import IBox from '../common/IBox';
import BasicFields from './BasicFields';
import AddressFields from './AddressFields';
import ContactFields from './ContactFields';
import SettingsPanel from './SettingsPanel';
import HustleFeedSettings from './HustleFeedSettings';
import RecognitionTagSettings from './RecognitionTagSettings';
import BadgingSystem from '../badges/BadgingSystem';

class CompanyInfo extends Component {

  submitData = (data) => {
    this.props.onUpdateUniversity(data)
  }

  render() {
    console.log(this.props.university)
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <IBox title="Basic Information">
              <BasicFields university={this.props.university} onFormSubmit={this.submitData} />
            </IBox>
          </div>

          <div className="col-md-4">
            <IBox title="Office Address">
              <AddressFields university={this.props.university} onReloadUniversity={this.props.onReloadUniversity} />
            </IBox>
          </div>

          <div className="col-md-4">
            <IBox title="Contact Information">
              <ContactFields university={this.props.university} onFormSubmit={this.submitData} />
            </IBox>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <IBox title="Access Settings">
              <h3>Who has access to the next functions:</h3>
              <br />
              <SettingsPanel
                option={"calendar"}
                humanOptionTitle={"Calendar"}
                labelText="Create calendar events"
              />
              <SettingsPanel
                option={"conversation"}
                humanOptionTitle={"Conversation"}
                labelText="Create conversations"
              />
              <SettingsPanel
                option={"pickup_shift"}
                humanOptionTitle={"Pickup Shift"}
                labelText="Create pickup shifts"
              />
              <SettingsPanel
                option={"quick_train"}
                humanOptionTitle={"Quick Train"}
                labelText="Upload quick training"
              />
              <SettingsPanel
                option={"invitation"}
                humanOptionTitle={"Invitation"}
                labelText="Send invitations"
              />
              <SettingsPanel
                option={"acknowledgement"}
                humanOptionTitle={"Acknowledgement"}
                labelText="Create acknowledgements"
              />
              <SettingsPanel
                option={"poll"}
                humanOptionTitle={"Quick Poll"}
                labelText="Create quick polls"
              />
            </IBox>
          </div>

          <div className="col-md-4">
            <IBox title="Hustle Feed">
              <HustleFeedSettings />
            </IBox>
          </div>

          <div className="col-md-4">
            <IBox title="Activity Recognition Hashtags">
              <RecognitionTagSettings />
            </IBox>
          </div>

          <div className='col-md-4'>
            <IBox title="Badging System">
              <BadgingSystem university={this.props.university}/>
            </IBox>
          </div>
        </div>

        <div style={{ height: "200px" }}>
        </div>

      </div>
    )
  }

}


export default CompanyInfo;
