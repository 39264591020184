import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { activityRecognitionTagClient } from '../../clients/ActivityRecognitionTagClient';
import RecognitionTagModalForm from './RecognitionTagModalForm';
import SweetAlert from 'sweetalert-react';
import { toastrNotification } from '../../helpers/Toastr';

class RecognitionTagSettings extends Component {

  state = {
    tags: []
  }

  componentDidMount() {
    this.getTags()
  }

  getTags = () => {
    activityRecognitionTagClient.getTags().
      then((tags) => {
        this.setState({ tags })
      });
  }

  openEditForm = (tag) => {
    if(!tag.icon_url) tag.icon_url = this.getImageURL(tag.name)
    this.refs.tagForm.open(tag);
  }

  openForm = () => {
    this.refs.tagForm.open();
  }

  closeForm = () => {
    this.refs.tagForm.close();
  }

  showDeleteWarningModal = (tag) => {
    this.setState({ showDeleteWarning: true, selectedTagId: tag.id });
  }

  deleteTag = () => {
    this.setState({ showDeleteWarning: false });
    if (!this.state.selectedTagId) { return };
    activityRecognitionTagClient.deleteTag(this.state.selectedTagId).then((response) => {
      if (response.success == true) {
        toastrNotification({ success: true, message: 'Hashtag was successfully deleted' });
        this.getTags();
      } else {
        toastrNotification({ success: false, message: 'Unable to delete hashtag' });
      }
      this.setState({ selectedTagId: null });
    });
  }

  getImageURL = (tagName) => {
    return activityRecognitionTagClient.getTagImageURL(tagName)
  }

  render() {
    const { tags } = this.state;

    return (
      <div>
        <ul className="list-unstyled list-group clear-list hustle-feed-items">
          {tags.map((tag, index) => (
            <li className="list-group-item" key={tag.id} >
              <div>
                <img src={tag.icon_url || this.getImageURL(tag.name)} alt={tag.name} className="tag-image" style={{ width: '36px', marginRight: '10px' }} />
                <div style={{ marginRight: "10px" }}>{tag.name} <img src='img/coin.png' width='16px' /> {tag.points}</div>
                <button type="button" className="btn btn-xs btn-default" onClick={() => this.openEditForm(tag)}><FontAwesomeIcon icon={faPencilAlt} /> Edit</button>
                <button type="button" className="btn btn-xs btn-default" onClick={() => this.showDeleteWarningModal(tag)}><FontAwesomeIcon icon={faTrashAlt} /> Delete</button>
              </div>
            </li>
          ))}
        </ul>

        <div className="modal-footer">
          <button type="button" className="btn btn-sm btn-default" onClick={() => this.openForm()}><FontAwesomeIcon icon={faPlus} /> Add New</button>
        </div>

        <RecognitionTagModalForm
          reloadTags={this.getTags}
          ref="tagForm"
        />

        <SweetAlert
          show={this.state.showDeleteWarning}
          type="warning"
          title="Are you sure?"
          confirmButtonColor="#DD6B55"
          showCancelButton
          text="This operation can not be undone."
          onConfirm={() => this.deleteTag()}
          onCancel={() => this.setState({ showDeleteWarning: false, selectedTagId: null })}
        />
      </div>
    )
  }
}

export default RecognitionTagSettings;
