import React, { Component } from "react";
import { badgesClient } from "../../clients/BadgesClient";
import { client } from "../Client";
import "./BadgePercentage.css";
import { Modal, Button, ModalBody } from "react-bootstrap";
import { CloseButton } from "react-bootstrap";

class BadgePercentage extends Component {
  state = {
    modalOpened: false,
    progressValue: 0,
    badge: null,
    next_badge: null,
    enabled: false,
    required_points: 0,
    points: 0,
    modalBadge: null,
  };

  /**@type {ActionCable.Channel} */
  subscription;

  imageClick = (badge) => {
    this.setState({ modalBadge: badge });
  };

  toggleSplashScreen = () => {
    this.setState({ modalOpened: !this.state.modalOpened });
  }

  close = () => {
    this.setState({ modalBadge: null });
  };

  componentDidMount() {
    badgesClient.badgesConfig().then((resp) => {
      if (resp?.badges_enabled) {
        this.setState({ enabled: true });
        this.subsribeToBadgeNotifications();
        return this.getBadgeInfo();
      }
    });
  }

  subsribeToBadgeNotifications = () => {
    /**@type {ActionCable.Subscriptions} */
    const subscriptions = window.cable?.subscriptions;
    const channelName = "NotificationChannel";
    if (subscriptions) {
      const _this = this;
      const listener = {
        received(data) {
          if (!client.isLoggedIn()) {
            window.location.hash = "/login";
            return;
          }
          if (!data) return;
          if (data.action === "badge_info") {
            switch (data.state) {
              case "points_update":
                _this.setState({
                  progressValue: Math.ceil(
                    ((data.points || 0) / _this.state.required_points) * 100
                  ),
                  points: data.points,
                });
                break;
              case "upgrade":
              case "downgrade":
                const { badge, next_badge, required_points, points } = data;
                _this.setState(
                  {
                    badge,
                    next_badge,
                    progressValue: Math.ceil(
                      ((points || 0) / required_points) * 100
                    ),
                    required_points,
                    points,
                  },
                  () => {
                    _this.toggleSplashScreen();
                  }
                );
                break;

              default:
                break;
            }
          }
        },
      };
      subscriptions.create(channelName, listener);
    }
  };

  componentWillUnmount() {
    if (this.subscription) {
      setTimeout(() => {
        console.log("unsubscribed");
        this.subscription.unsubscribe();
      }, 100);
    }
  }

  getBadgeInfo = () => {
    return client.getEmployee(client.currentUser().id).then((employee) => {
      const badge_info = employee?.badge_info;
      if (badge_info) {
        const { badge, next_badge, required_points, points } = badge_info;
        this.setState(
          {
            badge,
            next_badge,
            points,
            required_points,
          },
          () => {
            if (required_points)
              setTimeout(() => {
                this.setState({
                  progressValue: Math.ceil(
                    ((points || 0) / required_points) * 100
                  ),
                });
              }, 1000);
          }
        );
      }
    });
  };

  render() {
    if (this.state.enabled && this.state.next_badge)
      return (
        <div className="ibox">
          <div className="ibox-content badge-main">
            {this.state.modalOpened && (
              <div className="spalsh-screen" onClick={() => this.toggleSplashScreen()}>
                <div
                  className="splash-backdrop"
                  style={{ background: "url('/img/badges/Confetti.png')" }}
                >
                  <div className="splash-container">
                    <h1>congratulations!</h1>
                    <h2>You have been promoted</h2>
                    <img
                      className="image-center"
                      src={`img/badges/upgrade-icons/${this.state.badge} Upgrade.png`}
                      alt="Busy Bee"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="image-badge">
              {this.state.badge && (
                <div className="badge-icon" onClick={() => this.imageClick(this.state.badge)}>
                  <img src={`img/badges/Badges/${this.state.badge}.png`} />
                </div>
              )}

              <div className="progress progress-mini">
                <div
                  style={{
                    width: this.state.progressValue + "%",
                    height: "100%",
                  }}
                  className="progress-bar"
                  clicked
                ></div>
              </div>

              {this.state.next_badge && (
                <div className="badge-icon next-badge" onClick={() => this.imageClick(this.state.next_badge)}>
                  <img src={`img/badges/Badges/${this.state.next_badge}.png`} />
                </div>
              )}
            </div>
            <div className="image-badge">
              {this.state.badge && (
                <div className="badge-icon" onClick={() => this.imageClick(this.state.badge)}>
                  <div className="badge-label">{this.state.badge}</div>
                </div>
              )}

              {this.state.next_badge && (
                <div style={{ textAlign: "center", marginTop: "2px", flex: '1 1 auto' }}>
                  <strong>
                    '{this.state.required_points - this.state.points}'
                  </strong>{" "}
                  to <strong>{this.state.next_badge}</strong>
                </div>
              )}

              {this.state.next_badge && (
                <div className="badge-icon next-badge" onClick={() => this.imageClick(this.state.next_badge)}>
                  <div className="badge-label">{this.state.next_badge}</div>
                </div>
              )}
            </div>
            <Modal
              dialogClassName="badge-popup"
              show={!!this.state.modalBadge}
              onHide={this.close}
            >
              <Modal.Body>
                <div class="main-badge-modal">
                  <CloseButton onClick={this.close} />
                  <div class="badge-img">
                    <img
                      src={`img/badges/Badges/${this.state.modalBadge}.png`}
                      className="img"
                    />
                  </div>
                  <p>{this.state.modalBadge !== this.state.badge ? 'Next' : 'Current'} badge - {this.state.modalBadge}</p>
                  <div className="badge-header">
                    <h2>Gain points and level up</h2>
                  </div>
                  <div className="badge-points">
                    <ul>
                      <li>Thumbs Up - 2 points</li>
                      <li>Comment - 4 points</li>
                      <li>Share a picture - 4 points</li>
                      <li>Answer a Survey - 3 points</li>
                      <li>Poll Participation - 3 points</li>
                      <li>Acknowledgement - 3 points</li>
                      <li>Pick up a Shift - 3 points</li>
                      <li>Opt into Raffle - 5 points</li>
                      <li>Train - 10 points</li>
                    </ul>
                    <div className="badge-popup-button">
                      <button className="button" onClick={this.close}>
                        Let's Go!
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      );
    return null;
  }
}
export default BadgePercentage;
