import React, { Component } from 'react';

import { client } from '../Client';
import Employee from '../../models/Employee';
import { Typeahead } from 'react-bootstrap-typeahead';
import {withFormsy} from 'formsy-react';
import 'react-bootstrap-typeahead/css/Loader.css';
import 'react-bootstrap-typeahead/css/Token.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Config from 'config';

class EmployeeSelector extends Component {
  
  state = {
    employees: [],
    defaultSelected: []
  };
  
  componentDidMount() {
    this.getEmployeesList();
  }

  getEmployeesList = () => {
     client.getEmployees()
     .then((data) => {
       let employees = [];
       data.map((item) => {
         let employee = {};
         employee.id = item.id;
         employee.full_name = item.full_name();
         employee.first_name = item.first_name;
         employee.last_name = item.last_name;
         employee.avatar_url = item.avatar_image_url();
         employees.push(employee)

         return item;
       });
       employees.push(this.adminEmployee());
       this.setState({
         employees: employees
       });
       this.getDefaultSelected(employees);
     });
  }
  
  adminEmployee = () => {
    return {
      id: client.currentUser().id,
      first_name: client.currentUser().first_name,
      last_name: client.currentUser().last_name,
      avatar_url: client.currentUser().avatar_image_url(),
      full_name: client.currentUser().full_name()
    }
  }

  handleChange = (selectedEmployees) => {
    const value = selectedEmployees[0] !== undefined ? selectedEmployees[0].id : '';
    this.props.setValue(value);
    if (this.props.onChange) this.props.onChange(value);
  }
    
  renderMenuItemChildren(option, props, index) {
    return (
      <div key={option.id}>
        <img
          src={option.avatar_url}
          style={{
            height: '24px',
            marginRight: '10px',
            width: '24px',
          }}
        />
        <span>{option.full_name}</span>
      </div>
    );
  }
  
  getDefaultSelected = (employees) => {
    let defaultSelected = [];
    if (this.props.defaultSelectedEmployeeId !== undefined) {
      const employeeId = this.props.defaultSelectedEmployeeId;
      for (let i in employees) {
        if (employees[i].id === employeeId) {
          defaultSelected.push(employees[i])
        }
      }
    };
    this.setState({ defaultSelected: defaultSelected });
    if (defaultSelected[0] !== undefined) {
      this.props.setValue(defaultSelected[0].id)
    } else {
      this.props.setValue('')
    }
  }
  
  render() {
    const filterByCallback = (option, text) => {
      let regex = new RegExp("^" + text.toLowerCase());
      return (
        option.first_name.toLowerCase().search(regex) !== -1 || 
        option.last_name.toLowerCase().search(regex) !== -1
      );
    };
    
    const errorMessage = this.props.errorMessage;
    
    return (
      <div>
        <Typeahead
          ref="typeahead"
          //clearButton
          selected={this.state.defaultSelected}
          name={this.props.name}
          options={this.state.employees}
          labelKey="full_name"
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          renderMenuItemChildren={this.renderMenuItemChildren}
          filterBy={filterByCallback}
          tabIndex={this.props.tabIndex}
          required={this.props.required}
        />
        <span className='validation-error'>{errorMessage}</span>
      </div>
    )
  }
}

export default withFormsy(EmployeeSelector)
