// @flow
import React, { Component } from 'react';
import ActionCable  from 'actioncable';
import PageVisibility from 'react-page-visibility';
import {isIOS} from 'react-device-detect';
import { client } from '../Client';
import { activityRecognitionTagClient } from '../../clients/ActivityRecognitionTagClient';
import Config from 'config';
import { Waypoint } from 'react-waypoint';
import Post from '../../models/ActivityPost';
import Employee from '../../models/Employee';
import ActivityPost from './ActivityPost';
import { Button } from 'react-bootstrap';
import { toastrNotification } from './../../helpers/Toastr';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import './ActivityList.css'

class ActivityPersonalList extends Component {

  state = {
    posts: [],
    newPostsCnt: 0,
    page: 1,
    isLoading: false,
    postsEnded: false,
    rewardTags: []
  }

  subscription: ActionCable;
  channelName = "ActivityPersonalChannel";

  componentDidMount() {
    let _this = this;
    
    this.getRewardTags();
    
    let listener = {
      received(data) {
                
        if (!client.isLoggedIn()) {
          window.location.hash = '/login';
          return;
        };
                      
        switch(data.action) {
        case 'create_comment':
          _this.addNewComment(data);
          break;
        case 'confirm_comment':
          _this.confirmComment(data);
          break;
        case 'remove_post':
          _this.removePost(data);
          break;
        case 'remove_comment':
          _this.removePostComment(data);
          break;
        case 'post_like':
          _this.updatePostLikes(data);
          break;
        case 'comment_like':
          _this.updateCommentLikes(data);
          break;
        case 'create_post_reward':
          _this.updatePostRewards(data);
          break;
        case 'update_post_points':
          _this.updatePostPoints(data);
          break;
        }
        console.log("received", data);
      }
    };
    ActionCable.debugging = true;
    window.cable = ActionCable.createConsumer(client.getConsumerUrl());
    this.subscription = window.cable.subscriptions.create({channel: this.channelName}, listener);
    console.log(this.subscription);      
  }
  
  handleVisibilityChange(visibilityState, documentHidden) {
    if(isIOS) {      
      console.log('************** visibility hidden: **********');
      console.log(documentHidden);    
      if (documentHidden) { // submit pending activity
        if (this.subscription) {
          this.confirmPendingComments();
        }      
      } else { // reload activity
        this.setState({posts: [], page: 1});
        this.renderLoader();
      }
    }    
  }       
  
  componentWillUnmount() {
    if (this.subscription) {
      this.confirmPendingComments();
      setTimeout(() => {console.log('unsubscribed');this.subscription.unsubscribe()}, 100);
    }      
  }
  
  getRewardTags = () => {
    activityRecognitionTagClient.getTags().
    then((tags) => {
      this.setState({ rewardTags: tags });
    });
  }
    
  updatePostPoints(data) {
    const mPosts = this.state.posts.map((p) => {
      if (p.id === data.post_id) {
        let pp = new Post(p);
        pp.points = data.points;
        return pp
      } else {
        return p;
      }
    })
    this.setState({ posts: mPosts });        
  }
    
  confirmPendingComments() {
    this.state.posts.map((p) => {
      p.comments.map((c) => {
        if (c.author_id === client.currentUser().id && !c.confirmed) 
          this.subscription.perform('confirm_activity_post_comment', {comment_id: c.id});          
      });
    })   
  }
    
  removePost(data) {
    const post = JSON.parse(data.activity_post);
    const mPosts = this.state.posts.filter(p => p.id !== post.id);
    this.setState({ posts: mPosts, newPostsCnt: mPosts.filter(p => p.hidden).length });    
  }

  addNewComment(data) {
    if (data.success === true) {
      const comment = JSON.parse(data.comment);
      if (comment.author_id === client.currentUser().id || (comment.confirmed && (comment.author_id !== client.currentUser().id))) {          
        const modifiedPosts = this.state.posts.map((p) => {
          if (p.id === comment.post_id) {
            p.comments = p.comments.concat([comment]);
            p.newCommentErrors = [];
          };
          return p;
        });         
        this.setState({posts: modifiedPosts});
      } 
    } else {
      if (data.recipients[0] === client.currentUser().id) {
        toastrNotification({success: false, message: data.errors});
      }
    }
  }

  confirmComment(data) {
    const comment = JSON.parse(data.comment);
    console.log('confirm callback', comment);
    if (comment.author_id === client.currentUser().id) {   
      const modifiedPosts = this.state.posts.map((p) => {
        if(p.id === comment.post_id) {
          p.comments = p.comments.map((c) => {
            if (c.id === comment.id) {
              return comment;
            } else {
              return c;
            }
          })
        }
        return p;
      });            
      this.setState({posts: modifiedPosts});
    } else {
      this.addNewComment(data);
    }
  }
  
  removePostComment(data) {
    const comment = JSON.parse(data.comment);
    const mPosts = this.state.posts.map((p) => {
      if (p.id === comment.post_id) {
        let pp = new Post(p);
        pp.comments = p.comments.filter(c => c.id !== comment.id);
        return pp;
      } else {
        return p;          
      }
    });
    this.setState({ posts: mPosts });
  }

  updatePostLikes(data)  {
    const mPosts = this.state.posts.map((p) => {
      if (p.id === data.postId) {
        let pp = new Post(p);
        pp.liked = data.liked;
        pp.likes = data.likes.map((like) => {
          like.employee = new Employee(like.employee); 
          return like;
        });
        pp.likesNumber = data.likesNumber;
        return pp
      } else {
        return p;
      }
    })
    this.setState({ posts: mPosts });    
  }

  updateCommentLikes(data) {
    let modifiedPosts = this.state.posts;
    for (let i in modifiedPosts) {
      for (let j in modifiedPosts[i].comments) {
        if (modifiedPosts[i].comments[j].id === data.commentId) {
          modifiedPosts[i].comments[j].likesNumber = data.likesNumber;
          break
        }
      }
    }
    this.setState({ posts: modifiedPosts });
  }
  
  updatePostRewards(data)  {
    if (data.success == true) {
      const mPosts = this.state.posts.map((p) => {
        if (p.id === data.post_id) {
          let pp = new Post(p);
          pp.rewards_number = data.rewards_number;
          pp.points = data.post_points;
          pp.rewarded = data.rewarded;
          pp.rewards = data.rewards;
          return pp
        } else {
          return p;
        }
      })
      this.setState({ posts: mPosts });
    } else {
      if (data.author_id === client.currentUser().id) { 
        toastrNotification({success: false, message: data.errors})
      } 
    }    
  }
          
  loadMorePosts() {
    this.setState({ isLoading: true });
    client.getActivityPosts(this.state.page, this.props.employee.id)
    .then((posts) => {
        const postsEnded = posts.length > 0 ? false : true;
        this.setState({ posts: this.state.posts.concat(posts), page: this.state.page += 1, isLoading: postsEnded, postsEnded: postsEnded });
     });
  }

  renderWaypoint = () => {
    if (!this.state.postsEnded) {
      return (
        <Waypoint
          onEnter={this.loadMorePosts.bind(this)}
          scrollableAncestor={window}
        />
      )
    }
  }

  renderLoader = () => {
    if (this.state.isLoading && !this.state.postsEnded) {
      return (
        <div className="sk-spinner sk-spinner-double-bounce">
          <div className="sk-double-bounce1"></div>
          <div className="sk-double-bounce2"></div>
        </div>
      )
    }
  }

  render() {
      return (
      <PageVisibility onChange={(visibilityState, documentHidden) => this.handleVisibilityChange(visibilityState, documentHidden)}>
          <div>  
            <div className="activity-list">
              {
                this.state.posts.map((post) => {
                  if (!post.hidden)
                    return <ActivityPost
                    key={'personalPost'+post.id}
                    post={post}
                    channel={this.subscription}
                    onUndoPost={this.handleUndoPost}
                    containerId="personalList"
                    rewardTags={this.state.rewardTags}
                  />
                })
              }
              {this.renderWaypoint()}
              {this.renderLoader()}
            </div>
          </div>
      </PageVisibility>
      )
    }

}

export default ActivityPersonalList;
