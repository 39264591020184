import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import UsStateSelector from '../form_components/UsStateSelector';
import { client } from '../Client';
import { toastrNotification } from '../../helpers/Toastr';
import Input from '../form_components/Input';
import Select from '../form_components/Select';
import TimeZoneSelect from '../form_components/TimeZoneSelect';

class AddressFields extends Component {
  
  state = {
    editMode: false,
    canSubmit: false,
    fields: {},
    countries: []
  }
  
  componentDidMount() {
    client.getCountries().then((countries) => {    
      this.setState({countries: countries});
    })
  }
  
  openEditForm = () => {
    client.getCountries().then((countries) => {    
      this.setState({editMode: true,
        fields: {
          address1:   this.props.university.address1 || '',
          address2:   this.props.university.address2 || '',
          city:   this.props.university.city || '',
          state:   this.props.university.state || '',
          zip:   this.props.university.zip || '',
          country:    this.props.university.country || '',
          default_tz:   this.props.university.default_tz || ''
        }
      });
    });
  }  
  
  closeEditForm = () => {
    this.setState({editMode: false});
  }
  
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  } 
   
  countryName = () => {    
    const country = (this.state.countries || []).find(c => c.value === this.props.university.country);
    return country ? country.title : '';
  }
        
  formSubmit = (data) => {
    client.updateUniversity(data).then((university) => {
      if (university.id) {
        this.props.onReloadUniversity(university);
        toastrNotification({success: true, message: 'Updated successfully'});
        this.closeEditForm();
      } else {
        this.refs.form.updateInputsWithError(university);
        toastrNotification({success: false, message: 'Unable to update'})
      }
    });
  }
  
  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };
  
  render() {
    if (this.state.editMode) {
      return (
        <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal">
          <ul className="list-unstyled">             
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>Address1</label></div>
              <div className="col-sm-9">
                <Input value={this.state.fields.address1} onChange={this.onInputChange} className='form-control' name='address1'/>
              </div>
            </li>
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>Address2</label></div>
              <div className="col-sm-9">
                <Input value={this.state.fields.address2} onChange={this.onInputChange} className='form-control' name='address2'/>
              </div>
            </li>
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>City</label></div>
              <div className="col-sm-9">
                <Input value={this.state.fields.city} onChange={this.onInputChange} className='form-control' name='city'/>
              </div>
            </li>
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>State</label></div>
              <div className="col-sm-9">
              { this.state.fields.country === 'US'  &&     
                <UsStateSelector onChange={this.onInputChange} selected={this.state.fields.state} name='state' />
              }
              { this.state.fields.country !== 'US' &&                
                <Input value={this.state.fields.state} onChange={this.onInputChange} className='form-control' name='state' />
              }
              </div>
            </li>
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>Zip</label></div>
              <div className="col-sm-9">
                <Input value={this.state.fields.zip} onChange={this.onInputChange} className='form-control' name='zip'/>
              </div>
            </li>      
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>Country</label></div>
              <div className="col-sm-9">            
                <Select
                  value={this.state.fields.country}
                  onChange={this.onInputChange}
                  options={this.state.countries}
                  clearable={false}
                  className="form-control"
                  name='country'
                />                  
              </div>
            </li>            
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>Time zone</label></div>
              <div className="col-sm-9">            
                <TimeZoneSelect 
                  name="default_tz"
                  placeholder="Select"
                  className="form-control"
                  value={this.state.fields.default_tz}
                  onChange={this.onInputChange}
                  className="form-control"
                />            
              </div>
            </li>      
          </ul>
          
          
          <div className="modal-footer">
            <button disabled={!this.state.canSubmit} type="submit" className="btn btn-sm btn-primary">Save</button>
            <button onClick={this.closeEditForm} className="btn btn-sm btn-default">Cancel</button>
          </div>  

        </Formsy>
      )      
    } else {
      return (
        <div>          
          <ul className="list-unstyled list-group clear-list">
            { this.props.university.address1 &&                    
            <li>
              <div>
                <label>Address1 : </label> {this.props.university.address1}
              </div>
            </li>
            }            
            {this.props.university.address2 &&
            <li>
              <div>
                <label>Address2 : </label> {this.props.university.address2}
              </div>
            </li>
            }
            {this.props.university.city &&
            <li>
              <div>
                <label>City : </label> {this.props.university.city}
              </div>
            </li>
            }
            {this.props.university.state &&
            <li>
              <div>
                <label>State : </label> {this.props.university.state}
              </div>
            </li>
            }
            {this.props.university.zip &&
            <li>
              <div>
                <label>Zip : </label> {this.props.university.zip}
              </div>
            </li>
            }
            {this.props.university.country &&
            <li>
              <div>
                <label>Country : </label> { this.countryName()}
              </div>
            </li>
            }
            {this.props.university.default_tz &&
            <li>
              <div>
                <label>Time zone : </label> {this.props.university.default_tz}
              </div>
            </li>
            }
          </ul>
          
          <div className="modal-footer">
             <button type="button" className="btn btn-sm btn-default" onClick={this.openEditForm}><FontAwesomeIcon icon={faPencilAlt}/> Edit</button>
          </div>  
        </div>
      )
    }
  }
}

export default AddressFields;
