//@flow
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { companySettingsClient } from '../../clients/CompanySettingsClient';
import { client } from '../Client';
import { groupClient } from '../../clients/GroupClient';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IBox from '../common/IBox';
import Employee from '../../models/Employee';
import { toastrNotification } from './../../helpers/Toastr';
import {Checkbox, Radio, RadioGroup} from 'react-ui-icheck';
import MultipleEmployeeSelector from '../points_allocation_settings/MultipleEmployeeSelector';
import MultipleGroupSelector from '../common/MultipleGroupSelector';

class SettingsPanel extends Component {

  state = {
    editMode: false,
    accessMode: null,
    employeePositions: [],
    positionCheckboxes: {},
    allowedPositions: [],
    allowedEmployees: [],
    allowedGroups: [],
  }
  
  componentDidMount() {
    this.getSettings();
    this.getEmployeePositions();
  }
  
  getSettings = () => {
    companySettingsClient.getSettings(this.props.option)
    .then((settings) => {
      let positionCheckboxes = {};
      settings.allowed_positions.map((position) => positionCheckboxes[position] = true );
      this.setState({ accessMode: settings.access_mode, 
                      allowedPositions: settings.allowed_positions,
                      allowedEmployees: settings.allowed_employees,
                      allowedGroups: settings.allowed_departments,
                      positionCheckboxes: positionCheckboxes
                     });                                  
    });
  }
  
  getEmployeePositions = () => {
    client.getEmployeePositions()
    .then((positions) => {
      this.setState({ employeePositions: positions })
     });    
  }
  
  openEditForm = () => {
    this.setState({ editMode: true });
  }
    
  closeEditForm = () => {
    this.setState({ editMode: false });
  }
  
  handleSubmit = (evt) => {
    evt.preventDefault();
    const option = this.props.option;
    const allowedEmployeesIds = this.state.allowedEmployees.map((e) => e.id);
    const allowedDepartmentIds = this.state.allowedGroups.map((g) => g.id);
    let data = { [`${option}_access_mode`]: this.state.accessMode };
    if (this.state.accessMode === "positions") {
      data[`${option}_positions`] = this.state.allowedPositions
    };       
    if (this.state.accessMode === "employees") {
      data[`${option}_employees`] = allowedEmployeesIds
    };
    if (this.state.accessMode === "departments") {
      data[`${option}_departments`] = allowedDepartmentIds
    };
    companySettingsClient.updateSettings(data).then((response) => {
      if (response.success) {
        this.closeEditForm();
        toastrNotification({success: true, message: `${this.props.humanOptionTitle} settings were successfully updated`});
      } else {
        toastrNotification({success: false, message: `Unable to update ${this.props.humanOptionTitle} settings` })
      }
    });
  }
  
  onAccessModeChange(ev, newValue) {
    this.setState({ accessMode: newValue });
  } 
  
  onSelectAllowedEmployees = (newAllowedEmployees) => {
    this.setState({ allowedEmployees: newAllowedEmployees });
  }
  
  onSelectAllowedGroups = (newAllowedGroups) => {
    this.setState({ allowedGroups: newAllowedGroups });
  }
  
  humanizeAccessMode() {
    if (!this.state.accessMode) {return ""};
    switch (this.state.accessMode.toString()) 
       {
           case "all": return ("Everybody");
           case "positions": return ("Only particular positions");
           case "employees": return ("Only selected employees");
           case "departments": return ("Only members of selected groups");
       } 
  }
  
  onPositionChange(position, evt) {
    let nextAllowedPositions = [];
    let checkboxes = this.state.positionCheckboxes;
    let allowedPositions = this.state.allowedPositions;
    const checkboxState = !this.state.positionCheckboxes[position];
    if (checkboxState) {
      nextAllowedPositions= allowedPositions.concat(position)
    } else { 
      nextAllowedPositions = allowedPositions.filter(p => p !== position)
    }
    const chks = Object.assign({}, checkboxes, {[position]: checkboxState });
    this.setState({ allowedPositions: nextAllowedPositions, positionCheckboxes: chks });
  }
   
  render() {
    const option = this.props.option;
    
    if (this.state.editMode) {
      return (
        <div className="settings-panel">
            <div>
              <div className="form-horizontal">
                <ul className="list-unstyled"> 
                  <li className="form-group">
                    <div className="col-sm-12"><label>{this.props.labelText}</label></div>
                    <div className="col-sm-12 allocation-types">
                      <RadioGroup name="radio" value={this.state.accessMode ? this.state.accessMode.toString() : ""} onChange={this.onAccessModeChange.bind(this)}>
                        <Radio
                          value="all"
                          radioClass="iradio_square-green"
                          increaseArea="20%"
                          label="Everybody"
                        />
                        <Radio
                          value="positions"
                          radioClass="iradio_square-green"
                          increaseArea="20%"
                          label="Based on employee position"
                        />
                        <Radio
                          value="departments"
                          radioClass="iradio_square-green"
                          increaseArea="20%"
                          label="Only members of selected groups"
                        />
                        <Radio
                          value="employees"
                          radioClass="iradio_square-green"
                          increaseArea="20%"
                          label="Only selected employees"
                        />
                      </RadioGroup>
                    </div>  
                  </li>
                  { (this.state.accessMode === "positions") &&
                    <li className="form-group">
                      <div className="col-sm-12"><label>Employee positions</label></div>
                      <div className="col-sm-12 employee-position-list">
                        {this.state.employeePositions.map((position) => (
                          <Checkbox
                            key={position.title}
                            checkboxClass="icheckbox_square-green"
                            checked={this.state.positionCheckboxes[position.title]}
                            increaseArea="20%"
                            label={`${position.title} <span class="badge">${position.count}</span>`}
                            onChange={(evt) => this.onPositionChange(position.title, evt)}
                          />
                        ))}
                      </div>
                    </li>
                  }
                  { (this.state.accessMode === "employees") &&
                    <li className="form-group">
                      <div className="col-sm-12"><label>Employees list</label></div>
                      <div className="col-sm-12 employees-list">
                        <MultipleEmployeeSelector 
                          selectedEmployees={this.state.allowedEmployees}
                          selectAllowedEmployees={this.onSelectAllowedEmployees}
                          placeholder="Select employees"
                        />
                      </div>
                    </li>
                  }
                  { (this.state.accessMode === "departments") &&
                    <li className="form-group">
                      <div className="col-sm-12"><label>Groups</label></div>
                      <div className="col-sm-12 employees-list">
                        <MultipleGroupSelector 
                          selectedGroups={this.state.allowedGroups}
                          selectGroups={this.onSelectAllowedGroups}
                          placeholder="Select groups"
                        />
                      </div>
                    </li>
                  } 
                </ul>
              </div>
              <div className="modal-footer">
                <button onClick={this.handleSubmit} className="btn btn-sm btn-primary">Save</button>
                <button onClick={this.closeEditForm} className="btn btn-sm btn-default">Cancel</button>
              </div>
            </div>
            <br/>
        </div>    
      )
    } else {
      return (
        <div className="settings-panel">
            <div>
              <ul className="list-unstyled list-group clear-list">
                <li>
                  <div>
                    <label>{this.props.labelText} : </label>&nbsp; 
                    { this.state.accessMode === "all" && <span>Everybody</span> }
                    { this.state.accessMode === "positions" && 
                      <span>
                        {this.state.allowedPositions.map((position, index) => `${position}` + ((this.state.allowedPositions.length-1 == index) ? "" : ", "))}
                      </span>
                    }
                    { this.state.accessMode === "departments" && this.state.allowedGroups.length > 0 &&
                      <span>
                        {this.state.allowedGroups.map((group, index) => `${group.title}` + ((this.state.allowedGroups.length-1 == index) ? "" : ", "))}
                      </span>
                    }
                    { this.state.accessMode === "employees" &&
                      <span>
                        {this.state.allowedEmployees.map((employee, index) => 
                          <Link key={employee.id} to={client.currentUser().id == employee.id ? "/profile" : `/employee/${employee.id}`}> 
                            {this.state.allowedEmployees.length-1 == index ? employee.full_name : `${employee.full_name}, ` }
                          </Link> 
                        )}
                      </span>  
                    }
                    &nbsp;
                    <button type="button" className="btn btn-xs btn-default" onClick={this.openEditForm}><FontAwesomeIcon icon={faPencilAlt}/> Edit</button>
                  </div>
                </li>
              </ul>
            </div>
        </div>    
      )
    }
  }

}


export default SettingsPanel;
