import React, { Component } from "react";
import { client } from "../../components/Client";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import "./ClientCredentialsForm.css";
class ClientCredentialsForm extends Component {
  state = {
    invalid: false,
    shouldRedirect: false,
    canSubmit: true,
    errorMessage: "",
    loading: false,
    fields: {
      company_name: "",
      person_name: "",
      contact_person_name: "",
      contact_person_phone: "",
      contact_person_email: "",
    },
  };

  onFormSubmit = (evt) => {
    evt.preventDefault();
    this.setState({ loading: true });
    client.submitPotentialClient(this.state.fields).then((response) => {
      if (response.success) {
        this.setState({ shouldRedirect: true });
      } else {
        this.setState({ errorMessage: response.message });
      }
    });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  };

  render() {
    if (this.state.shouldRedirect) {
      return (
        <div className="client-credentials-main" style={{borderRadius: 'inherit'}}>
          <div className="ibox-title">
            <img src="img/logo2.png" height="42" className="logo" />
          </div>
          <div className="ibox-content">
            <br />
            <p>Thanks for the information.</p>
            <p>We will get in touch with your Organization shortly!</p>
            <br />
            <div className="text-center">
              <Link to={"/login"} className="login-link auth-links">
                Back To Sign-in
              </Link>
            </div>
            <br />
          </div>
        </div>
      );
    } else {
      return (
        <div className="client-credentials-main" style={{borderRadius: 'inherit'}}>
          {this.state.invalid &&
            this.state.errorMessage &&
            this.state.errorMessage.length > 0 && (
              <div
                className="alert alert-danger"
                style={{
                  borderTopLeftRadius: "inherit",
                  borderTopRightRadius: "inherit",
                  marginTop: "0",
                }}
              >
                {this.state.errorMessage}
              </div>
            )}
          <div className="ibox-title">
            <img src="img/logo2.png" height="42" className="logo" />
          </div>
          <div className="ibox-content">
            <h4 className="text-center" style={{ marginBottom: "20px" }}>
              Provide details of HR or Admin
            </h4>
            <form onSubmit={this.onFormSubmit} className="form-horizontal">
              <div className="row">
                <div className="form-group col-sm-6">
                  <label>Company Name</label>

                  <input
                    name="company_name"
                    className="form-control"
                    value={this.state.fields.company_name}
                    onChange={this.onInputChange}
                    required
                  />
                </div>
                <div className="form-group col-sm-6 ">
                  <label>Your Name</label>
                  <input
                    name="person_name"
                    className="form-control"
                    value={this.state.fields.person_name}
                    onChange={this.onInputChange}
                  />
                </div>
                <div className="form-group col-sm-12">
                  <label>Contact Person's Name</label>
                  <input
                    name="contact_person_name"
                    className="form-control"
                    value={this.state.fields.contact_person_name}
                    onChange={this.onInputChange}
                  />
                </div>
                <div className="form-group col-sm-12">
                  <label>Contact Person's Mobile Number</label>
                  <input
                    name="contact_person_phone"
                    className="form-control"
                    value={this.state.fields.contact_person_phone}
                    onChange={this.onInputChange}
                  />
                </div>
                <div className="form-group col-sm-12">
                  <label>Contact Person's Email Address</label>
                  <input
                    name="contact_person_email"
                    className="form-control"
                    value={this.state.fields.contact_person_email}
                    onChange={this.onInputChange}
                  />
                </div>
                <div className="form-group col-sm-12">
                  <div className="btn">
                    <LaddaButton
                      disabled={!this.state.canSubmit}
                      data-style={EXPAND_LEFT}
                      type="submit"
                      loading={this.state.loading}
                      className={
                        "ladda-button btn btn-md btn-primary no-margins"
                      }
                    >
                      Submit
                    </LaddaButton>
                    {/* &nbsp;&nbsp; */}
                    <br />
                    <br />
                    <Link to={"/login"} className="login-link auth-links">
                      {" "}
                      Back to login
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
}

export default ClientCredentialsForm;
