import React from 'react';
export function statusLabel(employee) {
  if (employee.deleted()) { return (<span className="label label-danger">Deleted</span>) };
  switch (employee.status) {
    case 0: return (<span className="label label-success">Active</span>);
    case 1: return (<span className="label label-danger">Terminated</span>);
  }
}

export function adminLabel(employee) {
  if (employee.admin) {
    return (<span className="label label-info">Admin</span>);
  }
}
/*
export function employeeStatus(employee) {
  switch (employee.status) 
  {
    case 0: return "Active";
    case 1: return "Terminated";
  }  
}
*/