import React, { Component } from 'react';

class Coin extends Component {

  render() {
      return (
        <img src="img/coin-new.png" {...this.props}  className="ezzely-coin" style={{ paddingBottom: '1px' }} />
      )
    }
}
export default Coin;
