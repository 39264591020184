// @flow
import React, { Component } from 'react';
import NotificationRow from '../components/common/NotificationRow';
import InfiniteScroll from 'react-infinite-scroll-component';
import { notificationClient } from '../clients/NotificationClient';

class NotificationsView extends Component {
  
  state = {
    page: 1,
    notifications: [],
    hasMoreNotifications: true,
    spinnerClass: 'sk-loading'
  }
  
  componentDidMount() {
    this.getNotifications();
  }
  
  getNotifications = () => {
    notificationClient.getNotifications(this.state.page).
    then((notifications) => {
      const newNotifications = this.state.notifications.concat(notifications);
      const hasMore = notifications.length > 0 ? true : false;
      this.setState({ notifications: newNotifications, 
                      page: this.state.page + 1, 
                      hasMoreNotifications: hasMore,
                      spinnerClass: "" });
      this.markNotificationsRead(notifications.map(notif => notif.id))                                   
    });
  }
  
  markNotificationsRead = (notificationIds) => {
    notificationClient.markNotificationsRead(notificationIds);
  }
  
  scrollProps = () => {
    let sp = {
      dataLength: this.state.notifications.length,
      next: this.getNotifications,
      hasMore: this.state.hasMoreNotifications,
      useWindow: true
    }
    return sp;
  }
  
  render() {
    return (
      <div>
         <div className={'wrapper wrapper-content notifications-view' + this.state.spinnerClass}>
           <div className="sk-spinner sk-spinner-double-bounce">
             <div className="sk-double-bounce1"></div>
             <div className="sk-double-bounce2"></div>
           </div>
           { this.state.notifications.length == 0 && !this.state.hasMoreNotifications && 
           <div className="text-center">
             <b>There are no notifications.</b>
           </div> }  
           <InfiniteScroll {...this.scrollProps()} >
             {
               this.state.notifications.map((notification, index) => (
                 <div key={'notif'+notification.id} className="notification-item">
                   { index !== 0 &&
                     <div className="divider"></div>
                   } 
                   <NotificationRow notification={notification} /> 
                 </div>  
               ))
             } 
           </InfiniteScroll> 
         </div> 
      </div>
    )
  }

}

export default NotificationsView;
