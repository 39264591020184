// @flow
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye';
import moment from 'moment';

class AcknowledgementRow extends Component {
          
  render() {
    let acknowledgement = this.props.acknowledgement;
    
    return (
      <tr>
        <td>
          <Link to={`/acknowledgement/${acknowledgement.id}`} >
            { acknowledgement.title }
          </Link>
        </td>
        <td>
          { acknowledgement.department ? acknowledgement.department : "All Groups" }
        </td>
        <td>
          { moment(acknowledgement.created_at).format('D MMM YYYY') }
        </td>
        <td>
          { moment(acknowledgement.expires_at).format('D MMM YYYY') }
        </td>
        <td>
          <Link to={`/acknowledgement/${acknowledgement.id}`} className="btn btn-white btn-sm">
            <FontAwesomeIcon icon={faEye}/> View
          </Link>
        </td>
      </tr>
    )
  }

}


export default AcknowledgementRow;
