import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import DatePicker from 'react-datepicker';
class DateField extends Component { 
    
  buildProperties = () => {
    let p = Object.assign({
      autoComplete: "off",
      utcOffset: 0,
      selected: this.props.value,
      placeholderText: this.props.placeholder,
      className: "form-control",
      peekNextMonth: true,
      dropdownMode: "select"
    }, this.props);
    p.onChange = this.handleChange; //higher priority than this.props
    return p;
  }
  
  handleChange = (date) => {
    this.props.setValue(date);
    if(this.props.onChange) this.props.onChange(date);
  }
  
  
  render() {
    const errorMessage = this.props.errorMessage;

    return (
      <div>
        <DatePicker {...this.buildProperties()} />
        <span className='validation-error'>{errorMessage}</span>
      </div>  
    );
  }
}

export default withFormsy(DateField)