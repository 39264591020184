import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { client } from '../components/Client';
import { iconUrl } from '../helpers/Courses.js';
import { toastrNotification } from './../helpers/Toastr';
import { Waypoint } from 'react-waypoint';

class CoggnoMarketplaceView extends Component {
  state = {
    courses: [],
    categories: [],
    selectedCategory: '',
    spinnerClass: 'sk-loading',
    search: '',
    fetched: false,
    addCourseButtonsDisabled: false,
    page: 1,
    coursesEnded: false,
    existingCourses: [],
    searchTimeout: 0
  }

  componentDidMount() {
    this.getCourses();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedCategory !== this.state.selectedCategory) {
      //this.loadMoreCourses()
    }
  }

  getCourses = () => {
    client.getCoggnoCourses().then((courses) => {
      const existingCourses = courses.map((c) => {
        return c.coggno_document_id
      })
      this.setState({ existingCourses: existingCourses });
    });
  }

  loadMoreCourses() {
    this.setState({ fetched: false });
    client.getCoggnoMarketplaceCourses(this.state.page, this.state.selectedCategory, this.state.search)
      .then((response) => {
        const coursesEnded = response.current_page >= response.total_pages;
        const categories = response.categories.sort();
        this.setState({
          courses: this.state.courses.concat(response.documents),
          categories: categories,
          page: this.state.page += 1,
          fetched: !coursesEnded,
          coursesEnded: coursesEnded
        });
      });
  }

  renderWaypoint = () => {
    if (!this.state.coursesEnded) {
      return (
        <Waypoint
          onEnter={this.loadMoreCourses.bind(this)}
          scrollableAncestor={window}
        />
      )
    }
  }

  renderLoader = () => {
    if (!this.state.fetched && !this.state.coursesEnded) {
      return (
        <div className="sk-spinner sk-spinner-double-bounce">
          <div className="sk-double-bounce1"></div>
          <div className="sk-double-bounce2"></div>
        </div>
      )
    }
  }

  handleCategoryChangeInSelect = (evt) => {
    this.selectCategory(evt.target.value)
  }

  selectCategory = (category) => {
    this.setState({ selectedCategory: category, page: 1, courses: [], fetched: false, coursesEnded: false })
  }

  handleSearchButtonClick = () => {
    this.setState({ page: 1, courses: [], fetched: false, coursesEnded: false, selectedCategory: '' })
  }

  handleSearch = (evt) => {
    /*
    if (this.state.searchTimeout) {
       clearTimeout(this.state.searchTimeout);
    }
    */

    this.setState({
      search: evt.target.value,
      /*searchTimeout: setTimeout(() => {
         this.setState({ page: 1, courses: [], fetched: false, coursesEnded: false, selectedCategory: '' })
      }, 2000)*/
    });
  }

  resetSearch = () => {
    this.setState({ page: 1, courses: [], fetched: false, coursesEnded: false, selectedCategory: '', search: '' })
  }

  addCourse = (docId) => {
    this.setState({ addCourseButtonsDisabled: true })
    client.addCoggnoCourse(docId)
      .then((response) => {
        if (response.success && response.document_id) {
          this.setState({ existingCourses: this.state.existingCourses.concat([response.document_id]) })
          toastrNotification({ success: true, title: 'Course was successfully added' });
        } else {
          toastrNotification({ success: false, title: response.message });
        }
        this.setState({ addCourseButtonsDisabled: false })
      });
  }

  render() {
    let categoriesList = [{ value: '', label: 'All categories' }]
    this.state.categories.forEach((category) => {
      let item = {};
      item.value = category;
      item.label = category;
      categoriesList.push(item);
    });

    return (
      <div>
        <div className='wrapper wrapper-content'>
          <div className="row hidden-xs">
            <div className="col-md-12">
              <Link to="/courses" className="btn btn-white btn-sm pull-right m-b-sm hidden-xs">Back to Training</Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-4">
              <div className="ibox float-e-margins">
                <div className="ibox-content">
                  <div className="file-manager" style={{ fontSize: '12px' }}>
                    <div className="input-group" style={{ marginBottom: "10px" }}>
                      <input type="text" placeholder="Search course " className="input form-control" value={this.state.search} onChange={(evt) => this.handleSearch(evt)} />
                      <span className="input-group-btn">
                        {this.state.search?.length > 0 &&
                          <button type="button" className="btn btn btn-primary" onClick={() => this.resetSearch()}> <FontAwesomeIcon icon={faSyncAlt} /></button>
                        }
                        <button type="button" className="btn btn btn-primary" onClick={() => this.handleSearchButtonClick()}> <FontAwesomeIcon icon={faSearch} /></button>
                      </span>
                    </div>
                    <h5>Categories</h5>
                    <ul className="folder-list hidden-xs" style={{ padding: 0 }}>
                      <li key={'cat-1'} className={this.state.selectedCategory === '' ? 'gray-bg' : ''}>
                        <a onClick={(evt) => this.selectCategory('')}>All Categories</a>
                      </li>
                      {
                        this.state.categories.map((category, index) => (
                          <li key={category + index} className={this.state.selectedCategory === category ? 'gray-bg' : ''}>
                            <div style={{ display: "inline-block" }}>
                              <a onClick={(evt) => this.selectCategory(category)}>{category}</a>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                    <div className="hidden-md hidden-lg hidden-sm">
                      <select className="form-control" name="selected-category" onChange={this.handleCategoryChangeInSelect} value={this.state.selectedCategory} >
                        {categoriesList.map((co) => (
                          <option key={co.value} value={co.value}>{co.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-8">
              <div className="row">
                {this.state.courses.map((course, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xsm-6" key={'course' + course.id + index}>
                    <div className="ibox">
                      <div className="ibox-content product-box">
                        <div>
                          <img style={{ width: '100%', padding: '20px' }} src={course.mp_coverpage_url} />
                        </div>
                        <div className="product-desc">
                          <div style={{ height: '44px' }}>
                            <LinesEllipsis text={course.title || ''} maxLine="2" trimRight basedOn="letters" />
                          </div>
                          <div style={{ display: "flex" }}>
                            <small className="text-muted text-ellipsis" style={{ width: '85%' }}>Category: {course.category.title}</small>
                            {course.mp_free && <span className="badge badge-success" style={{ marginLeft: "auto" }}>FREE</span>}
                            {!course.mp_free && <span className="badge badge-primary" style={{ marginLeft: "auto" }}>{course.document_price}</span>}
                          </div>
                          <div className="small m-t-xs" style={{ height: '45px' }}>
                            <LinesEllipsis text={course.description_nohtml || ''} maxLine="3" trimRight basedOn="letters" />
                          </div>
                          <div className="m-t-md">
                            {!this.state.existingCourses.includes(course.id) &&
                              <button className="btn btn-primary btn-sm btn-block" disabled={this.state.addCourseButtonsDisabled} onClick={() => this.addCourse(course.id)}><FontAwesomeIcon icon={faPlus} /> Add Course</button>
                            }
                            {this.state.existingCourses.includes(course.id) &&
                              <button className="btn btn-white btn-sm btn-block" disabled><FontAwesomeIcon icon={faCheck} />  added</button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {this.renderWaypoint()}
              {this.renderLoader()}
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default CoggnoMarketplaceView
