import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { client } from '../Client';


class PhotoCropper extends Component {

  state = {
    showModal: false,
    canSubmit: false,
    src: '',
    cropResult: null,
  };
 

 
  onChange = (e) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const reader = new FileReader()
    reader.onload = () => {
      this.setState({ src: reader.result })
    }
    reader.readAsDataURL(files[0]);
  }

  onCrop = () => {
    if (this.cropperRef.cropper === null) {
      this.setState({
        canSubmit: false,
        cropResult: null
      })
    }
    if (this.cropperRef.cropper && typeof(this.cropperRef.cropper.getCroppedCanvas() !== 'undefined')) {
      this.setState({
        cropResult: this.cropperRef.cropper.getCroppedCanvas({width: 200, height: 200}).toDataURL(),
        canSubmit: true
      })
    }
  }

  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true, src: this.props.employee.avatar_image_url(),  cropResult: null });
  }

  updatePhoto = (e) => {
    e.preventDefault()
    this.setState({canSubmit: false})
    const _this = this
    const employee_id = this.props.employee.id
    const cropped_photo = this.state.cropResult
    client.updateEmployeePhoto(employee_id, cropped_photo)
    .then((employee) => {
        if (employee.id) {
          _this.props.onUpdateEmployeePhoto(employee)
          _this.close()
        } else {
          //_this.refs.form.updateInputsWithError(employee);
        }
    });
  }

 render() {

    return (
      <div>
        <Button
          bsClass="btn btn-white btn-outline btn-xs"
          type="button"
          onClick={this.open}
          style={{ marginTop: "10px" }}
        >Change Photo</Button>
        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <div style={{ marginBottom: "10px" }}>
                    <input type="file" onChange={this.onChange} />
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <Cropper
                    style={{ height: 300, width: '100%' }}
                    aspectRatio={1/1}
                    preview=".img-preview"
                    guides={false}
                    crop={this.onCrop}
                    src={this.state.src}
                    ref={cropper => { this.cropperRef = cropper; }}
                    />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Preview</h1>
                  <div className="img-preview" style={{ height: 200, marginLeft: 'auto', marginRight: 'auto' }} />
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                bsClass="btn btn-primary"
                type="submit"
                disabled={!this.state.canSubmit}
                onClick={this.updatePhoto}
              >Save</Button>
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default PhotoCropper;
