import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faChartArea } from '@fortawesome/free-solid-svg-icons/faChartArea';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { mediumDateFormat } from './../../helpers/Formatter';
import { client } from '../Client';
import Markdown from 'react-markdown';

class NotesTable extends Component {

  showFulltext = (e, note) => {
    e.target.parentNode.innerText = note.text;
  }

  truncatedText = (note) => {
    const length = 100;
    let tt = note.text;
    if (note.text.length > length) tt = note.text.substring(0,length) + ' ... ';
    return (
      <span>
      {tt}
      { note.text.length > length &&
        <a onClick={(e) => this.showFulltext(e, note)}>See More</a>
      }
      </span>
    )

  }

  handleRemoveNote = (note) => {
    this.props.onRemoveNote(note);
  }

  categoryIcon = (category) => {
    return category.title === 'Performance' ? faChartArea : faBook ;
  }

  render() {
    return (
      <div className="inspinia-timeline notes-list">
      {this.props.employee.notes.map((note) => (
          <div className="timeline-item" key={'note' + note.id}>
            <div className="row">
              <div className="col-sm-3 date">
                <FontAwesomeIcon icon={this.categoryIcon(note.category)} />
                {mediumDateFormat(note.created_at)}<br />
                <small className="text-navy">{note.category.title}</small>
              </div>
              <div className="col-sm-9 content ">
                <h2 className="m-b-xs"><strong>{note.title}</strong></h2>
                <div className="pull-right delete-link-right"><a onClick={() => this.handleRemoveNote(note)} title="Delete"><FontAwesomeIcon icon={faTrashAlt} className="text-muted"/></a></div>
                <div><Markdown source={note.text} /></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default NotesTable;
