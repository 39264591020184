import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';
import Notification from '../models/Notification';

class NotificationClient {

  getNotifications(page) {
    return client.callApi(Config.API_BASE + '/notifications.json?page=' + page)
      .then((notifications) => notifications.map((notificationData) => (new Notification(notificationData))))
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }

  getNotification(id) {
    return client.callApi(Config.API_BASE + '/notifications/' + id + '.json')
      .then((notification) => new Notification(notification))
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }

  markNotificationsRead(notificationIds) {
    return client.callApi(Config.API_BASE + '/notifications/mark_read.json', {}, 'PUT', { notification_ids: notificationIds })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  }

}

export const notificationClient = new NotificationClient();
