import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons/faStarHalfAlt'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faStar as faStarRegular} from '@fortawesome/free-regular-svg-icons/faStar'
import { client } from '../components/Client';
import RequestCourse from '../components/courses/RequestCourse';
import StartCourse from '../components/courses/StartCourse';
import CourseProgress from '../components/courses/CourseProgress';
import { iconUrl } from '../helpers/Courses.js'
import IBox from '../components/common/IBox';
import { monthDateFormat } from '../helpers/Formatter';

class CourseView extends Component {
  state = {
    course: {items: [], license: {}, will_learn_items: []},
    spinnerClass: 'sk-loading'
  };

  componentDidMount() {
    this.getCourse();
  }

  getCourse = () => {
    client.getCourse(this.props.match.params.id).then((course) => {
      this.setState({ course: course, spinnerClass: '' })
    });
  };

  courseStatus = () => {
    let lblClass = "label label-" + this.labelClass();
    return (
      <span className={lblClass}>
        {this.state.course.license && this.state.course.license.status}
        {!this.state.course.license && 'Available for request'}
      </span>
    )
  }
  
  descriptionMarkup = () => {
    return {__html: this.state.course.rich_description};
  }  

  ratingStar = (rating, level) => {
    let classname = '';
    if(rating >= level) {
      classname = faStar      
    } else {
      if (rating > (level - 1)) {
        classname = faStarHalfAlt
      } else {
        classname = faStarRegular             
      }      
    }
    return classname;
  }

  courseRating = () => {
      return (
        <span style={{color:'orange'}}>
            <FontAwesomeIcon icon={this.ratingStar(this.state.course.rating, 1)}/>
            <FontAwesomeIcon icon={this.ratingStar(this.state.course.rating, 2)}/>
            <FontAwesomeIcon icon={this.ratingStar(this.state.course.rating, 3)}/>
            <FontAwesomeIcon icon={this.ratingStar(this.state.course.rating, 4)}/>
            <FontAwesomeIcon icon={this.ratingStar(this.state.course.rating, 5)}/>
        </span>        
      )
  }

  labelClass = () => {
    let l = 'plain'
    if (this.state.course.license) {
      if (this.state.course.license.boolean_complete) {
        l = 'primary'
      } else if (this.state.course.license.failed) {
        l = 'danger'
      } else {
        l = 'success'
      }
    }
    return l;
  }

    render() {
        return (
          <div>
          <div style={{padding: "10px 40px", marginTop: "10px"}}>
            <div className="pull-right">
              <Link to="/courses" className="btn btn-white btn-ms pull-right hidden-xs">Back to Courses</Link>
            </div>
          </div>
    <div className={'wrapper wrapper-content ' + this.state.spinnerClass}>
      <div className="sk-spinner sk-spinner-double-bounce">
        <div className="sk-double-bounce1"></div>
        <div className="sk-double-bounce2"></div>
      </div>
      <div className="ibox">
        <div className="ibox-content">
          <div className="row">
            <div className="col-md-9 p-md">
              <h1>{this.state.course.title}</h1><br/>
              {/*<h4>TODO: Learn Python like a Professional! Start from the basics and go all the way to creating your own applications and games!</h4>*/}
              <h5>
                {this.state.course.label &&                  
                  <span><span className="label label-success">{this.state.course.label}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                }                
                {this.courseRating()} &nbsp;
                {this.state.course.feedbacks > 0 &&
                  <span>({this.state.course.feedbacks} ratings)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                }
                <span>visited {this.state.course.views} times</span>
              </h5>
              <h5>
                <strong>Created by</strong>&nbsp;{this.state.course.author}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong>Last updated</strong>&nbsp;{monthDateFormat(this.state.course.last_updated)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.course.language &&
                  <span><strong>Language</strong>&nbsp;{this.state.course.language}</span>
                }
              </h5>
              <h5>
                <CourseProgress document={this.state.course}/>
              </h5>
            </div>
            <div className="col-md-3">
              <img style={{width:'100%', padding: '20px'}} src={iconUrl(this.state.course)} />
              <br />
              <RequestCourse course={this.state.course} onFinishRequest={this.getCourse}/>
            </div>
          </div>
        </div>
        {this.state.course.will_learn_items.length > 0 && 
        <div className="ibox-content ibox-heading p-md">
          <h2>What will I learn</h2>
          <ul className="row list-unstyled p-xs">            
            {
              this.state.course.will_learn_items.map((learn_item, i) => (
                <li className="col-md-6" key={'lu' + i}><FontAwesomeIcon icon={faCheck} className="text-success" /> {learn_item}</li>
              ))
            }
          </ul>
        </div>
        }
        <div className="ibox-content">
          <h2>Description</h2>
          <div dangerouslySetInnerHTML={this.descriptionMarkup()} />
        </div>
        <div className="ibox-content">
          <h2>Content</h2>
          <StartCourse document={this.state.course}/>
        </div>
      </div>
    </div>

</div>
        )
    }

}

export default CourseView;
