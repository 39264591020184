import React from 'react';

class Blank extends React.Component {

    render() {
      return (
        <div id='wrapper'>
          <div className='wrapper wrapper-content'>
            {
              React.createElement(this.props.component, this.props)
            }
          </div>
        </div>
      )
    }

    componentDidMount(){
        $('body').addClass('gray-bg');
    }

    componentWillUnmount(){
        $('body').removeClass('gray-bg');
    }
}

export default Blank