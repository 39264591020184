// @flow
import React, { Component } from 'react';

import EventView from "./EventView";
import { Modal, Button } from 'react-bootstrap';
import Masonry from 'react-masonry-component';
import { client } from '../Client';
import Config from 'config';

class UpcomingEvents extends Component {

  state = {
    events: [],
    numEvents: 0,
    spinnerClass: 'sk-loading'
  };

  componentDidMount() {
    client.getEvents(this.props.city, this.props.coordinates).then((data) => {
      const numEvents = data.events !== undefined ? data.events.length : 0
      this.setState({numEvents: numEvents, events: (data.events !== undefined ? data.events : []), spinnerClass: '' });
    });
  }
  
  renderEvents() {
    let upcoming = this;
    if (this.state.events.length > 0) {
      return (
        <div>
          { 
            this.state.events.map(function(evt) {
              return <div key={evt.id} className="col-lg-6"><EventView event={evt} onSelected={upcoming.props.onSelected}/></div>
            })
          }
        </div>  
      )
    } else {
      if (this.state.spinnerClass === '') {
        return (
          <div>There are no events in {this.props.city}.</div>
        )
      }    
    }
  }

  render() {
    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>Select Event</Modal.Title>
        </Modal.Header>
        <Modal.Body className="gray-bg">
          <div>
            <h3>Events in {this.props.city} <small>({this.state.numEvents})</small></h3>
            <Masonry className={'wrapper wrapper-content ' + this.state.spinnerClass}>
              <div className="sk-spinner sk-spinner-double-bounce">
                  <div className="sk-double-bounce1"></div>
                  <div className="sk-double-bounce2"></div>
              </div>
              {this.renderEvents()}
            </Masonry>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.props.onCloseBtnClick()}>Cancel</Button>
        </Modal.Footer>
      </div>
    )
  }
}

export default UpcomingEvents;
