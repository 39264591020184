import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { satisfyMinimalPrice } from '../../../helpers/Gift';
import { faCcAmazonPay } from '@fortawesome/free-brands-svg-icons/faCcAmazonPay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './RedeemDialog.css';

class GiftCardItem extends Component {
  
  calcGiftPrice = () => {
    return Math.floor(this.props.rewardType.points / this.props.rewardType.points_ratio) ;
  }
  
  isActive = () => {
    return this.props.employeePoints >= this.props.rewardType.points && 
      satisfyMinimalPrice(this.props.employeePoints, this.props.rewardType.points_ratio)
  }
  
  itemClassName = () => {
    let classname = 'redeem-type-item';
    if (!this.isActive()) classname += ' inactive';
    return classname;
  }
  
  renderBrandsLink = () => {
    if (this.isActive()) {
      return (
        <Link to='/gift_brands' style={{'color': 'inherit'}}>
          {this.renderItem()}
        </Link>
      )
    } else {
      return (
        <>
          {this.renderItem()}
        </>
      )
    }
  }
  
  renderItem = () => {
    return (
      <div className={this.itemClassName()}>
        <div className="pull-left">
          <FontAwesomeIcon icon={faCcAmazonPay} size='2x' className='m-sm'/>
        </div>
        <div className="pull-left">
          <h4>Gift Card </h4>
          <span><small>Redeem {this.props.rewardType.points} points for a ${this.calcGiftPrice()} gift card.</small></span>
        </div>
        <div className="clearfix"/>                
      </div>      
    )
  }
  
  render() {
    return (
      <div className="row">
        <div className='col-sm-12'>        
          {this.renderBrandsLink()}
        </div>
      </div>  
    )
  }
}

export default GiftCardItem;
