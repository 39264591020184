// @flow
import React from 'react';
import SpotlightSearch from './SpotlightSearch';
import SpotlightEmployeeRow from './SpotlightEmployeeRow';

class MySpotlightSearch extends React.Component {
        
  showSpotlight() {
    this.refs.spotlightSearch.showSpotlight();
  }

  render() {
      return (
        <SpotlightSearch ref="spotlightSearch" placeholder="Find Employee" >
          <SpotlightEmployeeRow />
        </SpotlightSearch>
      )
  }
}

export default MySpotlightSearch;
