// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { client } from '../components/Client';
import { companySettingsClient } from '../clients/CompanySettingsClient';
import AdminMenuItems from '../components/common/AdminMenuItems';
import EmployeeMenuItems from '../components/common/EmployeeMenuItems';

class MobileMenuView extends Component {
  
  state = {
    userAccessSettings: { pickup_shift: false,
                          acknowledgement: false,
                          poll: false, }
  }
  
  componentDidMount() {
    if ( !client.adminLoggedIn() ) {
      this.getCurrentUserAccessSettings();
    }  
  }
  
  getCurrentUserAccessSettings = () => {
    companySettingsClient.getCurrentUserAccessSettings()
    .then((response) => {
      if (response.access_settings) {
        this.setState({ userAccessSettings: response.access_settings });                          
      }  
    });
  }
  
  render() {
    return (
      <div className="hidden-lg hidden-md hidden-sm">   
        { client.adminLoggedIn() && 
          <AdminMenuItems containerClass="nav navbar-nav ezzely-header mobile-menu" isMobileMenu={true} currentPath={this.props.location.pathname} />
        }
        { !client.adminLoggedIn() && 
          <EmployeeMenuItems 
            containerClass="nav navbar-nav ezzely-header mobile-menu" 
            isMobileMenu={true} 
            currentPath={this.props.location.pathname} 
            pickShiftIsAllowed={this.state.userAccessSettings.pickup_shift}
            acknowledgementIsAllowed={this.state.userAccessSettings.acknowledgement}
            pollIsAllowed={this.state.userAccessSettings.poll}
          />  
        }
      </div>      
    )
  }

}

export default MobileMenuView;
