import moment from 'moment';
class Survey {
  constructor(obj) {
    for (var prop in obj) {
      if (prop === 'start_time' || prop === 'end_time') {
        this[prop] = moment.parseZone(obj[prop]).toDate();
      } else {
        this[prop] = obj[prop];
      }
    }
  }

  getStatus() : string {
    return this.isActive() ? 'Active' : 'Finished';
  }

  isActive() {
    const current = moment();
    return current.isBetween(this.start_time, this.end_time);
  }
}

export default Survey;
