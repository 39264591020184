import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import FileUploadProgress  from 'react-fileupload-progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import '../../../public/styles/upload-progress.css'
import { toastrNotification } from './../../helpers/Toastr';
import { client } from '../Client';

let message = '';

class UploadAttachment extends Component {

  state = {
    showModal: false,
    title: null,
    category: null,
    categories: [],
    file: null,
    warning: ''
  };

  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true });
    client.getAttachmentCategories().then((categories) => {
      this.setState({ categories: categories })
    });
  }

  disableButton = (warn = '') => {
    this.setState({category: null, title: null, file: null, warning: warn });
  }

  formGetter = () => {
    return new FormData(document.getElementById('upload-document-form'));
  }

  onCategorySelect = (evt) => {
    this.setState({category: evt.target.value});
  }

  onTitleEnter = (evt) => {
    this.setState({title: evt.target.value});
  }

  onFileSelect = (evt) => {
    this.setState({file: evt.target.value});
  }

  canSubmit = () => {
    return (!!this.state.category && !!this.state.title && !!this.state.file);
  }

  customFormRenderer = (onSubmit) => {
     return (
       <form style={{marginBottom: '15px'}} id="upload-document-form">
         <div className="row">
           <div className="col-sm-12">
             <input type="text" className="form-control" name="title" placeholder="Enter Title *" onChange={this.onTitleEnter}/>
           </div>
         </div>
         <br/>
         <div className="row">
           <div className="col-sm-12">
             <select className="form-control" tabIndex="-1" name="category_id" onChange={this.onCategorySelect} defaultValue="">
               <option key="-1" value="" disabled>Choose category *</option>
               {
                  this.state.categories.map((category) => (
                    <option key={category.id} value={category.id}>{category.title}</option>
                  ))
                }

             </select>
           </div>
         </div>
         <br/>
         <div className="row">
          <div className="col-sm-12">
             <input type="file" name='file' className="form-control" style={{height: 'auto'}}  onChange={this.onFileSelect}/>
           </div>
         </div>
         <br/>
         <div className="row">
          <div className="col-sm-9">
            <p>Upload doc, docx, pdf file or image</p>
          </div>
          <div className="col-sm-3 text-right">
           <button type="button" className="btn btn-primary" disabled={!this.canSubmit()} onClick={onSubmit}><FontAwesomeIcon icon={faUpload}/> Upload</button>
          </div>
         </div>
       </form>
     );
   }

   customProgressRenderer = (progress, hasError, cancelHandler) => {
      if (hasError || progress > -1 ) {
        let barStyle = {};
        barStyle.width = progress + '%';
        message = (<span>{barStyle.width}</span>);
        if (hasError) {
          message = (<span style={{'color': '#a94442'}}>Failed to upload ...</span>);
        }
        if (progress === 100){
          message = '';
        }

        return (
          <div>
            <div className="file-upload-progress-wrapper">
              <div style={barStyle} className="progress-bar progress-bar-success file-upload-progress-bar"></div>
            </div>
            <button className="cancel-upload file-upload-cancel-button" onClick={cancelHandler}>
              <span>&times;</span>
            </button>
            <div style={{'clear':'left'}}>{message}</div>
          </div>
        );
      } else {
        return;
      }
    }

 render() {

    return (
      <div>
        <button className="btn btn-white btn-sm  btn-block btn-outline" onClick={this.open}><FontAwesomeIcon icon={faUpload}/> Upload File</button>
        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Body>
              <h3 className="m-b">Upload Document</h3>
              <FileUploadProgress url={client.attachmentsUrl(this.props.employee.id)}
                method='post'
                formRenderer={this.customFormRenderer.bind(this)}
                progressRenderer={this.customProgressRenderer.bind(this)}
                formGetter={this.formGetter.bind(this)}
                beforeSend={(request) => {this.disableButton(); 
                  request.setRequestHeader('authorization', client.getAuthorizationHeader());
                  request.setRequestHeader('X-Client-Version', client.getClientVersion());
                  request.setRequestHeader('X-App-Version', client.getAppVersion());
                  return request; }}
                onError={(e, request) => {
                  this.props.onFinishUpload(false);
                  toastrNotification({success: false, message:'Unable to upload Document'});
                  }
                }
                onLoad={(e, request) => {
                  let json = JSON.parse(request.response);
                  this.props.onFinishUpload();
                  toastrNotification({success: json.success, message: json.success ? 'Document has been uploaded' : json.message});
                  this.close();
                }}
              />
              <div>{this.state.warning}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default UploadAttachment;
