import React, { Component } from 'react';

class RewardError extends Component {
  
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <p>We are unable to create reward at this time.</p>
            <p>{this.props.message}</p>
          </div>
        </div>
      </div>
    )
  }

}

export default RewardError;
