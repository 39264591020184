import React, { Component } from 'react';
import { mediumDateFormat } from './../../helpers/Formatter';
import { iconUrl } from './../../helpers/Courses'
import { client } from '../Client';

class CertificatesTable extends Component {

  render() {
    return (
      <div>
        { this.props.certificates.length === 0 && 
          <h2 className="text-center">No Certificates</h2>
        }
        { this.props.certificates.length > 0 && 
        <table className="table table-striped table-condensed">
          <thead>
          <tr>
            <th>Title</th>
            <th>Expires At</th>
            <th>Created At</th>
          </tr>
          </thead>
          <tbody>
          {
            this.props.certificates.map((certificate) => (                                                              
              <tr key={'cert' + certificate.document_id}>
                <td>
                  <div className="pull-left">
                    <img style={{width:'60px', marginRight: '20px'}} src={iconUrl(certificate)} />
                  </div>
                  { certificate.key &&                                           
                    <a href={client.downloadCertificateUrl(certificate)} target='_blank' title='Download Certificate'>{certificate.title}</a>
                  }
                  { !certificate.key &&                                           
                    <span>{certificate.title}</span>
                  }                                                                                
                </td>
                <td>{certificate.expires_at ? mediumDateFormat(certificate.expires_at) : ''}</td>
                <td>{mediumDateFormat(certificate.created_at)}</td>
              </tr>
            ))
          }
          </tbody>
        </table>
        }
      </div>
    )
  }
}

export default CertificatesTable;