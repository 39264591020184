import React from 'react';
import moment from 'moment';

export function statusLabel(poll) {
  if (poll.is_expired) {
    return (<span className="label">Inactive</span>);
  } else {
    return (<span className="label label-primary">Active</span>);  
  }   
}
