export function extractParameter(paramName, path) {
  if(path.length > 1 && path.charAt(0) === '?') {
    let p = path.substr(1).split('&').find(x => (x.split('=')[0] === paramName));
    return (p ? p.split('=')[1] : null);      
  } else 
    return null;  
}

export function parametersExcept(paramName, path) {
  if(path.length > 1 && path.charAt(0) === '?') {
    let p = path.substr(1).split('&').filter(x => (x.split('=')[0] !== paramName)).join('&');
    return ('?' + p);      
  } else 
    return null;  
}