// @flow
import React, { Component } from 'react';

import moment from 'moment';
import Event from './StubHubEvent';

class EventView extends Component {

  props: {
    event: Event,
    onSelected: Function
  }

  someFunction() {

  }

  formatDate(str: string): string {
    return moment(str).format('dddd MMM DD, hh:mma');
  }

  handleEventSelection() {
    if (this.props.onSelected) {
      this.props.onSelected(this.props.event);
    }
  }

  render() {
    let evt = this.props.event;
    return (
        <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>{evt.venue.name}</h5>
              <div className="ibox-tools">
                <button className="btn btn-xs btn-outline btn-success" onClick={() =>this.handleEventSelection()}>Select</button>
              </div>
            </div>
          <div className="ibox-content">
            <img src={evt.imageUrl} alt="image" style={{width: "100%"}} />
            <h3>{evt.name}</h3>
            <small className="text-muted">
              {evt.venue.name} <br />
              {this.formatDate(evt.eventDateLocal)}
            </small>
            <div className="pull-right text-success">from ${Math.ceil(evt.ticketInfo.minPriceWithCurrencyCode.amount)} per ticket</div>
          </div>
        </div>
    )
  }
}

export default EventView;
