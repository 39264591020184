import moment from 'moment';
class Acknowledgement {
  constructor(obj) {
    for (var prop in obj) {
      if (prop === 'created_at') {
        this[prop] = moment.parseZone(obj[prop]);        
      } else {
        this[prop] = obj[prop]; 
      }
    }
  }
}

export default Acknowledgement;
