import React, { Component } from 'react';
import { client } from '../components/Client.js';
import { companySettingsClient } from '../clients/CompanySettingsClient';
import EmployeeRequestList from '../components/pick_up_shifts/EmployeeRequestList';
import AdminRequestList from '../components/pick_up_shifts/AdminRequestList';

class PickUpShiftRequestsView extends Component {
  
    state = {
      isAccessible: false
    }
    
    componentDidMount() {
      this.checkUserAccess()
    }  
    
    checkUserAccess = () => {
      if ( client.adminLoggedIn() ) {
        this.setState({ isAccessible: true });
      } else {
        companySettingsClient.checkUserAccess("pickup_shift")
        .then((response) => {
          this.setState({ isAccessible: response.success });
          if ( !response.success ) {
            swal("Error!", "You dont have access to this page.", "error");
          }                             
        });
      }  
    }
    
    render() {
        return (
          <div className="wrapper wrapper-content animated fadeIn">
            { this.state.isAccessible && 
              ( client.adminLoggedIn() ? <AdminRequestList /> : <EmployeeRequestList /> )
            }  
          </div>
        )
    }

}

export default PickUpShiftRequestsView;
