import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import NumericInput from 'react-numeric-input';
import { Modal } from 'react-bootstrap';
import { giftMinimalPoints } from '../helpers/Gift';
import { client } from '../components/Client.js';

class GiftBrands extends Component {

  state = {
    points: 0,
    pointsLimit: 0,
    ratio: 10,
    brands: [],
    status: null,
    gift_link: null,
    message: null,
    search: null,
    searchTimer: null,
    loading: null,
    raffleMode: false,
  }

  componentDidMount() {
    const cardId = (new URLSearchParams(this.props.location.search)).get('card');
    this.setState({ spinner: true, raffleMode: !!cardId })
    if (cardId) {
      client.getRaffleGiftCard(cardId).then((giftCard) => {
        if (giftCard.redeemed_at) return;
        this.setState({ cardId })
        return giftCard.card_amount * this.state.ratio
      }).then(points => {
        if (points) return this.loadBrands(points, null)
          .then((data) => this.setState({ points }));
      });
    }
    else // load brands and set all points to numerical input
      this.loadBrands(giftMinimalPoints(this.state.ratio), null).then((data) => this.setState({ points: giftMinimalPoints(data.ratio) }))
  }

  loadBrands = (points, search) => {
    return client.getEmbeddedBrands(points, search).then((data) => {
      this.setState({
        brands: data.brands,
        ratio: data.ratio,
        pointsLimit: data.points,
        spinner: false
      });
      return data;
    })
  }

  onPointsChange = (points) => {
    if (this.state.searchTimer) {
      clearTimeout(this.state.searchTimer)
    }
    let pts = points > this.state.points ?
      (points + 10 - points % 10) : points < this.state.points ?
        (points - points % 10) : points
    if (pts > this.state.pointsLimit) {
      pts = this.state.pointsLimit
    }
    if (pts < giftMinimalPoints(this.state.ratio)) {
      pts = giftMinimalPoints(this.state.ratio)
    }
    this.setState({
      points: pts,
      spinner: true,
      searchTimer: setTimeout(() => { this.loadBrands(pts, this.state.search) }, 800)
    });
  }

  onSearchChange = (evt) => {
    const search = evt.target.value
    if (this.state.searchTimer) {
      clearTimeout(this.state.searchTimer)
    }
    this.setState({
      search: search,
      spinner: true,
      searchTimer: setTimeout(() => { this.loadBrands(this.state.points, search) }, 800)
    });
  }

  createGift = (brand, points) => {
    this.setState({ loading: brand, showModal: true })
    const gift_data = { brand };
    if (this.state.cardId) gift_data.card = this.state.cardId;
    else gift_data.points = points
    client.createEmbeddedGift(gift_data).then((response) => {
      if (response.success && response.gift.gift_link) {
        this.setState({ status: 'success', gift_link: response.gift.gift_link, pointsLimit: response.points });
      } else {
        this.setState({ status: 'error', message: response.message });
      }
      this.setState({ loading: null })
    })
  }

  close = () => {
    this.setState({ showModal: false });
  }

  renderLoader = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="sk-spinner-loader">
            <div className="sk-spinner sk-spinner-double-bounce">
              <div className="sk-double-bounce1"></div>
              <div className="sk-double-bounce2"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderBrand = (brand) => {
    const limit = 308 // taken from Amazon gift card
    let disclaimer = brand.disclaimer
    if (disclaimer && disclaimer.length > limit) {
      disclaimer = ''
      for (let sentence of brand.disclaimer.split('. ')) {
        if (disclaimer.length + sentence.length < limit) {
          disclaimer += (sentence + '. ')
        } else {
          break
        }
      }
    }

    return (
      <div className="brand-box" onClick={() => this.createGift(brand.brand_code, this.state.points)}>
        <div className="row">
          <div className="col-xs-12">
            <img src={brand.image_url} className="pull-left m-sm" />
            <h3>{brand.name}</h3>
            <p>{disclaimer}</p>
          </div>
        </div>
      </div>
    )
  }

  renderBrands = () => {
    if (this.state.spinner) {
      return this.renderLoader()
    } else {
      return (
        <div className="row equal">
          {
            this.state.brands.map((brand) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 m-b" key={'brand-' + brand.brand_code}>
                {this.renderBrand(brand)}
              </div>
            ))
          }
        </div>
      )
    }
  }

  renderBrandSelector = () => {
    return (
      <div className="row m-b">
        <div className='col-lg-4 col-md-5 col-sm-6 col-xs-6'>
          {!this.state.raffleMode &&
            <><span className="pull-left brand-points-text">Use </span><span className="pull-left">
              <NumericInput min={giftMinimalPoints(this.state.ratio)} max={this.state.pointsLimit} step={1}
                value={this.state.points}
                onChange={(val) => this.onPointsChange(val)}
                className="form-control" />
            </span><span className="pull-left brand-points-text"> points of {this.state.pointsLimit}</span></>
          }
        </div>
        <div className='col-lg-offset-5 col-lg-3 col-md-offset-3 col-md-4 col-sm-6 col-xs-6'>
          <span className="fake-line-480"> &nbsp; </span>
          <input type='text' className="form-control" placeholder="Search Brands" value={this.state.search || ''} onChange={(val) => this.onSearchChange(val)} />
        </div>
      </div>
    )
  }

  renderModalContent = () => {
    if (this.state.loading) {
      return this.renderLoader()
    } else if (this.state.status == 'success') {
      return (
        <div className="ibox">
          <p>Congratulations! We've generated a Gift Card for you!</p>
          <p className="text-center m-t-xl"><a href={this.state.gift_link} target="_blank" className="btn btn-primary">Get the Gift</a></p>
          <p><small>* You can get the gift later on 'My Rewards' page.</small></p>
        </div>
      )
    } else if (this.state.status == 'error') {
      return (
        <div className="ibox">
          <div className="alert alert-danger">Unable to generate gift card.</div>
          <p>{this.state.message}</p>
          <p>Please contact <a href="mailto:support@ezzely.com">Ezzely Support</a> for any questions.</p>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="wrapper wrapper-content animated fadeIn">
        <div className="m-b-lg">
          <h2 className="text-center">{this.state.raffleMode ? 'Select Your Gift' : 'Select number of points and get your gift.'}</h2>
        </div>
        {this.renderBrandSelector()}
        {this.renderBrands()}
        <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Body>
            <h3 className="m-b">Redeem Rewards</h3>
            {this.renderModalContent()}
          </Modal.Body>
          <Modal.Footer>
            <Link className="btn btn-default btn-sm" to="/rewards">
              Close
            </Link>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

}

export default withRouter(GiftBrands);
