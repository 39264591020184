import React, { Component } from 'react';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import SelectRafflePrizeDialog from './SelectRafflePrizeDialog';
import CreateRaffleEvent from './CreateRaffleEvent';
import CreateRaffleReward from './CreateRaffleReward';
import CreateRaffleGiftCard from './CreateRaffleGiftCard';

class CreateRaffle extends Component {

  state = {
    showModal: false,
    selectedPrizeType: null,
    step: 'initial',
    departmentsList: []
  }
      
  close = () => {
    this.setState({ showModal: false });
  }

  show = () => {
    this.setState({ showModal: true, selectedPrizeType: null, step: 'initial' });
  }
      
  selectPrizeType = (prizeType) => {
    this.setState({ selectedPrizeType: prizeType });
  }
  
  proceed = () => {
    this.setState({ step: 'build' })
  }
  
  modalSize() {
    return this.state.step !== 'initial' && this.state.selectedPrizeType === 'Event' ? 'large' : null
  }
    
 render() {
         
    return (
      <Modal show={this.state.showModal} onHide={this.close} bsSize={this.modalSize()}>
          { this.state.step == 'initial' && 
            <SelectRafflePrizeDialog 
              selectedPrizeType={this.state.selectedPrizeType}
              onSelectPrizeType={this.selectPrizeType}
              onCloseBtnClick={this.close}
              proceed={this.proceed}
            />
          }
          { this.state.step == 'build' && this.state.selectedPrizeType == 'Event' &&
            <CreateRaffleEvent 
              onCloseBtnClick={this.close}
              onAddRaffle={this.props.onAddRaffle}
            />
          }
          { this.state.step == 'build' && this.state.selectedPrizeType == 'Reward' &&
            <CreateRaffleReward 
              onCloseBtnClick={this.close}
              onAddRaffle={this.props.onAddRaffle}
            />
          }
          { this.state.step == 'build' && this.state.selectedPrizeType == 'Gift Card' &&
            <CreateRaffleGiftCard 
              onCloseBtnClick={this.close}
              onAddRaffle={this.props.onAddRaffle}
              companyBalance={this.props.companyBalance}
            />
          }
      </Modal>
    );
  }
}

export default CreateRaffle;
