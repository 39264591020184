// @flow
import React, { Component } from 'react';
import Coin from '../common/Coin';
import { client } from '../Client';
import ReactPaginate from 'react-paginate';

class AvailablePointsTransactionList extends Component {
  
  state = {
    transactions: [],
    page: 1,
    loading: false,
    pageCount:0
  }
  
  componentDidMount() {
    this.getEmployeeAvailablePointsTransactions();
  }
  
  getEmployeePointsTransactions = () => {
    let page = this.state.page;
    this.setState({ loading: true });
    client.getEmployeePointsTransactions(page, this.props?.employeeId).then((data) => {
      this.setState({ 
        transactions: data.transactions || [],
        pageCount: data.total_pages,
        loading: false
      });
    });
  }
  
  getEmployeeAvailablePointsTransactions = () => {
    let page = this.state.page;
    this.setState({ loading: true });
    client.getEmployeeAvailablePointsTransactions(page, this.props?.employeeId).then((data) => {
      this.setState({ 
        transactions: data.transactions || [],
        pageCount: data.total_pages,
        loading: false
      });
    });
  }
  
  handlePageClick = (data) => {
    let page = data.selected + 1;
    this.setState({ page: page }, () => {
      this.getEmployeeAvailablePointsTransactions()
    });
  }
  
  renderLoader = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="sk-spinner-loader">
            <div className="sk-spinner sk-spinner-double-bounce">
              <div className="sk-double-bounce1"></div>
              <div className="sk-double-bounce2"></div>
            </div>
          </div>  
        </div>
      </div>    
    )
  }

  render() {
    let dateFormat = require('dateformat');
    const { transactions } = this.state;
    return (
      <div className="full-height-scroll">
          { this.state.loading && this.renderLoader() }
          { !this.state.loading && 
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Details</th>
                        <th>Date</th>
                        <th>Points</th>
                        <th>Balance</th>
                      </tr>  
                    </thead>
                    <tbody>
                    {transactions.map((transaction) => (
                      <tr key={transaction.id}>
                         <td dangerouslySetInnerHTML={{__html: transaction.title}}/>
                         <td>{dateFormat(transaction.created_at, "mediumDate")}</td>
                         <td className={transaction.total < 0 ? 'balance-negative' : 'balance-positive'}><Coin width="16px" /> {transaction.total}</td>
                         <td className={transaction.balance < 0 ? 'balance-negative' : 'balance-positive'}><Coin width="16px" /> {transaction.balance}</td>
                      </tr>
                    ))}
                    </tbody>
                </table>
            </div>
          }
          {transactions.length > 0 && this.state.pageCount>1 &&
            <div className="row">
              <div className="col-md-12 text-center">
                <ReactPaginate
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  pageClassName={'paginate_button'}
                />
              </div>
            </div>
          }
      </div>
    )
  }
}
export default AvailablePointsTransactionList;
