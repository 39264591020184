import React, { Component } from 'react';
import { client } from '../../Client';
import Employee from '../../../models/Employee';
import debounce from 'lodash/debounce';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Loader.css';
import 'react-bootstrap-typeahead/css/Token.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Config from 'config';

class ActivityRecognitionsBox extends Component {

  state = {
    isLoading: false,
    employees: [],
    selectedEmployees: [],
    query: ''
  }

  cache = {}

  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 300);
    if (props.selectedEmployees?.length > 0) this.state.selectedEmployees = props.selectedEmployees;
  }

  componentDidUpdate() {
    if (this.props.autoFocus && this.state.selectedEmployees.length === 0) {
      this.refs.typeahead.getInstance().focus();
    }
  }

  getEmployees = () => {
    client.getEmployees()
      .then((data) => {
        let recognitions = [];
        data.map((item) => {
          let recognition = {};
          recognition.id = item.id;
          recognition.full_name = item.full_name();
          recognition.first_name = item.first_name;
          recognition.last_name = item.last_name;
          recognition.avatar_url = item.avatar_image_url();
          recognitions.push(recognition);
          return item;
        });
        this.setState({
          recognitions: recognitions
        });
      });
  }

  handleChange = (selectedEmployees) => {
    this.setState({ selectedEmployees }, () => {
      this.props.employeesChange && this.props.employeesChange(selectedEmployees);
    });
    if (selectedEmployees.length > 0) {
      this.props.validateRecognitionsBox(true);
    }
  }

  clear() {
    this.refs.typeahead.getInstance().clear();
    this.setState({ selectedEmployees: [] });
  }

  isValid() {
    return (this.state.selectedEmployees.length > 0)
  }

  getRecognitions() {
    return this.state.selectedEmployees;
  }

  handleInputChange = (query) => {
    this.setState({ query });
  }

  handlePagination = (e) => {
    const { query } = this.state;
    const cachedQuery = this.cache[query];

    // Don't make another request if:
    // - we've already fetched all possible results
    if (
      cachedQuery.employees.length === cachedQuery.totalCount
    ) {
      return;
    }

    this.setState({ isLoading: true });

    const page = cachedQuery.page + 1;

    client.getPaginatedEmployees(page, query)
      .then((resp) => {
        let newEmployees = resp.employees.map(employee => {
          let em = {};
          em.full_name = employee.full_name();
          em.avatar_url = employee.avatar_url;
          em.id = employee.id;
          return em;
        });
        const employees = cachedQuery.employees.concat(newEmployees);
        this.cache[query] = { ...cachedQuery, employees, page };
        this.setState({
          isLoading: false,
          employees: employees
        });
      });
  }

  handleSearch = (query) => {
    if (this.cache[query]) {
      this.setState({ employees: this.cache[query].employees });
      return;
    }

    this.setState({ isLoading: true });

    client.getPaginatedEmployees(1, query)
      .then((resp) => {
        let employees = resp.employees.map(employee => {
          let em = {};
          em.full_name = employee.full_name();
          em.avatar_url = employee.avatar_url;
          em.id = employee.id;
          return em;
        });
        this.cache[query] = { totalCount: resp.totalCount, employees, page: 1 };
        this.setState({
          isLoading: false,
          employees: employees
        });
      });
  }

  renderMenuItemChildren(option, props, index) {
    return (
      <div key={option.id}>
        <img
          src={option.avatar_url}
          style={{
            height: '24px',
            marginRight: '10px',
            width: '24px',
          }}
        />
        <span>{option.full_name}</span>
      </div>
    );
  }

  render() {

    return (
      <div>
        <AsyncTypeahead
          ref="typeahead"
          isLoading={this.state.isLoading}
          multiple={true}
          allowNew={false}
          defaultSelected={this.props.selectedEmployees || []}
          options={this.state.employees}
          labelKey="full_name"
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          placeholder={this.props.placeholder || "Select the person you want to reward"}
          renderMenuItemChildren={this.renderMenuItemChildren}
          onPaginate={this.handlePagination}
          onInputChange={this.handleInputChange}
          minLength={2}
          maxResults={5}
          paginate
          useCache={false}
          emptyLabel={false}
        />
      </div>
    )
  }
}



export default ActivityRecognitionsBox;
