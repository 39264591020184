import React, { Component } from 'react';
import { raffleClient } from '../clients/RaffleClient';
import RaffleList from '../components/raffle/RaffleList';

class RafflesView extends Component {
    
    render() {
        return (
            <RaffleList />
        )
    }

}

export default RafflesView;
