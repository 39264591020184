// @flow
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import RawHtml from "react-raw-html";
import { acknowledgementClient } from '../../clients/AcknowledgementClient';
import Acknowledgement from '../../models/Acknowledgement';

class AcknowledgementNotification extends Component {
  
 state = {
   acknowledgement: new Acknowledgement({ responded_employees: [] }),
   spinnerClass: 'sk-loading'
 }
 
 componentDidMount() {
   this.setState(this.state);
   this.getAcknowledgement();
 }
 
 handleAcknowledgementAttachmentDownload = (acknowledgement) => {
   acknowledgementClient.downloadAcknowledgementAttachment(acknowledgement);
 } 

 getAcknowledgement = () => {
   acknowledgementClient.getAcknowledgement(this.props.notification.source_id).then((acknowledgement) => {
     this.setState({ acknowledgement: acknowledgement, spinnerClass: '' })
   });
 }
    
 render() {
       
    return (
      <div>
        <Modal.Body>
          <div className={'wrapper wrapper-content' + this.state.spinnerClass}>
              <div className="sk-spinner sk-spinner-double-bounce">
                <div className="sk-double-bounce1"></div>
                <div className="sk-double-bounce2"></div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h4>{ this.state.acknowledgement.title }</h4>
                  <RawHtml.div>{ this.state.acknowledgement.message }</RawHtml.div>
                  { this.state.acknowledgement.original_filename &&
                    <div>
                      <p>
                        <a onClick={() => this.handleAcknowledgementAttachmentDownload(this.state.acknowledgement)} title={this.state.acknowledgement.original_filename}>
                        {this.state.acknowledgement.original_filename}
                        </a>
                      </p>
                    </div>
                  } 
                </div>
              </div> 
          </div>
        </Modal.Body>  
        <Modal.Footer>  
          <Button onClick={this.props.close}>Close</Button>
        </Modal.Footer>  
      </div>   
    );
  }
}

export default AcknowledgementNotification;
