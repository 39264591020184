import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import FileUploadProgress  from 'react-fileupload-progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import '../../../public/styles/upload-progress.css'
import { toastrNotification } from './../../helpers/Toastr';
import { client } from '../Client';
import { companySettingsClient } from '../../clients/CompanySettingsClient';

let message = ''; 
const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
const maxFileSize = 5; //5mb

class UploadDocument extends Component {
  
  state = {
    showModal: false,
    title: null,
    description: null,
    category: null,
    categories: [],
    departments: [],
    department_id: null,
    file: null,
    warning: '',
    fileValidationMessages: [],
    allowedToUploadEmployees: []
  };
  
  componentDidMount() {
    this.getAllowedQuickTrainEmployees()
  }
  
  getAllowedQuickTrainEmployees = () => {
    companySettingsClient.getAllowedEmployees("quick_train")
    .then((response) => {
      this.setState({ allowedToUploadEmployees: response.employee_ids })                                
    });
  }
  
  close = () => {
    this.setState({ showModal: false, departments: [] });
  }

  open = () => {
    this.setState({ showModal: true });
    this.disableButton();
    client.getQuickCategories().then((categories) => {
      this.setState({ categories })
    });
    client.getEmployeeDepartmentsList().then((departments) => {
      this.setState({ departments });
    });
  }
      
  disableButton = (warn = '') => {
    this.setState({ title: null, description: null, category: null, file: null, warning: warn, fileValidationMessages: [] });
  }
  
  formGetter = () => {
    return new FormData(document.getElementById('upload-document-form'));
  }
  
  onCategorySelect = (evt) => {
    this.setState({category: evt.target.value});
  }
  
  onDepartmentSelect = (evt) => {
    this.setState({department_id: evt.target.value});
  }
  
  onTitleEnter = (evt) => {
    this.setState({title: evt.target.value});    
  }
  
  onDescriptionEnter = (evt) => {
    this.setState({description: evt.target.value});    
  } 
  
  validateFileSize = (file) => {
    return !!file && file.size/1024/1024 <= maxFileSize;
  }
  
  validateFileExtension = (fileName) => {
    return !!allowedExtensions.exec(fileName.toLowerCase())
  }
  
  validateFile = (fileInput) => {
    let errorMessages = [];
    let fileSizeIsValid = this.validateFileSize(fileInput.files[0]);
    let fileExtensionIsValid = this.validateFileExtension(fileInput.value);
    if (!fileSizeIsValid) { errorMessages.push('Max file size is 5 MB') };
    if (!fileExtensionIsValid) { errorMessages.push('Allowed file extensions are .jpeg/.jpg/.png/.pdf/.doc/.docx/.xls/.xlsx') };
    this.setState({ fileValidationMessages: errorMessages });
    return fileSizeIsValid && fileExtensionIsValid;
  }
    
  onFileSelect = (evt) => { 
    if (this.validateFile(evt.target)) {
        this.setState({ file: evt.target.value, fileValidationMessages: [] });
    } else {
        this.setState({ file: null });
        evt.target.value = ''; 
        return false; 
    }       
  }
    
  canSubmit = () => {
    return ( !!this.state.category && !!this.state.title && !!this.state.file && !!this.state.department_id );
  }
  
  customFormRenderer = (onSubmit) => {
     return (
       <form style={{marginBottom: '15px'}} id="upload-document-form">
         <div className="row">
           <div className="col-sm-12">
             <input type="text" className="form-control" name="title" placeholder="Enter Title *" onChange={this.onTitleEnter}/>
           </div>
         </div>
         <br/>       
         <div className="row">
           <div className="col-sm-12">
             <textarea className="form-control" name="description" placeholder="Enter Comment" onChange={this.onDescriptionEnter}></textarea>
           </div>
         </div>
         <br/>       
         <div className="row">
           <div className="col-sm-12">
             <select className="form-control" tabIndex="-1" name="category_id" onChange={this.onCategorySelect} defaultValue="">
               <option key="-1" value="" disabled>Select category *</option>
               {
                  this.state.categories.map((category) => ( 
                    <option key={category.id} value={category.id}>{category.title}</option>
                  ))
                }               
             </select>
           </div>
         </div>         
         <br/> 
         <div>
           <div className="row">
             <div className="col-sm-12">
               <select className="form-control" tabIndex="-1" name="university_department_id" onChange={this.onDepartmentSelect} defaultValue="">
                 <option key="" value="" disabled>Select group *</option>
                 {
                    this.state.departments.map((department) => ( 
                      <option key={'dep-' + department.id} value={department.id}>{department.title}</option>
                    ))
                  }               
               </select>
             </div>
           </div>         
           <br/>
         </div>    
         <div className="row">
          <div className="col-sm-12">
              <input type="file" name='file' className="form-control" style={{height: 'auto'}} onChange={this.onFileSelect}/>
              <span className="validation-error">
                <ul>
                  {this.state.fileValidationMessages.map((errorMessage, index) => (
                    <li key={'fileError'+index}>{errorMessage}</li>
                  ))}
                </ul>  
              </span>
           </div>
         </div>    
         <br/>       
         <div className="row">
          <div className="col-sm-9">
            <p style={{marginTop: '8px'}}>Upload doc, docx, pdf file or image upto to 5 MB</p>
          </div>
          <div className="col-sm-3 text-right">
           <button type="button" className="btn btn-primary" disabled={!this.canSubmit()} onClick={onSubmit}><FontAwesomeIcon icon={faUpload}/> Upload</button>
          </div>
         </div>
       </form>
     );
   }  
  
   customProgressRenderer = (progress, hasError, cancelHandler) => {
      if (hasError || progress > -1 ) {
        let barStyle = {};
        barStyle.width = progress + '%';
        message = (<span>{barStyle.width}</span>);
        if (hasError) {
          message = (<span style={{'color': '#a94442'}}>Failed to upload ...</span>);
        }
        if (progress === 100){
          message = '';
        }

        return (
          <div>
            <div className="file-upload-progress-wrapper">
              <div style={barStyle} className="progress-bar progress-bar-success file-upload-progress-bar"></div>
            </div>
            <button className="cancel-upload file-upload-cancel-button" onClick={cancelHandler}>
              <span>&times;</span>
            </button>
            <div style={{'clear':'left'}}>{message}</div>
          </div>
        );
      } else {
        return;
      }
    }
  
 render() {
    return ( 
      <div>
        {( this.state.allowedToUploadEmployees.includes(client.currentUser().id) || client.adminLoggedIn() ) &&
          <button className="btn btn-primary btn-sm btn-block" onClick={this.open}><FontAwesomeIcon icon={faUpload}/> Quick Train Upload</button>
        }
        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Body>
              <h3 className="m-b">Upload Document</h3>
              <FileUploadProgress url={client.quickDocumentsUrl()}
                method='post'
                formRenderer={this.customFormRenderer.bind(this)}
                progressRenderer={this.customProgressRenderer.bind(this)}
                formGetter={this.formGetter.bind(this)}
                beforeSend={(request) => {this.disableButton(); 
                  request.setRequestHeader('authorization', client.getAuthorizationHeader());
                  request.setRequestHeader('X-Client-Version', client.getClientVersion());
                  request.setRequestHeader('X-App-Version', client.getAppVersion());
                  return request; 
                }}
                onError={(e, request) => {
                  this.props.onFinishUpload(false);
                  toastrNotification({success: false, message: '', title: 'Unable to upload Document'});
                  }
                }
                onLoad={(e, request) => {
                  let json = JSON.parse(request.response);
                  this.props.onFinishUpload();
                  toastrNotification({success: json.success, message: json.success ? 'Document has been uploaded' : json.message});
                  this.close(); 
                }}
              />        
              <div>{this.state.warning}</div>
            </Modal.Body>                
            <Modal.Footer>                
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>        
        </Modal>
      </div>
    );
  }
}

export default UploadDocument;
