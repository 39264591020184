import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { toastrNotification } from '../../helpers/Toastr';
import { pollClient } from '../../clients/PollClient';
import moment from 'moment';
import {RadioGroup, Radio} from 'react-radio-group';

class ActivityPollWidget extends Component {
  
  state = {
    loading: false,
    selectedChoice: null
  }

  vote() {
    this.setState({ loading: true })
    if (this.state.loading) return 
    pollClient.voteOnPoll(this.props.poll.instance_id, this.state.selectedChoice).then((response) => {
      if (response.success) {
        this.props.refreshEmployeePolls();
        toastrNotification({ success: true, title: 'Thank you.' }) 
      } else {
        toastrNotification({ success: false, title: 'Unable to vote.' })        
      }
      setTimeout(() => {
        this.setState({ loading: false })
      }, 1500)
    });   
  }
  
  hide() {
    this.setState({ loading: true })
    if (this.state.loading) return 
    pollClient.hidePoll(this.props.poll.instance_id).then((response) => {
      if (response.success) {
        this.props.refreshEmployeePolls();
      } else {
        toastrNotification({ success: false, title: 'Unable to hide poll.' })        
        setTimeout(() => {
          this.setState({ loading: false })
        }, 1500)
      }
    });   
  }
    
  handleChoiceSelect = (value) => {
    this.setState({ selectedChoice: value });
    if (this.props.updateChoiceOnParent) {
      this.props.updateChoiceOnParent(value)
    }
  }
  
  renderTimeLeft() {
    let now = moment();
    let deadline = moment(this.props.poll.expires_at);
    if (now.isAfter(deadline)) {
      return (
        'Poll is expired.'
      )
    } else {
      return (
        <span>Voting expires { deadline.from(now) }</span>
      )  
    }
  }
  
  render() {
      let poll = this.props.poll;
    
      return (
        <div className="ibox float-e-margins">
            <div className="ibox-title">
                <h5>Quick Poll for {poll.department} group.</h5>
                <div className="ibox-tools">
                  {this.props.widgetMode && poll.voted &&
                    <a className="close-link" onClick={() => this.hide()}>
                      <FontAwesomeIcon icon={faTimes}/>
                    </a> 
                  }
                  { !poll.voted && this.renderTimeLeft() }
                </div>
            </div>
            <div className="ibox-content poll-widget-content">    
              <div>{poll.question}</div>
              <br/>
              {!poll.voted && 
                <div>
                  <div className="form-group">
                    <RadioGroup name="choice" selectedValue={this.state.selectedChoice} onChange={this.handleChoiceSelect}>
                      {
                        poll.choices.map((choice) => (
                          <div className="radio" key={"choice"+choice.id}>
                            <label>
                              <Radio value={choice.id} />{choice.title}
                            </label>
                          </div>  
                        ))
                      }    
                    </RadioGroup>
                  </div>
                  {this.props.widgetMode && 
                    <div>
                      <button type="button" disabled={!this.state.selectedChoice} className="btn btn-primary" onClick={() => this.vote()}>Vote</button>
                    </div>
                  }  
                </div>
              }
              {poll.voted &&
                <div>
                  <div>
                    {
                      poll.choices.map((choice) => (
                        <div key={"choice"+choice.id}>
                          <div>
                            <span>{choice.title}</span>
                            <small className="float-right">{choice.percentage}%</small>
                          </div>
                          <div className="progress progress-small">
                            <div style={{width: `${choice.percentage}%`}} className="progress-bar"></div>
                          </div>
                        </div>  
                      ))
                    }      
                  </div>
                  <div style={{fontWeight: "400"}}>
                    Votes polled: {poll.votes} 
                  </div>  
                </div>
              }  
            </div>
        </div>
      )
    }
}

export default ActivityPollWidget;
