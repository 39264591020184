import React, { Component } from 'react';
import {withFormsy} from 'formsy-react';

class Textarea extends Component { 
  
  handleChange = (evt) => {
    this.props.setValue(evt.target.value);
    if (this.props.onChange) this.props.onChange(evt);
  }
    
  render() {

    const errorMessage = this.props.errorMessage;

    return (
      <div>
        <textarea
          type={this.props.type || 'text'}
          name={this.props.name}
          onChange={this.handleChange}
          value={this.props.value}
          className={this.props.className}
          placeholder={this.props.placeholder}
          tabIndex={this.props.tabIndex}
        />
        <span className='validation-error'>{errorMessage}</span>
      </div>  
    );
  }
}

export default withFormsy(Textarea)
