import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';
import Poll from '../models/Poll';

class PollClient {
  getPolls() {
    return client.callApi(Config.API_BASE + '/polls.json')
      .then((polls) => polls.map((pollData) => (new Poll(pollData))))
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  createPoll(pollData) {
    return client.callApi(Config.API_BASE + '/polls.json', {}, 'POST', {poll: pollData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  getPoll(pollId) {
    return client.callApi(Config.API_BASE + '/polls/' + pollId + '.json')
      .then((pollData) => (new Poll(pollData)))
      .catch((error) => {
        client.handleApiError(error);
        return [] ;
      });        
  }
  
  getPollInstance(pollId) {
    return client.callApi(Config.API_BASE + '/poll_instances/get_instance.json?poll_id='+pollId)
      .catch((error) => {
        client.handleApiError(error);
        return [] ;
      });        
  }
  
  updatePoll(pollId, pollData) {
    return client.callApi(Config.API_BASE + '/polls/'+pollId+'.json', {}, 'PUT', {poll: pollData})
      .then((pollData) => new Poll(pollData))
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  getActivityPolls() {
    return client.callApi(Config.API_BASE + '/poll_instances.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  voteOnPoll(instanceId, selectedChoiceId) {
    return client.callApi(Config.API_BASE + '/poll_instances/'+instanceId+'/vote.json', {}, 'PUT', {selected_choice_id: selectedChoiceId})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });       
  }
  
  hidePoll(instanceId) {
    return client.callApi(Config.API_BASE + '/poll_instances/'+instanceId+'/hide.json', {}, 'PUT')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });       
  }
  
  deletePoll(pollId) {
    return client.callApi(Config.API_BASE + '/polls/'+ pollId +'.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  } 
    
}

export const pollClient = new PollClient();
