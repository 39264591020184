import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Checkbox} from 'react-ui-icheck';
import { toastrNotification } from '../../helpers/Toastr';

class DepartmentsSelectionStep extends Component {

  state = {
    selectedDepartments:[],
    departments_checkboxes: {},
    departments: []
  }
  
  componentDidMount() {
    this.setState(this.props.getSurveyData());
  } 
  
  handleDepartmentChange = (department, evt) => {   
    let nextSelectedDepartments = [];
    const checkbox_state = !this.state.departments_checkboxes[department];
    if (checkbox_state) { 
      nextSelectedDepartments = this.state.selectedDepartments.concat(department)
    } else {
      nextSelectedDepartments = this.state.selectedDepartments.filter(d => d !== department)
    }
    const chks = Object.assign({}, this.state.departments_checkboxes, {[department]: checkbox_state });
    this.setState({departments_checkboxes: chks, selectedDepartments: nextSelectedDepartments});
    this.props.updateSurveyData({departments_checkboxes: chks, selectedDepartments: nextSelectedDepartments});
  } 
  
  isValidated() {
    let stepIsValid = false;
    if (this.state.selectedDepartments.length === 0) {
      toastrNotification({success: false, title: 'Please select one department at least'});   
    } else {
      stepIsValid = true;
    }
    return stepIsValid;
  }      
    
  render() {        
        return (
            <div className="row ibox">
              <div className="col-md-8 col-md-offset-2 ibox-content">
                <div className="clients-list">
                    <div className="full-height-scroll">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <tbody>
                                {
                                   this.props.departments.map((dep) => ( 
                                     <tr key={'d'+dep.id}>
                                       <td>
                                         <Checkbox 
                                            checkboxClass="icheckbox_square-green"
                                            checked={!!this.state.departments_checkboxes[dep.title]}
                                            onChange={(evt) => this.handleDepartmentChange(dep.title, evt)}
                                            label="&nbsp;"
                                         />
                                       </td>
                                       <td>
                                         {dep.title}
                                       </td>
                                     </tr>    
                                   ))
                                }                                  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
              </div>
            </div>        
        )
    }
}

export default DepartmentsSelectionStep
