import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';

class VideoPlayerModal extends Component {

  state = {
    showModal: false,
    url: ""
  };

  close = () => {
    this.setState({ showModal: false });
  }

  open = (url) => {
    this.setState({ showModal: true, url: url });
  }
  
 render() {
   
    return (
      <Modal show={this.state.showModal} onHide={()=>this.props.closeFnc()} >
          <Modal.Body>
            { <ReactPlayer url={this.state.url} playing={this.state.showModal} controls={true} width="100%" height="100%"/>}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-default btn-sm"  onClick={()=>this.props.closeFnc()}>Close</Button>
          </Modal.Footer>
      </Modal>
    );
  }
}

export default VideoPlayerModal;
