import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faCalendarAlt} from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import { mediumDateFormat } from './../../helpers/Formatter';
import { client } from '../Client';
import {Checkbox} from 'react-ui-icheck';

class BasicFields extends Component {
  
  state = {
    editMode:false,
    fields: {}
  }
  
  openEditForm = () => {
    this.setState({editMode: true,
      fields: {
        name:   this.props.university.name || '',
        verify_credentials: this.props.university.verify_credentials || false,
        show_owner_real_name: this.props.university.show_owner_real_name || false
      }
    });
  }  
  closeEditForm = () => {
    this.setState({editMode: false});
  }
  
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }
  
  onVerifyCredentialsChange = (evt) => {
    const checkboxState = !this.state.fields.verify_credentials;
    const fields = this.state.fields;
    fields[evt.target.name] = checkboxState;
    this.setState({ fields: fields });
  }    
  
  onShowOwnerRealNameChange = (evt) => {
    const checkboxState = !this.state.fields.show_owner_real_name;
    const fields = this.state.fields;
    fields[evt.target.name] = checkboxState;
    this.setState({ fields: fields });
  }    
  
  onDateChange = (field, date) => {
    const fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields: fields });
  }  
  
  handleSubmit = (evt) => {
    evt.preventDefault();
    this.props.onFormSubmit(this.state.fields);
    this.closeEditForm();
  }
  
  render() {
    if (this.state.editMode) {
      return (
        <div className="form-horizontal">
          <ul className="list-unstyled">             
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>Company Name</label></div>
              <div className="col-sm-9">
                <input type='text' value={this.state.fields.name} onChange={this.onInputChange} className='form-control' name='name'/>
              </div>
            </li>   
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>Verify Credentials</label></div>
              <div className="col-sm-9">
                <Checkbox
                  name="verify_credentials"
                  checkboxClass="icheckbox_square-green"
                  checked={this.state.fields.verify_credentials}
                  increaseArea="20%"
                  onChange={(evt) => this.onVerifyCredentialsChange(evt)}
                  label="&nbsp;"
                />
              </div>
            </li>    
            
          { client.ownerLoggedIn() &&
            <li className="form-group">
              <div className="col-sm-3 control-label"><label>Use company name when posting</label></div>
              <div className="col-sm-9">
                <Checkbox
                  name="show_owner_real_name"
                  checkboxClass="icheckbox_square-green"
                  checked={!this.state.fields.show_owner_real_name}
                  increaseArea="20%"
                  onChange={(evt) => this.onShowOwnerRealNameChange(evt)}
                  label="&nbsp;"
                />
              </div>
            </li>
          }
          </ul>
          
          <div className="modal-footer">
            <button onClick={this.handleSubmit} className="btn btn-sm btn-primary">Save</button>
            <button onClick={this.closeEditForm} className="btn btn-sm btn-default">Cancel</button>
          </div>
        </div>
      )      
    } else {
      return (
        <div>          
          <ul className="list-unstyled list-group clear-list">
            { this.props.university.name &&                    
            <li>
              <div>
                <label>Company Name : </label> {this.props.university.name}
              </div>
            </li>
            }            
            {this.props.university.created_at &&
            <li>
              <div>
                <label>Member since : </label> <FontAwesomeIcon icon={faCalendarAlt}/> {this.props.university.member_since_value()}
              </div>
            </li>
            }
            {this.props.university.year_started_at &&
            <li>
              <div>
                <label>Current year : </label> <FontAwesomeIcon icon={faCalendarAlt}/> {this.props.university.year_started_at_value()}
              </div>
            </li>
            }
            {this.props.university.passcode &&
            <li>
              <div>
                <label>Passcode : </label> {this.props.university.passcode}
              </div>
            </li>
            }
            {this.props.university.verify_credentials !== undefined &&
            <li>
              <div>
                <label>Verify employees credentials : </label> {this.props.university.verify_credentials ? 'Yes' : 'No' }
              </div>
            </li>
            }
            {client.ownerLoggedIn() && this.props.university.verify_credentials !== undefined &&
            <li>
              <div>
                <label>Use company name when posting : </label> {this.props.university.show_owner_real_name ? 'No' : 'Yes' }
              </div>
            </li>
            }
          </ul>
          
          <div className="modal-footer">
            <button type="button" className="btn btn-sm btn-default" onClick={this.openEditForm}><FontAwesomeIcon icon={faPencilAlt}/> Edit</button>
          </div>
        </div>
      )
    }
  }
}

export default BasicFields;
