import React from 'react';
import EmployeeRow from './EmployeeRow';
import './EmployeesTab.css';

class EmployeesTab extends React.Component {
    constructor(props) {
        super(props);
    }

    onSelectEmployee = (employee) => {
        this.props.onSelectEmployee(employee);
    };

    render() {
        return this.props.employees.length > 0
            ?
            <div className="full-height-scroll">
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <tbody>
                            {
                                this.props.employees.map((employee) => (
                                    <EmployeeRow key={employee.id} employee={employee} onSelectEmployee={this.onSelectEmployee} showStatus={this.props.showStatus} />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            :
            <div className="text-center m-t-md">
              <p>No employees found</p>
            </div>;
    }
}

EmployeesTab.defaultProps = {
    showStatus: true
};

export default EmployeesTab;