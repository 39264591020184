import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { toastrNotification } from '../../helpers/Toastr';
import NumericInput from 'react-numeric-input';
import moment from 'moment';
import { client } from '../Client';

class SurveyDetailsStep extends Component {
  
  state = {
    formFields: {
      name: '',
      start_time: moment().toDate(),
      end_time: moment().add(3, 'days').toDate(),
      points: 0
    }
  }
      
  componentDidMount() {
    this.setState(this.props.getSurveyData());
    this.props.updateSurveyData({ isInitialStep: false });
  }  
  
  onStartDateChange = (date) => {
    const fields = this.state.formFields;
    this.state.formFields.start_time = date;
    this.setState({ formFields: fields });
    this.props.updateSurveyData({ formFields: fields });       
  } 
   
  onEndDateChange = (date) => {
    const fields = this.state.formFields;
    this.state.formFields.end_time = date;
    this.setState({ formFields: fields });
    this.props.updateSurveyData({ formFields: fields });           
  }
    
  onInputChange = (evt) => {
    const fields = this.state.formFields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ formFields: fields });
    this.props.updateSurveyData({ formFields: fields });   
  }
  
  onPointsChange = (newPoints) => {
    const fields = this.state.formFields;
    fields.points = newPoints;
    this.setState({ formFields: fields });
    this.props.updateSurveyData({ formFields: fields });
  } 
  
  isValidated() {
    let allFiledsAreFilled = (!!this.state.formFields.name && !!this.state.formFields.start_time && !!this.state.formFields.end_time);
    let surveyDatesAreCorrect = (this.state.formFields.end_time > this.state.formFields.start_time)
    let stepIsValid = allFiledsAreFilled && surveyDatesAreCorrect
    
    if (!allFiledsAreFilled) {
      toastrNotification({success: false, title: 'Please fill the survey details form'});   
    } 
    
    if (!surveyDatesAreCorrect) {
      toastrNotification({success: false, title: 'Survey end time must be greater than start time'});   
    } 
    
    return stepIsValid;
  }     
    
  render() {
        return (
          <div className="row animated fadeInRight">          
            <div className="col-md-8 col-md-offset-2">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-sm-12">
                    <h3 className="m-t-none m-b">Survey Details</h3>
                    <p>Enter survey name and dates</p>
                    <form>
                      <div className="form-group">
                        <label>Name</label>
                        <input type="text" name="name" onChange={this.onInputChange} value={this.state.formFields.name} placeholder="Enter name" className="form-control"/>
                      </div>
                      <div className="form-group">
                        <label>Start Date</label>
                        <DatePicker name="start_time" onChange={this.onStartDateChange} selected={moment(this.state.formFields.start_time).toDate()} className="form-control" placeholderText="Start Date" />
                      </div>
                      <div className="form-group">
                        <label>End Date</label>
                        <DatePicker name="end_time" onChange={this.onEndDateChange} selected={moment(this.state.formFields.end_time).toDate()} className="form-control" placeholderText="End Date"/>
                      </div>
                      {client.adminLoggedIn() && 
                        <div className="form-group">
                          <label>Points for survey completion</label>
                          <NumericInput 
                            min={0} 
                            value={this.state.formFields.points}
                            onChange={(val) => this.onPointsChange(val)} 
                            className="form-control" 
                          />
                        </div>
                      }  
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        )
   }      
}

export default SurveyDetailsStep
