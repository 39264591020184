import React, { Component } from 'react';
import { client } from '../Client';
import { Link } from 'react-router-dom';
import { activeRoute, pickupshiftEnabled, renderProfileIcon } from '../../helpers/TopMenu';
import AppIcon from '../icons/AppIcon';
import { solidIcons } from '../icons/lib';

class AdminMenuItems extends Component {

  render() {
    const path = this.props.currentPath

    return (
      <ul className={this.props.containerClass}>
        {this.props.isMobileMenu &&
          <li className="nav-mobile-profile-item">
            <Link to="/profile" className="profile-link">
              {renderProfileIcon()}
              <div className="menu-item-label">
                <div className="current-user-name">{client.currentUser().first_name}</div>
                <small>View your profile</small>
              </div>
            </Link>
          </li>
        }
        <li className={"app-nav-link "+activeRoute('/employees', path)}>
          <Link to="/employees">
            <AppIcon icon={solidIcons.ActivitySolid} /> Employees
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/groups', path)}>
          <Link to="/groups">
            <AppIcon icon={solidIcons.GroupsSolid} /> Groups
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/verified_credentials', path)}>
          <Link to="/verified_credentials">
            <AppIcon icon={solidIcons.KeySolid} /> Verified Credentials
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/courses', path)}>
          <Link to="/courses">
            <AppIcon icon={solidIcons.TrainingSolid} /> Training
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/survey_builder', path)}>
          <Link to="/survey_builder">
            <AppIcon icon={solidIcons.ListSolid} /> Surveys
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/polls', path)}>
          <Link to="/polls">
            <AppIcon icon={solidIcons.CheckList} /> Quick Polls
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/account', path)}>
          <Link to="/account">
            <AppIcon icon={solidIcons.DollarSolid} /> Account Balance
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/raffles', path)}>
          <Link to="/raffles">
            <AppIcon icon={solidIcons.Raffles} /> Raffles
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/rewards', path)}>
          <Link to="/rewards">
            <AppIcon icon={solidIcons.RewardSolid} /> My Rewards
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/transactions', path)}>
          <Link to="/transactions">
            <AppIcon icon={solidIcons.History}/> Points History
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/reward_types', path)}>
          <Link to="/reward_types">
            <AppIcon icon={solidIcons.GiftSolid} /> Reward Settings
          </Link>
        </li>
        {pickupshiftEnabled() &&
          <li className={"app-nav-link "+activeRoute('/pick_up_shift_requests', path)}>
            <Link to="/pick_up_shift_requests">
              <AppIcon icon={solidIcons.PickShiftNew} /> Pick Up Shift
            </Link>
          </li>
        }
        <li className={"app-nav-link "+activeRoute('/acknowledgements', path)}>
          <Link to="/acknowledgements">
            <AppIcon icon={solidIcons.AcknowledgementNew} /> Acknowledgements
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/calendar', path)}>
          <Link to="/calendar">
            <AppIcon icon={solidIcons.CalendarSolid} /> Calendar
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/kpi_report', path)}>
          <Link to="/kpi_report">
            <AppIcon icon={solidIcons.DocReportSolid} /> KPI Report
          </Link>
        </li>
        <li className={"app-nav-link "+activeRoute('/settings', path)}>
          <Link to="/settings">
            <AppIcon icon={solidIcons.GearSolid} /> Settings
          </Link>
        </li>
        {this.props.isMobileMenu &&
          <li className="nav-mobile-logout-item">
            <Link to='/logout'>
              <AppIcon icon={solidIcons.Power} />
              <div className="menu-item-label">Log Out</div>
            </Link>
          </li>
        }
      </ul>
    )
  }
}
export default AdminMenuItems;
