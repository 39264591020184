import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { client } from '../components/Client';
import ClientCredentialsForm from '../components/login/ClientCredentialsForm';
import ForgotPasswordForm from '../components/login/ForgotPasswordForm';

class ClientCredentialsView extends Component {

  state = {
    shouldRedirect: false,
    forgotPassword: false,
    resetLinkSent: false
  };

    showForgotPasswordForm = () => {
    this.setState({forgotPassword: true});
  }

  hideForgotPasswordForm = () => {
    this.setState({forgotPassword: false});
  }
    
  render() {
      if (this.state.shouldRedirect) {
        return (
          <Redirect to={this.redirectPath()} />
        );
      } else {
        return (
          <div className="container">
            <div className="row signup-form">
              <div className="ibox float-e-margins">
                { !this.state.forgotPassword &&
                  <ClientCredentialsForm/>                  
                }
                { this.state.forgotPassword && !this.state.resetLinkSent &&
                  <ForgotPasswordForm onCancel={this.hideForgotPasswordForm} onSubmitEmail={this.resetLinkSent}/>                  
                }
              </div>
            </div>
          </div>
        )
    }
  }
}

export default ClientCredentialsView;