import React, { Component } from 'react';
import { kpiReportClient } from '../../clients/KpiReportClient';
import { currencyFormat } from '../../helpers/Formatter';

class AccountReport extends Component {
    
  state = {
    reportDataPresent: true,
    utilizedForTraining: 0,
    trainingBudged: 0,
    incentivesBudget: 0,
    utilizedForIncentives: 0
  };
  
  componentDidMount() {
    this.setState(this.state);
    this.getTrainingTransactions();
    this.getReportData();
  }
  
  getTrainingTransactions = () => {
    kpiReportClient.getTrainingTransactions().then((data) => {
      let totalCredit = 0, totalPurchase = 0
      for (let i = 0; i < data.operations.length; i++) {
         if (data.operations[i].operation_type === "purchase"){
           totalPurchase += data.operations[i].total
         };
         if (data.operations[i].operation_type === "credit"){
           totalCredit += data.operations[i].total
         };
      }
      this.setState({ utilizedForTraining: totalPurchase*(-1), trainingBudget: totalCredit })
    });
  }

  
  getReportData = () => {
    kpiReportClient.getReportData('account').then((data) => {
      this.setState({ incentivesBudget: parseFloat(data.ezzely_total_budget), utilizedForIncentives: parseFloat(data.utilized_for_incentive), reportDataPresent: data !== undefined })
    });
  }

  budgetDeposited() {
    return this.state.incentivesBudget + this.state.trainingBudget; 
  }  
  
  budgetUtilized() {
    return this.state.utilizedForIncentives + this.state.utilizedForTraining;
  }
  
  currentBalance() {
    return this.budgetDeposited() - this.budgetUtilized();
  }  
  
 renderReport = () => {
   if (this.state.reportDataPresent) {
     return (
       <div className='wrapper wrapper-content' style={{height: '380px'}}>
          <div className='row'>
             <div className="col-md-12">
                <table className="table">
                  <thead>
                    <tr>
                      <td><b>Current Time Frame</b></td>
                      <td><b>Year-to-Date</b></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><b>Total Budget Deposited</b></td>
                      <td><b>{currencyFormat(this.budgetDeposited())}</b></td>
                    </tr>
                    <tr>
                      <td style={{paddingLeft: '25px'}}>Incentive</td>
                      <td>{currencyFormat(this.state.incentivesBudget)}</td>
                    </tr>
                    <tr>
                      <td style={{paddingLeft: '25px'}}>Training</td>
                      <td>{currencyFormat(this.state.trainingBudget)}</td>
                    </tr>
                    <tr>
                      <td><b>Total Budget Utilized</b></td>
                      <td><b>{currencyFormat(this.budgetUtilized())}</b></td>
                    </tr>
                    <tr>
                      <td style={{paddingLeft: '25px'}}>Incentive</td>
                      <td>{currencyFormat(this.state.utilizedForIncentives)}</td>
                    </tr>
                    <tr>
                      <td style={{paddingLeft: '25px'}}>Training</td>
                      <td>{currencyFormat(this.state.utilizedForTraining)}</td>
                    </tr>
                    <tr>
                      <td><b>Current Balance</b></td>
                      <td><b>{currencyFormat(this.currentBalance())}</b></td>
                    </tr>
                  </tbody>
                </table>
             </div>  
          </div>
       </div>
     )
   } else {
     return (
       <div className="text-center">
         <h1>There is no report data</h1>
       </div>
     )
   }
 }  
  
 render() {

    return (
      <div>
        <h2>Account</h2>
        {this.renderReport()}
      </div>  
    );
  }
}

export default AccountReport;
