import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import Poll from '../models/Poll';
import { toastrNotification } from '../helpers/Toastr';
import SweetAlert from 'sweetalert-react';
import { pollClient } from '../clients/PollClient';
import moment from 'moment';
import { statusLabel } from '../helpers/Poll';
import ShowRespondedEmployeesModal from '../components/acknowledgements/ShowRespondedEmployeesModal';
import EditPollForm from '../components/polls/EditPollForm';

class PollView extends Component {

    state = {
      poll: new Poll({ choices: [], responded_employees: [], not_responded_employees: [] }),
      spinnerClass: 'sk-loading',
      showDeleteNotification: false,
      shouldRedirect: false,
      showUnableToEditNotification: false
    }

    componentDidMount() {
      this.setState(this.state);
      this.getPoll();
    }

    getPoll = () => {
      pollClient.getPoll(this.props.match.params.id).then((poll) => {
        this.setState({ poll: poll, spinnerClass: '' })
      });
    }
    
    openEditModal = () => {
      this.refs.editPollForm.open()
    }
    
    showUnableToEditNotification = () => {
      this.setState({ showUnableToEditNotification: true })
    }
    
    handleDeletePollClick() {
      this.setState({ showDeleteNotification: true });
    }
    
    deletePoll() {
      this.setState({ showDeleteNotification: false });
      pollClient.deletePoll(this.state.poll.id).then((response) => {
        if (response.success == true) {
          toastrNotification({ success: true, message: 'Poll was successfully deleted.' })
          this.setState({ shouldRedirect: true });
        } else {
          toastrNotification({ success: false, message: 'Unable to delete poll.' });
          this.getPoll();
        }
      });
    }
    
    showRespondedEmployeesModal = (ev, employees, title) => {
      ev.preventDefault();
      this.refs.respondedEmployeesModal.open(employees, title)
    }
    

    render() {
        let poll = this.state.poll;
      
        if (this.state.shouldRedirect) {
          return (
            <Redirect to="/polls" />
          );
        } else {
        return (
            <div className={'wrapper wrapper-content' + this.state.spinnerClass}>
                <div className="sk-spinner sk-spinner-double-bounce">
                  <div className="sk-double-bounce1"></div>
                  <div className="sk-double-bounce2"></div>
                </div>
                
                <div className="row">
                  <div className="col-lg-8 col-lg-offset-2 col-md-12">
                    <Link to="/polls" className="btn btn-white btn-sm pull-right m-b-sm hidden-xs">Back to polls</Link>
                    <div className="ibox">
                      <div className="ibox-title">
                        <h5>Poll Details</h5>
                      </div>
                      <div className="ibox-content poll-details-content">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-sm-12">
                                <dl className="dl-horizontal">
                                  <dt>Status</dt>
                                  <dd>{ statusLabel(poll) }</dd>
                                  <dt>Question:</dt>
                                  <dd>{ poll.question }</dd>
                                  <dt>Group:</dt>
                                  <dd>{ poll.department ? poll.department : "All Groups" }</dd>
                                  <dt>Created at:</dt>
                                  <dd>{ moment(poll.created_at).format('D MMM YYYY') }</dd>
                                  <dt>Expires at:</dt>
                                  <dd>{ moment(poll.expires_at).format('D MMM YYYY') }</dd>
                                  <dt>Employee responded:</dt>
                                  <dd>
                                     <a href="#" onClick={(ev) => this.showRespondedEmployeesModal(ev, poll.responded_employees, "Responded Employees")}>{poll.responded_employees.length}</a>
                                  </dd>   
                                  <dt>Employee yet to respond:</dt>
                                  <dd>
                                    <a href="#" onClick={(ev) => this.showRespondedEmployeesModal(ev, poll.not_responded_employees, "Not Responded Employees")}>{poll.not_responded_employees.length}</a>
                                  </dd>
                                  <ShowRespondedEmployeesModal ref="respondedEmployeesModal"/>
                                </dl>
                              </div>
                            </div>                         
                          </div>
                          <div className="col-sm-6">
                            {
                              poll.choices.map((choice) => (
                                <div key={"choice"+choice.id}>
                                  <div>
                                    <span>{choice.title}</span>
                                    <small className="float-right">{choice.percentage}%</small>
                                  </div>
                                  <div className="progress progress-small">
                                    <div style={{width: `${choice.percentage}%`}} className="progress-bar"></div>
                                  </div>
                                </div>  
                              ))
                            }  
                          </div>
                        </div>
                        <div className="row m-b-sm">
                          <div className="col-sm-12">
                            <br/>
                              {poll.responded_employees.length === 0 &&
                                <button className="btn btn-white m-r-sm" onClick={() => this.openEditModal()} >
                                  <FontAwesomeIcon icon={faPencilAlt}/> Edit
                                </button>
                              }
                              <button className="btn btn-white" onClick={() => this.handleDeletePollClick()}>
                                <FontAwesomeIcon icon={faTimes}/> Delete
                              </button>
                              <SweetAlert
                                show={this.state.showDeleteNotification}
                                type="warning"
                                title="Are you sure?"
                                confirmButtonColor="#DD6B55"
                                showCancelButton
                                text="This operation can not be undone."
                                onConfirm={() => this.deletePoll()}
                                onCancel={() => this.setState({ showDeleteNotification: false })}
                              />
                              <EditPollForm 
                                ref="editPollForm"
                                onUpdatePoll={this.getPoll}
                                poll={poll}
                                showUnableToEditNotification={this.showUnableToEditNotification}
                              />
                              <SweetAlert
                                show={this.state.showUnableToEditNotification}
                                type="warning"
                                title="Unable to edit poll."
                                confirmButtonColor="#DD6B55"
                                text="There are employees have voted on this poll."
                                onConfirm={() => this.setState({ showUnableToEditNotification: false })}
                              />
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>  
                </div> 
            </div>
        )
      }
    }
}

export default PollView
