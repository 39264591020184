import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt} from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import { toastrNotification } from '../../helpers/Toastr';
import moment from 'moment';
import { shiftRequestClient } from '../../clients/ShiftRequestClient';

class ActivityShiftRequest extends Component {
  
  state = {
    loading: false
  }

  createShiftRequestApplicant(is_active) {
    this.setState({ loading: true })
    if (this.state.loading) return 
    let applicantData = { shift_request_id: this.props.request.id,
                          is_active: is_active };
    shiftRequestClient.createShiftRequestApplicant(applicantData).then((response) => {
      if (response.success) {
        this.props.getShiftRequests();
        if (is_active) {
          toastrNotification({ success: true, title: 'Thank you for applying this request.' }) 
        };  
      } else {
        toastrNotification({ success: false, title: 'Something went wrong' })
        setTimeout(() => {
          this.setState({ loading: false })
        }, 1500)        
      }
    }); 
  }
  
  render() {
      let request = this.props.request;
    
      return (
            <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5><FontAwesomeIcon icon={faCalendarAlt} className="text-warning" /> Can anyone Pick Up Shift for {request.employee_full_name}</h5>
                </div>
                <div className="ibox-content ibox-heading">
                  <div className="row">
                    <div className="col-sm-12">
                      <p><strong>Date &ndash;</strong> {moment(request.start_date).format('D MMM YYYY')} to {moment(request.end_date).format('D MMM YYYY')}</p>
                      <p><strong>Shift Time &ndash;</strong> {request.start_time} - {request.end_time}</p>
                      {request.comment && 
                        <p>{request.comment}</p>
                      }  
                    </div>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="user-button">
                    <div className="row">
                        <div className="col-sm-6">
                           <button type="button" disabled={this.state.loading} className="btn btn-white btn-sm btn-block" onClick={() => this.createShiftRequestApplicant(true)}>Apply</button>  
                        </div>
                        <div className="col-sm-6">
                           <button type="button" disabled={this.state.loading} className="btn btn-white btn-sm btn-block" onClick={() => this.createShiftRequestApplicant(false)}>Pass</button>  
                        </div>
                    </div>
                  </div>
                </div>
            </div>
      )
    }
}

export default ActivityShiftRequest;
