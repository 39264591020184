import React, { Component } from 'react';

class WellnessView extends Component {

    render() {
        return (
            <div className="wrapper wrapper-content animated fadeIn">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center m-t-lg">
                            <h1>
                                Wellness
                            </h1>
                            <small>
                                Written in Wellness.js component
                            </small>
                        </div>
                        <ul>
                          <li>Rewards for annual health exams</li>
                          <li>Company sponsored gym visits</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

}

export default WellnessView
