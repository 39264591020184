import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faMagnet } from '@fortawesome/free-solid-svg-icons/faMagnet';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faSmile } from '@fortawesome/free-regular-svg-icons/faSmile';
import { client } from '../Client';

import './KeyMetricsReport.css'

class KeyMetricsReport extends Component {
    
  state = {
    showModal: false,
    spinnerClass: 'sk-loading',
    metrics: [],
    engagement_score: null,
    reportDataPresent: true
  };
  
  componentDidMount() {
    this.setState(this.state);
    //this.getSurveyReportData();
  }
  
  getSurveyReportData = () => {
    client.getSurveyReportData(this.props.survey.id, 'key_metrics').then((data) => {
      this.setState({ metrics: data.metrics, engagement_score: data.engagement_score, spinnerClass: '', reportDataPresent: data.metrics.length > 0 })
    });
  }
            
  close = () => {
    this.setState({ showModal: false });
    this.setState({ spinnerClass: 'sk-loading' })
  }

  open = () => {
    this.getSurveyReportData();
    this.setState({ showModal: true });
  }
  
  renderKeyMetric = (metric) => {
    let metricIcon = ""
    
    if (metric.category == "Workplace Satisfaction") {
      metricIcon = faStar
    } else if (metric.category == "Feeling Appreciated and Valued") {
      metricIcon = faSmile
    } else if (metric.category == "Employee Retention") {
      metricIcon = faMagnet
    } else if (metric.category == "Organizational Culture") {
      metricIcon = faBullhorn
    } else {
      metricIcon = faAsterisk
    }
    
    return (
      <div className="col-md-12">
        <div className="col-md-2 metric-icon">
          <span className="badge">
            <FontAwesomeIcon icon={metricIcon} size="2x"/>
          </span>  
        </div>
        <div className="col-md-8">
          <div>{metric.category}</div>
          <div className="progress progress-small">
            <div style={{width: `${metric.value * 10}%`}} className="progress-bar"></div>
          </div>
        </div>
        <div className="col-md-2 metric-value">
          {metric.value}
        </div>  
      </div>
    )
  }
  
 render() {

    return (
      <li>
        <a onClick={this.open}>
          Key Metrics Report
        </a>
        <Modal bsSize="large" show={this.state.showModal} onHide={this.close}>
            <Modal.Header closeButton>
              <Modal.Title>Key Metrics report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={'wrapper wrapper-content ' + this.state.spinnerClass}>
                 <div className="sk-spinner sk-spinner-double-bounce">
                   <div className="sk-double-bounce1"></div>
                   <div className="sk-double-bounce2"></div>
                 </div>
                 <div className='row'>
                 {
                    this.state.metrics.map((metric, index) => (
                      <div className="col-md-6" key={'km'+index} style={{marginBottom: '20px'}}> 
                        {this.renderKeyMetric(metric)}
                      </div>  
                    ))
                 }      
                 </div>
                 { this.state.engagement_score &&
                   <div className="row vertical-align" style={{marginBottom: '20px'}}>
                       <div className="col-md-12" style={{paddingLeft: '45px'}}>
                         <h2>Engagement Score <span className="font-bold">{this.state.engagement_score}</span></h2>
                       </div>                     
                   </div> 
                 }   
              </div>      
            </Modal.Body>  
            <Modal.Footer>  
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
        </Modal>
      </li>  
    );
  }
}

export default KeyMetricsReport;
