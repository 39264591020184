import React from 'react';
import RawHTMl from 'react-raw-html';

import './AppIcon.css'

export default function AppIcon(props) {
    const heroIcon = !props.icon.includes('xml:space="preserve"') && !props.default
    return (
        <RawHTMl.div>{`<div class="svg-icons${heroIcon ? ' hero-icons' : ''}${props.iconClass ? (" " + props.iconClass) : ''}">${props.icon}</div>`}</RawHTMl.div>
    );
}