// @flow
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import NotificationModal from '../notifications/NotificationModal';
import { FacebookShareButton } from 'react-share';
import { Link } from 'react-router-dom';
import ReelPrompt from '../reels/congratulations/ReelPrompt';

class NotificationRow extends Component {

  state = {
    read: this.props.notification.read
  }

  constructor(props) {
    super(props);
    this.promptRef = React.createRef();
  }

  componentDidMount() {
    const reelPrompt = this.promptRef.current;
    if (reelPrompt) {
      const notification = this.props.notification;
      if (notification) {
        if (notification.message.includes('gift card')) reelPrompt.giftCardModal();
        else reelPrompt.houseReward();
      }
    }
  }

  openNotificationModal = () => {
    this.refs.notificationModal.open();
    this.setState({ read: true })
  }

  renderWinnerNotification = (notification) => {
    if (notification?.config?.redeem_url)
      return (<div>
        <Link to={notification.config.redeem_url}>
          <span>{notification.message}</span>
          {notification.config.type == 'fbshare' &&
            <FacebookShareButton className="btn btn-fb btn-xs pull-right m-l-xs m-t-xs" url={notification.config.url} quote={notification.config.title}>
              <span><FontAwesomeIcon icon={faFacebookSquare} /> Share</span>
            </FacebookShareButton>
          }
        </Link>
      </div>);
    else return (<div>
      <span>{notification.message}</span>
      {notification.config.type == 'fbshare' &&
        <FacebookShareButton className="btn btn-fb btn-xs pull-right m-l-xs m-t-xs" url={notification.config.url} quote={notification.config.title}>
          <span><FontAwesomeIcon icon={faFacebookSquare} /> Share</span>
        </FacebookShareButton>
      }
    </div>);

  }

  renderRowBody = (notification) => {
    const created_date = <small className="text-muted">{notification.created_date()}</small>;

    if (notification.type == 'activity_post_reward' || notification.type == 'activity_recognition')
      return <div>
        <div><Link to={`/activity/posts/${notification.config.post_id}`}>{notification.message}</Link></div>
        {created_date}
      </div>;

    else if (notification.type == 'reel')
      return <div>
        <div><Link to={`/reel/${notification.config.reel_id}`}>{notification.message}</Link></div>
        {created_date}
      </div>;

    else if (notification.type == 'acknowledgement' || notification.type == 'poll')
      return <div>
        <div><a onClick={() => this.openNotificationModal()}>{notification.message}</a></div>
        {created_date}
      </div>;

    else if (notification.type == 'ticketwinner' || notification.type == 'rafflewinner')
      return this.renderWinnerNotification(notification);

    else if (notification.type == 'dep_member')
      return <div>
        <div><Link to={`/group/${notification.config.department_id}`}>{notification.message}</Link></div>
        {created_date}
      </div>;

    else if (notification.type == 'join_dep')
      return <div>
        <div><Link to={`/group/${notification.config.department_id}/candidates`}>{notification.message}</Link></div>
        {created_date}
      </div>;

    else if (notification.type == 'birthday')
      return <div>
        <div><Link to={`/activity/birthdays/${notification.config.employee_id}/new_post`}>{notification.message}</Link></div>
        {created_date}
      </div>;

    else return <div>
      <div>{notification.message}</div>
    </div>;
  }

  shareToFacebook = () => {
    console.log(window.plugins);
    if (window.plugins && window.plugins.socialsharing) {
      console.log(window.plugins.socialsharing);
      window.plugins.socialsharing.shareViaFacebookWithPasteMessageHint('Message via Facebook', null, 'https://ezzely.com', '',
        function () { console.log('share ok') },
        function (errormsg) {
          console.log('share failed');
          console.log(errormsg)
        }
      )
    } else {
    }
  }

  render() {
    const notification = this.props.notification;
    const containerClass = this.state.read ? "" : "unread-notification"
    let showReelPrompt = false;
    if ((notification.type === 'ticketwinner' || notification.type === 'rafflewinner') && notification.config?.type === 'fbshare' && !this.state.read)
      showReelPrompt = true;

    return (
      <div className={"notification-row row " + containerClass}>
        <div className="dropdown-messages-box">
          <div className="pull-left">
            <img alt="image" className={notification.icon.class_name} src={notification.icon.url} />
          </div>
          <div className="media-body">
            {this.renderRowBody(notification)}
          </div>
        </div>
        {(notification.type == 'acknowledgement' || notification.type == 'poll' || notification.type == 'activity_post_reward' || notification.type == 'activity_recognition') &&
          <NotificationModal notification={this.props.notification} ref="notificationModal" />
        }
        {showReelPrompt &&
          <ReelPrompt ref={this.promptRef} onAction={this.props.onMarkRead} />
        }
      </div>
    )
  }

}


export default NotificationRow;
