// @flow
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons/faFolder';
import moment from 'moment';
import { statusLabel } from '../../helpers/ShiftRequests';

class EmployeeRequestRow extends Component {
        
  render() {
    let request = this.props.shiftRequest;
    
    return (
      <tr>
        <td>
          {statusLabel(request)}
        </td>
        <td>{request.department}</td>
        <td>
          <Link to={`/shift_requests/${request.id}`}>
            {moment(request.start_date).format('D MMM YYYY')} - {moment(request.end_date).format('D MMM YYYY')}  
          </Link> 
        </td>
        <td>
          {request.start_time} - {request.end_time}
        </td>
        <td>
          {request.applicants_number}
        </td>
        <td>
          {request.approved()
            ? 
            <span>{request.performer_full_name}</span>
            :
            <span>&ndash;</span>
          }
        </td>
        <td>
          {moment(request.created_at).format('D MMM YYYY')}
        </td>
        <td>
          <Link to={`/shift_requests/${request.id}`} className="btn btn-white btn-sm">
            <FontAwesomeIcon icon={faFolder} /> View
          </Link>
        </td>
      </tr>
    )
  }

}


export default EmployeeRequestRow;
