/* eslint-disable prefer-template */
/* eslint-disable import/prefer-default-export */
import fetch from 'isomorphic-fetch';
import Config from 'config';
import Employee from '../models/Employee';
import University from '../models/University';
import Survey from '../models/Survey';
import ActivityPost from '../models/ActivityPost';

const CLIENT_VERSION = '4.1.1'
const LOCAL_STORAGE_KEY = 'ezzelyapp';

// There are risks with using localStorage for API tokens in a production
// application. You open yourself up to XSS attacks. If malicious
// JavaScript makes it into your app, that JavaScript will have access
// to localStorage and therefore any sensitive tokens.

// For more info on token management, see this article:
// https://auth0.com/blog/cookies-vs-tokens-definitive-guide/

// App.example = App.cable.subscriptions.create(..);
// App.example.unsubscribe();


class Client {
  constructor() {
    this.useLocalStorage = (typeof localStorage !== 'undefined');
    if (this.useLocalStorage) {
      this.userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    }
  }

  isLoggedIn() {
    const _isregistered = this.isRegistered();
    if (!_isregistered) this.removeToken();
    return _isregistered && this.userData.profile.status === 0;
  }

  isTerminatedLoggedIn() {
    return this.isRegistered() && this.userData.profile.status === 1;
  }

  isRegistered() {
    return this.userData !== null &&
      this.userData.auth !== null &&
      this.userData.auth.token.length > 0 &&
      this.userData.auth.expires_at > new Date();
  }

  adminLoggedIn() {
    return this.isLoggedIn() && (this.userData.auth.owner || this.userData.auth.admin);
  }

  ownerLoggedIn() {
    return this.isLoggedIn() && this.userData.auth.owner;
  }

  noQuicktrain() {
    if (this.isLoggedIn()) {
      if (this.userData.global === undefined) {
        return false;
      } else {
        return this.userData.global.noquicktrain;
      }
    }
  }

  noPosts() {
    if (this.isLoggedIn()) {
      if (this.userData.global === undefined) {
        return false;
      } else {
        return this.userData.global.noposts;
      }
    }
  }

  noComments() {
    if (this.isLoggedIn()) {
      if (this.userData.global === undefined) {
        return false;
      } else {
        return this.userData.global.nocomments;
      }
    }
  }

  noPickupshift() {
    if (this.isLoggedIn()) {
      if (this.userData.global === undefined) {
        return false;
      } else {
        return this.userData.global.nopickupshift;
      }
    }
  }

  currentUser() {
    const userData = (this.useLocalStorage && this.isLoggedIn()) ? this.userData.profile : {}
    return new Employee(userData)
  }

  updateCurrentUserAvatar(url) {
    if (this.useLocalStorage && this.isLoggedIn()) {
      this.userData.profile.avatar_url = url;
      if (this.useLocalStorage) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.userData));
      }
    }
  }

  updateCurrentUserDepartmentChannelId(departmentId) {
    if (this.useLocalStorage && this.isLoggedIn()) {
      this.userData.profile.department_channel_id = departmentId;
      if (this.useLocalStorage) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.userData));
      }
    }
  }

  setToken(json) {
    if (json.success) {
      this.userData = json;
      const expiration_time = new Date(Date.now() + json.auth.validity).getTime();
      this.userData.auth.expires_at = expiration_time;
      this.setOneSignalTags(json);
      if (this.useLocalStorage) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(json))
      }
    }
    return json;
  }

  setOneSignalTags(json) {
    if (window.plugins && window.plugins.OneSignal) {
      window.plugins.OneSignal.deleteTags(["website", "email", "phone", "uid"]);
      window.plugins.OneSignal.sendTags({
        website: json.profile.web_site,
        uid: json.profile.id
      });
      window.plugins.OneSignal.getTags(function (tags) {
        console.log('Tags Received: ' + JSON.stringify(tags));
      });
    }
  }

  removeToken() {
    this.userData = null;
    if (this.useLocalStorage) {
      if (window.plugins && window.plugins.OneSignal) {
        window.plugins.OneSignal.deleteTags(["website", "email", "phone", "uid"]);
      };
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }

  getClientVersion() {
    return CLIENT_VERSION;
  }

  getAppVersion() {
    return window.appVersion || '';
  }

  getConsumerUrl() {
    return Config.WEBSOCKET_URL + '?token=' + this.getAuthorizationHeader();
  }

  callApi(url, headers = {}, method = 'get', body = null) {
    return fetch(url, {
      method: method,
      headers: Object.assign({ accept: 'application/json', 'Content-Type': 'application/json', 'X-Client-Version': this.getClientVersion(), 'X-App-Version': this.getAppVersion(), authorization: this.getAuthorizationHeader() }, headers),
      body: (body ? JSON.stringify(body) : null)
    }).then(this.checkStatus)
      .then(this.parseJson)
  }

  login(email, password) {
    let headers = window.packageID ? { 'X-Requested-With': window.packageID } : {}
    return this.callApi(Config.API_BASE + '/auth/login', headers, 'POST', { email: email, password: password })
      .then((json) => this.setToken(json))
      .catch((error) => {
        this.handleApiError(error);
        return {
          success: false,
          communication_error: navigator && !navigator.onLine
        };
      });
  }

  loginByToken(token) {
    return this.callApi(Config.API_BASE + '/auth/login_by_token', {}, 'POST', { token: token })
      .then((json) => this.setToken(json))
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  forgotPassword(email) {
    return this.callApi(Config.API_BASE + '/auth/forgot_password', {}, 'POST', { email: email })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  checkResetPasswordCode(code) {
    return this.callApi(Config.API_BASE + '/auth/check_reset_password_code', {}, 'POST', { code: code })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  resetPassword(password, code) {
    let headers = window.packageID ? { 'X-Requested-With': window.packageID } : {}
    return this.callApi(Config.API_BASE + '/auth/reset_password', headers, 'POST', { password: password, code: code })
      .then((json) => this.setToken(json))
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  changePassword(data) {
    return this.callApi(Config.API_BASE + '/employees/change_password.json', { 'Content-Type': 'application/json' }, 'PUT', data)
      .then((json) => this.setToken(json))
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  employeeSignup(registrationData) {
    return this.callApi(Config.API_BASE + '/employee_registrations', {}, 'POST', { registration: registrationData })
      .then((json) => this.setToken(json))
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  submitPotentialClient(data) {
    return this.callApi(Config.API_BASE + '/potential_clients', {}, 'POST', { client: data })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  logout() {
    this.removeToken();
  }

  getLayoutUrl() {
    if (this.isLoggedIn()) {
      return (Config.API_BASE + '/assets/templates/css/' + this.userData.profile.template + '.css?' + Math.floor(Math.random() * 1000000).toString());
    } else {
      return null;
    }
  }

  getCoggnoCourses(isAdmin) {
    return this.callApi(Config.PORTAL_API_BASE + '/ezzely/api/courses.json?admin=' + isAdmin, { authorization: this.getPortalAuthorizationHeader() })
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getCoggnoMarketplaceCourses(page, category, search) {
    return this.callApi(Config.PORTAL_API_BASE + '/ezzely/api/marketplace_courses.json?page=' + page + '&category=' + category + '&search=' + search, { authorization: this.getPortalAuthorizationHeader() })
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getCoggnoTransactions() {
    return this.callApi(Config.PORTAL_API_BASE + '/ezzely/api/finance_operations.json', { authorization: this.getPortalAuthorizationHeader() })
      .catch((error) => {
        this.handleApiError(error);
        return { operations: [], coggno_balance: null };
      });
  }

  getEzzelyTransactions(page) {
    return this.callApi(Config.API_BASE + '/finance_operations.json?page=' + page)
      .catch((error) => {
        this.handleApiError(error);
        return { operations: [], coggno_balance: null };
      });
  }

  getCompanyBalance() {
    return this.callApi(Config.API_BASE + '/finance_operations/get_balance.json')
      .catch((error) => {
        this.handleApiError(error);
        return { balance: 0 };
      });
  }

  getEmployeePointsTransactions(page, employeeId) {
    let url = Config.API_BASE + '/employee_points_operations.json?page=' + page;
    if (employeeId) url += ('&id=' + employeeId);
    return this.callApi(url)
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getEmployeeAvailablePointsTransactions(page, employeeId) {
    let url = Config.API_BASE + '/employee_available_points_operations.json?page=' + page;
    if (employeeId) url += ('&id=' + employeeId);
    return this.callApi(url)
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getCourse(document_id) {
    return this.callApi(Config.PORTAL_API_BASE + '/ezzely/api/course/' + document_id + '.json', { authorization: this.getPortalAuthorizationHeader() })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  requestCourse(course_id, data = {}) {
    let _data = data;
    _data.first_name = this.userData.profile.first_name;
    _data.last_name = this.userData.profile.last_name;
    return this.callApi(Config.PORTAL_API_BASE + '/ezzely/api/request_document/' + course_id + '.json',
      { authorization: this.getPortalAuthorizationHeader() }, 'post', _data)
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getQuickDocuments() {
    return this.callApi(Config.API_BASE + '/quick_documents.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  removeQuickDocument(documentId) {
    return this.callApi(Config.API_BASE + '/quick_documents/' + documentId + '.json', {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error);
        return { success: false };
      });
  }

  removeCoggnoCourse(courseId) {
    return this.callApi(Config.PORTAL_API_BASE + '/ezzely/api/remove_course/' + courseId + '.json',
      { authorization: this.getPortalAuthorizationHeader() }, 'post')
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  addCoggnoCourse(docId) {
    return this.callApi(Config.PORTAL_API_BASE + '/ezzely/api/add_course/' + docId + '.json',
      { authorization: this.getPortalAuthorizationHeader() }, 'post')
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getColleagues() {
    return this.callApi(Config.API_BASE + '/employees/colleagues.json')
      .then((employees) => employees.map((employee) => (new Employee(employee))))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getConversationColleagues() {
    return this.callApi(Config.API_BASE + '/employees/conversation_colleagues.json')
      .then((employees) => employees.map((employee) => (new Employee(employee))))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getCompanyLeaders() {
    return this.callApi(Config.API_BASE + '/employees/company_leaders.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getEmployees(full = false) {
    return this.callApi(Config.API_BASE + '/employees' + (full ? '/list' : '') + '.json')
      .then((employees) => employees.map((employee) => (new Employee(employee))))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getPaginatedEmployees(page, search) {
    return this.callApi(Config.API_BASE + '/employees/paginated_list.json?page=' + page + '&search=' + search)
      .then((data) => {
        let paginated = { totalPages: data.total_pages, totalCount: data.total_count };
        paginated.employees = data.employees.map((employee) => (new Employee(employee)));
        return paginated;
      })
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getDeletedEmployees(page, search) {
    return this.callApi(Config.API_BASE + '/employees/destroyed_users.json?page=' + page + '&search=' + search)
      .then((data) => {
        let paginated = { totalPages: data.total_pages, totalCount: data.total_count };
        paginated.employees = data.employees.map((employee) => (new Employee(employee)));
        return paginated;
      })
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getDepartmentEmployees(departmentId) {
    return this.callApi(Config.API_BASE + '/employees/list_for_department.json?department_id=' + departmentId)
      .then((employees) => employees.map((employee) => (new Employee(employee))))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getEmployeesWithAdmin() {
    return this.callApi(Config.API_BASE + '/employees/list_with_admin.json')
      .then((employees) => employees.map((employee) => (new Employee(employee))))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getEmployee(employeeId, full = false) {
    return this.callApi(Config.API_BASE + '/employees/' + employeeId + (full ? '/details' : '') + '.json')
      .then((employee) => (new Employee(employee)))
      .catch((error) => {
        this.handleApiError(error);
        return new Employee();
      });
  }

  getUnreadNotificationsInfo() {
    return this.callApi(Config.API_BASE + '/employees/get_unread_notifications.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  updateEmployee(employeeId, data) {
    return this.callApi(Config.API_BASE + '/employees/' + employeeId + '.json', {}, 'PUT', { employee: data })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  hideEmployeeProfileWidget() {
    return this.callApi(Config.API_BASE + '/employees/' + this.currentUser().id + '/hide_profile_widget.json', {}, 'PUT')
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  resendWelcomeEmail(id) {
    return this.callApi(Config.API_BASE + '/employees/' + id + '/resend_welcome_email.json')
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  updateEmployeeRole(employeeId, data) {
    return this.callApi(Config.API_BASE + '/employees/' + employeeId + '/update_role.json', {}, 'PUT', { employee: data })
      .then((employee) => (new Employee(employee)))
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getUniversity() {
    return this.callApi(Config.API_BASE + '/university.json')
      .then((university) => (new University(university)))
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getUniversityPointsRatio() {
    return this.callApi(Config.API_BASE + '/allocation_settings/get_points_ratio.json')
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getUniversityPointsInfo() {
    return this.callApi(Config.API_BASE + '/allocation_settings/get_points_info.json')
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getUniversityAllocationSettings() {
    return this.callApi(Config.API_BASE + '/allocation_settings.json')
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  updateUniversityAllocationSettings(data) {
    return this.callApi(Config.API_BASE + '/allocation_settings/update_settings.json', {}, 'PUT', { allocation_settings: data })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  updateUniversity(data) {
    return this.callApi(Config.API_BASE + '/university.json', {}, 'PUT', { university: data })
      .then((university) => (new University(university)))
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getEmployeeDepartments(employeeId) {
    return this.callApi(Config.API_BASE + '/employees/' + employeeId + '/get_departments.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getEmployeeDepartment() {
    return this.callApi(Config.API_BASE + '/employees/get_department.json')
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getEmployeeDepartmentsList() {
    return this.callApi(Config.API_BASE + '/employees/get_departments_list.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getActivityDepartmentsList() {
    return this.callApi(Config.API_BASE + '/employees/get_activity_departments_list.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getEmployeeUniversityDepartments(field) {
    return this.callApi(Config.API_BASE + '/university_departments/accessible.json?field=' + field)
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getEmployeePositions() {
    return this.callApi(Config.API_BASE + '/employees/get_positions.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getUserRecords(email) {
    return this.callApi(Config.PORTAL_API_BASE + '/ezzely/api/user_records.json?email=' + email, { authorization: this.getPortalAuthorizationHeader() })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  removeAttachment(attachment) {
    return this.callApi(this.attachmentsUrl(attachment.employee_id) + '/' + attachment.id + '.json', {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error);
        return { success: false };
      });
  }

  updateAttachment(attachment, attachmentData) {
    return this.callApi(this.attachmentsUrl(attachment.employee_id) + '/' + attachment.id + '.json', {}, 'PUT', { attachment: attachmentData })
      .catch((error) => {
        this.handleApiError(error);
        return { success: false };
      });
  }

  removeNote(note) {
    return this.callApi(this.notesUrl(note.employee_id) + '/' + note.id + '.json', {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error);
        return { success: false };
      });
  }

  removeActivityPostAttachment(attachmentId, mediaType) {
    return this.callApi(Config.API_BASE + '/activity_post_attachments/' + attachmentId + '.json?media_type=' + mediaType, {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error);
        return { success: false };
      });
  }

  getActivityPostVideoPresignedUrl(fileName) {
    return this.callApi(Config.API_BASE + '/activity_post_attachments/get_video_presigned_url.json?file_name=' + fileName)
      .catch((error) => {
        this.handleApiError(error);
        return { success: false };
      });
  }

  downloadAttachment(attachment) {
    return this.callApi(attachment.url)
      .then((resp) => (window.ezzely_download(resp.s3_url)))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  downloadQuickDocument(document) {
    return this.callApi(document.url)
      .then((resp) => (window.ezzely_download(resp.s3_url)))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  showRaffleRules() {
    window.open(Config.API_BASE + '/pages/raffle_terms');
  }

  getAttachmentCategories() {
    return this.callApi(Config.API_BASE + '/attachment_categories.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getQuickCategories() {
    return this.callApi(Config.API_BASE + '/quick_categories.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  createEmployee(employee_data) {
    return this.callApi(Config.API_BASE + '/employees.json', {}, 'POST', { employee: employee_data })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  inviteEmployee(employee_data) {
    return this.callApi(Config.API_BASE + '/employees/invite.json', {}, 'POST', { employee: employee_data })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getEmbeddedBrands(points, search) {
    let url = Config.API_BASE + '/gifts/brands.json?points=' + points;
    if (search) url += ('&search=' + search);
    return this.callApi(url)
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  createGiftCampaign(gift_data) {
    return this.callApi(Config.API_BASE + '/gifts.json', {}, 'POST', gift_data)
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  createEmbeddedGift(gift_data) {
    return this.callApi(Config.API_BASE + '/gifts/create_embedded_gift.json', {}, 'POST', gift_data)
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getRaffleGiftCard(cardId) {
    return this.callApi(Config.API_BASE + '/gifts/raffle_gift_card?uuid=' + cardId)
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  createStoredReport(report_data, report_type) {
    return this.callApi(Config.API_BASE + '/stored_reports.json', {}, 'POST', { report: report_data, report_type: report_type })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  terminateEmployee(employeeId) {
    return this.callApi(Config.API_BASE + '/employees/' + employeeId + '/terminate.json', {}, 'POST')
      .then((employee) => (new Employee(employee)))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  reActivateEmployee(employeeId) {
    return this.callApi(Config.API_BASE + '/employees/' + employeeId + '/re_activate.json', {}, 'POST')
      .then((employee) => (new Employee(employee)))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  removeEmployee(employeeId) {
    return this.callApi(Config.API_BASE + '/employees/' + employeeId + '.json', {}, 'DELETE')
      .then((employee) => {
        if (employee.id === this.userData?.profile?.id && employee.deleted_at) {
          this.logout();
          window.location.replace('/#/logout');
        }
        return new Employee(employee);
      })
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  restoreEmployee(employeeId) {
    return this.callApi(Config.API_BASE + '/employees/' + employeeId + '/revert_user.json', {}, 'PUT')
      .then((employee) => (new Employee(employee)))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  createNote(note_data) {
    return this.callApi(this.notesUrl(note_data.employee_id) + '.json', {}, 'POST', { note: note_data })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  createSurvey(survey_data) {
    return this.callApi(Config.API_BASE + '/surveys.json', {}, 'POST', { survey: survey_data })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  removeSurvey(surveyId) {
    return this.callApi(Config.API_BASE + '/surveys/' + surveyId + '.json', {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getSurvey(surveyId) {
    return this.callApi(Config.API_BASE + '/surveys/' + surveyId + '.json')
      .then((survey_data) => (new Survey(survey_data)))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getSurveyReportData(surveyId, reportType) {
    return this.callApi(Config.API_BASE + '/surveys/' + surveyId + '/build_report_data.json', {}, 'POST', { report_type: reportType })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getSurveyReportDataForPeriod(reportType) {
    return this.callApi(Config.API_BASE + '/surveys/build_report_data_for_period.json', {}, 'POST', { report_type: reportType })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getTerminationSurvey(surveyInstanceId) {
    return this.callApi(Config.API_BASE + '/termination_survey/' + surveyInstanceId + '.json')
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  submitTerminationSurvey(surveyInstanceId, answers_data) {
    return this.callApi(Config.API_BASE + '/termination_survey/' + surveyInstanceId + '.json', {}, 'POST', { answers: answers_data })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  createSurveyAnswers(survey_instance_id, survey_answers_data) {
    return this.callApi(Config.API_BASE + '/survey_instances/' + survey_instance_id + '/create_answers.json', {}, 'POST', { survey_answers: survey_answers_data })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  updateSurveyInstanceStartDate(survey_instance_id) {
    return this.callApi(Config.API_BASE + '/survey_instances/' + survey_instance_id + '/update_start_date.json', {}, 'POST')
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  updateEmployeePhoto(employee_id, photo_url) {
    return this.callApi(Config.API_BASE + '/employees/' + employee_id + '/update_photo.json', {}, 'POST', { photo_url: photo_url })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getActivityPosts(page, employeeId, departmentId) {
    return this.callApi(this.activityPostsUrl(page, employeeId, departmentId))
      .then((posts) => posts.map((post) => (new ActivityPost(post))))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getActivityPostDraft() {
    return this.callApi(Config.API_BASE + '/activity_posts/get_draft.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getActivityPost(postId) {
    return this.callApi(this.activityPostUrl(postId))
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  updateActivityPostLikes(post_id) {
    return this.callApi(Config.API_BASE + '/activity_post_likes.json', {}, 'POST', { post_id: post_id })
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  updateActivityPostCommentLikes(comment_id) {
    return this.callApi(Config.API_BASE + '/activity_comment_likes.json', {}, 'POST', { comment_id: comment_id })
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  updateQuickDocumentPhoto(document_id, photo_url) {
    return this.callApi(Config.API_BASE + '/quick_documents/' + document_id + '/update_photo.json', {}, 'POST', { photo_url: photo_url })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getSurveys() {
    return this.callApi(Config.API_BASE + '/surveys')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getSurveyCategories() {
    return this.callApi(Config.API_BASE + '/survey_categories')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  createSurveyCategory(categoryData) {
    return client.callApi(Config.API_BASE + '/survey_categories.json', {}, 'POST', { survey_category: categoryData })
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });
  };

  removeSurveyCategory(category) {
    return this.callApi(Config.API_BASE + '/survey_categories/' + category.id + '.json', {}, 'DELETE')
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getTasks() {
    return this.callApi(Config.API_BASE + '/tasks.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getCities(state) {
    return this.callApi(Config.API_BASE + '/battuta/' + state)
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getCountries() {
    return this.callApi(Config.API_BASE + '/countries.json')
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  getEvents(city, coordinates) {
    let stubhub_api = Config.API_BASE + '/stubhub?';
    if (coordinates && coordinates.latitude && coordinates.longitude) {
      stubhub_api += ('point=' + coordinates.latitude + ',' + coordinates.longitude);
    } else {
      stubhub_api += ('city=' + city);
    }
    return this.callApi(stubhub_api)
      .catch((error) => {
        this.handleApiError(error);
        return [];
      });
  }

  updateTask(taskId, data) {
    return this.callApi(Config.API_BASE + '/tasks/' + taskId + '/complete.json', {}, 'PUT', { task: data })
      .catch((error) => {
        this.handleApiError(error);
        return {};
      });
  }

  getAuthorizationHeader() {
    return this.userData === null ? '' : ('Bearer ' + this.userData.auth.token);
  }

  getPortalAuthorizationHeader() {
    return this.userData === null ? '' : ('Bearer ' + this.userData.auth.portal_token);
  }

  startDocumentUrl(doc) {
    //return (Config.PORTAL_API_BASE + '/ezzely/sp/saml_login?tk=' + this.userData.auth.token + '&uid=' + doc.coggno_document_id);
    return (Config.PORTAL_API_BASE + '/ezzely/training/start?tk=' + this.userData.auth.portal_token + '&uid=' + doc.coggno_document_id);
  }

  downloadCertificateUrl(certificate) {
    return (Config.PORTAL_API_BASE + '/ezzely/training/download_certificate?tk=' + this.userData.auth.portal_token + '&uid=' + certificate.id);
  }

  importEmployeesUrl(send_notification, change_password) {
    const notify = send_notification ? 1 : 0
    const change_pswd = change_password ? 1 : 0
    let url = Config.API_BASE + '/employees/import?notify=' + notify + '&change_pswd=' + change_pswd;
    return url;
  }

  importVerifiedCredentialsUrl() {
    return Config.API_BASE + '/verified_credentials/import'
  }

  attachmentsUrl(employee_id) {
    return Config.API_BASE + '/employees/' + employee_id + '/attachments';
  }

  notesUrl(employee_id) {
    return Config.API_BASE + '/employees/' + employee_id + '/notes';
  }

  quickDocumentsUrl() {
    return Config.API_BASE + '/quick_documents';
  }

  activityPostsUrl(page, employeeId, departmentId) {
    return Config.API_BASE + '/activity_posts.json?page=' + page + '&employee_id=' + employeeId + '&department_id=' + departmentId;
  }

  activityPostUrl(postId) {
    return Config.API_BASE + '/activity_posts/' + postId + '.json';
  }

  checkStatus(response) {
    if ((response.status >= 200 && response.status < 300) || response.status === 422) {
      return response;
    } else {
      const error = new Error(`HTTP Error ${response.statusText}`);
      error.status = response.statusText;
      error.response = response;
      console.log(error.response);
      throw error;
    }
  }

  handleApiError(error) {
    console.log('handle error:' + error.message);
    if (error.response) {
      error.response.json().then((data) => {
        if (data) {
          if (data.error === 'client out-of-date') {
            swal({
              title: "Application is out-of-date",
              text: "<p>" + data.message + ". Please click the button to update application on your device.</p><br/><a href='https://ezzely.com' class='btn btn-primary text-center'>Update</a> ",
              type: "error",
              html: true,
              showCancelButton: false,
              showConfirmButton: false
            }
            );
          } else if (data.error === 'Subscription expired') {
            const txt = this.ownerLoggedIn() ? '<a target="_blank" href="https://prod.ezzely.com/backend">Please update your subscription</a>.' : 'Please contact you administrator.'
            swal({
              title: "Company subscription is expired.",
              text: txt,
              type: "error",
              html: true,
              showCancelButton: false,
              showConfirmButton: true
            }
            );
          } else if (data.error === 'Account locked') {
            swal({
              title: "Company account is locked.",
              text: "Please contact Ezzely Support.",
              type: "error",
              html: true,
              showCancelButton: false,
              showConfirmButton: true
            }
            );
          } else if (data.error == 'Not Authorized') {
            const txt = '<b><a href="#/logout" onclick="swal.close()">log in</a></b>'
            swal({
              title: "Session is expired.",
              text: 'Please ' + txt + ' again.',
              type: "warning",
              html: true,
              showCancelButton: false,
              showConfirmButton: false
            }
            );

            this.logout();
          }
        }
      });
    }
  }


  parseJson(response) {
    return response.json();
  }

}

export const client = new Client();
