import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Survey from '../models/Survey';
import FeedbackQuestion from '../components/form_survey/FeedbackQuestion';
import ChoiceQuestion from '../components/form_survey/ChoiceQuestion';
import ChoicesQuestion from '../components/form_survey/ChoicesQuestion';
import { toastrNotification } from './../helpers/Toastr';
import moment from 'moment';

import '../components/form_survey/TerminationSurvey.css';
import { client } from '../components/Client';

class TerminationSurveyView extends Component {
  
    state = {
      submitted: false,
      survey: new Survey({categories: []}),
      answers: {},      
      spinnerClass: 'sk-loading'
    }
    
    componentDidMount() {
      this.setState(this.state);
      this.getSurvey(this.props.match.params.id);
    }

    getSurvey = (surveyInstanceId) => {
      const answers = {};
      client.getTerminationSurvey(surveyInstanceId).then((survey) => {      
        survey.categories.map((category) => {            
          category.questions.map((question) => {
            answers['q-' + question.id] = question.answer;
          })
        });
        this.setState({ survey: survey, answers: answers});
      });
    }

    updateAnswer = (question, choice) => {
      const answers = this.state.answers;
      answers['q-' + question.id] = choice;
      this.setState({ answers: answers });
    }

    submit = (evt) => {
      evt.preventDefault();
      let _this = this;
      this.setState({submitted: true});
      let qs = [];
      this.state.survey.categories.map((category) => {
        qs = qs.concat(category.questions)
      });
      if(!qs.some(q => _this.invalidAnswer(q))) {
        client.submitTerminationSurvey(this.props.match.params.id, this.state.answers).then((response) => {
          if(response.success) {
            toastrNotification({success: true, title: 'Answers were updated'})
          } else {
            toastrNotification({success: false, title: 'Unable to update the answers'})
          }          
        })
      } else {
        toastrNotification({success: false, title: 'Please answer all required questions'})
      }
    }
    
    renderQuestion = (question, answer) => {
      switch(question.qtype) {
        case 'choice':
          return (
            <ChoiceQuestion question={question} answer={answer} ref={'question'+question.id} onUpdateAnswer={this.updateAnswer}/>
          );
          break; 
        case 'choices':
          return (
            <ChoicesQuestion question={question} answer={answer} ref={'question'+question.id} onUpdateAnswer={this.updateAnswer}/>
          );
          break; 
        case 'feedback':
          return (
            <FeedbackQuestion question={question} answer={answer} ref={'question'+question.id} onUpdateAnswer={this.updateAnswer}/>
          );
          break;
        default:
          break;  
      } 
    }
    
    invalidAnswer = (question) => {
      const ans = this.state.answers['q-'+question.id];
      return !question.optional && (ans === null || ans === '');
    }
    
    questionItemClassName = (question) => {
      let klass = 'question-row';
      if(this.state.submitted && this.invalidAnswer(question)) klass += ' invalid';
      return klass;
    }
        
    render() {
        return (
          <div className='row'>          
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h2>Termination Survey for {this.state.survey.employee_name}</h2>
                </div>
                <div className="ibox-content">
                  <div className="row">
                    <div className="col-sm-12">
                      <form>
                        {
                          this.state.survey.categories.map((category) => (
                            <div key={'cat' + category.id}>
                              <br/><h2 className='text-center'>{category.name}</h2><br/>
                              {
                                category.questions.map((question) => (
                                  <div key={'q' + question.id} className={this.questionItemClassName(question)}> 
                                    {
                                      this.renderQuestion(question, this.state.answers['q-' + question.id])
                                    }    
                                  </div>                              
                                ))
                              }
                            </div>
                          ))
                        }
                        <div>
                          <br/>
                          <button className="btn btn-sm btn-primary pull-right m-t-n-xs" type="submit" onClick={this.submit}><strong>Submit</strong></button>                                      
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default TerminationSurveyView;
