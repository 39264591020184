// @flow
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { client } from '../Client';
import ActivityTextBox from './ActivityPostForm/ActivityTextBox';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';

class ActivityPostCommentNew extends Component {
  
  canSubmit = () => {
    return true//return (this.refs.editor.getContent().length >= this.state.minTextLength);
  }
      
  sendComment() {
    const content = this.refs.editor.getContent(); 
    const parentCommentId = this.props.comment ? this.props.comment.id : null;
    this.props.channel.perform('create_activity_post_comment', {post_id: this.props.post.id, comment_id: parentCommentId, message: content});
    this.refs.editor.clear();
    this.props.onHideCommentBox();
  }
    
  renderCommentFormErrors = () => {
    if (this.props.post.newCommentErrors && this.props.post.newCommentErrors.length > 0) {
      return (
        <div style={{color: '#ed5565'}} className="pull-left">  
          <ul>
            { 
              this.props.post.newCommentErrors.map((errorMessage, index) => (
                <li key={'commentError'+index}>{errorMessage}</li>
              ))  
            }
          </ul>
        </div>          
      )
    }  
  }
  
  render() {
      return (
        <div className="social-comment">
            <a href="" className="pull-left">
              <img alt={client.currentUser().full_name()} src={client.currentUser().avatar_image_url()} className="avatar-image img-circle"/>
            </a>
            <div className="pull-right" style={{marginLeft: '10px'}}>
            {this.renderCommentFormErrors()}
            <button disabled={!this.canSubmit()} className="btn btn-white pull-right" style={{borderRadius:'0.75rem'}} onClick={() => this.sendComment()}>
              <FontAwesomeIcon icon={faPaperPlane} size="lg"/>
            </button>
            </div> 
            <div className="media-body editor comment-editor">
              <ActivityTextBox               
                ref="editor"
                placeholder="Write a comment"
              />
            </div>
        </div>
      )
    }

}

export default ActivityPostCommentNew;
