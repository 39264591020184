import React, { Component } from 'react';

class FeedbackQuestion extends Component {
  
  handleChange = (event) => {
    this.props.onUpdateAnswer(this.props.question, event.target.value);
  }   
  
  render() {
       
    return (
      <div className="row">
        <div>
          <div className="col-md-4" ><h4>{this.props.question.question}</h4></div>
          <div className="col-md-8">
            <div>
              <textarea
                style={{width: '100%'}}
                rows={2}
                placeholder="Type your answer here..."
                onChange={(e) => {this.handleChange(e)}}
                value={this.props.answer || ''}
              />
            </div>
          </div>    
        </div>
      </div>  
    );
  }
}

export default FeedbackQuestion;
