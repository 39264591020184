import Config from 'config';
import { ageToHuman } from '../helpers/Humanizer';
import moment from 'moment';

class Employee {
  constructor(obj) {
    this.attachments = [];
    this.categories = [];
    this.notes = [];
    this.visible = true;
    for (var prop in obj) {
      if ((prop === 'date_of_birth' || prop === 'employment_date') && obj[prop]) {
        this[prop] = moment.parseZone(obj[prop]).toDate();
      } else {
        this[prop] = obj[prop];
      }
    }
  }

  full_name() {
    return this.first_name + ' ' + this.last_name;
  }

  full_position() {
    return [this.position, this.department].filter(function(e){return e}).join(" in ");
  }

  age_and_full_position() {
    return [this.age(), this.position, this.department].filter(function(e){return e}).join(", ");
  }

  hideByName(searchExpr) {
    this.visible = (this.full_name().match(searchExpr) !== null);
    return this;
  }

  avatar_image_url() {
    const url = (this.avatar_url) ? this.avatar_url : 'img/avatar_missing.png';
    return url;
  }

  link() {
    return `#/employee/${this.id}`
  }

  age() {
    let _age = '';
    if (this.date_of_birth !== null) {
      _age = (Date.now() - Date.parse(this.date_of_birth)) / 31536000000;
    }
    return (ageToHuman(_age) === null ? "" :  ageToHuman(_age) + " old");
  }

  work_experience() {
    let _we = '';
    if (this.employment_date !== null) {
      _we = (Date.now() - Date.parse(this.employment_date)) / 31536000000;
    }
    return ageToHuman(_we) || 'just hired';
  }

  terminated() {
    return this.status === 1;
  }
  
  deleted() {
    return !!this.deleted_at;
  }
  
  email_value() {
    return this.email ? this.email : ""
  }
  
  online_status() {
    if (this.is_online) {
      return ''; //online
    } else if(this.online_at !== null) {
      const t = moment.utc(this.online_at);
      if(t.isAfter(moment().subtract(7, 'days'))) {
        return t.fromNow();
      } else if(t.isAfter(moment().subtract(1, 'year'))) {
          return t.format('MMM, D');        
        } else {
          return 'more than year ago';
        }
    } else {
      // offline, unknown when was online
      return '';
    }
  }

}

export default Employee;
