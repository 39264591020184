import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt'

class GroupsList extends Component {

  handleGroupSelect = () => {}
  
  statusLabel = (group) => {
    switch (group.is_private) 
       {
           case false: return (<span className="label label-success">Public</span>);
           case true: return (<span className="label label-danger">Private</span>);
       }        
  }
        
  render() {
    const {groups} = this.props
    
    return (
      <div className="clients-list">
          <div className="full-height-scroll">
              <div className="">
                  <table className="table table-striped table-hover mobile-table">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Admin</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                         groups.map((group) => ( 
                           <tr key={'group'+group.id} 
                               onClick={() => this.handleGroupSelect(group)}
                               className={group.visible ? '' : 'hidden'}
                           >
                             <td data-label="Status:">{this.statusLabel(group)}</td>
                             <td data-label="Name:">
                              { group.current_user_is_member 
                                ? <Link to={`/group/${group.id}`}>{group.title}</Link>
                                : <span>{group.title}</span>
                              }  
                             </td>
                             <td data-label="Description:">{group.description}</td>
                             <td data-label="Admin:">{group.creator.full_name}</td>
                             <td className="actions">
                                <div>
                                  { group.current_user_is_candidate && !group.default &&
                                    <span className="pull-right">Requested</span>
                                  }
                                  { !group.current_user_is_member && !group.current_user_is_candidate && !group.default &&
                                    <button type="button" className='btn btn-primary btn-sm pull-right' onClick={() => this.props.onJoinGroup(group)}><FontAwesomeIcon icon={faSignInAlt} /> Join</button>
                                  }
                                  { group.current_user_is_member &&
                                    <Link to={`/group/${group.id}`} className="btn btn-white btn-sm pull-right"><FontAwesomeIcon icon={faEye}/> Detail</Link>
                                  }
                                </div>
                             </td>
                           </tr>
                         ))
                      }                                  
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
    )
  }

}

export default GroupsList;
