import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons/faFolder';
import { toastrNotification } from '../../helpers/Toastr';
import SweetAlert from 'sweetalert-react';
import SurveyQuestion from './SurveyQuestion';

import "./QuestionsSelectionStep.css";

class QuestionsSelectionStep extends Component {

  state = {
    selectedQuestions:[],
    checkboxes: {},
    categories: [],
    showDeleteCategoryDialog: false
  }
    
  componentDidMount() {
    this.setState(this.props.getSurveyData());
    this.props.updateSurveyData({ isInitialStep: true });
  } 
  
  handleQuestionChange = (question, evt) => {   
    let nextSelectedQuestions = [];
    let checkboxes = this.props.checkboxes;
    let selectedQuestions = this.props.selectedQuestions;
    //const checkbox_state = !this.state.checkboxes['q'+question.id];
    const checkbox_state = !this.props.checkboxes['q'+question.id];
    if (checkbox_state) { // add to selected questions
      nextSelectedQuestions = selectedQuestions.concat(question)
    } else { // remove from selected questions
      nextSelectedQuestions = selectedQuestions.filter(q => q.id !== question.id)
    }
    const chks = Object.assign({}, checkboxes, {['q'+question.id]: checkbox_state });
    //this.setState({checkboxes: chks, selectedQuestions: nextSelectedQuestions});
    this.props.updateSurveyData({checkboxes: chks, selectedQuestions: nextSelectedQuestions});
  } 
      
  filterByCategory = (category) => {
    const cats = this.props.categories.map((c) => {
      c.hidden = (c.id !== category.id && category.id !== -1);
      return c;
    })
    this.setState({ categories: cats });
    this.props.updateSurveyData({selectedCategory: category.id});
  } 
  
  isValidated() {
    let stepIsValid = false;
    if (this.props.selectedQuestions.length === 0) {
      toastrNotification({success: false, title: 'Please select one question at least'});   
    } else {
      stepIsValid = true;
    }
    return stepIsValid;
  } 
  
  handleRemoveCategoryLinkClick = () => {
    this.setState({ showDeleteCategoryDialog: true });
  }

  handleDeleteSurveyCategory = (category) => {
    this.props.onDeleteSurveyCategory(category);
    this.setState({ showDeleteCategoryDialog: false });  
  }     
    
  render() {        
        return (
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-5">
                <div className="ibox float-e-margins">
                  <div className="ibox-content">
                    <div className="file-manager">
                      <h5>Categories</h5>
                      <ul className="folder-list" style={{padding: 0}}>
                        <li key={'cat-1'} className={this.props.selectedCategory === -1 ? 'gray-bg' : ''}>
                          <a onClick={(evt) => this.filterByCategory({id: -1})}>
                            <FontAwesomeIcon icon={faFolder} /> All Categories
                          </a>
                        </li>
                        {
                          this.props.categories.map((category) => (
                            <li key={'cat' + category.id} className={this.props.selectedCategory === category.id ? 'gray-bg' : ''}>
                              <div style={{display: "inline-block"}}>
                                <a onClick={(evt) => this.filterByCategory(category)}>
                                  <FontAwesomeIcon icon={faFolder} /> {category.name}
                                </a>
                              </div>  
                              { category.can_be_deleted && 
                                <div className="pull-right" style={{display: "inline-block"}}>
                                  <a className="text-danger" onClick={() => this.handleRemoveCategoryLinkClick()}>&times;</a>
                                  <SweetAlert
                                    show={this.state.showDeleteCategoryDialog}
                                    type="warning"
                                    title="Are you sure to remove the category and all belonged questions?"
                                    confirmButtonColor="#DD6B55"
                                    showCancelButton
                                    text="This operation can not be undone."
                                    onConfirm={() => this.handleDeleteSurveyCategory(category)}
                                    onCancel={() => this.setState({ showDeleteCategoryDialog: false })}
                                  />
                                </div>
                              }  
                            </li>
                          ))
                        }                                  
                      </ul>

                      <h5 className="tag-title">Selected Questions</h5>
                      <ul>   
                        {
                          this.props.selectedQuestions.map((question) => (
                            <li key={'sq' + question.id}>{question.question}</li>                      
                          ))
                        }             
                      </ul>

                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-sm-7 animated fadeInRight">
                    {
                      this.props.categories.filter(q => !q.hidden).map((category) => ( 
                      category.questions.map((question) => (           
                        <SurveyQuestion 
                          key={'csq' + category.id + '-' + question.id}
                          question={question}
                          categories={this.props.categories}
                          handleQuestionChange={this.handleQuestionChange}
                          checkboxes={this.props.checkboxes}
                          deleteSurveyQuestion={this.props.onDeleteSurveyQuestion}
                          reloadSurvey={this.props.reloadSurvey}
                        />                  
                        ))
                      ))
                    }
              </div>
            </div>        
        )
    }
}

export default QuestionsSelectionStep
