import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { mediumDateFormat } from './../helpers/Formatter';
import Survey from '../models/Survey';
import SurveyView from '../components/survey/SurveyView';
import { client } from '../components/Client';
import ENPSReport from '../components/survey_reports/ENPSReport';
import ENPSReportForPeriod from '../components/survey_reports/ENPSReportForPeriod';
import KeyMetricsReport from '../components/survey_reports/KeyMetricsReport';
import EngagementReportForPeriod from '../components/survey_reports/EngagementReportForPeriod';
import ParticipationReport from '../components/survey_reports/ParticipationReport';
import ParticipationReportForPeriod from '../components/survey_reports/ParticipationReportForPeriod';
import GroupsPopover from '../components/groups/GroupsPopover';
import { toastrNotification } from '../helpers/Toastr';

import '../components/survey/SurveyBuilder.css'; 

class SurveyBuilderView extends Component {

  state = {
    surveys: []
  };

  componentDidMount() {
    this.getSurveys();
  }

  getSurveys = () => {
    client.getSurveys().
    then((surveys) => surveys.map((s) => (new Survey(s)))).
    then((surveys) => {
      this.setState({ surveys: surveys })
    });
  }
  
  generateAnswersReport = (survey) => {
    client.createStoredReport({survey_id: survey.id}, 'SurveyAnswersStoredReport').then((resp) => {
      if (resp.success) {        
        window.ezzely_download(resp.s3_url);
        toastrNotification({success: true, message: ('The report was successfully generated') })
      } else {
        toastrNotification({success: false, message: ('Unable to generate report: ' + resp.message) })
      }
    })
  }  

  render() {
    return (
      <div className="wrapper wrapper-content">
        <div className="row">
          <div className="col-lg-12">
              <div className="ibox">
                  <div className="ibox-title">
                      <h5>Surveys</h5>
                      <div className="ibox-tools">
                        {client.adminLoggedIn() && 
                        <div className="btn-group" style={{marginRight: '5px'}}>
                          <button data-toggle="dropdown" className="btn-white btn-sm dropdown-toggle" aria-expanded="false">Reports <span className="caret"></span></button>
                          <ul className="dropdown-menu">
                            <ENPSReportForPeriod />
                            <EngagementReportForPeriod />
                            <ParticipationReportForPeriod />
                          </ul>
                        </div> }
                        <Link to={'/new_survey'} className="btn btn-primary btn-sm">Create New Survey</Link>
                      </div>
                  </div>
                  <div className="ibox-content">
                      <div className="project-list">
                              {
                                 this.state.surveys.map((survey) => (
                              <div key={'sur' + survey.id} className="row survey-row">
                                  <div className="project-status col-sm-1">
                                      <span className={`label label-${survey.isActive() ? 'primary' : 'warning'}`}>{survey.getStatus()}</span>
                                  </div>
                                  <div className="col-sm-3">
                                      <Link to={`/surveys/${survey.id}`}>
                                        {survey.name}
                                      </Link>
                                      <br/>
                                      <small>Created {mediumDateFormat(survey.created_at)}</small>
                                  </div>
                                  <div className="col-xs-6 col-sm-2">
                                    <GroupsPopover id={'groups'+survey.id} groups={survey.departments} />
                                  </div>
                                  <div className="col-xs-6 col-sm-2">
                                    {mediumDateFormat(survey.start_time)} - {mediumDateFormat(survey.end_time)}
                                  </div>
                                  <div className="col-sm-2 project-completion">
                                          <small>{survey.completion_progress}% Employee Participation</small>
                                          <div className="progress progress-mini">
                                              <div style={{width: `${survey.completion_progress}%`}} className="progress-bar"></div>
                                          </div>
                                  </div>
                                  <div className="col-sm-2 project-actions">
                                    <div className="btn-group">
                                      <button data-toggle="dropdown" className="btn-white btn-sm dropdown-toggle" aria-expanded="false">Reports <span className="caret"></span></button>
                                      <ul className="dropdown-menu pull-right">
                                        <ENPSReport survey={survey}/>
                                        <KeyMetricsReport survey={survey}/>
                                        <ParticipationReport survey={survey}/>
                                        <li>
                                          <a onClick={() => this.generateAnswersReport(survey)}>
                                            <FontAwesomeIcon icon={faDownload}/> Answers Report
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <SurveyView survey={survey} employeeMode={false}/>
                                  </div>
                              </div>
                                ))
                              }
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default SurveyBuilderView
