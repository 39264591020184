import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';
import ShiftRequest from '../models/ShiftRequest';

class ShiftRequestClient {
  
  getShiftRequests() {
    return client.callApi(Config.API_BASE + '/shift_requests.json')
      .then((shift_requests) => shift_requests.map((shift_request) => (new ShiftRequest(shift_request))))
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  getAdminShiftRequests() {
    return client.callApi(Config.API_BASE + '/shift_requests/admin_list.json')
      .then((shift_requests) => shift_requests.map((shift_request) => (new ShiftRequest(shift_request))))
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  getActivityShiftRequests() {
    return client.callApi(Config.API_BASE + '/shift_requests/activity_shift_requests_list.json')
      .then((shift_requests) => shift_requests.map((shift_request) => (new ShiftRequest(shift_request))))
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  createShiftRequest(requestData) {
    return client.callApi(Config.API_BASE + '/shift_requests.json', {}, 'POST', {shift_request: requestData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  createShiftRequestApplicant(applicantData) {
    return client.callApi(Config.API_BASE + '/shift_request_applicants.json', {}, 'POST', {shift_request_applicant: applicantData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  } 
  
  getShiftRequest(shiftRequestId) {
    return client.callApi(Config.API_BASE + '/shift_requests/' + shiftRequestId + '.json')
      .then((shiftRequestData) => (new ShiftRequest(shiftRequestData)))
      .catch((error) => {
        client.handleApiError(error);
        return [] ;
      });        
  }
  
  approveApplicant(data, shiftRequestId) {
    return client.callApi(Config.API_BASE + '/shift_requests/' + shiftRequestId + '/approve_applicant.json', {}, 'PUT', {shift_request: data})
      .catch((error) => {
        client.handleApiError(error);
        return [] ;
      });        
  }
  
  deleteShiftRequest(shiftRequestItemId) {
    return client.callApi(Config.API_BASE + '/shift_requests/'+ shiftRequestItemId +'.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }    

}

export const shiftRequestClient = new ShiftRequestClient();
