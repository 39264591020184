import React, { Component } from 'react';
import CredentialsList from '../components/verified_credentials/CredentialsList';

class VerifiedCredentialsView extends Component {
    
    render() {
        return (
            <div className="wrapper wrapper-content animated fadeIn">
              <CredentialsList />
            </div>
        )
    }

}

export default VerifiedCredentialsView;
