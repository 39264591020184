// @flow
import React, { Component } from 'react';
import TransactionList from "../components/account/TransactionList";
import EmployeeBenefitReportForm from "../components/account/EmployeeBenefitReportForm";
import IBox from "../components/common/IBox";
import { currencyFormat } from './../helpers/Formatter';
import { client } from '../components/Client';
import { Button, Glyphicon, Tab, Tabs } from 'react-bootstrap';
import Config from 'config';
import RewardsTransactionsList from '../components/rewards/RewardsTransactionsList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import RewardTypeReportForm from '../components/reward_types/RewardTypeReportForm';
import PointsAllocationReportForm from '../components/points_allocation_settings/PointsAllocationReportForm';
import { withRouter } from 'react-router-dom';

class AccountBalanceView extends Component {

  state = {
    coggno_transactions: [],
    ezzely_transactions: [],
    coggno_balance: 0,
    ezzely_balance: 0,
    ezzely_transactions_page: 1,
    ezzely_transactions_total_pages: 0,
    currentTab: 'incentiveTransactions'
  };

  constructor(props) {
    super(props)
    const queryParams = new URLSearchParams(this.props.location.search)
    if (queryParams.has('tab')) this.state.currentTab = queryParams.get('tab')
    this.reportFormRef = React.createRef()
    this.pointsAllocationReportForm = React.createRef()
  }

  componentDidMount() {
    this.getCoggnoTransactions();
    this.getEzzelyTransactions();
  }

  getCoggnoTransactions = () => {
    client.getCoggnoTransactions().then((data) => {
      this.setState({ coggno_transactions: data.operations, coggno_balance: data.balance });
    });
  }

  getEzzelyTransactions = () => {
    client.getEzzelyTransactions(this.state.ezzely_transactions_page).then((data) => {
      this.setState({
        ezzely_transactions:
          data.operations,
        ezzely_balance: data.balance,
        company_points: data.points,
        allowpayments: data.allowpayments,
        ezzely_transactions_total_pages: data.total_pages || 1
      });
    });
  }

  handleEzzelyPageChange = (data) => {
    const page = data.selected + 1;
    this.setState({ ezzely_transactions_page: page }, () => {
      this.getEzzelyTransactions();
    });
  }


  render() {
    return (
      <div className="wrapper wrapper-content animated fadeIn">
        {this.state.allowpayments && this.state.ezzely_balance < 25.0 &&
          <div className="alert alert-warning"> Your rewards balance is below $25. Please <a className='alert-link' href={Config.API_BASE + '/backend/recurly/accounts'} target='_blank' >replenish your incentive deposit</a> to continue offering gift cards. User gift card requests will be denied if they exceed your balance.</div>
        }
        <div className="row">
          <div className="col-lg-10">
            <div className="ibox">
              <div className="ibox-title">
              </div>
              <div className="ibox-content" style={{ paddingTop: '0' }}>
                <Tabs id="account-balance-tabs" defaultActiveKey={this.state.currentTab}>
                  <Tab eventKey="incentiveTransactions" title="Incentive Transactions">
                    <TransactionList transactions={this.state.ezzely_transactions} pageCount={this.state.ezzely_transactions_total_pages} handlePageChange={this.handleEzzelyPageChange} />
                  </Tab>
                  {this.state.coggno_transactions.length > 0 &&
                    <Tab eventKey="trainingTransactions" title="Training Transactions">
                      <TransactionList transactions={this.state.coggno_transactions} />
                    </Tab>
                  }
                  <Tab eventKey="rewardTransactions" title="Rewards">
                    <RewardsTransactionsList />
                  </Tab>
                  <Tab eventKey="nonRedeemedRewardTransactions" title="Non Redeemed Rewards">
                    <RewardsTransactionsList nonRedeemed />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <IBox title="Training Balance">
              <span className={this.state.coggno_balance < 0 ? 'balance-negative' : 'balance-positive'}>{currencyFormat(this.state.coggno_balance)}</span>
            </IBox>
            <IBox title="Incentive Balance">
              <p><span className={this.state.ezzely_balance < 0 ? 'balance-negative' : 'balance-positive'}>{currencyFormat(this.state.ezzely_balance)}</span></p>
              <p><span>{this.state.company_points}</span><span>  points</span></p>
            </IBox>
            <IBox title="Reports">
              <EmployeeBenefitReportForm />
              <div style={{ paddingTop: '10px' }}>
                <a onClick={() => this.reportFormRef.current?.open()}><FontAwesomeIcon icon={faDownload} /> Employee Rewards</a>
                <RewardTypeReportForm ref={this.reportFormRef} />
              </div>
              <div style={{ paddingTop: '10px', display: 'block' }}>
                <a onClick={() => this.pointsAllocationReportForm.current?.open()}><FontAwesomeIcon icon={faDownload} /> Points Allocation</a>
                <PointsAllocationReportForm ref={this.pointsAllocationReportForm} />
              </div>
            </IBox>
          </div>
        </div>
      </div>
    )
  }

}

export default withRouter(AccountBalanceView)
