import React, { Component } from 'react';
import { client } from '../Client';
import Slider from "react-slick";
import Coin from "../common/Coin";

import './LeaderBoard.css'
import { disableSwipe } from '../../helpers/Gestures';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className="leader-slide-next"
      style={{display: className.includes('slick-disabled')? 'none':'flex'}}
      onClick={onClick}
    >
      <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M11.56 5.56L10.5 4.5 6 9l4.5 4.5 1.06-1.06L8.12 9z"></path></svg>
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className="leader-slide-prev"
      style={{display: className.includes('slick-disabled')? 'none':'flex'}}
      onClick={onClick}
    >
      <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M11.56 5.56L10.5 4.5 6 9l4.5 4.5 1.06-1.06L8.12 9z"></path></svg>
    </div>
  );
}

class LeaderBoard extends Component {
  constructor(props){
    super(props);
    this.divRef = React.createRef();
  }

  componentDidMount(){
    const mql = window.matchMedia('(max-width: 767px)');
    if(!mql.matches) return;
    const node = this.divRef.current;
    const stopPropagation = event => disableSwipe(); //event.stopPropagation();
    node.addEventListener('touchmove',stopPropagation,false);
    node.addEventListener('mousemove',stopPropagation,false);
  }

  render() {
    let settings = {
      dots: false,
      speed: 500,
      infinite: false,
      swipeToSlide: true,
      //arrows: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 330,
          settings: {
            slidesToShow: 2
          }
        }]
    }

    const leaders = this.props.leaders;

    return (
      <div ref={this.divRef}>
        {leaders && leaders.length > 0 &&
          <Slider {...settings}>
            {leaders.map((leader) => {
              return (
                <div key={'leader' + leader.id} className="activity-leader-board-item">
                  <img src={leader.avatar_url} className="img-circle" />
                  <div className="leader-points hidden-xs">
                    <strong>{leader.total_points}</strong>&nbsp;
                    <Coin width="16px" />
                  </div>
                  <div className="text-ellipsis hidden-xs"><small>{leader.full_name}</small></div>
                  <div className="leader-points hidden-sm hidden-md hidden-lg">
                    {leader.first_name} - <strong>{leader.total_points}</strong>&nbsp;<Coin width="16px" />
                  </div>
                </div>
              )
            }
            )}
          </Slider>
        }
      </div>
    )
  }
}

export default LeaderBoard;
