import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons/faPaperPlane';
import PhotoCropper from './PhotoCropper';
import UploadAttachment from './UploadAttachment';
import AddNoteForm from './AddNoteForm';
import { conversationClient } from '../../clients/ConversationClient';
import { companySettingsClient } from '../../clients/CompanySettingsClient';
import { client } from '../Client';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { toastrNotification } from '../../helpers/Toastr';


class EmployeeSummary extends Component {

  state = {
    conversationId: null,
    canCreateConversation: false
  }

  close = () => {
    this.setState({ showModal: false });
  }

  open = (employee) => {
    this.setState({ showModal: true, employee: employee });

    companySettingsClient.getAllowedEmployees("conversation")
      .then((response) => {
        if (response.employee_ids.includes(client.currentUser().id) &&
          response.employee_ids.includes(employee.id)) {
          this.setState({ canCreateConversation: true });
          this.createConversation(employee);
        }
      })
  }

  createConversation = (employee) => {
    conversationClient.createConversation(employee.id)
      .then((response) => {
        if (response.conversation_id) {
          this.setState({ conversationId: response.conversation_id })
        }
      })
  }

  handleAttachmentUpload = () => {
  }

  handleAddNote = () => {
  }

  handleResendEmail = (id) => {
    client.resendWelcomeEmail(id)
      .then(response => {
        toastrNotification({
          success: true,
          message: 'Welcome Email Sent ',
        })
      })
      .catch(err => {
        toastrNotification({
          success: false,
          message: 'Failed to send email',
        })
      });

  }

  render() {
    const employee = this.state.employee;

    return (
      <Modal show={this.state.showModal} onHide={this.close} >
        <Modal.Body>
          {this.state.employee &&
            <div className="row m-b-lg">
              <div className="col-lg-4 text-center">
                <div className="m-b-sm">
                  <img alt="image" className="img-circle" src={employee.avatar_image_url()}
                    style={{ width: "62px" }} />
                  <PhotoCropper employee={employee} onUpdateEmployeePhoto={this.props.onUpdateEmployeePhoto} />
                </div>
              </div>
              <div className="col-lg-8">
                <h3>{employee.full_name()}</h3>
                <p>
                  <strong>{employee.position}</strong><br />
                  {employee.department}
                </p>

                <ul className="list-group clear-list">
                  {employee.email_value().length > 0 &&
                    <li className="list-group-item">
                      <FontAwesomeIcon icon={faEnvelope} /> <a href={'mailto:' + employee.email_value()}>{employee.email_value()}</a>
                    </li>
                  }
                  {employee.work_phone &&
                    <li className="list-group-item">
                      <span className="pull-right label">work</span>
                      <FontAwesomeIcon icon={faPhone} /> {employee.work_phone}
                    </li>
                  }
                  {employee.cell_phone &&
                    <li className="list-group-item">
                      <span className="pull-right label">cell</span>
                      <FontAwesomeIcon icon={faPhone} /> {employee.cell_phone}
                    </li>
                  }
                  {employee.home_phone &&
                    <li className="list-group-item">
                      <span className="pull-right label">home</span>
                      <FontAwesomeIcon icon={faPhone} /> {employee.home_phone}
                    </li>
                  }
                </ul>
              </div>
              <div className="full-height-scroll">
                <div style={{ marginBottom: '5px' }} className="row">
                  <div className="col-sm-6">
                    <AddNoteForm employee={employee} onAddNote={this.handleAddNote} />
                  </div>
                  <div className="col-sm-6">
                    <UploadAttachment employee={employee} onFinishUpload={this.handleAttachmentUpload} />
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "5px" }}>
                  {this.state.canCreateConversation && this.state.conversationId &&
                    <div className="col-md-6">
                      <Link to={`/conversation/${this.state.conversationId}`}>
                        <button type="button" style={{ color: "#676A6C" }} className="btn btn-sm btn-block btn-white btn-outline">
                          <FontAwesomeIcon icon={faPaperPlane} /> Start conversation
                        </button>
                      </Link>
                    </div>
                  }
                  <div className={this.state.canCreateConversation ? 'col-md-6' : 'col-md-12'}>
                    <button type="button" onClick={() => this.handleResendEmail(employee.id)} className="btn btn-sm btn-block btn-white btn-outline">
                      <FontAwesomeIcon icon={faEnvelope} /> Welcome email
                    </button>
                  </div>
                </div>
                <div>
                  <Link to={`/employee/${employee.id}`}>
                    <button type="button" className="btn btn-primary btn-sm btn-block">
                      <FontAwesomeIcon icon={faArrowDown} /> View Details
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.close}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

}

export default EmployeeSummary;
