import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { client } from '../Client';


let reportChartData =  {
  labels: [],
  datasets: [
    {
      label: 'Employee Net Promoter Score',
      fill: false,
      lineTension: 0.1,
      backgroundColor: '#E95227',
      borderColor: '#E95227',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#E95227',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#E95227',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: []
    }
  ]
}

class ENPSReportForPeriod extends Component {
    
  state = {
    showModal: false,
    chartData: {},
    spinnerClass: 'sk-loading',
    avg_enps: 0,
    reportDataPresent: true
  };
  
  componentDidMount() {
    this.setState(this.state);
    //this.getSurveyReportData();
  }
  
  getSurveyReportData = () => {
    client.getSurveyReportDataForPeriod('enps_for_period').then((data) => {
      reportChartData.labels = data.labels;
      reportChartData.datasets[0].data = data.data;
      this.setState({ chartData: reportChartData, spinnerClass: '', avg_enps: data.avg_enps, reportDataPresent: data.data.length > 0 })
    });
  }
            
  close = () => {
    this.setState({ showModal: false });
    this.setState({ spinnerClass: 'sk-loading' })
  }

  open = () => {
    this.getSurveyReportData();
    this.setState({ showModal: true });
  }

  
 renderReport = () => {
   if (this.state.reportDataPresent) {
     return (
       <div className={'wrapper wrapper-content ' + this.state.spinnerClass}>
          <div className="sk-spinner sk-spinner-double-bounce">
            <div className="sk-double-bounce1"></div>
            <div className="sk-double-bounce2"></div>
          </div>
          <div className='row'>
            <div className="col-md-4">
              <div className={(this.state.avg_enps >= 10) ? 'widget lazur-bg p-lg text-center' : 'widget red-bg p-lg text-center'}>
                <h1>{this.state.avg_enps}</h1>
                <h3>{(this.state.avg_enps >= 10) ? 'Good' : 'Bad'}</h3>
                <small>Average ENPS value</small>
              </div> 
            </div>
            <div className="col-md-8">
              <Line data={this.state.chartData} />
            </div>
          </div>
       </div>
     )
   } else {
     return (
       <div className="text-center">
         <h1>There is no data for ENPS value calculation yet</h1>
       </div>
     )
   }
 }  
  
 render() {

    return (
      <li>
        <a onClick={this.open}>
          eNPS for period
        </a>
        <Modal bsSize="large" show={this.state.showModal} onHide={this.close}>
            <Modal.Header closeButton>
              <Modal.Title>Employee Net Promoter Score</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.renderReport()}
            </Modal.Body>  
            <Modal.Footer>  
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
        </Modal>
      </li>  
    );
  }
}

export default ENPSReportForPeriod;
