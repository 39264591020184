import React, { Component } from 'react';
import AcknowledgementsList from '../components/acknowledgements/AcknowledgementsList';
import { client } from '../components/Client.js';
import { companySettingsClient } from '../clients/CompanySettingsClient';

class AcknowledgementsView extends Component {
    
    state = {
      isAccessible: false
    }
  
    componentDidMount() {
      this.checkUserAccess()
    }
    
    checkUserAccess = () => {
      if ( client.adminLoggedIn() ) {
        this.setState({ isAccessible: true });
      } else {
        companySettingsClient.checkUserAccess("acknowledgement")
        .then((response) => {
          this.setState({ isAccessible: response.success });
          if ( !response.success ) {
            swal("Error!", "You dont have access to this page.", "error");
          }                             
        });
      }  
    }
    
    render() {
        return (
            <div className="wrapper wrapper-content animated fadeIn">
              { this.state.isAccessible && 
                <AcknowledgementsList />
              }  
            </div>
        )
    }

}

export default AcknowledgementsView;
