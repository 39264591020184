// @flow
import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { client } from '../Client';
import { surveyQuestionClient } from '../../clients/SurveyQuestionClient';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { toastrNotification } from '../../helpers/Toastr';
import Input from '../form_components/Input';

class NewCategoryModal extends Component {
      
  state = {
    showModal: false,
    canSubmit: false,
    fields: {
      name: ""
    }
  };
              
  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true, fields: { name: "" } });
  }
  
  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  resetForm = () => {
     formsy.reset();
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }

  formSubmit = (data) => {
    const _this = this
    client.createSurveyCategory(data)
    .then((category) => {
        if (category.id) {
          _this.props.reloadSurvey();
          toastrNotification({success: true, title: 'Survey category was successfully created'});
          _this.close();
        } else {
          _this.refs.form.updateInputsWithError(category);
        }
    });
  }
  
  
 render() {

    return (
      <div>
        <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>New Category</Modal.Title>
          </Modal.Header>
          <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal employee-form">
            <Modal.Body>
              <div className="row">
                <div className="col-sm-12">   
                  <div className="form-group">
                    <label htmlFor="name" className="sr-only"></label>
                    <Input
                      name="name"
                      placeholder="Category name *"
                      className="form-control"
                      tabIndex="1"
                      value={this.state.fields.name}
                      onChange={this.onInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>  
            <Modal.Footer>  
              <Button
                bsClass="btn btn-primary"
                type="submit"
                disabled={!this.state.canSubmit}
              >Save</Button>
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
          </Formsy>  
        </Modal>
      </div>  
    );
  }
}

export default NewCategoryModal;
