import React, { Component } from 'react';
import {withFormsy} from 'formsy-react';

class FileInput extends Component { 
  
  handleChange = (evt) => {
    this.props.setValue(evt.target.value);
    if (this.props.onChange) this.props.onChange(evt);
  }
  
  render() {
    const errorMessage = this.props.errorMessage;
    return (
      <div>
        <input
          type='file'
          name={this.props.name}
          onChange={this.handleChange}
          value={this.props.value}
          className={this.props.className}
          placeholder={this.props.placeholder}
          tabIndex={this.props.tabIndex}
          disabled={this.props.disabled}
          required={this.props.required}
          accept={this.props.accept || '*'}   
          style={{'height':'auto'}}
        />
        <span className='validation-error'>{errorMessage}</span>
      </div>  
    );
  }
}

export default withFormsy(FileInput)
