import React, { Component } from "react";
import "./GiftCard.css";
import LaddaButton from "react-ladda";

class GiftCard extends Component {
  render() {
    return (
      <div className="main-gift-card">
        {this.props.svg}
        <h1>Congratualtions!</h1>
        <p>{this.props.para}</p>

        <LaddaButton
          className={"btn btn-outline gift-card-dialog-action-button"}
          style={{
            width: "95%",
            height: "42px",
            display: "flex",
            justifyContent: "center",
            border: "none",
            borderRadius: "8px",
          }}
          onClick={this.props.onAction}
        >
          <h3>Continue</h3>
        </LaddaButton>
      </div>
    );
  }
}

export default GiftCard;
