import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import {Checkbox} from 'react-ui-icheck';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { groupClient } from '../../clients/GroupClient';
import { toastrNotification } from '../../helpers/Toastr';

class AddGroupMembersModal extends Component {

  state = {
    showModal: false,
    employees: [],
    selectedCnt: 0,
    loading: false
  }
    
  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true, loading: false, selectedCnt: 0 })
    groupClient.getGroupCandidates(this.props.groupId).then((employees) => {
      for (let e of employees) { e.visible = true; e.selected = false }
      this.setState({ employees })
    })
  }
  
  addEmployeesInGroup = () => {
     const employeeIds = this.state.employees.filter(e => e.selected).map(e => e.id)
     this.setState({ loading: true });
     groupClient.addEmployeesInGroup(this.props.groupId, employeeIds)
     .then((response) => {
         if (response.success) {
           this.props.refreshGroupMembers();
           toastrNotification({success: true, title: `You successfully added ${response.cnt} members`});
           this.close();
         } else {
           toastrNotification({success: false, title: 'Unable to add members.'});
           setTimeout(() => {
             this.setState({ loading: false })
           }, 1500)
         }
     });
  }
  
  updateEmployeeVisibility = (employee, searchExpr) => {
    employee.visible = employee.full_name.match(searchExpr) !== null;
    return employee
  }
  
  handleSearch = (evt) => {
    evt.preventDefault();
    const searchExpr = new RegExp(this.refs.search.value, "i");
    const nextEmployees = [...this.state.employees].map((employee) => this.updateEmployeeVisibility(employee, searchExpr));
    const cnt = nextEmployees.filter((employee) => (employee.visible)).length
    this.setState({ employees: nextEmployees });
  }
  
  handleEmployeeSelect = (evt, employee) => {
    const mEmployees = this.state.employees.map((e) => {
      if (e.id === employee.id) {
        let em = e
        em.selected = !e.selected
        return em
      } else {
        return e
      }
    })
    const cnt = mEmployees.filter(e => e.selected).length
    this.setState({ employees: mEmployees, selectedCnt: cnt })
  }

 render() {
    const {employees} = this.state

    return (
      <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Select employees</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <form className="clearfix">
                  <div className="input-group">
                    <input type="text" placeholder="Search " ref="search" className="input form-control"  onChange={this.handleSearch} />
                    <span className="input-group-btn">
                       <button type="button" className="btn btn btn-primary" onClick={(evt) => this.handleSearch(evt)}> <FontAwesomeIcon icon={faSearch}/></button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
            <br/>
            <div className="row">
              <div className="col-md-12 group-candidates-list">
                <ul>
                  {employees.map((employee) => 
                    <li key={'re'+employee.id} className={employee.visible ? '' : 'hidden'}>
                      <Checkbox
                        name='employees'
                        checkboxClass="icheckbox_square-green"
                        checked={employee.selected}
                        increaseArea="20%"
                        label={`<span><img className='img-circle' alt='image' src=${employee.avatar_url} />${employee.full_name}</span>`}
                        onChange={(evt) => this.handleEmployeeSelect(evt, employee)}
                      />                                            
                    </li>    
                  )}
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LaddaButton disabled={this.state.selectedCnt === 0} data-style={EXPAND_LEFT} type="button" onClick={() => this.addEmployeesInGroup()} loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Submit</LaddaButton>
            <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
      </Modal>
    );
  }
}

export default AddGroupMembersModal;
