import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';

import { client } from '../components/Client';

class LogoutView extends Component {

  constructor(props) {
    super(props);
console.log('Logout');
    client.logout();
  }

  render() {
    return (
      <Redirect
        to='/login'
      />
    );
  }
}

export default LogoutView;
