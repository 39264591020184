import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';

class SurveyQuestionClient {
  
  createSurveyQuestion(questionData) {
    return client.callApi(Config.API_BASE + '/survey_questions.json', {}, 'POST', {survey_question: questionData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  };
  
  updateSurveyQuestion(questionId, questionData) {
    return client.callApi(Config.API_BASE + '/survey_questions/' + questionId + '.json', {}, 'PUT', {survey_question: questionData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  };
  
  removeSurveyQuestion(question) {
    return client.callApi(Config.API_BASE + '/survey_questions/' + question.id + '.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {} ;
      });           
  };
          
}

export const surveyQuestionClient = new SurveyQuestionClient();
