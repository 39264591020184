import moment from 'moment';

export function ageToHuman(years) {
    let humanized = null;
    if (years * 12 < 1) return null;
    if (years < 1) {
      humanized = Math.floor(years * 12);
      humanized += (humanized > 1) ? ' months' : ' month';
    } else if (years < 2) {
      humanized = '1 year';
    } else {
      humanized = Math.floor(years) + ' years';
    }  
    return humanized;
}

export function dateToHuman(date) {
  let dateHumanized = moment(date).format('MMMM Do YYYY, h:mm a');
  return dateHumanized
}

export function pluralize(text, cnt) {
  if (cnt > 1) text += 's';
  return text;
}

export function timeFromNow(date) {
  let time = moment(date).fromNow(true);
  return time;
}

export function trimText(text, length) {
  if (text.length > length) {
    return (text.substr(0, length) + '...')
  } else {
    return text
  }
}
