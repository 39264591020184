// @flow
import React, { Component } from 'react';
import ActionCable from 'actioncable';
import PageVisibility from 'react-page-visibility';
import { isIOS } from 'react-device-detect';
import { client } from '../components/Client';
import { companySettingsClient } from '../clients/CompanySettingsClient';
import { conversationClient } from '../clients/ConversationClient';
import ConversationMessagesList from '../components/conversation/ConversationMessagesList';
import Config from 'config';
import { extractParameter } from '../helpers/PathParameter';
import Media from 'react-media';

import './Conversation.css';
import NewConversationModal from '../components/conversation/NewConversationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import ConversationRow from '../components/conversation/ConversationRow';
import { Waypoint } from 'react-waypoint';

class ConversationView extends Component {

  state = {
    messages: [],
    conversation: null,
    conversations: [],
    subscriptionActive: false,
    isAccessible: false,
    conversationsEnded: false,
    isLoading: false
  }

  subscription: ActionCable;
  channelName = "ConversationChannel";

  constructor(props) {
    super(props);

    this.newConversationModal = React.createRef()
  }

  loadMoreConversations() {
    this.setState({ isLoading: true });
    conversationClient.getConversations(this.state.page, this.state.search)
    .then((conversations) => {
        const conversationsEnded = conversations.length > 0 ? false : true;
        this.setState({ conversations: this.state.conversations.concat(conversations || []), page: this.state.page += 1, isLoading: false, conversationsEnded: conversationsEnded });
     });
  }

  renderLoader = () => {
    if (this.state.isLoading && !this.state.conversationsEnded) {
      return (
        <div className="sk-spinner sk-spinner-double-bounce">
          <div className="sk-double-bounce1"></div>
          <div className="sk-double-bounce2"></div>
        </div>
      )
    }
  }

  renderWaypoint = () => {
    if (!this.state.conversationsEnded) {
      return (
        <Waypoint
          onEnter={this.loadMoreConversations.bind(this)}
          scrollableAncestor={window}
        />
      )
    }
  }

  componentDidMount() {
    let _this = this;

    this.checkUserAccess();

    conversationClient.getConversation(this.props.match.params.id)
      .then((conversation) => {
        this.setState({ conversation: conversation })
      });

    let listener = {
      received(data) {
        if (!client.isLoggedIn()) {
          window.location.hash = '/login';
          return;
        };

        switch (data.action) {
          case 'create_message':
            _this.addNewMessage(data);
            break;
        }
        console.log("received, channel:" + _this.channelName, data);
      }
    };

    ActionCable.debugging = true;
    window.cable = ActionCable.createConsumer(client.getConsumerUrl());
    this.subscription = window.cable.subscriptions.create({ channel: this.channelName, conversation_id: this.props.match.params.id }, listener);
    if (this.subscription) { this.setState({ subscriptionActive: true }) }

    $(window).bind("load resize", function () {
      _this.updateElementsWidth();
    });
    _this.setInitialWidthForLeftBar(_this)
  }

  updateElementsWidth(width=null) {
    if(!width) width = $('#conversationMessagesList').parent().css('margin-left');
    $('.conversation-left-bar').css('width', width)
    $('.conversation-left-bar').css('width', '+=15')
  }

  setInitialWidthForLeftBar(_this){
    let count=0;
    const timer=setInterval(()=>{
      const width = $('#conversationMessagesList').parent().css('margin-left');
      if(count>=10 || parseInt(width)>0) {
        clearInterval(timer);
        _this.updateElementsWidth(width)
      }
      count++;
    },200)
  }

  checkUserAccess = () => {
    if (client.adminLoggedIn()) {
      this.setState({ isAccessible: true });
    } else {
      companySettingsClient.checkUserAccess("conversation")
        .then((response) => {
          this.setState({ isAccessible: response.success });
          if (!response.success) {
            swal("Error!", "You dont have access to this page.", "error");
          }
        });
    }
  }

  componentWillUnmount() {
    if (this.subscription) {
      setTimeout(() => { console.log('unsubscribed'); this.subscription.unsubscribe() }, 100);
    }
  }

  addNewMessage = (data) => {
    if (this.refs.messagesList) {
      this.refs.messagesList.addNewMessage(data)
    }
  }

  handleVisibilityChange(visibilityState, documentHidden) {
    if (isIOS) {
      console.log('************** visibility hidden: **********');
      console.log(documentHidden);
      if (documentHidden) {
        if (this.subscription) { }
      } else { }
    }
  }

  openNewConversationModal = () => {
    this.newConversationModal.current?.open()
  }

  render() {
    return (
      <PageVisibility onChange={(visibilityState, documentHidden) => this.handleVisibilityChange(visibilityState, documentHidden)}>
        <div className="wrapper wrapper-content animated fadeIn">
          <div className="row">
            <Media query="(min-width: 769px)" render={() =>
              <div className="conversation-left-bar">
                {this.state.isAccessible &&
                  <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                    <h5>Conversations</h5>
                    <div>
                      <button className="btn btn-primary btn-sm" onClick={() => this.openNewConversationModal()}><FontAwesomeIcon icon={faPlus} /> New Conversation</button>
                      <NewConversationModal ref={this.newConversationModal} />
                    </div>
                    <div className="conversations-list">
                    {
                       this.state.conversations.map((conversation) => {
                         return <ConversationRow
                            key={'conversation'+conversation.id}
                            conversation={conversation}
                         />
                       })
                      }
                      {this.renderWaypoint()}
                      {this.renderLoader()}
                    </div>
                  </div>
                }
              </div>
            } />
            <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
              {this.state.conversation && this.state.subscriptionActive && this.state.isAccessible &&
                <ConversationMessagesList
                  ref="messagesList"
                  subscription={this.subscription}
                  conversation={this.state.conversation}
                />}
            </div>
          </div>
        </div>
      </PageVisibility>
    )
  }
}

export default ConversationView
