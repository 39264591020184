// @flow
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { verifiedCredentialsClient } from '../../clients/VerifiedCredentialsClient';
import { Button } from 'react-bootstrap';
import IBox from '../common/IBox';
import SweetAlert from 'sweetalert-react';
import { toastrNotification } from './../../helpers/Toastr';
import CreateCredentialsItem from './CreateCredentialsItem';
import RemoveCredentialsItemLink from './RemoveCredentialsItemLink';
import ImportCredentialsItems from './ImportCredentialsItems';

import 'sweetalert/dist/sweetalert.css';
//import './RewardsList.css'

class CredentialsList extends Component {

  state = {
    credentialsList: [],
    cnt: 0
  }
  
  componentDidMount() {
    this.getCredentials();
  }

  getCredentials = () => {
    verifiedCredentialsClient.getCredentials().
    then((credentials) => {
      this.setState({ credentialsList: credentials, cnt: credentials.length })
    });
  }
    
  handleCreateNewCredentialsItem = (newCredentialsItem) => {
    let joinedCredentialsList = this.state.credentialsList.concat(newCredentialsItem);
    this.setState({ credentialsList: joinedCredentialsList });
  }
  
  removeCredentialsItem = (credentialsItem) => {
    const _this = this;
    verifiedCredentialsClient.RemoveCredentialsItem(credentialsItem)
    .then((response) => {
        if (response.success) {
          let modifiedList = this.state.credentialsList;
          for (let i in modifiedList) {
            if (modifiedList[i].id === credentialsItem.id) {
               modifiedList.splice(i, 1);
               break
            }
          };
          this.setState({ credentialsList: modifiedList });
          toastrNotification({success: true, title: 'New verified credentials item was successfully deleted'});
        } else {
          toastrNotification({success: false, title: 'Unable to remove verified credentials item'});
        }
    });
  }
  
  hideItem = (item, searchExpr) => {
    item.visible = item.email_or_phone.match(searchExpr) !== null; 
    return item;
  }
  
  handleSearch = (evt) => {
    evt.preventDefault();
    const escapedString =  this.refs.search.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); 
    const searchExpr = new RegExp(escapedString, "i");
    const nextCredentials = [...this.state.credentialsList].map((item) => (this.hideItem(item, searchExpr)));
    const cnt = nextCredentials.filter((item) => (item.visible)).length;
    this.setState({ credentialsList: nextCredentials, cnt: cnt });
  }
  
  openModal() {
    this.refs.CreateCredentialsItemModal.show();
  }
  
  render() {
    return (
      <div className="ibox">
          <div className="ibox-title">
            <h5>Verified Credentials ({ this.state.cnt })</h5>
          </div>
          <div className="ibox-content">
            <div className="row">
              <div className="col-sm-12">
                <div className="pull-right">
                  <button className="btn btn-white btn-sm btn-outline" onClick={() => this.openModal()}><FontAwesomeIcon icon={faPlus}/> Add</button>
                  {<CreateCredentialsItem 
                    ref="CreateCredentialsItemModal" 
                    onAddNewCredentialsItem={this.handleCreateNewCredentialsItem}
                  />}
                </div>
                <div className="pull-right">&nbsp;</div>
                <div className="pull-right">
                  <ImportCredentialsItems onFinishImport={this.getCredentials}/>
                </div>  
              </div>
            </div>
            <div className="row" style={{marginTop: "10px"}}>
              <div className="col-sm-12">
                <form className="clearfix">
                  <div className="input-group">
                    <input type="text" placeholder="Search " ref="search" className="input form-control"  onChange={this.handleSearch} />
                    <span className="input-group-btn">
                        <button type="submit" className="btn btn btn-primary"> <FontAwesomeIcon icon={faSearch}/> Search</button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
            <div className="table-responsive project-list">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th width="90%">Email or phone</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.credentialsList.filter((item) => (item.visible)).map((credentialsItem) => (
                      <tr key={'cri' + credentialsItem.id}>
                        <td>{credentialsItem.email_or_phone}</td>
                        <td className="pull-right">
                          <RemoveCredentialsItemLink onRemoveCredentialsItem={this.removeCredentialsItem} credentialsItem={credentialsItem}/>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
      </div>    
    )
  }

}


export default CredentialsList;
