import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { client } from '../components/Client';
import ResetPasswordForm from '../components/login/ResetPasswordForm';

class ResetPasswordView extends Component {

  state = {
    shouldRedirect: false
  };  

  enterAccount = () => {
    this.setState({shouldRedirect: true});
  }
    
  redirectPath = () => {
    const locationState = this.props.location.state;
    const pathname = (
      locationState && locationState.from && locationState.from.pathname
    );
    return pathname || '/activity';
  };

  render() {
      if (this.state.shouldRedirect) {
        return (
          <Redirect to={this.redirectPath()} />
        );
      } else {
        return (
          <div className="container">
            <div className="row signin-form">
              <div className="ibox float-e-margins">              
                <ResetPasswordForm onResetPassword={this.enterAccount} code={this.props.match.params.code}/>
              </div>
            </div>
          </div>
        )
    }
  }
}

export default ResetPasswordView;