// @flow
import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import TimeZoneAgnosticBigCalendar from '../components/calendar/TimeZoneAgnosticBigCalendar';
import NewEventForm from '../components/calendar/NewEventForm';
import EventModal from '../components/calendar/EventModal';
import { client } from '../components/Client';
import { companySettingsClient } from '../clients/CompanySettingsClient';
import { eventClient } from '../clients/EventClient';

import "react-big-calendar/lib/css/react-big-calendar.css";

class CalendarView extends Component {

  state = {
    events: [],
    departmentList: [],
    allowedEmployees: [],
    timezone: null
  }

  componentDidMount() {
    const currentDate = new Date();
    this.getEvents(currentDate.toUTCString());
    this.getDepartments();
    this.getCalendarAllowedEmployees();
  }
  
  getEvents(date) {
    let timezone = null;
    eventClient.getEvents(date).
    then((event_data) => {
      this.setState({ events: event_data.events, timezone: event_data.timezone });
    });
  }
  
  getDepartments() {
    client.getEmployeeDepartmentsList().then((departments) => {
      const departmentList = departments.map((dep) => ({ value: dep.id, label: dep.title }))
      this.setState({ departmentList: departmentList });
    });
  }
  
  getCalendarAllowedEmployees = () => {
    companySettingsClient.getAllowedEmployees("calendar")
    .then((response) => {
      this.setState({ allowedEmployees: response.employee_ids })                                
    });
  }
  
  handleCreateNewEvent = () => {
    this.getEvents((new Date()).toUTCString())
  }
  
  handleDeleteEvent = () => {
    this.getEvents((new Date()).toUTCString())
  }
  
  openNewEventForm = () => {
    this.refs.eventForm.open();
  }
  
  selectEvent = (event) => {
    this.refs.eventModal.open(event)
  }
  
  handleCalendarDatesChange = (date) => {
    this.getEvents(date.toUTCString())
  }
  
  render() {
        return (
            <div className="wrapper wrapper-content animated fadeIn">
              <div className="row">
                <div className="col-md-12">
                  <div className="ibox" >
                    <div className="ibox-title">
                      <h5>Calendar</h5>
                      <div className="ibox-tools">
                        { (this.state.allowedEmployees.includes(client.currentUser().id) || client.adminLoggedIn()) && 
                          <button className="btn btn-primary btn-sm pull-right" onClick={() => this.openNewEventForm()}><FontAwesomeIcon icon={faPlus}/> Add Event</button>
                        }
                        <NewEventForm 
                          ref="eventForm" 
                          departmentList={this.state.departmentList}
                          onCreateEvent={this.handleCreateNewEvent}
                        />
                        <EventModal 
                          ref="eventModal" 
                          onDeleteEvent={this.handleDeleteEvent}
                        />
                      </div>  
                    </div>
                    <div className="ibox-content" style={{height: '600px', overflowX: 'auto'}}>
                      <div style={{height: "500px"}}>
                        <TimeZoneAgnosticBigCalendar
                          events={this.state.events}
                          startAccessor="start"
                          endAccessor="end"
                          onSelectEvent={(event, e) => this.selectEvent(event)}
                          timeZoneName={this.state.timezone}
                          onCalendarDatesChange={this.handleCalendarDatesChange}
                        />
                      </div>  
                    </div>
                  </div>    
                </div>
              </div>
            </div>
        )
    }

}

export default CalendarView
