import Config from 'config';
import React from 'react';

export function iconUrl(course) {
  return `${Config.PORTAL_API_BASE}/tools/screenshot/icon_mp/${course.icon_path}`
}

export function quickDocumentIconUrl(doc) {
  if (doc.icon_url == null)  {
    let icon_url = 'img/quick-doc.png';  
    if (doc.filename.match(/\.(png|jpg|jpeg|gif|bmp|tiff)$/)) {
      icon_url = 'img/quick-img.png';
    } else if(doc.filename.match(/\.(pdf)$/)) {
      icon_url = 'img/quick-pdf.png';
    }
    return icon_url;
  } else {
    return doc.icon_url
  }  
}

export function docStatusLabel(doc) {
  if (doc.status === 'passed' && doc.boolean_complete) {
    return (<span className="label label-info">Passed</span>);
  } else if (doc.status === 'failed' && doc.failed) {
    return (<span className="label label-danger">Failed</span>);
  } else if (doc.status === 'not started') {
    return (<span className="label">Not Started</span>);
  } else {
    return (<span className="label label-primary">In Progress</span>);
  }   
}
