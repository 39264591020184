import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';

class ActivityActionClient {
  
  getActivityActions() {
    return client.callApi(Config.API_BASE + '/activity_actions.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });        
  }
  
  createAction(actionData) {
    return client.callApi(Config.API_BASE + '/activity_actions.json', {}, 'POST', {activity_action: actionData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
    
  updateAction(actionId, actionData) {
    return client.callApi(Config.API_BASE + '/activity_actions/'+actionId+'.json', {}, 'PUT', {activity_action: actionData})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
    
  deleteAction(actionId) {
    return client.callApi(Config.API_BASE + '/activity_actions/'+ actionId +'.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  sortActions(actionIds) {
    return client.callApi(Config.API_BASE + '/activity_actions/sort.json', {}, 'PUT', {activity_actions: actionIds})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
}

export const activityActionClient = new ActivityActionClient();
