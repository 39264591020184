import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faMagnet } from '@fortawesome/free-solid-svg-icons/faMagnet';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faSmile } from '@fortawesome/free-regular-svg-icons/faSmile';
import { client } from '../Client';

import './KeyMetricsReport.css'

class KeyMetricsReportWidget extends Component {
    
  state = {
    spinnerClass: 'sk-loading',
    metrics: [],
    engagement_score: null,
    reportDataPresent: true
  };
  
  componentDidMount() {
    this.setState(this.state);
    this.getSurveyReportData();
  }
  
  getSurveyReportData = () => {
    client.getSurveyReportData(this.props.survey.id, 'key_metrics').then((data) => {
      this.setState({ metrics: data.metrics, engagement_score: data.engagement_score, spinnerClass: '', reportDataPresent: data.metrics.length > 0 })
    });
  }
  
  renderKeyMetric = (metric) => {
    let metricIcon = ""

    if (metric.category == "Workplace Satisfaction") {
      metricIcon = faStar
    } else if (metric.category == "Feeling Appreciated and Valued") {
      metricIcon = faSmile
    } else if (metric.category == "Employee Retention") {
      metricIcon = faMagnet
    } else if (metric.category == "Organizational Culture") {
      metricIcon = faBullhorn
    } else {
      metricIcon = faAsterisk
    }    
    
    return (
      <div className="col-md-12">
        <div className="col-md-2 metric-icon">
          <span className="badge">
            <FontAwesomeIcon icon={metricIcon} size="2x"/>
          </span>  
        </div>
        <div className="col-md-8">
          <div>{metric.category}</div>
          <div className="progress progress-small">
            <div style={{width: `${metric.value * 10}%`}} className="progress-bar"></div>
          </div>
        </div>
        <div className="col-md-2 metric-value">
          {metric.value}
        </div>  
      </div>
    )
  }
  
 render() {


    return (
      <div className="row">
        <div className="col-md-12">
          <h2>Key Metrics</h2>
          <div className="row">
            {
              this.state.metrics.map((metric, index) => (
                <div className="col-md-6" key={'km'+index} style={{marginBottom: '20px'}}> 
                  {this.renderKeyMetric(metric)}
                </div>  
              ))
            }
          </div>  
          { this.state.engagement_score &&
            <div className="row" style={{marginBottom: '20px'}}>
              <div className="col-md-3">
                <div className="row vertical-align">
                  <div className="col-xs-12">
                    <h2>Engagement score <span className="font-bold">{this.state.engagement_score}</span></h2>
                  </div>  
                </div>
              </div>
            </div> 
          }   
        </div>       
      </div>
    );
  }
}

export default KeyMetricsReportWidget;
