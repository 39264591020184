import React from "react";
import "./ReelDraft.css";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { Link } from "react-router-dom";
import { reelsClient } from "../../clients/ReelsClient";
import SweetAlert from "sweetalert-react/lib/SweetAlert";
class ReelDraft extends React.Component {
  state = {
    showModal: false,
    drafts: [],
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    reelsClient.getMyDrafts().then((drafts) => this.setState({ drafts }));
  }
  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  deleteDraft(draft_id) {
    const _this = this;
    return () => {
      reelsClient.removeReel(draft_id).then(() => {
        const drafts = _this.state.drafts;
        const draftIndex = drafts.findIndex(draft => draft.id === draft_id);
        if (draftIndex > -1) {
          drafts.splice(draftIndex, 1);
          _this.setState({ drafts });
        }
      });
    };
  }

  render() {
    return (
      <div>
        <div className="draft-container">
          {this.state.drafts?.map((draft) => (
            <DraftTumbnail key={draft.id} draft={draft} onDraftSelected={this.props.onDraftSelected} onDeleteDraft={this.deleteDraft(draft.id)} />
          ))}
        </div>
        <div style={{ width: '100%', marginTop: '20px' }}>
          <Link to={"/activity#new_reel"}>
            <LaddaButton
              className={"ladda-button btn btn-sm btn-primary "}
              onClick={() => this.props.onDraftSelected()}
              style={{
                width: "100%",
                height: "40px",
              }}
            >
              Back to Create new Xtra
            </LaddaButton>
          </Link>
        </div>
      </div>
    );
  }
}

export function DraftTumbnail(props) {
  const [dialog, openDialog] = React.useState(false);

  const deleteDraft = () => {
    props.onDeleteDraft();
    openDialog(false);
  }

  return (
    <div className="main-draft" key={props.draft.id}>
      <img src={props.draft.thumb_url}></img>
      <div className="draft-title">{props.draft.original_filename}</div>
      {!props.hideOptions && <div className="draft-options">
        <div className="draft-option draft-use-button" onClick={() => props.onDraftSelected(props.draft)}>Use</div>
        <div className="draft-option draft-delete-button" onClick={() => openDialog(true)}>Delete</div>
      </div>}
      {!props.hideOptions && <SweetAlert
        show={dialog}
        type="warning"
        title="Are you sure?"
        confirmButtonColor="#DD6B55"
        showCancelButton
        text="This operation can not be undone."
        onConfirm={() => deleteDraft()}
        onCancel={() => openDialog(false)}
      />}
    </div>
  );
}

export default ReelDraft;
