import React, { Component } from 'react';

class RewardSuccess extends Component {
  
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2>Congratulations! You got the reward!</h2>
          </div>
        </div>
      </div> 
    )
  }

}

export default RewardSuccess;
