import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { kpiReportClient } from '../../clients/KpiReportClient';

let chartData = {
  labels: [],
  datasets: [{
    label: 'Spent $',
    type: 'line',
    data: [],
    fill: false,
    borderColor: '#EC932F',
    backgroundColor: '#EC932F',
    pointBorderColor: '#EC932F',
    pointBackgroundColor: '#EC932F',
    pointHoverBackgroundColor: '#EC932F',
    pointHoverBorderColor: '#EC932F',
    yAxisID: 'y-axis-2'
  }, {
    type: 'bar',
    label: 'Count',
    data: [],
    fill: false,
    backgroundColor: '#71B37C',
    borderColor: '#71B37C',
    hoverBackgroundColor: '#71B37C',
    hoverBorderColor: '#71B37C',
    yAxisID: 'y-axis-1'
  }]
};

const options = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  elements: {
    line: {
      fill: false
    }
  },
  scales: {
    x: {
      display: true,
      gridLines: {
        display: false
      },
      labels: {
        show: true
      },
      ticks: {
        autoSkip: false,
        maxTicksLimit: 5
      }
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      id: 'y-axis-1',
      gridLines: {
        display: false
      },
      labels: {
        show: true
      },
      ticks: {
        beginAtZero: true
      }
    },
    y2: {
      type: 'linear',
      display: true,
      position: 'right',
      id: 'y-axis-2',
      gridLines: {
        display: false
      },
      labels: {
        show: true
      },
      ticks: {
        callback: function (value, index, values) {
          return '$' + value;
        }
      }
    }
  }
};

class TrainingReport extends Component {

  state = {
    reportData: {},
    reportDataPresent: false
  };

  componentDidMount() {
    this.setState(this.state);
    this.getReportData()
  }

  getReportData = () => {
    kpiReportClient.getTopCategories().then((data) => {
      chartData.labels = data.categories;
      chartData.datasets[1].data = data.amount_list;
      chartData.datasets[0].data = data.sum_list;
      this.setState({ reportData: chartData, reportDataPresent: data?.categories.length > 0 })
    });
  }

  render() {
    return (
      <div>
        <h2>
          Training
          <small> (top training categories)</small>
        </h2>
        {this.state.reportDataPresent &&
          <Bar
            data={this.state.reportData}
            options={options}
          />
        }
        {
          !this.state.reportDataPresent &&
          <div>There is no data yet</div>
        }
      </div>
    );
  }
}

export default TrainingReport;
