import React from 'react';
import ActionCable from 'actioncable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { Dropdown } from 'react-bootstrap';
import { smoothlyMenu } from '../layouts/Helpers';
import { Link } from 'react-router-dom';
import { client } from '../Client';
import { companySettingsClient } from '../../clients/CompanySettingsClient';
import Config from 'config';
import MySpotlightSearch from './MySpotlightSearch';
import NotificationsList from './NotificationsList';
import EmployeeMenuItems from './EmployeeMenuItems';
import AdminMenuItems from './AdminMenuItems';
import InviteEmployeeForm from '../employees/InviteEmployeeForm';
import ChangePasswordForm from '../employees/ChangePasswordForm';
import { activeRoute, adminLoggedIn, ownerLoggedIn, quicktrainEnabled, pickupshiftEnabled, renderProfileIcon } from '../../helpers/TopMenu';
import AppIcon from '../icons/AppIcon';
import { regularIcons, solidIcons } from '../icons/lib';

class TopHeader extends React.Component {

  state = {
    prevPath: "",
    unreadNotificationsSize: 0,
    unreadConversationMessagesSize: 0,
    userAccessSettings: {
      conversation: false,
      pickup_shift: false,
      invitation: false,
      acknowledgement: false,
      poll: false,
      need_to_change_password: false,
    }
  }

  subscription: ActionCable;
  channelName = "NotificationChannel";

  componentDidMount() {
    let _this = this;

    this.getUnreadNotificationsInfo();

    if (!client.adminLoggedIn()) {
      this.getCurrentUserAccessSettings();
    }

    let listener = {
      received(data) {
        if (!client.isLoggedIn()) {
          window.location.hash = '/login';
          return;
        };

        switch (data.action) {
          case 'new_notification':
            _this.increaseUnreadNotificationsSize();
            break;
          case 'new_conversation_message':
            _this.increaseUnreadConversationMessagesSize();
            break;
          case 'update_unread_conversation_messages_size':
            _this.updateUnreadConversationMessagesSize(data);
            break;
          case 'update_unread_notifications_size':
            _this.updateUnreadNotificationsSize(data);
            break;
          case 'refresh_access_settings':
            _this.getCurrentUserAccessSettings();
            break;
        }
        console.log("received, channel:" + _this.channelName, data);
      }
    }

    ActionCable.debugging = true;
    window.cable = ActionCable.createConsumer(client.getConsumerUrl());
    this.subscription = window.cable.subscriptions.create({ channel: this.channelName }, listener);
  }

  componentDidUpdate = () => {
    //hide navbar after redirect for small areas
    if ($('body').is('.body-small') && $('#navbar').is('.in')) {
      $(".navbar-toggle").click();
    };
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    if (this.subscription) {
      setTimeout(() => { console.log('unsubscribed'); this.subscription.unsubscribe() }, 100);
    }
  }

  getCurrentUserAccessSettings = () => {
    companySettingsClient.getCurrentUserAccessSettings()
      .then((response) => {
        if (response.access_settings) {
          this.setState({ userAccessSettings: response.access_settings });
          if (response.access_settings.need_to_change_password) {
            this.refs.changePasswordForm.open();
          }
        }
      });
  }

  getUnreadNotificationsInfo = () => {
    client.getUnreadNotificationsInfo().
      then((response) => {
        this.setState({
          unreadNotificationsSize: response.unread_notifications,
          unreadConversationMessagesSize: response.unread_conversation_messages
        }, () => {
          if (this.state.unreadNotificationsSize > 0)
            this.refs.notificationsBox.initNotifications(false);
        });
      });
  }

  increaseUnreadNotificationsSize = () => {
    this.setState({ unreadNotificationsSize: this.state.unreadNotificationsSize + 1 }, () => {
      this.refs.notificationsBox.initNotifications(false);
    })
  }

  increaseUnreadConversationMessagesSize = () => {
    this.setState({ unreadConversationMessagesSize: this.state.unreadConversationMessagesSize + 1 })
  }

  updateUnreadConversationMessagesSize = (data) => {
    this.setState({ unreadConversationMessagesSize: data.size })
  }

  updateUnreadNotificationsSize = (data) => {
    this.setState({ unreadNotificationsSize: data.size })
  }

  toggleNavigation(e: Event) {
    e.preventDefault();
    $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  showSearch() {
    this.refs.spotlightSearch.showSpotlight();
  }

  handleNotificationsBoxClick = () => {
    if ($('body').is('.body-small') && $('#navbar').is('.in')) {
      $('#navbar').removeClass("in")
    };
    this.setState({ unreadNotificationsSize: 0 });
    this.refs.notificationsBox.initNotifications();
  }

  saveCurrentPath = () => {
    if (this.props.location.pathname !== "/mobile_menu" && this.props.location.pathname !== "/mobile_admin_menu") {
      this.setState({ prevPath: this.props.location.pathname })
    }
  }

  handlePasswordUpdate = () => {
    let accessSettings = this.state.userAccessSettings;
    accessSettings.need_to_change_password = false;
    this.setState({ userAccessSettings: accessSettings });
  }

  render() {
    const pathname = this.props.location.pathname
    const activeAdminRoute = activeRoute('/employees', pathname) || activeRoute('/groups', pathname) || activeRoute('/account', pathname) ||
      activeRoute('/verified_credentials', pathname) || activeRoute('/survey_builder', pathname) ||
      activeRoute('/incentives', pathname) || activeRoute('/survey_builder', pathname) || activeRoute('/pick_up_shift_requests', pathname) ||
      activeRoute('/reward_types', pathname) || activeRoute('/kpi_report', pathname) || activeRoute('/raffles', pathname) ||
      activeRoute('/mobile_admin_menu', pathname) || activeRoute('/settings', pathname) ||
      activeRoute('/acknowledgements', pathname) || activeRoute('/polls', pathname) || activeRoute('/courses', pathname) || activeRoute('/rewards', pathname) || activeRoute('/calendar', pathname)

    const toggleButtonLink = (pathname === "/mobile_menu") ? this.state.prevPath : "/mobile_menu"

    return (
      <div className="row border-bottom sticky">
        <MySpotlightSearch ref="spotlightSearch" placeholder="Find Employee" />
        <nav className="navbar navbar-static-top white-bg" role="navigation" style={{ marginBottom: 0 }}>
          <div className="navbar-header ezzely-logo-box">
            <a href="#" className="navbar-brand company-logo">&nbsp;</a>
            <Link to={toggleButtonLink} onClick={() => this.saveCurrentPath()} className="navbar-toggle">
              <FontAwesomeIcon icon={faBars} />
            </Link>
          </div>
          <div className="navbar-collapse collapse" id="navbar">
            <ul className="nav navbar-nav ezzely-header">
              <li className={activeRoute('/activity', pathname)}>
                <Link to="/activity">
                  <AppIcon icon={solidIcons.HomeSolid} />
                  <div className="menu-item-label">Activity</div>
                </Link>
              </li>
              <li className={activeRoute('/reels', pathname)}>
                <Link to="/reels">
                  <div style={{ padding: '5px 0 0 0' }}>
                    <AppIcon icon={solidIcons.Reels} iconClass="reels-icon" />
                  </div>
                  <div className="menu-item-label">Xtras</div>
                </Link>
              </li>
              {(client.adminLoggedIn() || this.state.userAccessSettings.invitation) &&
                <li>
                  <InviteEmployeeForm>
                    <ul className="nav navbar-nav"><li>
                      <a>
                        <AppIcon icon={solidIcons.InviteSolid} />
                        <div className="menu-item-label">Invite</div>
                      </a>
                    </li></ul>
                  </InviteEmployeeForm>
                </li>
              }
              {(client.adminLoggedIn() || this.state.userAccessSettings.conversation) &&
                <li className={activeRoute('/conversations', pathname)}>
                  <Link to="/conversations">
                    <AppIcon icon={solidIcons.ChatSolid} />
                    {this.state.unreadConversationMessagesSize > 0 &&
                      <span className="top-header-icon-label">{this.state.unreadConversationMessagesSize}</span>
                    }
                    <div className="menu-item-label">Conversations</div>
                  </Link>
                </li>
              }
              <li className={activeRoute('/notifications', pathname)}>
                <a href="#" onClick={() => this.handleNotificationsBoxClick()} className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                  <AppIcon icon={solidIcons.BellSolid} />
                  {this.state.unreadNotificationsSize > 0 &&
                    <span className="top-header-icon-label">{this.state.unreadNotificationsSize}</span>
                  }
                  <div className="menu-item-label">Notifications</div>
                </a>
                <NotificationsList ref="notificationsBox" />
              </li>

              {/**<li className={ activeAdminRoute }>
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                              <img src="img/header/icon_admin.png"/>
                              <div className="menu-item-label">See All <span className="caret"></span></div>
                            </a>
                            { !adminLoggedIn() &&
                              <EmployeeMenuItems
                                containerClass="dropdown-menu ezzely-admin-dropdown"
                                currentPath={pathname}
                                pickShiftIsAllowed={this.state.userAccessSettings.pickup_shift}
                                acknowledgementIsAllowed={this.state.userAccessSettings.acknowledgement}
                                pollIsAllowed={this.state.userAccessSettings.poll}
                              />
                            }
                            { adminLoggedIn() &&
                              <AdminMenuItems
                                containerClass="dropdown-menu ezzely-admin-dropdown"
                                currentPath={pathname}
                              />
                            }
                          </li>
                           */}

              {adminLoggedIn() &&
                <li className="hidden-xs hidden-sm spotlight-search-field"><a className="spotlight-search-field" onClick={() => this.showSearch()} title="Find Employee" style={{ marginTop: 8 }}><FontAwesomeIcon className='spotlight-search-field' icon={faSearch} /></a></li>
              }
            </ul>
            <ul className="nav navbar-top-links navbar-right ezzely-header hidden-xs">
              <li className={activeRoute('/profile', pathname)}>
                <Link to="/profile" className="profile-link">
                  {renderProfileIcon()}
                  <div className="menu-item-label">{client.currentUser().first_name}</div>
                </Link>
              </li>
              <li>
                <Link to='/logout'>
                  <AppIcon icon={solidIcons.Power} />
                  <div className="menu-item-label">Log Out</div>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        <ChangePasswordForm
          ref="changePasswordForm"
          forceMode={true}
          title="Please setup your new password"
          onSuccessfulPasswordUpdate={this.handlePasswordUpdate}
        />
      </div>
    )
  }
}

export default TopHeader
