// @flow
import React, { Component } from 'react';
import { dateToHuman, timeFromNow } from '../../helpers/Humanizer';
import { Link } from 'react-router-dom';
import ActivityMessageBox from '../activity/ActivityMessageBox';
import moment from 'moment';
import { activeRoute } from '../../helpers/TopMenu';

class ConversationRow extends Component {

  state = {}

  renderDate = (conversation) => {
    if (conversation?.last_message) return (
      <div className="pull-right">
        {moment(conversation.last_message.created_at).format('MMM DD')}
      </div>
    );
    else return (
      <div className="pull-right">
        {moment(conversation.updated_at).format('MMM DD')}
      </div>
    );
  }

  renderLastMessage = (conversation) => {
    if (conversation?.last_message) return (
      <div className="conversation-last-message">
        <ActivityMessageBox content={conversation.last_message.body} />
        {conversation.unread_messages_size > 0 &&
          <div className="pull-right">
            <span className="badge badge-primary">{conversation.unread_messages_size}</span>
          </div>}
      </div>
    );
    else return (
      <div className="conversation-last-message">
        <ActivityMessageBox content="" />
        {conversation.unread_messages_size > 0 &&
          <div className="pull-right">
            <span className="badge badge-primary">{conversation.unread_messages_size}</span>
          </div>}
      </div>
    );
  }

  render() {
    console.log
    const conversation = this.props.conversation;
    let conversationRowClass = conversation.unread_messages_size > 0 ? "conversation-row has-unread-messages" : "conversation-row"
    conversationRowClass = conversationRowClass + " " + activeRoute(`/conversation/${conversation.id}`, window.location.href)

    return (
      <div className="row">
        <div className="col-sm-12">
          <Link to={`/conversation/${conversation.id}`}>
            <div className={conversationRowClass}>
              <div className="social-avatar">
                <div className="pull-left">
                  <div className={conversation.interlocutor.is_online ? "online" : "offline"}>
                    <img alt="image" src={conversation.interlocutor.avatar_url} />
                  </div>
                </div>
                <div className="media-body">
                  <div className="post-title">
                    {conversation.interlocutor.full_name}
                    {this.renderDate(conversation)}
                  </div>
                  {this.renderLastMessage(conversation)}
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  }

}

export default ConversationRow;
