import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { client } from '../components/Client';
import { quickDocumentIconUrl, iconUrl, docStatusLabel } from '../helpers/Courses.js';
import UploadDocument from '../components/quicktrain/UploadDocument.js';
import RemoveQuickDocumentLink from '../components/quicktrain/RemoveQuickDocumentLink.js';
import RemoveCourseLink from '../components/courses/RemoveCourseLink.js';
import { toastrNotification } from './../helpers/Toastr';

class CoursesView extends Component {
  state = {
    fetched: false,
    courses: [],
    quick_courses: [],
    showCourses: 'all', //possible values 'all', 'coggno', 'quick'
    selectedCategory: 'all',
    coursesStatus: 'all',
    spinnerClass: 'sk-loading'
  };

  componentDidMount() {
    this.getQuickDocuments();
    this.getCourses();
  }

  getCourses = () => {
    client.getCoggnoCourses(client.adminLoggedIn()).then((courses) => {
        this.setState({ courses: courses, fetched: true, spinnerClass: "" });
     });
  };
  
  getQuickDocuments = () => {
    client.getQuickDocuments().then((courses) => {
        this.setState({ quick_courses: courses });
     });
  };
  
  handleDocumentUpload = () => {
    this.getQuickDocuments();
  }
  
  handleDocumentDownload = (doc) => {
    client.downloadQuickDocument(doc);
  }
  
  changeCoursesVisibility = (value) => {
    this.setState({ showCourses: value, selectedCategory: 'all' })
  }
    
  selectCategory = (value) => {
    this.setState({ selectedCategory: value })
  }
  
  buildCategoriesList = (courses) => {
    let cats = [];
    courses.map((course) => {
      cats.push(course.category);
      return course;
    });
    return [...new Set(cats.sort())];
  }
  
  removeQuickDocument = (documentId) => {
    client.removeQuickDocument(documentId)
    .then((response) => {
        if (response.success) {
          let newQuickCourses = this.state.quick_courses.filter(function(qc) {
            return qc.id !== response.document_id;
          });
          this.setState({ quick_courses: newQuickCourses });
          toastrNotification({success: true, title: 'Document was successfully removed'});
        } else {
          toastrNotification({success: false, title: 'Unable to remove document'});
        }
    });
  }
  
  removeCourse = (courseId) => {
    client.removeCoggnoCourse(courseId)
    .then((response) => {
        if (response.success) {
          let newCourses = this.state.courses.filter(function(c) {
            return c.id !== response.course_id;
          });
          this.setState({ courses: newCourses });
          toastrNotification({success: true, title: 'Course was successfully removed'});
        } else {
          toastrNotification({success: false, title: 'Unable to remove this course'});
        }
    });
  }
  
    render() {
        let categories = []
        if (this.state.showCourses === 'all') {
          let courses = this.state.courses;
          categories = this.buildCategoriesList(courses.concat(this.state.quick_courses))
        } else if (this.state.showCourses === 'quick') {
          categories = this.buildCategoriesList(this.state.quick_courses) 
        } else if (this.state.showCourses === 'licenses') {
          categories = this.buildCategoriesList(this.state.courses.filter(c => c.is_license))
        } else {
          const courses = this.state.coursesStatus === 'all' ? this.state.courses : this.state.courses.filter(c => c.status == this.state.coursesStatus)
          categories = this.buildCategoriesList(courses)
        }
      
        let filteredCourses = this.state.selectedCategory === 'all' ? this.state.courses : this.state.courses.filter(c => c.category == this.state.selectedCategory)
        let filteredQuickCourses = this.state.selectedCategory === 'all' ? this.state.quick_courses : this.state.quick_courses.filter(c => c.category == this.state.selectedCategory)
        
        if (this.state.showCourses === 'coggno' && this.state.coursesStatus !== 'all') {
          filteredCourses = filteredCourses.filter(c => c.status == this.state.coursesStatus)
        }
        
        if (this.state.showCourses === 'licenses') {
          filteredCourses = filteredCourses.filter(c => c.is_license)
        }
        
        let showCoursesOptions = [
          {value: 'all', label: 'All'},
          {value: 'quick', label: 'Quick Training'},
          {value: 'coggno', label: 'Coggno Courses'},
          {value: 'licenses', label: 'My Licenses'}
        ]
        
        let categoryOptions = [{value: 'all', label: 'All categories'}]
        categories.forEach((category) => {
           let item = {};
           item.value = category;
           item.label = category;
           categoryOptions.push(item);
         });
                
        return (
          <div>
            <div className={'wrapper wrapper-content ' + this.state.spinnerClass}>
              <div className="sk-spinner sk-spinner-double-bounce">
                <div className="sk-double-bounce1"></div>
                <div className="sk-double-bounce2"></div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-4">
                  <div className="ibox float-e-margins">
                    <div className="ibox-content">
                      <div className="file-manager" style={{fontSize: '12px'}}>
                        <h5>Show Courses:</h5>
                        <ul className="folder-list hidden-xs" style={{padding: 0}}>
                          <li key={'cat-all'} className={this.state.showCourses === 'all' ? 'gray-bg' : ''}>
                            <a onClick={() => this.changeCoursesVisibility('all')}>All</a>
                          </li>
                          <li key={'cat-quick'} className={this.state.showCourses === 'quick' ? 'gray-bg' : ''}>
                            <a onClick={() => this.changeCoursesVisibility('quick')}>Quick Training</a>
                          </li>
                          <li key={'cat-coggno'} className={this.state.showCourses === 'coggno' ? 'gray-bg' : ''}>
                            <a onClick={() => this.changeCoursesVisibility('coggno')}>Coggno Courses</a>
                          </li>
                          <li key={'cat-licenses'} className={this.state.showCourses === 'licenses' ? 'gray-bg' : ''}>
                            <a onClick={() => this.changeCoursesVisibility('licenses')}>My Licenses</a>
                          </li>
                        </ul>
                        <div className="hidden-md hidden-lg hidden-sm">
                          <select className="form-control" name="courses-option" onChange={(evt) => this.changeCoursesVisibility(evt.target.value)} value={this.state.showCourses} >
                            {showCoursesOptions.map((co) => (
                              <option key={co.value} value={co.value}>{co.label}</option>
                            ))}
                          </select>
                        </div>
                        <div className="hr-line-dashed hidden-xs"></div>
                        <h5>Categories</h5>
                        <ul className="folder-list hidden-xs" style={{padding: 0}}>
                            <li key={'cat-1'} className={this.state.selectedCategory === 'all' ? 'gray-bg' : ''}>
                              <a onClick={(evt) => this.selectCategory('all')}>All Categories</a>
                            </li>
                            {
                              categories.map((category, index) => (
                                <li key={category+index} className={this.state.selectedCategory === category ? 'gray-bg' : ''}>
                                  <div style={{display: "inline-block"}}>
                                    <a onClick={(evt) => this.selectCategory(category)}>{category}</a>
                                  </div>  
                                </li>
                              ))
                            }                                  
                        </ul>
                        <div className="hidden-md hidden-lg hidden-sm">
                          <select className="form-control" name="selected-category" onChange={(evt) => this.selectCategory(evt.target.value)} value={this.state.selectedCategory} >
                            {categoryOptions.map((co) => (
                              <option key={co.value} value={co.value}>{co.label}</option>
                            ))}
                          </select>
                        </div>
                        <div style={{marginTop: "15px"}}>
                          <UploadDocument onFinishUpload={this.handleDocumentUpload}/>
                          { client.adminLoggedIn() &&
                            <Link to='/coggno_training'>
                              <button className="btn btn-primary btn-sm btn-block"><FontAwesomeIcon icon={faPlus}/> Add Coggno Course</button>
                            </Link>
                          }    
                        </div>  
                      </div>
                    </div>
                  </div>
                </div> 
                <div className="col-lg-9 col-md-9 col-sm-8 animated fadeInRight">
                  <div className="row">
                    { (this.state.showCourses == 'all' || this.state.showCourses == 'quick') && this.state.fetched && filteredQuickCourses.map((course) => (                        
                      
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xsm-6" key={"quick-" + course.id}>
                          <div className="ibox">
                              <div className="ibox-content product-box">
                                  <div style={{position: 'relative', paddingTop: '75%', overflow: 'hidden'}}>                          
                                    <a onClick={() => this.handleDocumentDownload(course)}>
                                      <img style={{position: 'absolute', left: '0px', top: '0px', width:'100%', padding: '20px'}} src={quickDocumentIconUrl(course)} />
                                    </a>
                                  </div>
                                  <div className="product-desc">
                                      <a onClick={() => this.handleDocumentDownload(course)} className="product-name" style={{height: '44px'}}>
                                        <LinesEllipsis text={course.title} maxLine="2" trimRight basedOn="letters"/>
                                      </a>
                                      <div><small className="text-muted text-ellipsis">Category: {course.category}</small></div>
                                      <div><small className="text-muted text-ellipsis">Group: {course.department}</small></div>
                                      <div><small className="text-muted text-ellipsis">Author: {course.creator.full_name}</small></div>
                                      { (client.currentUser().id == course.creator.id)
                                        ? <div style={{height:'70px'}}>
                                            <div className="small m-t-xs" style={{height:'35px'}}>
                                              <LinesEllipsis text={course.description} maxLine="3" trimRight basedOn="letters"/>
                                            </div>
                                            <RemoveQuickDocumentLink onRemoveQuickDocument={this.removeQuickDocument} course={course} />
                                          </div>
                                        : <div className="small m-t-xs" style={{height:'70px'}}>
                                            <LinesEllipsis text={course.description} maxLine="5" trimRight basedOn="letters"/>
                                          </div>  
                                      }  
                                  </div>
                              </div>
                          </div>
                        </div>
                    ))}
                  </div>            
                  
                  <div className="row">
                    { (this.state.showCourses == 'all' || ['coggno', 'licenses'].includes(this.state.showCourses)) &&  this.state.fetched && filteredCourses.map((course) => (            
                      <div className="col-lg-4 col-md-4 col-sm-6 col-xsm-6" key={course.id}>
                          <div className="ibox">
                              <div className="ibox-content product-box">
                                  <div>
                                    <Link to={`/course/${course.coggno_document_id}`}>
                                      <img style={{width:'100%', padding: '20px'}} src={iconUrl(course)} />
                                    </Link>
                                  </div>
                                  <div className="product-desc">
                                      <Link to={`/course/${course.coggno_document_id}`} className="product-name" style={{height: '44px'}}>
                                        <LinesEllipsis text={course.title} maxLine="2" trimRight basedOn="letters"/>
                                      </Link>
                                      <div><small className="text-muted text-ellipsis">Category: {course.category}</small></div>
                                      { course.is_license && !client.adminLoggedIn() &&
                                        <div style={{height:'65px'}}>
                                          <div><small className="text-muted">Status: {docStatusLabel(course)}</small></div>
                                          { course.status === 'incomplete' &&
                                            <div>
                                              <small className="text-muted">Score: {course.score}%</small>
                                              <div className="progress progress-small" style={{marginTop: '10px'}}>
                                                <div style={{width: course.score}} className="progress-bar"></div>
                                              </div>
                                            </div> }
                                        </div>  
                                      }
                                      { course.is_license && client.adminLoggedIn() &&
                                        <div style={{height:'87px'}}>
                                          <div><small className="text-muted">Status: {docStatusLabel(course)}</small></div>
                                          { course.status === 'incomplete' &&
                                            <div>
                                              <small className="text-muted">Score: {course.score}%</small>
                                              <div className="progress progress-small" style={{marginTop: '10px'}}>
                                                <div style={{width: course.score}} className="progress-bar"></div>
                                              </div>
                                            </div>
                                          }
                                          { course.status !== 'incomplete' &&   
                                            <div className="small m-t-xs" style={{height:'40px'}}>
                                              <LinesEllipsis text={course.description} maxLine="5" trimRight basedOn="letters"/>
                                            </div>
                                          }
                                        </div> 
                                      }  
                                      { !course.is_license &&
                                        <div className="small m-t-xs" style={{height:'60px'}}>
                                          <LinesEllipsis text={course.description} maxLine="4" trimRight basedOn="letters"/>
                                        </div>
                                      }
                                      { client.adminLoggedIn() && 
                                        <RemoveCourseLink onRemoveCourse={this.removeCourse} course={course} />
                                      }  
                                  </div>
                              </div>
                          </div>
                         </div>
                    ))}
                  </div>
                </div>                     
              </div>                
            </div>
          </div>
  )}

}

export default CoursesView
