import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { client } from '../Client';


const reportChartData = {
        labels: [],
       	datasets: [{
       		data: [],
       		backgroundColor: [
       		'#FF6384',
       		'#36A2EB',
       		'#FFCE56'
       		],
       		hoverBackgroundColor: [
       		'#FF6384',
       		'#36A2EB',
       		'#FFCE56'
       		]
       	}]
};


class ENPSWidget extends Component {

  state = {
    current_enps: 0,
    total_employees_answered: 0,
    survey_employees_total_number: 0,
    reportDataPresent: true
  };

  componentDidMount() {
    this.setState(this.state);
    this.getSurveyReportData();
  }

  getSurveyReportData = () => {
    client.getSurveyReportData(this.props.survey.id, 'enps').then((data) => {
      reportChartData.labels = data.labels;
      reportChartData.datasets[0].data = data.data;
      this.setState({
        current_enps: data.current_enps,
        reportDataPresent: true })
    });
  }

  enpsClass() {
    if (this.state.current_survey_enps >= 50) {
      return "text-success";
    }
    if (this.state.current_survey_enps >= 10 && this.state.current_survey_enps < 50) {
      return "text-danger";
    }
    return "";
  }

 render() {
   if (this.state.reportDataPresent) {
     return (
        <div className="widget bg-muted">
          <div className="row">
            <div className="col-md-5">
              <p>eNPS&nbsp;Score</p>
              <h2 className={"font-bold " + this.enpsClass()}>{this.state.current_enps}</h2>
            </div>
            <div className="col-md-2">
              <p>&nbsp;</p>
              <h3>/</h3>
            </div>
            <div className="col-md-5">
              <p>Participation</p>
              <h2 className="font-bold">{this.props.survey.completion_progress}%</h2>
            </div>
          </div>
        </div>)
      }
    }
}

export default ENPSWidget;
