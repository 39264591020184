import React, { Component } from 'react';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { client } from '../../Client';
import { rewardTypeClient } from '../../../clients/RewardTypeClient';
import { rewardClient } from '../../../clients/RewardClient';
import RewardTypeList from './RewardTypeList';
import RewardError from './RewardError';
import RewardSuccess from './RewardSuccess';


class RedeemDialog extends Component {

  state = {
    showModal: false,
    step: 'initial',
    message: '',
    rewardTypes: []
  };
  
  calcGiftPrice = (rewardType) => {
    return Math.floor(this.props.employeePoints / rewardType.points_ratio);
  }
  
  pointsRatio = () => {
    
  }
  
  isEnoughPoints = () => {
    return (this.props.cheapestRewardType !== null && this.props.employeePoints >= this.props.cheapestRewardType.points)
  }
  
  refreshEmployeePoints = () => {
    this.props.refreshEmployeePoints();
  }
    
  proceed = () => {
    if (this.state.step == 'initial') {
      this.setState({spinnerClass: 'sk-loading'});
      if (this.state.selectedRewardsType) {
        rewardClient.createReward(this.state.selectedRewardsType.id).then((response) => {
          if (response.success) {
            this.refreshEmployeePoints();
            this.setState({ step: 'rewardSuccess', spinnerClass: '' });
          } else {
            this.setState({ step: 'rewardError', message: response.message, spinnerClass: '' });          
          };
        });
      }    
    }
  } 
  
  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true, selectedRewardsType: null, step: 'initial'});
  }

  selectRewardsType = (type) => {
    this.setState({ selectedRewardsType: type });
  }
    
  isProceedButtonDisabled = () => {
    return (!this.state.selectedRewardsType || this.state.spinnerClass === 'sk-loading');
  }
  
  renderDialogContent() {
    
    switch (this.state.step) {
          case "initial":   return (
            <div className={'wrapper wrapper-content ' + this.state.spinnerClass}>
              <div className="sk-spinner sk-spinner-double-bounce">
                <div className="sk-double-bounce1"></div>
                <div className="sk-double-bounce2"></div>
              </div>
              <RewardTypeList 
                rewardTypes={this.props.rewardTypes} 
                employeePoints={this.props.employeePoints}
                cheapestRewardType={this.props.cheapestRewardType}
                selectedRewardsType={this.state.selectedRewardsType}
                onRewardsTypeSelect={this.selectRewardsType}
              />
            </div>  
          )
          case "rewardSuccess":  return (
            <RewardSuccess />           
          )
          case "rewardError": return (
            <RewardError message={this.state.message}/>                        
          )
          default: return (<div/>);
    }
  }
  
 render() {
   

      
    return (
      <div>
        <button type="button" disabled={!this.isEnoughPoints()} className="btn btn-primary btn-sm btn-block" onClick={this.open}><FontAwesomeIcon icon={faEnvelope}/> Redeem</button>
  
        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Body>
              <h3>Redeem Rewards</h3>              
              {this.renderDialogContent()}              
            </Modal.Body>
            <Modal.Footer>
            { (this.state.step === 'initial') &&
              <button type="button" className="btn btn-primary btn-sm" disabled={this.isProceedButtonDisabled()} onClick={this.proceed}>Redeem</button> 
            }
              <button type="button" className="btn btn-default btn-sm" onClick={this.close}>Close</button>
            </Modal.Footer>
            
        </Modal>
      </div>
    );
  }
}

export default RedeemDialog;
