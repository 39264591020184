import React from "react";
import AvailablePointsTransactionList from "../rewards/AvailablePointsTransactionList";
import PointsTransactionList from "../rewards/PointsTransactionList";

class EmployeePoints extends React.Component{
    render(){
        return <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                 <div className="ibox float-e-margins">
                   <div className="ibox-title">
                     <h5>Points Transactions</h5>
                   </div>
                   <div className="ibox-content">                
                     <PointsTransactionList ref="pointsTransactionList" employeeId={this.props.employeeId}/>                
                   </div>
                 </div>
               </div>
               <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                 <div className="ibox float-e-margins">
                   <div className="ibox-title">
                     <h5>Points Rewarded To Others</h5>
                   </div>
                   <div className="ibox-content">                
                     <AvailablePointsTransactionList  employeeId={this.props.employeeId}/>                
                   </div>
                 </div>
               </div>
        </div>;
    }
}

export default EmployeePoints