import fetch from 'isomorphic-fetch';
import { client } from '../components/Client.js';
import Config from 'config';

class VerifiedCredentialsClient {
  
  getCredentials() {
    return client.callApi(Config.API_BASE + '/verified_credentials.json')
      .catch((error) => {
        client.handleApiError(error);
        return [];
      });
  }
  
  CreateCredentialsItem(data) {
    return client.callApi(Config.API_BASE + '/verified_credentials.json', {}, 'POST', {credentials_item: data})
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }
  
  RemoveCredentialsItem(credentialsItem) {
    return client.callApi(Config.API_BASE + '/verified_credentials/'+ credentialsItem.id +'.json', {}, 'DELETE')
      .catch((error) => {
        client.handleApiError(error);
        return {};
      });        
  }        
              
}

export const verifiedCredentialsClient = new VerifiedCredentialsClient();
