// @flow
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { dateToHuman, timeFromNow } from '../../helpers/Humanizer';
import { Link } from 'react-router-dom';
import { client } from '../Client';
import RemoveCommentLink from './RemoveCommentLink';
import ActivityMessageBox from './ActivityMessageBox';
import moment from 'moment';
import EditCommentModal from './EditCommentModal';

class ActivityPostComment extends Component {

  state = {} // do not remove, otherwise componentWillUnmount will cause exception

  componentDidMount = () => {
    // we ignore confirm timeout for modal activity post as it anyway called from ActivityList
    // otherwise it tries to insert 2 comments with the same key in other connected browsers
    if (!this.props.comment.confirmed && this.props.containerId != 'modalPost') {
      let timeout = setTimeout(() => {this.confirmComment()}, 10000);
      this.setState({timeout: timeout});
    }
  }

  componentWillUnmount = () => {
    if(this.state.timeout) clearTimeout(this.state.timeout);
  }

  sendCommentLike = () => {
    const commentId = this.props.comment.id;
    this.props.channel.perform('activity_post_comment_like', {comment_id: commentId});
  }

  sendRemoveComment = () => {
    const commentId = this.props.comment.id;
    this.props.channel.perform('remove_activity_post_comment', {comment_id: commentId});
  }

  renderRemoveCommentLink = () => {
    if (this.props.comment.author_id === client.currentUser().id || client.ownerLoggedIn()) {
      return (
        <RemoveCommentLink onRemoveComment={this.sendRemoveComment} comment={this.props.comment}/>
      )
    }
  }
    
  isAuthor() {
    return this.props.comment.author_id === client.currentUser().id
  }
  
  editComment = () => {
    this.refs.editCommentModal.open(this.props.comment)
  }
  
  render() {
      return (
        <div className="social-comment">
            <div className="pull-right">
            {this.isAuthor() && moment().diff(moment(this.props.comment.createdDate), 'minutes') <= 10 &&
              <div style={{ display: 'inline-block', marginRight: '15px' }}>
                <a onClick={this.editComment}><FontAwesomeIcon icon={faEdit}/></a>
                <EditCommentModal ref="editCommentModal" channel={this.props.channel} />
              </div>
            }
            {this.props.comment.confirmed &&
              this.renderRemoveCommentLink()
            }
            </div>
            <div className="pull-left media-body-avatar">
                <img alt="image" src={this.props.comment.author_avatar_url ? this.props.comment.author_avatar_url : 'img/avatar_missing.png'} className="avatar-image img-circle"/>
            </div>
            <div className="media-body">
                <b>{this.props.comment.author_name}</b> - <small className="text-muted">{timeFromNow(this.props.comment.createdDate)}</small> &nbsp;
                  { (!client.noComments() || client.adminLoggedIn()) &&
                    <div>
                      <a className="small" onClick={() => this.sendCommentLike()}>
                        <FontAwesomeIcon icon={faThumbsUp}/> {this.props.comment.likesNumber > 0 ? this.props.comment.likesNumber : ''} Like this!
                      </a>
                      <span> · </span>
                      <a className="small" onClick={() => this.props.onClickReply()}>Reply</a>
                    </div>
                  }
                <div style={{marginTop: '4px', marginBottom: '1px'}}>
                  <ActivityMessageBox  content={this.props.comment.message} />
                </div>
            </div>
        </div>
      )
    }

}

export default ActivityPostComment;
