import React from 'react';
import ActionCable from 'actioncable';
import { Link } from 'react-router-dom';
import { client } from '../Client';
import { companySettingsClient } from '../../clients/CompanySettingsClient';
import Config from 'config';
import InviteEmployeeForm from '../employees/InviteEmployeeForm';
import { activeRoute, adminLoggedIn, ownerLoggedIn } from '../../helpers/TopMenu';
import ChangePasswordForm from '../employees/ChangePasswordForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './MobileFooter.css';
import { solidIcons } from '../icons/lib';
import AppIcon from '../icons/AppIcon';
import CreateButtonsModal from '../reels/button/CreateButtonsModal';
import { notificationClient } from '../../clients/NotificationClient';
import ReelPrompt from '../reels/congratulations/ReelPrompt';

class MobileFooter extends React.Component {

  state = {
    prevPath: "",
    unreadNotificationsSize: 0,
    unreadConversationMessagesSize: 0,
    userAccessSettings: {
      conversation: false,
      invitation: false,
      need_to_change_password: false,
    },
    openButtonsModal: false,
    notificationPromptId: null,
  }

  subscription: ActionCable;
  channelName = "NotificationChannel";

  constructor(props) {
    super(props);
    this.promptRef = React.createRef();
  }

  componentDidMount() {
    let _this = this;

    this.getUnreadNotificationsInfo();

    if (!client.adminLoggedIn()) {
      this.getCurrentUserAccessSettings();
    };

    let listener = {
      received(data) {
        if (!client.isLoggedIn()) {
          window.location.hash = '/login';
          return;
        };

        switch (data.action) {
          case 'new_notification':
            _this.increaseUnreadNotificationsSize(data);
            break;
          case 'new_conversation_message':
            _this.increaseUnreadConversationMessagesSize();
            break;
          case 'update_unread_conversation_messages_size':
            _this.updateUnreadConversationMessagesSize(data);
            break;
          case 'update_unread_notifications_size':
            _this.updateUnreadNotificationsSize(data);
            break;
          case 'refresh_access_settings':
            _this.getCurrentUserAccessSettings();
            break;
        }
        console.log("received, channel:" + _this.channelName, data);
      }
    }

    ActionCable.debugging = true;
    window.cable = ActionCable.createConsumer(client.getConsumerUrl());
    this.subscription = window.cable.subscriptions.create({ channel: this.channelName }, listener);
  }

  componentWillUnmount() {
    if (this.subscription) {
      setTimeout(() => { console.log('unsubscribed'); this.subscription.unsubscribe() }, 100);
    }
  }

  toggleButtonsModal = () => {
    this.setState({ openButtonsModal: !this.state.openButtonsModal });
  };

  getCurrentUserAccessSettings = () => {
    companySettingsClient.getCurrentUserAccessSettings()
      .then((response) => {
        if (response.access_settings) {
          this.setState({ userAccessSettings: response.access_settings });
          if (response.access_settings.need_to_change_password) {
            this.refs.changePasswordForm.open();
          }
        }
      });
  }

  getUnreadNotificationsInfo = () => {
    client.getUnreadNotificationsInfo().
      then((response) => {
        this.setState({
          unreadNotificationsSize: response.unread_notifications,
          unreadConversationMessagesSize: response.unread_conversation_messages
        });
      });
  }

  increaseUnreadNotificationsSize = (data) => {
    this.setState({ unreadNotificationsSize: this.state.unreadNotificationsSize + 1 }, () => {
      if (data.notification) {
        const notificiationId = JSON.parse(data.notification)?.id;
        if (notificiationId) notificationClient.getNotification(notificiationId).then((notification) => {
          if ((notification.type === 'ticketwinner' || notification.type === 'rafflewinner') && notification.config?.type === 'fbshare' && !notification.read) {
            this.setState({ notificationPromptId: notification.id }, () => {
              const prompt = this.promptRef.current;
              if (prompt) {
                if (notification.message.includes('gift card')) prompt.giftCardModal();
                else prompt.houseReward();
              }
            });
          }
        });
      }
    })
  }

  increaseUnreadConversationMessagesSize = () => {
    this.setState({ unreadConversationMessagesSize: this.state.unreadConversationMessagesSize + 1 })
  }

  updateUnreadConversationMessagesSize = (data) => {
    this.setState({ unreadConversationMessagesSize: data.size })
  }

  updateUnreadNotificationsSize = (data) => {
    this.setState({ unreadNotificationsSize: data.size })
  }

  handlePasswordUpdate = () => {
    let accessSettings = this.state.userAccessSettings;
    accessSettings.need_to_change_password = false;
    this.setState({ userAccessSettings: accessSettings });
  }

  markNotificationsRead = () => {
    if (this.state.notificationPromptId === null) return;
    notificationClient.markNotificationsRead([this.state.notificationPromptId]);
    this.setState({ notificationPromptId: null })
  }

  render() {
    const pathname = this.props.location.pathname
    const activeAdminRoute = activeRoute('/mobile_menu', pathname) || activeRoute('/employees', pathname) || activeRoute('/groups', pathname) || activeRoute('/account', pathname) ||
      activeRoute('/verified_credentials', pathname) || activeRoute('/survey_builder', pathname) ||
      activeRoute('/incentives', pathname) || activeRoute('/survey_builder', pathname) || activeRoute('/pick_up_shift_requests', pathname) ||
      activeRoute('/reward_types', pathname) || activeRoute('/kpi_report', pathname) || activeRoute('/raffles', pathname) ||
      activeRoute('/mobile_admin_menu', pathname) || activeRoute('/settings', pathname) ||
      activeRoute('/acknowledgements', pathname) || activeRoute('/polls', pathname) || activeRoute('/courses', pathname) || activeRoute('/rewards', pathname) || activeRoute('/calendar', pathname)

    const toggleButtonLink = (pathname === "/mobile_menu") ? this.state.prevPath : "/mobile_menu"

    return (
      <>
        <div className="footer mobile-app-footer" style={{ zIndex: "3", borderTop: "1px solid rgba(170,170,170,0.4)" }}>
          <div className={activeRoute('/activity', pathname) + " mobile-quick-menu-item"}>
            <Link to="/activity">
              <AppIcon icon={solidIcons.HomeSolid} />
            </Link>
          </div>
          {(client.adminLoggedIn() || this.state.userAccessSettings.invitation) &&
            <InviteEmployeeForm>
              <div className="mobile-quick-menu-item">
                <a>
                  <AppIcon icon={solidIcons.InviteSolid} />
                </a>
              </div>
            </InviteEmployeeForm>
          }

          <div className={activeRoute('/reels', pathname) + " mobile-quick-menu-item"}>
            <Link to="/reels">
              <AppIcon icon={solidIcons.Reels} iconClass="reels-icon" />
            </Link>
          </div>

          {/* <Link to="/activity#new_post"> */}
          <div className="mobile-quick-menu-item" id="add-post-reel" onClick={() => this.toggleButtonsModal()}>
            <svg style={{ margin: '0 5px', paddingTop: '4px' }} xmlns="http://www.w3.org/2000/svg" height="32" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path stroke="#676a6c" strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <CreateButtonsModal openModal={this.state.openButtonsModal} onClose={this.toggleButtonsModal} />
          {/* </Link> */}

          {(client.adminLoggedIn() || this.state.userAccessSettings.conversation) &&
            <div className={activeRoute('/conversations', pathname) + " mobile-quick-menu-item"}>
              <Link to="/conversations">
                <AppIcon icon={solidIcons.ChatSolid} />
                {this.state.unreadConversationMessagesSize > 0 &&
                  <span className="top-header-icon-label">{this.state.unreadConversationMessagesSize}</span>
                }
              </Link>
            </div>
          }
          <div className={activeRoute('/notifications', pathname) + " mobile-quick-menu-item"}>
            <Link to="/notifications">
              <AppIcon icon={solidIcons.BellSolid} />
              {this.state.unreadNotificationsSize > 0 &&
                <span className="top-header-icon-label">{this.state.unreadNotificationsSize}</span>
              }
            </Link>
          </div>
          <div className={activeAdminRoute + " mobile-quick-menu-item"}>
            <Link to="/mobile_menu" >
              <AppIcon icon={solidIcons.Menu} />
            </Link>
          </div>
          <ChangePasswordForm
            ref="changePasswordForm"
            forceMode={true}
            title="Please setup your new password"
            onSuccessfulPasswordUpdate={this.handlePasswordUpdate}
          />
        </div>
        {this.state.notificationPromptId !== null &&
          <ReelPrompt ref={this.promptRef} onAction={this.markNotificationsRead} />}
      </>
    )
  }
}

export default MobileFooter
