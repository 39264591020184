// @flow
import React, { Component } from 'react';
import ActionCable  from 'actioncable';
import ActivityTextBox from '../components/activity/ActivityPostForm/ActivityTextBox';
import Config from 'config';
import { Modal, Button, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import Employee from '../models/Employee';
import { Redirect } from 'react-router-dom';
import { client } from '../components/Client';
import { toastrNotification } from '../helpers/Toastr';

const images = [
  {
    src: "img/birthday/bday-card-1.jpg",
    num: 1,
  },
  {
    src: "img/birthday/bday-card-2.jpg",
    num: 2
  },
  {
    src: "img/birthday/bday-card-3.jpg",
    num: 3
  },
  {
    src: "img/birthday/bday-card-4.jpg",
    num: 4
  },
  {
    src: "img/birthday/bday-card-5.jpg",
    num: 5
  }
]  

class ActivityNewBirthdayPostView extends Component {

  state = {
    showModal: true,
    shouldRedirect: false,
    canSubmit: false,
    loading: false,
    employeeSuggestions: []
  }
  
  subscription: ActionCable;
  channelName = "ActivityChannel";

  componentDidMount() {
    ActionCable.debugging = true;
    let _this = this;
    let listener = {
      received(data) {
        if (!client.isLoggedIn()) {
          window.location.hash = '/login';
          return;
        };

        switch(data.action) {
        case 'create_post':
          _this.setState({ shouldRedirect: true, loading: false })
          break;
        }  
        console.log("received", data);
      }
    };  
    client.getActivityDepartmentsList().then((departmentsList) => {
      const defaulDepartment = departmentsList.find(d => d.default);
      window.cable = ActionCable.createConsumer(client.getConsumerUrl());
      this.subscription = window.cable.subscriptions.create({channel: this.channelName, department_id: defaulDepartment.id}, listener);
      this.setState({ canSubmit: true });
    });
    this.getEmployeeSuggestions();
  }
  
  componentWillUnmount() {
    if (this.subscription) {
      setTimeout(() => {console.log('unsubscribed');this.subscription.unsubscribe()}, 100);
    }      
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.employee !== this.state.employee && this.editor) {
      const employee = this.state.employee;
      this.editor.loadContent(`<p><span class="atwho-inserted" data-atwho-at-query="@"><span class="mention-selected" data-mention-id="${employee.id}"><span>${employee.full_name()}</span></span></span>‍&nbsp;</p>`);
      this.editor.focus();
    }
  }
        
  getEmployeeSuggestions() {
    client.getEmployees()
     .then((data) => {
       let suggestions = [];
       data.map((item) => {
         let suggestion = {};
         suggestion.id = item.id.toString();
         suggestion.name = item.full_name();
         suggestion.avatar = item.avatar_image_url();
         if (client.currentUser().id !== item.id) {
           suggestions.push(suggestion);
         }   
         return item;
       });
       const employee = data.find(item => item.id == this.props.match.params.employee_id);
       this.setState({ employeeSuggestions: suggestions, employee: employee, spinnerClass: '' });
      });
  }
                  
  close = () => {
    this.setState({ showModal: false, shouldRedirect: true });
  }
  
  validateMessageBox = (result) => { return }
  
  selectImage = (image) => {
    this.setState({ imageNum: image.num })
  }
  
  getFormData() {
    return { message: this.editor.getContent(),
             recognitions: [],
             images: [],
             videos: [],
             birthday_image_number: this.state.imageNum
           }
  }
  
  sendPost = () => {
    if (!this.editor.isValid()) {
      toastrNotification({success: false, message: "Please write your message"})
    } else {
      this.setState({ loading: true, errorMessage: '' });
      const formData = this.getFormData();
      this.subscription.perform('create_activity_post', formData);
      console.log("sent new post", formData);        
    }  
  }
      
  render() {
    const popover = (
      <Popover id="modal-popover" title="popover">
      </Popover>
    )
    const tooltip = (
      <Tooltip id="modal-tooltip">
      </Tooltip>
    )
        
    if (this.state.shouldRedirect) {
      return (
        <Redirect to='/activity' />
      );
    } else {
      return (
        <div>
          <Modal show={this.state.showModal} 
            onHide={this.close} 
            dialogClassName="" 
          >
              <Modal.Header closeButton> 
                {this.state.employee && 
                  <h3>Send your wishes to {this.state.employee.full_name()} on his birthday!</h3>
                }
              </Modal.Header>
              { !this.state.employee &&
                <div className="sk-spinner sk-spinner-double-bounce">
                  <div className="sk-double-bounce1"></div>
                  <div className="sk-double-bounce2"></div>
                </div>
              }
              <Modal.Body id="birthday-post-wizard" style={{display: this.state.employee ? 'block' : 'none'}}>
                <div className="row">
                  <div className="col-md-12">
                    <ActivityTextBox
                      onRef={ref => (this.editor = ref)}
                      employeeSuggestions={this.props.employeeSuggestions}
                      placeholder="Write you wishes here"
                      validateMessageBox={this.validateMessageBox}
                    />
                  </div>
                </div>
                <br/>
                <div className="row">
                  <div className="col-md-12">
                    <div className="images-container" >
                      { images.map((image) => {
                        return <div key={'img'+image.num} className="image-box main-color" >
                          <img src={image.src} 
                               width="200"
                               className={this.state.imageNum == image.num ? 'selected' : ''}  
                               onClick={(e) => this.selectImage(image)}>
                          </img>
                        </div>
                      }) }
                    </div>   
                  </div>
                </div>  
              </Modal.Body>
              <Modal.Footer>
                <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} onClick={() => this.sendPost()} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Submit</LaddaButton>
                <Button onClick={this.close}>Close</Button>
              </Modal.Footer>
          </Modal>
        </div>  
      );
    }
  }
    
}

export default ActivityNewBirthdayPostView
