// @flow
import React, { Component } from 'react';
import Formsy from 'formsy-react';
import Select from 'react-select';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { rouletteClient } from '../../clients/RouletteClient';
import { toastrNotification } from '../../helpers/Toastr';
import { Modal, Button } from 'react-bootstrap';
import Input from '../form_components/Input';
import DateField from '../form_components/DateField';
import { Checkbox } from 'react-ui-icheck';

// import 'react-select/dist/react-select.css';
import './CreateReward.css';

const rouletteFrequencyOptions = [
  { value: 'onetime',  label: 'One Time' },
  { value: 'weekly',  label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' }
]

class CreateRoulette extends Component {

  state = { 
    showDialog: false,
    canSubmit: false,
    moneyValue: '',
    prizes: [],
    fields: {
      title: null,
      frequency: rouletteFrequencyOptions[0],
      next_date: moment().add(1, 'days').toDate()
    }
  }

  show() {
    this.setState({ showDialog: true });
  }

  close() {
    this.setState({ showDialog: false });
  }
  
  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
    
  handlePrizesChange = (evt, reward_type) => {
    let pzs = []
    if (this.isPrizeSelected(reward_type)) {
      pzs = this.state.prizes.filter(p => p !== reward_type.id)
    } else {
      pzs = this.state.prizes.concat(reward_type.id)
    }
    this.setState({prizes: pzs})
  }  
    
  handleFrequencyChange = (freq) => {
    const fields = this.state.fields;
    fields['frequency'] = freq;
    this.setState({ fields: fields });
  }  
    
  formSubmit = (data) => {
    const rouletteData = {
      roulette_prizes_attributes: this.state.prizes,
      title: this.state.fields.title,
      frequency: this.state.fields.frequency.value,
      next_date: this.state.fields.next_date      
    }
    
    if(rouletteData.roulette_prizes_attributes.length < 3) {
      toastrNotification({success: false, title: 'Please choose at least 3 prizes'});
      return false
    }

    if(rouletteData.roulette_prizes_attributes.length > 8) {
      toastrNotification({success: false, title: 'Please do not choose more than 8 prizes'});
      return false
    }
        
    const _this = this;
    rouletteClient.createRoulette(rouletteData).then((roulette) => {
        if (roulette.id) {
          _this.props.onAddRoulette(roulette);
          toastrNotification({success: true, title: 'New roulette was successfully created'});
          _this.close();
        } else {
          _this.refs.rouletteForm.updateInputsWithError(roulette);
        }
    });
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  }
  
  onDateChange = (field, date) => {
    const fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields: fields });
  }     

  orderedRewards = () => {
    const rewards = this.props.rewardTypes
    if(rewards.length < this.state.prizes.lenght) {
      return ""       
    }
    const titles = this.state.prizes.map(p => {
      const reward = rewards.find(r => r.id === p)
      if (reward) return reward.title
    })
    return titles.join(", ")
  }  

  isPrizeSelected = (prize) => {
    return !!this.state.prizes.find(p => p === prize.id)
  }
  
  render() {
      
      const popover = <Popover id='edit-roulette-tooltip' style={{'zIndex': 10000}}>
        <p>Roulette contains 8 prizes. If you choose 7 or less then we repeat prizes starting from the one you choosed first.</p>
        <p>The current order: {this.orderedRewards()}</p>
      </Popover>;
            
      return (
        <Modal show={this.state.showDialog } onHide={() => this.close()} >
          <Modal.Header closeButton>
            <Modal.Title>New Roulette</Modal.Title>
          </Modal.Header>
          <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="rouletteForm" className="form-horizontal reward-form">
            <Modal.Body>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="title" className="sr-only"></label>
                    <Input
                      name="title"
                      placeholder="Title *"
                      className="form-control"
                      tabIndex="1"
                      value={this.state.fields.title}
                      onChange={this.onInputChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <p>Start Date</p>
                    <label htmlFor="next_date" className="sr-only"></label>
                    <DateField name="next_date" 
                      className="form-control" 
                      placeholder="Start Date *" 
                      minDate={moment().add(1, 'days').toDate()} 
                      maxDate={moment().add(1, 'year').toDate()} 
                      value={this.state.fields.next_date} 
                      onChange={(date) => this.onDateChange('next_date', date)} 
                      required/>
                  </div>

                  <div className="form-group">
                    <p>Frequency</p>
                    <label htmlFor="frequency" className="sr-only"></label>
                    <Select
                      defaultValue={rouletteFrequencyOptions[0]}
                      onChange={this.handleFrequencyChange}
                      options={rouletteFrequencyOptions}
                      clearable={false}
                    />                    
                  </div>

                
                  <div className="form-group">
                    <p>
                      Choose Rewards
                      <span className="pull-right">
                        <OverlayTrigger trigger={['click']}
                          overlay={popover} placement="top"
                          delayShow={300} delayHide={150}
                        >
                          <FontAwesomeIcon icon={faInfoCircle}/>
                        </OverlayTrigger>
                      </span>
                    </p>
                    <div className="scrollable-area col-md-12">
                    { this.props.rewardTypes.map((prize, index) => (
                       <div key={'prize' + index} className="col-xs-6">
                         <label>
                           <Checkbox
                             name='prizes'
                             checkboxClass="icheckbox_square-green"
                             checked={this.isPrizeSelected(prize)}
                             increaseArea="20%"
                             onChange={(evt) => this.handlePrizesChange(evt, prize)}
                           /> &nbsp;
                           {prize.title}
                         </label>
                       </div>
                     ))          
                    }
                    </div>
                  </div>  
                </div>  
              </div>                              
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.close()}>Cancel</Button>
              <Button 
                bsStyle="primary" 
                type="submit"
                disabled={!this.state.canSubmit}
              >Save</Button>
            </Modal.Footer>
          </Formsy>  
        </Modal>
      )
  }
}
export default CreateRoulette;