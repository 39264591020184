// @flow
import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { rewardTypeClient } from '../../clients/RewardTypeClient';
import { toastrNotification } from '../../helpers/Toastr';
import { Modal, Button } from 'react-bootstrap';
import Input from '../form_components/Input';
import Textarea from '../form_components/Textarea';
import EmployeeSelector from '../form_components/EmployeeSelector';
import { Checkbox } from 'react-ui-icheck';

// import 'react-select/dist/react-select.css';
import './CreateReward.css';

class CreateRewardType extends Component {

  state = { 
    showDialog: false,
    canSubmit: false,
    moneyValue: '',
    fields: {
      title: '',
      description: '',
      responsible_person: '',
      unit: '',
      points: '',
      taxable: false,
      redeemable: true
    }
  }

  show() {
    this.setState({ showDialog: true });
  }

  close() {
    this.setState({ showDialog: false });
  }
  
  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
    
  formSubmit = (data) => {
    let rewardTypeData = data;
    rewardTypeData.taxable = this.state.fields.taxable;
    const _this = this;
    rewardTypeClient.createRewardType(rewardTypeData)
    .then((reward_type) => {
        if (reward_type.id) {
          _this.props.onAddNewRewardType(reward_type);
          toastrNotification({success: true, title: 'New reward type was successfully created'});
          _this.close();
        } else {
          _this.refs.rewardForm.updateInputsWithError(reward_type);
        }
    });
  }
    
  resetForm = () => {
     formsy.reset();
  }

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
    if (evt.target.name == "points") {
      const newMoneyValue = evt.target.value / this.props.pointsRatio; 
      const shownMoneyValue = Number.isNaN(newMoneyValue) ? "-" : newMoneyValue
      this.setState({ moneyValue: shownMoneyValue });
    };
  }
  
  onTaxableChange = (evt) => {
    const fields = this.state.fields;
    fields["taxable"] = !this.state.fields.taxable;
    this.setState({ fields: fields });
  }  
  
  onRedeemableChange = (evt) => {
    const fields = this.state.fields;
    fields["redeemable"] = !this.state.fields.redeemable;
    this.setState({ fields: fields });
  }  

  render() {
      
      return (
        <Modal show={this.state.showDialog } onHide={() => this.close()} >
          <Modal.Header closeButton>
            <Modal.Title>New Reward</Modal.Title>
          </Modal.Header>
          <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="rewardForm" className="form-horizontal reward-form">
            <Modal.Body >
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="title" className="sr-only"></label>
                  <Input
                    name="title"
                    placeholder="Title *"
                    className="form-control"
                    tabIndex="1"
                    value={this.state.fields.title}
                    onChange={this.onInputChange}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-12">   
                <div className="form-group">
                  <label htmlFor="description" className="sr-only"></label>
                  <Textarea
                    name="description"
                    placeholder="Description *"
                    className="form-control"
                    tabIndex="2"
                    value={this.state.fields.description}
                    onChange={this.onInputChange}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-12"> 
                <div className="form-group">
                  <label htmlFor="responsible_person" className="sr-only"></label>
                  <EmployeeSelector 
                    placeholder="Responsible person *" 
                    ref="responsiblePersonSelector"
                    name="responsible_person_id"
                    tabIndex="3"
                    required={true}
                  />
                </div>
              </div>
              <div className="row inhouse-reward-inline-fields">
                <div className="col-sm-12">
                  <div className="col-sm-4"> 
                    <div className="form-group unit-field">
                      <label htmlFor="unit" className="sr-only"></label>
                      <Input
                        name="unit"
                        placeholder="Unit *"
                        className="form-control"
                        tabIndex="4"
                        value={this.state.fields.unit}
                        onChange={this.onInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">   
                    <div className="form-group points-field">
                      <label htmlFor="points" className="sr-only"></label>
                      <Input
                        name="points"
                        placeholder="Points *"
                        className="form-control"
                        tabIndex="5"
                        value={this.state.fields.points}
                        onChange={this.onInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">   
                    <div className="form-group">
                      <div className="input-group m-b">
                        <span className="input-group-addon">$</span> 
                        <input type="text" className="form-control" placeholder="Value" value={this.state.moneyValue} disabled/> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 taxable-field">
                  <Checkbox
                    key="taxable"
                    checkboxClass="icheckbox_square-green"
                    increaseArea="20%"
                    label="Taxable"
                    onChange={this.onTaxableChange}
                    checked={this.state.fields.taxable}
                  />
                  <Checkbox
                    key="redeemable"
                    checkboxClass="icheckbox_square-green"
                    increaseArea="20%"
                    label="Redeemable"
                    onChange={this.onRedeemableChange}
                    checked={this.state.fields.redeemable}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.close()}>Cancel</Button>
              <Button 
                bsStyle="primary" 
                type="submit"
                disabled={!this.state.canSubmit}
              >Save</Button>
            </Modal.Footer>
          </Formsy>  
        </Modal>
      )
  }
}
export default CreateRewardType;
