// @flow
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import IBox from '../common/IBox';
import SweetAlert from 'sweetalert-react';
import { toastrNotification } from './../../helpers/Toastr';
import EmployeeShiftRequestForm from './EmployeeShiftRequestForm';
import { shiftRequestClient } from '../../clients/ShiftRequestClient';
import { client } from '../Client';
import EmployeeRequestRow from './EmployeeRequestRow';

import 'sweetalert/dist/sweetalert.css';

class EmployeeRequestList extends Component {

  state = {
    shiftRequests: [],
    employeeDepartments: []
  }
  
  componentDidMount() {
    this.getShiftRequests();
    this.getEmployeeDepartments();
  }
  
  getShiftRequests() {
    shiftRequestClient.getShiftRequests().
    then((requests) => {
      this.setState({ shiftRequests: requests })
    });
  }
  
  getEmployeeDepartments() {
    client.getEmployeeDepartmentsList().then((departments) => {
      const departmentList = departments.map((dep) => ({ value: dep.id, title: dep.title }))
      this.setState({ employeeDepartments: departmentList });
    });
  }
  
  onAddNewRequest = () => {
    this.getShiftRequests();
  }
  
  openModal() {
    this.refs.employeeShiftRequestForm.open();
  }
  
  render() {
    return (
      <div className="ibox">
          <div className="ibox-title">
            <h5>Pick Up Shift Requests</h5>
          </div>
          <div className="ibox-content">
            <div className="row">
              <div className="col-sm-12">
                <button className="btn btn-primary btn-sm pull-right" onClick={() => this.openModal()}><FontAwesomeIcon icon={faPlus}/> New Request</button>
                <EmployeeShiftRequestForm 
                  ref="employeeShiftRequestForm"
                  onAddNewRequest={this.onAddNewRequest}
                  employeeDepartments={this.state.employeeDepartments}
                />
              </div>
            </div>
            <div className="table-responsive project-list">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Group</th>
                    <th>Shift Date</th>
                    <th>Shift Time</th>
                    <th>Applicants Number</th>
                    <th>Picked By</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.shiftRequests.map((shiftRequest, index) => (
                      <EmployeeRequestRow 
                        currentIndex={index+1}
                        shiftRequest={shiftRequest} 
                        key={'sr'+shiftRequest.id}
                      />
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
      </div>    
    )
  }

}


export default EmployeeRequestList;
