import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { acknowledgementClient } from '../../clients/AcknowledgementClient';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { client } from '../Client';
import Select from '../form_components/Select';
import Textarea from '../form_components/Textarea';
import Input from '../form_components/Input';
import DateField from '../form_components/DateField';
import DateFieldLink from '../form_components/DateFieldLink';
import AcknowledgementTextBox from './AcknowledgementTextBox';
import moment from 'moment';
import { toastrNotification } from '../../helpers/Toastr';

class EditAcknowledgementForm extends Component {

  state = {
    showModal: false,
    canSubmit: false,
    errorMessage: '',
    loading: false,
    fields: {
      title: 'Acknowledgement Message',
      message: '',
      expires_at: null,
      university_department_id: -1,
      responded_employees: []
    }
  };
  
  componentDidMount = () => {
    let acknowledgementFields = this.props.acknowledgement;
    acknowledgementFields.responded_employees = [];
    this.setState({ fields: acknowledgementFields })
  }
    
  close = () => {
    this.setState({ showModal: false, errorMessage: '' });
  }

  open = () => {
    acknowledgementClient.getAcknowledgement(this.props.acknowledgement.id).
    then((acknowledgement) => {
      if (acknowledgement.responded_employees.length == 0) {
        let acknowledgementFields = acknowledgement;
        this.setState({ fields: acknowledgementFields, showModal: true, loading: false });
      } else {
        this.props.showUnableToEditNotification();
      }
    });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
    
  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  } 
  
  onDateChange = (field, date) => {
    const fields = this.state.fields;
    fields[field] = date;
    this.setState({ fields: fields });
  }   
      
  formSubmit = (data) => {
    this.setState({ loading: true });
    acknowledgementClient.updateAcknowledgement(this.props.acknowledgement.id, data)
    .then((acknowledgement) => {
        if (acknowledgement.id) {
          this.props.onUpdateAcknowledgement();
          toastrNotification({success: true, title: 'Acknowledgement was successfully updated'});
          this.close();
        } else {
          let formErrors = acknowledgement;
          this.refs.form.updateInputsWithError(formErrors);
          toastrNotification({success: false, title: 'Unable to update acknowledgement'});
          setTimeout(() => {
            this.setState({ loading: false })
          }, 1500)
        }
    });
  }

 render() {
    const tooltip = (
      <Tooltip id="modal-tooltip">
      </Tooltip>
    )

    return (
      <Modal show={this.state.showModal} onHide={this.close} >
        <Formsy onValidSubmit={this.formSubmit} onValid={this.enableButton} onInvalid={this.disableButton} ref="form" className="form-horizontal employee-form">
          <Modal.Header closeButton>
            <Modal.Title>Edit Acknowledgement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.errorMessage.length > 0 && 
            <div className="alert alert-danger">
              {this.state.errorMessage}
            </div>
            }
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="title" className="sr-only"></label>
                <Input 
                  name="title"
                  placeholder="Title *"
                  className="form-control"
                  tabIndex="1"
                  value={this.state.fields.title}
                  onChange={this.onInputChange} 
                  required 
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-12">
                <AcknowledgementTextBox name="message" value={this.state.fields.message} required validations='notEmptyText'/>                
              </div>
            </div>    
            <div className="row">
              <div className="form-group col-sm-12">
                <div className="pull-left">&nbsp; expires on &nbsp;</div>
                <div className="pull-left">
                  <DateField name="expires_at" value={moment(this.state.fields.expires_at).toDate()}
                    minDate={moment().toDate()}                 
                    customInput={<DateFieldLink />}
                    onChange={(date) => this.onDateChange('expires_at', date)}/>
                </div>
              </div>
            </div>             
          </Modal.Body>
          <Modal.Footer>
            <LaddaButton disabled={!this.state.canSubmit} data-style={EXPAND_LEFT} type="submit" loading={this.state.loading} className={'ladda-button btn btn-sm btn-primary no-margins'}>Submit</LaddaButton>
            <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
        </Formsy>
      </Modal>
    );
  }
}

export default EditAcknowledgementForm;
