import React, { Component } from "react";
import Formsy from "formsy-react";
import { client } from "../../components/Client";
import { Button, Modal } from "react-bootstrap";
import Input from "../form_components/Input";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import Welcome from "./Welcome";
class SignupForm extends Component {
  state = {
    signupInProgress: false,
    invalid: false,
    shouldRedirect: false,
    canSubmit: false,
    errorMessage: "",
    loading: false,
    fields: {
      first_name: "",
      last_name: "",
      email: "",
      cell_phone: "",
      passcode: "",
      password: "",
      password_confirmation: "",
    },
  };

  enterAccount = () => {
    this.setState({ shouldRedirect: true });
  };

  formSubmit = (data) => {
    this.disableButton();
    this.setState({ loading: true });
    client.employeeSignup(data).then((response) => {
      if (response.success && client.isLoggedIn()) {
        this.setState({ invalid: false, errorMessage: "" });
        this.enterAccount();
      } else {
        let formErrors = response.errors;
        this.setState({ invalid: true, errorMessage: response.errors.base });
        delete formErrors.base;
        this.refs.form.updateInputsWithError(formErrors);
      }
      this.enableButton();
      this.setState({ loading: false });
    });
  };

  resetForm = () => {
    formsy.reset();
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  onInputChange = (evt) => {
    const fields = this.state.fields;
    fields[evt.target.name] = evt.target.value;
    this.setState({ fields: fields });
  };

  render() {
    if (this.state.shouldRedirect) {
      return (
        <>
          <Redirect to="/welcome" />
        </>
      );
    } else {
      return (
        <div style={{borderRadius: 'inherit'}}>
          {this.state.invalid &&
            this.state.errorMessage &&
            this.state.errorMessage.length > 0 && (
              <div className="alert alert-danger">
                {this.state.errorMessage}
              </div>
            )}
          <div className="ibox-title">
            <img
              src="img/logo2.png"
              height="42"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>
          <div className="ibox-content">
            <Formsy
              onValidSubmit={this.formSubmit}
              onValid={this.enableButton}
              onInvalid={this.disableButton}
              ref="form"
              className="form-horizontal"
            >
              <div className="row">
                <div>
                  <div className="form-group col-sm-6">
                    <label htmlFor="first_name">First Name</label>
                    <Input
                      name="first_name"
                      className="form-control"
                      value={this.state.fields.first_name}
                      onChange={this.onInputChange}
                      required
                    />
                  </div>
                  <div className="form-group col-sm-6">
                    <label htmlFor="last_name">Last Name</label>
                    <Input
                      name="last_name"
                      className="form-control"
                      value={this.state.fields.last_name}
                      onChange={this.onInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12">
                  <label htmlFor="email">Email</label>
                  <Input
                    name="email"
                    className="form-control"
                    value={this.state.fields.email}
                    onChange={this.onInputChange}
                  />
                </div>
                <div className="form-group col-sm-12">
                  <label htmlFor="cell_phone">Mobile Phone</label>
                  <Input
                    name="cell_phone"
                    className="form-control"
                    value={this.state.fields.cell_phone}
                    onChange={this.onInputChange}
                  />
                </div>
                <div className="form-group col-sm-12">
                  <label htmlFor="passcode">Company Passcode</label>
                  <Input
                    name="passcode"
                    className="form-control"
                    value={this.state.fields.passcode}
                    onChange={this.onInputChange}
                    required
                  />
                </div>
                <div>
                  <div className="form-group col-sm-6">
                    <label htmlFor="password">Password</label>
                    <Input
                      type="password"
                      name="password"
                      className="form-control"
                      value={this.state.fields.password}
                      onChange={this.onInputChange}
                      required
                    />
                  </div>
                  <div className="form-group col-sm-6">
                    <label htmlFor="password_confirmation">
                      Confirm Password
                    </label>
                    <Input
                      type="password"
                      name="password_confirmation"
                      className="form-control"
                      validations="equalsField:password"
                      validationError="Password confirmation doesn't match Password"
                      value={this.state.fields.password_confirmation}
                      onChange={this.onInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12">
                  <div className="pull-right" style={{ width: "100%" }}>
                    <LaddaButton
                      style={{ width: "100%" }}
                      disabled={!this.state.canSubmit}
                      data-style={EXPAND_LEFT}
                      type="submit"
                      loading={this.state.loading}
                      className={
                        "ladda-button btn btn-md btn-primary no-margins"
                      }
                    >
                      Signup
                    </LaddaButton>
                    <br />
                    <br />
                    <Link
                      to={"/login"}
                      className="auth-links"
                      style={{
                        paddingLeft: "45%",
                      }}
                    >
                      Back to login
                    </Link>
                  </div>
                </div>
              </div>
            </Formsy>
          </div>
        </div>
      );
    }
  }
}

export default SignupForm;
