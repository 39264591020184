import React, { Component } from "react";
import { Link } from "react-router-dom";
import { client } from "../../components/Client";
import "./forgetPassword.css";

class ForgotPasswordForm extends Component {
  state = {
    invalid: false,
  };

  onFormSubmit = (evt) => {
    evt.preventDefault();
    client.forgotPassword(this.refs.email.value, "desktop").then((response) => {
      if (response.success) {
        this.props.onSubmitEmail();
      } else {
        this.setState({ invalid: true, terminated: response.terminated });
      }
    });
  };

  cancelForgotPasswordForm = () => {
    this.props.onCancel();
  };

  render() {
    return (
      <div style={{ borderRadius: "inherit" }} className="forget-pass-main">
        {this.state.invalid && this.state.terminated && (
          <div
            className="alert alert-danger"
            style={{
              borderTopLeftRadius: "inherit",
              borderTopRightRadius: "inherit",
              marginTop: "0",
            }}
          >
            Your account is terminated.
          </div>
        )}
        {this.state.invalid && !this.state.terminated && (
          <div
            className="alert alert-danger"
            style={{
              borderTopLeftRadius: "inherit",
              borderTopRightRadius: "inherit",
              marginTop: "0",
            }}
          >
            We couldn't find your account with that information.
          </div>
        )}
        <div className="ibox-title">
          <img src="img/logo2.png" height="42" className="forget-logo" />
        </div>
        <div className="ibox-content">
          <form className="form-horizontal" onSubmit={this.onFormSubmit}>
            <div className="form-group">
              <div
                className="col-lg-10 col-lg-offset-1 "
                id="forget-input-main"
              >
                <p>
                  Please enter your email or mobile phone to search for your
                  account.
                </p>
                <br />
                <input
                  placeholder="Email or Mobile Phone"
                  className="form-control"
                  id="forget-input"
                  ref="email"
                  required
                />
                <span className="help-block m-b-none"></span>
              </div>
            </div>
            <div className="form-group">
              <div
                className="col-lg-10 col-lg-offset-1"
                style={{ width: "100%", marginLeft: "0%" }}
              >
                <button
                  className="btn btn-md btn-primary"
                  id="forget-btn"
                  type="submit"
                >
                  Submit
                </button>
                &nbsp;{" "}
                <a onClick={this.cancelForgotPasswordForm} className="auth-links">
                  <center> Back to login</center>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordForm;
