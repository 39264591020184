import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger } from 'react-bootstrap';
import SliderQuestion from './SliderQuestion';  
import ChoiceQuestion from './ChoiceQuestion';
import RatingQuestion from './RatingQuestion';
import FeedbackQuestion from './FeedbackQuestion';

class SurveyQuestionModal extends Component {
    
  state = {
    showModal: false,
    canSubmit: false
  };
            
  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true });
  }
  
  updateCurrentAnswer = (answer) => {
    //do to something
  }
  
  renderQuestion = () => {
    if ( this.props.question.qtype === 'slider' ) {
      return (
        <SliderQuestion question={this.props.question} updateCurrentAnswer={this.updateCurrentAnswer}/>
      ) 
    } else if ( this.props.question.qtype === 'choice' ) {
      return (
        <ChoiceQuestion question={this.props.question} updateCurrentAnswer={this.updateCurrentAnswer}/>
      ) 
    } else if ( this.props.question.qtype === 'rating' ) {
      return (
        <RatingQuestion question={this.props.question} updateCurrentAnswer={this.updateCurrentAnswer}/>
      ) 
    } else if ( this.props.question.qtype === 'feedback' ) {
      return (
        <FeedbackQuestion question={this.props.question} updateCurrentAnswer={this.updateCurrentAnswer}/>
      ) 
    } 
  }  
  
 render() {

    return (
      <div>
        <a onClick={this.open}>
          { this.props.showTruncatedQuestion ? this.props.question.truncated_question : this.props.question.question }
        </a>
        <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Body>
              {this.renderQuestion()}
            </Modal.Body>  
            <Modal.Footer>  
              <Button onClick={this.close}>Close</Button>
            </Modal.Footer>
        </Modal>
      </div>  
    );
  }
}

export default SurveyQuestionModal;
