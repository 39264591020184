import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import Employee from "../models/Employee";
import ActivityPersonalList from "../components/activity/ActivityPersonalList";
import PhotoCropper from "../components/employees/PhotoCropper";
import UploadAttachment from "../components/employees/UploadAttachment";
import WorkFields from "../components/employees/WorkFields";
import AddressFields from "../components/employees/AddressFields";
import AboutMeFields from "../components/employees/AboutMeFields";
import EmergencyFields from "../components/employees/EmergencyFields";
import LicensesTable from "../components/employees/LicensesTable";
import CertificatesTable from "../components/employees/CertificatesTable";
import AttachmentsList from "../components/employees/AttachmentsList";
import ChangePasswordForm from "../components/employees/ChangePasswordForm";
import IBox from "../components/common/IBox";
import ProfileUpdateWidget from "../components/common/ProfileUpdateWidget";

import { client } from "../components/Client";
import { toastrNotification } from "./../helpers/Toastr";
import SweetAlert from "sweetalert-react/lib/SweetAlert";

class ProfileView extends Component {
  state = {
    employee: new Employee({}),
    licenses: [],
    certificates: [],
    spinnerClass: "sk-loading",
    personalEdit: false,
    emergencyEdit: false,
    deleteDialog: false,
    profileCompleteness: {},
  };

  componentDidMount() {
    this.setState(this.state);
    this.getEmployee();
  }

  getEmployee = () => {
    client.getEmployee(client.currentUser().id, true).then((employee) => {
      this.getUserRecords(employee.coggno_email);
      this.setState({
        employee: employee,
        spinnerClass: "",
        profileCompleteness: JSON.parse(employee.profile_completeness),
      });
    });
  };

  reloadEmployee = (employee) => {
    this.setState({
      employee: employee,
      spinnerClass: "",
      profileCompleteness: JSON.parse(employee.profile_completeness),
    });
  };

  getUserRecords = (email) => {
    client.getUserRecords(email).then((data) => {
      this.setState({
        licenses: data.licenses || [],
        certificates: data.certificates || [],
      });
    });
  };

  handleCertificateDownload = (certificate) => {
    client.downloadCertificateUrl(certificate);
  };

  submitData = (data) => {
    client.updateEmployee(client.currentUser().id, data).then((empl) => {
      if (empl.id) {
        let employee = new Employee(empl);
        this.reloadEmployee(employee);
        toastrNotification({ success: true, message: "Updated successfully" });
      } else {
        toastrNotification({ success: false, message: "Unable to update" });
      }
    });
  };

  removeAttachment = (attachment) => {
    if (confirm("Are you sure to remove document?")) {
      client.removeAttachment(attachment).then((stat) => {
        if (stat.success) {
          let empl = Object.assign({}, this.state.employee);
          empl.attachments = empl.attachments.filter(
            (a) => a.id !== attachment.id
          );
          this.setState({ employee: new Employee(empl) });
          toastrNotification({
            success: true,
            title: "Document has been removed",
          });
        } else {
          toastrNotification({
            success: false,
            title: "Unable to remove document",
          });
        }
      });
    }
  };

  updateEmployeeAttachments = () => {
    this.getEmployee();
  };

  updateEmployeePhoto = (updated_employee) => {
    let empl = Object.assign({}, this.state.employee);
    empl.avatar_url = updated_employee.avatar_url;
    $(".current-user-avatar")[0].src = empl.avatar_url;
    client.updateCurrentUserAvatar(empl.avatar_url);
    this.setState({
      employee: new Employee(empl),
      profileCompleteness: JSON.parse(updated_employee.profile_completeness),
    });
  };

  changePersonalFormMode = (mode) => {
    this.setState({ personalEdit: mode });
  };

  changeAboutMeFormMode = (mode) => {
    this.setState({ aboutMeEdit: mode });
  };

  changeEmergencyFormMode = (mode) => {
    this.setState({ emergencyEdit: mode });
  };

  deleteAccount = () => {
    this.setState({ deleteDialog: false }, () => {
      if (client.ownerLoggedIn()) {
        toastrNotification({
          success: true,
          message:
            "We are processing your request Ezzely support team will contact you soon.",
        });
      } else {
        toastrNotification({
          success: true,
          message: "Account Deleted",
        });
      }
      client.removeEmployee(this.state.employee.id);
    });
  };

  render() {
    return (
      <div className={"wrapper wrapper-content " + this.state.spinnerClass}>
        <div className="sk-spinner sk-spinner-double-bounce">
          <div className="sk-double-bounce1"></div>
          <div className="sk-double-bounce2"></div>
        </div>
        <div className="row m-b-lg m-t-lg">
          <div className="col-lg-6 col-lg-offset-3 text-center">
            <div>
              <img
                alt="image"
                src={this.state.employee.avatar_image_url()}
                width="200"
                className="img-circle circle-border"
                alt="profile"
              />
              <PhotoCropper
                employee={this.state.employee}
                onUpdateEmployeePhoto={this.updateEmployeePhoto}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <div className="">
                <div>
                  <h2 className="no-margins">
                    {this.state.employee.full_name()}
                  </h2>
                  <h4>{this.state.employee.age_and_full_position()}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-lg-offset-4">
            {this.state.profileCompleteness.completeness < 100 && (
              <ProfileUpdateWidget
                profileCompleteness={this.state.profileCompleteness}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-lg-offset-3">
            <div className="ibox float-e-margins">
              <div className="ibox-content">
                <Tabs defaultActiveKey={1} animation={false} id="employee-tabs">
                  <Tab eventKey={1} title="Employment">
                    <WorkFields
                      ref="employeeForm"
                      employee={this.state.employee}
                    />
                  </Tab>
                  <Tab eventKey={2} title="Personal/Address">
                    <AddressFields
                      ref="personalForm"
                      employee={this.state.employee}
                      onReloadEmployee={this.reloadEmployee}
                      onChangeMode={this.changePersonalFormMode}
                    />
                    {!this.state.personalEdit && (
                      <div className="modal-footer">
                        <Button
                          bsStyle="primary"
                          bsSize="small"
                          onClick={() => this.refs.personalForm.openEditForm()}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                          &nbsp;Edit
                        </Button>
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey={3} title="About Me">
                    <AboutMeFields
                      ref="aboutMeForm"
                      employee={this.state.employee}
                      onFormSubmit={this.submitData}
                      onChangeMode={this.changeAboutMeFormMode}
                    />
                    {!this.state.aboutMeEdit && (
                      <div className="modal-footer">
                        <Button
                          bsStyle="primary"
                          bsSize="small"
                          onClick={() => this.refs.aboutMeForm.openEditForm()}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                          &nbsp;Edit
                        </Button>
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey={4} title="Emergency Contact">
                    <EmergencyFields
                      ref="emergencyForm"
                      employee={this.state.employee}
                      onFormSubmit={this.submitData}
                      onChangeMode={this.changeEmergencyFormMode}
                    />
                    {!this.state.emergencyEdit && (
                      <div className="modal-footer">
                        <Button
                          bsStyle="primary"
                          bsSize="small"
                          onClick={() => this.refs.emergencyForm.openEditForm()}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                          &nbsp;Edit
                        </Button>
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey={5} title="Certificates">
                    <IBox title="Attempted Courses">
                      <div className="table-responsive">
                        <LicensesTable licenses={this.state.licenses} />
                      </div>
                    </IBox>

                    <IBox title="Certificates">
                      <div className="table-responsive">
                        <CertificatesTable
                          certificates={this.state.certificates}
                        />
                      </div>
                    </IBox>
                  </Tab>
                  <Tab eventKey={6} title="Files">
                    <AttachmentsList
                      employee={this.state.employee}
                      onRemoveAttachment={this.removeAttachment}
                      onUpdateAttachment={this.updateEmployeeAttachments}
                    />
                    <hr />
                    {this.state.employee.attachments.length === 0 && (
                      <div className="text-center">
                        <h2>Upload your first File</h2>
                      </div>
                    )}
                    <UploadAttachment
                      employee={this.state.employee}
                      onFinishUpload={this.getEmployee}
                    />
                  </Tab>
                  <Tab eventKey={7} title="Security">
                    <br />
                    <ChangePasswordForm />
                    <br />
                    <Button
                      bsStyle="primary"
                      bsSize="small"
                      onClick={() => this.setState({ deleteDialog: true })}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                      &nbsp;Delete My Account
                    </Button>
                    <SweetAlert
                      show={this.state.deleteDialog}
                      type={client.ownerLoggedIn() ? "info" : "warning"}
                      title="Are you sure?"
                      showCancelButton
                      text={
                        client.ownerLoggedIn()
                          ? "We will process your request and contact you regarding account deletion."
                          : "This will terminate your current session and remove your account."
                      }
                      onConfirm={() => this.deleteAccount()}
                      onCancel={() => this.setState({ deleteDialog: false })}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-lg-offset-3">
            {this.state.employee.id && (
              <ActivityPersonalList
                ref="personalList"
                channelName={"ActivityChannel"}
                employee={this.state.employee}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileView;
