import React from "react";
import { CloseButton } from "react-bootstrap";
import "./BottomSheet.css";

const SLIDE_IN_UP = "animate__slideInUp";
const SLIDE_OUT_DOWN = "animate__slideOutDown";

class BottomSheet extends React.Component {
  state = {
    openSheet: false,
    animationClass: SLIDE_IN_UP,
  };

  constructor(props){
    super(props);

    if(this.props.hideClose === undefined) this.state.hideClose=false;
    else this.state.hideClose = props.hideClose;
  }

  componentDidUpdate(prevProps, prevState) {
    //Discard Internal State Changes
    if (
      this.state.animationClass !== prevState.animationClass ||
      this.state.openSheet !== prevState.openSheet
    )
      return;

    //Listent to Changes in Props
    if (
      prevProps.openSheet !== this.props.openSheet &&
      this.props.openSheet !== this.state.openSheet
    ) {
      if (this.props.openSheet) this.openSheet();
      else this.closeSheet();
    }
  }

  closeSheet = () => {
    this.setState({ animationClass: SLIDE_OUT_DOWN }, () => {
      setTimeout(
        () =>
          this.setState(
            { openSheet: false },
            () => this.props.onClose && this.props.onClose()
          ),
        this.props.height > 35 ? 700 : 500
      );
    });
  };

  openSheet = () => {
    this.setState({ animationClass: SLIDE_IN_UP }, () =>
      this.setState({ openSheet: true })
    );
  };

  render() {
    if (this.state.openSheet)
      return (
        <div className="bottom-sheet-container">
          <div
            className={
              "bottom-sheet-content animate__animated " +
              this.state.animationClass
            }
            style={{ height: (this.props.height || "40") + "vh" }}
          >
            {this.props.children}
            {!this.state.hideClose && <div className="bottom-sheet-close">
              <CloseButton onClick={() => this.closeSheet()} />
            </div>}
          </div>
        </div>
      );
    else return null;
  }
}

export default BottomSheet;
