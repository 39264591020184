import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import Employee from '../models/Employee';
import EmployeesList from '../components/employees/EmployeesList';
import EmployeeSummary from '../components/employees/EmployeeSummary';
import NewEmployeeForm from '../components/employees/NewEmployeeForm';
import InviteEmployeeForm from '../components/employees/InviteEmployeeForm';
import ImportEmployees from '../components/employees/ImportEmployees';
import ReactPaginate from 'react-paginate';
import { client } from '../components/Client';
import { toastrNotification } from '../helpers/Toastr';


class EmployeesView extends Component {

  state = {
    employees: [],
    cnt: 0,
    selectedEmployee: null,
    page: 1,
    activeTab: 0,
    loading: false,
    query: ""
  }

  activeTabChange() {
    const _this = this;
    return (key) => {
      _this.state.activeTab = key;
      _this.state.page = 1;
      _this.getEmployees(key);
    };
  }

  componentDidMount() {
    this.getEmployees(this.state.activeTab);
  }

  getEmployees = (status = null) => {
    const { page, query } = this.state;
    this.setState({ loading: true });
    (this.state.activeTab === 2 ?
      client.getDeletedEmployees(page, query, status)
      : client.getPaginatedEmployees(page, query, status))
      .then((data) => {
        this.setState({
          employees: data.employees,
          cnt: data.totalCount,
          pageCount: data.totalPages,
          loading: false,
          page: page
        })
      });
  }

  selectEmployee = (employee) => {
    this.refs.employeeSummary.open(employee);
  }

  addNewEmployee = (employee) => {
    toastrNotification({ success: true, message: 'Employee has been added.' })
    this.setState({ page: 1 }, () => {
      this.getEmployees(this.state.activeTab);
    });
  }

  updateEmployeePhoto = (updated_employee) => {
    let employees = this.state.employees
    for (let i in employees) {
      if (employees[i].id === updated_employee.id) {
        employees[i].avatar_url = updated_employee.avatar_url
        break
      }
    }
    this.setState({ employees: employees });
  }

  handleSearch = (evt) => {
    evt.preventDefault();
    const query = this.refs.search.value;
    this.setState({ page: 1, query: query }, () => {
      this.getEmployees(this.state.activeTab);
    });
  }

  handlePageClick = (data) => {
    let page = data.selected + 1;
    this.setState({ page: page }, () => {
      this.getEmployees(this.state.activeTab);
    });
  }

  downloadEmployeesReport = () => {
    client.createStoredReport({}, 'EmployeesStoredReport').then((resp) => {
      if (resp.success) {
        window.ezzely_download(resp.s3_url);
        this.close();
      } else {
        toastrNotification({ success: false, message: ('Unable to generate report: ' + resp.message) })
      }
    })
  }

  renderLoader = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="sk-spinner-loader">
            <div className="sk-spinner sk-spinner-double-bounce">
              <div className="sk-double-bounce1"></div>
              <div className="sk-double-bounce2"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="wrapper wrapper-content animated fadeIn">
        <div className="row">
          <div className="col-sm-12 col-lg-8 col-lg-offset-2">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="pull-left">Employees <small>({this.state.cnt})</small></h2>
                  </div>
                </div>
                {client.adminLoggedIn() &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-actions">
                        <div className="html5buttons">
                          <div className="dt-buttons btn-group employees-page-actions">
                            <InviteEmployeeForm>
                              <button className="btn btn-white btn-outline">
                                <FontAwesomeIcon icon={faPlus} /> Invite
                              </button>
                            </InviteEmployeeForm>
                            <NewEmployeeForm onAddNewEmployee={this.addNewEmployee} />
                            <ImportEmployees onFinishImport={this.getEmployees} />
                            <div>
                              <button onClick={this.downloadEmployeesReport} className="btn btn-white btn-outline">
                                <FontAwesomeIcon icon={faDownload} /> Export
                              </button>
                            </div>
                          </div>
                        </div>
                        <form>
                          <div className="input-group">
                            <input type="text" placeholder="Search " ref="search" className="input form-control" onChange={this.handleSearch} />
                            <span className="input-group-btn">
                              <button type="submit" className="btn btn btn-primary"> <FontAwesomeIcon icon={faSearch} /></button>
                            </span>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                }
                {this.state.loading
                  ? this.renderLoader()
                  : <EmployeesList employees={this.state.employees} onSelectEmployee={this.selectEmployee} activeTab={this.state.activeTab} onTabChange={this.activeTabChange()} />
                }
                {this.state.employees.length > 0 && this.state.pageCount > 1 &&
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        forcePage={this.state.page - 1}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        pageClassName={'paginate_button'}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <EmployeeSummary onUpdateEmployeePhoto={this.updateEmployeePhoto} ref="employeeSummary" />
      </div>

    )
  }

}

export default EmployeesView;
