import React, { Component } from 'react';
import './RedeemDialog.css';
import RewardTypeItem from './RewardTypeItem';
import GiftCardItem from './GiftCardItem';

class RewardTypeList extends Component {
  
  isEnoughPoints() {
    return (this.props.cheapestRewardType !== null && this.props.employeePoints >= this.props.cheapestRewardType.points)
  }
  
  renderRewardType(rewardType) {
    if (rewardType.title === 'Gift Card') {
      return (
        <GiftCardItem
          key={'rti' + rewardType.id}
          rewardType={rewardType}
          employeePoints={this.props.employeePoints}
          selectedRewardsType={this.props.selectedRewardsType}
          onRewardsTypeSelect={this.props.onRewardsTypeSelect}
        />
      )
    } else {
      return (
        <RewardTypeItem
          key={'rti' + rewardType.id}
          rewardType={rewardType}
          employeePoints={this.props.employeePoints}
          selectedRewardsType={this.props.selectedRewardsType}
          onRewardsTypeSelect={this.props.onRewardsTypeSelect}
        />
      )
    }
  }

  render() {
    return (
        <div className="ibox" style={{border: 'none'}}>
        { this.isEnoughPoints() && 
          <div>
            <h2 className='m-t-xs'>Congrats! You're ready to redeem your reward! </h2>
            <p><b>You have {this.props.employeePoints} points</b></p>
          </div>
        }
        { !this.isEnoughPoints() &&
          <div>
            <p><b>You {"don't"} have enough points yet. Minimum {this.props.cheapestRewardType.points} points needed to redeem a {this.props.cheapestRewardType.title}.</b></p>
            <br/>
          </div> 
        }              
          {
            this.props.rewardTypes.map((rewardType) => (
              this.renderRewardType(rewardType)
            ))
          }
        </div>             
    );

  }

}

export default RewardTypeList;
