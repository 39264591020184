// @flow
import React, { Component } from 'react';
import { client } from '../../Client';
import Config from 'config';
import { toastrNotification } from './../../../helpers/Toastr';
import DropzoneComponent from 'react-dropzone-component';
import RSVP from '../../../../public/vendor/frame-grab/lib/rsvp';
import frameGrab from '../../../../public/vendor/frame-grab/frame-grab';

import "./ActivityPostAttachments.css";

class ActivityPostAttachments extends Component {

  state = {
    videos: [],
    images: []
  }

  constructor(props) {
    super(props);
    this.clickableRef = React.createRef();
  }

  openFileInputDialog() {
    this.clickableRef.current?.click();
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  componentWillUnmount() {
    if (this.props.onRef) this.props.onRef(undefined);
  }

  getVideos = () => {
    return this.state.videos;
  }

  getImages = () => {
    return this.state.images;
  }

  clear = () => {
    this.setState({ images: [], videos: [] });
    this.refs.dropzone.dropzone.removeAllFiles(true)
    $(".dz-preview").remove();
  }

  getUploadedContentState = () => {
    return this.refs.dropzone.dropzone;
  }

  contentUploaded() {
    return (this.state.videos.length > 0 || this.state.images.length > 0)
  }

  loadContent = (attachments) => {
    if (attachments.length === 0) return;
    let images = [];
    let videos = [];
    let dropzone = this.refs.dropzone.dropzone;
    attachments.forEach((attachment) => {
      if (attachment.media_type === 'image') {
        images.push(attachment.id)
      } else {
        videos.push(attachment.id)
      };
      const mockFile = { name: attachment.filename, attachment_id: attachment.id, media_type: attachment.media_type };
      dropzone.emit("addedfile", mockFile);
      dropzone.emit("thumbnail", mockFile, attachment.thumb_url);
      dropzone.emit("complete", mockFile);
    })
    this.setState({ images: images, videos: videos })
  }

  render() {
    let componentConfig = {
      showFiletypeIcon: true,
      postUrl: Config.API_BASE + '/activity_post_attachments'
    };

    let djsConfig = {
      addRemoveLinks: true,
      acceptedFiles: 'image/*,application/vnd.apple.mpegurl,application/x-mpegurl, video/3gpp, video/mp4, video/mpeg, video/ogg, video/quicktime, video/webm, video/x-m4v, video/ms-asf, video/x-ms-wmv, video/x-msvideo',
      clickable: ['.add-post-photo-btn'],
      dictDefaultMessage: '',
      maxFiles: 10,
      maxFilesize: 100, //MB
      timeout: 3600000,
      method: 'put',
      headers: { 'Content-Type': 'multipart/form-data', 'Access-Control-Allow-Origin': '*' },
      accept: function (file, done) {
        if ((/image/i).test(file.type)) {
          file.uploadUrl = Config.API_BASE + '/activity_post_attachments';
          file.method = 'post';
          file.headers = { 'X-Client-Version': client.getClientVersion(), 'X-App-Version': client.getAppVersion(), 'Authorization': client.getAuthorizationHeader() };
          done();
        } else {
          if ((/video/i).test(file.type)) {
            client.getActivityPostVideoPresignedUrl(file.upload.filename)
              .then((data) => {
                file.uploadUrl = data.signedUrl;
                file.method = 'put';
                file.headers = { 'Content-Type': 'multipart/form-data', 'Access-Control-Allow-Origin': '*' };
                file.attachment_id = data.attachment_id;
                done();
              })
          } else {
            done('Unable to upload. Use image or video only.')
          }
        }
      }
    }

    let eventHandlers = {
      success: (file, response) => {
        if (file.previewElement) {
          if (response.media_type == "image") {
            let images = this.state.images;
            this.setState({ images: images.concat(response.attachment_id) });
            file.attachment_id = response.attachment_id;
            file.media_type = response.media_type;
          } else {
            if (file.attachment_id) {
              let videos = this.state.videos;
              this.setState({ videos: videos.concat(file.attachment_id) });
              file.media_type = 'video';
            }
          };
          this.props.validateAttachmentsBox(this.contentUploaded());
          return file.previewElement.classList.add("dz-success");
        }
      },

      error: (file, errorMessage) => {
        if (file.attachment_id && file.media_type == 'video' && this.contentUploaded()) {
          client.removeActivityPostAttachment(file.attachment_id, 'video');
          let videos = this.state.videos;
          let video_index = videos.indexOf(file.attachment_id);
          if (video_index > -1) {
            videos.splice(video_index, 1);
          };
          this.setState({ videos: videos });
          this.props.validateAttachmentsBox(this.contentUploaded());
        }
      },

      addedfile: (file) => {
        if ((/video\//i).test(file.type)) {
          FrameGrab.blob_to_video(file).then(
            function videoRendered(videoEl) {
              let frameGrab = new FrameGrab({ video: videoEl });
              let imgEl = file.previewElement.querySelector("img");
              frameGrab.grab(imgEl, 1, 220).then(
                function frameGrabbed(img) { },
                function frameFailedToGrab(reason) {
                  console.log("Can't grab the video frame from file: " + file.name + ". Reason: " + reason);
                }
              );
            },
            function videoFailedToRender(reason) {
              console.log("Can't convert the file to a video element: " + file.name + ". Reason: " + reason);
            }
          );
        }
      },

      removedfile: (file) => {
        if (file.attachment_id && file.media_type && this.contentUploaded()) {
          if (!this.props.editPostMode) {
            client.removeActivityPostAttachment(file.attachment_id, file.media_type);
          }
          if (file.media_type == "image") {
            let images = this.state.images;
            let image_index = images.indexOf(file.attachment_id);
            if (image_index > -1) {
              images.splice(image_index, 1);
            };
            this.setState({ images: images });
          } else {
            let videos = this.state.videos;
            let video_index = videos.indexOf(file.attachment_id);
            if (video_index > -1) {
              videos.splice(video_index, 1);
            };
            this.setState({ videos: videos });
          };
          this.props.validateAttachmentsBox(this.contentUploaded());
        }
      },

      queuecomplete: () => {
        this.props.enablePostForm();
      },

      canceled: (file) => {
        this.props.enablePostForm();
      },

      processing: (file) => {
        this.props.disablePostForm();
        this.refs.dropzone.dropzone.options.url = file.uploadUrl;
        this.refs.dropzone.dropzone.options.method = file.method;
        this.refs.dropzone.dropzone.options.headers = file.headers;
      },

      sending: (file, xhr) => {
        //override send method for video files 
        if ((/video\//i).test(file.type)) {
          let _send = xhr.send;
          xhr.send = () => {
            _send.call(xhr, file)
          }
        }
      }
    }

    return (
      <div>
        <div ref={this.clickableRef} className='add-post-photo-btn' style={{ display: 'none' }}></div>
        <DropzoneComponent ref="dropzone" config={componentConfig} eventHandlers={eventHandlers} djsConfig={djsConfig} />
      </div>
    );
  }
}

export default ActivityPostAttachments;
