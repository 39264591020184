import React, { Component } from 'react';
import SweetAlert from 'sweetalert-react';

import 'sweetalert/dist/sweetalert.css';

class RemovePostLink extends Component {

  state = {
    show: false,
    text: 'This operation can not be undone.'
  }

  handleClick() {
    const {recognitions_number, rewards_number} = this.props.post
    if (recognitions_number === 0 && rewards_number) {
      this.setState({ show: true })
    } else {
      //swal("Error!", "Unable to delete post with associated rewards.", "error");
      this.setState({ show: true, text: this.state.text+' All recognitions & rewards will be reverted!' })
    }
  }

  handlePostRemove = () => {
    this.props.onRemovePost();
    this.setState({ show: false });
  }

  render() {
      return (
        <li>
          <a className="text-danger" onClick={() => this.handleClick()}>Delete</a>
          <SweetAlert
            show={this.state.show}
            type="warning"
            title="Are you sure?"
            confirmButtonColor="#DD6B55"
            showCancelButton
            text={this.state.text}
            onConfirm={() => this.handlePostRemove()}
            onCancel={() => this.setState({ show: false })}
          />
        </li>
      )
  }

}

export default RemovePostLink;
