// @flow
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { conversationMessageClient } from '../../clients/ConversationMessageClient';
import { client } from '../Client';
import moment from 'moment';
import Config from 'config';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import Employee from '../../models/Employee';
import Message from '../../models/ConversationMessage';
import ConversationMessage from './ConversationMessage';
import { Button } from 'react-bootstrap';
import { toastrNotification } from './../../helpers/Toastr';
import SweetAlert from 'sweetalert-react';
import MessageForm from './MessageForm';
import { Element, animateScroll as scroll, scroller } from 'react-scroll';

import 'sweetalert/dist/sweetalert.css';
import Media from 'react-media';

class ConversationMessagesList extends Component {

  state = {
    messages: [],
    page: 1,
    isLoading: false,
    messagesEnded: false
  }

  componentDidMount() {
    let _this = this;

    this.loadMoreMessages();

    scroll.scrollToBottom();

    $(window).bind("load resize", function () {
      _this.updateElementsWidth();
    });

    _this.updateElementsWidth();

    document.addEventListener('keydown', this.keydownHandler);
  }

  keydownHandler = (e) => {
    if (e.keyCode === 13 && e.ctrlKey) this.sendMessage()
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keydownHandler);
  }

  updateElementsWidth() {
    let width = $('.conversation-page-body-container').width();
    $('.conversation-page-footer').css("width", width);
    $('.conversation-page-header').css("width", width);
    $('.chat-discussion').css("width", width);
  }

  addNewMessage(data) {
    if (data.success == true) {
      const messageData = JSON.parse(data.message);
      const newMessage = new Message(messageData)
      this.setState({ messages: this.state.messages.concat([newMessage]) });
      if (this.refs.messageForm !== undefined) { this.refs.messageForm.reset() }
    } else {
      if (data.author_id === client.currentUser().id) {
        toastrNotification({ success: false, message: data.errors });
      }
    }
  }

  sendMessage = () => {
    if (this.refs.messageForm.isValid()) {
      const formData = this.refs.messageForm.getFormData();
      this.props.subscription.perform('create_conversation_message', formData);
      this.refs.messageForm.closeEmojiPicker();
      scroll.scrollToBottom({ containerId: "chatContainerElement" });
      this.refs.messageForm.focus();
    } else {
      this.refs.messageForm.focus();
      return false
    }
  }

  loadMoreMessages() {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true });
    const topMessage = this.state.messages[0];
    conversationMessageClient.getConversationMessages(this.state.page, this.props.conversation.id)
      .then((messages) => {
        const messagesEnded = messages.length > 0 ? false : true;
        this.setState({ messages: messages.concat(this.state.messages), page: this.state.page += 1, isLoading: false, messagesEnded: messagesEnded });

        if (topMessage && this.state.page !== 1) {
          scroller.scrollTo('message' + topMessage.id, {})
        };
      });
  }

  renderWaypoint = () => {
    if (!this.state.messagesEnded) {
      return (
        <Waypoint
          onEnter={this.loadMoreMessages.bind(this)}
          bottomOffset="100px"
          scrollableAncestor={window}
        />
      )
    }
  }

  renderLoader = () => {
    if (this.state.isLoading && !this.state.messagesEnded) {
      return (
        <div className="sk-spinner sk-spinner-double-bounce">
          <div className="sk-double-bounce1"></div>
          <div className="sk-double-bounce2"></div>
        </div>
      )
    }
  }


  render() {
    const conversation = this.props.conversation;

    return (
      <div className="ibox conversation-view" id="conversationMessagesList">
        <div className="conversation-page-header">
          <div className="row">
            <div className="col-md-12">
              <div className="conversation-page-header-inner">
                <div className="return-link">
                  <Media query="(max-width: 768px)" render={() =>
                    <Link to="/conversations" >
                      <FontAwesomeIcon icon={faChevronLeft} /> To conversations
                    </Link>
                  } />
                </div>
                <div className="recipient-info">
                  {conversation.interlocutor.full_name}
                  {conversation.interlocutor.is_online &&
                    <small><br />online</small>
                  }
                </div>
                <div className="recipient-avatar">
                  <div className="client-avatar">
                    <img alt="image" src={conversation.interlocutor.avatar_url} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row conversation-page-body">
          <div className="col-md-12 conversation-page-body-container">
            <div className="conversation-page-body-inner">
              <div className="chat-discussion">
                {this.renderLoader()}
                {this.renderWaypoint()}
                {
                  this.state.messages.map((message, index) => {
                    const prevMessage = this.state.messages[index - 1];
                    const showDate = prevMessage ? (moment(message.created_at).day() !== moment(prevMessage.created_at).day()) : true;
                    return <Element name={'message' + message.id} key={'convMessage' + message.id} className="element">
                      {showDate &&
                        <div className="date-informer">{moment(message.created_at).format("MM/DD/YYYY")}</div>
                      }
                      <ConversationMessage
                        message={message}
                        channel={this.props.subscription}
                      />
                    </Element>
                  })
                }
              </div>
            </div>
          </div>
        </div>
        <MessageForm
          sendMessage={this.sendMessage}
          ref="messageForm"
        />
      </div>
    )
  }

}

export default ConversationMessagesList;
