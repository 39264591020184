import Config from 'config';
import moment from 'moment';
import Employee from './Employee';
import { client } from '../components/Client';

class ActivityPost {
  constructor(obj) {
    for (var prop in obj) {
      if(prop === 'recognitions' || prop === 'likes' || prop === 'high_fives') { // use Employee type
        for (var p in obj[prop]) {
          obj[prop][p].employee = new Employee(obj[prop][p].employee);
        }
      }
      this[prop] = obj[prop];
    }
  }
  
  full_name() {
    return this.author.first_name + ' ' + this.author.last_name;
  }
  
  avatar_image_url() {
    const url = (this.author.avatar_url) ? this.author.avatar_url : 'img/avatar_missing.png'
    return url
  }
  
  created_date() {
    let date = moment.parseZone(this.created_at).format('MMMM DD')
    return date
  }
  
  authorLink() {
    const link = (this.author.email === client.currentUser().email) ? `/profile` : `/employee/${this.author.id}` 
    return link
  }

}

export default ActivityPost;
