// @flow
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { conversationClient } from '../../clients/ConversationClient';
import { client } from '../Client';
import Config from 'config';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { Button } from 'react-bootstrap';
import { toastrNotification } from './../../helpers/Toastr';
import SweetAlert from 'sweetalert-react';
import ConversationRow from './ConversationRow';
import NewConversationModal from './NewConversationModal';

import 'sweetalert/dist/sweetalert.css';

class ConversationsList extends Component {

  state = {
    conversations: [],
    page: 1,
    isLoading: false,
    conversationsEnded: false,
    search: ""
  }

  loadMoreConversations() {
    this.setState({ isLoading: true });
    conversationClient.getConversations(this.state.page, this.state.search)
    .then((conversations) => {
        const conversationsEnded = conversations.length > 0 ? false : true;
        this.setState({ conversations: this.state.conversations.concat(conversations), page: this.state.page += 1, isLoading: false, conversationsEnded: conversationsEnded });
     });
  }

  renderWaypoint = () => {
    if (!this.state.conversationsEnded) {
      return (
        <Waypoint
          onEnter={this.loadMoreConversations.bind(this)}
          scrollableAncestor={window}
        />
      )
    }
  }
  
  updateConversation = (data) => {
    const conversation = JSON.parse(data.conversation);
    let isNewConversation = true;
    let modifiedConversations = this.state.conversations.map((c) => {
        if (c.id === conversation.id) {
          isNewConversation = false;
          return conversation;
        } else {
          return c;
        }  
    });
    if (isNewConversation) {
      modifiedConversations = [conversation].concat(modifiedConversations);
    };
    modifiedConversations.sort((a,b) => (b.unread_messages_size > a.unread_messages_size) ? 1 : ((a.unread_messages_size > b.unread_messages_size) ? -1 : 0));         
    this.setState({ conversations: modifiedConversations });
  }
  
  renderLoader = () => {
    if (this.state.isLoading && !this.state.conversationsEnded) {
      return (
        <div className="sk-spinner sk-spinner-double-bounce">
          <div className="sk-double-bounce1"></div>
          <div className="sk-double-bounce2"></div>
        </div>
      )
    }
  }

  handleSearch = (evt) => {
    evt.preventDefault();
    this.setState({ isLoading: true, search: this.refs.search.value });
    conversationClient.getConversations(1, this.refs.search.value)
    .then((conversations) => {
        this.setState({ conversations: conversations, isLoading: false, page: 2, conversationsEnded: false });
     });
  }

  openNewConversationModal = () => {
    this.refs.newConversationModal.open()
  }

  render() {

      return (
            <div className="ibox">
                <div className="ibox-title">
                  <h5>Conversations</h5>
                  <div className="ibox-tools">
                    <button className="btn btn-primary btn-sm" onClick={() => this.openNewConversationModal()}><FontAwesomeIcon icon={faPlus}/> New Conversation</button>
                    <NewConversationModal ref="newConversationModal" />
                  </div>  
                </div>
                <div className="ibox-content">
                    <div className="conversations-list">
                      {
                       this.state.conversations.map((conversation) => {
                         return <ConversationRow
                            key={'conversation'+conversation.id}
                            conversation={conversation}
                         />
                       })
                      }
                      {this.renderWaypoint()}
                      {this.renderLoader()}
                    </div>
                </div>
            </div>
      )
    }

}

export default ConversationsList;
