// @flow
import React, { Component } from 'react';
import ActivityTextBox from '../activity/ActivityPostForm/ActivityTextBox';
import { client } from '../Client';
import Config from 'config';
import { Button, Dropdown, MenuItem } from 'react-bootstrap';
import { toastrNotification } from '../../helpers/Toastr';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

class MessageForm extends Component {

  state = {
    canSubmit: true,
    messageBoxIsValid: true,
    showEmojiPicker: false
  }

  validateMessageBox = (result) => {
    this.setState({ messageBoxIsValid: result })
  }

  isValid = () => {
    const content = this.refs.editor.getContent();
    return (content.length >= 1 && content !== "<br><br>")
  }

  reset = () =>  {
    this.refs.editor.clear();
    this.setState({ canSubmit: true });
  }

  getFormData() {
    return { message: this.refs.editor.getContent() };
  }

  disableForm = () => {
    this.setState({ canSubmit: false });
  }

  enableForm = () => {
    this.setState({ canSubmit: true });
  }

  addEmoji = (emoji, event) => {
    this.refs.editor.addEmoji(emoji);
  }

  toggleEmojiPicker = () => {
    this.focus();
    this.setState({ showEmojiPicker: !this.state.showEmojiPicker });
  }

  closeEmojiPicker = () => {
    this.setState({ showEmojiPicker: false });
  }
  
  focus = () => {
    this.refs.editor.focus();
  }

  render() {
    let emojiLinkImage = this.state.showEmojiPicker ? "img/emoji-icon-active.png" : "img/emoji-icon.png";

    const emojiPicker = (
      <div className="conv-chat-emoji-picker" style={{padding:'6px 12px'}}>
        <div className="hidden-xs">
          <Picker
            set='emojione'
            onClick={this.addEmoji}
            style={{ position: 'absolute', bottom: '45px', zIndex: "1", display: this.state.showEmojiPicker ? "block" : "none",padding: '6px' }}
            />
        </div>
        <img src={emojiLinkImage} onClick={this.toggleEmojiPicker} />
      </div>
    )

    const messageBox = (
      <div className="form-group conv-chat-message-box">
        <ActivityTextBox
          ref="editor"
          placeholder="Write your message here"
          validateMessageBox={this.validateMessageBox}
          closeEmojiPicker={this.closeEmojiPicker}
          focusAfterLoad={true}
        />
      </div>
    )

    const buttons = (
      <div className="conv-chat-send-btn">
        <Button
          className="pull-right send-button"
          disabled={!this.state.canSubmit}
          onClick={() => this.props.sendMessage()}
        >
          <img src="img/send_icon.png"/>
        </Button>
      </div>
    )

    return (
        <div className="conversation-page-footer">
          <div className="row">
            <div className="col-md-12">
              <div className="chat-message-form">
                <div className="chat-message-form-container">
                  { emojiPicker }
                  { messageBox }
                  { buttons }
                </div>
              </div>
              <div className="hidden-lg hidden-md hidden-sm">
                <Picker
                  set='emojione'
                  onClick={this.addEmoji}
                  style={{ width: "100%", border: "none", display: this.state.showEmojiPicker ? "block" : "none"  }}
                />
              </div>
            </div>
          </div>
        </div>
    );
  }

}

export default MessageForm;
