class RewardType {
  constructor(obj) {
    for (var prop in obj) {
      this[prop] = obj[prop]; 
    }
  }
  
  type() {
    return (this.kind === 0 ? 'MONETARY' : 'IN-HOUSE' )
  }
  
  responsiblePersonAvatar() {
    return (this.responsible_person.avatar_url ? this.responsible_person.avatar_url : 'img/avatar_missing.png')
  }
  
}

export default RewardType;
