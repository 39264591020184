//@flow
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { client } from '../Client';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IBox from '../common/IBox';
import SweetAlert from 'sweetalert-react';
import Employee from '../../models/Employee';
import { toastrNotification } from './../../helpers/Toastr';
import NumericInput from 'react-numeric-input';
import {Checkbox, Radio, RadioGroup} from 'react-ui-icheck';
import MultipleEmployeeSelector from './MultipleEmployeeSelector';
import MultipleGroupSelector from '../common/MultipleGroupSelector';
import MultipleGroupLimitsSelector from '../common/MultipleGroupLimitsSelector';

import 'sweetalert/dist/sweetalert.css';

class PointsAllocationSettings extends Component {

  state = {
    editMode: false,
    pointsRatio: null,
    allocationMode: null,
    pointsRatioDisabled: true,
    employeePositions: [],
    positionCheckboxes: {},
    allowedPositions: [],
    allowedEmployees: [],
    allowedGroups: [],
    limitedGroups: [],
    employees: [],
    pointsLimit: 0,
  }
  
  componentDidMount() {
    this.getAllocationSettings();
    this.getEmployeePositions();
  }
  
  getAllocationSettings = () => {
    client.getUniversityAllocationSettings()
    .then((settings) => {
      let positionCheckboxes = {};
      settings.allowed_positions.map((position) => positionCheckboxes[position] = true );
      this.setState({ pointsRatio: settings.points_ratio,
                      allocationMode: settings.allocation_mode, 
                      allowedPositions: settings.allowed_positions,
                      allowedEmployees: settings.allowed_employees,
                      allowedGroups: settings.allowed_departments,
                      limitedGroups: settings.limited_departments,
                      positionCheckboxes: positionCheckboxes,
                      pointsRatioDisabled: settings.points_ratio_disabled,
                      pointsLimit: settings.points_limit
                     });                                  
    });
  }
  
  getEmployeePositions = () => {
    client.getEmployeePositions()
    .then((positions) => {
      this.setState({ employeePositions: positions })
     });    
  }
    
  openEditForm = () => {
    this.setState({ editMode: true });
  }
    
  closeEditForm = () => {
    this.setState({ editMode: false });
  }
  
  handleSubmit = (evt) => {
    evt.preventDefault();
    const allowedEmployeesIds = this.state.allowedEmployees.map((e) => e.id);
    const allowedDepartmentIds = this.state.allowedGroups.map((g) => g.id);
    let data =  { allocation_mode: this.state.allocationMode, points_limit: this.state.pointsLimit };
    if (!this.state.pointsRatioDisabled) {
      data.points_ratio = this.state.pointsRatio
    };
    if (this.state.allocationMode == "2") {
      data.allowed_positions = this.state.allowedPositions
    };       
    if (this.state.allocationMode == "3") {
      data.allowed_employees = allowedEmployeesIds
    };
    if (this.state.allocationMode == "4") {
      data.allowed_departments = allowedDepartmentIds
    };
    if (this.state.allocationMode == "5") {
      data.allowed_departments = allowedDepartmentIds
      data.limited_departments = this.state.limitedGroups
    };
    client.updateUniversityAllocationSettings(data).then((response) => {
      if (response.success) {
        this.props.updatePointsRatio();
        this.closeEditForm();
        toastrNotification({success: true, message:'Allocation setttings were successfully updated'});
      } else {
        toastrNotification({success: false, message: ('Unable to update company allocation settings') })
      }
    });
  }
  
  onRatioChange(newRation) {
    this.setState({ pointsRatio: newRation });
  }
  
  onPointsLimitChange = (newPointsLimit) => {
    this.setState({ pointsLimit: newPointsLimit });
  }
  
  onAllocationModeChange(ev, newValue) {
    this.setState({ allocationMode: newValue });
  } 
  
  onSelectAllowedEmployees = (newAllowedEmployees) => {
    this.setState({ allowedEmployees: newAllowedEmployees });
  }
  
  onSelectAllowedGroups = (newAllowedGroups) => {
    this.setState({ allowedGroups: newAllowedGroups });
  }
  
  onAddLimitedGroup = (group, points_limit) => {
    const lg = this.state.limitedGroups.find((x) => (x.id == group.id))
    if (lg) {
      this.onUpdateLimitedGroups(group, points_limit)
    } else {      
      this.setState({limitedGroups: this.state.limitedGroups.concat({id: group.id, points_limit: points_limit})});    
    }
  }
  
  onUpdateLimitedGroups = (group, limit) => {
    const limitedGroups = this.state.limitedGroups.map((x) => {
      if (x.id === group.id) {
        return {id: group.id, points_limit: limit}
      } else {
        return x
      }      
    })
    this.setState({limitedGroups: limitedGroups});
  }
  
  humanizeAllocationMode() {
    if (!this.state.allocationMode) {return ""};
    switch (this.state.allocationMode.toString()) 
       {
           case "1": return ("Equal to All");
           case "2": return ("Based on employee position");
           case "3": return ("To selected employees");
           case "4": return ("To the members of selected groups");
           case "5": return ("To the members of selected groups using different limits");
       } 
  }
  
  onPositionChange(position, evt) {
    let nextAllowedPositions = [];
    let checkboxes = this.state.positionCheckboxes;
    let allowedPositions = this.state.allowedPositions;
    const checkboxState = !this.state.positionCheckboxes[position];
    if (checkboxState) {
      nextAllowedPositions= allowedPositions.concat(position)
    } else { 
      nextAllowedPositions = allowedPositions.filter(p => p !== position)
    }
    const chks = Object.assign({}, checkboxes, {[position]: checkboxState });
    this.setState({ allowedPositions: nextAllowedPositions, positionCheckboxes: chks });
  }
    
  render() {
    if (this.state.editMode) {
      return (
        <div className="ibox" id="pointsAllocationSettings">
            <div className="ibox-title">
              <h5>Points Allocation Settings</h5>
            </div>
            <div className="ibox-content">
              <div className="form-horizontal">
                <ul className="list-unstyled"> 
                  { !this.state.pointsRatioDisabled && 
                  <li className="form-group">
                    <div className="col-sm-2 control-label"><label>Each Dollar</label></div>
                    <div className="col-sm-10">
                      <NumericInput 
                        min={1} 
                        value={this.state.pointsRatio}
                        onChange={(val) => this.onRatioChange(val)} 
                        className="form-control" 
                      />
                      Points
                    </div>  
                  </li> }
                  <li className="form-group">
                    <div className="col-sm-2 control-label"><label>Allocate points to employee</label></div>
                    <div className="col-sm-10 allocation-types">
                      <RadioGroup name="radio" value={this.state.allocationMode ? this.state.allocationMode.toString() : ""} onChange={this.onAllocationModeChange.bind(this)}>
                        <Radio
                          value="1"
                          radioClass="iradio_square-green"
                          increaseArea="20%"
                          label="Equal to All"
                        />
                        <Radio
                          value="2"
                          radioClass="iradio_square-green"
                          increaseArea="20%"
                          label="Based on employee position"
                        />
                        <Radio
                          value="4"
                          radioClass="iradio_square-green"
                          increaseArea="20%"
                          label="To the members of selected groups"
                        />
                        <Radio
                          value="5"
                          radioClass="iradio_square-green"
                          increaseArea="20%"
                          label="To the members of selected groups using different limits"
                        />
                        <Radio
                          value="3"
                          radioClass="iradio_square-green"
                          increaseArea="20%"
                          label="To selected employees"
                        />
                      </RadioGroup>
                    </div>  
                  </li>
                  { (this.state.allocationMode == "2") &&
                    <li className="form-group">
                      <div className="col-sm-2 control-label"><label>Employee positions</label></div>
                      <div className="col-sm-10 employee-position-list">
                        {this.state.employeePositions.map((position) => (
                          <Checkbox
                            key={position.title}
                            checkboxClass="icheckbox_square-green"
                            checked={this.state.positionCheckboxes[position.title]}
                            increaseArea="20%"
                            label={`${position.title} <span class="badge">${position.count}</span>`}
                            onChange={(evt) => this.onPositionChange(position.title, evt)}
                          />
                        ))}
                      </div>
                    </li>
                  }
                  { (this.state.allocationMode == "3") &&
                    <li className="form-group">
                      <div className="col-sm-2 control-label"><label>Employees list</label></div>
                      <div className="col-sm-10 employees-list">
                        <MultipleEmployeeSelector 
                          selectedEmployees={this.state.allowedEmployees}
                          selectAllowedEmployees={this.onSelectAllowedEmployees}
                        />
                      </div>
                    </li>
                  }
                  { (this.state.allocationMode == "4") &&
                    <li className="form-group">
                      <div className="col-sm-2 control-label"><label>Groups list</label></div>
                      <div className="col-sm-10 employees-list">
                        <MultipleGroupSelector 
                          selectedGroups={this.state.allowedGroups}
                          selectGroups={this.onSelectAllowedGroups}
                          placeholder="Select groups"
                        />
                      </div>
                    </li>
                  }
                  { (this.state.allocationMode == "5") &&
                    <li className="form-group">
                      <div className="col-sm-2 control-label"><label>Groups list</label></div>
                      <div className="col-sm-10 employees-list">
                        <MultipleGroupLimitsSelector 
                          selectedGroups={this.state.allowedGroups}
                          limitedGroups={this.state.limitedGroups}
                          pointsLimit={this.state.pointsLimit}
                          selectGroups={this.onSelectAllowedGroups}
                          addLimitedGroup={this.onAddLimitedGroup}
                          updateLimitedGroups={this.onUpdateLimitedGroups}
                          placeholder="Select groups"
                        />
                      </div>
                    </li>
                  }
                  { (this.state.allocationMode != "5") &&
                    <li className="form-group">
                      <div className="col-sm-2 control-label"><label>Monthly points limit</label></div>
                      <div className="col-sm-10">
                        <NumericInput 
                        min={0} 
                        value={this.state.pointsLimit}
                        onChange={(val) => this.onPointsLimitChange(val)} 
                        className="form-control" 
                        />
                      </div>
                    </li>   
                  }
                </ul>
              </div>
              <div className="modal-footer">
                <button onClick={this.handleSubmit} className="btn btn-sm btn-primary">Save</button>  &nbsp;
                <button onClick={this.closeEditForm} className="btn btn-sm btn-default">Cancel</button>
              </div>
            </div>
        </div>    
      )
    } else {
      return (
        <div className="ibox" id="pointsAllocationSettings">
            <div className="ibox-title">
              <h5>Points Allocation Settings</h5>
            </div>
            <div className="ibox-content">
              <ul className="list-unstyled list-group clear-list">
                <li>
                  <div>
                    <label>Each dollar =  </label> {this.state.pointsRatio} Points
                  </div>
                </li>
                <li>
                  <div>
                    <label>Allocate points to employee : </label> {this.humanizeAllocationMode()}
                  </div>
                </li>
                { this.state.allocationMode == "2" &&
                <li>
                  <div>
                    <label>Selected Positions : </label>&nbsp; 
                    {this.state.allowedPositions.map((position, index) => `${position}` + ((this.state.allowedPositions.length-1 == index) ? "" : ", "))}
                  </div>
                </li> }
                { this.state.allocationMode == "3" &&
                <li>
                  <div>
                    <label>Selected Employees : </label>&nbsp; 
                    {this.state.allowedEmployees.map((employee, index) => 
                      <Link key={employee.id} to={client.currentUser().id == employee.id ? "/profile" : `/employee/${employee.id}`}> 
                        {this.state.allowedEmployees.length-1 == index ? employee.full_name : `${employee.full_name}, ` }
                      </Link> 
                    )}
                  </div>
                </li> }
                { this.state.allocationMode == "4" &&
                <li>
                  <div>
                    <label>Selected Groups : </label>&nbsp; 
                    <span>
                      {this.state.allowedGroups.map((group, index) => `${group.title}` + ((this.state.allowedGroups.length-1 == index) ? "" : ", "))}
                    </span>
                  </div>  
                </li> }
                { this.state.allocationMode == "5" &&
                <li>
                  <div>
                    <label>Selected Groups : </label>&nbsp; 
                    <span>
                      {this.state.allowedGroups.map((group, index) => `${group.title} (${(this.state.limitedGroups.find((x) => x.id == group.id) || {}).points_limit})` + ((this.state.allowedGroups.length-1 == index) ? "" : ", "))}
                    </span>
                  </div>  
                </li> }
                <li>
                  <div>
                    <label>Monthly points limit : </label> {this.state.pointsLimit}
                  </div>
                </li>   
              </ul>
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-primary" onClick={this.openEditForm}><FontAwesomeIcon icon={faPencilAlt}/> Edit</button>
              </div>
            </div>
        </div>    
      )
    }
  }

}


export default PointsAllocationSettings;
