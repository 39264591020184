import React, { Component } from 'react';
import { Wheel } from 'react-custom-roulette';
import { Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert-react';
import { toastrNotification } from '../helpers/Toastr';
import { rouletteClient } from '../clients/RouletteClient';
import { trimText } from '../helpers/Humanizer';

//const backgroundColors = ['#82e4aa', '#eeeeee', '#ffc47a', '#39b9ea', '#ff7c7c', '#ecbeef', '#f7efb5', '#98b0dc' ]
//const textColors = ['#333', '#333', '#333', '#333', '#333', '#333', '#333', '#333']

//const backgroundColors = ['#104c77', '#ff9151', '#f7efb5', '#39b9ea', '#104c77', '#ff9151', '#f7efb5', '#39b9ea' ]
//const textColors = ['#73c7ff', '#444', '#ff9151', '#444', '#f7efb5', '#444', '#104c77', '#444']

const backgroundColors = ['#EF4F24', '#8BC53F', '#EF4F24', '#8BC53F', '#EF4F24', '#8BC53F', '#EF4F24', '#8BC53F' ]
const textColors = ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']

class RouletteRecordingView extends Component {
  
  state = {
    mustSpin: false,
    prizeNumber: 0,
    gomessage: '',
    record: {        
      roulette: {},    
      prizes: []
    }
  }
  
  componentDidMount() {
    this.getRouletteRecording()
  }

  getRouletteRecording() {
    rouletteClient.getRouletteRecording(this.props.match.params.id).then((record) => {
      if (record.id) {
        this.setState({ record: this.composePrizes(record)})
        if(record.reward_type_id) {
          setTimeout(() => {this.goMessage('Ready')},   500)
          setTimeout(() => {this.goMessage('Steady')},  1500)
          setTimeout(() => {this.goMessage('Go!')},     2500)
          setTimeout(() => {this.startSpinning()}, 3000)          
        }
      } else {
        toastrNotification({success: false, message: "Unable to find roulette."});
      }      
    });
  }
  
  composePrizes = (record) => {
    let prizes = Array.from(record.prizes)
    for(var i=prizes.length; i<8; i++) {
      prizes.push(prizes[i % record.prizes.length])
    }
    return Object.assign({}, record, {prizes: prizes})    
  }

  goMessage = (message) => {
    this.setState({gomessage: message})
  }
  
  startSpinning = () => {
    const prizeNumber = this.state.record.prizes.findIndex(p => p.reward_type_id === this.state.record.reward_type_id)
    this.setState({ prizeNumber: prizeNumber, mustSpin: true})        
  }
    
  stopSpinning = () => {
    this.setState({ mustSpin: false, showPrize: true })  
  }
  
  closeCongratulation = () => {
    this.setState({showPrize: false})
  }
  
  rouletteOptions = () => {
    return (this.state.record.prizes || []).map((prize, i) => (
        {
          option: trimText(prize.title, 16), 
          style: {
            backgroundColor: backgroundColors[i % backgroundColors.length],
            textColor: textColors[i % textColors.length]
          } 
        }
      )
    )
  }
  
  winningText = () => {
    if (this.state.record.prizes[this.state.prizeNumber]) {
      return this.state.record.winner.first_name + " " + this.state.record.winner.last_name + " won " + this.state.record.prizes[this.state.prizeNumber].title + "."        
    } else {
      return ""
    }      
  }  
  
  initialText = () => {
    if (this.state.record.reward_type_id) {
      return this.state.gomessage
    } else {
      return 'Not Started Yet'
    }
  }
    
  render() {
        
    return (
      <>
        { this.state.record.id &&
          <div className="roulette-box">
            <h1 className="text-center">{this.state.record.roulette.title}</h1>
            <Wheel
            mustStartSpinning={this.state.mustSpin}
            prizeNumber={this.state.prizeNumber}
            data={this.rouletteOptions()}
            outerBorderWidth={10}
            outerBorderColor={'#ddd'}
            radiusLineWidth={10}
            radiusLineColor={'#ddd'}
            fontSize={18}
            onStopSpinning={this.stopSpinning}
            />
            <div className='text-center'>
              <h1>
              {this.initialText()}
              </h1>
            </div>
          </div>        
        }
          <SweetAlert
            show={this.state.showPrize}
            type="success"
            title="Congratulations!"
            confirmButtonColor="#DD6B55"
            text={this.winningText()}
            onConfirm={this.closeCongratulation}
          />        
      </>
    )
  }
}


export default RouletteRecordingView