import React, { Component } from 'react';
import SliderJS from 'ion-rangeslider';
import 'ion-rangeslider/css/ion.rangeSlider.css';
// import 'ion-rangeslider/css/ion.rangeSlider.skinHTML5.css';
import './RangeSlider.css'

class SliderQuestion extends Component {
  
  state = {
    answer: ''
  }

  renderSlider() {
    $(this.refs.slider).ionRangeSlider({
      from: this.props.value,
      type: 'single',
      step: this.props.step || 1,
      values: this.props.question.values,
      prettify: true,
      grid: true,
      onChange: this.handleChange
    });
  }

  getSliderValue(): number {
    return $(this.refs.slider).data("ionRangeSlider").result.from_value
  }

  componentDidMount() {
    this.renderSlider();
  }

  componentDidUpdate() {
    this.renderSlider();
  }
    
  getAnswer = () => {
    return this.state.answer
  } 
  
  buildAnswerHash = () => {
    return { survey_question_id: this.props.question.id, data: this.state.answer }
  } 

 handleChange = () => {
   this.setState({ answer: this.getSliderValue() });
   this.props.updateCurrentAnswer(this.getSliderValue());
 } 
          
 render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h3 className="text-center">{this.props.question.question}</h3>
          <div style={{margin: '30px 0'}}>
            <input ref="slider"></input>
          </div>
        </div>      
      </div>  
    );
  }
}

export default SliderQuestion;
