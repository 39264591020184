import React, { Component } from "react";
import { Link } from "react-router-dom";
import { client } from "../../components/Client";
import "./loginForm.css";

class LoginForm extends Component {
  state = {
    loginInProgress: false,
    invalid: false,
    shouldRedirect: false,
    terminated: false,
    warning: "",
  };

  onFormSubmit = (evt) => {
    evt.preventDefault();
    this.setState({ loginInProgress: true });

    client
      .login(this.refs.email.value, this.refs.password.value)
      .then((response) => {
        if (
          client.isLoggedIn() ||
          (client.isTerminatedLoggedIn() &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.allow_terminated)
        ) {
          this.props.onEnterAccount();
        } else {
          this.setState({
            loginInProgress: false,
            invalid: true,
            terminated: client.isTerminatedLoggedIn(),
            warning: response.user_authentication,
            communication_error: response.communication_error,
          });
        }
      });
  };

  showForgotPasswordForm = () => {
    this.props.onForgotPassword();
  };

  redirectPath = () => {
    const locationState = this.props.location.state;
    const pathname =
      locationState && locationState.from && locationState.from.pathname;
    return pathname || "/activity";
  };

  render() {
    return (
      <div className="login-form-main" style={{ borderRadius: "inherit" }}>
        {this.state.invalid && this.state.communication_error && (
          <div
            className="alert alert-danger"
            style={{
              borderTopLeftRadius: "inherit",
              borderTopRightRadius: "inherit",
              marginTop: "0",
            }}
          >
            No internet connection.
          </div>
        )}
        {this.state.invalid &&
          this.state.terminated &&
          !this.state.communication_error && (
            <div
              className="alert alert-danger"
              style={{
                borderTopLeftRadius: "inherit",
                borderTopRightRadius: "inherit",
                marginTop: "0",
              }}
            >
              Your account is terminated.
            </div>
          )}
        {this.state.invalid &&
          !this.state.terminated &&
          !this.state.communication_error && (
            <div
              className="alert alert-danger"
              style={{
                borderTopLeftRadius: "inherit",
                borderTopRightRadius: "inherit",
                marginTop: "0",
              }}
            >
              {this.state.warning}
            </div>
          )}

        <div className="ibox-title">
          <img src="img/logo2.png" height="42" className="login-logo" />
        </div>

        <div className="ibox-content">
          <form className="form-horizontal" onSubmit={this.onFormSubmit}>
            <p></p>
            <div className="form-group">
              <label className="col-lg-3 control-label content-1">
                Email/Phone
              </label>
              <br />
              <div className="col-lg-9" id="input-main-1">
                <input
                  autoComplete="off"
                  name="email"
                  placeholder="example@gmail.com"
                  className="form-control"
                  id="input-1"
                  ref="email"
                  required
                />
                <span className="help-block m-b-none"></span>
              </div>
            </div>
            <div className="form-group">
              <label className="col-lg-3 control-label content-2">
                Password
              </label>
              <div className="col-lg-9" id="input-main-2">
                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  className="form-control"
                  id="input-2"
                  ref="password"
                  required
                />
                <div className="pull-right">
                  <a
                    className="forgot-password-link"
                    onClick={this.showForgotPasswordForm}
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "15px", width: "100%" }}>
              <button
                className="btn btn-md btn-primary"
                type="submit"
                id="btn-log-in"
              >
                Login
              </button>
            </div>
          </form>
          <div style={{ display: "flex", alignItems: "center" }}>
            <hr
              style={{
                flex: "1 1 auto",
                height: "2px",
                backgroundColor: "gray",
              }}
            ></hr>
            <span style={{ padding: "0 40px" }}>OR</span>
            <hr
              style={{
                flex: "1 1 auto",
                height: "2px",
                backgroundColor: "gray",
              }}
            ></hr>
          </div>

          <div id="create-new">
            <Link to={"/signup"} className="signup">
              Create account
            </Link>
          </div>
          <br />
          <p>
            <small>
              If your company hasn't registered yet, please{" "}
              <Link to={"/client_credentials"} className="auth-links">provide details here</Link> and
              we'll get them signed up and get you started!
            </small>
          </p>
        </div>
      </div>
    );
  }
}

export default LoginForm;
